import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from '../auth.module.scss';
import { Box, Grid } from '@mui/material';
import { AuthLoginForm } from '../forms/auth-login-form';
import { a11yProps, Tab, TabPanel, Tabs } from './tabs';
import { AuthSignUpForm } from '../forms/auth-signup-form';
import { AuthConfirmSignupForm } from '../forms/auth-confirm-signup-form';
import { AuthForgotPasswordForm } from '../forms/auth-forgot-password';
import { AuthResetPasswordForm } from '../forms/auth-reset-password';
import { AuthenticatorProps } from '../auth';
import { ArtcuratorgridApiClient } from '@acg/artcuratorgrid-api-spec';
import { AuthMustResetPassword } from '../forms/auth-must-reset-password';
import { AuthHeader } from './auth-header';

export enum AUTH_STEPS {
  SIGN_IN = 0,
  SIGN_UP = 1,
  CONFIRM_SIGNUP = 2,
  FORGOT_PASSWORD = 3,
  RESET_PASSWORD = 4,
  MUST_RESET_PASSWORD = 5,
}

const STEPS_MAPPING = {
  [AUTH_STEPS.SIGN_IN]: AuthLoginForm,
  [AUTH_STEPS.SIGN_UP]: AuthSignUpForm,
  [AUTH_STEPS.CONFIRM_SIGNUP]: AuthConfirmSignupForm,
  [AUTH_STEPS.MUST_RESET_PASSWORD]: AuthMustResetPassword,
  [AUTH_STEPS.FORGOT_PASSWORD]: AuthForgotPasswordForm,
  [AUTH_STEPS.RESET_PASSWORD]: AuthResetPasswordForm,
};

const getTitle = (step: AUTH_STEPS) => {
  switch (step) {
    case AUTH_STEPS.MUST_RESET_PASSWORD:
      return 'Our security system is evolving!';
    default:
      return 'Please sign in or create an account to continue';
  }
};

interface User {
  username: string;
  password: string;
}

export const initialUser: User = {
  username: '',
  password: '',
};

export interface IFormProps {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  setAuthStep: React.Dispatch<React.SetStateAction<AUTH_STEPS>>;
  timeOutError: string;
  setTimeOutError: React.Dispatch<React.SetStateAction<string>>;
  artcuratorgridApi: ArtcuratorgridApiClient;
}

export const AuthBody = (props: AuthenticatorProps) => {
  const [user, setUser] = useState<User>(initialUser);
  const [authStep, setAuthStep] = useState<AUTH_STEPS>(AUTH_STEPS.SIGN_IN);
  const [timeOutError, setTimeOutError] = useState('');

  useEffect(() => {
    if (!!timeOutError) {
      setTimeOutError('');
    }
  }, [authStep]);

  const CurrentStep = STEPS_MAPPING[authStep];
  const CurrentStepWithProps = (
    <CurrentStep
      user={user}
      setUser={setUser}
      setAuthStep={setAuthStep}
      timeOutError={timeOutError}
      setTimeOutError={setTimeOutError}
      artcuratorgridApi={props.artcuratorgridApi}
    />
  );

  const isTabScreen = useMemo(
    () => [AUTH_STEPS.SIGN_IN, AUTH_STEPS.SIGN_UP].includes(authStep),
    [authStep]
  );

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, newValue: AUTH_STEPS) => {
      setAuthStep(newValue);
    },
    [setAuthStep]
  );

  return (
    <Box className={styles.modal}>
      <AuthHeader {...props} title={getTitle(authStep)} />
      <Grid className={styles.bodyContainer}>
        {isTabScreen && (
          <>
            <Tabs
              value={authStep}
              onChange={handleTabChange}
              aria-label="Form tabs"
              variant="fullWidth"
            >
              <Tab
                className={styles.tab}
                label="Sign In"
                {...a11yProps(AUTH_STEPS.SIGN_IN)}
              />
              <Tab
                className={styles.tab}
                label="Create Account"
                {...a11yProps(AUTH_STEPS.SIGN_UP)}
              />
            </Tabs>
            <Grid className={styles.formContainer}>
              <TabPanel value={authStep} index={AUTH_STEPS.SIGN_IN}>
                {CurrentStepWithProps}
              </TabPanel>
              <TabPanel value={authStep} index={AUTH_STEPS.SIGN_UP}>
                {CurrentStepWithProps}
              </TabPanel>
            </Grid>
          </>
        )}

        {!isTabScreen && (
          <Grid className={styles.formContainer}>{CurrentStepWithProps}</Grid>
        )}
      </Grid>
    </Box>
  );
};
