import {
  default as withWidth,
  WithWidth,
} from '@mui/material/Hidden/withWidth';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import * as React from 'react';
import { Backdrop, duration, Fade, Grid, Paper } from '@mui/material';
import { compose } from 'recompose';
import SwipableCarouselView from '../../components/modal/SwipableCarouselView';
import Dots from 'material-ui-dots';
import ArrowBackIcon from '../../img/exhibitionPoints/ACG_ICONS-03.svg';
import ArrowForwardIcon from '../../img/exhibitionPoints/ACG_ICONS-04.svg';
import ArrowBackBlackIcon from '../../img/exhibitionPoints/ACG_ICONS-03_black.svg';
import ArrowForwardBlackIcon from '../../img/exhibitionPoints/ACG_ICONS-04_black.svg';

import { PRIMARY_COLOR } from '../../styles/constants';
import Title from '../../library/dataDisplay/typography/Title';
import IconClose from '../../library/dataDisplay/icons/IconClose';
import IconCross from '../../library/dataDisplay/icons/IconCross';
import { ACGModal } from './ACGModal';

export interface IExhibitionPointModalProps {
  open: boolean;
  onClose: () => void;
  onChange?: (index: number) => void;
  ModalProps?: any;
  containerStyle?: any;
  mobile: boolean;
  hideArrows: boolean;
  initialIndex: number;
  children: React.ReactNode;
}

interface IState {
  slideIndex: number;
  isOpen: boolean;
}

const styles = createStyles({
  modal: {
    '& > *:focus': {
      outline: 'none',
    },
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: 0,
  },
  content: {
    width: '80%',
    height: '100%',
    margin: '0 auto',
    position: 'relative',
    borderRadius: 0,
  },
  contentMobile: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    position: 'relative',
    borderRadius: 0,
  },
  arrowLeft: {
    left: -120,
    width: 120,
    height: 120,
    position: 'absolute',
    top: 'calc((100% - 96px) / 2 + 24px)',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    color: 'white',
    textAlign: 'center',
    fontSize: 20,
  },
  arrowRight: {
    right: -120,
    width: 120,
    height: 120,
    position: 'absolute',
    top: 'calc((100% - 96px) / 2 + 24px)',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    color: 'white',
    textAlign: 'center',
    fontSize: 20,
  },
  closeIcon: {
    right: -89,
    position: 'absolute',
    top: 10,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  carouselWrapper: {
    overflow: 'hidden',
    transform: 'scale(1.0)',
    background: 'transparent',
    height: '100%',
    overflowY: 'auto',
    borderRadius: 0,
  },
  footer: {
    marginTop: -72,
    width: '100%',
    position: 'relative',
    textAlign: 'center',
  },
  footerMobile: {
    marginTop: -72,
    width: '100%',
    position: 'relative',
    textAlign: 'center',
  },
  dots: {
    paddingTop: 36,
    margin: '0 auto',
    '& .MuiPaper-rounded': {
      backgroundColor: PRIMARY_COLOR,
    },
  },
  dotsMobile: {
    paddingTop: 0,
  },
  slide: {
    width: '100%',
    height: '100%',
  },
  slideMobile: {
    width: '100%',
    height: '100%',
  },
  carousel: {
    height: '100%',
  },
  carouselContainer: {
    height: '100%',
  },

  headerStyle: {
    backgroundColor: '#F6F6F6',
    borderRadius: 0,
    padding: '0px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  orderValue: {
    fontSize: 30,
  },
});

type Props = IExhibitionPointModalProps & WithStyles<string> & WithWidth;

class ExhibitionPointModal extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    const { initialIndex, open } = this.props;

    this.state = {
      slideIndex: initialIndex,
      isOpen: open,
    };

    this.onChange(initialIndex);
  }

  public onChange = (slideIndex: number) => {
    const { onChange, children } = this.props;

    const countChildren = React.Children.count(children);
    if (onChange) {
      onChange(this.modulo(slideIndex, countChildren));
    }
  };

  public modulo = (a: number, n: number) => {
    return ((a % n) + n) % n;
  };

  public decreaseIndex = () => {
    const { slideIndex } = this.state;

    this.setState(
      {
        slideIndex: slideIndex - 1,
      },
      () => this.onChange(slideIndex - 1)
    );
  };

  public increaseIndex = () => {
    const { slideIndex } = this.state;

    this.setState(
      {
        slideIndex: slideIndex + 1,
      },
      () => this.onChange(slideIndex + 1)
    );
  };

  public setInitialState = () => {
    const { initialIndex } = this.props;

    if (initialIndex) {
      this.setState({
        slideIndex: initialIndex,
      });
    }
  };

  public render() {
    const {
      open,
      onClose,
      ModalProps,
      containerStyle,
      mobile,
      hideArrows,
      classes,
      children,
    } = this.props;

    const { slideIndex, isOpen } = this.state;

    const transitionDuration = {
      enter: duration.enteringScreen,
      exit: duration.leavingScreen,
    };
    const countChildren = React.Children.count(children);
    const hasMultipleChildren = countChildren > 0;
    const showDots = countChildren < 10;

    const headerMobile = (orderValue: string) => {
      if (!mobile) {
        return;
      }

      return (
        <Grid container={true} className={classes.headerStyle}>
          <Grid container={true} justifyContent={'flex-end'}>
            <Grid
              item={true}
              onClick={onClose}
              style={{ textAlign: 'right', paddingTop: '15px' }}
            >
              <IconCross height={30} />
            </Grid>
          </Grid>

          <Grid container={true}>
            <Grid
              item={true}
              xs={1}
              onClick={this.decreaseIndex}
              style={{ textAlign: 'left' }}
            >
              <img src={ArrowBackBlackIcon} style={{ width: 38 }} />
            </Grid>
            <Grid item={true} xs={10} style={{ textAlign: 'center' }}>
              <Title className={classes.orderValue}>{orderValue}</Title>
            </Grid>
            <Grid
              item={true}
              xs={1}
              onClick={this.increaseIndex}
              style={{ textAlign: 'right' }}
            >
              <img src={ArrowForwardBlackIcon} style={{ width: 38 }} />
            </Grid>
          </Grid>
        </Grid>
      );
    };

    const carousel = (
      <SwipableCarouselView
        key={`slider_${this.state.slideIndex}`}
        className={classes.carousel}
        containerStyle={{ height: '100%', ...containerStyle }}
        index={slideIndex}
        onChangeIndex={this.handleChange}
        slideClassName={classes.slide}
      >
        {React.Children.map(children, (c: any) =>
          React.cloneElement(c, {
            headerMobile,
            mobile,
          })
        )}
      </SwipableCarouselView>
    );

    return (
      <ACGModal
        open={isOpen}
        onClose={onClose}
        BackdropComponent={Backdrop}
        BackdropProps={
          ModalProps
            ? { transitionDuration, ...ModalProps.BackdropProps }
            : { transitionDuration }
        }
        className={classes.modal}
      >
        <Fade appear={true} in={open} timeout={transitionDuration}>
          <div
            className={mobile ? classes.contentMobile : classes.content}
            onClick={this.handleContentClick}
          >
            <Paper
              elevation={mobile ? 0 : 1}
              className={classes.carouselWrapper}
            >
              {carousel}
            </Paper>

            <div>
              <div className={mobile ? classes.footerMobile : classes.footer}>
                {hasMultipleChildren && showDots && (
                  <Dots
                    count={countChildren}
                    index={this.modulo(this.state.slideIndex, countChildren)}
                    className={mobile ? classes.dotsMobile : classes.dots}
                    onDotClick={this.handleChange}
                  />
                )}
              </div>
            </div>

            {!mobile && !hideArrows && hasMultipleChildren && (
              <div>
                <div className={classes.closeIcon} onClick={onClose}>
                  <IconClose height={50} />
                </div>
                <div className={classes.arrowLeft} onClick={this.decreaseIndex}>
                  <img src={ArrowBackIcon} />
                  <p>Previous</p>
                </div>
                <div
                  className={classes.arrowRight}
                  onClick={this.increaseIndex}
                >
                  <img src={ArrowForwardIcon} />
                  <p>Next</p>
                </div>
              </div>
            )}
          </div>
        </Fade>
      </ACGModal>
    );
  }

  public handleContentClick = (e: any) =>
    e.stopPropagation() || e.preventDefault();

  public handleChange = (slideIndex: number) => {
    this.setState({
      slideIndex,
    });
  };
}

export default compose<Props, IExhibitionPointModalProps>(
  withStyles(styles),
  withWidth()
)(ExhibitionPointModal);
