import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './containers/App';
import './styles.scss';
import { Provider } from 'react-redux';
import { store } from './store';
import { setUpAuth } from '@acg/auth/frontend';

setUpAuth();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
