import * as Yup from 'yup';
import randomPassword from 'secure-random-password';

export enum CustomAttributeKeys {
  COUNTRY_OF_RESIDENCE = 'custom:country_of_residence',
}

const isPasswordStrong = (value: string | undefined) => {
  const lowercaseLetter = /[a-z]/;
  const uppercaseLetter = /[A-Z]/;
  const digit = /\d/;
  const specialCharacter = /[!@#$%\\^&]/;
  return !!(
    value &&
    value.match(lowercaseLetter) &&
    value.match(uppercaseLetter) &&
    value.match(digit) &&
    value.match(specialCharacter)
  );
};

const specialChars = '!@#$%^&';

export const passwordValidation = Yup.string()
  .required('Password is required')
  .min(8, 'Password must be at least 8 characters')
  .test(
    'test-name',
    `Password must contain at least one uppercase and one lowercase character, a digit and a special character (${specialChars})`,
    isPasswordStrong
  );

export const generateValidPassword = (): string => {
  const password = randomPassword.randomPassword({
    length: 15,
    characters: [
      randomPassword.upper,
      randomPassword.lower,
      randomPassword.digits,
      specialChars,
    ],
  });
  passwordValidation.validateSync(password);
  return password;
};
