import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Formik, FormikProps, Field } from 'formik';
import { Grid } from '@mui/material';
import { compose } from 'recompose';
import { withAccount, IWithAccountProps } from '../account/InjectAccount';
import { generateValidator, IValidationConfig } from '../../utils/formatters';
import {} from '@mui/material/';
import { DARK_GREY_COLOR, PRIMARY_COLOR } from '../../styles/constants';
import DesignedButton from '../../library/inputs/DesignedButton';
import ImagesCheckboxSelect from '../../components/form/ImagesCheckboxSelect';
import { useEffect, useState } from 'react';
import PostService from '../../services/PostService';
import Loading from '../../components/loading/Loading';
import { IPost } from '../../types/posts';

export interface IViewerStep4Props {}

type Props = RouteComponentProps<{}> &
  IViewerStep4Props &
  IWithAccountProps &
  WithStyles<string> &
  WithWidth;

interface IFormValue {
  posts: [];
}

const styles = createStyles({
  wrapper: {
    fontFamily: 'Replica-Regular',
    minHeight: 'calc(100vh - 90px)',
    textAlign: 'center',
    padding: 20,
  },
  label: {
    color: DARK_GREY_COLOR,
    marginBottom: '5px',
  },
});

const ViewerStep4: React.FC<Props> = (props) => {
  const { classes, history, account, updateAccount } = props;

  // TODO - Take to redux
  const postService = new PostService();

  const [posts, setPosts] = useState<IPost[]>([]);

  const updatePostsAndAccount = async (postData: any[]) => {
    let update = {
      ...account,
      hasWorkedIn: true,
      pending: true,
    };

    if (postData.length > 0) {
      await postService.addArtistToPosts(postData);
      update = {
        ...update,
        hasWorkedWith: [],
        pending: false,
      };
    }

    await updateAccount(update);

    history.push('/login');
  };

  useEffect(() => {
    try {
      postService.getArtistPosts().then(async (data) => {
        if (data.data.length === 0) {
          updatePostsAndAccount([]);
        }
        setPosts(data.data);
      });
    } catch (e) {
      window.console.log(e);
    }
  }, []);

  // const [error, setError] = useState<boolean>(false)
  const onSubmit = async (values: IFormValue, { setSubmitting }: any) => {
    // setError(false)
    try {
      const selectedPosts = [];
      for (const key in values.posts) {
        if (values.posts[key]) {
          selectedPosts.push(key);
        }
      }
      updatePostsAndAccount(selectedPosts);
    } catch (err) {
      // setError(true)
    }
    setSubmitting(false);
  };

  const renderForm = (formProps: FormikProps<IFormValue>) => {
    return (
      <>
        <form onSubmit={formProps.handleSubmit}>
          <Grid
            container={true}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item={true} xs={12} style={{ padding: 35 }}>
              <Field
                name="posts"
                component={ImagesCheckboxSelect}
                classes={classes}
                inputProps={{
                  id: 'posts',
                  type: 'posts',
                  posts,
                }}
                label={''}
              />
            </Grid>
            <Grid item={true} xs={8}>
              <DesignedButton
                ACGType={'main'}
                size={'large'}
                type={'submit'}
                fullWidth={true}
                disabled={formProps.isSubmitting}
              >
                Finalize Application
              </DesignedButton>
            </Grid>
          </Grid>
        </form>
      </>
    );
  };

  const initialValues: IFormValue = {
    posts: [],
  };
  const config: IValidationConfig = {
    // location: ['REQUIRED']
  };

  const validate = generateValidator(config);

  return posts.length !== 0 ? (
    <Grid
      container={true}
      direction="column"
      spacing={2}
      className={classes.wrapper}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <h3 style={{ color: PRIMARY_COLOR, margin: 0, fontSize: 20 }}>
        Please, confirm you are the artist featured in this/these post(s)
      </h3>
      <p style={{ margin: 0 }}>
        Select the one(s) where your work was presented so they can be added to
        your profile.
      </p>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        render={renderForm}
      />
    </Grid>
  ) : (
    <Loading />
  );
};

export default compose<Props, IViewerStep4Props>(
  withRouter,
  withAccount,
  withStyles(styles),
  withWidth()
)(ViewerStep4);
