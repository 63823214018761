import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, makeStyles } from '@mui/material';
import { DARK_GREY_COLOR, SECONDARY_COLOR } from '../../styles/constants';
import { useIsWidthDown } from '../../hooks/theme';

const PREFIX = 'Section';

const classes = {
  header: `${PREFIX}-header`,
  section: `${PREFIX}-section`,
};

const StyledGrid = styled(Grid)({
  [`& .${classes.header}`]: {
    marginBottom: 10,
  },
  [`&.${classes.section}`]: {
    padding: '82px 153px',
  },
});

export interface IPostTypeFormProps {
  texts: {
    title: string;
    subTitle: string;
    label?: string;
    labelLink?: string;
  };
  reverse?: boolean;
  grey?: boolean;
  icon: JSX.Element;
  isSpecial?: boolean;
}

const Section = (props: IPostTypeFormProps) => {
  const { texts, icon, grey, reverse, isSpecial } = props;

  const isMobile = useIsWidthDown('sm');
  const isPad = useIsWidthDown('md');
  const backgroundColor = grey ? '#f4f4f4' : '#ffffff';

  return (
    <StyledGrid
      container={true}
      className={classes.section}
      style={{
        backgroundColor,
        paddingLeft: `${isMobile ? '20px' : '135px'}`,
        paddingRight: `${isMobile ? '20px' : '135px'}`,
      }}
      justifyContent="flex-start"
      spacing={4}
    >
      {isMobile || isPad ? (
        <>
          <Grid item={true} md={12}>
            {icon}
          </Grid>
          <Grid item={true} md={12}>
            <h2 className={classes.header}>{texts.title}</h2>
            <h3 style={{ marginTop: 0 }}>{texts.subTitle}</h3>
            {texts.label && texts.labelLink && (
              <>
                <br />
                <br />
                <br />
                <label style={{ color: SECONDARY_COLOR }}>
                  <a href={texts.labelLink} style={{ color: SECONDARY_COLOR }}>
                    {texts.label}
                  </a>
                </label>
              </>
            )}

            {texts.label && !texts.labelLink && (
              <>
                <br />
                <br />
                <br />
                <label style={{ color: DARK_GREY_COLOR }}>{texts.label}</label>
              </>
            )}
          </Grid>
        </>
      ) : (
        <>
          {reverse ? (
            <>
              <Grid
                item={true}
                md={isSpecial ? 4 : 6}
                container={true}
                direction="column"
                justifyContent="center"
              >
                <h2 className={classes.header}>{texts.title}</h2>
                <h3 style={{ marginTop: 0 }}>{texts.subTitle}</h3>
                {texts.label && texts.labelLink && (
                  <>
                    <br />
                    <br />
                    <br />
                    <label style={{ color: SECONDARY_COLOR }}>
                      <a
                        href={texts.labelLink}
                        style={{ color: SECONDARY_COLOR }}
                      >
                        {texts.label}
                      </a>
                    </label>
                  </>
                )}

                {texts.label && !texts.labelLink && (
                  <>
                    <br />
                    <br />
                    <br />
                    <label style={{ color: DARK_GREY_COLOR }}>
                      {texts.label}
                    </label>
                  </>
                )}
              </Grid>
              <Grid
                item={true}
                md={isSpecial ? 8 : 6}
                container={true}
                justifyContent="center"
                alignItems={'center'}
              >
                {icon}
              </Grid>
            </>
          ) : (
            <>
              <Grid
                item={true}
                md={isSpecial ? 4 : 6}
                container={true}
                justifyContent="center"
              >
                {icon}
              </Grid>
              <Grid
                item={true}
                md={isSpecial ? 8 : 6}
                container={true}
                direction="column"
                justifyContent="center"
              >
                <h2 className={classes.header}>{texts.title}</h2>
                <h3 style={{ marginTop: 0 }}>{texts.subTitle}</h3>
                {texts.label && texts.labelLink && (
                  <>
                    <br />
                    <br />
                    <br />
                    <label style={{ color: SECONDARY_COLOR }}>
                      <a
                        href={texts.labelLink}
                        style={{ color: SECONDARY_COLOR }}
                      >
                        {texts.label}
                      </a>
                    </label>
                  </>
                )}

                {texts.label && !texts.labelLink && (
                  <>
                    <br />
                    <br />
                    <br />
                    <label style={{ color: DARK_GREY_COLOR }}>
                      {texts.label}
                    </label>
                  </>
                )}
              </Grid>
            </>
          )}
        </>
      )}
    </StyledGrid>
  );
};

export default Section;
