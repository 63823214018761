import * as React from 'react';
import './App.css';
import { themes } from '@acg/assets';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import ContentManager from '../components/cookies/ContentManagerView';
import { useDispatch, useSelector } from 'react-redux';
import useAsyncEffect from 'use-async-effect';
import {
  authenticatedUser,
  IAuthRootState,
  reduxAuthManagement,
  setUser,
} from '@acg/auth/frontend';
import { Authenticator, GenericMetaData } from '@acg/frontend-ui-artpool';
import { Routes } from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { artpoolApiClient } from '../services/ArtpoolApiClient';
import { artcuratorgridApiClient } from '../services/ArtcuratorgridApiClient';
import { Helmet } from 'react-helmet';

export const App = (props: {}) => {
  const dispatch = useDispatch();
  const artpoolApi = artpoolApiClient();

  const { user, renderAuthenticator } = useSelector(
    (root: IAuthRootState) => root.authReducer
  );

  useAsyncEffect(async () => {
    dispatch(setUser(await authenticatedUser()));
    reduxAuthManagement(dispatch);
  }, [dispatch]);

  // When a user authenticates call an authenticated endpoint to make sure their user in created in the artpool-api database
  useAsyncEffect(async () => {
    if (!user) {
      return;
    }
    try {
      await artpoolApi.myAccountApi().getMyAccount();
    } catch (err) {
      console.error(err);
    }
  }, [user]);

  return (
    <div className="app">
      <Helmet>
        <GenericMetaData />
      </Helmet>
      {renderAuthenticator && (
        <Authenticator artcuratorgridApi={artcuratorgridApiClient()} />
      )}
      <ThemeProvider theme={themes.acgTheme}>
        <StyledEngineProvider injectFirst>
          <Router>
            <Routes />
          </Router>
        </StyledEngineProvider>
      </ThemeProvider>
      <ContentManager />
    </div>
  );
};
