import { } from '@mui/material';
import { } from '@mui/material/';
import {
  WithWidth,
  isWidthDown,
  default as withWidth,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import IconVenues from '../../components/icons/IconVenues';
import IconNetwork from '../../library/dataDisplay/icons/IconNetwork';
import IconOnlineExhibition from '../../library/dataDisplay/icons/IconOnlineExhibition';
import IconPost from '../../library/dataDisplay/icons/IconPost';
import { PRIMARY_COLOR } from '../../styles/constants';
import { checkRight } from '../../utils/userUtils';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';

export interface IExtraInfoButtonProps {}

const style = createStyles({
  buttonStyle: {
    marginLeft: 50,
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
      color: PRIMARY_COLOR,
    },
  },
  nftsButtonStyle: {
    color: 'black',
    textAlign: 'right',
    '&:hover': {
      cursor: 'pointer',
      color: PRIMARY_COLOR,
    },
  },
  iconText: {
    display: 'block',
    fontSize: 12,
    fontFamily: 'Replica-Regular',
    whiteSpace: 'nowrap',
    margin: 0,
  },
});

type Props = IExtraInfoButtonProps &
  IWithAccountProps &
  WithWidth &
  WithStyles<string> &
  RouteComponentProps<{}>;

function ExtraInfoButton(props: Props) {
  const { width, classes, account } = props;

  const isNotDesktop = isWidthDown('lg', width);
  const isPad = isWidthDown('md', width);
  const right = checkRight(account);

  const handleCreateClick = (event: any) => {
    if (right === 'MANAGER') {
      if (account.manages && account.manages.length > 0) {
        props.history.push(
          `/venues/${account.manages[0].url}/posts?create=true`
        );
        return;
      }
    } else {
      if (
        (account.profile === 'CURATOR' || account.profile === 'VIEWER') &&
        location.pathname.indexOf('/venues/') === 0 &&
        account.manages
      ) {
        props.history.push(
          `/venues/${account.manages[0].url}/posts?create=true`
        );
      } else if (location.pathname.indexOf('/users/') < 0) {
        props.history.push(`/users/${props.account.url}/posts?create=true`);
      } else if (location.pathname.indexOf(`/users/${props.account.url}`) < 0) {
        props.history.push(`/users/${props.account.url}/posts?create=true`);
      } else if (
        location.pathname.indexOf('/venues/') > 0 &&
        account.manages &&
        account.manages.length > 0
      ) {
        props.history.push(
          `/venues/${account.manages[0].url}/posts?create=true`
        );
      } else {
        props.history.push(`${location.pathname}/?create=true`);
      }
    }
    props.history.push(`/users/${props.account.url}/posts?create=true`);
    return;
  };

  const handleExhibitionClick = (event: any) => {
    props.history.push(`/exhibitions`);
  };

  const [isHoveringExhibition, setIsHoveringExhibition] = React.useState(false);
  const toggleExhibitionHover = (isHovering: boolean) => {
    setIsHoveringExhibition(isHovering);
  };
  const [isHoveringNetwork, setIsHoveringNetwork] = React.useState(false);
  const toggleNetworkHover = (isHovering: boolean) => {
    setIsHoveringNetwork(isHovering);
  };

  const [isHoveringVenues, setIsHoveringVenues] = React.useState(false);
  const toggleVenuesHover = (isHovering: boolean) => {
    setIsHoveringVenues(isHovering);
  };

  const userCanPost =
    right !== 'VIEWER' ||
    (right === 'VIEWER' &&
      account &&
      account.manages &&
      account.manages.length > 0);

  const canSeeNetwork = right === 'ALL';

  if (isPad) {
    return <></>;
  }

  return (
    <div style={{ display: 'flex', padding: '20px 0' }}>
      {userCanPost && (
        <div onClick={handleCreateClick} className={classes.buttonStyle}>
          <IconPost />
          <p className={classes.iconText}>Create Post</p>
        </div>
      )}
      {!isNotDesktop && (
        <>
          <div
            onClick={handleExhibitionClick}
            className={classes.buttonStyle}
            onMouseOver={() => toggleExhibitionHover(true)}
            onMouseOut={() => toggleExhibitionHover(false)}
          >
            <IconOnlineExhibition
              fill={isHoveringExhibition ? PRIMARY_COLOR : 'black'}
            />
            <p className={classes.iconText}>Exhibitions</p>
          </div>
          <div
            className={classes.buttonStyle}
            onMouseOver={() => toggleVenuesHover(true)}
            onMouseOut={() => toggleVenuesHover(false)}
          >
            <Link
              to={'/venues'}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <IconVenues fill={isHoveringVenues ? PRIMARY_COLOR : 'black'} />
              <p className={classes.iconText}>Art Venues</p>
            </Link>
          </div>
          {canSeeNetwork && (
            <div
              className={classes.buttonStyle}
              onMouseOver={() => toggleNetworkHover(true)}
              onMouseOut={() => toggleNetworkHover(false)}
            >
              <Link
                to={'/users'}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <IconNetwork
                  fill={isHoveringNetwork ? PRIMARY_COLOR : 'black'}
                />
                <p className={classes.iconText}>Network</p>
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default compose<Props, IExtraInfoButtonProps>(
  withStyles(style),
  withRouter,
  withAccount,
  withWidth()
)(ExtraInfoButton);
