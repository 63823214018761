import { getImageUrl } from '@acg/shared/utilities';
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import ReactPlayer from 'react-player';
import styles from './nft-viewer.module.scss';
import { Grid } from '@mui/material';
import { clippedCorners } from '../../styles/artpool-styles';
import { NEUTRAL, PRIMARY, themes } from '@acg/assets';
import { Property } from 'csstype';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import { JsonNft } from '@acg/artpool-api-spec';
import {
  ArrowButton,
  ArrowButtonProps,
} from '../../inputs/arrow-button/arrow-button';

export interface NftViewerProps {
  nft: JsonNft;
  maxHeight: string;
  style?: React.CSSProperties;
  hideFullScreenBtn?: boolean;
  objectFit?: Property.ObjectFit;
  alwaysUseImage?: boolean;
  bgColor?: Property.Color;
  controls?: {
    step: number;
    maxSteps: number;
    back: ArrowButtonProps;
    next: ArrowButtonProps;
  };
  cloudFrontUrl: string;
}

const width = '100%';

export const NftViewer = (props: NftViewerProps) => {
  const {
    nft,
    maxHeight,
    hideFullScreenBtn,
    objectFit,
    alwaysUseImage,
    controls,
    cloudFrontUrl,
  } = props;
  const style = props.style ?? {};

  const [fullSize, setFullSize] = useState(false);

  const onCloseDialog = () => setFullSize(false);
  const openDialog = () => setFullSize(true);

  const isSmall = useMediaQuery(themes.artpoolTheme.breakpoints.down('md'));

  const fullSizeNft = () => {
    if (props.nft.video) {
      return (
        <ReactPlayer
          url={props.nft.video.src}
          height={'90vh'}
          width={'90vw'}
          playing={false}
          controls={true}
          config={{
            vimeo: {
              playerOptions: {
                top: 0,
                maxWidth: '100%',
                width: '100%',
              },
            },
          }}
        />
      );
    }
    return (
      <img
        src={getImageUrl(nft.image.src, cloudFrontUrl)}
        style={{ maxHeight: '100vh', maxWidth: '100vw' }}
      />
    );
  };

  if (nft.video && !alwaysUseImage) {
    return (
      <ReactPlayer
        url={nft.video.src}
        height={isSmall ? '40vh' : '60vh'}
        width={width}
        playing={false}
        controls={true}
        config={{
          vimeo: {
            playerOptions: {
              top: 0,
              maxWidth: '100%',
              width: '100%',
            },
          },
        }}
      />
    );
  }

  return (
    <Grid container justifyContent={'center'} style={{ width, ...style }}>
      <Grid
        item={true}
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      >
        <img
          src={nft.image.src}
          onClick={openDialog}
          style={{
            ...clippedCorners,
            objectFit: objectFit || 'contain',
            width: width || 'auto',
            height: maxHeight,
            maxHeight,
            backgroundColor: props.bgColor || NEUTRAL[50],
          }}
        />
        {!hideFullScreenBtn && (
          <img
            src={'/assets/images/full-width-img.png'}
            onClick={openDialog}
            style={{
              position: 'absolute',
              width: 40,
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
              right: 10,
              top: 10,
              borderRadius: 4,
            }}
          />
        )}
        {controls && (
          <>
            <div
              style={{
                position: 'absolute',
                left: 20,
                bottom: 17,
              }}
            >
              <p style={{ color: PRIMARY.ARTPOOL_GREEN }}>
                {controls.step}/{controls.maxSteps}
              </p>
            </div>
            <div
              style={{
                position: 'absolute',
                right: 5,
                bottom: 5,
              }}
            >
              <ArrowButton variant={'text'} {...controls.back} />
              <ArrowButton variant={'text'} {...controls.next} />
            </div>
          </>
        )}
        <Dialog
          classes={{ paper: styles.full_image }}
          onClose={onCloseDialog}
          open={fullSize}
        >
          {fullSizeNft()}
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default NftViewer;
