import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import {} from '@mui/material';
import { compose } from 'recompose';
import { PRIMARY_COLOR } from '../../../styles/constants';

export interface IHightLightedTitleProps {
  children: React.ReactNode;
}

const style = createStyles({
  root: {
    padding: 25,
    fontFamily: 'Replica-Regular',
    fontSize: 20,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.2,
    letterSpacing: 'normal',
    textAlign: 'center',
    backgroundColor: PRIMARY_COLOR,
    textTransform: 'uppercase',
    cursor: 'default',
  },
});

type Props = WithStyles<string> & IHightLightedTitleProps;

class HightLightedTitle extends React.Component<Props, any> {
  public render() {
    const { classes } = this.props;

    return <div className={classes.root}>{this.props.children}</div>;
  }
}

export default compose<Props, IHightLightedTitleProps>(withStyles(style))(
  HightLightedTitle
);
