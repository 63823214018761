import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { AcgArrowIcon, NEUTRAL, PRIMARY, themes } from '@acg/assets';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';

export interface ArrowButtonProps {
  disabled: boolean;
  rotation: RotationString;
  handleClick: () => void;
  style?: React.CSSProperties;
}

export enum RotationString {
  LTR = 'ltr',
  RTL = 'rtl',
}

export function ArrowButton(props: ArrowButtonProps & ButtonProps) {
  const { disabled, rotation, handleClick, style } = props;
  const theme = useTheme();
  const isMedium = useMediaQuery(themes.artpoolTheme.breakpoints.down('lg'));

  return (
    <Button
      size="small"
      onClick={handleClick}
      disabled={disabled}
      variant={props.variant || isMedium ? 'outlined' : 'text'}
      color={'secondary'}
      style={{
        color: isMedium ? NEUTRAL[600] : PRIMARY.ARTPOOL_GREEN,
        padding: '15px 0px',
        ...style,
      }}
    >
      {theme.direction === rotation ? (
        <AcgArrowIcon />
      ) : (
        <AcgArrowIcon style={{ transform: 'rotate(180deg)' }} />
      )}
    </Button>
  );
}
