import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import {} from '@mui/material';
import { IWithAccountProps } from '../../containers/account/InjectAccount';
import { ImageOverlay, Map } from 'react-leaflet';
import { CRS } from 'leaflet';
import { getImageUrl } from '../../utils/imageUrl';
import { IExhibitionPoint } from '../../types/exhibitions';
import { useRef } from 'react';
import OnlineExhibitionPoint from './OnlineExhibitionPoint';
import OnlineExhibitionRenderLines from './OnlineExhibitionRenderLines';

export interface IOnlineExhibitionMapProps {
  exhibition: any;
  mapRef: any;
  markersArray: any;
  setModalOpen: (open: boolean) => void;
  setInitialIndex: (index: number) => void;

  selectedPointId: number | undefined;
  setSelectedPointId: (pointId: number | undefined) => void;
}

const MAP_HEIGHT = '580px';

const styles = createStyles({
  map: {
    height: MAP_HEIGHT,
  },
  mapMobile: {
    height: '400px',
  },
});

type Props = WithStyles<string> &
  IWithAccountProps &
  WithWidth &
  IOnlineExhibitionMapProps;

function OnlineExhibitionMap(props: Props) {
  const {
    exhibition,
    classes,
    width,
    mapRef,
    selectedPointId,
    setSelectedPointId,
    markersArray,
    setModalOpen,
    setInitialIndex,
  } = props;

  const popup = useRef<any | undefined>();

  const isMobile = isWidthDown('xs', width);

  // const [ selectedPointId, setSelectedPointId] = useState<number|undefined>(undefined);
  // const [modalOpen, setModalOpen] = useState(false);
  // const [initialIndex, setInitialIndex] = useState(0);

  const artworksColor = exhibition && exhibition.artworks_color;
  const artworksBackColor = exhibition && exhibition.artworks_back_color;
  const artworksLabelColor = exhibition && exhibition.artworks_label_color;

  return (
    <Map
      zoom={isMobile ? 0 : 1}
      zoomSnap={0.5}
      zoomDelta={0.5}
      zoomControl={isMobile ? false : true}
      center={[
        (exhibition.base_map.size[0] * 0.4) / 2,
        (exhibition.base_map.size[1] * 0.4) / 2,
      ]}
      className={isMobile ? classes.mapMobile : classes.map}
      crs={CRS.Simple}
      attributionControl={false}
      maxZoom={6}
      dragging={isMobile ? false : true}
      tap={isMobile ? false : true}
      touchZoom={true}
      boundsOptions={{ padding: [50, 50] }}
      ref={mapRef}
      // onlayeradd={fitToLayers}
      scrollWheelZoom={false}
    >
      <ImageOverlay
        url={
          isMobile
            ? getImageUrl(exhibition.base_map.base_image)
            : getImageUrl(exhibition.base_map.base_image)
        }
        bounds={[
          [0, 0],
          [
            exhibition.base_map.size[0] * 0.4,
            exhibition.base_map.size[1] * 0.4,
          ],
        ]}
      />

      {exhibition.exhibition_points &&
        exhibition.exhibition_points.map(
          (point: IExhibitionPoint, i: number) => {
            return (
              <OnlineExhibitionPoint
                key={`__exhibitioPoint_${i}__`}
                point={point}
                index={i}
                show_artworks_order={exhibition.show_artworks_order}
                artworksColor={artworksColor}
                artworksLabelColor={artworksLabelColor}
                artworksBackColor={artworksBackColor}
                selectedPointId={selectedPointId}
                verticalSize={exhibition.base_map.size[1]}
                setSelectedPointId={setSelectedPointId}
                setModalOpen={setModalOpen}
                setInitialIndex={setInitialIndex}
                popup={popup}
              />
            );
          }
        )}
      {exhibition.exhibition_points && exhibition.show_artworks_line && (
        <OnlineExhibitionRenderLines
          pointsList={exhibition.exhibition_points}
          artworksLineColor={exhibition.artworks_line_color}
          markersArray={markersArray}
        />
      )}
    </Map>
  );
}

export default compose<Props, IOnlineExhibitionMapProps>(
  withStyles(styles),
  withWidth()
)(OnlineExhibitionMap);
