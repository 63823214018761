import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { IPost } from '../../types/posts';
import { Grid, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ProfileSmall from '../../library/dataDisplay/profile/ProfileSmall';
import { getImageUrl } from '../../utils/imageUrl';
import { compose } from 'recompose';
import PostFullSkeleton from './PostFullSkeleton';
import Title from '../../library/dataDisplay/typography/Title';
import SubTitle from '../../library/dataDisplay/typography/SubTitle';
import Text from '../../library/dataDisplay/typography/Text';
import Tags from '../tags/Tags';

import ReactPlayer from 'react-player';
import { ReactTinyLink } from 'react-tiny-link';
import { dateToStringParser } from '../../utils/datetimehelper';
import ProfileInstitutionSmall from '../institution/ProfileInstitutionSmall';
import { ICuratorUser } from '../../types/user';
import { RouteComponentProps, withRouter } from 'react-router';
import { getExhibitionArtists } from '../../utils/exhibition';

export interface IPostProps {
  post: IPost;
  onAuthorClick: () => void;
  onLike: (post: IPost) => void;
  liking: (post: IPost) => boolean;
  onTagClick: (id: string) => void;
  onCommentClick: () => void;
  onShareClick: () => void;
}

interface IState {
  openPressRelease?: boolean;
}

const styles = createStyles({
  text: {
    fontSize: 16,
    overflowWrap: 'break-word',
  },
  textPressRelease: {
    fontSize: 16,
    paddingTop: 10,
    width: '80%',
    color: '#6d7278',
  },
  image: {
    width: '100%',
  },
  margin: {
    marginTop: 10,
    marginBottom: 10,
  },
  title: {
    paddingTop: 20,
    fontSize: 16,
  },
  titleFont: {
    fontSize: 16,
    color: '#afb0b3',
  },
  creditText: {
    textAlign: 'center',
    fontSize: 12,
    color: '#afb0b3',
  },

  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
    /* 720 / 1280 = 0.5625 */
  },

  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

type Props = IPostProps & WithStyles<string> & RouteComponentProps<{}>;

class FullPost extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openPressRelease: false,
    };
  }

  public showURL = (error: Error) => {
    window.console.log(error);
  };

  public render() {
    const {
      post,
      onAuthorClick,
      classes,
      liking,
      onLike,
      onTagClick,
      onCommentClick,
      onShareClick,
    } = this.props;

    let featured;
    let content;
    let additionnalContent;

    const renderCuratedBy = () => {
      const createdBy = post.created_by || undefined;

      if (!renderCuratedBy) {
        return;
      }
      const curatedByText =
        post.sub_type === 'EXHIBITION' ||
        post.sub_type === 'ARTPROJECT' ||
        post.sub_type === 'ONLINEEXHIBITION' ||
        post.sub_type === 'PUBLICATION'
          ? 'Curated by:'
          : 'Shared by:';

      const curatorsList: any[] = [];
      if (post.curated_by) {
        post.curated_by.map((curator) => {
          curatorsList.push(curator);
        });
      }

      // If the creator is a curator, add him/her to curatorsList
      if (
        createdBy &&
        createdBy.profile === 'CURATOR' &&
        !curatorsList.some((curator) => curator.id === createdBy.id)
      ) {
        curatorsList.unshift(createdBy);
      }

      return curatorsList.length > 0 ? (
        <React.Fragment>
          <Text className={classes.text}>{`${curatedByText} `}</Text>
          {curatorsList.map((curator: ICuratorUser, i: number) => {
            const handleCuratorClick = () => {
              this.props.history.push(`/users/${curator.url}/posts`);
            };

            return (
              <React.Fragment key={'curator' + i + '_' + curator.id}>
                <Text className={classes.text}>{i ? ', ' : ''}</Text>
                <Text className={classes.text} onClick={handleCuratorClick}>
                  {curator.firstName} {curator.lastName}
                </Text>
              </React.Fragment>
            );
          })}
        </React.Fragment>
      ) : (
        <></>
      );
    };

    const renderArtistsList = () => {
      return (
        <div>
          {getExhibitionArtists(post).map((artist: any, i: any) => {
            if (artist.url) {
              const handleArtistClick = () => {
                this.props.history.push(`/users/${artist.url}/posts`);
              };
              return (
                <Text
                  className={classes.text}
                  onClick={handleArtistClick}
                  key={'_artist_' + i + '_'}
                >
                  {(i ? ', ' : '') + artist.name}
                </Text>
              );
            }

            return (
              <Text className={classes.text} key={'_artist_' + i + '_'}>
                {(i ? ', ' : '') + artist.name}
              </Text>
            );
          })}
        </div>
      );
    };

    switch (post.type) {
      case 'ARTICLE':
        content = 'article';
        break;
      case 'EXHIBITION':
      case 'ARTPROJECT':
        if (post.featured_imageWithCredit) {
          featured = (
            <>
              <Grid item={true} xs={12} key={'main_image'}>
                <img
                  className={classes.image}
                  src={getImageUrl(post.featured_imageWithCredit.image_url)}
                />
                <p className={classes.creditText}>
                  {post.featured_imageWithCredit.image_credit}
                </p>
              </Grid>
            </>
          );
        } else {
          featured = (
            <img
              className={classes.image}
              src={getImageUrl(post.featured_image)}
            />
          );
        }

        const postVenue =
          post.venue && typeof post.venue === 'string'
            ? post.venue
            : post.venue_name;

        let mask = 0;

        mask = post.start_date ? mask | 1 : mask;
        mask = post.end_date ? mask | 2 : mask;
        mask = postVenue ? mask | 4 : mask;

        content = (
          <div>
            <div>
              <Title className={classes.text}>{post.title}</Title>
            </div>

            <div>
              {(mask === 1 || mask === 5) && (
                <>
                  <SubTitle className={classes.text}>
                    Start Date{' '}
                    {dateToStringParser(post.start_date, 'MMM Do YYYY')}{' '}
                  </SubTitle>
                </>
              )}
              {(mask === 2 || mask === 6) && (
                <>
                  <SubTitle className={classes.text}>
                    End Date {dateToStringParser(post.end_date, 'MMM Do YYYY')}{' '}
                  </SubTitle>
                </>
              )}
              {(mask === 3 || mask === 7) && (
                <>
                  <SubTitle className={classes.text}>
                    {dateToStringParser(post.start_date, 'MMM Do YYYY')} -{' '}
                    {dateToStringParser(post.end_date, 'MMM Do YYYY')}
                  </SubTitle>
                </>
              )}
            </div>
            {mask >= 4 && (
              <div>
                {post.venue && post.venue.placeId ? (
                  <a
                    href={`https://www.google.com/maps/place/?q=place_id:${post.venue.placeId}`}
                    target="_blank"
                  >
                    <SubTitle className={classes.text}>{postVenue}</SubTitle>
                  </a>
                ) : (
                  <SubTitle className={classes.text}>{postVenue}</SubTitle>
                )}
              </div>
            )}
            {renderArtistsList()}
            {renderCuratedBy()}
            <div>
              {post.co_curator_list &&
                post.co_curator_list.length > 0 &&
                post.co_curator_list.map((coCurator, i) => {
                  if (i === 0 && coCurator !== '') {
                    return (
                      <Text className={classes.text} key={coCurator}>
                        Co-curated with{' '}
                        {`${coCurator}${
                          i + 1 < post.co_curator_list!.length ? ', ' : ''
                        }`}
                      </Text>
                    );
                  }
                  return (
                    <Text className={classes.text} key={coCurator}>
                      {`${coCurator}${
                        i + 1 < post.co_curator_list!.length ? ', ' : ''
                      }`}
                    </Text>
                  );
                })}
            </div>
          </div>
        );
        additionnalContent = [
          <Grid item={true} xs={12} key={1}>
            <Title className={classes.text}>{post.description}</Title>
          </Grid>,
        ];

        if (post.press_release) {
          const { openPressRelease } = this.state;

          const onClickEvent = (evt: any) => {
            this.setState({ openPressRelease: !openPressRelease });
          };

          additionnalContent.push(
            <Grid
              container={true}
              key={'press_release'}
              style={{
                margin: '20px 0',
                backgroundColor: '#fafbfb',
              }}
            >
              <Grid container={true} onClick={onClickEvent}>
                <Grid item={true} xs={11}>
                  <Title className={classes.textPressRelease}>
                    Press release
                  </Title>
                </Grid>
                <Grid
                  item={true}
                  xs={1}
                  className={classes.textPressRelease}
                  alignContent={'flex-end'}
                >
                  {openPressRelease ? <ExpandLess /> : <ExpandMore />}
                </Grid>
              </Grid>
              <Grid container={true}>
                <Grid item={true} xs={12}>
                  <Collapse timeout="auto" in={openPressRelease}>
                    <Title className={classes.title}>
                      {post.press_release}
                    </Title>
                  </Collapse>
                </Grid>
              </Grid>
            </Grid>
          );
        }

        if (post.other_contributors_list) {
          additionnalContent.push(
            <Grid
              item={true}
              xs={12}
              key={'otherContributors'}
              style={{ margin: '20px 0' }}
            >
              {post.other_contributors_list &&
                post.other_contributors_list.length > 0 &&
                post.other_contributors_list.map((otherContributors, i) => {
                  if (i === 0 && otherContributors !== '') {
                    return (
                      <Text className={classes.text} key={otherContributors}>
                        Contributors:{' '}
                        {`${otherContributors}${
                          i + 1 < post.other_contributors_list!.length
                            ? ', '
                            : ''
                        }`}
                      </Text>
                    );
                  }
                  return (
                    <Text className={classes.text} key={otherContributors}>
                      {`${otherContributors}${
                        i + 1 < post.other_contributors_list!.length ? ', ' : ''
                      }`}
                    </Text>
                  );
                })}
            </Grid>
          );
        }

        additionnalContent = additionnalContent.concat([
          <Tags
            onTagClick={onTagClick}
            tags={post.tags}
            key={'tags'}
            style={
              post.tags.length
                ? {
                    margin: '20px 0',
                    fontSize: 16,
                  }
                : {}
            }
          />,
        ]);

        if (post.links) {
          const linksList = post.links.map((link, index) => {
            if (!link.link_url.includes('http')) {
              link.link_url = 'http://' + link.link_url;
            }

            const regexURLFormula =
              /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

            const onErrorEvt = () => {
              // window.console.log("onErrorEvt")
              return (
                <Grid
                  item={true}
                  xs={12}
                  key={`link_${index}`}
                  style={{ margin: '20px 0' }}
                >
                  <Title className={classes.titleFont}>{link.link_title}</Title>{' '}
                  <br />
                  <a href={link.link_url} target="_blank">
                    {' '}
                    {link.link_url}
                  </a>
                  <br />
                </Grid>
              );
            };

            // Case the url is miss-speld
            if (!regexURLFormula.test(link.link_url)) {
              return (
                <Grid
                  item={true}
                  xs={12}
                  key={`link_${index}`}
                  style={{ margin: '20px 0' }}
                >
                  <Title className={classes.titleFont}>{link.link_title}</Title>{' '}
                  <br />
                  <a href={link.link_url} target="_blank">
                    {' '}
                    {link.link_url}
                  </a>
                  <br />
                </Grid>
              );
            }
            return (
              <Grid
                item={true}
                xs={12}
                key={`link_${index}`}
                style={{ margin: '20px 0' }}
              >
                <Title className={classes.titleFont}>{link.link_title}</Title>{' '}
                <br />
                <ReactTinyLink
                  cardSize="small"
                  showGraphic={true}
                  maxLine={2}
                  minLine={1}
                  width={'100%'}
                  url={link.link_url}
                  // proxyUrl={"https://cors-anywhere.herokuapp.com"}
                  onError={onErrorEvt}
                />
                <br />
              </Grid>
            );
          });

          const linksSection = (
            <Grid
              item={true}
              xs={12}
              key={'links_section'}
              style={{ margin: '20px 0' }}
            >
              {linksList}
            </Grid>
          );
          additionnalContent.push(linksSection);
        }

        if (post.videos) {
          const linksList = post.videos.map((video, index) => {
            return (
              <Grid
                item={true}
                xs={12}
                key={`videos_${index}`}
                style={{ margin: '20px 0' }}
              >
                <Title className={classes.titleFont}>{video.video_title}</Title>{' '}
                <br />
                <div className="playerWrapper">
                  <ReactPlayer
                    className="reactPlayer"
                    url={video.video_link}
                    playing={false}
                    width="100%"
                    controls={true}
                    vimeo={{ playerOptions: { height: '263px' } }}
                  />
                  <br />
                </div>
              </Grid>
            );
          });

          const linksSection = (
            <Grid
              item={true}
              xs={12}
              key={'videos_section'}
              style={{ margin: '20px 0' }}
            >
              {linksList}
            </Grid>
          );
          additionnalContent.push(linksSection);
        }

        if (post.imagesWithCredit) {
          const images =
            post.imagesWithCredit && post.imagesWithCredit.length > 1
              ? post.imagesWithCredit.map((image, index) => {
                  if (index !== 0) {
                    return (
                      <>
                        <Grid
                          item={true}
                          xs={12}
                          key={image.id}
                          className={classes.centerImage}
                        >
                          <img
                            className={classes.image}
                            src={getImageUrl(image.image_url)}
                          />
                          <p className={classes.creditText}>
                            {image.image_credit}
                          </p>
                        </Grid>
                      </>
                    );
                  }
                  return <></>;
                })
              : [];

          additionnalContent = additionnalContent.concat(images);
        }

        if (post.images && !post.imagesWithCredit) {
          const images = post.images
            ? post.images.map((image) => {
                return (
                  <>
                    <Grid item={true} xs={12} key={image}>
                      <img className={classes.image} src={getImageUrl(image)} />
                    </Grid>
                  </>
                );
              })
            : [];

          additionnalContent = additionnalContent.concat(images);
        }

        break;
      case 'IMAGE':
        if (post.featured_imageWithCredit) {
          featured = (
            <>
              <Grid item={true} xs={12} key={'main_image'}>
                <img
                  className={classes.image}
                  src={getImageUrl(post.featured_imageWithCredit.image_url)}
                />
                <p className={classes.creditText}>
                  {post.featured_imageWithCredit.image_credit}
                </p>
              </Grid>
            </>
          );
        } else {
          featured = (
            <img
              className={classes.image}
              src={getImageUrl(post.featured_image)}
            />
          );
        }

        content = (
          <div>
            <Title className={classes.text}>{post.title}</Title>
            {post.start_date && (
              <SubTitle className={classes.text}>
                {dateToStringParser(post.start_date, 'MMM Do YYYY')}{' '}
              </SubTitle>
            )}
            {renderArtistsList()}
            {renderCuratedBy()}
          </div>
        );
        additionnalContent = [
          <Grid item={true} xs={12} key={1}>
            <Title className={classes.text}>{post.description}</Title>
          </Grid>,
        ];

        if (post.links) {
          const linksList = post.links.map((link, index) => {
            if (!link.link_url.includes('http')) {
              link.link_url = 'http://' + link.link_url;
            }
            // window.console.log( link.link_url)
            const regexURLFormula =
              /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

            // Case the url is miss-speld
            if (!regexURLFormula.test(link.link_url)) {
              return (
                <Grid
                  item={true}
                  xs={12}
                  key={`link_${index}`}
                  style={{ margin: '20px 0' }}
                >
                  <Title className={classes.titleFont}>{link.link_title}</Title>{' '}
                  <br />
                  <a href={link.link_url} target="_blank">
                    {' '}
                    {link.link_url}
                  </a>
                  <br />
                </Grid>
              );
            }
            return (
              <Grid
                item={true}
                xs={12}
                key={`link_${index}`}
                style={{ margin: '20px 0' }}
              >
                <Title className={classes.titleFont}>{link.link_title}</Title>{' '}
                <br />
                <ReactTinyLink
                  cardSize="small"
                  showGraphic={true}
                  maxLine={2}
                  minLine={1}
                  width={'100%'}
                  url={link.link_url}
                  // proxyUrl={"https://cors-anywhere.herokuapp.com"}
                />
                <br />
              </Grid>
            );
          });

          const linksSection = (
            <Grid
              item={true}
              xs={12}
              key={'links_section'}
              style={{ margin: '20px 0' }}
            >
              {linksList}
            </Grid>
          );
          additionnalContent.push(linksSection);
        }

        if (post.videos) {
          const linksList = post.videos.map((video, index) => {
            return (
              <Grid
                item={true}
                xs={12}
                key={`videos_${index}`}
                style={{ margin: '20px 0' }}
              >
                <Title className={classes.titleFont}>{video.video_title}</Title>{' '}
                <br />
                <ReactPlayer
                  url={video.video_link}
                  playing={false}
                  width="100%"
                  controls={true}
                  vimeo={{ playerOptions: { height: '263px' } }}
                />
                <br />
              </Grid>
            );
          });

          const linksSection = (
            <Grid
              item={true}
              xs={12}
              key={'videos_section'}
              style={{ margin: '20px 0' }}
            >
              {linksList}
            </Grid>
          );
          additionnalContent.push(linksSection);
        }

        if (post.imagesWithCredit) {
          const images = post.imagesWithCredit
            ? post.imagesWithCredit.map((image, index) => {
                if (index !== 0) {
                  return (
                    <>
                      <Grid item={true} xs={12} key={image.id}>
                        <img
                          className={classes.image}
                          src={getImageUrl(image.image_url)}
                        />
                        <p className={classes.creditText}>
                          {image.image_credit}
                        </p>
                      </Grid>
                    </>
                  );
                }
                return <></>;
              })
            : [];

          additionnalContent = additionnalContent.concat(images);
        }

        if (post.images && !post.imagesWithCredit) {
          const images = post.images
            ? post.images.map((image) => {
                return (
                  <>
                    <Grid item={true} xs={12} key={image}>
                      <img className={classes.image} src={getImageUrl(image)} />
                    </Grid>
                  </>
                );
              })
            : [];

          additionnalContent = additionnalContent.concat(images);
        }

        break;
      case 'STANDARD':
        content = (
          <div>
            <Title className={classes.text}>{post.title}</Title>
            {post.start_date && (
              <SubTitle className={classes.text}>
                {dateToStringParser(post.start_date, 'MMM Do YYYY')}{' '}
              </SubTitle>
            )}
            {renderArtistsList()}
            {renderCuratedBy()}
          </div>
        );
        additionnalContent = [
          <Grid item={true} xs={12} key={'title_key'}>
            <Title className={classes.text}>{post.description}</Title>
          </Grid>,
          <Tags
            onTagClick={onTagClick}
            tags={post.tags}
            key={'tags'}
            style={post.tags.length ? { margin: '20px 0', fontSize: 16 } : {}}
          />,
        ];

        if (post.links) {
          const linksList = post.links.map((link, index) => {
            if (!link.link_url.includes('http')) {
              link.link_url = 'http://' + link.link_url;
            }
            // window.console.log( link.link_url)
            const regexURLFormula =
              /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

            // Case the url is miss-speld
            if (!regexURLFormula.test(link.link_url)) {
              return (
                <Grid
                  item={true}
                  xs={12}
                  key={`link_${index}`}
                  style={{ margin: '20px 0' }}
                >
                  <Title className={classes.titleFont}>{link.link_title}</Title>{' '}
                  <br />
                  <a href={link.link_url} target="_blank">
                    {' '}
                    {link.link_url}
                  </a>
                  <br />
                </Grid>
              );
            }
            return (
              <Grid
                item={true}
                xs={12}
                key={`link_${index}`}
                style={{ margin: '20px 0' }}
              >
                <Title className={classes.titleFont}>{link.link_title}</Title>{' '}
                <br />
                <ReactTinyLink
                  cardSize="small"
                  showGraphic={true}
                  maxLine={2}
                  minLine={1}
                  width={'100%'}
                  url={link.link_url}
                  // proxyUrl={"https://cors-anywhere.herokuapp.com"}
                />
                <br />
              </Grid>
            );
          });

          const linksSection = (
            <Grid
              item={true}
              xs={12}
              key={'links_section'}
              style={{ margin: '20px 0' }}
            >
              {linksList}
            </Grid>
          );
          additionnalContent.push(linksSection);
        }

        if (post.videos) {
          const [mainVideo, ...otherVideos] = post.videos;
          if (mainVideo) {
            featured = (
              <>
                <Grid item={true} xs={12} key={'main_image'}>
                  <ReactPlayer
                    url={mainVideo.video_link}
                    playing={false}
                    width="100%"
                    controls={true}
                    vimeo={{ playerOptions: { height: '263px' } }}
                  />
                  <br />
                </Grid>
              </>
            );
          }

          const linksList = otherVideos.map((video, index) => {
            return (
              <Grid
                item={true}
                xs={12}
                key={`videos_${index}`}
                style={{ margin: '20px 0' }}
              >
                <Title className={classes.titleFont}>{video.video_title}</Title>{' '}
                <br />
                <ReactPlayer
                  url={video.video_link}
                  playing={false}
                  width="100%"
                  controls={true}
                  vimeo={{ playerOptions: { height: '263px' } }}
                />
                <br />
              </Grid>
            );
          });

          const linksSection = (
            <Grid
              item={true}
              xs={12}
              key={'videos_section'}
              style={{ margin: '20px 0' }}
            >
              {linksList}
            </Grid>
          );
          additionnalContent.push(linksSection);
        }

        break;
      case 'VIDEO':
        content = 'video';
        break;
    }

    return (
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <div onClick={onAuthorClick}>
            {!post.venue_id && <ProfileSmall user={post.created_by} />}
            {post.venue_id && (
              <ProfileInstitutionSmall institution={post.venue_id} />
            )}
          </div>

          <PostFullSkeleton
            onShareClick={onShareClick}
            onCommentClick={onCommentClick}
            onLike={onLike}
            liking={liking}
            post={post}
            content={content}
            featured={featured}
            isDraft={post.status === 'DRAFT'}
            additionnalContent={additionnalContent}
          />
        </Grid>
      </Grid>
    );
  }
}

export default compose<Props, IPostProps>(
  withStyles(styles),
  withRouter
)(FullPost);
