import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { Grid, Alert, AlertTitle } from '@mui/material';
import {
  IWithAccountProps,
  withAccount,
} from '../../containers/account/InjectAccount';
import { IOnlineExhibitionInitialValue } from '../../containers/onlineexhibition/OnlineExhibitionForm';
import { InjectedFormikProps, Field, FieldArray, ErrorMessage } from 'formik';
import { DARK_GREY_COLOR, EXHIBITION_BREAKPOINT } from '../../styles/constants';
import FormMessage from '../../components/form/FormMessage';
import TextInput from '../../components/form/TextInput';
import FormTagWrapper from '../../containers/tags/FormTagWrapper';
import BaseMapUpload from '../form/BaseMapUpload';
import MultipleFilesUpload from '../form/MultipleFilesUpload';
import VideoLinkInput from '../form/VideoLinkInput';
import LinkInput from '../form/LinkInput';
import { useEffect, useState } from 'react';
import SocialModal from '../../containers/social/SocialModal';
import DesignedButton from '../../library/inputs/DesignedButton';
import OnlineExhibitionFormHeader from './OnlineExhibitionFormHeader';
import SingleFileUploader from '../form/SingleFileUploader';
import Loading from '../loading/Loading';

import FormUserNameWrapper from '../form/FormUserNameWrapper';
import InviteButton from '../../library/inputs/InviteButton';
import { ICuratorUser } from '../../types/user';
import { ComponentType } from 'aws-sdk/clients/imagebuilder';

export interface IOnlineExhibitionFormComponentProps {
  isUpdate: boolean;
  onExhibitionCancel: () => void;
  error?: any;
}

const styles = createStyles({
  root: {
    flexGrow: 1,
  },
  headerSection: {
    margin: 0,
    paddingBottom: 20,
  },
  formContainerDesktop: {
    // paddingTop: 160,
    // paddingTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
  formContainerMobile: {
    // paddingTop: 185,
    // paddingTop: 25,
    paddingLeft: 10,
    paddingRight: 10,
  },
  sectionSpacing: {
    paddingTop: 50,
  },
});

type Props = WithStyles<string> &
  IWithAccountProps &
  WithWidth &
  IOnlineExhibitionFormComponentProps &
  InjectedFormikProps<
    IOnlineExhibitionFormComponentProps,
    IOnlineExhibitionInitialValue
  >;

const OnlineExhibitionFormComponent: React.FC<Props> = (props) => {
  const {
    classes,
    initialValues,
    handleSubmit,
    error,
    width,
    values,
    isUpdate,
    onExhibitionCancel,
    resetForm,
    account,
  } = props;

  // const [openVisibility, setOpenVisibility] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [openConfigBanner, setOpenConfigBanner] = useState(!props.isUpdate);
  const [loadingValues, setLoadingValues] = useState(false);

  const imageError = undefined;

  const isExhibitionBreakpoint = isWidthDown(EXHIBITION_BREAKPOINT, width);
  const isMobile = isWidthDown('xs', width);

  const onlineExhibitionUrl = {
    title: values.title,
    url:
      values.visibility !== 'PUBLIC'
        ? `online-exhibition/${values.url}?key=${values.access_key}`
        : `online-exhibition/${props.values.url}`,
  };

  const handleBannerOpen = (isOpen: boolean) => {
    setOpenConfigBanner(isOpen);
  };

  const fileImagesWithCreditRenderer = (propsObj: any) => (
    <MultipleFilesUpload {...propsObj} label="Upload More Images" />
  );
  const fileFeaturedImageWithCreditRenderer = (propsObj: any) => {
    return <SingleFileUploader {...propsObj} label="Upload a Cover Picture*" />;
  };

  const onShareClick = () => {
    setShareDialogOpen(true);
  };

  const shareAndSubmit = () => {
    values.isDraft = false;
    initialValues.isDraft = false;
    handleSubmit();
    if (initialValues && initialValues.url) {
      initialValues.isDraft = false;
      values.isDraft = false;
      if (!isUpdate) {
        onShareClick();
      }
    }
  };

  const saveAsDraft = () => {
    values.isDraft = true;
    initialValues.isDraft = true;
    handleSubmit();
  };

  const onShareClose = () => setShareDialogOpen(false);

  const onExhibitionPreview = () => {
    if (initialValues && initialValues.url) {
      window.open('/online-exhibition/' + initialValues.url, '_blank'); // to open new page
    }
  };

  useEffect(() => {
    if (isUpdate) {
      setLoadingValues(true);
    }

    return () => {
      resetForm();
    };
  }, []);

  useEffect(() => {
    if (isUpdate && values.id) {
      setLoadingValues(false);
    }
  }, [values.id]);

  if (loadingValues) {
    return <Loading />;
  }

  let curator: ICuratorUser | undefined;
  if (!isUpdate && account.profile === 'CURATOR') {
    curator = account;
  } else if (values.created_by && values.created_by.profile === 'CURATOR') {
    curator = values.created_by;
  } else {
    curator = undefined;
  }

  return (
    <form onSubmit={handleSubmit}>
      <OnlineExhibitionFormHeader
        handleBannerOpen={handleBannerOpen}
        openConfigBanner={openConfigBanner}
        handleSubmit={handleSubmit}
        isSubmitting={props.isSubmitting}
        isUpdate={isUpdate}
        onExhibitionPreview={onExhibitionPreview}
        saveAsDraft={saveAsDraft}
        values={values}
        shareAndSubmit={shareAndSubmit}
      />
      <div
        className={
          isExhibitionBreakpoint
            ? classes.formContainerMobile
            : classes.formContainerDesktop
        }
        style={{ paddingTop: openConfigBanner ? 420 : isMobile ? 185 : 160 }}
      >
        <FormMessage
          message={
            imageError
              ? imageError
              : 'There is an unexpected error, please try again.'
          }
          visible={!!(error || imageError)}
          type={'ERROR'}
        />
        <FormMessage
          message={
            error ? error : 'There is an unexpected error, please try again.'
          }
          visible={!!(error || imageError)}
          type={'ERROR'}
        />

        <Grid container={true}>
          <Grid item={true} sm={2} />
          <Grid item={true} xs={12} sm={8}>
            <Grid container={true} spacing={1}>
              {/* Title */}
              <Grid item={true} xs={12}>
                <Field
                  name="title"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'title',
                    label: 'Exhibition Title*',
                    type: 'title',
                  }}
                />
              </Grid>
              {/* Start date */}
              <Grid item={true} xs={6}>
                <Field
                  name="start_date"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'start_date',
                    label: 'Start Date',
                    type: 'date',
                  }}
                />
              </Grid>
              {/* End date */}
              <Grid item={true} xs={6}>
                <Field
                  name="end_date"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'end_date',
                    label: 'End Date',
                    type: 'date',
                  }}
                />
              </Grid>
              {/* curated_by */}
              <Grid item={true} xs={12}>
                <Field
                  name={'curated_by'}
                  defaultUser={curator}
                  component={FormUserNameWrapper}
                  inputProps={{
                    id: 'curatedBy',
                    label: 'Curated by',
                    type: 'curators',
                  }}
                />
              </Grid>

              {/* co_curator_list */}
              <Grid item={true} xs={9}>
                <Field
                  name="co_curator_list"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'co_curator_list',
                    label:
                      'Co-curators who are not on our platform (please separate the names with a comma)',
                    type: 'co-curators',
                  }}
                />
              </Grid>
              <Grid item={true} xs={3}>
                <InviteButton profileInvited={'Curators'} fullWidth={true} />
              </Grid>
              {/* Featured imageWithCredit */}
              <Grid item={true} xs={12} className={classes.root}>
                <Field
                  name="featured_imageWithCredit"
                  render={fileFeaturedImageWithCreditRenderer}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container={true} className={classes.sectionSpacing}>
          <Grid item={true} sm={2}>
            <h3 className={classes.headerSection}>Curatorial text</h3>
          </Grid>
          <Grid item={true} xs={12} sm={8}>
            <Grid container={true} spacing={1}>
              {/* Curatorial text */}
              <Grid item={true} xs={12}>
                <Field
                  name="description"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'description',
                    label: 'Curatorial Text*',
                    type: 'description',
                    multiline: true,
                    rows: 5,
                    maxRows: 20,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/*Base Map*/}
        {!isExhibitionBreakpoint ? (
          <FieldArray name="base_map" component={BaseMapUpload as any} />
        ) : (
          <p style={{ color: DARK_GREY_COLOR }}>
            * Map editing is only available in desktop
          </p>
        )}

        <Grid container={true} className={classes.sectionSpacing}>
          <Grid item={true} sm={2}>
            <h3 className={classes.headerSection}>Press Release</h3>
          </Grid>
          <Grid item={true} xs={12} sm={8}>
            <Grid container={true} spacing={1}>
              {/* press_release */}
              <Grid item={true} xs={12}>
                <Field
                  name="press_release"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'press_release',
                    label: 'Press release',
                    type: 'press_release',
                    multiline: true,
                    rows: 5,
                    maxRows: 20,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container={true} className={classes.sectionSpacing}>
          <Grid item={true} sm={2}>
            <h3 className={classes.headerSection}>More Information</h3>
          </Grid>
          <Grid item={true} xs={12} sm={8}>
            <Grid container={true} spacing={1}>
              {/* other_contributors_list */}
              <Grid item={true} xs={12}>
                <Field
                  name="other_contributors_list"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'other_contributors_list',
                    label:
                      'Other Contributors (please separate the names with a comma)',
                    type: 'artists',
                  }}
                />
              </Grid>
              {/* acknowledgement_text */}
              <Grid item={true} xs={12}>
                <Field
                  name="acknowledgement_text"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'acknowledgement_text',
                    label: 'Acknowledgement text',
                    type: 'description',
                    multiline: true,
                    rows: 5,
                    maxRows: 20,
                  }}
                />
              </Grid>
              {/* videos */}
              <Grid item={true} xs={12} className={classes.root}>
                <FieldArray name="videos" component={VideoLinkInput as any} />
              </Grid>
              {/* links */}
              <Grid item={true} xs={12} className={classes.root}>
                <FieldArray name="links" component={LinkInput as any} />
              </Grid>
              {/* imagesWithCredit */}
              <Grid item={true} xs={12} className={classes.root}>
                <FieldArray
                  name="imagesWithCredit"
                  render={fileImagesWithCreditRenderer}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* ALERT WARNING */}
        {Object.keys(props.errors).length !== 0 && (
          <Grid container={true} className={classes.sectionSpacing}>
            <Grid item={true} sm={2} />
            <Grid item={true} xs={12} sm={8}>
              <Grid container={true} spacing={1}>
                <Grid
                  item={true}
                  xs={12}
                  className={classes.root}
                  style={{ paddingTop: 40 }}
                >
                  <Alert severity="error">
                    <AlertTitle>Something is missing</AlertTitle>
                    Please check below:
                    <br />
                    <ErrorMessage name="title">
                      {(msg) => (
                        <>
                          <b>Title: </b> {msg}
                          <br />
                        </>
                      )}
                    </ErrorMessage>
                    <ErrorMessage name="description">
                      {(msg) => (
                        <>
                          <b>Description: </b> {msg}
                          <br />
                        </>
                      )}
                    </ErrorMessage>
                    <ErrorMessage name="featured_imageWithCredit">
                      {(msg) => (
                        <>
                          <b>Exhibition Featured Image: </b> An image is
                          required to save the exhibition
                          <br />
                        </>
                      )}
                    </ErrorMessage>
                    <ErrorMessage name="featured_imageWithCredit.image_credit">
                      {(msg) => (
                        <>
                          <b>Exhibition Featured Image: </b> The image credit is
                          required to save the exhibition
                          <br />
                        </>
                      )}
                    </ErrorMessage>
                    <ErrorMessage name="imagesWithCredit">
                      {(msg) => (
                        <>
                          <b>Images: </b> The credit field is required <br />
                        </>
                      )}
                    </ErrorMessage>
                    {/*<ErrorMessage name="exhibition_points">*/}
                    {/*    {msg => <><b>Exhibition Points: </b> {JSON.stringify(msg)}<br/></>*/}
                    {/*    }</ErrorMessage>*/}
                  </Alert>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {/* Buttons*/}
        <Grid container={true} className={classes.sectionSpacing}>
          <Grid item={true} sm={2} />
          <Grid item={true} xs={12} sm={8}>
            <Grid container={true} spacing={1}>
              <Grid
                item={true}
                xs={12}
                sm={6}
                md={3}
                style={{ order: isMobile ? 2 : 0 }}
              >
                <DesignedButton
                  fullWidth={true}
                  size="large"
                  ACGType="neutral"
                  onClick={onExhibitionCancel}
                >
                  Cancel
                </DesignedButton>
              </Grid>
              <Grid
                item={true}
                xs={12}
                sm={6}
                md={3}
                style={{
                  marginLeft: 'auto',
                  order: isMobile ? 1 : 0,
                  marginBottom: 80,
                }}
              >
                <DesignedButton
                  fullWidth={true}
                  size="large"
                  ACGType="neutral"
                  disabled={props.isSubmitting}
                  onClick={saveAsDraft}
                >
                  {(values.isDraft || !isUpdate) && <>Save as Draft</>}
                  {!values.isDraft && isUpdate && <>Switch to Draft</>}
                </DesignedButton>
              </Grid>
              <Grid
                item={true}
                xs={12}
                md={3}
                style={{ order: isExhibitionBreakpoint ? -1 : 0 }}
              >
                {/* <FormSubmit disabled={props.isSubmitting} onClick={shareAndSubmit}>sdsd</FormSubmit> */}
                <DesignedButton
                  fullWidth={true}
                  size="large"
                  ACGType="main"
                  disabled={props.isSubmitting}
                  onClick={shareAndSubmit}
                >
                  {(values.isDraft || !isUpdate) && 'Post Now!'}
                  {!values.isDraft && isUpdate && 'Update!'}
                </DesignedButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <SocialModal
        open={shareDialogOpen}
        onClose={onShareClose}
        object={{
          type: 'URL',
          object: onlineExhibitionUrl,
          showIframeOption: true,
          // showIframeOption: values.visibility !== 'PRIVATE'
        }}
      />
    </form>
  );
};

export default compose<Props, IOnlineExhibitionFormComponentProps>(
  withStyles(styles),
  withAccount,
  withWidth()
)(OnlineExhibitionFormComponent);
