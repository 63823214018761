export function convertHex2Rgb(hex: string, opacity: number): string {
  const formattedHex = hex.replace('#', '');
  const r = parseInt(formattedHex.substring(0, 2), 16);
  const g = parseInt(formattedHex.substring(2, 4), 16);
  const b = parseInt(formattedHex.substring(4, 6), 16);
  return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
}

export function setColorOpacity(color: string, opacity: number): string {
  if (color.startsWith('#')) {
    return convertHex2Rgb(color, opacity);
  } else if (color.startsWith('rgb')) {
    let newColor = color.replace('rgb', 'rgba');
    newColor = color.replace(')', `, ${opacity / 100})`);
    return newColor;
  }
  return '';
}
