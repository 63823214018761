import * as React from 'react';

export interface IIconOnlineExhibitionProps {
  fill?: string;
  height?: number;
  style?: any;
}

const IconOnlineExhibition = (props: IIconOnlineExhibitionProps) => {
  const style: React.CSSProperties = {
    height: props.height || 40,
    backgroundColor:
      (props.style && props.style.backgroundColor) || 'transparent',
    borderRadius: (props.style && props.style.borderRadius) || 6,
    margin: (props.style && props.style.margin) || 0,
  };

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
      fill={props.fill || 'black'}
      style={style}
    >
      <path d="M111.73,889.31l50.71-88.15L174.62,780l3.35-5.82.77-1.33.14-.25a5.72,5.72,0,0,1,.34-.55c.45-.7.44-.69,0,0q1.57-1.46.36-.39c.12-.12,1.79-1.51.37-.34.65-.54,1.71-.5.36-.3a5.27,5.27,0,0,1,1.26,0h712c1.41,0,2.83,0,4.25,0h.57c1.89,0,1.47.08.83,0-1.64-.13,2.42.58.1.06,1,.23,1.49.57.52.16l.87.84c-.45-.72-.45-.73,0,0,.26.37.47.77.7,1.16,7.3,12.35,14.3,24.88,21.45,37.32l32.39,56.35,10.6,18.44c.74,1.28,1.57,2.55,2.23,3.87,0,.07.08.14.11.21-1-2.11.3,2.3-.06-.59a13,13,0,0,1,0,2.16c.1-2.6-.2.36-.43,1,.57-1.54.47-.19-.16,0-.67,1-.77,1.17-.31.48s.32-.56-.49.34l.76-.56a11.51,11.51,0,0,1-1.39.82l-.47.26c1.18-.52,1.1-.55-.25-.12,1.3.48.75,0-.26,0H762.36l-127.15,0-136.42,0-132.6,0h-251c-.52,0-1.06,0-1.57,0,1.46.16.45.42-.15-.21,1.37,1.45,0,.06-.57-.39l-.46-.29c1,.8,1,.74,0-.18.2,1.3,1.38.31,0-.07l-.53-.92c.41,1.11.48,1.25.2.43-.4-.66-.34-2.88-.26-.46a14.28,14.28,0,0,1,0-2.17c-.38,3.09,1-1.71-.07.62,2.69-5.89,1.68-12.92-4.31-16.42-5.16-3-13.71-1.62-16.42,4.31-4.28,9.38-4.46,19.84,1.86,28.43A27.51,27.51,0,0,0,114.78,917c.74,0,1.48,0,2.22,0h80.94L301,917H428.46l137.33,0,135.7,0H821.07l90.7,0h48.7c1.32,0,2.64,0,4,0A27.66,27.66,0,0,0,987,905.75c7-9.45,6-21.18.44-30.91L978,858.34c-18.55-32.27-36.88-64.68-55.65-96.81-5.21-8.92-13.71-14.22-24.11-14.54-1.07,0-2.15,0-3.22,0H187.63c-9.34,0-18.14.83-25.39,8.11-3.53,3.55-5.86,8.47-8.34,12.78l-12.37,21.5-33.11,57.55L91,877.2a12,12,0,0,0,20.73,12.11Z" />
      <path d="M895,285.06V724.67c0,6.78-.1,13.57,0,20.35,0-2.15.13-1-.07-.23s-.93,1.57.16-.14c-.6,1-1.66,2.07-.28.76-.46.44-.89.92-1.35,1.36.34-.33,2-1.29.09-.19-.2.12-1.65.8-.31.24,1.53-.66-1.05.19-1.37.29,1.44-.45,1-.1,0-.12h-704c-.18,0,0-.08-.52,0-1.31.14.39,0,.68.14-.62-.19-1.28-.33-1.91-.52,2.66.82-1.23-1-.14-.17,1.38,1.06-.63-.46-.57-.66-.45-.5-.44-.46,0,.11s.47.6.1,0c-.22,0-1.18-2.33-.5-.72.57,1.34,0-.17-.06-.4-.68-2.42-.16,1.67-.11-.8v-.78c0-1.06,0-2.13,0-3.19V266.6c0-1,0-2,0-3v-.59c0-.36,0-.75,0-1.08,0,.31-.52,1.87,0,.71.14-.34.8-2.35.18-.89s.12-.1.24-.31c1.55-2.64-.64,1.29.14-.26.2-.39.74-.69,1-1-1.37,1.46-.8.59-.09.18s1.74-.49-.23,0a8.56,8.56,0,0,0,1.42-.41c1.22-.51-2.38,0,.35,0H870.32c6.28,0,12.29-5.52,12-12s-5.27-12-12-12H196.74c-2.54,0-5.08,0-7.61,0-12.43,0-23.39,7.23-27.16,19.36-2.36,7.58-1.11,17.31-1.11,25.1V741.37c0,8.53,2.31,16.79,9,22.67,5.7,5,12.74,7,20.13,7h701c12.7-.06,24.45-8,27.36-20.79,1.81-7.91.74-17.05.74-25.06V285.06c0-6.27-5.52-12.29-12-12s-12,5.28-12,12Z" />
      <path d="M595.32,603.16l3.36-3.88,9.17-10.62L621.36,573l16.49-19.08,18-20.9,18.31-21.19,17-19.68,14.37-16.63,10.38-12c1.63-1.89,3.27-3.77,4.89-5.66l.21-.25a12.74,12.74,0,0,0,3.52-8.48,12.21,12.21,0,0,0-3.52-8.49,12,12,0,0,0-8.48-3.51c-3.05.13-6.42,1.12-8.49,3.51l-3.35,3.88-9.18,10.62L678,470.79l-16.48,19.08-18.06,20.9L625.2,532l-17,19.67-14.37,16.64-10.37,12-4.9,5.66-.21.25a12.78,12.78,0,0,0-3.51,8.48,12.25,12.25,0,0,0,3.51,8.49,12.09,12.09,0,0,0,8.49,3.51c3-.13,6.42-1.12,8.48-3.51Z" />
      <path d="M356.88,457.72l3.39,3.88,9.27,10.62,13.66,15.63,16.66,19.07,18.25,20.89L436.62,549l17.18,19.67,14.52,16.61,10.45,12c1.65,1.9,3.28,3.82,5,5.7l.21.24c1.94,2.22,5.62,3.51,8.48,3.51a12.25,12.25,0,0,0,8.49-3.51,12,12,0,0,0,3.51-8.49,13.23,13.23,0,0,0-3.51-8.48l-3.39-3.88-9.27-10.62L474.6,556.06,457.94,537,439.69,516.1l-18.51-21.17L404,475.27l-14.52-16.62-10.45-12c-1.65-1.9-3.28-3.82-5-5.69l-.21-.25c-1.94-2.22-5.62-3.51-8.49-3.51a12.24,12.24,0,0,0-8.48,3.51,12.1,12.1,0,0,0-3.52,8.49,13.27,13.27,0,0,0,3.52,8.48Z" />
      <circle cx="319.95" cy="393.99" r="36.84" />
      <circle cx="539.91" cy="649" r="36.84" />
      <circle cx="760.19" cy="393.99" r="36.84" />
    </svg>
  );
};

export default IconOnlineExhibition;
