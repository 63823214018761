import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import {
  Body1,
  clippedCorners,
  Subtitle1,
  Subtitle2,
  StyledButton,
} from '@acg/frontend-ui-artpool';
import { ALTERNATIVE, CertifiedIcon, LocationIcon, themes } from '@acg/assets';
import { useState } from 'react';
import { JsonUserOrVenue } from '@acg/artpool-api-spec';
import useAsyncEffect from 'use-async-effect';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import { artpoolApiClient } from '../../../services/ArtpoolApiClient';
import { ACGAppPaths } from '@acg/shared/const';

const CuratorThumbnail = (props: { curator: JsonUserOrVenue }) => {
  const { curator } = props;
  return (
    <a href={curator.acgPostsUrl} target={'_blank'}>
      <Grid container spacing={2} key={curator.name}>
        <Grid item>
          <img
            src={curator.profilePicture}
            style={{
              borderRadius: '50%',
              height: 140,
              width: 140,
              objectFit: 'cover',
            }}
          />
        </Grid>
        <Grid item alignSelf={'center'}>
          <Subtitle1>{curator.name}</Subtitle1>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <CertifiedIcon />
            <Body1>Curator</Body1>
          </Stack>
          {curator.location && (
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <LocationIcon />
              <Body1>{curator.location}</Body1>
            </Stack>
          )}
        </Grid>
      </Grid>
    </a>
  );
};

export const CuratorHighlight = () => {
  const [curators, setCurators] = useState<JsonUserOrVenue[]>([]);
  const artpoolApi = artpoolApiClient();
  const isSmall = useMediaQuery(themes.artpoolTheme.breakpoints.down('md'));

  useAsyncEffect(async () => {
    const curatorsResp = await artpoolApi.usersApi().getMainCurators();
    setCurators(curatorsResp.data);
  }, []);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundColor: ALTERNATIVE.ARTPOOL_PINK,
          padding: 5,
          ...clippedCorners,
        }}
      >
        <Stack spacing={3}>
          <Subtitle2>
            <b>Curator Highlight</b>
          </Subtitle2>
          {curators.map((curator) => (
            <CuratorThumbnail curator={curator} />
          ))}
        </Stack>
      </Grid>
      <Grid item xs={12} md={6} sx={{ padding: 5 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Subtitle2>
              <b>Apply to be part of Artpool's curator network</b>
            </Subtitle2>
          </Grid>
          <Grid item xs={12}>
            <Body1>
              We pride ourselves on the expertise of our professional art
              curators and community. Interested parties are welcome to apply to
              be part of our network below, free for curators. We will connect
              via email and ask for the following materials:
              <br />
              <br />
              • A portfolio of 3 shows you have curated
              <br />
              • 1 curatorial text you have written
              <br />
              • Your CV, Website or Portfolio
              <br />
              • Mention any curatorial studies or residencies you have taken
              part in
              <br />• Optional recommendation from a peer already on Artpool
            </Body1>
          </Grid>
          <Grid item xs={12}>
            <Link to={ACGAppPaths.LOGIN}>
              <StyledButton variant={'contained'} fullWidth={isSmall}>
                {' '}
                Apply now
              </StyledButton>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
