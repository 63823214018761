import * as React from 'react';
import { useState } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

// tslint:disable-next-line:no-empty-interface
export interface ISquaredCardContainerProps {
  children?: React.ReactNode;
  type?: 'button' | 'image';
  bg?: string;
  p?: string;
  width?: string;
  height?: string;
  noShadow?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const useStyles = makeStyles({
  chopped_corners: {
    width: '100%',
    height: '100%',
    clipPath:
      'polygon(10px 0%, calc(100% - 10px) 0%, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0% calc(100% - 10px), 0% 10px)',
  },
});

export function SquaredCardContainer(props: ISquaredCardContainerProps) {
  const classes = useStyles(props);

  const [isHovering, setIsHovering] = useState(false);

  const toggleHover = () => {
    setIsHovering(!isHovering);
  };

  const typeStyling = {
    general_item: {
      backgroundColor: props.bg || 'white',
      padding: props.p || 20,
      width: props.width || 'inherit',
    },
    general_container: {
      height: props.height || 'inherit',
    },
    button: {
      cursor: 'pointer',
      textAlign: 'center',
    },
    image: {
      padding: 0,
      height: 'auto',
      width: props.width || 'fit-content',
    },
    shadow: {
      filter:
        !isHovering || props.type !== 'button'
          ? 'drop-shadow(rgba(0, 0, 0, 0.6) 1.5px 2px 2px)'
          : 'drop-shadow(rgba(0, 0, 0, 0.5) 1.5px 1.5px 1.5px)',
    },
  };

  const style = props.type
    ? { ...typeStyling.general_item, ...typeStyling[props.type] }
    : typeStyling.general_item;

  return (
    <Grid
      container={true}
      style={
        props.noShadow
          ? typeStyling.general_container
          : { ...typeStyling.shadow, ...typeStyling.general_container }
      }
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      onClick={props.onClick}
    >
      <Grid item={true} className={classes.chopped_corners} style={style}>
        {props.children}
      </Grid>
    </Grid>
  );
}

export default SquaredCardContainer;
