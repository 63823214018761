import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import PaddedLayout from '../../library/layout/container/PaddedLayout';
import { compose } from 'recompose';
import { Grid } from '@mui/material';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  DARK_GREY_COLOR,
} from '../../styles/constants';
import ElCielo from '../../img/labbox/ElCielo_final.jpg';

export interface ILabBox2Props {}

type Props = ILabBox2Props & WithStyles<string> & IWithAccountProps & WithWidth;

const style = createStyles({
  wrapper: {
    fontFamily: 'Replica-Regular',
    display: 'flex',
    alignContent: 'stretch',
    justifyItems: 'center',
    flexDirection: 'column',
  },
  main: {
    flex: '1 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  letterWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: ' 0 1em',
    '& p': {
      margin: 0,
    },
    '& h2': {
      margin: 0,
    },
  },

  margin: {
    // margin: '30px 0'
  },

  office: {
    width: '100%',
  },

  border: {
    // borderBottom: `1px solid ${PRIMARY_COLOR}`
    marginBottom: 50,
  },
  arrow: {
    height: '50px',
  },
  flex: {
    display: 'flex',
  },
  textMobile: {
    '& h2': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 16,
      margin: 0,
    },
    padding: '0 15px',
    width: '100%',
  },
  textDesktop: {
    '& h2': {
      fontSize: 30,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 15,
      paddingBottom: 20,
      paddingTop: 0,
      margin: 0,
    },
    padding: '0 20px',
    width: '100%',
  },
  letterWrapperMobile: {
    padding: '0',
  },
  link: {
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    cursor: 'pointer',
    color: 'black',
    '&:hover': {
      color: PRIMARY_COLOR,
    },
  },

  elCielo: {
    width: '100%',
    height: 660,
  },

  paralax: {
    backgroundImage: `url(${ElCielo})`,
    minHeight: 660,
    width: '100%',

    // backgroundAttachment: "fixed",
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',

    /* The image used *
        background-image: url("img_parallax.jpg");

        /* Set a specific height *
        height: 500px;

        /* Create the parallax scrolling effect *
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        <img src={ElCielo} alt={"ElCielo 2020"} className={classes.elCielo} />

        <div className={classes.paralax} />

        */
  },

  paralaxMobile: {
    backgroundImage: `url(${ElCielo})`,
    minHeight: 377,
    width: '100%',

    // backgroundAttachment: "fixed",
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },

  title: {
    fontFamily: 'Replica-Regular',
    fontSize: '30px !important',
    fontWeight: 'bold',
    color: SECONDARY_COLOR,
  },

  subtitle: {
    color: DARK_GREY_COLOR,
    fontWeight: 'normal',
    fontFamily: 'Replica-Regular',
    fontSize: '20px !important',
  },

  titleMobile: {
    fontFamily: 'Replica-Regular',
    fontSize: '20px !important',
    fontWeight: 'bold',
    color: SECONDARY_COLOR,
  },

  subtitleMobile: {
    color: DARK_GREY_COLOR,
    fontWeight: 'normal',
    fontFamily: 'ReplicaPro',
    fontSize: '20px !important',
  },

  subtext: {
    fontSize: 16,
    paddingTop: 0,
    fontStyle: 'italic',
  },

  subtextMobile: {
    fontSize: 10,
    paddingTop: 0,
    fontStyle: 'italic',
  },
});

class LabBox2 extends React.Component<Props, any> {
  public render() {
    const { classes, width } = this.props;

    const isMobile = isWidthDown('xs', width);

    return (
      <div className={classes.wrapper}>
        <div className={classes.main}>
          <PaddedLayout>
            {/*
                        <div className={isMobile ? classes.textMobile : classes.textDesktop}>
                            <Grid className={classes.margin} container={true} alignContent="center">
                                <Grid item={true} xs={12} sm={12} className={ isMobile ? classes.paralaxMobile : classes.paralax} />
                            </Grid>
                            <Grid className={classes.margin} container={true} alignContent="center">
                                <Grid item={true} xs={12} sm={6} style={{paddingLeft:20}}/>
                                <Grid item={true} xs={12} sm={6} style={{paddingLeft:20}}>
                                    <p className={isMobile ? classes.subtextMobile : classes.subtext}>
                                        Fátima Moreno ElCielo 2020: 1.20m x 1.45m, watercolour and oil painting on arches paper
                                    </p>
                                </Grid>
                            </Grid>
                        </div>
                        */}

            <div
              className={isMobile ? classes.textMobile : classes.textDesktop}
            >
              <Grid
                className={classes.margin}
                container={true}
                alignContent="center"
              >
                <Grid
                  item={true}
                  xs={12}
                  sm={6}
                  style={{ paddingLeft: 20, paddingTop: 20 }}
                >
                  <p>
                    On the occasion of its second Lab Box exhibition, Art
                    Curator Grid presents: <br />
                    {isMobile && <br />}
                    Para a segunda exposição no seu Lab Box, Artpool
                    apresenta:
                  </p>
                </Grid>
                {!isMobile && (
                  <Grid item={true} xs={12} sm={6} style={{ padding: 20 }} />
                )}
              </Grid>
            </div>

            <div
              className={isMobile ? classes.textMobile : classes.textDesktop}
            >
              <Grid
                className={classes.margin}
                container={true}
                alignContent="center"
              >
                <Grid
                  item={true}
                  xs={12}
                  sm={6}
                  style={{ paddingLeft: 20, paddingTop: 18 }}
                >
                  <p className={classes.title}>
                    <i>En El Principio Era El Delirio </i>
                    <br />
                    Fátima Moreno
                  </p>
                </Grid>
                {!isMobile && (
                  <Grid
                    item={true}
                    xs={12}
                    sm={6}
                    style={{ paddingLeft: 20 }}
                  />
                )}
              </Grid>
            </div>

            {isMobile && <div style={{ paddingTop: 20 }} />}
            <div
              className={isMobile ? classes.textMobile : classes.textDesktop}
            >
              <Grid
                className={classes.margin}
                container={true}
                alignContent="center"
              >
                <Grid item={true} xs={12} sm={6} style={{ paddingLeft: 20 }}>
                  <p className={classes.subtitle}>
                    Curated by/ Curadoria de&nbsp;
                    <a
                      href={'/users/antonia-gaeta/posts'}
                      target="_blank"
                      style={{ color: DARK_GREY_COLOR }}
                    >
                      Antonia Gaeta
                    </a>{' '}
                    <br />
                    17 September — 15 November 2020
                  </p>
                </Grid>
                {!isMobile && (
                  <Grid
                    item={true}
                    xs={12}
                    sm={6}
                    style={{ paddingLeft: 20 }}
                  />
                )}
              </Grid>
            </div>
            <div
              className={isMobile ? classes.textMobile : classes.textDesktop}
            >
              <div className={classes.border}>
                <Grid
                  className={classes.margin}
                  container={true}
                  alignContent="center"
                  spacing={1}
                  style={{ paddingTop: 22 }}
                >
                  <Grid
                    item={true}
                    xs={12}
                    sm={6}
                    style={{ paddingLeft: 20, paddingBottom: 30 }}
                  >
                    <p>
                      Watercolors representing naked bodies, at times just
                      stand-alone anatomical parts, a pair of flaming boots on
                      feet, anthropomorphia, rhythm, a flowerbed on the head.
                      Tongues, eyes, hands with pointy nails, beams of light,
                      faces overcome by surprise which are playfully coupled
                      with snakes, braids, lichens, stars, and also pleasure in
                      the shape of a colorful milky way. These are some of the
                      elements we can find in Fátima Moreno’s work, author of a
                      frontal and fresh piece which suggests the reappropriation
                      of desire as it rises to the tone of delirium.
                    </p>
                    <p>
                      Fátima Moreno (Granada, 1980) has an education in
                      engraving, works in illustration, and has in more recent
                      times come across painting and drawing on her artistic
                      path. Her artistic practice is naturally embedded in
                      various domains and collaborations with fashion and object
                      design are frequent.
                    </p>
                    <p>
                      The distinctive aspect of her work lies in the total
                      absence of a place of spatial or temporal reference, other
                      than that which acts as a niche for the body and the
                      shapes that surround it. Colors light up in a gamut of
                      reds, blues, and yellows, the fine line smashed by
                      blotches of color – as if it were an explosion -, images
                      veiled in a marvelously suspended matter. Her figures
                      suggest the smell of seaweed on winter days, the
                      intermittent oscillation of dreams.
                    </p>
                    <p>
                      The mural that the artist puts forward is perhaps a
                      lararium where the <i>lari, lases lares</i>, souls of the
                      ancestors, protectors of the hearth and the land, appear
                      as figures of adoration with magical qualities with which
                      the artist works to operate a sudden displacement, using
                      strategies of subjectification, appropriation, and new
                      contextualization. They move with a dancing step, fluvial,
                      ethereal, musical, with bizarre narratives and deliriums,
                      they are Venus with her divine astrological branch,
                      disorders in the judgment of reality, legends on the
                      creation of the world, all bundled up. In the artist’s
                      words, the mural is born of an impulse: “
                      <i>
                        the gesture, the motion of moving upwards, arms
                        stretched out [...] a beginning, a new thing with an old
                        meaning
                      </i>
                      ”.
                    </p>
                    <p>
                      By working directly on the walls of Lab Box, a space which
                      is a cube, with features and measurements which adjust to
                      the human body (1,20m face width, with a height of 2,35m),
                      Fátima Moreno puts forward a mural, a unique and ephemeral
                      piece, with a beginning and an end, influenced by the
                      research of philosopher and writer Maria Zambrano on the
                      process of transition from mystery to problem, from the
                      spatial <i>logos</i> connected to the word to the temporal{' '}
                      <i>logos</i> connected to number, the first forms of
                      rationality, and the concepts of delirium.
                    </p>
                    <p>
                      {' '}
                      Antonia Gaeta
                      <br />
                      September 2020
                    </p>
                  </Grid>

                  <Grid item={true} xs={12} sm={6} style={{ paddingLeft: 20 }}>
                    <p>
                      Aguarelas a representarem corpos nus, por vezes apenas
                      partes anatómicas isoladas, um par de botas flamejantes
                      nos pés, antropomorfias, ritmo, um canteiro de flores na
                      cabeça. Línguas, olhos, mãos com unhas pontiagudas, raios
                      de luz, rostos dominados pela surpresa ludicamente
                      acompanhados de cobras, tranças, líquenes, estrelas e
                      ainda o prazer em forma de via láctea colorida. Eis alguns
                      dos elementos que se encontram na obra de Fátima Moreno,
                      autora de um trabalho frontal e fresco que sugere a
                      reapropriação do desejo a subir ao tom do delírio.
                    </p>
                    <p>
                      Formada em gravura, Fátima Moreno (Granada, 1980) trabalha
                      em ilustração e mais recentemente encontrou-se com a
                      pintura e o desenho. A sua prática artística integra-se
                      naturalmente em vários âmbitos e são frequentes
                      colaborações com a moda e o design de objectos.
                    </p>
                    <p>
                      O carácter distintivo do seu trabalho é a ausência total
                      de um local de referência espacial e temporal, se não
                      aquele que actua como nicho do corpo e das formas que o
                      rodeiam. As cores iluminam-se na gama dos vermelhos, azuis
                      e amarelos, a linha fina esmagada por manchas de cor -
                      como se de uma explosão se tratasse -, imagens veladas
                      numa matéria maravilhosamente suspensa. As suas figuras
                      lembram o cheiro das algas em dias de inverno, a oscilação
                      intermitente dos sonhos.
                    </p>
                    <p>
                      O mural que a artista apresenta será porventura um larário
                      onde os <i>lari, lases lares</i>, almas dos antepassados,
                      protectores da casa e da terra, aparecem como figuras de
                      adoração com qualidades mágicas com as quais a artista
                      trabalha para operar um deslocamento repentino usando
                      estratégias de subjectificação, apropriação e nova
                      contextualização. Elas movem-se a passo de dança,
                      fluviais, etéreas, musicais, com narrativas bizarras e
                      delírios, são Venus com a sua divina rama astrológica,
                      transtornos do juízo de realidade, lendas sobre a criação
                      do mundo, tudo à mistura. Nas palavras da artista o mural
                      vai nascer de um impulso: “
                      <i>
                        o gesto, o movimento de ir até cima, braços esticados
                        [...] um principio, uma coisa nova mas com um sentido
                        antigo
                      </i>
                      ”.
                    </p>
                    <p>
                      Ao trabalhar directamente sobre as paredes do Lab Box, um
                      espaço que é um cubo, com características e medidas que se
                      ajustam ao corpo humano (1.20m de largura nas suas faces,
                      com uma altura de 2.35m), Fátima Moreno apresenta um
                      mural, uma obra única e efémera, com um começo e um fim,
                      influenciada pela investigação da filósofa e escritora
                      Maria Zambrano sobre o processo de passagem do mistério ao
                      problema, do <i>logos</i> espacial ligado à palavra ao{' '}
                      <i>logos</i> temporal ligado ao número, as primeiras
                      formas de racionalidade e os conceitos de delírio.
                    </p>
                    <p>
                      {' '}
                      Antonia Gaeta
                      <br />
                      Setembro 2020
                    </p>
                  </Grid>
                </Grid>
              </div>
            </div>
          </PaddedLayout>
        </div>
      </div>
    );
  }
}

export default compose<Props, ILabBox2Props>(
  withStyles(style),
  withAccount,
  withWidth()
)(LabBox2);
