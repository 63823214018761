import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { Grid } from '@mui/material';
import { compose } from 'recompose';
import {
  DARK_GREY_COLOR,
  PRIMARY_COLOR,
  SPACING,
} from '../../styles/constants';
import { IPost } from '../../types/posts';
import SubTitle from '../../library/dataDisplay/typography/SubTitle';

import ACGLikeButton from '../../library/inputs/ACGLikeButton';
import ACGShareButton from '../../library/inputs/ACGShareButton';
import ACGCommentButton from '../../library/inputs/ACGCommentButton';
import IconLike from '../../library/dataDisplay/icons/IconLike';
import IconViews from '../../library/dataDisplay/icons/IconViews';

export interface IPostFullSkeletonProps {
  featured?: React.ReactNode;
  additionnalContent?: React.ReactNode;
  post: IPost;
  liking: (post: IPost) => boolean;
  content: React.ReactNode;
  onLike: (post: IPost) => void;
  onCommentClick: () => void;
  onShareClick: () => void;
  isDraft?: boolean;
}

type Props = IPostFullSkeletonProps & WithStyles<string>;

const styles = createStyles({
  container: {
    marginTop: SPACING,
  },
  content: {
    fontSize: '16px',
    padding: `${SPACING}px 0`,
    marginTop: -5,
    display: 'inline-block',
  },
  draft: {
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
    backgroundColor: PRIMARY_COLOR,
    padding: '8px 8px',
  },
  overflow: {
    display: 'block',
    height: 5,
    width: '100%',
  },
});

class PostFullSkeleton extends React.Component<Props, any> {
  public render() {
    const {
      classes,
      featured,
      post,
      additionnalContent,
      liking,
      content,
      onLike,
      onCommentClick,
      onShareClick,
    } = this.props;

    const liked = liking(post);

    const onLikeClick = () => onLike(post);

    return (
      <Grid container={true} className={classes.container}>
        <Grid item={true} xs={12}>
          {featured}
        </Grid>
        {this.props.isDraft && (
          <Grid item={true} xs={12}>
            <div className={classes.draft}>DRAFT</div>
          </Grid>
        )}
        <Grid className={classes.content} item={true} xs={12}>
          <div>
            <Grid
              container={true}
              spacing={1}
              justifyContent={'flex-end'}
              alignItems={'center'}
            >
              <Grid
                item={true}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <IconLike height={20} liked={false} fill={DARK_GREY_COLOR} />
                <SubTitle>{post.likes.length}</SubTitle>
              </Grid>
              <Grid
                item={true}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <IconViews height={20} />
                <SubTitle>{post.number_of_views}</SubTitle>
              </Grid>
              <Grid item={true} onClick={onLikeClick}>
                <ACGLikeButton liked={liked} isButton={true} />
              </Grid>
              <Grid item={true} onClick={onCommentClick}>
                <ACGCommentButton isButton={true} />
              </Grid>
              <Grid item={true} onClick={onShareClick}>
                <ACGShareButton isButton={true} />
              </Grid>
            </Grid>

            {content}
            <div className={classes.overflow} />
          </div>
        </Grid>
        {additionnalContent}
        <div className={classes.overflow} />
      </Grid>
    );
  }
}

export default compose<Props, IPostFullSkeletonProps>(withStyles(styles))(
  PostFullSkeleton
);
