import { idParams } from './util';
import { createRouteRequest } from 'typesafe-api';
import {
  CreateProjectEndpointDef,
  createProjectRoute,
  DeployProjectEndpointDef,
  deployProjectRoute,
  BuildEtherscanVerifyCmdEndpointDef,
  buildEtherscanVerifyCmdRoute,
  GetFeaturedProjectsEndpointDef,
  getFeaturedProjectsRoute,
  GetProjectEndpointDef,
  GetProjectOptions,
  getProjectRoute,
  GetProjectsEndpointDef,
  getProjectsRoute,
  GetSuperAdminProjectEndpointDef,
  getSuperAdminProjectRoute,
  UpdatedProjectOptions,
  UpdateProjectEndpointDef,
  updateProjectRoute,
} from '@acg/artpool-api-spec';
import { AuthApiClient } from '@acg/shared/api-spec-utils';
import { CreateProjectData } from '@acg/shared/interfaces';

export class ProjectClient extends AuthApiClient {
  /**
   * get projects
   */
  private _getProjects = createRouteRequest<GetProjectsEndpointDef>(
    this,
    getProjectsRoute
  );

  public getProjects = (projectOptions: GetProjectOptions) =>
    this._getProjects({ query: projectOptions });

  public getLiveProjects = () => this._getProjects({ query: { live: true } });

  public getHighlightedProjects = () =>
    this._getProjects({ query: { highlighted: true } });

  /**
   * get project
   */
  private _getProject = createRouteRequest<GetProjectEndpointDef>(
    this,
    getProjectRoute
  );

  public getProject = (_id: string) => this._getProject(idParams(_id));

  /**
   * get super admin project
   */
  private _getSuperAdminProject =
    createRouteRequest<GetSuperAdminProjectEndpointDef>(
      this,
      getSuperAdminProjectRoute
    );

  public getSuperAdminProject = (_id: string) =>
    this._getSuperAdminProject(idParams(_id));

  /**
   * get featured projects
   */
  private _featuredProjects =
    createRouteRequest<GetFeaturedProjectsEndpointDef>(
      this,
      getFeaturedProjectsRoute
    );

  public featuredProjects = (options?: { limit?: number; skip?: number }) =>
    this._featuredProjects({ query: options ?? {} });

  /**
   * create project
   */

  private _createProject = createRouteRequest<CreateProjectEndpointDef>(
    this,
    createProjectRoute
  );

  public createProject = (body: CreateProjectData) =>
    this._createProject({
      body,
    });

  /**
   * Build etherscan verify cmd
   */

  private _buildEtherscanVerifyCmd =
    createRouteRequest<BuildEtherscanVerifyCmdEndpointDef>(
      this,
      buildEtherscanVerifyCmdRoute
    );

  public buildEtherscanVerifyCmd = (_id: string) =>
    this._buildEtherscanVerifyCmd(idParams(_id));

  /**
   * deploy project
   */
  private _deployProject = createRouteRequest<DeployProjectEndpointDef>(
    this,
    deployProjectRoute
  );

  public deployProject = (_id: string) => this._deployProject(idParams(_id));

  private _updateProject = createRouteRequest<UpdateProjectEndpointDef>(
    this,
    updateProjectRoute
  );

  public updateProject = (id: string, body: UpdatedProjectOptions) =>
    this._updateProject({
      body,
      params: {
        id,
      },
    });
}
