import * as React from 'react';

export const META_DATA_APP_NAME = 'Artpool';

export const GenericMetaData = () => (
  <>
    <meta property="twitter:site" content="@Artpool_xyz" />
    <meta property="og:site_name" content={META_DATA_APP_NAME} />
    <meta name="fb:app_id" content="1152151491637472" />
    <meta property="og:type" content="website" />
  </>
);
