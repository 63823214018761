import { IArtistUser, ICuratorUser, IViewerUser } from '../../../types/user';
import { action } from 'typesafe-actions';

export function fetchRequest() {
  return action('@@pendingCurators/FETCH_REQUEST');
}
export function fetchSuccess(data: ICuratorUser[]) {
  return action('@@pendingCurators/FETCH_SUCCESS', data);
}

export function fetchError(message: string) {
  return action('@@pendingCurators/FETCH_ERROR');
}

export function fetchArtistRequest() {
  return action('@@pendingCurators/FETCH_ARTIST_REQUEST');
}
export function fetchArtistSuccess(data: IArtistUser[]) {
  return action('@@pendingCurators/FETCH_ARTIST_SUCCESS', data);
}

export function fetchArtistError(message: string) {
  return action('@@pendingCurators/FETCH_ARTIST_ERROR');
}

export function changeCuratorProfile({ viewer }: { viewer: IViewerUser }) {
  return action('@@pendingCurators/CHANGE_CURATOR_PROFILE', { viewer });
}

export function approveCurator({
  userId,
  profile,
}: {
  userId: string;
  profile?: string;
}) {
  return action('@@pendingCurators/APPROVE_CURATOR', { userId, profile });
}
