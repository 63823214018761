export const SPACING = 10;
export const GREY_COLOR = '#F6F6F6';
export const DARK_GREY_COLOR = '#afb0b3';
export const PRIMARY_COLOR = '#abff80';
export const ARTPOOL_PINK = '#E177FA';
export const LIGHT_PRIMARY = '#cab8ff';
export const SECONDARY_COLOR = '#2b2e34';
export const BREAKPOINT = 'sm';
export const INFO_POINT_ICON =
  'https://artcuratorgrid.s3.amazonaws.com/assets/info_point.png';
export const EXHIBITION_BREAKPOINT = 'md';

// ARTPOOL COLORS
export const NEUTRAL = {
  50: '#F6F6F6',
  100: '#F1F1F1',
  200: '#EBEBEB',
  300: '#E6E6E6',
  400: '#CCCCCC',
  500: '#999999',
  600: '#999999',
  700: '#5D5D5D',
  800: '#3E3E3E',
  900: '#1D1D1D',
};

export const PRIMARY = {
  ARTPOOL_GREEN: '#AAff80',
};
export const ALTERNATIVE = {
  ARTPOOL_BLUE: '#CBFFFF',
  ARTPOOL_YELLOW: '#FBF563',
  ARTPOOL_ORANGE: '#DD9768',
  ARTPOOL_PINK: '#E177FA',
};
