import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const PixeledArrowOpenIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox={'0 0 32 32'}>
      <path d="M0.305582 24L0 24.3056V31.6944L0.305582 32H7.69512L8.0007 31.6944V24.3056L7.69512 24H0.305582Z" />
      <path d="M8.30485 16L7.99927 16.3056V23.6944L8.30485 24H15.6944L16 23.6944V16.3056L15.6944 16H8.30485Z" />
      <path d="M16.3092 8L16.0037 8.30556V15.6944L16.3092 16H23.6988L24.0044 15.6944V8.30556L23.6988 8H16.3092Z" />
      <path d="M8.30485 0L7.99927 0.305556V7.69444L8.30485 8H15.6944L16 7.69444V0.305556L15.6944 0H8.30485Z" />
      <path d="M16.3092 0L16.0037 0.305556V7.69444L16.3092 8H23.6988L24.0044 7.69444V0.305556L23.6988 0H16.3092Z" />
      <path d="M24.3036 0L23.998 0.305556V7.69444L24.3036 8H31.6932L31.9987 7.69444V0.305556L31.6932 0H24.3036Z" />
      <path d="M24.3036 16L23.998 16.3056V23.6944L24.3036 24H31.6932L31.9987 23.6944V16.3056L31.6932 16H24.3036Z" />
      <path d="M24.3036 8L23.998 8.30556V15.6944L24.3036 16H31.6932L31.9987 15.6944V8.30556L31.6932 8H24.3036Z" />
    </SvgIcon>
  );
};
