import * as React from 'react';
import { AccountContext } from './AccountContext';
import { IUser } from '../../types/user';
import { IDataResponse } from '../../services/ApiServices';

export interface IWithAccountProps {
  account: IUser;
  updateAccount: (account: Partial<IUser>) => Promise<IDataResponse<IUser>>;
}

export const withAccount = <P extends object>(
  Component: React.ComponentType<P>
) => {
  return class extends React.Component<P & IWithAccountProps> {
    public render() {
      // ... and renders the wrapped component with the fresh data!
      // Notice that we pass through any additional props
      return (
        <AccountContext.Consumer>
          {({ account, updateAccount }) => (
            <Component
              {...this.props}
              account={account}
              updateAccount={updateAccount}
            />
          )}
        </AccountContext.Consumer>
      );
    }
  };
};
