import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import * as React from 'react';
import { Grid } from '@mui/material';
import {} from '@mui/material/';
import { compose } from 'recompose';
import { GridProps } from '@mui/material/Grid';
import { BREAKPOINT } from '../../../styles/constants';
export interface IPublicLayoutProps {
  children: React.ReactNode;
}

type Props = IPublicLayoutProps & WithWidth;

class PublicLayout extends React.Component<Props, any> {
  public render() {
    const { width } = this.props;

    const props: Partial<GridProps> = {
      justifyContent: isWidthDown(BREAKPOINT, width) ? 'flex-start' : 'center',
      alignItems: isWidthDown(BREAKPOINT, width) ? 'flex-start' : 'center',
    };

    return (
      <Grid
        container={true}
        direction="row"
        {...props}
        style={{
          height: '100vh',
          overflowY: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className="public-layout"
      >
        <Grid xs={10} sm={10} md={8} lg={6} item={true}>
          {this.props.children}
        </Grid>
      </Grid>
    );
  }
}

export default compose<Props, IPublicLayoutProps>(withWidth())(PublicLayout);
