import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { Grid } from '@mui/material';
// import logo from '../../img/logo.svg';

export interface IPaddedLayoutProps {
  noPadded?: boolean;
  children: React.ReactNode;
}

const styles = createStyles({
  padded: {
    paddingTop: 80,
  },
});

type Props = IPaddedLayoutProps & WithStyles<string>;

class PaddedLayout extends React.Component<Props, any> {
  public render() {
    const { classes, noPadded } = this.props;

    return (
      <Grid
        container={true}
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={noPadded ? '' : `${classes.padded}`}
      >
        {this.props.children}
      </Grid>
    );
  }
}

export default compose<Props, IPaddedLayoutProps>(withStyles(styles))(
  PaddedLayout
);
