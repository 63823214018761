import styles from '../auth.module.scss';
import { FormControl, TextField } from '@mui/material';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import { useFormikMui } from '@acg/frontend-utils';
import { signUp } from '@acg/auth/frontend';
import FormMessage from '../../inputs/form-message/form-message';
import { AUTH_STEPS, IFormProps } from '../components/auth-body';
import { passwordValidation } from '@acg/auth/auth';

export interface AccountParams {
  name: string;
  email: string;
  password: string;
}
interface ErrorValues {
  serverError?: string;
}
interface FormValues extends AccountParams {
  confirmPassword: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Enter a valid email'),
  name: Yup.string()
    .required('Name is required')
    .matches(/\S+\s+\S+/, 'Please enter your full name'),
  password: passwordValidation,
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export const AuthSignUpForm = ({ setAuthStep, setUser }: IFormProps) => {
  const { formik, formikProps } = useFormikMui<FormValues & ErrorValues>({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      name: '',
    },
    initialErrors: {
      serverError: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => submitSignup(values),
  });

  const submitSignup = async (values: AccountParams) => {
    try {
      const { email, password, name } = values;
      await signUp({ username: email, password, attributes: { email, name } });

      setUser({ username: email, password });
      setAuthStep(AUTH_STEPS.CONFIRM_SIGNUP);
    } catch (error: any) {
      formik.setFieldError('serverError', error.message);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <FormControl fullWidth>
            <TextField
              {...formikProps('email')}
              label="Email"
              type="email"
              color={'secondary'}
            />
          </FormControl>
        </Grid>
        <Grid item={true} xs={12}>
          <FormControl fullWidth>
            <TextField
              {...formikProps('password')}
              label="Password"
              type={'password'}
              color={'secondary'}
              onChange={(event) => {
                formik.setFieldTouched('password', true);
                formik.handleChange(event);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item={true} xs={12}>
          <FormControl fullWidth>
            <TextField
              {...formikProps('confirmPassword')}
              label="Confirm password"
              type={'password'}
              color={'secondary'}
            />
          </FormControl>
        </Grid>
        <Grid item={true} xs={12}>
          <FormControl fullWidth>
            <TextField
              {...formikProps('name')}
              label="Name"
              color={'secondary'}
            />
          </FormControl>
        </Grid>
        <FormMessage
          visible={!!formik.errors.serverError}
          message={formik.errors.serverError}
        />
        <Grid item={true} xs={12}>
          <button
            className={styles.buttonPrimary}
            type="submit"
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? 'Creating account...' : 'Create account'}
          </button>
        </Grid>
      </Grid>
    </form>
  );
};
