import React from 'react';
import styles from './caption.module.scss';

export interface CaptionProps
  extends React.HTMLAttributes<HTMLParagraphElement> {
  children?: React.ReactNode;
}

export function Caption(props: CaptionProps) {
  const { children, ...rest } = props;

  return (
    <p className={styles.root} {...rest}>
      {children}
    </p>
  );
}

export default Caption;
