import { EndpointDef, ErrorType, ReqOptions, ResOptions } from 'typesafe-api';
import { AuthReqOpts } from '@acg/shared/api-spec-utils';

export type DefaultErrorCodes = 500 | 403 | 401;

export enum IsPurchaseAbleErrorCodes {
  SOLD_OUT = 410,
  PROJECT_INACTIVE = 452,
}

export type IsPurchaseAbleNftError = ErrorType<
  | DefaultErrorCodes
  | 404
  | IsPurchaseAbleErrorCodes.SOLD_OUT
  | IsPurchaseAbleErrorCodes.PROJECT_INACTIVE
>;

export type ArtpoolApiEndpoint<
  ReqOpt extends ReqOptions,
  RespOpt extends ResOptions,
  E = ErrorType<DefaultErrorCodes>
> = EndpointDef<AuthReqOpts, ReqOpt, RespOpt, E>;
