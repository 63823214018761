import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { Grid } from '@mui/material';
import { Field, Formik, FormikProps } from 'formik';
import TextInput from '../../../components/form/TextInput';
import FormSubmit from '../../../components/form/FormSubmit';
import BackOfficeServices from '../../../services/BackOfficeServices';
import { useState } from 'react';
import { PRIMARY_COLOR } from '../../../styles/constants';

const style = createStyles({
  container: {
    marginTop: 15,
  },
  errorMessage: {
    color: '#f44336',
  },
  successMessage: {
    color: PRIMARY_COLOR,
  },
  message: {
    marginLeft: 14,
    marginRight: 14,
    fontSize: '0.75rem',
    marginTop: 3,
    textAlign: 'left',
    fontFamily: 'Replica-Regular',
    fontWeight: 400,
    lineHeight: 1.66,
  },
});

type Props = WithStyles<string>;

const FeaturedExhibitionSection: React.FC<Props> = (props) => {
  const { classes } = props;

  const backOfficeService = new BackOfficeServices();

  const [exhibitionError, setExhibitionError] = useState({
    error: false,
    message: '',
  });

  const onFeaturedExhibitionSubmit = async (
    values: { url: string },
    { setSubmitting }: any
  ) => {
    backOfficeService
      .setFeaturedExhibition(values.url)
      .then((res) => {
        setExhibitionError({ error: false, message: '* Exhibition Set' });
      })
      .catch((err) => {
        setExhibitionError({
          error: true,
          message: '* Error setting exhibition',
        });
      });
    setSubmitting(false);
  };

  const renderFeaturedExhibitionForm = (formProps: FormikProps<any>) => {
    return (
      <form onSubmit={formProps.handleSubmit}>
        <Grid
          container={true}
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item={true} xs={12}>
            <Field
              name="url"
              component={TextInput}
              naked={true}
              newTextInput={true}
              inputProps={{
                id: 'url',
                label: 'Featured Exhibition url',
                type: 'text',
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <p
              className={`${classes.message} ${
                exhibitionError.error
                  ? classes.errorMessage
                  : classes.successMessage
              }`}
            >
              {exhibitionError.message}
            </p>
          </Grid>
          <Grid item={true} xs={12}>
            <FormSubmit
              disabled={formProps.isSubmitting}
              newButton={true}
              variant="contained"
            >
              Set Featured Exhibition url
            </FormSubmit>
          </Grid>
        </Grid>
      </form>
    );
  };

  return (
    <Grid
      container={true}
      className={classes.container}
      direction="column"
      alignItems="flex-start"
      xs={12}
    >
      <Formik
        initialValues={{ url: '' }}
        onSubmit={onFeaturedExhibitionSubmit}
        render={renderFeaturedExhibitionForm}
      />
    </Grid>
  );
};

export default compose<Props, any>(withStyles(style))(
  FeaturedExhibitionSection
);
