import artpoolStyles from './../../styles/artpool-styles.module.scss';
import { clippedCorners } from '../../styles/artpool-styles';
import { FrameIcon, NEUTRAL, WHITE } from '@acg/assets';
import { Grid } from '@mui/material';
import { genericErrMsg, getMediumImageUrl } from '@acg/shared/utilities';
import DoneIcon from '@mui/icons-material/Done';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import React, { useState } from 'react';
import { ArtpoolApiClient, JsonNftWithRawFile } from '@acg/artpool-api-spec';
import {
  NFTCardArtistItem,
  NFTCardTitleItem,
  nftContainerProps,
  StyledButton,
} from '../..';
import { useSelector } from 'react-redux';
import { IAuthRootState } from '@acg/auth/frontend';

interface IOwnedNftCardProps {
  nft: JsonNftWithRawFile;
  artpoolApiClient: ArtpoolApiClient;
  cloudFrontUrl: string;
}

const cardWidth = 300;
const cardHeight = 215;

const containerStyles: React.CSSProperties = {
  width: cardWidth,
};

const imageStyles: React.CSSProperties = {
  width: cardWidth,
  height: 320,
  objectFit: 'cover',
};

const contentStyles: React.CSSProperties = {
  ...clippedCorners,
  backgroundColor: WHITE,
  padding: 15,
  height: cardHeight,
};

export const OwnedNftCard = (props: IOwnedNftCardProps) => {
  const { nft, artpoolApiClient , cloudFrontUrl} = props;

  const [printRequested, setPrintRequested] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const { user } = useSelector((state: IAuthRootState) => state.authReducer);

  const sendEmail = async () => {
    if (!user) {
      setError(true);
      return;
    }

    try {
      const resp = await artpoolApiClient
        .emailApi()
        .sendPrintRequestEmail({ nft });
      if (!resp) {
        setError(true);
        return;
      }
      setPrintRequested(true);
      setError(false);
    } catch (e) {
      setError(true);
    }
  };

  return (
    <Grid container style={containerStyles}>
      <Grid item style={{ ...clippedCorners }}>
        <img src={getMediumImageUrl(nft.image.src)} style={imageStyles} />
      </Grid>
      <Grid item style={contentStyles}>
        <Grid {...nftContainerProps}>
          <NFTCardTitleItem title={nft.title} />
          <NFTCardArtistItem artist={nft.artist} cloudFrontUrl={cloudFrontUrl} />
          <Grid item xs={12}>
            <Grid container alignItems={'center'}>
              <Grid item xs={6}>
                <p style={{ color: NEUTRAL[600] }}>Artwork Raw File</p>
              </Grid>
              <Grid item xs={6} textAlign={'right'}>
                <a href={nft.nftFile} download={nft.title}>
                  <StyledButton
                    variant={'contained'}
                    size={'small'}
                    startIcon={<FileDownloadOutlinedIcon />}
                  >
                    Download
                  </StyledButton>
                </a>
              </Grid>
            </Grid>
          </Grid>
          {nft.printOpts?.printable && (
            <Grid item xs={12}>
              <StyledButton
                disabled={printRequested}
                onClick={sendEmail}
                variant={'outlined'}
                size={'small'}
                startIcon={
                  <FrameIcon fill={printRequested ? NEUTRAL[600] : undefined} />
                }
                fullWidth
                style={{ padding: 10 }}
              >
                {printRequested ? 'Print Requested' : 'Request Printed Version'}
              </StyledButton>
              {error && (
                <p className={artpoolStyles['error--orange']}>
                  {genericErrMsg}
                </p>
              )}
              {printRequested && (
                <Grid container spacing={1} alignItems={'center'}>
                  <Grid item>
                    <p className={artpoolStyles['success--pink']}>
                      <DoneIcon fontSize={'small'} />
                    </p>
                  </Grid>
                  <Grid item>
                    <p className={artpoolStyles['success--pink']}>
                      We will be contacting you by email shortly
                    </p>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
