import styles from './auth.module.scss';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IAuthRootState, sendForgotPasswordCode } from '@acg/auth/frontend';
import { Box } from '@mui/material';
import { AuthBody } from './components/auth-body';
import { REDIRECT_KEY, SHOW_AUTH_UI_KEY } from '@acg/frontend-utils';
import React, { useMemo } from 'react';
import { ArtcuratorgridApiClient } from '@acg/artcuratorgrid-api-spec';

export interface AuthenticatorProps {
  onClose?: () => void;
  artcuratorgridApi: ArtcuratorgridApiClient;
}

export const Authenticator = (props: AuthenticatorProps) => (
  <Box className={styles.overlay}>
    <AuthBody {...props} />
  </Box>
);

type PrivateRouteProps = RouteProps;

export const sendResetPasswordCode = async (
  artcuratorgridApi: ArtcuratorgridApiClient,
  email: string
) => {
  await artcuratorgridApi.authMigrationApi().forgotPassword(email);
  await sendForgotPasswordCode(email);
};

export const PrivateRoute = ({
  component: Component,
  ...routeProps
}: PrivateRouteProps) => {
  const location = useLocation();
  const { user } = useSelector((state: IAuthRootState) => state.authReducer);

  const prevPath = (location.state as unknown as any)?.prevPath;
  const finalSearchParams = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.append(REDIRECT_KEY, location.pathname);
    searchParams.append(SHOW_AUTH_UI_KEY, 'true');
    return searchParams;
  }, [location]);

  if (!Component) {
    return null;
  }

  return (
    <Route
      {...routeProps}
      render={(props) =>
        !!user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: prevPath || '/',
              search: finalSearchParams.toString(),
            }}
          />
        )
      }
    />
  );
};
