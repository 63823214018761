import { IExhibition } from '../../../types/exhibitions';
import { action } from 'typesafe-actions';
import { IExhibitionFilters } from '../../../services/ExhibitionService';
import { IValidationError } from '../../../types/returns';
import { IOnlineExhibitionInitialValue } from '../../../containers/onlineexhibition/OnlineExhibitionForm';

// Exhibitions
export function getExhibitions() {
  return action('@@exhibitions/GET_EXHIBITIONS');
}
export function getExhibitionsSuccess({
  exhibitions,
}: {
  exhibitions: IExhibition[];
}) {
  return action('@@exhibitions/GET_EXHIBITIONS_SUCCESS', { exhibitions });
}

export function getExhibitionsError({ error }: { error: Error }) {
  return action('@@exhibitions/ERROR', { error });
}

// Exhibitions
export function getExhibition(
  exhibitionId: string,
  loggedUserId?: string,
  filters?: IExhibitionFilters
) {
  return action('@@exhibitions/GET_EXHIBITION', {
    exhibitionId,
    loggedUserId,
    filters,
  });
}
export function getExhibitionSuccess(exhibition: IExhibition) {
  return action('@@exhibitions/GET_EXHIBITION_SUCCESS', { exhibition });
}
export function getExhibitionError(error: Error) {
  return action('@@exhibitions/ERROR', { error });
}

// Exhibition likes
export function likeExhibition(exhibitionId: string) {
  return action('@@exhibitions/LIKE_EXHIBITION', { exhibitionId });
}
export function likeExhibitionSuccess(exhibition: IExhibition) {
  return action('@@exhibitions/LIKE_EXHIBITION_SUCCESS', { exhibition });
}
export function likeExhibitionError(error: Error) {
  return action('@@exhibitions/LIKE_EXHIBITION_ERROR', { error });
}

// Create Exhibition
export function createExhibition({
  exhibition,
}: {
  exhibition: Partial<IExhibition>;
}) {
  return action('@@exhibitions/CREATE_EXHIBITION', { exhibition });
}
export function createExhibitionSuccess(exhibition: IExhibition) {
  return action('@@exhibitions/CREATE_EXHIBITION_SUCCESS', { exhibition });
}
export function createExhibitionError(error: IValidationError) {
  return action('@@exhibitions/CREATE_EXHIBITION_ERROR', { error });
}

// Update Exhibition
export function updateExhibition({
  id,
  exhibition,
  editKey,
}: {
  id: string;
  exhibition: Partial<IExhibition>;
  editKey?: string;
}) {
  return action('@@exhibitions/UPDATE_EXHIBITION', { id, exhibition, editKey });
}
export function updateExhibitionSuccess(exhibition: IExhibition) {
  return action('@@exhibitions/UPDATE_EXHIBITION_SUCCESS', { exhibition });
}
export function updateExhibitionError(error: IValidationError) {
  return action('@@exhibitions/UPDATE_EXHIBITION_ERROR', { error });
}

// Checking if the user has access to exhibition
export function hasExhibitionAccess() {
  return action('@@exhibitions/HAS_EXHIBITION_ACCESS');
}
export function hasExhibitionAccessSuccess(isExhibitionAdmin: boolean) {
  return action('@@exhibitions/HAS_EXHIBITION_ACCESS_SUCCESS', {
    isExhibitionAdmin,
  });
}
export function hasExhibitionAccessError(error: Error) {
  return action('@@exhibitions/HAS_EXHIBITION_ACCESS_ERROR', { error });
}

// Getting the exihibition to edit exhibitions
export function getEditExhibition(exhibitionId: string, editKey?: string) {
  return action('@@exhibitions/GET_EDIT_EXHIBITION', { exhibitionId, editKey });
}
export function getEditExhibitionSuccess(exhibition: IExhibition) {
  return action('@@exhibitions/GET_EDIT_EXHIBITION_SUCCESS', { exhibition });
}

export function clearRedirect() {
  return action('@@exhibitions/CLEAR_REDIRECT');
}
export function clearExhibitionEdit() {
  return action('@@exhibitions/CLEAR_EDIT_EXHIBITION');
}

export function updateState(editExhibitionData: IOnlineExhibitionInitialValue) {
  return action('@@exhibitions/UPDATE_STATE', { editExhibitionData });
}

// Delete exhibitions
export function deleteExhibition(exhibitionId: string) {
  return action('@@exhibitions/DELETE_EXHIBITION', { exhibitionId });
}
export function deleteExhibitionSuccess() {
  return action('@@exhibitions/DELETE_EXHIBITION_SUCCESS');
}
export function deleteExhibitionError(error: Error) {
  return action('@@exhibitions/ERROR', { error });
}

// Send invite to edit exhibitions
export function inviteEditExhibition(
  exhibitionId: string,
  inviteEmail: string
) {
  return action('@@exhibitions/INVITE_EDIT_EXHIBITION', {
    exhibitionId,
    inviteEmail,
  });
}
export function inviteEditExhibitionSuccess(inviteEmail: string) {
  return action('@@exhibitions/INVITE_EDIT_EXHIBITION_SUCCESS', {
    inviteEmail,
  });
}
export function inviteEditExhibitionError(error: Error) {
  return action('@@exhibitions/ERROR', { error });
}
