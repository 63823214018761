import { NEUTRAL, themes } from '@acg/assets';
import {
  StyledButton,
  Subtitle2,
  clippedCorners,
} from '@acg/frontend-ui-artpool';
import { ACGAppPaths } from '@acg/shared/const';
import { Grid, useMediaQuery } from '@mui/material';
import { CommunitySection } from '../servicesPage/sections/community-section';
import { BlockchainSection } from './sections/blockchain-section';
import { BlogMagazineSection } from './sections/blog-magazine-section';
import { CuratorHighlight } from './sections/curator-highlight';
import { PostsSection } from './sections/posts-section';

export const ForCuratorsPage = () => {
  const isSmall = useMediaQuery(themes.artpoolTheme.breakpoints.down('md'));
  return (
    <div style={{ paddingTop: '80px' }}>
      <Grid container direction="row">
        <Grid
          container
          item
          md={6}
          padding={4}
          alignContent={isSmall ? 'center' : undefined}
          justifyContent="center"
          flexDirection={'column'}
          sx={{ textAlign: isSmall ? 'center' : undefined }}
        >
          <Grid item>
            <h1>Revolutionize art curation</h1>
          </Grid>
          <Grid item>
            <Subtitle2>
              Empowering art curators worldwide to connect, collaborate, and
              inspire in the digital era.
            </Subtitle2>
          </Grid>
          <Grid item paddingTop={4}>
            <a href={ACGAppPaths.LOGIN}>
              <StyledButton fullWidth={isSmall} variant={'contained'}>
                <span style={{ padding: '0px 20px' }}>
                  Join today, it’s free!
                </span>
              </StyledButton>
            </a>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
              <img
                src={'/assets/images/artpool/landing-page/curators.png'}
                alt={'curators'}
                style={{
                  width: isSmall ? '100%' : 'auto',
                  height: isSmall ? 'auto' : '100%',
                  ...clippedCorners,
                  objectFit: 'cover',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <a
                href={`/assets/images/acg/Portugal2020Doc.pdf`}
                target={'_blank'}
                rel="noopener noreferrer"
                download={false}
              >
                <img
                  src={'/assets/images/acg/Lisboa2020_RGB_1.png'}
                  style={{
                    width: isSmall ? '100%' : '75%',
                    margin: 'auto',
                    display: 'block',
                  }}
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <div style={{ backgroundColor: NEUTRAL[50] }}>
          <CommunitySection
            text={{
              title:
                "Join our network of premium, verified curators, it's completely free!",
              subtitle:
                'Artpool is home to over 900 international curators, all of whom have taken part in our rigorous application process-ensuring our network is only the most first rate professionals. Our curators gain access to premium expertise by the Artpool Team in digital and physical spaces.',
              ctaLabel: 'Join the Network',
            }}
          />
        </div>
        <PostsSection />
        <CuratorHighlight />
        <BlockchainSection />
        <BlogMagazineSection />
      </div>
    </div>
  );
};
