import { ITag } from './tags-interfaces';
import { IUser } from './user-interfaces';

export enum InstitutionType {
  INSTITUTION = 'INSTITUTION',
}

export interface IInstitution {
  email: string;
  name: string;
  location: {
    address: string;
    placeId: string;
    latLng: [number, number];
    city?: string;
    state?: string;
    country?: string;
  };
  profilePicture: string;
  featuredPicture: string;
  about: string;
  city: string;
  tags: [ITag];
  createdBy: IUser;
  url: string;
  institution_profile_type: 'PUBLIC' | 'PRIVATE';
  institution_type:
    | 'MUSEUM'
    | 'NON-PROFIT_ORGANIZATION'
    | 'ART_CENTER'
    | 'ART_FOUNDATION'
    | 'ARTIST-RUN_SPACE'
    | 'ART_GALLERY'
    | 'ART_RESIDENCY';
  post_total: number;
  exhibitions_number: number;
  followers_number: number;

  managedBy?: [IUser];
}
