import { IInstitution } from '../../../types/institution';
import { action } from 'typesafe-actions';
import { IRegisterInstitutionRequest } from '../../../services/BackOfficeServices';

export function fetchRequest(institutionType?: string) {
  return action('@@institutions/FETCH_REQUEST', { institutionType });
}
export function fetchSuccess(data: IInstitution[]) {
  return action('@@institutions/FETCH_SUCCESS', data);
}

export function fetchError(message: string) {
  return action('@@institutions/FETCH_ERROR');
}

export function createInstitution(institution: IRegisterInstitutionRequest) {
  return action('@@institutions/CREATE_INSTITUTION', { institution });
}

export function createInstitutionSuccess(data: any) {
  return action('@@institutions/CREATE_INSTITUTION_SUCCESS', data);
}

export function createInstitutionError(message: string) {
  return action('@@institutions/CREATE_INSTITUTION_ERROR');
}

export function updateInstitution(
  institution: IRegisterInstitutionRequest,
  isBackOffice: boolean
) {
  return action('@@institutions/UPDATE_INSTITUTION', {
    institution,
    isBackOffice,
  });
}

export function updateInstitutionSuccess(data: any) {
  return action('@@institutions/UPDATE_INSTITUTION_SUCCESS', data);
}

export function updateInstitutionError(message: string) {
  return action('@@institutions/UPDATE_INSTITUTION_ERROR');
}

export function resetCurrentInstitution() {
  return action('@@institutions/RESET_CURRENT_INSTITUTION');
}
