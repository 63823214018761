import { ActionType } from 'typesafe-actions';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import * as actions from './actions';
import CommentService from '../../../services/CommentService';

export function* getComments({
  payload,
}: ActionType<typeof actions.getComments>) {
  try {
    const service = new CommentService();
    const { data } = yield call(service.getPostComments, payload.id);
    yield put(actions.getCommentsSuccess(data));
  } catch (error: any) {
    yield put(actions.getCommentsError(error));
  }
}

export function* createComment({
  payload,
}: ActionType<typeof actions.createComment>) {
  try {
    const service = new CommentService();

    const { data } = yield call(
      service.createPostComment,
      payload.postId,
      payload.comment
    );

    yield put(actions.createCommentSuccess(data));
  } catch (error: any) {
    yield put(actions.createCommentError(error));
  }
}

export function* updateComment({
  payload,
}: ActionType<typeof actions.updateComment>) {
  try {
    const service = new CommentService();

    const { data } = yield call(
      service.updatePostComment,
      payload.id,
      payload.commentId,
      payload.comment
    );

    yield put(actions.updateCommentSuccess(data));
  } catch (error: any) {
    yield put(actions.updateCommentError(error));
  }
}

export function* deleteComment({
  payload,
}: ActionType<typeof actions.deleteComment>) {
  try {
    const service = new CommentService();

    yield call(service.deletePostComment, payload.id, payload.commentId);

    yield put(actions.deleteCommentSuccess(payload.commentId));
  } catch (error: any) {
    yield put(actions.deleteCommentError(error));
  }
}

export default all([
  takeLatest('@@comments/GET_COMMENTS', getComments),
  takeLatest('@@comments/CREATE_COMMENT', createComment),
  takeLatest('@@comments/UPDATE_COMMENT', updateComment),
  takeLatest('@@comments/DELETE_COMMENT', deleteComment),
]);
