import {
  ArtcuratorgridApiUrls,
  ArtpoolApiUrls,
  EnvLabels,
} from '@acg/shared/const';
import { IArtcuratorgridEnvironment } from './utils';

export const environment: IArtcuratorgridEnvironment = {
  production: true,
  envLabel: EnvLabels.PRODUCTION,
  REACT_APP_ENV_LABEL: 'production',
  REACT_APP_API_URL: ArtcuratorgridApiUrls.PRODUCTION,
  REACT_APP_ARTPOOL_API_URL: ArtpoolApiUrls.PRODUCTION,
  REACT_APP_PAULINE: 'luisa-santos',
  REACT_APP_FOR_CURATORS: 'pauline-foessel',
  REACT_APP_CLOUDFRONT_URL: 'https://drbfkoztg80ia.cloudfront.net',
  REACT_APP_ALGOLIA_INDEX: 'prod_artcuratorgrid',
  REACT_APP_ALGOLIA_SEARCH_KEY: 'f51c6525eee34a9d0e86eb6a453e29b2',
  REACT_APP_ALGOLIA_ID: '410H0118DM',
  REACT_APP_EXHIBITION:
    '/online-exhibition/onsite-research--commission-series-x-paulo-nimer-pjota',
  REACT_APP_DEFAULT_PASSWORD: 'password123',
  artpoolApiUrl: ArtpoolApiUrls.PRODUCTION,
};
