import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { Dialog, Grid } from '@mui/material';
import { compose } from 'recompose';
import { withRouter, RouteComponentProps } from 'react-router';
import Facebook from '../../components/icons/social/circle/Facebook';
import Twitter from '../../components/icons/social/circle/Twitter';
import Linkedin from '../../components/icons/social/circle/Linkedin';
import { IUser } from '../../types/user';
import { IPost } from '../../types/posts';
import ACGDialogTitle from '../../components/modal/ACGDialogTitle';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import { IExhibition } from '../../types/exhibitions';
import { StyledButton } from '@acg/frontend-ui-artpool';

export type SharedObject =
  | ISharedUser
  | ISharedPost
  | ISharedExhibition
  | ISharedURL;

export interface ISharedUser {
  type: 'USER';
  object: IUser;
}

export interface ISharedPost {
  type: 'POST';
  object: IPost;
}

export interface ISharedExhibition {
  type: 'EXHIBITION';
  object: IExhibition;
}

export interface ISharedURL {
  type: 'URL';
  object: {
    url: string;
    title: string;
    visibility?: string;
    access_key?: string;
    base_map?: any;
  };
  showIframeOption: boolean;
  hideShare?: boolean;
}

export interface ISocialModalProps {
  open: boolean;
  onClose: () => void;
  hideShare?: boolean;
  object: SharedObject;
}

type Props = ISocialModalProps &
  WithStyles<string> &
  RouteComponentProps<{}> &
  IWithAccountProps;

const styles = createStyles({
  social: {
    fontFamily: 'Replica-Regular',
    fontSize: 12,
    fontWeight: 300,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#000000',
  },
  socialWrapper: {
    padding: '28px 0',
  },
  linkWrapper: {
    padding: '0 10px',
  },
  noDecoration: {
    textDecoration: 'none',
  },
  socialIcon: {
    textAlign: 'center',
  },
  linkBox: {
    border: 'solid 1px #dadada',
    backgroundColor: '#fafbfb',
    fontFamily: 'Replica-Regular',
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#929292',
    padding: '13px 27px',
    textAlign: 'center',
  },
  buttonWrapper: {
    textAlign: 'center',
    padding: '20px 0',
  },
  socialBlock: {
    cursor: 'pointer',
    display: 'inline-block',
    margin: '0 15px',
  },
});

interface IState {
  copied: boolean;
  copiedIFrame: boolean;
}

class SocialModal extends React.Component<Props, IState> {
  private textArea: any;
  private textAreaIFrame: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      copied: false,
      copiedIFrame: false,
    };
  }

  public onFbClick = () => {
    const { account, object } = this.props;

    const userId = account && account.id ? account.id : 'public_user';

    let shareUrl = location.href;
    if (object.type === 'URL') {
      shareUrl = `${window.location.protocol}//${window.location.host}/${object.object.url}`;
    }

    if (
      (object.type === 'URL' || object.type === 'EXHIBITION') &&
      object.object.visibility === 'PRIVATE'
    ) {
      shareUrl = `${shareUrl}?key=${object.object.access_key}`;
    }

    (window as any).FB.ui({
      method: 'share',
      href: shareUrl,
    });
  };

  public getWindowOptions = () => {
    const width = 500;
    const height = 350;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;

    return [
      'resizable,scrollbars,status',
      'height=' + height,
      'width=' + width,
      'left=' + left,
      'top=' + top,
    ].join();
  };

  public openUrlInWindow = (shareUrl: string, title: string) => {
    const win = window.open(shareUrl, title, this.getWindowOptions());
    if (win) {
      win.opener = null; // 2
    }
  };

  public renderPostDescription = (object: SharedObject) => {
    switch (object.type) {
      case 'USER':
        return `Discover ${object.object.firstName} ${object.object.lastName}’s profile on Artpool`;
        break;
      case 'POST':
        return `Discover ${object.object.title} on Artpool`;
        break;
      case 'EXHIBITION':
        return `Discover ${object.object.title} on Artpool`;
        break;
      case 'URL':
        return `Discover ${object.object.title} on Artpool`;
        break;
    }
  };

  public onTwitterClick = () => {
    const { object } = this.props;
    const text = encodeURIComponent(this.renderPostDescription(object));
    let shareUrl = location.href;
    if (object.type === 'URL') {
      shareUrl = `${window.location.protocol}//${window.location.host}/${object.object.url}`;
    }
    if (
      (object.type === 'URL' || object.type === 'EXHIBITION') &&
      object.object.visibility === 'PRIVATE'
    ) {
      shareUrl = `${shareUrl}?key=${object.object.access_key}`;
    }
    const shareTwitterUrl =
      'https://twitter.com/intent/tweet?url=' + shareUrl + '&text=' + text;
    this.openUrlInWindow(shareTwitterUrl, 'Share on Twitter');

    const { account } = this.props;
    const userId = account && account.id ? account.id : 'public_user';
  };

  public onLinkedinClick = () => {
    const { object } = this.props;
    const text = encodeURIComponent(this.renderPostDescription(object));
    const title = encodeURIComponent('Artpool');
    let shareUrl = location.href;
    if (object.type === 'URL') {
      shareUrl = `${window.location.protocol}//${window.location.host}/${object.object.url}`;
    }

    if (
      (object.type === 'URL' || object.type === 'EXHIBITION') &&
      object.object.visibility === 'PRIVATE'
    ) {
      shareUrl = `${shareUrl}?key=${object.object.access_key}`;
    }

    const shareLinkedInUrl =
      'https://www.linkedin.com/shareArticle?mini=true&url=' +
      shareUrl +
      '&title=' +
      title +
      '&summary=' +
      text +
      '&source=' +
      window.location.host;
    this.openUrlInWindow(shareLinkedInUrl, 'Share on Linkedin');
  };

  public render() {
    const { onClose, open, classes, object, account } = this.props;

    const { copied, copiedIFrame } = this.state;

    let shareUrl = window.location.href;
    let iFrameSource = '';

    if (
      object.type === 'POST' &&
      object.object &&
      object.object.created_by &&
      !object.object.venue_id
    ) {
      shareUrl = `${window.location.protocol}//${window.location.host}/users/${object.object.created_by.url}/posts/${object.object.url}`;
    } else if (
      object.type === 'POST' &&
      object.object &&
      object.object.venue_id
    ) {
      shareUrl = `${window.location.protocol}//${window.location.host}/venues/${object.object.venue_id.url}/posts/${object.object.url}`;
    } else if (object.type === 'EXHIBITION') {
      shareUrl = `${window.location.protocol}//${window.location.host}/online-exhibition/${object.object.url}`;
    } else if (object.type === 'URL') {
      shareUrl = `${window.location.protocol}//${window.location.host}/${object.object.url}`;
      if (object.object.base_map) {
        shareUrl = `${window.location.protocol}//${window.location.host}/online-exhibition/${object.object.url}`;
      }
      if (object.object.visibility === 'PRIVATE') {
        shareUrl = `${shareUrl}?key=${object.object.access_key}`;
      }
      const containerUrl = shareUrl.replace(
        'online-exhibition',
        'container/online-exhibition'
      );
      iFrameSource = `<iframe title="${object.object.title}"
        src="${containerUrl}" width="100%" height="600"
        style="border:1px solid black;">
        <p>Your browser does not support iframes.</p>
        </iframe>`;
    }

    if (
      (object.type === 'URL' || object.type === 'EXHIBITION') &&
      object.object.visibility === 'PRIVATE'
    ) {
      shareUrl = `${shareUrl}?key=${object.object.access_key}`;
    }

    const onCopy = () => {
      const el = this.textArea;
      el.select();
      document.execCommand('copy');
      this.setState(
        {
          copied: true,
          copiedIFrame: false,
        },
        () => {
          setTimeout(() => {
            this.setState({
              copied: false,
            });
          }, 2000);
        }
      );
    };

    const onCopyIFrame = () => {
      const el = this.textAreaIFrame;
      el.select();
      document.execCommand('copy');
      this.setState(
        {
          copiedIFrame: true,
          copied: false,
        },
        () => {
          setTimeout(() => {
            this.setState({
              copiedIFrame: false,
            });
          }, 2000);
        }
      );
    };

    return (
      <Dialog
        open={open}
        fullWidth
        maxWidth={'sm'}
        keepMounted
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <ACGDialogTitle id="customized-dialog-title" onClose={onClose}>
          {this.props.hideShare ? 'Embed Into Your Website' : 'Share'}
        </ACGDialogTitle>
        {!this.props.hideShare && (
          <Grid container className={classes.socialWrapper}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <div onClick={this.onFbClick} className={classes.socialBlock}>
                <div className={classes.socialIcon}>
                  <Facebook fontSize={50} />
                </div>
                <div className={classes.social}>Facebook</div>
              </div>
              <div
                onClick={this.onTwitterClick}
                className={classes.socialBlock}
              >
                <div className={classes.socialIcon}>
                  <Twitter fontSize={50} />
                </div>
                <div className={classes.social}>Twitter</div>
              </div>
              <div
                onClick={this.onLinkedinClick}
                className={classes.socialBlock}
              >
                <div className={classes.socialIcon}>
                  <Linkedin fontSize={50} />
                </div>
                <div className={classes.social}>Linkedin</div>
              </div>
            </Grid>
          </Grid>
        )}
        <Grid container className={classes.linkWrapper}>
          {!this.props.hideShare && (
            <>
              <Grid item xs={12}>
                <div className={classes.linkBox}>{shareUrl}</div>
                <div
                  style={{ position: 'absolute', top: -99999, left: -99999 }}
                >
                  <textarea
                    ref={(textarea) => (this.textArea = textarea)}
                    defaultValue={shareUrl}
                  />
                </div>
              </Grid>
              <Grid item xs={12} className={classes.buttonWrapper}>
                <StyledButton
                  variant={'outlined'}
                  onClick={onCopy}
                  size="small"
                >
                  {copied ? 'Copied to your clipboard !' : 'Copy Link'}
                </StyledButton>
              </Grid>
            </>
          )}
          {object.type === 'URL' && object.showIframeOption && (
            <>
              <Grid item xs={12}>
                <div className={classes.linkBox}>{iFrameSource}</div>
                <div
                  style={{ position: 'absolute', top: -99999, left: -99999 }}
                >
                  <textarea
                    ref={(textarea) => (this.textAreaIFrame = textarea)}
                    defaultValue={iFrameSource}
                  />
                </div>
              </Grid>
              <Grid item xs={12} className={classes.buttonWrapper}>
                <StyledButton
                  variant={'outlined'}
                  onClick={onCopyIFrame}
                  size="small"
                >
                  <span style={{ padding: '0 10px' }}>
                    {copiedIFrame
                      ? 'Code copied to your clipboard!'
                      : 'Copy Code'}
                  </span>
                </StyledButton>
              </Grid>
            </>
          )}
        </Grid>
      </Dialog>
    );
  }
}

export default compose<Props, ISocialModalProps>(
  withRouter,
  withStyles(styles),
  withAccount
)(SocialModal);
