import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import caret from '../../img/caret.svg';
import { compose } from 'recompose';
import {} from '@mui/material';

export interface ICaretProps {
  rotated?: boolean;
  fontSize?: number;
}

type Props = ICaretProps & WithStyles<string>;

const styles = createStyles({
  size: {
    width: 20,
    cursor: 'pointer',
  },
  rotated: {
    transform: 'rotate(180deg)',
  },
});

class Caret extends React.Component<Props, any> {
  public render() {
    const { classes, rotated, fontSize } = this.props;

    return (
      <img
        src={caret}
        className={
          rotated ? `${classes.size} ${classes.rotated}` : classes.size
        }
        style={fontSize ? { width: fontSize } : undefined}
      />
    );
  }
}

export default compose<Props, ICaretProps>(withStyles(styles))(Caret);
