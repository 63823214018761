import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { Field, FieldArrayRenderProps, getIn } from 'formik';
import { Grid, CircularProgress } from '@mui/material';
import { compose } from 'recompose';

import Trash from '../icons/Trash';
import TextInput from './TextInput';
import * as _ from 'lodash';
import ACGLink from '../../library/inputs/ACGLink';

export interface ILinkInputProps extends FieldArrayRenderProps {}

interface IState {
  error?: any;
  loading: boolean;
}

const style = createStyles({
  root: {
    position: 'relative',
    '&:hover overlay': {
      backgroundColor: 'black',
      opacity: 1,
    },
  },
  overlay: {
    textAlign: 'right',
    marginLeft: 10,
  },
  addButton: {
    fontSize: 12,
    textDecoration: 'underline',
    padding: '20px 0',
    cursor: 'pointer',
    paddingBottom: '20px',
  },
  error: {
    color: 'red',
  },

  linksInfo: {
    width: '80%',
  },
  remove: {
    width: '70px',
  },

  linksInput: {
    width: '33%',
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  containerList: {
    width: '100%',
    paddingTop: '20px',
  },
});

type Props = ILinkInputProps & WithStyles<string>;

class LinkInput extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public addLink = () => {
    this.setState({ loading: true });
    // event.preventDefault();

    const {
      form: { values },
      name,
    } = this.props;

    let linkTitle = values.link_title;
    let linkUrl = values.link_url;

    if (name !== 'links') {
      linkTitle = _.get(values, name + '_link_title');
      linkUrl = _.get(values, name + '_link_url');
    }

    const regexURLFormula =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

    if (!linkUrl || linkUrl === '') {
      this.props.form.errors.links = 'Missing url or link';

      this.setState({ loading: false });
    } else if (!regexURLFormula.test(linkUrl)) {
      this.props.form.errors.links = 'A wrong url was used';

      this.setState({ loading: false });
    } else {
      const linkList = {
        link_title: linkTitle,
        link_url: linkUrl,
      };

      let linksListValues = values.links;
      if (!linksListValues || name !== 'links') {
        linksListValues = _.get(values, name);
      }

      linksListValues.push(linkList);

      values.link_title = '';
      values.link_url = '';

      if (name !== 'links') {
        _.set(values, name + '_link_title', '');
        _.set(values, name + '_link_url', '');
      }

      this.setState({ loading: false });
    }
  };

  public onBlurEvent = (event: any) => {
    event.persist();
    const {
      form: { values },
    } = this.props;

    let linkUrl = values.link_url;

    // @ts-ignore
    if (name !== 'links') {
      // @ts-ignore
      linkUrl = _.get(values, name + '_link_url');
    }

    const regexURLFormula =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

    if (!regexURLFormula.test(linkUrl)) {
      this.props.form.errors.links = 'A wrong url was used';
    }
  };

  public render() {
    const {
      form: { values, errors },
      name,
      remove,
      classes,
    } = this.props;

    const error = getIn(errors, name);

    let linksListValues = values.links;

    if (!linksListValues || name !== 'links') {
      linksListValues = _.get(values, name);
    }

    const urlFieldName = name !== 'links' ? name + '_link_url' : 'link_url';
    const titleFieldName =
      name !== 'links' ? name + '_link_title' : 'link_title';

    return (
      <React.Fragment>
        <Grid
          container={true}
          direction="row"
          // xs={12}
        >
          <div className={classes.containerList}>
            {linksListValues.map((val: any, i: number) => {
              const onClick = () => remove(i);
              return (
                <div className={classes.container} key={`link_elem_${i}`}>
                  <div className={classes.remove}>
                    <span onClick={onClick} className={classes.overlay}>
                      <Trash big={true} />
                    </span>
                  </div>
                  <div className={classes.linksInfo}>
                    {val.link_title && <p>{val.link_title}</p>}
                    <p>{val.link_url}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </Grid>
        <Grid
          container={true}
          // xs={12}
          direction="row"
          spacing={1}
        >
          <Grid item={true} xs={12} sm={6}>
            <Field
              name={urlFieldName}
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: urlFieldName,
                label: 'Share a link',
                type: 'link_url',
                onBlur: this.onBlurEvent,
              }}
            />
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <Field
              name={titleFieldName}
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: titleFieldName,
                label: 'Link Title',
                type: 'link_title',
              }}
            />
          </Grid>
        </Grid>

        {this.state.loading ? (
          <CircularProgress />
        ) : (
          <ACGLink resize={12} onClick={this.addLink}>
            Add more links
          </ACGLink>
        )}
        {error && typeof errors[name] === 'string' ? (
          <div className={classes.error}>{error}</div>
        ) : undefined}
      </React.Fragment>
    );
  }
}

export default compose<Props, ILinkInputProps>(withStyles(style))(LinkInput);
