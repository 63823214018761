import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthListenersParams, createAuthListeners } from './authenticator';
import { Dispatch } from 'react';
import { User } from '@acg/auth/auth';
import { ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';

export interface AuthState {
  shouldShowAuthUI: boolean;
  renderAuthenticator: boolean;
  user?: User;
  accessToken?: string;
}

const initialState: AuthState = {
  shouldShowAuthUI: false,
  renderAuthenticator: false,
};

const updateRenderAuthenticator = (state: AuthState) => {
  const { shouldShowAuthUI, user } = state;
  state.renderAuthenticator = !user && shouldShowAuthUI;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User | undefined>) {
      const user = action.payload;
      if (user) {
        state.shouldShowAuthUI = false;
      }
      state.user = user;
      state.accessToken = user?.signInUserSession.idToken.jwtToken;
      updateRenderAuthenticator(state);
    },
    setAccessToken(state, action: PayloadAction<string>) {
      state.accessToken = action.payload;
    },
    showAuthUI(state) {
      state.shouldShowAuthUI = true;
      updateRenderAuthenticator(state);
    },
    hideAuthUI(state) {
      state.shouldShowAuthUI = false;
      updateRenderAuthenticator(state);
    },
  },
});

export const { setUser, showAuthUI, hideAuthUI, setAccessToken } =
  authSlice.actions;

export const reduxAuthManagement = (dispatch: Dispatch<AnyAction>) => {
  const authListenerParams: AuthListenersParams = {
    onSignInOrOut: (user) => dispatch(setUser(user)),
    onTokenRefresh: (accessToken) => dispatch(setAccessToken(accessToken)),
  };
  createAuthListeners(authListenerParams);
};

const reducer = combineReducers({
  authReducer: authSlice.reducer,
});

export type IAuthRootState = ReturnType<typeof reducer>;
