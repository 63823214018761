import { ActionType } from 'typesafe-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import UserService from '../../../services/UserService';
import { IUser } from '../../../types/user';

export function* updateProfile({
  payload,
}: ActionType<typeof actions.update>): any {
  try {
    const service = new UserService();

    const user = yield call(service.updateProfile, payload.request);

    yield put(actions.updateSuccess(user));
  } catch (error: any) {
    yield put(actions.loginError(error));
  }
}

export function* getAccount(action: ActionType<typeof actions.getAccount>) {
  try {
    const service = new UserService();

    const { data } = yield call(service.getAccount);
    const userData = data[0] ? data[0] : data;
    if (data[1]) {
      userData.manages = data[1];
    }
    yield put(
      actions.getAccountSuccess({ user: formatAccountLocation(userData) })
    );
  } catch (error: any) {
    yield put(actions.logoutError(error));
  }
}

export function* acceptEthicCode(
  action: ActionType<typeof actions.getAccount>
): any {
  try {
    const service = new UserService();

    const data = yield call(service.acceptEthicCode);
    yield put(actions.acceptEthicCodeSuccess(data));
  } catch (error: any) {
    window.console.log('[acceptEthicCode] error: ', error);
    yield put(actions.acceptEthicCodeError());
  }
}

const formatAccountLocation = (data: IUser): IUser => {
  return {
    ...data,
    location: {
      value:
        data.location && data.location.address
          ? data.location.address
          : data.city,
      address:
        data.location && data.location.address
          ? data.location.address
          : data.city,
      placeId:
        data.location && data.location.placeId ? data.location.placeId : '',
      latLng:
        data.location && data.location.latLng ? data.location.latLng : [0, 0],
    },
  };
};

export default all([
  takeLatest('@@account/GET_ACCOUNT', getAccount),
  takeLatest('@@account/UPDATE_ACCOUNT', updateProfile),
  takeLatest('@@account/ACCEPT_ETHIC_CODE', acceptEthicCode),
]);
