import { IUser, ICuratorUser, IViewerUser, IArtistUser } from '../types/user';

type UserRight = 'ALL' | 'VIEWER' | 'PENDING' | 'MANAGER' | 'ARTIST';

export const checkRight = (user: IUser|undefined): UserRight => {

  if (!user) {
    return 'VIEWER';
  }

  const checkCuratorRight = (u: ICuratorUser): UserRight => {
    if (u.isTouring && u.pending) {
      return 'VIEWER';
    } else if (!u.pending) {
      return 'ALL';
    }
    return 'PENDING';
  };

  const checkArtistRight = (u: IArtistUser): UserRight => {
    if (u.isTouring && u.pending) {
      return 'VIEWER';
    }
    // else if(u.manages && u.manages.length > 0) {
    //   return 'MANAGER'
    // }
    else if (!u.pending) {
      return 'ARTIST';
    }
    return 'VIEWER';
  };

  const checkViewerRight = (u: IViewerUser): UserRight => {
    if (u.manages && u.manages.length > 0) {
      return 'MANAGER';
    }
    return 'VIEWER';
  };

  if (!user || !user.profile) {
    return 'VIEWER';
  }

  switch (user.profile) {
    case 'CURATOR':
      return checkCuratorRight(user);
    case 'VIEWER':
      return checkViewerRight(user);
    case 'ARTIST':
      return checkArtistRight(user);
  }
};
