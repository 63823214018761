import * as React from 'react';

export interface IIconACGCrossProps {
  fill?: string;
  height?: number;
  style?: any;
}

const IconACGCross = (props: IIconACGCrossProps) => {
  const style: React.CSSProperties = {
    height: props.height || 24,
    backgroundColor:
      (props.style && props.style.backgroundColor) || 'transparent',
    borderRadius: (props.style && props.style.borderRadius) || 6,
  };
  return (
    <svg viewBox="0 0 1080 1080" style={style}>
      <rect
        transform="rotate(90 540.0000000000001,323.3900451660157) "
        stroke="null"
        rx="13"
        id="svg_8"
        height="26.47161"
        width="320.60056"
        y="310.15424"
        x="379.69972"
        opacity="undefined"
        strokeDasharray="null"
        strokeWidth="30"
        fill={props.fill || 'black'}
      />
      <rect
        transform="rotate(90 540,757.4989013671876) "
        stroke="null"
        rx="13"
        id="svg_7"
        height="26.47161"
        width="320.60056"
        y="744.2631"
        x="379.69972"
        opacity="undefined"
        strokeDasharray="null"
        strokeWidth="30"
        fill={props.fill || 'black'}
      />
      <rect
        stroke="null"
        rx="13"
        id="svg_12"
        height="26.47161"
        width="320.60056"
        y="527.69864"
        x="594.92034"
        opacity="undefined"
        strokeDasharray="null"
        strokeWidth="30"
        fill={props.fill || 'black'}
      />
      <rect
        stroke="null"
        rx="13"
        id="svg_9"
        height="26.47161"
        width="320.60056"
        y="527.69864"
        x="161.41192"
        opacity="undefined"
        strokeDasharray="null"
        strokeWidth="30"
        fill={props.fill || 'black'}
      />
    </svg>
  );
};

export default IconACGCross;
