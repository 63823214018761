import { ReqOptions, ResOptions, Route } from 'typesafe-api';
import {
  ArtpoolApiEndpoint,
  JsonNftWithRawFile,
  JsonNft,
} from '@acg/artpool-api-spec';

/**
 * Send Print request Email
 */

export const sendPrintRequestEmailRoute: Route = {
  method: 'post',
  path: '/email/print',
};

export interface printRequestEmailReq extends ReqOptions {
  body: {
    nft: JsonNftWithRawFile;
  };
}

export type SendPrintRequestEmailEndpointDef = ArtpoolApiEndpoint<
  printRequestEmailReq,
  ResOptions
>;

/**
 * Send Purchased NFT Email
 */

export const sendPurchasedNFTEmailRoute: Route = {
  method: 'post',
  path: '/email/purchased',
};

export interface purchasedNFTEmailReq extends ReqOptions {
  body: {
    nft: JsonNft;
    tsxHash: string;
  };
}

export type SendPurchasedNFTEmailEndpointDef = ArtpoolApiEndpoint<
  purchasedNFTEmailReq,
  ResOptions
>;
