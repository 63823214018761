import * as React from 'react';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { ClickAwayListener, Grid } from '@mui/material';
import { SPACING } from '../../styles/constants';

const PREFIX = 'ACGModalContent';

const classes = {
  modal: `${PREFIX}-modal`,
  container: `${PREFIX}-container`,
};

const StyledGrid = styled(Grid)({
  [`&.${classes.modal}`]: {
    backgroundColor: 'white',
    padding: SPACING,
    overflowY: 'auto',
  },
  [`& .${classes.container}`]: {
    height: '100%',
    overflowY: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
  },
});

export interface IACGModalProps {
  onClickAway?: () => void;
  id?: string;
  children?: React.ReactNode;
}

const freezeVp: EventListener = (e) => e.preventDefault();

const addEventListeners = () => {
  const root = document.getElementById('root');
  if (root) {
    root.addEventListener('touchstart', freezeVp, { passive: false });
    root.addEventListener('touchmove', freezeVp, { passive: false });
  }
};

const removeEventListeners = () => {
  const root = document.getElementById('root');
  if (root) {
    root.removeEventListener('touchstart', freezeVp, false);
    root.removeEventListener('touchmove', freezeVp, false);
  }
};

const ACGModalContent = (props: IACGModalProps) => {
  const { children, onClickAway, id } = props;

  useEffect(() => {
    addEventListeners();
    return removeEventListeners;
  }, []);

  let content = (
    <StyledGrid className={classes.modal} item={true} xs={12} sm={8}>
      {children}
    </StyledGrid>
  );

  if (onClickAway) {
    content = (
      <ClickAwayListener onClickAway={onClickAway}>{content}</ClickAwayListener>
    );
  }

  return (
    <Grid
      id={id}
      container={true}
      className={classes.container}
      justifyContent="center"
    >
      {content}
    </Grid>
  );
};

export default ACGModalContent;
