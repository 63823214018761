import { Amplify, Auth } from 'aws-amplify';
import { awsExports } from '@acg/auth/auth';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { User } from '@acg/auth/auth';

export const setUpAuth = () => {
  return Amplify.configure(awsExports);
};

export const parseCognitoUser = (
  user: CognitoUser | undefined
): User | undefined =>
  user && (JSON.parse(JSON.stringify(user)) as unknown as User);

export const authenticatedUser = async (): Promise<User | undefined> => {
  try {
    return parseCognitoUser(await Auth.currentAuthenticatedUser());
  } catch (err) {
    console.log(err);
    return;
  }
};
