import { Grid } from '@mui/material';
import {
  Body1,
  Body2,
  clippedCorners,
  StyledButton,
  Subtitle2,
  Subtitle3,
  Caption,
} from '@acg/frontend-ui-artpool';
import { ARTPOOL_MAIL_URL } from '@acg/shared/const';
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import { themes } from '@acg/assets';

export const InfoSection = () => {
  const isSmall = useMediaQuery(themes.artpoolTheme.breakpoints.down('md'));
  const IMAGE_PATH = '/assets/images/artpool/services/';

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} style={{ position: 'relative' }}>
          <img
            src={IMAGE_PATH + 'celeste-banner.png'}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              ...clippedCorners,
            }}
          />
          <Body2 style={{ marginTop: 0 }}>
            NFT by Solimán López commissioned by Design Hotels for Further
            Lisbon, Facing Hyperreality
          </Body2>
        </Grid>
        <Grid item xs={12} md={6} sx={{ p: 5 }}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <h1>Dive into the digital art realm</h1>
            </Grid>
            <Grid item xs={12}>
              <Body1>
                Our team is well-versed in the digital space and the exciting
                world of NFTs.
                <b />
                <b />
                We can provide guidance and insights on incorporating digital
                aspects into your projects, ensuring you stay at the forefront
                of artistic innovation.
              </Body1>
            </Grid>
            <Grid item xs={12}>
              <Subtitle3>
                <b>What we do:</b>
              </Subtitle3>
            </Grid>
            <Grid item xs={12}>
              <Body1>
                • Curatorial Services: we offer personalized curation services
                for art collections, exhibitions, and events. We curate the
                physical and the digital.
                <br /><br />
                • NFT Integration and Digital Art Consultation: we advise
                leveraging NFTs (Non-Fungible Tokens) for digital art. We guide
                you through the process of minting, selling, and managing
                NFT-based artwork on various blockchain platforms.
                <br /><br />
                • Commission artists to create digital art sold as NFTs to
                enhance your collection or reach out to your community.
                <br /><br />
                • Personalized Art Recommendations: we research and advise on
                digital art pieces based on your preferences, budget, and
                collecting goals.
              </Body1>
            </Grid>

            <Grid item xs={12}>
              <a href={ARTPOOL_MAIL_URL}>
                <StyledButton variant={'contained'} fullWidth={isSmall}>
                  <span style={{ padding: '5px 20px' }}>Contact Us</span>
                </StyledButton>
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ p: 5 }}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <h3>
                <b>Education</b>
              </h3>
            </Grid>
            <Grid item xs={12}>
              <Subtitle2>
                Engage with your employees, clients or investors in a playful,
                interactive way by inviting the Artpool team to your next event.
                <br />
                <br />
                • NFT & Digital Art Workshops
                <br />
                • Courses in Crypto Basics
                <br />
                • Crypto Wallet How-To
                <br />
                • Curator/Artist Q&A
                <br />• Art Industry Lectures
              </Subtitle2>
            </Grid>

            <Grid item xs={12}>
              <a href={ARTPOOL_MAIL_URL}>
                <StyledButton variant={'contained'} fullWidth={isSmall}>
                  <span style={{ padding: '5px 20px' }}>
                    Schedule an appointment
                  </span>
                </StyledButton>
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} order={isSmall ? -1 : undefined}>
          <img
            src={IMAGE_PATH + 'education.png'}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              ...clippedCorners,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
