import React from 'react';
import styles from './subtitle2.module.scss';

export interface Subtitle2Props
  extends React.HTMLAttributes<HTMLParagraphElement> {
  children?: React.ReactNode;
}

export const Subtitle2 = (props: Subtitle2Props) => {
  const { children, ...rest } = props;

  return (
    <p className={styles.root} {...rest}>
      {props.children}
    </p>
  );
};

export default Subtitle2;
