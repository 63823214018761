import { createRouteRequest } from 'typesafe-api';
import { AuthApiClient } from '@acg/shared/api-spec-utils';
import {
  GetUsersEndpointDef,
  GetUsersOptions,
  getUsersRoute,
  GetMainCuratorsEndpointDef,
  getMainCuratorsRoute,
} from '../endpoints/user';
import { UserProfileType } from '@acg/api-interfaces';

export class UserClient extends AuthApiClient {
  /**
   * Get Users
   */
  private _getUsers = createRouteRequest<GetUsersEndpointDef>(
    this,
    getUsersRoute
  );

  public getUsers = (options: GetUsersOptions) =>
    this._getUsers({
      query: { profiles: options.profiles, search: options.search },
    });

  /**
   * Get Main Curators
   */

  private _getMainCurators = createRouteRequest<GetMainCuratorsEndpointDef>(
    this,
    getMainCuratorsRoute
  );

  public getMainCurators = () => this._getMainCurators({});
}
