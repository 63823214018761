import React from 'react';
import { Grid } from '@mui/material';
import { ArtpoolSkeleton } from '@acg/frontend-ui-artpool';
import Stack from '@mui/material/Stack';

export const UserSkeleton = () => {
  return (
    <Grid container spacing={1}>
      <Grid item>
        <ArtpoolSkeleton width={40} height={40} style={{ borderRadius: 20 }} />
      </Grid>
      <Grid item>
        <Stack spacing={1}>
          <ArtpoolSkeleton width={40} height={10} />
          <ArtpoolSkeleton width={100} height={10} />
        </Stack>
      </Grid>
    </Grid>
  );
};
