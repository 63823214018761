import { AuthApiRoot } from '@acg/shared/api-spec-utils';
import { AuthMigrationClient } from './auth-migration';
import { PostsClient } from './posts';

export class ArtcuratorgridApiClient extends AuthApiRoot {
  public authMigrationApi = () =>
    new AuthMigrationClient(this.getChildParams());

  public postsApi = () => new PostsClient(this.getChildParams());
}
