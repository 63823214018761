import Grid from '@mui/material/Grid';
import { NEUTRAL } from '@acg/assets';
import { ReadMoreCard } from '@acg/frontend-ui-artpool';
import { BLOG_URL, CURTAIN_URL } from '@acg/shared/const';
import React from 'react';

export const BlogMagazineSection = () => {
  const content: { bgColor: string; text: string; link: string }[] = [
    { bgColor: NEUTRAL[50], text: 'Blog', link: BLOG_URL },
    { bgColor: 'white', text: 'Curtain Magazine', link: CURTAIN_URL },
  ];

  return (
    <Grid container sx={{ height: 385 }}>
      {content.map((el) => {
        return (
          <Grid item xs={12} md={6} key={el.text}>
            <ReadMoreCard
              hoverColor
              bgColor={el.bgColor}
              text={el.text}
              link={{ url: el.link, external: true }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
