import { AccountAction, IAccountState } from './types';

const initialState: IAccountState = {
  account: undefined,
  isLoading: false,
  error: undefined,
};

export default function account(
  state = initialState,
  action: AccountAction
): IAccountState {
  switch (action.type) {
    case '@@account/LOGIN':
      return {
        ...state,
        isLoading: true,
      };
    case '@@account/LOGIN_SUCCESS':
      return {
        ...state,
        isLoading: false,
        error: undefined,
      };
    case '@@account/GET_ACCOUNT':
      return {
        ...state,
        isLoading: true,
      };
    case '@@account/GET_ACCOUNT_SUCCESS':
      return {
        ...state,
        isLoading: false,
        account: action.payload.user,
        error: undefined,
      };
    case '@@account/LOGOUT':
      return {
        ...state,
        isLoading: true,
      };
    case '@@account/LOGOUT_SUCCESS':
      return {
        ...state,
        isLoading: false,
        account: undefined,
        error: undefined,
      };
    case '@@account/ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case '@@account/UPDATE_ACCOUNT_DATA':
      return {
        ...state,
        account: action.payload.user,
        isLoading: false,
      };

    case '@@account/ACCEPT_ETHIC_CODE':
      return {
        ...state,
        isLoading: true,
      };
    case '@@account/ACCEPT_ETHIC_CODE_SUCCESS':
      const accountData = state.account;
      const codeOfEthic = action.payload.data;
      if (accountData) {
        accountData.codeOfEthic = codeOfEthic;
      }

      return {
        ...state,
        isLoading: false,
        account: accountData,
        error: undefined,
      };

    default:
      return state;
  }
}
