import * as React from 'react';
import { ButtonProps } from '@mui/material/Button';
import { StyledButton } from '@acg/frontend-ui-artpool';

export interface IFormSubmitProps extends ButtonProps {
  draft?: boolean;
  small?: boolean;
  outlined?: boolean;
  newButton?: boolean;
}

export default (props: IFormSubmitProps) => {
  const { small, className, outlined, newButton, children, ...rest } = props;

  return (
    <StyledButton
      {...rest}
      variant={outlined ? 'outlined' : 'contained'}
      size="large"
      aria-label="Add"
      type="submit"
      fullWidth
    >
      {children}
    </StyledButton>
  );
};
