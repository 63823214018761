import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import arrow from '../../img/arrow.svg';
import { compose } from 'recompose';
import {} from '@mui/material';

export interface IArrowProps {}

type Props = IArrowProps & WithStyles<string>;

const styles = createStyles({
  size: {
    width: 14,
    cursor: 'pointer',
  },
});

class Arrow extends React.Component<Props, any> {
  public render() {
    const { classes } = this.props;

    return <img src={arrow} className={classes.size} />;
  }
}

export default compose<Props, IArrowProps>(withStyles(styles))(Arrow);
