import { ActionType } from 'typesafe-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import ExhibitionService from '../../../services/ExhibitionService';

export function* getExhibitions(
  action: ActionType<typeof actions.getExhibitions>
) {
  try {
    const service = new ExhibitionService();
    const { data } = yield call(service.getExhibitions);

    yield put(actions.getExhibitionsSuccess(data));
  } catch (error: any) {
    yield put(actions.getExhibitionsError(error));
  }
}

export function* getExhibition({
  payload,
}: ActionType<typeof actions.getExhibition>) {
  try {
    const service = new ExhibitionService();
    const { data } = yield call(
      service.getExhibition,
      payload.exhibitionId,
      payload.loggedUserId,
      payload.filters
    );
    yield put(actions.getExhibitionSuccess(data));
  } catch (error: any) {
    yield put(actions.getExhibitionError(error));
  }
}

export function* createExhibition({
  payload,
}: ActionType<typeof actions.createExhibition>) {
  try {
    const service = new ExhibitionService();
    const { data } = yield call(service.createExhibition, payload.exhibition);

    yield put(actions.createExhibitionSuccess(data));
  } catch (error: any) {
    window.console.log('error ', error);
    yield put(actions.createExhibitionError(error));
  }
}

export function* updateExhibition({
  payload,
}: ActionType<typeof actions.updateExhibition>) {
  try {
    const service = new ExhibitionService();
    const { data } = yield call(
      service.updateExhibition,
      payload.id,
      payload.exhibition,
      payload.editKey
    );

    yield put(actions.updateExhibitionSuccess(data));
  } catch (error: any) {
    yield put(actions.updateExhibitionError(error));
  }
}

export function* likeExhibition({
  payload,
}: ActionType<typeof actions.likeExhibition>) {
  try {
    const service = new ExhibitionService();
    const { data } = yield call(service.likeExhibition, payload.exhibitionId);
    yield put(actions.likeExhibitionSuccess(data));
  } catch (error: any) {
    yield put(actions.likeExhibitionError(error));
  }
}

export function* hasExhibitionAccess() {
  try {
    const service = new ExhibitionService();
    const { data } = yield call(service.hasExhibitionAccess);
    yield put(actions.hasExhibitionAccessSuccess(data.isExhibitionAdmin));
  } catch (error: any) {
    yield put(actions.hasExhibitionAccessError(error));
  }
}

export function* getEditExhibition({
  payload,
}: ActionType<typeof actions.getEditExhibition>) {
  try {
    const service = new ExhibitionService();
    const { data } = yield call(
      service.getExhibitionData,
      payload.exhibitionId,
      payload.editKey
    );
    yield put(actions.getEditExhibitionSuccess(data));
  } catch (error: any) {
    yield put(actions.getExhibitionError(error));
  }
}

export function* deleteExhibition({
  payload,
}: ActionType<typeof actions.deleteExhibition>) {
  try {
    const service = new ExhibitionService();
    yield call(service.deleteExhibition, payload.exhibitionId);
    yield put(actions.deleteExhibitionSuccess());
  } catch (error: any) {
    yield put(actions.deleteExhibitionError(error));
  }
}

export function* inviteEditExhibition({
  payload,
}: ActionType<typeof actions.inviteEditExhibition>) {
  try {
    const service = new ExhibitionService();
    yield call(
      service.sendInvitationEmail,
      payload.exhibitionId,
      payload.inviteEmail
    );
    yield put(actions.inviteEditExhibitionSuccess(payload.inviteEmail));
  } catch (error: any) {
    yield put(actions.deleteExhibitionError(error));
  }
}

export default all([
  takeLatest('@@exhibitions/GET_EXHIBITIONS', getExhibitions),
  takeLatest('@@exhibitions/GET_EXHIBITION', getExhibition),
  takeLatest('@@exhibitions/CREATE_EXHIBITION', createExhibition),
  takeLatest('@@exhibitions/UPDATE_EXHIBITION', updateExhibition),
  takeLatest('@@exhibitions/LIKE_EXHIBITION', likeExhibition),
  takeLatest('@@exhibitions/HAS_EXHIBITION_ACCESS', hasExhibitionAccess),
  takeLatest('@@exhibitions/GET_EDIT_EXHIBITION', getEditExhibition),
  takeLatest('@@exhibitions/DELETE_EXHIBITION', deleteExhibition),
  takeLatest('@@exhibitions/INVITE_EDIT_EXHIBITION', inviteEditExhibition),
]);
