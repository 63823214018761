import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { Grid } from '@mui/material';
import { withAccount } from '../../containers/account/InjectAccount';
import Title from '../../library/dataDisplay/typography/Title';
import { getImageUrl } from '../../utils/imageUrl';
import { ILink } from '../../types/links';
import { ReactTinyLink } from 'react-tiny-link';
import ReactPlayer from 'react-player';
import OnlineExhibitionLayout from '../../library/layout/container/OnlineExhibitionLayout';
import SubTitle from '../../library/dataDisplay/typography/SubTitle';
import { dateToStringParser } from '../../utils/datetimehelper';
import { IExhibitionPoint } from '../../types/exhibitions';
import { INFO_POINT_ICON } from '../../styles/constants';
import Text from '../../library/dataDisplay/typography/Text';

export interface IExhibitionInfoPointProps {
  orderValue: string;
  exhibitionPoint: IExhibitionPoint;
  onlineExhibitionData: any;
  mobile: boolean;
}

const style = createStyles({
  root: {
    padding: '0 22px 22px 22px',
    minHeight: '100vh',
  },
  containerPadding: {
    paddingTop: 20,
  },
  orderValue: {
    fontSize: 70,
    textAlign: 'center',
  },
  exhibitionTitle: {
    fontSize: 30,
    paddingLeft: 20,
    paddingTop: 10,
  },
  exhibitionDate: {
    fontSize: 20,
    color: '#afb0b3',
    paddingLeft: 20,
  },

  artistTitle: {
    fontSize: 30,
    color: '#000',
  },

  artistTitleMobile: {
    fontSize: 20,
    color: '#000',
  },

  descriptionText: {
    fontSize: 20,
    color: '#afb0b3',
  },

  curatorialText: {
    fontSize: 20,
    color: '#000',
  },

  text: {
    fontSize: 16,
    overflowWrap: 'break-word',
  },
  textInBlack: {
    fontSize: 16,
    overflowWrap: 'break-word',
    color: 'black',
  },
  centerImage: {
    // width: 480
    // height: 500,
    width: '100%',
    textAlign: 'center',
  },

  imageArtPoint: {
    width: 480,
  },

  mainImage: {
    maxHeight: 500,
    maxWidth: '100%',
  },
  infoPointImage: {
    maxHeight: 20,
    opacity: 0.35,
    paddingRight: 5,
  },

  image: {
    width: '100%',
  },
  imageCredit: {
    fontSize: 15,
    color: '#afb0b3',
    textAlign: 'center',
  },
});

interface IState {}

type Props = IExhibitionInfoPointProps & WithStyles<string> & WithWidth;

class ExhibitionInfoPoint extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
  }

  public renderLinks = (links: ILink[]) => {
    const { classes } = this.props;

    const linksList = links.map((link, index) => {
      if (!link.link_url.includes('http')) {
        link.link_url = 'http://' + link.link_url;
      }
      const regexURLFormula =
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

      const onErrorEvt = () => {
        window.console.log('onErrorEvt');
        return (
          <Grid
            item={true}
            xs={12}
            key={`artpoint_link_${index}`}
            style={{ margin: '20px 0' }}
          >
            <Title>{link.link_title}</Title> <br />
            <a href={link.link_url} target="_blank">
              {' '}
              {link.link_url}
            </a>
            <br />
          </Grid>
        );
      };

      // Case the url is miss-speld
      if (!regexURLFormula.test(link.link_url)) {
        return (
          <Grid
            item={true}
            xs={12}
            key={`artpoint_link_${index}`}
            style={{ margin: '20px 0' }}
          >
            <Title>{link.link_title}</Title> <br />
            <a href={link.link_url} target="_blank">
              {' '}
              {link.link_url}
            </a>
            <br />
          </Grid>
        );
      }
      return (
        <Grid
          item={true}
          xs={12}
          key={`link_${index}`}
          style={{ margin: '20px 0' }}
        >
          <Title>{link.link_title}</Title> <br />
          <ReactTinyLink
            cardSize="small"
            showGraphic={true}
            maxLine={2}
            minLine={1}
            width={'100%'}
            url={link.link_url}
            // proxyUrl={"https://cors-anywhere.herokuapp.com"}
            onError={onErrorEvt}
          />
          <br />
        </Grid>
      );
    });

    const linksSection = (
      <Grid
        item={true}
        xs={12}
        key={'links_section'}
        style={{ margin: '20px 0' }}
      >
        {linksList}
      </Grid>
    );

    return linksSection;
  };

  public render() {
    const {
      onlineExhibitionData,
      orderValue,
      exhibitionPoint,
      classes,
      mobile,
    } = this.props;

    let mask = 0;

    if (onlineExhibitionData) {
      mask = onlineExhibitionData.start_date ? mask | 1 : mask;
      mask = onlineExhibitionData.end_date ? mask | 2 : mask;
      mask = onlineExhibitionData ? mask | 4 : mask;
    }

    return (
      <OnlineExhibitionLayout type={mobile ? 'ARTPOINTMOBILE' : 'ARTPOINT'}>
        <Grid container={true} className={classes.root}>
          {!mobile && (
            <Grid container={true} spacing={2}>
              <Grid item={true} sm={1}>
                <Title className={classes.orderValue}>{orderValue}</Title>
              </Grid>
              <Grid item={true} sm={11}>
                <Title className={classes.exhibitionTitle}>
                  {onlineExhibitionData.title}
                </Title>
                {(mask === 1 || mask === 5) && (
                  <>
                    <SubTitle className={classes.exhibitionDate}>
                      Start Date{' '}
                      {dateToStringParser(
                        onlineExhibitionData.start_date,
                        'MMM Do YYYY'
                      )}{' '}
                    </SubTitle>
                  </>
                )}
                {(mask === 2 || mask === 6) && (
                  <>
                    <SubTitle className={classes.exhibitionDate}>
                      End Date{' '}
                      {dateToStringParser(
                        onlineExhibitionData.end_date,
                        'MMM Do YYYY'
                      )}{' '}
                    </SubTitle>
                  </>
                )}
                {(mask === 3 || mask === 7) && (
                  <>
                    <SubTitle className={classes.exhibitionDate}>
                      {dateToStringParser(
                        onlineExhibitionData.start_date,
                        'MMM Do YYYY'
                      )}{' '}
                      -{' '}
                      {dateToStringParser(
                        onlineExhibitionData.end_date,
                        'MMM Do YYYY'
                      )}
                    </SubTitle>
                  </>
                )}
              </Grid>
            </Grid>
          )}
          <Grid container={true} alignItems={'center'}>
            <img src={INFO_POINT_ICON} className={classes.infoPointImage} />
            <SubTitle>
              <h3 style={{ fontWeight: 'normal' }}>Information Point</h3>
            </SubTitle>
          </Grid>

          <Grid container={true} className={classes.containerPadding}>
            <Text
              className={
                mobile ? classes.artistTitleMobile : classes.artistTitle
              }
            >
              {exhibitionPoint.title}
            </Text>
          </Grid>

          {exhibitionPoint.imagesWithCredit &&
            exhibitionPoint.imagesWithCredit[0] &&
            exhibitionPoint.imagesWithCredit[0].image_url !==
              INFO_POINT_ICON && (
              <Grid container={true} className={classes.containerPadding}>
                <Grid
                  item={true}
                  xs={12}
                  sm={12}
                  className={classes.centerImage}
                >
                  <img
                    src={getImageUrl(
                      exhibitionPoint.imagesWithCredit[0].image_url
                    )}
                    className={classes.mainImage}
                  />
                </Grid>
                <Grid
                  item={true}
                  xs={12}
                  sm={12}
                  className={classes.imageCredit}
                >
                  <p className={classes.creditText}>
                    {exhibitionPoint.imagesWithCredit[0].image_credit}
                  </p>
                </Grid>
              </Grid>
            )}

          {(!exhibitionPoint.imagesWithCredit ||
            (exhibitionPoint.imagesWithCredit &&
              exhibitionPoint.imagesWithCredit.length === 0)) &&
            exhibitionPoint.videos &&
            exhibitionPoint.videos[0] && (
              <Grid container={true} className={classes.containerPadding}>
                <Grid
                  item={true}
                  xs={12}
                  key={`_videos_0`}
                  style={{ margin: '20px 0', textAlign: 'center' }}
                >
                  <div className="playerWrapper">
                    <ReactPlayer
                      className="reactPlayer"
                      url={exhibitionPoint.videos[0].video_link}
                      playing={false}
                      width="100%"
                      controls={true}
                      vimeo={{ playerOptions: { height: '463px' } }}
                    />
                    <br />
                  </div>
                </Grid>
                <Grid
                  item={true}
                  xs={12}
                  sm={12}
                  className={classes.imageCredit}
                >
                  <p className={classes.creditText}>
                    {exhibitionPoint.videos[0].video_title}
                  </p>
                </Grid>
              </Grid>
            )}

          <Grid container={true} className={classes.containerPadding}>
            <Grid item={true} xs={12}>
              <Title className={classes.curatorialText}>
                {exhibitionPoint.group}
              </Title>
            </Grid>
            <Grid item={true} xs={12}>
              <Title className={classes.curatorialText}>
                {exhibitionPoint.curatorial_text}
              </Title>
            </Grid>
          </Grid>

          <Grid container={true} className={classes.containerPadding}>
            <Grid
              item={true}
              xs={12}
              key={'videos_section'}
              style={{ margin: '20px 0' }}
            >
              {exhibitionPoint.videos &&
                exhibitionPoint.videos.map((video, index) => {
                  if (
                    (!exhibitionPoint.imagesWithCredit ||
                      (exhibitionPoint.imagesWithCredit &&
                        exhibitionPoint.imagesWithCredit.length === 0)) &&
                    index === 0
                  ) {
                    return;
                  }

                  return (
                    <>
                      <Grid
                        item={true}
                        xs={12}
                        key={`_videos_${index}`}
                        style={{ margin: '20px 0' }}
                      >
                        <div className="playerWrapper">
                          <ReactPlayer
                            className="reactPlayer"
                            url={video.video_link}
                            playing={false}
                            width="100%"
                            controls={true}
                            vimeo={{ playerOptions: { height: '463px' } }}
                          />
                          <br />
                        </div>
                      </Grid>
                      <Grid
                        item={true}
                        xs={12}
                        sm={12}
                        className={classes.imageCredit}
                      >
                        <p className={classes.creditText}>
                          {video.video_title}
                        </p>
                      </Grid>
                    </>
                  );
                })}
            </Grid>
          </Grid>

          <Grid container={true} className={classes.containerPadding}>
            <Grid item={true} xs={12}>
              {exhibitionPoint.links && this.renderLinks(exhibitionPoint.links)}
            </Grid>
          </Grid>
          <Grid container={true} className={classes.containerPadding}>
            {exhibitionPoint.imagesWithCredit &&
              exhibitionPoint.imagesWithCredit.map((image, index) => {
                if (index > 0) {
                  return (
                    <Grid
                      item={true}
                      xs={12}
                      key={'_image_' + image.id}
                      className={classes.centerImage}
                    >
                      <img
                        className={classes.image}
                        src={getImageUrl(image.image_url)}
                      />
                      <p className={classes.creditText}>{image.image_credit}</p>
                    </Grid>
                  );
                }
                return;
              })}
          </Grid>
        </Grid>
      </OnlineExhibitionLayout>
    );
  }
}

export default compose<Props, IExhibitionInfoPointProps>(
  withStyles(style),
  withAccount
)(ExhibitionInfoPoint);
