import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { withAccount, IWithAccountProps } from '../account/InjectAccount';
import { Box, Grid } from '@mui/material';
import StyledButton from '../../components/buttons/StyledButton';
import * as accountActions from '../../store/modules/account/actions';
import { useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { DARK_GREY_COLOR, PRIMARY_COLOR } from '../../styles/constants';

type Props = IWithAccountProps & WithStyles<string>;

const styles = createStyles({
  title: {
    color: DARK_GREY_COLOR,
    textAlign: 'center',
  },
  button: {
    color: '#000000',
    padding: '12px',
    '&:hover': {
      backgroundColor: PRIMARY_COLOR,
    },
  },
});

const Signup2: React.FC<Props> = (props) => {
  const {
    account,
    classes,
    // width
  } = props;

  const dispatch = useDispatch();

  const handleSubmit = (event: any) => {
    let profile: any;
    let pendingValue = false;

    switch (event.target.textContent) {
      case 'Curator':
        profile = 'CURATOR';
        pendingValue = true;
        break;
      case 'Artist':
        profile = 'ARTIST';
        pendingValue = true;
        break;
      default:
        profile = 'VIEWER';
    }

    dispatch(
      accountActions.update({
        request: {
          ...account,
          profile,
          type: event.target.textContent.toUpperCase(),
          pending: pendingValue,
        },
      })
    );

    setTimeout(() => location.reload(), 1000);
  };

  // const hidden = isWidthDown(BREAKPOINT, width);
  // const logoHidden = isWidthDown("xs", width)

  return (
    <Box
      mx={2}
      style={{ height: 'calc(100vh - 90px)', paddingLeft: 15 }}
      justifyContent="center"
      display="flex"
    >
      <Grid container={true} direction="column" spacing={2}>
        <Grid
          container={true}
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid
            item={true}
            // container={true}
            // justifyContent={'center'}
            xs={12}
            sm={12}
            md={12}
            style={{ textAlign: 'center' }}
          >
            <label className={classes.title}>You are:</label>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <StyledButton
              type="submit"
              fullWidth={true}
              variant="outlined"
              onClick={handleSubmit}
              className={classes.button}
            >
              Curator
            </StyledButton>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <StyledButton
              type="submit"
              fullWidth={true}
              variant="outlined"
              onClick={handleSubmit}
              className={classes.button}
            >
              Artist
            </StyledButton>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <StyledButton
              type="submit"
              fullWidth={true}
              variant="outlined"
              onClick={handleSubmit}
              className={classes.button}
            >
              Institution Staff
            </StyledButton>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <StyledButton
              type="submit"
              fullWidth={true}
              variant="outlined"
              onClick={handleSubmit}
              className={classes.button}
            >
              Gallery Founder or Staff
            </StyledButton>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <StyledButton
              type="submit"
              fullWidth={true}
              variant="outlined"
              onClick={handleSubmit}
              className={classes.button}
            >
              Other Art Professional
            </StyledButton>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <StyledButton
              type="submit"
              fullWidth={true}
              variant="outlined"
              onClick={handleSubmit}
              className={classes.button}
            >
              Collector
            </StyledButton>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <StyledButton
              type="submit"
              fullWidth={true}
              variant="outlined"
              onClick={handleSubmit}
              className={classes.button}
            >
              Art Lover
            </StyledButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default compose(
  withAccount,
  withStyles(styles)
)(Signup2 as unknown as any);
