import './project-state-switch.module.scss';
import React, { useEffect, useState } from 'react';
import { JsonProject } from '@acg/artpool-api-spec';
import useAsyncEffect from 'use-async-effect';
import { sleep } from '@acg/shared/utilities';

export enum ProjectStageNames {
  NOT_STARTED = 'notStarted',
  PRE_SALE = 'preSale',
  STARTED = 'started',
  STARTED_NO_END = 'startedNoEnd',
  FINISHED = 'finished',
}

export interface ProjectStages extends Partial<Record<ProjectStageNames, any>> {
  notStarted: (nextStageDate: Date) => JSX.Element;
  preSale: (nextStageDate: Date) => JSX.Element;
  started: (nextStageDate: Date) => JSX.Element;
  startedNoEnd?: () => JSX.Element;
  finished: (endDate: Date) => JSX.Element;
}

export interface ProjectStateSwitchProps {
  project: JsonProject;
  stages: ProjectStages;
}

interface StageOrderElement {
  name: ProjectStageNames;
  countDownDate?: Date;
}

export function ProjectStateSwitch(props: ProjectStateSwitchProps) {
  const { project, stages } = props;

  const presaleDate = new Date(project.preSaleDate);
  const startDate = new Date(project.startDate);
  const endDate = new Date(project.endDate);

  const stageOrder: StageOrderElement[] = [
    {
      name: ProjectStageNames.NOT_STARTED,
      countDownDate: presaleDate,
    },
    {
      name: ProjectStageNames.PRE_SALE,
      countDownDate: startDate,
    },
    {
      name: ProjectStageNames.STARTED,
      countDownDate: endDate,
    },
    {
      name: ProjectStageNames.STARTED_NO_END,
    },
    {
      name: ProjectStageNames.FINISHED,
      countDownDate: endDate,
    },
  ];

  const [stageName, setStageName] = useState<ProjectStageNames>();
  const [looping, setLooping] = useState<boolean>(true);

  useEffect(() => {
    setLooping(true);
    return () => {
      setLooping(false);
    };
  }, []);

  useAsyncEffect(async () => {
    const projectHasNoEnd = endDate.getTime() === 0;

    while (looping) {
      const now = new Date();

      if (
        now >= startDate &&
        projectHasNoEnd &&
        stages[ProjectStageNames.STARTED_NO_END]
      ) {
        setStageName(ProjectStageNames.STARTED_NO_END);
      } else if (!projectHasNoEnd && now >= endDate) {
        setStageName(ProjectStageNames.FINISHED);
      } else if (now >= startDate) {
        setStageName(ProjectStageNames.STARTED);
      } else if (now >= presaleDate) {
        setStageName(ProjectStageNames.PRE_SALE);
      } else {
        setStageName(ProjectStageNames.NOT_STARTED);
      }

      await sleep(1000);
    }
  }, []);

  if (!stageName) {
    return <></>;
  }

  const stageFunction: (countdown?: any) => JSX.Element = stages[
    stageName
  ] as any;
  if (!stageFunction) {
    throw Error(`A stage function must be defined for ${stageName}`);
  }

  const stage = stageOrder.find((s) => s.name === stageName);
  if (!stage) {
    throw Error(`A stage must be in the stageOrder array for ${stageName}`);
  }

  return stageFunction(stage.countDownDate);
}
