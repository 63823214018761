import {
  default as withWidth,
  isWidthDown,
  WithWidth,
} from '@mui/material/Hidden/withWidth';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import { Grid, Tab, Tabs } from '@mui/material';
import { IInstitution } from '../../types/institution';
import InstitutionService from '../../services/InstitutionService';
import PaddedLayout from '../../library/layout/container/PaddedLayout';
import Loading from '../../components/loading/Loading';
import ProfileInstitutionHeader from '../../components/institution/ProfileInstitutionHeader';

import { PostInstitutionsTypeOptions } from '../../types/posts';
import Posts from './VisitorPosts';
import { PRIMARY_COLOR, SPACING } from '../../styles/constants';
import { generateMetaForVenue } from '../../components/meta';
import { sumPostTotals } from '../../utils/post-totals-utils';
import { connect, DispatchProp } from 'react-redux';
import { showAuthUI } from '@acg/auth/frontend';
import { Redirect } from 'react-router-dom';

type TabValue = 'POSTS' | 'FOLLOWERS' | 'FOLLOWINGS';

export interface IVisitorInstitutionProfileProps {
  tabValue: TabValue;
  onClickOnAction: () => void;
}

interface IState {
  institution?: IInstitution;
  loading: boolean;
  isManager: boolean;
  error: boolean;
  following: string[];
  isPrivateVenue: boolean;

  selectedTab: string;
  postTotals?: {
    exhibition: number;
    art_project: number;
    publication: number;
    studio_visit: number;
    interview: number;
    online_exhibition: number;
    misc: number;
  };
}

type Props = IVisitorInstitutionProfileProps &
  WithWidth &
  RouteComponentProps<{ institutionId: string }> &
  WithStyles<string> &
  DispatchProp;

const style = createStyles({
  root: {
    flexGrow: 1,
  },
  tabRoot: {
    textTransform: 'none',
  },
  backdrop: {
    backgroundColor: 'rgba(242,242,242,0.92)',
  },
  tabs: {
    fontSize: '0.715rem',
    letterSpacing: -0.12,
    textAlign: 'right',
    minWidth: 120,
    textColor: '#000',
    '& button': {
      minWidth: 120,
      // fontSize: '0.715rem'
      fontSize: 16,
    },
  },
  scrollButton: {
    width: 10,
  },
  indicator: {
    backgroundColor: PRIMARY_COLOR,
  },

  tabsContainer: {
    marginTop: `-${SPACING}px`,
    paddingRight: `${SPACING}px`,
    paddingLeft: `${SPACING}px`,
  },

  postsContainer: {
    marginTop: `30px`,
  },
  signupSection: {
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    // paddingTop: 200,
    // paddingBottom: 200,
    backgroundColor: '#fafbfb',
  },

  signupSectionMobile: {
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    paddingTop: 100,
    paddingBottom: 100,
    backgroundColor: '#fafbfb',
  },
  signupContainer: {
    flexGrow: 0,
    maxWidth: '80%',
    height: '100%',
    flexBasis: '90%',
  },
  signupContainerMobile: {
    flexGrow: 0,
    height: '100%',
    flexBasis: '90%',
  },
});

class VisitorInstitutionProfile extends React.Component<Props, IState> {
  private institutionService = new InstitutionService();

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      following: [],
      isManager: false,
      isPrivateVenue: false,
      selectedTab: 'ALL',
    };
  }

  public componentDidMount() {
    const {
      match: {
        params: { institutionId },
      },
    } = this.props;

    this.getInstitution(institutionId);
  }

  public getInstitution = async (institutionId: string) => {
    this.setState({ loading: true });

    return Promise.all([
      this.institutionService.getPublicInstitution(institutionId),
      this.institutionService.getInstitutionPostTotals(institutionId),
    ])
      .then(([institutionData, postTotalsData]) => {
        this.setState({
          loading: false,
          error: false,
          institution: institutionData.data,
          following: [],
          isManager: false,
          postTotals: postTotalsData.data || null,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          error: true,
          isPrivateVenue: err.error_message === 'NOT_ALLOWED',
        });
      });
  };

  public render() {
    const {
      classes,
      width,
      match: {
        params: { institutionId },
      },
      history,
    } = this.props;

    const {
      loading,
      institution,
      following,
      isManager,
      isPrivateVenue,
      selectedTab,
      postTotals,
    } = this.state;

    const onConnectHandler = (institutionConnected?: IInstitution) => {
      // window.console.log('onConnectHandler', institutionConnected)
    };
    const onFollowHandler = (institutionFollowed?: IInstitution) => {};

    const showAuth = () => this.props.dispatch(showAuthUI());

    const onTabClick = (e: any, val: string) => {
      this.setState({ selectedTab: val });
      switch (val) {
        case 'FOLLOWERS':
          return history.push(`/venues/${institutionId}/followers`);
        case 'FOLLOWINGS':
          return history.push(`/venues/${institutionId}/followings`);
        default:
          return history.push(`/venues/${institutionId}/posts`);
      }
    };

    const isMobile = isWidthDown('xs', width);

    const isFollowing = following.indexOf(institutionId) > -1;

    if (isPrivateVenue) {
      return <Redirect to="/login" />;
    }

    return (
      <PaddedLayout>
        <Grid container={true} className={classes.root}>
          {loading && <Loading />}
          {institution && (
            <>
              {generateMetaForVenue(institution)}
              <ProfileInstitutionHeader
                institution={institution}
                isManager={isManager}
                isFollowing={isFollowing}
                onConnectHandler={onConnectHandler}
                onFollowHandler={onFollowHandler}
                onEditClick={showAuth}
                onShareClick={showAuth}
                showConnect={false}
              />

              <Grid item={true} xs={12} className={classes.tabsContainer}>
                <Tabs
                  value={selectedTab}
                  onChange={onTabClick}
                  centered={false}
                  classes={{
                    root: classes.tabs,
                    indicator: classes.indicator,
                    scrollButtons: classes.scrollButton,
                  }}
                  scrollButtons={true}
                  variant="scrollable"
                >
                  {postTotals && (
                    <Tab
                      label={'(' + sumPostTotals(postTotals) + ') All'}
                      value={'ALL'}
                      key={'ALL'}
                      classes={{
                        root: classes.tabRoot,
                      }}
                    />
                  )}
                  {PostInstitutionsTypeOptions.map((type) => {
                    let tabLabel = type.tabLabel;

                    if (postTotals && postTotals.exhibition >= 0) {
                      switch (type.id) {
                        case 'EXHIBITION':
                          tabLabel =
                            '(' + postTotals.exhibition + ') ' + type.tabLabel;
                          break;
                        case 'ARTPROJECT':
                          tabLabel =
                            '(' + postTotals.art_project + ') ' + type.tabLabel;
                          break;
                        case 'PUBLICATION':
                          tabLabel =
                            '(' + postTotals.publication + ') ' + type.tabLabel;
                          break;
                        case 'STUDIOVISIT':
                          tabLabel =
                            '(' +
                            postTotals.studio_visit +
                            ') ' +
                            type.tabLabel;
                          break;
                        case 'INTERVIEW':
                          tabLabel =
                            '(' + postTotals.interview + ') ' + type.tabLabel;
                          break;
                        case 'ONLINEEXHIBITION':
                          tabLabel =
                            '(' +
                            postTotals.online_exhibition +
                            ') ' +
                            type.tabLabel;
                          break;
                        default:
                          tabLabel =
                            '(' + postTotals.misc + ') ' + type.tabLabel;
                          break;
                      }
                    }

                    return (
                      <Tab
                        label={tabLabel}
                        value={type.id}
                        key={type.id}
                        classes={{
                          root: classes.tabRoot,
                        }}
                      />
                    );
                  })}
                </Tabs>
                {selectedTab !== 'FOLLOWERS' && selectedTab !== 'FOLLOWINGS' && (
                  <div className={classes.postsContainer}>
                    <Posts
                      noProfile={true}
                      onClickOnAction={this.props.onClickOnAction}
                      selectedTab={selectedTab}
                      noTopMargin={true}
                      venueId={institution.url}
                    />
                  </div>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </PaddedLayout>
    );
  }
}

export default compose<Props, IVisitorInstitutionProfileProps>(
  withWidth(),
  withRouter,
  withStyles(style)
)(connect()(VisitorInstitutionProfile) as any);
