import styles from './about-page.module.scss';
import { MemberCard } from './components/member-card/MemberCard';
import { members } from './constants';
import { useLayoutEffect } from 'react';
import { ARTPOOL_MAIL_URL } from '@acg/shared/const';
import MailIcon from '@mui/icons-material/Mail';
import { SupportedBy } from './components/supported-by/SupportedBy';

const PATH_TO_IMAGES = '/assets/images/artpool/about';

export function AboutPage() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className={styles.aboutPage} style={{ paddingTop: '80px' }}>
      <section className={styles.banner}>
        <div className={styles.bannerImage}>
          <img
            src={`${PATH_TO_IMAGES}/banner-image.png`}
            alt="Artpool logo for about page"
          />
        </div>
        <p>
          Empowering art curators worldwide to connect, collaborate, and inspire
          in the digital era.
        </p>
      </section>
      <section className={styles.aboutArtpool}>
        <h1 className={styles.title}>About Artpool</h1>
        <p>
          At Artpool, we believe in the transformative power of art curation.
          Our platform is dedicated to creating a global community of passionate
          art curators, fostering collaboration, inspiration, and professional
          growth.
        </p>
        <p>
          With a strong foundation built over the years, we have gathered a
          curated network of verified professionals from diverse artistic
          practices, spanning across continents. Our platform serves as a
          dynamic hub where curators can connect with like-minded peers, share
          insights, and unlock new possibilities. We understand the challenges
          faced by art curators in today's digital landscape. We are a team of
          experts who can guide curators into the exciting world of NFTs,
          offering support to explore the potential of this innovative space.
        </p>
        <p>
          At Artpool, curators can amplify their expertise, expand their
          influence, and nurture their creative visions.
        </p>
        <p>
          Join us today and be part of a vibrant network that empowers curators,
          celebrates artistic diversity, and reshapes the future of art
          curation.
        </p>
      </section>
      <section className={styles.meetTeam}>
        <h2 className={styles.title}>Meet our team</h2>
        <div className={styles.carousel}>
          {members.map((member) => (
            <MemberCard
              img={`${PATH_TO_IMAGES}/${member.img}`}
              name={member.name}
              role={member.role}
              socialIcons={member.socialIcons}
            />
          ))}
        </div>
      </section>
      <SupportedBy />
      <section className={styles.getInTouch}>
        <div className={styles.content}>
          <h2>Get in touch</h2>
          <p>
            Reach out to us and explore the limitless possibilities of art
            curation in the digital realm. Whether you're a curator looking to
            join our vibrant community, seeking to hire a talented curator, or
            interested in the intersection of NFTs and curatorial expertise,
            we're here to guide and assist you.
          </p>
          <div className={styles.socialIcons}>
            <a className={styles.socialIcon} href={ARTPOOL_MAIL_URL}>
              <MailIcon />
              Contact Us
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}
