import {
  default as withWidth,
  WithWidth,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { Grid } from '@mui/material';
import { IWithAccountProps } from '../../containers/account/InjectAccount';
import { IExhibitionPoint } from '../../types/exhibitions';
import { INFO_POINT_ICON, NEUTRAL, SPACING } from '../../styles/constants';
import { getMediumImageUrl } from '../../utils/imageUrl';
import ReactPlayer from 'react-player';
import {
  getExhibitionArtists,
  getExhibitionPointTitle,
  getExhibitionPointType,
  PointType,
} from '../../utils/exhibition';
import { StyledButton } from '@acg/frontend-ui-artpool';
import { onBuyNftClick } from '../../artpool-library/utilities/shared-utilities';

export interface IOnlineExhibitionArtPointsListProps {
  artPoint: IExhibitionPoint;
  refCall: any;
  onArtWorkClick: (point: any) => void;
  selectedPointId: number | undefined;
  setSelectedPointId: (pointId: number | undefined) => void;
}

const styles = createStyles({
  artPointContainer: {
    borderBottom: '1px solid black',
    padding: `${SPACING}px ${SPACING}px`,
    margin: 0,
    '&:hover': {
      background: '#efefef',
    },

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  artPointContainerImage: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    margin: '0 auto',
    textAlign: 'center',
  },

  artPointContainerImageHighlight: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    margin: '0 auto',
    textAlign: 'center',
  },

  artPointContainerText: {
    height: 79,
  },

  artPointContainerTextHighlight: {
    backgroundColor: NEUTRAL[50],
    color: 'black',
  },
  artPointImage: {
    maxHeight: 76,
    maxWidth: '100%',
  },
  textOverflowController: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

type Props = WithStyles<string> &
  IWithAccountProps &
  WithWidth &
  IOnlineExhibitionArtPointsListProps;

function OnlineExhibitionArtPointsList(props: Props) {
  const { classes, artPoint, onArtWorkClick, refCall, selectedPointId } = props;

  const getArtistsList = () => {
    if (
      artPoint.art_work &&
      artPoint.art_work.nft_data &&
      artPoint.art_work.nft_data.nft
    ) {
      return `${artPoint.art_work.nft_data.nft.artist.name}`;
    } else if (artPoint.art_work) {
      return getExhibitionArtists(artPoint.art_work).map(
        (artist: any, i: number) => {
          return (
            <React.Fragment key={i}>
              {(i ? ', ' : '') + artist.name}
            </React.Fragment>
          );
        }
      );
    }
    return '';
  };

  const onPointClick = () => {
    onArtWorkClick(artPoint);
  };

  const isSelectedHighlight = selectedPointId === artPoint.point_order;

  const renderImage = () => {
    const imgSrc = (): string => {
      if (getExhibitionPointType(artPoint) === PointType.NFT) {
        return getMediumImageUrl(artPoint.art_work!!.nft_data!!.nft.image.src);
      }

      if (
        artPoint.art_work &&
        artPoint.art_work.imagesWithCredit &&
        artPoint.art_work.imagesWithCredit[0] &&
        artPoint.art_work.imagesWithCredit[0].image_url
      ) {
        return getMediumImageUrl(
          artPoint.art_work.imagesWithCredit[0].image_url
        );
      }

      if (getExhibitionPointType(artPoint) === PointType.INFO_POINT) {
        if (
          artPoint.imagesWithCredit &&
          artPoint.imagesWithCredit[0] &&
          artPoint.imagesWithCredit[0].image_url
        ) {
          return getMediumImageUrl(artPoint.imagesWithCredit[0].image_url);
        } else {
          return INFO_POINT_ICON;
        }
      }

      if (
        artPoint.imagesWithCredit &&
        artPoint.imagesWithCredit[0] &&
        artPoint.imagesWithCredit[0].image_url
      ) {
        return getMediumImageUrl(artPoint.imagesWithCredit[0].image_url);
      }

      return '';
    };

    return (
      <Grid
        item
        xs={4}
        className={
          isSelectedHighlight
            ? classes.artPointContainerImageHighlight
            : classes.artPointContainerImage
        }
      >
        <img
          src={imgSrc()}
          className={classes.artPointImage}
          style={
            getExhibitionPointType(artPoint) === PointType.INFO_POINT
              ? { maxWidth: 65 }
              : {}
          }
        />
      </Grid>
    );
  };

  const renderCreditImageOrVideo = () => {
    if (
      artPoint &&
      (!artPoint.imagesWithCredit || !artPoint.imagesWithCredit[0]) &&
      artPoint.videos &&
      artPoint.videos[0] &&
      artPoint.videos[0].video_link
    ) {
      return (
        <Grid
          item
          xs={4}
          className={
            isSelectedHighlight
              ? classes.artPointContainerImageHighlight
              : classes.artPointContainerImage
          }
          style={{ textAlign: 'center', alignItems: 'center' }}
        >
          <div className={classes.playerList}>
            <ReactPlayer
              className="reactPlayer"
              url={artPoint.videos[0].video_link}
              playing={false}
              width="100%"
              height="76px"
              controls
              vimeo={{ playerOptions: { height: '76px' } }}
            />
          </div>
        </Grid>
      );
    } else if (
      artPoint.art_work &&
      !artPoint.art_work.featured_imageWithCredit &&
      (!artPoint.art_work.imagesWithCredit ||
        !artPoint.art_work.imagesWithCredit[0]) &&
      artPoint.art_work.videos &&
      artPoint.art_work.videos[0] &&
      artPoint.art_work.videos[0].video_link
    ) {
      return (
        <Grid
          item
          xs={4}
          className={
            isSelectedHighlight
              ? classes.artPointContainerImageHighlight
              : classes.artPointContainerImage
          }
          style={{ textAlign: 'center', alignItems: 'center' }}
        >
          <div className={classes.playerList}>
            <ReactPlayer
              className="reactPlayer"
              url={artPoint.art_work.videos[0].video_link}
              playing={false}
              width="100%"
              height="76px"
              controls
              vimeo={{ playerOptions: { height: '76px' } }}
            />
          </div>
        </Grid>
      );
    } else {
      return renderImage();
    }
  };

  const artWorkYear = () => {
    if (artPoint.art_work && artPoint.art_work.year) {
      return artPoint.art_work.year;
    }
    return '';
  };

  const onBuyClick = () => {
    onBuyNftClick(artPoint.art_work!!.nft_data);
  };

  return (
    <div
      onClick={onPointClick}
      key={'_artPoint_' + artPoint.id}
      style={{
        width: '100%',
        cursor: 'pointer',
        fontFamily: 'Replica-Regular',
      }}
      ref={refCall['_artPoint_' + artPoint.id]}
    >
      <Grid container className={classes.artPointContainer} spacing={2}>
        {/* ---- IMAGE ----*/}
        {renderCreditImageOrVideo()}

        {/* ---- ORDER ----*/}
        <Grid
          item
          xs={1}
          className={`${classes.artPointContainerText} ${
            isSelectedHighlight ? classes.artPointContainerTextHighlight : ''
          }`}
          style={{
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            paddingTop: 10,
          }}
        >
          <Grid item xs={12}>
            {artPoint.point_order < 10
              ? '0' + artPoint.point_order
              : artPoint.point_order}
          </Grid>
        </Grid>
        {/*  ---- DATA ----*/}
        <Grid
          item
          xs={getExhibitionPointType(artPoint) === PointType.NFT ? 4 : 7}
          style={{ paddingTop: 12 }}
          className={`${classes.artPointContainerText} ${
            isSelectedHighlight ? classes.artPointContainerTextHighlight : ''
          }`}
        >
          {getExhibitionPointType(artPoint) !== PointType.INFO_POINT && (
            <Grid item xs={12} className={classes.textOverflowController}>
              <b>{getArtistsList()}</b>
              {(!getArtistsList() || getArtistsList() === []) && <br />}
            </Grid>
          )}
          <Grid item xs={12} className={classes.textOverflowController}>
            {getExhibitionPointTitle(artPoint)}
          </Grid>
          {getExhibitionPointType(artPoint) === PointType.ARTWORK && (
            <Grid item xs={12} className={classes.textOverflowController}>
              {artWorkYear()}
            </Grid>
          )}
        </Grid>
        {getExhibitionPointType(artPoint) === PointType.NFT && (
          <Grid item xs={3}>
            <StyledButton
              variant={'contained'}
              size={'small'}
              style={{ padding: '10px 30px' }}
              onClick={onBuyClick}
            >
              Buy
            </StyledButton>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default compose<Props, IOnlineExhibitionArtPointsListProps>(
  withStyles(styles),
  withWidth()
)(OnlineExhibitionArtPointsList);
