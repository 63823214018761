import styles from '../auth.module.scss';
import { Grid } from '@mui/material';
import { CloseIcon } from '@acg/assets';
import { hideAuthUI } from '@acg/auth/frontend';
import { useDispatch } from 'react-redux';
import { AuthenticatorProps } from '../auth';

export interface AuthHeaderProps extends AuthenticatorProps {
  title: string;
}

export const AuthHeader = (params: AuthHeaderProps) => {
  const dispatch = useDispatch();

  const { onClose, title } = params;

  return (
    <Grid className={styles.header} container>
      <CloseIcon
        className={styles.closeButton}
        onClick={() => {
          dispatch(hideAuthUI());
          if (onClose) {
            onClose();
          }
        }}
      />
      <img
        className={styles.logo}
        alt="Artpool A logo"
        src={'/assets/logos/artpool/Artpool_monogram_green.svg'}
      />
      <Grid item xs={12}>
        <p className={styles.text}>{title}</p>
      </Grid>
    </Grid>
  );
};
