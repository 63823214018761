import {
  default as withWidth,
  WithWidth,
} from '@mui/material/Hidden/withWidth';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import * as React from 'react';
import { Grid } from '@mui/material';
import { compose } from 'recompose';
import { GridProps } from '@mui/material/Grid';

export interface IPublicLayoutProps {
  scrollToTop: (scrolToTop: number) => void;
  notTransparent?: boolean;
  hasScrollEvent: boolean;
  disableScroll: boolean;
  children: React.ReactNode;
}

const styles = createStyles({
  withScrollEvent: {
    overflowY: 'auto',
  },

  withNoScrollEvent: {
    height: '100%',
    overflowY: 'auto',
  },
});

type Props = IPublicLayoutProps & WithWidth & WithStyles<string>;

class PublicOnlineLayout extends React.Component<Props, any> {
  private myRef: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      scrollTop: 0,
    };
    this.myRef = React.createRef();
  }

  public onScroll = () => {
    const { scrollToTop, disableScroll } = this.props;

    if (disableScroll) {
      return;
    }
    const scrollTop = this.myRef.current.scrollTop;
    this.setState(
      {
        scrollTop,
      },
      () => scrollToTop(scrollTop)
    );
  };

  public render() {
    const {
      classes,
      // width
    } = this.props;

    const props: Partial<GridProps> = {
      // justify: isWidthDown(BREAKPOINT, width) ? "flex-start" : "center",
      // alignItems: isWidthDown(BREAKPOINT, width) ? "flex-start" : "center",
    };

    return (
      <Grid
        container={true}
        direction="row"
        {...props}
        // style={{  height: '100%', overflowY: 'auto'}}
        // className="public-layout"
        ref={this.myRef}
        className={
          this.props.hasScrollEvent
            ? classes.withScrollEvent
            : classes.withNoScrollEvent
        }
        onScroll={this.onScroll}
      >
        <Grid xs={12} sm={12} md={12} lg={12} item={true}>
          {this.props.children}
        </Grid>
      </Grid>
    );
  }
}

export default compose<Props, IPublicLayoutProps>(
  withStyles(styles),
  withWidth()
)(PublicOnlineLayout);
