import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { virtualize } from 'react-swipeable-views-utils';
import {} from '@mui/material';
import { compose } from 'recompose';

export interface ISwipableCarouselViewProps {
  index: number;
  key: string;
  className?: string;
  containerStyle: any;
  onChangeIndex: (index: number) => void;
  slideClassName?: string;
  children?: React.ReactNode;
}

interface IState {}

const styles = createStyles({});

type Props = ISwipableCarouselViewProps & WithStyles<string> & WithWidth;

class SwipableCarouselView extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
  }

  public modulo = (a: number, n: number) => {
    return ((a % n) + n) % n;
  };

  public render() {
    const { key, index, children } = this.props;

    const VirtualizeSwipeableViews = virtualize(SwipeableViews);

    const countChildren = React.Children.count(children);

    const slideRenderer = () => {
      return (
        children &&
        // @ts-ignore
        React.cloneElement(children[this.modulo(index, countChildren)], { key })
      );
    };

    return (
      <VirtualizeSwipeableViews slideRenderer={slideRenderer} index={index} />
    );
  }
}

export default compose<Props, ISwipableCarouselViewProps>(
  withStyles(styles),
  withWidth()
)(SwipableCarouselView);

/* <SwipeableViews>
                {children}
            </SwipeableViews>

 */
