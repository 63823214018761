import * as React from 'react';

export interface IIconNetworkProps {
  fill?: string;
  height?: number;
  style?: any;
}

const IconNetwork = (props: IIconNetworkProps) => {
  const style: React.CSSProperties = {
    height: props.height || 40,
    backgroundColor:
      (props.style && props.style.backgroundColor) || 'transparent',
    borderRadius: (props.style && props.style.borderRadius) || 6,
  };

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
      fill={props.fill || 'black'}
      style={style}
    >
      <path d="M857,503.49a111.1,111.1,0,0,1-1,14.58l.43-3.19a110.28,110.28,0,0,1-7.58,27.9l1.21-2.87a110.78,110.78,0,0,1-14.24,24.4l1.87-2.43a111,111,0,0,1-19.85,19.85l2.43-1.87a110.39,110.39,0,0,1-24.4,14.24l2.86-1.21a110.1,110.1,0,0,1-27.89,7.58l3.19-.43a112.06,112.06,0,0,1-29.16,0l3.19.43a110.1,110.1,0,0,1-27.89-7.58l2.86,1.21a110.39,110.39,0,0,1-24.4-14.24l2.43,1.87a111,111,0,0,1-19.85-19.85l1.87,2.43a110.39,110.39,0,0,1-14.24-24.4l1.21,2.87a110.28,110.28,0,0,1-7.58-27.9l.43,3.19a112.06,112.06,0,0,1,0-29.16l-.43,3.19a110.1,110.1,0,0,1,7.58-27.89l-1.21,2.86a110.39,110.39,0,0,1,14.24-24.4l-1.87,2.43a111,111,0,0,1,19.85-19.85l-2.43,1.87a110.78,110.78,0,0,1,24.4-14.24l-2.86,1.21a110.1,110.1,0,0,1,27.89-7.58l-3.19.43a112.06,112.06,0,0,1,29.16,0l-3.19-.43a110.1,110.1,0,0,1,27.89,7.58l-2.86-1.21a110.78,110.78,0,0,1,24.4,14.24l-2.43-1.87a111,111,0,0,1,19.85,19.85l-1.87-2.43a110.78,110.78,0,0,1,14.24,24.4l-1.21-2.86a110.1,110.1,0,0,1,7.58,27.89l-.43-3.19a111.21,111.21,0,0,1,1,14.58,12.43,12.43,0,0,0,3.52,8.49,12.15,12.15,0,0,0,8.48,3.51c6.5-.29,12-5.27,12-12a121.14,121.14,0,0,0-5.77-37.07A119.5,119.5,0,0,0,859.14,434a132.4,132.4,0,0,0-12.33-15,128.48,128.48,0,0,0-14.5-12.67,120.77,120.77,0,0,0-68.41-24.24,121.18,121.18,0,0,0-37.45,4.47,121.44,121.44,0,0,0-60,38.91,132.1,132.1,0,0,0-11.24,15.68,130.67,130.67,0,0,0-8.62,17.63,119.6,119.6,0,0,0-8.25,36.17A121.44,121.44,0,0,0,708.39,613.7a129.11,129.11,0,0,0,18.55,6.89,130.44,130.44,0,0,0,19.7,3.67,120.79,120.79,0,0,0,37.88-1.86,119.44,119.44,0,0,0,34.22-12.79,121.73,121.73,0,0,0,49.2-51.27,118.8,118.8,0,0,0,11.4-35A133,133,0,0,0,881,503.49a12.35,12.35,0,0,0-3.51-8.48,12.19,12.19,0,0,0-8.49-3.52C862.51,491.78,857,496.76,857,503.49Z" />
      <path d="M943.74,893H847.18c-4.46,0-8.92-.06-13.38,0h-.19a12.42,12.42,0,0,0-8.49,3.51,12.22,12.22,0,0,0-3.51,8.49c.29,6.5,5.27,12,12,12h96.55c4.46,0,8.93.06,13.38,0h.2a12.38,12.38,0,0,0,8.48-3.52,12.21,12.21,0,0,0,3.52-8.48c-.3-6.5-5.28-12-12-12Z" />
      <path d="M683.09,698.73a256.34,256.34,0,0,1,41.08-9l-3.19.43a299.29,299.29,0,0,1,64.65-1.57q6.86.58,13.67,1.49l-3.19-.43a248.42,248.42,0,0,1,48.35,11.36q6.67,2.33,13.21,5.08l-2.87-1.21a198.25,198.25,0,0,1,34.06,18.44q4.64,3.15,9.08,6.57l-2.42-1.88a180.88,180.88,0,0,1,25.79,24.31q3.45,4,6.66,8.12l-1.88-2.43a190,190,0,0,1,20.07,32.36q2.55,5.21,4.81,10.54l-1.21-2.86a240.87,240.87,0,0,1,14.44,48.4c1,5.18,1.82,10.39,2.53,15.62l-.43-3.19q.65,4.77,1.14,9.57c.34,3.27,1.11,6.08,3.52,8.48a12,12,0,0,0,17,0c2-2.2,3.83-5.37,3.51-8.48a300.21,300.21,0,0,0-8.75-48.44,238.36,238.36,0,0,0-15.55-41.23,190.49,190.49,0,0,0-19.67-32A194.17,194.17,0,0,0,921.6,718.6a202.48,202.48,0,0,0-30-22,221.84,221.84,0,0,0-33.29-16,232.58,232.58,0,0,0-32.76-10,291.87,291.87,0,0,0-36.84-5.78,301.07,301.07,0,0,0-38.46-1.13,292.83,292.83,0,0,0-59.82,8c-4.6,1.12-9.16,2.39-13.68,3.78a12.56,12.56,0,0,0-7.18,5.52,12.28,12.28,0,0,0-1.21,9.24c2,6,8.34,10.37,14.77,8.39Z" />
      <path d="M525.41,357.43a187.21,187.21,0,0,1-1.61,24.36l.43-3.19a183.29,183.29,0,0,1-12.64,46.48l1.21-2.87a184.17,184.17,0,0,1-23.74,40.66l1.88-2.43a185.25,185.25,0,0,1-33.08,33.07l2.43-1.87a184.06,184.06,0,0,1-40.65,23.73l2.86-1.21A183.21,183.21,0,0,1,376,526.8l3.19-.42a186.53,186.53,0,0,1-48.72,0l3.19.42a183.21,183.21,0,0,1-46.47-12.64l2.86,1.21a184.06,184.06,0,0,1-40.65-23.73l2.43,1.87a185.25,185.25,0,0,1-33.08-33.07l1.88,2.43a184.56,184.56,0,0,1-23.74-40.66l1.21,2.87a183.29,183.29,0,0,1-12.64-46.48l.43,3.19a186.45,186.45,0,0,1,0-48.71l-.43,3.19a183.17,183.17,0,0,1,12.64-46.48l-1.21,2.87A184.56,184.56,0,0,1,220.66,252l-1.88,2.43a185.63,185.63,0,0,1,33.08-33.08l-2.43,1.88a184.49,184.49,0,0,1,40.65-23.74l-2.86,1.21a183.63,183.63,0,0,1,46.47-12.64l-3.19.43a186.53,186.53,0,0,1,48.72,0l-3.19-.43A183.63,183.63,0,0,1,422.5,200.7l-2.86-1.21a184.49,184.49,0,0,1,40.65,23.74l-2.43-1.88a185.63,185.63,0,0,1,33.08,33.08L489.06,252a184.17,184.17,0,0,1,23.74,40.66l-1.21-2.87a183.17,183.17,0,0,1,12.64,46.48l-.43-3.19a187.06,187.06,0,0,1,1.61,24.35c0,6.28,5.52,12.29,12,12s12-5.27,12-12a193.44,193.44,0,0,0-35.34-111.77,201.9,201.9,0,0,0-42-43.44A194.24,194.24,0,0,0,205.39,233a201.24,201.24,0,0,0-31,52,192.23,192.23,0,0,0-13.48,58.14A194.5,194.5,0,0,0,273.75,534.25a188,188,0,0,0,59.75,16.5A194.56,194.56,0,0,0,528.78,444.67a192,192,0,0,0,18.1-56.21,206.79,206.79,0,0,0,2.53-31c0-6.27-5.52-12.29-12-12S525.42,350.71,525.41,357.43Z" />
      <path d="M804.72,868.49a270.36,270.36,0,0,0-19.38-73.56,243.89,243.89,0,0,0-36.05-58.83c-29.34-35.8-68.65-62.74-110.42-82-46.59-21.51-96.5-34.82-147.09-42.76-57-8.95-114.87-11.78-172.48-9.66-68,2.5-136.81,11.79-201.44,33.67q-11.25,3.83-22.3,8.18c-5,2-8.81,5.88-8.81,11.57V874.38c0,10.09-.32,20.23,0,30.31v.43a12.17,12.17,0,0,0,12,12H733.35c8.57,0,17.16.13,25.74,0h1.1c6.28,0,12.29-5.52,12-12s-5.27-12-12-12H125.61c-8.57,0-17.18-.22-25.75,0h-1.1l12,12V685.8c0-10,.55-20.28,0-30.31,0-.15,0-.29,0-.43l-8.81,11.57a547.52,547.52,0,0,1,92.33-26.82q12.4-2.5,24.91-4.55,6-1,12-1.87c4.93-.73,7.55-1.08,11.45-1.58q25.15-3.19,50.46-4.83A908.71,908.71,0,0,1,467,631.8c.8.1,5.78.76,2.74.35l4.43.61c3.44.49,6.87,1,10.3,1.53q9.48,1.47,18.92,3.2,18.84,3.45,37.45,8A514.41,514.41,0,0,1,611,668l3.77,1.54c.65.27,2.63,1.12.05,0l1.66.71q4.34,1.86,8.63,3.82,7.88,3.6,15.6,7.54c10.32,5.27,20.41,11,30.16,17.31q7,4.5,13.73,9.37,3.25,2.36,6.45,4.79c1.69,1.28-.17-.13-.43-.34l2,1.56,3.27,2.63a267.16,267.16,0,0,1,24.4,22.57q5.55,5.84,10.7,12a63.58,63.58,0,0,1,4.92,6.09c-1.81-2.81.63.84,1.12,1.5.83,1.1,1.64,2.21,2.44,3.33a229.74,229.74,0,0,1,17.4,28.24c2.47,4.73,4.77,9.56,6.9,14.45.25.58,1.54,3.18.12.27.25.5.45,1.06.66,1.57.54,1.32,1.06,2.65,1.58,4,1,2.68,2,5.37,3,8.09a247.8,247.8,0,0,1,9.07,33.88q.84,4.29,1.53,8.6c.24,1.54.46,3.07.7,4.61.09.59.31,2.35,0-.41l.36,2.81c.77,6.2,5,12.31,12,12,5.84-.26,12.83-5.33,12-12Z" />
    </svg>
  );
};

export default IconNetwork;
