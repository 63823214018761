import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import ACGModalContent from '../../components/modal/ACGModalContent';
import { Formik, FormikHelpers, FormikProps, Field } from 'formik';
import { compose } from 'recompose';
import { withAccount, IWithAccountProps } from '../account/InjectAccount';
import { IValidationConfig, generateValidator } from '../../utils/formatters';
import FormMessage from '../../components/form/FormMessage';
import { Grid } from '@mui/material';
import TextInput from '../../components/form/TextInput';
import FormCancel from '../../components/form/FormCancel';
import HighlightedTitle from '../../library/dataDisplay/typography/HighlightedTitle';
import { IInviteInstitutionRequest } from '../../types/institution';
import UserService from '../../services/UserService';
import DesignedButton from '../../library/inputs/DesignedButton';

export interface IInviteFormProps {
  onClickAway: () => void;
  profileInvited: string;
  isAfterSignUp?: boolean;
}

type Props = IInviteFormProps & IWithAccountProps & WithStyles<string>;

type IInitialValues = IInviteInstitutionRequest;

const style = createStyles({
  text: {
    color: 'rgba(0, 0, 0, 0.64)',
    fontSize: '0.9em',
  },
  topContent: {
    width: '100%',
    fontSize: 16,
    marginTop: 40,
    marginBottom: 20,
    fontWeight: 'normal',
  },
  section: {
    marginBottom: 10,
    marginTop: 10,
  },
  buttonSection: {
    marginTop: 40,
  },
});

const InviteForm: React.FC<Props> = (props) => {
  const userService = new UserService();

  const [error, setError] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);

  const { account, profileInvited, isAfterSignUp, onClickAway, classes } =
    props;

  const isVenue = profileInvited === 'Venues';

  const onSubmit = async (
    values: IInitialValues,
    actions: FormikHelpers<IInitialValues>
  ) => {
    setError(false);
    setSuccess(false);
    try {
      await userService.inviteRequest(account.id, values, profileInvited);
      setError(false);
      setSuccess(true);
      setIsSubmitted(true);
    } catch (err) {
      setError(true);
      setSuccess(false);
      actions.setSubmitting(false);
    }
  };

  const renderForm = (formProps: FormikProps<IInitialValues>) => {
    let content: React.ReactNode;

    content = (
      <div className={classes.topContent}>
        {isAfterSignUp && isVenue ? (
          <>
            <div className={classes.section}>
              Our venue directory is open to submission, feel free to suggest
              some contacts and we’ll get in touch.
            </div>
            <div className={classes.section}>
              Please, kindly note that this process is subject to selection
              criteria.
            </div>
          </>
        ) : (
          <div className={classes.section}>
            {`Please help us${
              isVenue ? ' build a Venue directory and' : ''
            } grow the community!`}
          </div>
        )}
      </div>
    );

    return (
      <form onSubmit={formProps.handleSubmit}>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12}>
            <HighlightedTitle>
              {isAfterSignUp
                ? `Venue Registration`
                : `Invite ${profileInvited}`}
            </HighlightedTitle>
          </Grid>
          {!!error && (
            <FormMessage
              message={'An unexpected error happened, please try again.'}
              visible={!!error}
              type={'ERROR'}
            />
          )}
          {!!success && (
            <FormMessage
              message={
                <>
                  <p>Thank you!</p>
                </>
              }
              visible={!!success}
              type={'SUCCESS'}
            />
          )}
          <Grid item={true} xs={12}>
            {content}
          </Grid>
          {isVenue && (
            <Grid item={true} xs={12}>
              <Field
                name="venue_name"
                component={TextInput}
                newTextInput={true}
                naked={true}
                disabled={formProps.isSubmitting}
                inputProps={{
                  id: 'venue_name',
                  label: 'Venue Name',
                  type: 'text',
                }}
              />
            </Grid>
          )}
          <Grid item={true} xs={12}>
            <Field
              name="contact_name"
              component={TextInput}
              newTextInput={true}
              naked={true}
              disabled={formProps.isSubmitting}
              inputProps={{
                id: 'contact_name',
                label: 'Contact Name',
                type: 'text',
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              name="email"
              component={TextInput}
              newTextInput={true}
              naked={true}
              disabled={formProps.isSubmitting}
              inputProps={{
                id: 'email',
                label: 'Email',
                type: 'email',
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              name="more_info"
              component={TextInput}
              newTextInput={true}
              naked={true}
              disabled={formProps.isSubmitting}
              inputProps={{
                id: 'more_info',
                label: 'More Info',
                type: 'text',
                multiline: true,
                rows: 5,
                maxRows: 20,
              }}
            />
          </Grid>

          {!isSubmitted && (
            <>
              <Grid xs={12} container={true} justifyContent="space-between">
                <Grid item={true} xs={6} style={{ padding: 4 }}>
                  <DesignedButton
                    ACGType={'neutral'}
                    size={'large'}
                    disabled={formProps.isSubmitting}
                    onClick={onClickAway}
                    fullWidth={true}
                  >
                    Cancel
                  </DesignedButton>
                </Grid>
                <Grid item={true} xs={6} style={{ padding: 4 }}>
                  <DesignedButton
                    ACGType={'main'}
                    disabled={formProps.isSubmitting}
                    type="submit"
                    size={'large'}
                    fullWidth={true}
                  >
                    {isAfterSignUp ? 'Register' : 'Invite'}
                  </DesignedButton>
                </Grid>
              </Grid>
            </>
          )}

          {isSubmitted && (
            <>
              <Grid item={true} xs={12}>
                <FormCancel
                  onClick={onClickAway}
                  newButton={true}
                  variant="contained"
                >
                  Back
                </FormCancel>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    );
  };

  const initialValues: IInitialValues = {
    venue_name: '',
    contact_name: '',
    email: '',
    more_info: '',
  };

  const config: IValidationConfig = {
    // email: ['IS_EMAIL']
  };

  const validate = generateValidator(config);

  return (
    <ACGModalContent>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
        render={renderForm}
      />
    </ACGModalContent>
  );
};

export default compose<Props, IInviteFormProps>(
  withStyles(style),
  withAccount
)(InviteForm);
