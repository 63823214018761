import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { compose } from 'recompose';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import { Theme } from '@mui/material';
import { checkRight } from '../../utils/userUtils';
import Grid from '@mui/material/Grid';
import UserPicture from '../../library/dataDisplay/profile/UserPicture';
import { DARK_GREY_COLOR, PRIMARY_COLOR } from '../../styles/constants';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import { generateLocation } from '../../utils/locationHelper';
import IconDownArrow from '../../library/dataDisplay/icons/IconDownArrow';

export interface IMenuDropdownProps {}

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const style = (theme: Theme) =>
  createStyles({
    dropdownWrapper: {
      marginLeft: 35,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '&:hover': {
        // textDecoration: 'underline',
        cursor: 'pointer',
        color: PRIMARY_COLOR,
      },
    },
    iconText: {
      display: 'block',
      fontSize: 12,
      fontFamily: 'Replica-Regular',
      whiteSpace: 'nowrap',
      margin: '0 4px 0 0',
    },
    avatar: {
      margin: 10,
      marginLeft: 0,
    },
    link: {
      fontWeight: 500,
      textDecoration: 'none',
      color: '#000',
    },
    menuItem: {
      '&:hover': {
        // backgroundColor: setColorOpacity(theme.palette.primary.light, 70)
        backgroundColor: 'transparent',
        color: PRIMARY_COLOR,
      },
    },
    menu: {
      backgroundColor: '#fff',
      width: 255,
    },
    menuBorder: {
      borderBottom: `1px solid ${DARK_GREY_COLOR} `,
    },
  });

type Props = IMenuDropdownProps &
  IWithAccountProps &
  WithStyles<string> &
  WithWidth &
  RouteComponentProps<{}>;

function MenuDropdown(props: Props) {
  const [element, setElement] = React.useState(null);

  const [isHovering, setIsHovering] = React.useState(false);

  const { account, history, classes, width } = props;

  const handleClick = (event: any) => {
    setElement(event.currentTarget);
  };

  const handleClose = () => {
    setElement(null);
  };

  const handleProfileClick = () => {
    const right = checkRight(account);
    const profileUrl =
      account.profile === 'ARTIST' || right !== 'VIEWER'
        ? `/users/${account.url}/posts`
        : `/users/${account.url}/followers`;
    // const profileUrl = right === "VIEWER" ? `/users/${account.url}/followers` : `/users/${account.url}/posts`
    history.push(profileUrl);
  };

  const handleEditProfileClick = () => {
    const right = checkRight(account);
    const profileUrl =
      right === 'VIEWER'
        ? `/users/${account.url}/followers?edit=true`
        : `/users/${account.url}/posts?edit=true`;
    history.push(profileUrl);
  };

  const toggleHovering = (isHovering: boolean) => {
    setIsHovering(isHovering);
  };

  const getInstitutionsLinks = (venues: any) => {
    return venues.map((venue: any) => {
      const handleInstitutionClick = () => {
        const institutionUrl = `/venues/${venue.url}`;
        history.push(institutionUrl);
        window.location.reload();
      };

      return (
        <div
          className={classes.link}
          onClick={handleInstitutionClick}
          key={`org_link_${venue.id}`}
        >
          <MenuItem
            className={`${classes.menuItem} ${classes.menuBorder}`}
            onClick={handleClose}
          >
            <Grid
              container
              spacing={1}
              flexWrap={'nowrap'}
              alignItems={'center'}
            >
              <Grid item>
                <ListItemIcon>
                  <UserPicture user={venue} />
                </ListItemIcon>
              </Grid>
              <Grid item>
                <div style={{ lineHeight: 1 }}>
                  <Typography variant="inherit">{venue.name}</Typography>
                </div>
                <div style={{ lineHeight: 1 }}>
                  <Typography
                    variant="inherit"
                    style={{
                      fontSize: 12,
                      color: DARK_GREY_COLOR,
                    }}
                  >
                    {generateLocation(venue)}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </MenuItem>
        </div>
      );
    });
  };

  const isMobile = isWidthDown('md', width);

  return (
    <div style={{ padding: '20px 0px' }}>
      <div
        aria-owns={element ? 'simple-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        onMouseOver={() => toggleHovering(true)}
        onMouseOut={() => toggleHovering(false)}
        className={classes.dropdownWrapper}
      >
        <div
          style={{
            alignItems: 'center',
            paddingBottom: isMobile ? 23 : 5,
            display: 'flex',
            opacity: isHovering ? 0.85 : 1,
          }}
        >
          {isMobile && (
            <div style={{ margin: 5 }}>
              <IconDownArrow
                height={14}
                fill={isHovering ? PRIMARY_COLOR : 'black'}
              />
            </div>
          )}
          <UserPicture
            user={account}
            style={isMobile ? { height: 35, width: 35 } : {}}
          />
        </div>
        {!isMobile && (
          <div style={{ display: 'flex' }}>
            <p className={classes.iconText}>My profile</p>
            <IconDownArrow
              height={14}
              fill={isHovering ? PRIMARY_COLOR : 'black'}
            />
          </div>
        )}
      </div>
      <StyledMenu
        classes={{ paper: classes.menu }}
        id="simple-menu"
        anchorEl={element}
        open={!!element}
        onClose={handleClose}
        disableAutoFocusItem={true}
      >
        <div className={classes.link} onClick={handleProfileClick}>
          <MenuItem
            className={`${classes.menuItem} ${classes.menuBorder}`}
            onClick={handleClose}
          >
            <Grid
              container
              spacing={1}
              flexWrap={'nowrap'}
              alignItems={'center'}
            >
              <Grid item>
                <ListItemIcon>
                  <UserPicture user={account} />
                </ListItemIcon>
              </Grid>
              <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ lineHeight: 1 }}>
                  <Typography variant="inherit">
                    {account.firstName} {account.lastName}
                  </Typography>
                </div>
                <div style={{ lineHeight: 1 }}>
                  <Typography
                    variant="inherit"
                    style={{ fontSize: 12, color: DARK_GREY_COLOR }}
                  >
                    {generateLocation(account)}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </MenuItem>
        </div>

        {account.manages && getInstitutionsLinks(account.manages)}

        {account.isBackOfficeAdmin && (
          <Link className={classes.link} to={`/back-office`}>
            <MenuItem className={classes.menuItem} onClick={handleClose}>
              Admin
            </MenuItem>
          </Link>
        )}
        <div onClick={handleProfileClick} className={classes.link}>
          <MenuItem className={classes.menuItem} onClick={handleClose}>
            My Profile
          </MenuItem>
        </div>

        <div onClick={handleEditProfileClick} className={classes.link}>
          <MenuItem
            className={`${classes.menuItem} ${classes.menuBorder}`}
            onClick={handleClose}
          >
            Edit Profile
          </MenuItem>
        </div>
        <Link className={classes.link} to={`/logout`}>
          <MenuItem className={classes.menuItem} onClick={handleClose}>
            Logout
          </MenuItem>
        </Link>
      </StyledMenu>
    </div>
  );
}

export default compose<Props, IMenuDropdownProps>(
  withAccount,
  withRouter,
  withWidth(),
  withStyles(style)
)(MenuDropdown);
