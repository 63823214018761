import * as React from 'react';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Tabs } from '@mui/material';
import { BREAKPOINT, PRIMARY_COLOR, SPACING } from '../../styles/constants';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import { useDispatch, useSelector } from 'react-redux';
import Tab from '@mui/material/Tab';
import * as institutionActions from '../../store/modules/institutions/actions';
import ProfileInstitutionHeader from '../../components/institution/ProfileInstitutionHeader';
import InstitutionService from '../../services/InstitutionService';
import * as _ from 'lodash';
import SocialModal from '../social/SocialModal';
import InviteButton from '../../library/inputs/InviteButton';
import { IInstitution } from '../../types/institution';
import { IStoreState } from '../../store';
import { useIsWidthDown } from '../../hooks/theme';
import { useHistory } from 'react-router-dom';

const PREFIX = 'Institutions';

const classes = {
  root: `${PREFIX}-root`,
  topBorder: `${PREFIX}-topBorder`,
  titleContainer: `${PREFIX}-titleContainer`,
  button: `${PREFIX}-button`,
  buttonSmall: `${PREFIX}-buttonSmall`,
  closeButton: `${PREFIX}-closeButton`,
  tabs: `${PREFIX}-tabs`,
  indicator: `${PREFIX}-indicator`,
  scrollButton: `${PREFIX}-scrollButton`,
  selected: `${PREFIX}-selected`,
};

const Root = styled('div')({
  [`& .${classes.root}`]: {
    textTransform: 'none',
    fontFamily: 'Replica-Regular',
  },
  [`& .${classes.topBorder}`]: {
    borderTop: `1px solid ${PRIMARY_COLOR}`,
  },
  [`& .${classes.titleContainer}`]: {
    padding: SPACING,
  },
  [`& .${classes.button}`]: {
    padding: 25,
    borderRadius: 100,
    marginBottom: 20,
  },
  [`& .${classes.buttonSmall}`]: {
    padding: '7px 20px',
    minHeight: 'auto',
    borderRadius: 30,
    display: 'inline-block',
    width: 'auto',
    marginLeft: 5,
    fontSize: '0.6em',
    minWidth: 0,
  },
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: '2px',
    top: '2px',
  },
  [`& .${classes.tabs}`]: {
    letterSpacing: -0.12,
    textAlign: 'right',
    minWidth: 100,
    '& button': {
      minWidth: 100,
      fontSize: 14,
    },
  },
  [`& .${classes.indicator}`]: {
    backgroundColor: PRIMARY_COLOR,
  },
  [`& .${classes.selected}`]: {
    color: 'black !important',
  },
  [`& .${classes.scrollButton}`]: {
    width: 10,
  },
});

const Institutions = (props: IWithAccountProps) => {
  const institutionService = new InstitutionService();
  const { institutions } = useSelector(
    (state: IStoreState) => state.institutions
  );
  const dispatch = useDispatch();

  const [tabSelected, setTabSelected] = useState<string>('ALL');
  const [following, setFollowing] = useState<any[]>([]);
  const [shareDialogOpen, setShareDialogOpen] = useState<boolean>(false);
  const [institutionToShare, setInstitutionToShare] = useState<
    IInstitution | undefined
  >(undefined);

  const handleTabChange = (e: any, val: string) => {
    setTabSelected(val);
    dispatch(institutionActions.fetchRequest(val));
  };

  useEffect(() => {
    dispatch(institutionActions.fetchRequest());
    if (props.account) {
      institutionService
        .getUserFollowings(props.account.id)
        .then((userFollowing) =>
          setFollowing(
            userFollowing.data.map((d) => (d.following ? d.following.url : []))
          )
        );
    }
  }, []);

  const history = useHistory();
  const isMobile = useIsWidthDown(BREAKPOINT);
  const isPad = useIsWidthDown('md');

  const onConnectHandler = () => {
    // window.console.log('ConnectHandler')
  };
  const onShareClose = () => {
    setShareDialogOpen(false);
    setInstitutionToShare(undefined);
  };

  const venueTypeOptions = [
    { label: 'All', value: 'ALL' },
    { label: 'Institutions', value: 'INSTITUTION' },
    { label: 'Art Galleries', value: 'ART_GALLERY' },
  ];

  return (
    <Root style={{ paddingTop: 80 }}>
      <Grid container={true} className={classes.root}>
        <Grid container={true}>
          <Grid item={true} xs={7} md={10}>
            <Tabs
              value={tabSelected}
              classes={{
                root: classes.tabs,
                indicator: classes.indicator,
                scrollButtons: classes.scrollButton,
              }}
              onChange={handleTabChange}
              scrollButtons={true}
              variant="scrollable"
            >
              {venueTypeOptions.map((type) => {
                return (
                  <Tab
                    label={type.label}
                    value={type.value}
                    key={type.value}
                    classes={{
                      selected: classes.selected,
                      root: `${classes.root} ${!isPad && classes.topBorder}`,
                    }}
                  />
                );
              })}
            </Tabs>
          </Grid>
          {props.account && (
            <Grid
              item={true}
              xs={5}
              md={2}
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                padding: 10,
              }}
            >
              <InviteButton
                buttonSize={isMobile ? 'small' : 'medium'}
                profileInvited={'Venues'}
              />
            </Grid>
          )}
        </Grid>
        {institutions.length === 0 ? (
          <></>
        ) : (
          institutions.map((institution: IInstitution) => {
            const isFollowing = following.indexOf(institution.url) > -1;
            const isManager = _.find(institution.managedBy, (manager) => {
              if (!props.account) {
                return false;
              } else {
                return manager.id === props.account.id;
              }
            });
            const onShareClickHandler = () => {
              setInstitutionToShare(institution);
              setShareDialogOpen(true);
            };

            const onEditClickHandler = async () => {
              history.push(`/venues/${institution.url}`);
            };
            const onFollowHandler = () => {
              if (isFollowing) {
                if (institution) {
                  institution.followers_number--;
                  dispatch(
                    institutionActions.updateInstitution(
                      institution as any,
                      false
                    )
                  );
                  setFollowing(following.filter((d) => d !== institution.url));
                }
              } else {
                if (institution) {
                  institution.followers_number++;
                  dispatch(
                    institutionActions.updateInstitution(
                      institution as any,
                      false
                    )
                  );
                  setFollowing([...following, institution.url]);
                }
              }
            };

            return (
              <React.Fragment key={institution.id}>
                <ProfileInstitutionHeader
                  institution={institution}
                  isManager={!!isManager}
                  isFollowing={isFollowing}
                  isVenueListing={true}
                  onConnectHandler={onConnectHandler}
                  onFollowHandler={onFollowHandler}
                  onEditClick={onEditClickHandler}
                  onShareClick={onShareClickHandler}
                  showConnect={false}
                />
              </React.Fragment>
            );
          })
        )}
        {institutionToShare && (
          <SocialModal
            open={shareDialogOpen}
            onClose={onShareClose}
            object={{
              type: 'URL',
              object: {
                url: `venues/${institutionToShare.url}`,
                title: institutionToShare.name,
              },
              showIframeOption: false,
            }}
          />
        )}
      </Grid>
    </Root>
  );
};

export default withAccount(Institutions);
