import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Formik, FormikProps, Field } from 'formik';
import { Box, Grid } from '@mui/material';
import { compose } from 'recompose';
import { withAccount, IWithAccountProps } from '../account/InjectAccount';
import { IValidationConfig, generateValidator } from '../../utils/formatters';
import ProfileUpload from '../../components/form/ProfileUpload';
import FormSubmit from '../../components/form/FormSubmit';
import CheckboxInput from '../../components/form/CheckboxInput';
import LocationFinder from '../../components/form/LocationFinder';
import { DARK_GREY_COLOR } from '../../styles/constants';

export interface ICuratorStep3Props {}

type Props = RouteComponentProps<{}> &
  ICuratorStep3Props &
  IWithAccountProps &
  WithStyles<string>;

interface IFormValue {
  profilePicture: string;
  newsletter_network: boolean;
  newsletter_curtain: boolean;
  location: {
    address: string;
    value: string;
    placeId: string;
    latLng: [number, number];
  };
}

interface IState {
  error: boolean;
}

const styles = createStyles({
  main: {
    width: '100%',
    position: 'relative',
    zIndex: 1,
    minHeight: '100%',
  },
  navbar: {
    flexGrow: 1,
    position: 'sticky',
    display: 'flex',
    justifyContent: 'center',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
  },
  colorDefault: {
    backgroundColor: 'white',
    height: 60,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  content: {
    zIndex: 2,
    height: '100%',
    width: '100%',
    minHeight: '100%',
    justifyContent: 'center',
  },
  wrapper: {},
  logo: {
    height: 40,
    float: 'left',
    marginRight: 15,
  },
  title: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    float: 'left',
    minWidth: 220,
    cursor: 'pointer',
  },
  titleSmall: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    float: 'left',
    minWidth: 125,
    cursor: 'pointer',
  },
  left: {
    float: 'left',
    width: '100%',
  },
  right: {
    float: 'right',
    width: 250,
  },
  companyName: {
    lineHeight: '40px',
  },
  companyNameSmall: {
    lineHeight: '40px',
    fontSize: '15px',
  },
  beta: {
    width: 26,
    height: 14,
    fontFamily: 'Replica-Regular',
    fontSize: 12,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -0.12,
    textAlign: 'center',
    color: '#6236ff',
    marginLeft: 5,
  },
  grow: {
    flex: 1,
  },

  formArea: {
    justifyContent: 'center',
    maxWidth: '50%',
    flexBasis: '50%',
  },
  contentMobile: {
    zIndex: 2,
    height: '100%',
    width: '100%',
    minHeight: '100%',
    justifyContent: 'center',
    paddingTop: 65,
  },

  checkboxSection: {
    paddingLeft: 22,
    paddingRight: 22,
  },
  label: {
    color: DARK_GREY_COLOR,
    marginBottom: '5px',
  },

  checkboxClass: {
    color: '#6d7278',
    fontSize: '12',
    letterSpacing: '-0.12px',
  },
});

class CuratorStep3 extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: false,
    };
  }
  public onSubmit = async (values: IFormValue, { setSubmitting }: any) => {
    const { updateAccount, account, history } = this.props;
    this.setState({ error: false });
    try {
      await updateAccount({
        ...account,
        ...values,
        hasSkippedProfilePicture: true,
      });
      history.push('/login');
    } catch (err) {
      this.setState({ error: true });
    }
    setSubmitting(false);
  };

  public renderForm = (props: FormikProps<IFormValue>) => {
    const { classes, account } = this.props;

    return (
      <form onSubmit={props.handleSubmit}>
        <Grid
          container={true}
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item={true} xs={12} sm={6} md={6}>
            <Field
              name="profilePicture"
              component={ProfileUpload}
              inputProps={{
                id: 'profilePicture',
                label: 'Upload your Picture',
                type: 'profilePicture',
              }}
            />
          </Grid>

          <Grid item={true} xs={12} sm={6} md={6}>
            <div className={classes.label}>
              {account ? `${account.firstName} ${account.lastName}` : ''}
            </div>
            <Field
              name="location"
              component={LocationFinder}
              inputProps={{
                id: 'location',
                label: 'Your City',
                type: 'location',
              }}
              naked={true}
              newLocationFinder={true}
            />
          </Grid>

          <Grid
            container={true}
            // xs={12}
            className={classes.text}
            direction="row"
          >
            <Grid
              item={true}
              xs={12}
              className={classes.checkboxSection}
              style={{}}
            >
              <Field
                name="newsletter_network"
                component={CheckboxInput}
                // classes={classes}
                inputProps={{
                  id: 'newsletter_network',
                  type: 'newsletter_network',
                }}
                label={
                  'I agree to receive latest news from our network (sent approximately once a month).'
                }
              />
            </Grid>
          </Grid>
          <Grid
            container={true}
            // xs={12}
            className={classes.text}
            direction="row"
          >
            <Grid
              item={true}
              xs={12}
              className={classes.checkboxSection}
              style={{}}
            >
              <Field
                name="newsletter_curtain"
                component={CheckboxInput}
                // classes={classes}
                inputProps={{
                  id: 'newsletter_curtain',
                  type: 'newsletter_curtain',
                }}
                label={
                  'I subscribe to Curtain, the Artpool Magazine newsletter. Twice a month, get your insider’s roundup of the latest curators interviewed.'
                }
              />
            </Grid>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <FormSubmit
              disabled={props.isSubmitting}
              newButton={true}
              variant="contained"
            >
              Sign Up
            </FormSubmit>
          </Grid>
        </Grid>
      </form>
    );
  };

  public render() {
    // const onHomeClick = () => history.push('/')

    // const hidden = isWidthDown(BREAKPOINT, width);
    // const logoHidden = isWidthDown("xs", width)

    const initialValues: IFormValue = {
      profilePicture: '',
      newsletter_network: true,
      newsletter_curtain: true,
      location: {
        address: '',
        value: '',
        placeId: '',
        latLng: [0, 0],
      },
    };

    const config: IValidationConfig = {
      location: ['REQUIRED'],
      profilePicture: ['REQUIRED'],
    };

    const validate = generateValidator(config);

    return (
      <Box
        mx={2}
        style={{ height: 'calc(100vh - 90px)' }}
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <Grid container={true} direction="column" spacing={2} xs={12} md={6}>
          <Formik
            initialValues={initialValues}
            onSubmit={this.onSubmit}
            validate={validate}
            render={this.renderForm}
          />
        </Grid>
      </Box>
    );
  }
}

export default compose<Props, ICuratorStep3Props>(
  withRouter,
  withAccount,
  withStyles(styles)
)(CuratorStep3);
