import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { Grid } from '@mui/material';
import { IUser } from '../../../types/user';
import { GREY_COLOR } from '../../../styles/constants';
import Title from '../typography/Title';
import SubTitle from '../typography/SubTitle';
import UserPicture from './UserPicture';
import { generateLocation } from '../../../utils/locationHelper';

export interface IProfileSmallProps {
  user: IUser;
}

type Props = IProfileSmallProps & WithStyles<string>;

const styles = createStyles({
  profile: {
    paddingTop: '0.75rem',
  },
  profileContainer: {
    backgroundColor: GREY_COLOR,
    paddingTop: 14,
    paddingLeft: 10,
    paddingBottom: 10,
  },
  user: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '40px',
  },
  userName: {
    fontSize: 16,
  },
});

function ProfileSmall(props: Props) {
  const { classes, user } = props;

  return (
    <Grid container={true} className={classes.profile}>
      <Grid item={true} xs={12} className={classes.profileContainer}>
        <UserPicture user={user} style={{ float: 'left', marginRight: 10 }} />
        <div className={classes.user}>
          {user.firstName && user.lastName && (
            <Title className={classes.userName}>
              {user.firstName} {user.lastName}
            </Title>
          )}
          <SubTitle>{generateLocation(user)}</SubTitle>
        </div>
      </Grid>
    </Grid>
  );
}

export default compose<Props, IProfileSmallProps>(withStyles(styles))(
  ProfileSmall
);
