import { createRouteRequest } from 'typesafe-api';
import { AuthApiClient } from '@acg/shared/api-spec-utils';
import {
  UploadSignedUrlEndpointDef,
  UploadSignedUrlParams,
  uploadSignedUrlRoute,
} from '../endpoints';

export class FileClient extends AuthApiClient {
  /**
   * Send Print Request Email
   */
  private _uploadSignedUrl = createRouteRequest<UploadSignedUrlEndpointDef>(
    this,
    uploadSignedUrlRoute
  );

  public uploadSignedUrl = (body: UploadSignedUrlParams) =>
    this._uploadSignedUrl({ body });
}
