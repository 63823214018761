import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { Button, Dialog, Grid } from '@mui/material';
import { compose } from 'recompose';
import { IExhibition } from '../../types/exhibitions';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import { RouteComponentProps } from 'react-router';
import {
  DARK_GREY_COLOR,
  PRIMARY_COLOR,
  SPACING,
} from '../../styles/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import * as exhibitionsActions from '../../store/modules/exhibitions/actions';
import * as commentActions from '../../store/modules/comments/actions';
import Loading from '../../components/loading/Loading';
import OnlineExhibitionLayout from '../../library/layout/container/OnlineExhibitionLayout';
import NotFoundRedirect from '../../components/errors/NotFoundRedirect';
import { checkRight } from '../../utils/userUtils';
import * as _ from 'lodash';
import { generateMetaForOnlineExhibition } from '../../components/meta';
import { getImageUrl } from '../../utils/imageUrl';
import Title from '../../library/dataDisplay/typography/Title';
import SubTitle from '../../library/dataDisplay/typography/SubTitle';
import { dateToStringParser } from '../../utils/datetimehelper';
import Text from '../../library/dataDisplay/typography/Text';
import OnlineExhibitionTabs from '../../components/onlineexhibition/OnlineExhibitionTabs';
import { IExhibitionFilters } from '../../services/ExhibitionService';
import { deserializeUrl } from '../../utils/router';
import OnlineExhibitionActionButtons from '../../components/onlineexhibition/OnlineExhibitionActionButtons';
import ButtonDownArrow from '../../components/buttons/ButtonDownArrow';
import ACGModalContent from '../../components/modal/ACGModalContent';
// import VisitorService from "../../services/VisitorServices";
import { IPost } from '../../types/posts';
import { Link, Redirect } from 'react-router-dom';
import { getExhibitionArtists } from '../../utils/exhibition';
import { IStoreState } from '../../store';
import { ACGAppPaths } from '@acg/shared/const';
import { IUser } from '../../types/user';

export interface IOnlineExhibitionProps {
  onExhibitionDone: () => void;
  onExhibitionCreate: (exhibition: IExhibition) => void;
  onExhibitionUpdate: (exhibition: IExhibition) => void;
}

const MAP_HEIGHT = '580px';

const style = createStyles({
  root: {
    flexGrow: 1,
  },
  notAllowedCursor: {
    cursor: 'not-allowed',
    color: '#000',
  },
  container: {
    paddingTop: 80,
    paddingRight: 10,
    paddingLeft: 10,
    backgroundColor: '#fff',
    flexGrow: 1,
  },
  textMobile: {
    fontFamily: 'Replica-Regular',
    '& h1': {
      fontSize: 35,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 15,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 12,
    },
  },
  textDesktop: {
    fontFamily: 'Replica-Regular',
    '& h1': {
      fontSize: 50,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 30,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 16,
    },
  },
  curatedBy: {
    cursor: 'pointer',
    '&:hover': {
      color: PRIMARY_COLOR,
    },
  },
  venueLink: {
    color: '#000',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: PRIMARY_COLOR,
    },
  },
  mapContainer: {
    // padding: 30,
  },
  mapContainerMobile: {},
  pointsContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: `calc(${MAP_HEIGHT} + 20px)`,
    minHeight: `calc(${MAP_HEIGHT} + 20px)`,
  },
  exhibitionTitle: {
    fontSize: 30,
    // paddingLeft:20,
  },
  exhibitionTitleMobile: {
    fontSize: 20,
  },
  exhibitionTextMobile: {
    fontSize: 15,
  },
  exhibitionDate: {
    fontSize: 20,
    color: '#afb0b3',
    // paddingLeft:20,
  },
  artistTitle: {
    fontSize: 20,
    color: '#afb0b3',
  },
  postOuter: {
    padding: `${SPACING}px ${SPACING}px`,
  },
  postInner: {
    display: 'block',
    margin: '10px 0',
    padding: '0 10px',
    borderRight: '1px solid black',
  },
  postInnerMobile: {
    display: 'block',
    margin: '10px 0',
    // padding: '0 10px',
  },
  lineBottomWrapper: {
    padding: `0 ${SPACING}px`,
    display: 'block',
  },
  lineBottom: {
    borderBottom: '1px solid',
  },
  lineRight: {
    paddingTop: 10,
    borderRight: '1px solid black',
  },
  draftSection: {
    paddingTop: 10,
    height: 50,
    borderColor: PRIMARY_COLOR,
    borderStyle: 'dotted',
    fontSize: 20,
    color: PRIMARY_COLOR,
    textAlign: 'center',
  },
  creditText: {
    textAlign: 'center',
    color: '#afb0b3',
  },
  mobileFeaturedImageWrapper: {
    textAlign: 'center',
    minHeight: 300,
    display: 'flex',
    flexDirection: 'column',
  },
  desktopFeaturedImageWrapper: {
    textAlign: 'center',
    height: '90%',
  },
});

type Props = WithStyles<string> &
  IWithAccountProps &
  WithWidth &
  RouteComponentProps<{ exhibitionId: string }>;

const OnlineExhibition: React.FC<Props> = (props) => {
  const {
    match: {
      params: { exhibitionId },
    },
    location: { search },
    history,
    classes,
    width,
  } = props;

  // This is a little hacky it would probably be better to create a different HOC
  // for when account is optional
  const account = props.account as IUser | undefined;

  const { exhibition, isLoading, error, isDeleted } = useSelector(
    (state: IStoreState) => state.exhibitions
  );
  const dispatch = useDispatch();

  // The modal definitions
  // const [openPressRelease, setOpenPressRelease] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  // const [modalCatalog,setModalCatalog] = useState<boolean>(false);
  // const [shareDialogOpen, setShareDialogOpen] = useState<boolean>(false);

  // // Setting the map used constants
  const [overflow, setOverflow] = useState(false);
  // // Actions and events
  const [element] = useState(undefined);
  const [post, setPost] = useState<IPost>();

  /*
        Setting the references
     */
  const contentRef = useRef<any | undefined>();

  if (isDeleted) {
    history.push(`/users/${account?.url}/posts`);
  }

  /*
        Functions section
     */
  const fetchData = () => {
    const isLoggedUser = account && account.id ? account.id : undefined;

    const key = deserializeUrl(search);
    const query: IExhibitionFilters = {
      key: key && key.key ? key.key : undefined,
    };
    // fetchComments();

    dispatch(
      exhibitionsActions.getExhibition(exhibitionId, isLoggedUser, query)
    );
  };

  const fetchComments = () => {
    if (exhibition && exhibition.post) {
      dispatch(commentActions.getComments(exhibition.post.id));
      setPost(exhibition.post);
    }
  };

  const checkOverflow = (el?: HTMLDivElement) => {
    if (!el) {
      return false;
    }
    const curOverflow = el.style.overflow;

    if (!curOverflow || curOverflow === 'visible') {
      el.style.overflow = 'hidden';
    }
    const isOverflowing =
      el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

    el.style.overflow = curOverflow;

    return isOverflowing;
  };

  const onTagClick = (id: string) => {
    if (!isViewer) {
      history.push(ACGAppPaths.ROOT, { tags: [id] });
    }
  };

  const scrollToMap = () => {
    const pointToScroll = contentRef.current;
    if (pointToScroll) {
      window.scroll({ top: pointToScroll.offsetTop - 78, behavior: 'smooth' });
      // pointToScroll.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  };

  const setMapLatLonZoom = (
    latLon: [number, number],
    highlightPoint?: number
  ) => {
    if (!highlightPoint) {
      highlightPoint = -1;
    }
    // setSelectedPointId(highlightPoint);
  };

  const editOnlineExhibition = (evt: any) => {
    if (exhibition) {
      history.push('/create-online-exhibition/' + exhibition.url);
    }
  };

  const deleteOnlineExhibitionOpen = () => setDeleteDialogOpen(true);
  const deleteOnlineExhibitionClose = () => setDeleteDialogOpen(false);

  const onDeleteOnlineExhibition = async (evt: any) => {
    try {
      if (exhibition) {
        dispatch(exhibitionsActions.deleteExhibition(exhibition.url));
        /*await this.exhibitionService.deleteExhibition(onlineExhibition.url)
                history.push(`/users/${onlineExhibition.created_by.url}/posts`)
                track(account.id, 'ExhibitionDeleted', (onlineExhibition as any));*/
      }
    } catch (error: any) {
      window.console.log('TO BE DELETED ', error);
      /*
            this.setState({error})
            this.setState({error})
            */
    }
  };

  const onLikeClick = () => {
    if (!account) {
      history.push(ACGAppPaths.LOGIN, { from: history.location });
    }
    if (exhibition) {
      dispatch(exhibitionsActions.likeExhibition(exhibition.url));
    }
  };

  const renderCuratedBy = () => {
    if (exhibition) {
      const createdBy = exhibition.created_by;
      const curatorsList = exhibition.curated_by || [];

      // If the creator is a curator, add him/her to curatorsList
      if (
        createdBy.profile === 'CURATOR' &&
        !curatorsList.some((curator) => curator.id === createdBy.id)
      ) {
        curatorsList.push(createdBy);
      }

      return curatorsList.length > 0 ? (
        <div style={{ paddingTop: 45 }}>
          <p style={{ display: 'inline' }}>Curated by: </p>
          {curatorsList.map((curator, i) => {
            const handleCuratorClick = () => {
              props.history.push(`/users/${curator.url}/posts`);
            };

            return (
              <React.Fragment key={'curator' + i + '_' + curator.id}>
                <h3 style={{ display: 'inline' }}>{i ? ', ' : ''}</h3>
                <h3
                  style={{ display: 'inline' }}
                  className={classes.curatedBy}
                  onClick={handleCuratorClick}
                >
                  {curator.firstName} {curator.lastName}
                </h3>
              </React.Fragment>
            );
          })}
        </div>
      ) : (
        <></>
      );
    } else {
      return;
    }
  };

  // Getting the institution data
  useEffect(() => {
    window.scroll({ top: 0 });
    if (exhibitionId) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (exhibition && exhibition.url === exhibitionId) {
      fetchComments();
    }
  }, [exhibition]);

  /*
        Variable sections
     */

  const isMobile = isWidthDown('xs', width);

  if (checkOverflow(element) && !overflow) {
    setOverflow(true);
  }

  let isViewer = true;

  if (account) {
    const right = checkRight(account);
    isViewer = right === 'VIEWER' || right === 'PENDING';
  }

  let mask = 0;

  let showShareButton = false;

  // How to display the exhibition date
  if (exhibition) {
    mask = exhibition.start_date ? mask | 1 : mask;
    mask = exhibition.end_date ? mask | 2 : mask;
    mask = exhibition ? mask | 4 : mask;

    const latLon: [number, number] = [
      (exhibition.base_map.size[0] * 0.4) / 2,
      (exhibition.base_map.size[1] * 0.4) / 2,
    ];

    setMapLatLonZoom(latLon);

    exhibition.exhibition_points = _.orderBy(
      exhibition.exhibition_points,
      ['point_order'],
      ['asc']
    );

    if (exhibition.exhibition_points) {
      /*
            artPointsRef = exhibition.exhibition_points.reduce((acc, value) => {
                acc['_artPoint_' + value.id] = useRef();
                return acc;
            }, {});

           */
    }
    if (exhibition.visibility === 'PUBLIC') {
      showShareButton = true;
    } else if (account && exhibition.created_by.id === account.id) {
      showShareButton = true;
    }
  }

  /*
        Render section
     */

  if (error && error.error_message === 'NOT_FOUND') {
    return <NotFoundRedirect />;
  }

  if (
    error &&
    error.error_message &&
    error.error_message.message === 'Unauthorized'
  ) {
    return <Redirect to="/login" />;
  }

  if (isLoading) {
    return <Loading />;
  }
  if (!exhibition && !isLoading) {
    return <></>;
  }

  const getVenueName = () => {
    if (exhibition && exhibition.venue_id) {
      return (
        <a
          href={`/venues/${exhibition.venue_id.url}`}
          className={classes.venueLink}
        >
          {exhibition.venue_id.name}
        </a>
      );
    } else if (exhibition && exhibition.gallery) {
      return exhibition.gallery.gallery_name;
    }
    return '';
  };

  return (
    <>
      <div className={classes.root}>
        <OnlineExhibitionLayout type={'ONLINEEXHIBITION'}>
          <Grid
            container={true}
            className={`${classes.container} ${
              isMobile ? classes.textMobile : classes.textDesktop
            }`}
          >
            {exhibition && generateMetaForOnlineExhibition(exhibition)}
            {exhibition && (
              <Grid container={true}>
                {exhibition.isDraft && (
                  <Grid
                    container={true}
                    direction="row"
                    justifyContent={'center'}
                    alignItems={'center'}
                    style={{ paddingTop: 40 }}
                  >
                    <Grid item={true} xs={12} className={classes.draftSection}>
                      THIS IS A DRAFT EXHIBITION
                    </Grid>
                  </Grid>
                )}

                {/* ------ MAIN INFO ------ */}

                <Grid
                  container={true}
                  style={{
                    height: isMobile ? undefined : 'calc(100vh - 80px)',
                  }}
                >
                  {exhibition.featured_imageWithCredit && (
                    <Grid
                      item={true}
                      xs={12}
                      sm={6}
                      className={
                        isMobile
                          ? classes.mobileFeaturedImageWrapper
                          : classes.desktopFeaturedImageWrapper
                      }
                    >
                      <img
                        src={getImageUrl(
                          exhibition.featured_imageWithCredit.image_url
                        )}
                        style={{
                          objectFit: 'cover',
                          height: isMobile ? 'inherit' : ' 100%',
                          flexGrow: isMobile ? 1 : 0,
                          width: '100%',
                        }}
                      />
                      <p
                        className={classes.creditText}
                        style={{
                          width: '95%',
                          fontSize: 12,
                        }}
                      >
                        {exhibition.featured_imageWithCredit.image_credit}
                      </p>
                    </Grid>
                  )}
                  <Grid
                    item={true}
                    xs={12}
                    sm={6}
                    style={{
                      height: isMobile ? undefined : '90%',
                      paddingLeft: isMobile ? 0 : 20,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        paddingBottom: 50,
                        paddingTop: isMobile ? 5 : 0,
                      }}
                    >
                      {(exhibition.venue_id || exhibition.gallery) && (
                        <h2 style={{ margin: 0 }} className={classes.curatedBy}>
                          {getVenueName()}
                        </h2>
                      )}
                    </div>
                    <div>
                      <Title
                        className={
                          isMobile
                            ? classes.exhibitionTitleMobile
                            : classes.exhibitionTitle
                        }
                      >
                        {exhibition.title}
                      </Title>
                      {(mask === 1 || mask === 5) && (
                        <>
                          <SubTitle
                            className={
                              isMobile
                                ? classes.exhibitionTextMobile
                                : classes.exhibitionDate
                            }
                          >
                            Start Date{' '}
                            {dateToStringParser(
                              exhibition.start_date,
                              'MMM Do YYYY'
                            )}{' '}
                          </SubTitle>
                        </>
                      )}
                      {(mask === 2 || mask === 6) && (
                        <>
                          <SubTitle
                            className={
                              isMobile
                                ? classes.exhibitionTextMobile
                                : classes.exhibitionDate
                            }
                          >
                            End Date{' '}
                            {dateToStringParser(
                              exhibition.end_date,
                              'MMM Do YYYY'
                            )}{' '}
                          </SubTitle>
                        </>
                      )}
                      {(mask === 3 || mask === 7) && (
                        <>
                          <SubTitle
                            className={
                              isMobile
                                ? classes.exhibitionTextMobile
                                : classes.exhibitionDate
                            }
                          >
                            {dateToStringParser(
                              exhibition.start_date,
                              'MMM Do YYYY'
                            )}{' '}
                            -
                            {dateToStringParser(
                              exhibition.end_date,
                              'MMM Do YYYY'
                            )}
                          </SubTitle>
                        </>
                      )}
                      <div style={{ marginTop: 24 }}>
                        {getExhibitionArtists(exhibition).map(
                          (artist: any, i: any) => {
                            return (
                              <Link
                                to={
                                  artist.url
                                    ? `/users/${artist.url}/posts`
                                    : '#'
                                }
                                key={'artist_' + i}
                                style={{ textDecoration: 'none' }}
                              >
                                <Text
                                  className={`${
                                    isMobile
                                      ? classes.exhibitionTextMobile
                                      : classes.artistTitle
                                  } ${
                                    artist.url
                                      ? classes.venueLink
                                      : classes.notAllowedCursor
                                  }`}
                                >
                                  {(i ? ', ' : '') + artist.name}
                                </Text>
                              </Link>
                            );
                          }
                        )}
                      </div>
                      {renderCuratedBy()}
                      {exhibition.co_curator_list &&
                        exhibition.co_curator_list.length > 0 && (
                          <div>
                            <p
                              style={{
                                color: DARK_GREY_COLOR,
                                display: 'inline',
                              }}
                            >
                              Co-curated with:{' '}
                            </p>
                            {exhibition.co_curator_list.map((coCurator, i) => {
                              return (
                                <Text
                                  className={
                                    isMobile
                                      ? classes.exhibitionTextMobile
                                      : classes.artistTitle
                                  }
                                  key={coCurator}
                                >
                                  {`${coCurator.trim()}${
                                    i + 1 < exhibition.co_curator_list!.length
                                      ? ', '
                                      : ''
                                  }`}
                                </Text>
                              );
                            })}
                          </div>
                        )}
                      {isMobile && (
                        <OnlineExhibitionActionButtons
                          exhibition={exhibition}
                          onLikeClick={onLikeClick}
                          deleteOnlineExhibition={deleteOnlineExhibitionOpen}
                          editOnlineExhibition={editOnlineExhibition}
                          showShareButton={showShareButton}
                        />
                      )}
                    </div>
                  </Grid>
                  {!isMobile && (
                    <Grid
                      item={true}
                      xs={12}
                      onClick={scrollToMap}
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <ButtonDownArrow />
                    </Grid>
                  )}
                </Grid>
                {/* ---- ACTION BUTTONS -------*/}
                <div ref={contentRef} style={{ width: '100%' }}>
                  <OnlineExhibitionTabs
                    onlineExhibition={exhibition}
                    post={post}
                    actionButtons={
                      <OnlineExhibitionActionButtons
                        editOnlineExhibition={editOnlineExhibition}
                        deleteOnlineExhibition={deleteOnlineExhibitionOpen}
                        onLikeClick={onLikeClick}
                        exhibition={exhibition}
                        showShareButton={showShareButton}
                      />
                    }
                    onTagClick={onTagClick}
                  />
                </div>
              </Grid>
            )}
          </Grid>
        </OnlineExhibitionLayout>

        {/* MODALS SECTION */}

        {/* --- Delete exhibition Dialog ----- */}
        <Dialog open={deleteDialogOpen}>
          <ACGModalContent>
            <div>
              <h2>Are you sure?</h2>
              <br />
              <div>
                You are about to delete an exhibition, this operation cannot be
                undone. Are you sure you want to proceed?
              </div>
              <Button onClick={deleteOnlineExhibitionClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={onDeleteOnlineExhibition}
                color="primary"
                autoFocus={true}
              >
                Proceed
              </Button>
            </div>
          </ACGModalContent>
        </Dialog>
      </div>
    </>
  );
};

export default compose<Props, IOnlineExhibitionProps>(
  withStyles(style),
  withWidth(),
  withAccount
)(OnlineExhibition);
