import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
// import * as ReactDOM from 'react-dom';
import {
  // Paper,
  // Popper,
  TextField,
  // MenuItem,
  Chip,
  Avatar,
} from '@mui/material';
import { compose } from 'recompose';
import Autosuggest from 'react-autosuggest';
import { FieldProps } from 'formik';
import { GREY_COLOR, PRIMARY_COLOR } from '../../styles/constants';
import ArtistService from '../../services/ArtistService';
import {
  withAccount,
  IWithAccountProps,
} from '../../containers/account/InjectAccount';
import { getSmallmageUrl } from '../../utils/imageUrl';
import Grid from '@mui/material/Grid';

export interface IArtistNameWrapperProps extends FieldProps {
  inputProps: any;
  helpText?: string;
}

export interface IArtist {
  id: string;
  profilePicture?: string;
  name: string;
  _id: number;
}

type Props = IArtistNameWrapperProps & WithStyles<string> & IWithAccountProps;

const style = createStyles({
  input: {
    backgroundColor: 'white',
    // backgroundImage: 'linear-gradient(to right, #f5f5f5, #f6f6f6 10%, #f7f7f7 20%, #f8f8f8 30%, #f9f9f9 40%, #fafafa 50%, #fbfbfb 60%, #fcfcfc 70%, #fdfdfd 80%, #fefefe 90%, #ffffff)',
  },
  chip: {
    marginLeft: 5,
    marginBottom: 5,
    borderRadius: 8,
  },
  suggestionStyle: {
    // margin: 5,
    fontFamily: 'Replica-Regular',
  },
  popper: {
    '& ul': {
      margin: 0,
      padding: 0,
      '& li': {
        listStyleType: 'none',
        cursor: 'pointer',
        '&:last-child': {
          fontWeight: 'bold',
        },
        '&:hover': {
          backgroundColor: GREY_COLOR,
        },
      },
    },
  },

  icon: {
    backgroundColor: PRIMARY_COLOR,
    borderRadius: 20,
    padding: 2,
    color: 'white',
    cursor: 'pointer',
    width: 20,
    height: 20,
    lineHeight: '20px',
    textAlign: 'center',
    fontWeight: 500,
  },
  errorMessage: {
    color: '#f44336',
    marginLeft: 14,
    marginRight: 14,
    fontSize: '0.75rem',
    marginTop: 3,
    textAlign: 'left',
    fontFamily: 'Replica-Regular',
    fontWeight: 400,
    lineHeight: 1.66,
  },
});

interface IState {
  suggestions: IArtist[];
  value: string;
}

class ArtistNameWrapper extends React.Component<Props, IState> {
  private artistService = new ArtistService();
  // private ref: any;
  // private nodeRef: any;

  public constructor(props: Props) {
    super(props);
    this.state = {
      suggestions: [],
      value: '',
    };
  }

  public onChange = (event: any, { newValue }: any) => {
    const { form, field } = this.props;
    this.setState({
      value: newValue,
    });
    form.setFieldTouched(field.name);
  };

  public onSuggestionSelected = (event: any, { suggestion }: any) => {
    if (suggestion.id === 'create') {
      this.createNewArtist(this.state.value);
    } else {
      this.addSuggestion(suggestion);
    }
  };

  public addSuggestion = (suggestion: any) => {
    const { form, field } = this.props;
    form.setFieldTouched(field.name);
    const foundValue = (field.value || []).find(
      (s: IArtist) => s.id === suggestion.id
    );
    if (!foundValue) {
      form.setFieldValue(field.name, (field.value || []).concat([suggestion]));
    }
  };

  public createNewArtist = (value: string) => {
    return this.artistService
      .createArtist({ name: value, created_by: this.props.account.url })
      .then((artist) => {
        return this.addSuggestion(artist.data);
      });
  };

  public onSuggestionsFetchRequested = (event: any) => {
    return this.artistService
      .getArtists({ url: this.props.account.url, search: event.value })
      .then((response) => {
        const curatorArtists = response.data[0].curatorArtists;
        const existingArtists = response.data[1].existingArtists;
        const newSuggestion = [
          { id: 'create', name: `Add new artist: ${this.state.value}` },
        ];
        const artistList: any = [];
        if (existingArtists && existingArtists.length > 0) {
          existingArtists.forEach((artist: any) => {
            artist.name = `${artist.firstName} ${artist.lastName}`;
            artistList.push(artist);
          });
        }
        if (curatorArtists && curatorArtists.length > 0) {
          curatorArtists.forEach((artist: any) => artistList.push(artist));
        }
        const r = artistList.slice(0, 5);
        if (
          r.find(
            (artist: any) =>
              artist.name.toLowerCase() ===
              this.state.value.trim().toLowerCase()
          )
        ) {
          this.setState({
            suggestions: r,
          });
        } else {
          this.setState({
            suggestions: r.concat(newSuggestion),
          });
        }
      })
      .catch((error) => {
        window.console.log('ERROR FETCHING SUGGESTIONS', error);
      });
  };

  public onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
      value: '',
    });
  };

  public renderInputComponent = (inputProps: any) => {
    const { classes, inputRef = () => ({}), ref, ...other } = inputProps;

    return (
      <React.Fragment>
        <TextField
          fullWidth={true}
          margin="normal"
          variant="outlined"
          className={classes.root}
          InputProps={{
            inputRef: (node) => {
              ref(node);
              inputRef(node);
            },
            classes: {
              input: classes.input,
            },
          }}
          {...other}
        />
      </React.Fragment>
    );
  };

  public handleDelete = (artistId: number) => () => {
    const { form, field } = this.props;
    form.setFieldTouched(field.name);
    const newValues = (field.value || []).filter((value: any) => {
      return value._id !== artistId;
    });
    form.setFieldValue(field.name, newValues);
  };

  public render() {
    const { field, form, classes, inputProps } = this.props;

    const { suggestions } = this.state;

    const getSuggestionValue = (suggestion: IArtist) => {
      return suggestion.id;
    };

    const renderSuggestion = (suggestion: IArtist) => {
      return (
        <Grid
          container={true}
          key={suggestion.id}
          className={classes.suggestionStyle}
          alignItems={'center'}
          spacing={1}
        >
          {suggestion.profilePicture && (
            <Grid item={true}>
              <Avatar
                alt={suggestion.name}
                src={getSmallmageUrl(suggestion.profilePicture)}
              />
            </Grid>
          )}
          <Grid item={true}>
            <p>{suggestion.name}</p>
          </Grid>
        </Grid>
      );
    };

    const artistsFromForm = this.props.form.values.exhibition_points
      ? this.props.form.values.exhibition_points[this.props.inputProps.index]
          .art_work.artists
      : this.props.form.values.artists;
    const chips =
      artistsFromForm &&
      artistsFromForm.length > 0 &&
      artistsFromForm.map((artist: any) => {
        const artistName = artist.name
          ? artist.name
          : `${artist.firstName} ${artist.lastName}`;

        return (
          <Chip
            key={artist._id}
            label={artistName}
            onDelete={this.handleDelete(artist._id)}
            className={classes.chip}
          />
        );
      });

    return (
      <div>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          // renderSuggestionsContainer={this.renderSuggestionsContainer(coordinate)}
          renderInputComponent={this.renderInputComponent}
          theme={{
            container: classes.popper,
            // input: classes.none
            // inputOpen: classes.none
            // inputFocused: classes.none
            // suggestionsContainer: classes.none
            // suggestionsContainerOpen: classes.none
            // suggestionsList: classes.none
            // suggestion: classes.none
            // suggestionHighlighted: classes.none
          }}
          inputProps={{
            ...inputProps,
            disabled: form.isSubmitting || inputProps.disabled,
            error: !!(form.touched[field.name] && form.errors[field.name]),
            helperText:
              form.touched[field.name] && form.errors[field.name]
                ? form.errors[field.name]
                : inputProps.helperText,
            value: this.state.value,
            onChange: this.onChange,
            classes,
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flexGrow: 1, flexBasis: 0 }}>{chips}</div>
        </div>
      </div>
    );
  }
}

export default compose<Props, IArtistNameWrapperProps>(
  withStyles(style),
  withAccount
)(ArtistNameWrapper);
