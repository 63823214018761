import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { Grid } from '@mui/material';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import {
  DARK_GREY_COLOR,
  GREY_COLOR,
  PRIMARY_COLOR,
  SPACING,
} from '../../styles/constants';
import { IInstitution } from '../../types/institution';
import { getOriginalImageUrl } from '../../utils/imageUrl';
import Tags from '../tags/Tags';
import Biography from '../profile/Biography';
import ProfileInstitutionContainer from './ProfileInstitutionContainer';

const style = createStyles({
  container: {
    width: '100%',
    backgroundColor: GREY_COLOR,
    padding: SPACING * 3,
    margin: `${SPACING * 2}px ${SPACING}px 0px ${SPACING}px`,
    flexBasis: 'unset',
  },
  avatar: {
    width: 99,
    height: 99,
  },
  headerTextContainer: {
    marginLeft: 23,
  },
  headerName: {
    fontSize: 20,
    fontWeight: 'normal',
    fontFamily: 'ReplicaPro',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#000000',
  },
  subText: {
    fontSize: 16,
    fontWeight: 'normal',
    fontFamily: 'ReplicaPro',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.15,
    letterSpacing: 'normal',
    color: '#afb0b3',
  },
  featuredImageSection: {
    height: 350,
    margin: `0px ${SPACING}px`,
  },
  featuredImage: {
    width: '100%',
    // maxHeight: 567,
  },
  wrapperCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 120,
  },
  colWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '150px',
    paddingRight: 45,
  },
  reverseWrapper: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    minHeight: 120,
  },
  marginRight: {
    // marginRight: 30,
  },
  counterWrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: 30,
    padding: '5px 0',
  },
  counter: {
    flexGrow: 0,

    textAlign: 'center',
    marginRight: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'normal',
    fontFamily: 'ReplicaPro',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#000000',
  },
  textMobile: {
    fontFamily: 'Replica-Regular',
    '& h1': {
      fontSize: 35,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 15,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 12,
    },
  },
  textDesktop: {
    fontFamily: 'Replica-Regular',
    '& h1': {
      fontSize: 50,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 30,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 16,
    },
  },
  gray: {
    color: DARK_GREY_COLOR,
  },
  textMargin: {
    margin: 0,
  },
  profile: {
    backgroundColor: GREY_COLOR,
    padding: SPACING,
    margin: 10,
    marginBottom: 0,
  },
  mobileProfile: {
    backgroundColor: GREY_COLOR,
    padding: SPACING,
    margin: 10,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '120px',
  },
  mobileWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapperReverse: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    minHeight: 90,
  },
  mobileButtonWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-around',
    flexDirection: 'column-reverse',
  },
  picture: {
    width: 90,
    height: 90,
  },
  mobilePicture: {
    width: 60,
    height: 60,
  },
  right: {
    textAlign: 'right',
    width: '100%',
  },
  button: {
    textTransform: 'none',
    borderRadius: 8,
    // padding: '25px 25px'
  },
  marginLeft: {
    marginLeft: 20,
  },
});

interface IProfileInstitutionHeader {
  institution: IInstitution;
  isManager: boolean;
  isVenueListing?: boolean;
  isFollowing: boolean;
  onFollowHandler: (institution?: IInstitution) => void;
  onConnectHandler: (institution?: IInstitution) => void;
  onShareClick: () => void;
  onEditClick: () => void;
  showConnect: boolean;
}

type props = IProfileInstitutionHeader & WithWidth & WithStyles<string>;

const ProfileInstitutionHeader: React.FC<props> = (Props) => {
  const {
    classes,
    institution,
    onFollowHandler,
    isFollowing,
    isManager,
    onShareClick,
    onEditClick,
    isVenueListing,
    onConnectHandler,
    // showConnect,
    width,
  } = Props;

  const isPad = isWidthDown('md', width);

  const onTagClick = () => {
    // window.console.log('onTagClick')
    // TODO: history.push(ACGAppPaths.ROOT, { tags: [id] });
    return;
  };

  return (
    <React.Fragment>
      <ProfileInstitutionContainer
        institution={institution}
        isManager={isManager}
        isFollowing={isFollowing}
        onConnectHandler={onConnectHandler}
        onFollowHandler={onFollowHandler}
        onEditClick={onEditClick}
        onShareClick={onShareClick}
        showConnect={true}
      />
      {institution.featuredPicture && !isVenueListing && (
        <Grid container={true} className={classes.featuredImageSection}>
          <img
            src={getOriginalImageUrl(institution.featuredPicture)}
            style={{ objectFit: 'cover', width: '100%', maxHeight: 350 }}
          />
        </Grid>
      )}
      {institution.about && institution.about !== '' && !isVenueListing && (
        <Grid
          container={true}
          className={isPad ? classes.textMobile : classes.textDesktop}
          style={{
            margin: '10px 20px',
            borderBottom: `1px solid ${PRIMARY_COLOR}`,
            paddingBottom: 20,
          }}
        >
          <Grid item={true} xs={12} sm={4}>
            <h3 style={{ marginTop: 0 }}>About</h3>
          </Grid>

          <Grid item={true} xs={12} sm={8}>
            <Biography
              user={institution}
              showAddress={true}
              height={isPad ? 135 : 152}
              socialFontSize={16}
              background={'white'}
            >
              <p
                style={{
                  margin: 0,
                  lineHeight: isPad ? '15px' : '19px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {institution.about}
              </p>
            </Biography>

            {isPad && (
              <div>
                <Tags
                  onTagClick={onTagClick}
                  tags={institution.tags}
                  tagName={'Venue Tags'}
                  style={{ fontSize: 12, margin: 0 }}
                />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default compose<props, IProfileInstitutionHeader>(
  withWidth(),
  withStyles(style),
  withRouter
)(ProfileInstitutionHeader);
