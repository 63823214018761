export type Member = {
  name: string;
  img: string;
  role: string;
  socialIcons?: {
    linkedin?: string;
    twitter?: string;
    instagram?: string;
  };
};

export const members: Member[] = [
  {
    img: 'pauline.png',
    name: 'Pauline Foessel',
    role: 'Artpool Founder and Director',
    socialIcons: {
      linkedin: 'https://pt.linkedin.com/in/pauline-foessel-56aa7811',
      twitter: 'https://twitter.com/FoesselPaulinel',
      instagram: 'https://www.instagram.com/paulinefoessel/',
    },
  },
  {
    img: 'delfina.png',
    name: 'Delfina Sena',
    role: 'Curators & Institutions Liaison',
    socialIcons: {
      linkedin: 'https://pt.linkedin.com/in/delfina-sena-35b67b159/',
      twitter: 'https://twitter.com/senadelfina',
      instagram: 'https://www.instagram.com/delfinasena__/'
    }
  },
  {
    img: 'julia.png',
    name: 'Julia Flamingo',
    role: 'Communication Associate',
    socialIcons: {
      linkedin: 'https://pt.linkedin.com/in/julia-flamingo-398529250/',
      twitter: 'https://twitter.com/bigorna_art',
      instagram: 'https://www.instagram.com/flamingo_julia/',
    }
  }
];
