import './user-profile.module.scss';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import {
  ConditionalWrapper,
  curatedOrOrganizedStr,
  getSmallImageUrl,
} from '@acg/shared/utilities';
import * as React from 'react';
import { ALTERNATIVE, NEUTRAL } from '@acg/assets';
import { JsonUserOrVenue } from '@acg/artpool-api-spec';
import Body2 from '../typography/body2/body2';
import Caption from '../typography/caption/caption';

const PREFIX = 'user-or-venue-thumbnail';

const classes = {
  root: `${PREFIX}-root`,
};

export enum UserOrVenueTypes {
  CURATOR = 'Curated by',
  ARTIST = 'Artist',
  ORGANIZER = 'Organized by',
}

export interface UserProfileProps {
  userOrVenue: JsonUserOrVenue;
  width?: number;
  fontColor?: string;
  hoverColor?: string;
  typeOverwrite?: UserOrVenueTypes;
  onlyName?: boolean;
  cloudFrontUrl: string;
}

export function UserOrVenueThumbnail(props: UserProfileProps) {
  const { userOrVenue, width, fontColor, hoverColor, typeOverwrite, onlyName, cloudFrontUrl } =
    props;
  const { profilePicture, name, acgPostsUrl } = userOrVenue;

  const Root = styled('a')({
    [`& .${classes.root}`]: {
      color: fontColor || 'black',
      cursor: 'pointer',
      textOverflow: 'ellipsis',
      maxWidth: width || 130,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '&:hover': {
        color: hoverColor || ALTERNATIVE.ARTPOOL_PINK,
      },
    },
  });

  const title = curatedOrOrganizedStr(userOrVenue);

  return (
    <Root href={acgPostsUrl} target={'_blank'}>
      <Grid
        container
        spacing={1}
        alignItems={'center'}
        style={{ flexWrap: 'nowrap', overflow: 'hidden' }}
      >
        <Grid item>
          <Avatar src={getSmallImageUrl(cloudFrontUrl, profilePicture)} alt={name} />
        </Grid>
        <Grid item style={{ overflow: 'auto' }}>
          <ConditionalWrapper
            wrap={!onlyName}
            wrapper={(children: React.ReactNode) => (
              <Grid container>
                <Grid item xs={12}>
                  <Caption style={{ color: NEUTRAL[400], paddingRight: 10 }}>
                    {typeOverwrite || title}
                  </Caption>
                </Grid>
                <Grid item xs={12}>
                  {children}{' '}
                </Grid>
              </Grid>
            )}
          >
            <Body2 className={classes.root}>{name}</Body2>
          </ConditionalWrapper>
        </Grid>
      </Grid>
    </Root>
  );
}
