import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import { StyledButton } from '@acg/frontend-ui-artpool';
import * as React from 'react';
import { Avatar, Button, Grid } from '@mui/material';
import { compose } from 'recompose';
import { RouteComponentProps, withRouter } from 'react-router';
import { DARK_GREY_COLOR, GREY_COLOR, SPACING } from '../../styles/constants';
import { IInstitution } from '../../types/institution';
import { getMediumImageUrl } from '../../utils/imageUrl';
import { generateCityLocation } from '../../utils/locationHelper';
import Tags from '../tags/Tags';
import FollowInstitution from '../../containers/institution/FollowInstitution';
import ConnectInstitutionButton from '../../containers/institution/ConnectInstitutionButton';
import ShareProfileButton from '../../containers/services/ShareProfilleButton';
import {
  IWithAccountProps,
  withAccount,
} from '../../containers/account/InjectAccount';
import InstitutionService from '../../services/InstitutionService';
import { useState } from 'react';
import { sumPostTotals } from '../../utils/post-totals-utils';
import { ACGAppPaths } from '@acg/shared/const';

const style = createStyles({
  textMobile: {
    fontFamily: 'Replica-Regular',
    '& h1': {
      fontSize: 35,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 15,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 12,
    },
  },
  textDesktop: {
    fontFamily: 'Replica-Regular',
    '& h1': {
      fontSize: 50,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 30,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 16,
    },
  },
  gray: {
    color: DARK_GREY_COLOR,
  },
  textMargin: {
    margin: 0,
  },
  profile: {
    backgroundColor: GREY_COLOR,
    padding: SPACING,
    margin: '20px 10px 0px 10px',
  },
  mobileProfile: {
    backgroundColor: GREY_COLOR,
    padding: SPACING,
    margin: '20px 10px 0px 10px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '120px',
  },
  mobileWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  mobileButtonWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-around',
    flexDirection: 'column-reverse',
  },
  right: {
    textAlign: 'right',
    width: '100%',
  },
  button: {
    textTransform: 'none',
    borderRadius: 4,
    // padding: '25px 25px'
  },
  marginLeft: {
    marginLeft: 10,
  },
  nameStyle: {
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

interface IProfileInstitutionContainer {
  institution: IInstitution;
  isManager: boolean;
  isFollowing: boolean;
  onFollowHandler: (institution?: IInstitution) => void;
  onConnectHandler: (institution?: IInstitution) => void;
  onShareClick: () => void;
  onEditClick: () => void;
  showConnect: boolean;
}

type props = IProfileInstitutionContainer &
  WithWidth &
  IWithAccountProps &
  WithStyles<string> &
  RouteComponentProps<{}>;

const ProfileInstitutionContainer: React.FC<props> = (Props) => {
  const {
    classes,
    institution,
    onFollowHandler,
    isFollowing,
    isManager,
    onShareClick,
    onEditClick,
    showConnect,
    width,
    history,
    account,
  } = Props;

  const isPad = isWidthDown('md', width);

  const onTagClick = (id: string) => {
    history.push(ACGAppPaths.ROOT, { tags: [id] });
  };

  const institutionService = new InstitutionService();
  const [totalPost, setTotalPosts] = useState(0);
  React.useEffect(() => {
    institutionService
      .getInstitutionPostTotals(institution.id)
      .then((postTotalsResp) => {
        setTotalPosts(sumPostTotals(postTotalsResp.data));
      });
  }, []);

  const institutionType = institution.institution_type
    ? institution.institution_type
        .toLowerCase()
        .replace('_', ' ')
        .replace(/\w\S*/g, (w: string) =>
          w.replace(/^\w/, (c: string) => c.toUpperCase())
        )
    : '';

  const onSelect = () => {
    history.push(`/venues/${institution.url}`, { from: '/venues' });
  };

  const locationText = institution.city
    ? institution.city
    : generateCityLocation(institution.location);

  return (
    <React.Fragment>
      {/*-------- PROFILE DESKTOP DESIGN -----------*/}
      {!isPad && (
        <Grid
          container
          className={`${classes.profile} ${
            isPad ? classes.textMobile : classes.textDesktop
          }`}
        >
          <Grid item xs={12} sm={4}>
            <div className={classes.wrapper}>
              <Avatar
                alt={`${institution.name}`}
                onClick={onSelect}
                sx={{
                  width: 90,
                  height: 90,
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                src={getMediumImageUrl(institution.profilePicture)}
                style={{ float: 'left', marginRight: 12, marginLeft: 16 }}
              />

              <div onClick={onSelect} className={classes.nameStyle}>
                {institution.name && (
                  <h3 className={classes.textMargin}>{institution.name}</h3>
                )}
                <p className={`${classes.gray} ${classes.textMargin}`}>
                  {institutionType}
                </p>
                <p className={`${classes.gray} ${classes.textMargin}`}>
                  {locationText}
                </p>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            style={{
              minHeight: 120,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                minHeight: 60,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <h3 style={{ margin: '0 35px 0 0' }}>
                  <strong>{totalPost}</strong> Posts
                </h3>
                <h3 style={{ margin: '0 35px 0 0' }}>
                  <strong>{institution.followers_number}</strong> Followers
                </h3>
              </div>
              <div>
                <Tags
                  onTagClick={onTagClick}
                  tags={institution.tags}
                  tagName={''}
                  style={{ fontSize: 16, margin: 0 }}
                />
              </div>
            </div>
          </Grid>
          {account && (
            <Grid
              item
              xs={12}
              sm={3}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {!isManager && (
                <Grid container spacing={1}>
                  {showConnect && (
                    <Grid item xs={6}>
                      <ConnectInstitutionButton institution={institution} />
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <FollowInstitution
                      institution={institution}
                      callback={onFollowHandler}
                      isFollowing={isFollowing}
                    />
                  </Grid>
                </Grid>
              )}
              {isManager && (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <StyledButton
                      fullWidth
                      onClick={onEditClick}
                      variant="outlined"
                    >
                      Edit
                    </StyledButton>
                  </Grid>
                  <Grid item xs={6}>
                    <ShareProfileButton onClick={onShareClick} />
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      )}
      {/*-------- PROFILE MOBILE DESIGN -----------*/}
      {isPad && (
        <Grid
          container
          className={`${classes.mobileProfile} ${classes.textMobile}`}
        >
          <Grid item xs={9} sm={10} className={classes.mobileWrapper}>
            <Avatar
              alt={`${institution.name}`}
              onClick={onSelect}
              sx={{
                width: 60,
                height: 60,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              src={getMediumImageUrl(institution.profilePicture)}
              style={{ float: 'left', marginRight: 12, marginLeft: 0 }}
            />

            <div onClick={onSelect}>
              {institution.name && (
                <h3
                  className={`${classes.nameStyle} ${classes.textMargin}`}
                  style={{ fontSize: 16 }}
                >
                  {institution.name}
                </h3>
              )}
              <p className={`${classes.gray} ${classes.textMargin}`}>
                {institutionType}
              </p>
              <p className={`${classes.gray} ${classes.textMargin}`}>
                {locationText}
              </p>
            </div>
          </Grid>
          {account && (
            <Grid item xs={3} sm={2}>
              {!isManager && (
                <div className={classes.mobileButtonWrapper}>
                  {showConnect && (
                    <ConnectInstitutionButton
                      buttonSize="small"
                      institution={institution}
                    />
                  )}
                  <FollowInstitution
                    institution={institution}
                    buttonSize="small"
                    callback={onFollowHandler}
                    isFollowing={isFollowing}
                  />
                </div>
              )}
              {isManager && (
                <div className={classes.mobileButtonWrapper}>
                  <StyledButton
                    size="small"
                    onClick={onShareClick}
                    variant="outlined"
                  >
                    Share
                  </StyledButton>
                  <StyledButton
                    style={{ marginBottom: 5 }}
                    size="small"
                    onClick={onEditClick}
                    variant="outlined"
                  >
                    Edit
                  </StyledButton>
                </div>
              )}
            </Grid>
          )}
          <Grid item xs={3} sm={2} />
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <p style={{ margin: '5px 10px 0px 0px', fontSize: 12 }}>
              <strong>{totalPost}</strong> Posts
            </p>
            <p style={{ margin: '5px 10px 0px 0px', fontSize: 12 }}>
              <strong>{institution.followers_number}</strong> Followers
            </p>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default compose<props, IProfileInstitutionContainer>(
  withWidth(),
  withStyles(style),
  withAccount,
  withRouter
)(ProfileInstitutionContainer);
