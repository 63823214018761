import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import dots from '../../img/dots.svg';
import { compose } from 'recompose';
import {} from '@mui/material';

export interface IDotsProps extends React.HTMLAttributes<HTMLDivElement> {
  big?: boolean;
}

type Props = IDotsProps & WithStyles<string>;

const styles = createStyles({
  size: {
    width: 14,
    cursor: 'pointer',
  },
  big: {
    width: 24,
    cursor: 'pointer',
  },
});

class Dots extends React.Component<Props, any> {
  public render() {
    const { classes, className, big, ...rest } = this.props;

    return (
      <div className={`${className ? className : ''}`} {...(rest as any)}>
        <img src={dots} className={big ? classes.big : classes.size} />
      </div>
    );
  }
}

export default compose<Props, IDotsProps>(withStyles(styles))(Dots);
