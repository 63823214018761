import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { RouteComponentProps, withRouter } from 'react-router';
import { withAccount, IWithAccountProps } from '../account/InjectAccount';
import DesignedButton from '../../library/inputs/DesignedButton';
import { Grid } from '@mui/material';
import LegalContent from './LegalContent';

export interface ILegalProps {
  tabValue: IRouteType;
}

const style = createStyles({
  textMobile: {
    '& h2': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 16,
    },
  },
  textDesktop: {
    '& h2': {
      fontSize: 30,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 16,
    },
  },
});

type Props = ILegalProps &
  RouteComponentProps<{}> &
  WithWidth &
  IWithAccountProps &
  WithStyles<string>;

export type IRouteType = 'PRIVACY' | 'CONDITIONS';

const Legal: React.FC<Props> = (props) => {
  const {
    history,
    location: { pathname },
    classes,
    width,
  } = props;

  const handleTermsClick = () => history.push('/terms-and-conditions');
  const handleConditionsClick = () => history.push('/privacy-policy');

  let content = '';
  if (pathname.includes('privacy-policy')) {
    content = 'PRIVACY';
  }
  if (pathname.includes('terms-and-conditions')) {
    content = 'CONDITIONS';
  }
  const isMobile = isWidthDown('sm', width);

  return (
    <div
      className={`${isMobile ? classes.textMobile : classes.textDesktop}`}
      style={{
        fontFamily: 'Replica-Regular',
        padding: isMobile ? '100px 10px' : '100px 20px',
      }}
    >
      <Grid container={true} justifyContent={'center'} spacing={1}>
        <Grid item={true} xs={6} sm={3}>
          <DesignedButton
            ACGType={content === 'PRIVACY' ? 'secondary' : 'main'}
            size={isMobile ? 'medium' : 'large'}
            fullWidth={true}
            onClick={handleTermsClick}
          >
            Terms & Conditions
          </DesignedButton>
        </Grid>
        <Grid item={true} xs={6} sm={3}>
          <DesignedButton
            ACGType={content === 'PRIVACY' ? 'main' : 'secondary'}
            size={isMobile ? 'medium' : 'large'}
            fullWidth={true}
            onClick={handleConditionsClick}
          >
            Privacy Policy
          </DesignedButton>
        </Grid>
      </Grid>
      <LegalContent content={content} />
    </div>
  );
};

export default compose<Props, ILegalProps>(
  withAccount,
  withRouter,
  withWidth(),
  withStyles(style)
)(Legal);
