import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { Field, FieldArrayRenderProps, getIn } from 'formik';
import { Grid, CircularProgress } from '@mui/material';
import { compose } from 'recompose';
import Trash from '../icons/Trash';
import TextInput from './TextInput';
import ACGLink from '../../library/inputs/ACGLink';
import { useState } from 'react';

export interface ICuratorInputProps extends FieldArrayRenderProps {}

const style = createStyles({
  root: {
    position: 'relative',
    '&:hover overlay': {
      backgroundColor: 'black',
      opacity: 1,
    },
  },
  overlay: {
    textAlign: 'right',
    marginLeft: 10,
  },
  addButton: {
    fontSize: 12,
    textDecoration: 'underline',
    padding: '20px 0',
    cursor: 'pointer',
    paddingBottom: '20px',
  },
  error: {
    color: 'red',
  },

  curatorsInfo: {
    width: '80%',
  },
  remove: {
    width: '70px',
  },

  curatorsInput: {
    width: '33%',
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  containerList: {
    width: '100%',
    paddingTop: '20px',
  },
});

type Props = ICuratorInputProps & WithStyles<string>;

const CuratorInput: React.FC<Props> = (props) => {
  const {
    form: { values, errors },
    name,
    remove,
    classes,
  } = props;

  const [loading, setLoading] = useState(false);

  const addCurator = () => {
    setLoading(true);
    const firstName = values.firstName;
    const lastName = values.lastName;
    const email = values.email;

    if (!firstName || !lastName || firstName === '' || lastName === '') {
      window.console.log('Setting Errors');

      props.form.errors.curators = 'Missing Data';
      setLoading(false);
    } else {
      const linkList = {
        firstName,
        lastName,
        email,
      };

      props.push(linkList);
      values.firstName = '';
      values.lastName = '';
      values.email = '';

      setLoading(false);
    }
  };

  const onBlurEvent = (event: any) => {
    event.persist();
  };

  const error = getIn(errors, name);

  return (
    <React.Fragment>
      <Grid container={true} direction="row">
        <div className={classes.containerList}>
          {values.curators.map((val: any, i: number) => {
            const onClick = () => remove(i);
            return (
              <div className={classes.container} key={`link_elem_${i}`}>
                <div className={classes.remove}>
                  <span onClick={onClick} className={classes.overlay}>
                    <Trash big={true} />
                  </span>
                </div>
                <div className={classes.curatorsInfo}>
                  <p>
                    {val.firstName} {val.lastName}
                  </p>
                  <p>{val.email}</p>
                </div>
              </div>
            );
          })}
        </div>
      </Grid>
      <Grid container={true} direction="row" spacing={2}>
        <Grid item={true} xs={12} sm={6}>
          <Field
            name="firstName"
            component={TextInput}
            newTextInput={true}
            naked={true}
            inputProps={{
              id: 'firstName',
              label: 'First Name',
              type: 'text',
              onBlur: onBlurEvent,
            }}
          />
        </Grid>
        <Grid item={true} xs={12} sm={6}>
          <Field
            name="lastName"
            component={TextInput}
            newTextInput={true}
            naked={true}
            inputProps={{
              id: 'lastName',
              label: 'Last Name',
              type: 'text',
            }}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <Field
            name="email"
            component={TextInput}
            newTextInput={true}
            naked={true}
            inputProps={{
              id: 'email',
              label: 'Email',
              type: 'text',
            }}
          />
        </Grid>
      </Grid>

      {loading ? (
        <CircularProgress />
      ) : (
        <ACGLink resize={12} onClick={addCurator}>
          Add Curator
        </ACGLink>
      )}
      {error && typeof errors[name] === 'string' ? (
        <div className={classes.error}>{error}</div>
      ) : undefined}
    </React.Fragment>
  );
};

export default compose<Props, ICuratorInputProps>(withStyles(style))(
  CuratorInput
);
