import queryString from 'query-string';
import { Index } from './types';

export interface ISearchSetting {
  paramName: string;
  defaultValue: any;
  deserialize: (query: Index<any>) => any;
  serialize: (value: any) => string;
  isValid: (query: Index<any>) => boolean;
}

export const isSearchValid = (search: string, settings: ISearchSetting[]) => {
  const query = queryString.parse(search);
  // notEmpty and must forall settings query isValid
  return (
    Object.keys(query).length > 0 &&
    settings.reduce((acc, setting) => {
      return acc && setting.isValid(query);
    }, true)
  );
};

export const QUERY_SEARCH_SETTINGS: ISearchSetting[] = [
  {
    paramName: 'query',
    defaultValue: '',
    deserialize: (query: Index<string>) => query.query,
    serialize: (value: string) => value,
    isValid: () => true,
  },
];

/**
 * @param {String} search location.search
 * @param {Array} settings (optional) type settings
 * @returns the parsed search string into object
 */
export function parseSearch<T = Index<any>>(
  search: string,
  settings?: ISearchSetting[]
): T {
  const query = queryString.parse(search) as any;
  if (!settings) {
    return query;
  }
  return settings.reduce(
    (acc, setting) => ({
      ...acc,
      [setting.paramName]: setting.deserialize(query),
    }),
    {}
  ) as T;
}

export const deserializeUrl = (url: string) => {
  if (url) {
    const search = url.substring(1);
    return JSON.parse(
      '{"' +
        decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    ) as any;
  } else {
    return;
  }
};

export const serializeUrl = (object: any) => {
  const qs = Object.keys(object)
    .map((key) => `${key}=${object[key]}`)
    .join('&');
  return qs as any;
};
