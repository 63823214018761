import styles from './body1.module.scss';
import React from 'react';

// tslint:disable-next-line:no-empty-interface
export interface Body1Props extends React.HTMLAttributes<HTMLParagraphElement> {
  children?: React.ReactNode;
}

export function Body1(props: Body1Props) {
  const { children, ...rest } = props;

  return (
    <p {...rest} className={styles.body1}>
      {children}
    </p>
  );
}
