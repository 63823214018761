import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import PostCover from './PostCover';
import { Grid, Button } from '@mui/material';
import { IPost, PostTypeOptions } from '../../types/posts';
import { compose } from 'recompose';
import { IDataListResponse, IDataResponse } from '../../services/ApiServices';
import { IError } from '../../types/returns';
import { IUser } from '../../types/user';
import Loading from '../loading/Loading';
import PostMobileButton from '../buttons/PostMobileButton';
import { BREAKPOINT } from '../../styles/constants';
import { ITag } from '../../types/tags';
import TagFilter from '../tags/TagFilter';
import { withRouter, RouteComponentProps } from 'react-router';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SearchTab from '../../containers/search/SearchPosts';
import { PRIMARY } from '@acg/assets';
import { ACGModal } from '../modal/ACGModal';
import { Footer } from '@acg/frontend-ui-artpool';

type loadPostsType = (
  pageNumber: number,
  sorts: Sorts,
  subType?: string,
  orderBy?: string,
  connectedUser?: IUser,
  filters?: IFilters
) => Promise<IDataListResponse<IPost>>;
type searchPostsType = (
  pageNumber: number,
  query: string,
  subType?: string,
  orderBy?: string
) => Promise<IDataListResponse<IPost>>;
type loadFilters = () => Promise<IDataListResponse<ITag>>;

export interface IPostsGridProps {
  noProfile?: boolean;
  forceLogin?: boolean;
  onPostClick: (postId: string, postType: string) => void;
  onCommentClick: (postId: string) => void;
  onShareClick: (post: IPost) => void;
  onPageChange: (pageNumber: number) => void;
  onClickOnAvatar?: (userId: string, isVenue?: boolean) => void;
  offset: number;
  loadPosts: loadPostsType;
  loadFilters?: loadFilters;
  searchPosts?: searchPostsType;
  query?: string;
  showAddButton?: boolean;
  onClickOnAdd?: () => void;
  connectedUser?: IUser;
  pageSize: number;
  onLike: (post: IPost) => Promise<IDataResponse<IPost>>;
  isLiking: (post: IPost) => boolean;
  modalComponent: React.ComponentClass<{
    onCreate: (post: IPost) => void;
    onUpdate: (post: IPost) => void;
    onLike: (post: IPost) => void;
    onDone: () => void;
    onRemove: (post: IPost) => void;
  }>;
  showModal: boolean;
  onCloseModal: () => void;
  showFilter?: boolean;
  noResult?: React.ReactNode;

  showTabs?: boolean;
  onTabSelection?: (tabSelected: string) => void;
  selectedTab?: string;
  showDraft?: boolean;
  venueId?: string;
}

const style = createStyles({
  topBorder: {
    borderTop: `1px solid ${PRIMARY.ARTPOOL_GREEN} !important`,
  },
  postBar: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: 60,
    lineHeight: '60px',
    textAlign: 'center',
    backgroundColor: 'white',
  },
  bottomContent: {
    textAlign: 'center',
    padding: '20px 0',
  },
  bottomContentMobile: {
    textAlign: 'center',
    padding: '20px 0 80px 0',
  },
  sorters: {
    fontSize: '0.8em',
    textAlign: 'right',
    padding: '10px 34px',
  },
  filters: {
    fontSize: '0.8em',
    textAlign: 'left',
    padding: '10px 34px 10px 59px',
  },
  selected: {
    fontWeight: 500,
    color: PRIMARY.ARTPOOL_GREEN,
  },
  normal: {
    cursor: 'pointer',
  },
  noResult: {
    width: '100%',
    padding: '50px 24px 250px 24px',
    textAlign: 'center',
  },
  infoNavBar: {
    marginBottom: 20,
    paddingBottom: 10,
    position: 'fixed',
    top: 80,
    backgroundColor: '#FFF',
    zIndex: 100,
    alignItems: 'center',
  },

  infoNavBarMobile: {
    marginBottom: 20,
    paddingBottom: 10,
    position: 'fixed',
    top: 80,
    backgroundColor: '#FFF',
    zIndex: 100,
  },
  tabsSelected: {
    color: `${PRIMARY.ARTPOOL_GREEN} !important`,
  },
  tabs: {
    letterSpacing: -0.12,
    textAlign: 'right',
    minWidth: 100,
    '& button': {
      minWidth: 100,
      fontSize: 14,
    },
  },
  indicator: {
    backgroundColor: `${PRIMARY.ARTPOOL_GREEN} !important`,
  },
  scrollButton: {
    width: 10,
  },
});

const tabStyle = {
  textTransform: 'none',
  fontFamily: 'Replica-Regular',
  color: 'black',
  '& .MuiButtonBase-root-MuiTab-root.Mui-selected': {
    color: 'red',
  },
};

export const hidePost = 'md';

export const gridConfig: {
  [keys in 'xs' | 'sm' | 'md' | 'lg' | 'xl']:
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12;
} = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 4,
  xl: 3,
};

type Props = IPostsGridProps &
  WithWidth &
  WithStyles<string> &
  RouteComponentProps<{}, {}, { tags: string[] }>;

export type Sorts = 'DISCOVER' | 'MY_GRID';

export interface IFilters {
  tags: string[];
}

interface IState {
  loading: boolean;
  error?: Error;
  data: IPost[];
  currentPage: number;
  hasMoreData: boolean;
  hasLoadedOnce: boolean;
  sort: Sorts;
  filters?: IFilters;
  tags: ITag[];
  height: number;
  message: string;
  tabSelected: string;
  orderBy?: string;
}

class PostsGrid extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      currentPage: 0,
      hasMoreData: false,
      hasLoadedOnce: false,
      sort: 'DISCOVER',
      tags: [],
      height: window.innerHeight,
      message: 'not at bottom',
      tabSelected: 'ALL',
      orderBy: 'LASTPOSTED',
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  public componentDidMount() {
    const {
      query,
      location: { state },
      selectedTab,
    } = this.props;

    if (selectedTab) {
      this.setState({ tabSelected: selectedTab });
    }

    if (query) {
      this.searchData(0, query);
    } else if (selectedTab) {
      this.setState({ tabSelected: selectedTab }, () => {
        this.getData(0);
      });
    } else {
      this.setState({
        filters: state && state.tags ? { tags: state.tags } : undefined,
      });
      this.getData(0, state && state.tags ? { tags: state.tags } : undefined);
    }

    window.addEventListener('scroll', this.handleScroll, { passive: false });
  }

  public componentDidUpdate(prevProps: Props) {
    const {
      query,
      location: { state },
      selectedTab,
    } = this.props;
    const {
      query: prevQuery,
      location: { state: prevState },
      selectedTab: prevSelectedTab,
    } = prevProps;

    if (prevQuery && !query) {
      this.setState({
        currentPage: 0,
        data: [],
        hasLoadedOnce: false,
        filters: state && state.tags ? { tags: state.tags } : undefined,
      });
      this.getData(0, state && state.tags ? { tags: state.tags } : undefined);
    } else if (!prevQuery && query) {
      this.setState(
        { currentPage: 0, data: [], hasLoadedOnce: false, tabSelected: 'ALL' },
        () => {
          this.searchData(0, query);
        }
      );
    } else if (query && prevQuery && prevQuery !== query) {
      this.setState(
        { currentPage: 0, data: [], hasLoadedOnce: false, tabSelected: 'ALL' },
        () => {
          this.searchData(0, query);
        }
      );
    } else if (
      (state &&
        state.tags &&
        prevState &&
        prevState.tags &&
        state.tags !== prevState.tags) ||
      (state && state.tags && !prevState)
    ) {
      this.setState({
        currentPage: 0,
        data: [],
        hasLoadedOnce: false,
        filters: state && state.tags ? { tags: state.tags } : undefined,
      });
      this.getData(0, state.tags ? { tags: state.tags } : undefined);
    } else if (!state && prevState && prevState.tags) {
      this.setState({
        currentPage: 0,
        data: [],
        hasLoadedOnce: false,
        filters: undefined,
      });
      this.getData(0);
    } else if (selectedTab && prevSelectedTab !== selectedTab) {
      this.setState(
        { tabSelected: selectedTab, currentPage: 0, data: [] },
        () => {
          this.getData(0);
        }
      );
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  public getFilters = () => {
    return this.props.loadFilters
      ? this.props.loadFilters().then((r) => {
          this.setState({
            tags: r.data,
          });
        })
      : Promise.resolve();
  };

  public getData = (pageNumber: number = 0, filters?: IFilters) => {
    const { connectedUser, loadPosts, pageSize } = this.props;

    const { sort, tabSelected, orderBy } = this.state;

    this.setState({ loading: true });
    this.getFilters();
    return loadPosts(
      pageNumber,
      sort,
      tabSelected,
      orderBy,
      connectedUser,
      filters
    )
      .then((data) => {
        this.setState({
          loading: false,
          hasLoadedOnce: true,
          data: [...this.state.data, ...data.data],
          currentPage: pageNumber,
          hasMoreData: data.count >= pageSize ? true : false,
        });
      })
      .catch((err: IError) => {
        this.setState({
          loading: false,
          error:
            err.status === 'error' ? new Error(err.error_message) : undefined,
        });
      });
  };

  public searchData = (pageNumber: number = 0, query: string) => {
    const { searchPosts, pageSize } = this.props;

    const { tabSelected, orderBy } = this.state;

    this.setState({ loading: true });
    return searchPosts
      ? searchPosts(pageNumber, query, tabSelected, orderBy)
          .then((data) => {
            this.setState({
              loading: false,
              hasLoadedOnce: true,
              data: [...this.state.data, ...data.data],
              currentPage: pageNumber,
              hasMoreData:
                data.count !== 0 || data.count === pageSize ? true : false,
            });
          })
          .catch((err: IError) => {
            this.setState({
              loading: false,
              error:
                err.status === 'error'
                  ? new Error(err.error_message)
                  : undefined,
            });
          })
      : Promise.resolve();
  };

  public onLikeUnlike = (post: IPost) => {
    const { onLike } = this.props;
    const { data } = this.state;

    return onLike(post).then((updatedPost) => {
      const updatedData: IPost[] = data.map((d) => {
        if (d.id === updatedPost.data.id) {
          return updatedPost.data;
        }
        return d;
      });
      return this.setState({ data: updatedData });
    });
  };

  public onLikeInModal = (post: IPost) => {
    const { data } = this.state;

    const updatedData: IPost[] = data.map((d) => {
      if (d.id === post.id) {
        return post;
      }
      return d;
    });
    return this.setState({ data: updatedData });
  };

  public onCreatePost = (post: IPost) => {
    if (post.status === 'DRAFT' && !this.props.showDraft) {
      return;
    }

    const { data } = this.state;

    const updatedData: IPost[] = [post, ...data];
    return this.setState({ data: updatedData });
  };

  public onUpdatePost = (post: IPost) => {
    const { data } = this.state;

    let updatedData: IPost[];
    if (this.props.showDraft) {
      updatedData = data.map((d) => {
        if (d.id === post.id) {
          return post;
        }
        return d;
      });
    } else {
      updatedData = data
        .filter((item) => !(item.id === post.id && post.status === 'DRAFT'))
        .map((d) => {
          if (d.id === post.id) {
            return post;
          }
          return d;
        });
    }

    return this.setState({ data: updatedData });
  };

  public removeData = (post: IPost) => {
    const { data } = this.state;

    const updatedData = data.filter((p) => p.id !== post.id);
    return this.setState({ data: updatedData });
  };

  public handleScroll = () => {
    // This will avoid of call the Load more again if it's still loading
    if (!this.state.loading) {
      const windowHeight =
        'innerHeight' in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      const windowBottom = windowHeight + window.pageYOffset;
      if (windowBottom >= docHeight) {
        const { query } = this.props;
        const { currentPage, hasMoreData } = this.state;
        if (hasMoreData) {
          this.setState({
            message: 'bottom reached',
          });
          query
            ? this.searchData(currentPage + 1, query)
            : this.getData(currentPage + 1);
        } else {
          window.removeEventListener('scroll', this.handleScroll);
        }
      } else {
        this.setState({
          message: 'not at bottom',
        });
      }
    }
  };

  public render() {
    const {
      width,
      onPostClick,
      onClickOnAvatar,
      offset,
      showAddButton,
      onClickOnAdd,
      // connectedUser,
      classes,
      isLiking,
      modalComponent,
      showModal,
      onCloseModal,
      showFilter,
      showTabs,
      query,
      loadFilters: loadTags,
      onCommentClick,
      onShareClick,
      noResult,
      selectedTab,
    } = this.props;

    const {
      currentPage,
      hasMoreData,
      loading,
      data: posts,
      sort,
      tags,
      filters,
      tabSelected,
      orderBy,
    } = this.state;

    const modulo = 12 / gridConfig[width];

    const isMobile = isWidthDown(BREAKPOINT, width);
    const isPad = isWidthDown('sm', width);

    const loadNextPage = () => {
      if (this.props.forceLogin) {
        this.props.history.push('/login');
      } else {
        query
          ? this.searchData(currentPage + 1, query)
          : this.getData(currentPage + 1);
      }
    };

    const onSortClick = (a: Sorts) => () => {
      window.addEventListener('scroll', this.handleScroll, { passive: false });
      this.setState(
        {
          sort: a,
          loading: true,
          data: [],
          tabSelected: 'ALL',
        },
        () => {
          this.getData(0);
        }
      );
    };

    const Component = modalComponent;

    const handleTagChange = (t: string[]) => {
      window.addEventListener('scroll', this.handleScroll, { passive: false });

      this.setState(
        {
          loading: true,
          filters: { tags: t },
          currentPage: 0,
          hasLoadedOnce: false,
          error: undefined,
          data: [],
          tabSelected: 'ALL',
        },
        () => {
          this.getData(currentPage, { tags: t });
        }
      );
    };

    const handleTabChange = (e: any, val: string) => {
      window.addEventListener('scroll', this.handleScroll, { passive: false });
      this.setState(
        {
          tabSelected: val,
          loading: true,
          data: [],
        },
        () => {
          if (query) {
            this.searchData(0, query);
          } else {
            this.getData(0);
          }
        }
      );
    };

    const setOrderBy = (orderByValue: string) => {
      this.setState(
        {
          loading: true,
          data: [],
          orderBy: orderByValue,
        },
        () => {
          if (query) {
            this.searchData(0, query);
          } else {
            this.getData(0);
          }
        }
      );
    };

    const showFollowing = () => (query ? false : true);

    return (
      <React.Fragment>
        {showFilter && loadTags && (
          <Grid item xs={6} className={classes.filters}>
            <TagFilter
              availableTags={tags}
              handleChange={handleTagChange}
              selectedTags={filters && filters.tags ? filters.tags : []}
            />
          </Grid>
        )}
        {showFilter && (
          <Grid item xs={6} className={classes.sorters}>
            <a
              className={
                sort === 'DISCOVER' ? classes.selected : classes.normal
              }
              style={{ paddingRight: 15 }}
              onClick={onSortClick('DISCOVER')}
            >
              Discover
            </a>
            <a
              className={sort === 'MY_GRID' ? classes.selected : classes.normal}
              onClick={onSortClick('MY_GRID')}
            >
              My Grid
            </a>
          </Grid>
        )}
        {showTabs && (
          <>
            <Grid
              container
              className={
                !isMobile ? classes.infoNavBar : classes.infoNavBarMobile
              }
            >
              {isMobile && (
                <Grid item md={false} sm={12} xs={12}>
                  <SearchTab
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    showFollowing={showFollowing()}
                  />
                </Grid>
              )}
              <Grid item md={9} sm={12} className={classes.tabs}>
                <Tabs
                  value={tabSelected}
                  classes={{
                    root: classes.tabs,
                    indicator: classes.indicator,
                    scrollButtons: classes.scrollButton,
                  }}
                  onChange={handleTabChange}
                  scrollButtons
                  variant="scrollable"
                >
                  {!selectedTab && (
                    <Tab
                      label="All"
                      value={'ALL'}
                      key={'ALL'}
                      sx={tabStyle}
                      classes={{
                        selected: classes.tabsSelected,
                        root: `${classes.root} ${!isPad && classes.topBorder}`,
                      }}
                    />
                  )}
                  {PostTypeOptions.map((type) => {
                    return (
                      <Tab
                        sx={tabStyle}
                        label={type.tabLabel}
                        value={type.id}
                        key={type.id}
                        classes={{
                          selected: classes.tabsSelected,

                          root: `${classes.root} ${
                            !isPad && classes.topBorder
                          }`,
                        }}
                      />
                    );
                  })}
                </Tabs>
              </Grid>
              {!isMobile && (
                <Grid item md={3} sm={false}>
                  <SearchTab
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    showFollowing={showFollowing()}
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
        {posts.map((data, n) => {
          const onClick = () => onPostClick(data.url, data.type);
          const onCClick = () => onCommentClick(data.id);
          const onSClick = () => onShareClick(data);
          const computedIndex = n + 1 + offset; // add +1 to have the real position and not the index. Add + 1 to add offset for the add post card
          const onCategoryButtonClick = (selectedCategory: string) => {
            handleTabChange(null, selectedCategory);
          };

          return (
            <Grid
              key={data.id}
              item
              xs={gridConfig.xs}
              sm={gridConfig.sm}
              md={gridConfig.md}
              lg={gridConfig.lg}
              xl={gridConfig.xl}
            >
              <PostCover
                noProfile={this.props.noProfile}
                onShare={onSClick}
                onCommentClick={onCClick}
                onLike={this.onLikeUnlike}
                onClickOnAvatar={onClickOnAvatar}
                onCategoryButtonClick={onCategoryButtonClick}
                isLiking={isLiking}
                isDraft={data.status === 'DRAFT'}
                post={data}
                onClick={onClick}
                hasRightBorder={
                  !(computedIndex % modulo === 0 && computedIndex !== 0)
                }
              />
            </Grid>
          );
        })}
        {hasMoreData && !loading ? (
          <Grid
            key="showMore"
            item
            className={
              showAddButton && isWidthDown(hidePost, width)
                ? classes.bottomContentMobile
                : classes.bottomContent
            }
            xs={12}
          >
            <Button onClick={loadNextPage}>Load More</Button>
          </Grid>
        ) : null}
        {loading ? (
          <Grid
            key="loading"
            item
            className={
              showAddButton && isWidthDown(hidePost, width)
                ? classes.bottomContentMobile
                : classes.bottomContent
            }
            xs={12}
          >
            <Loading />
          </Grid>
        ) : null}
        {showAddButton && isWidthDown(hidePost, width) && (
          <div key="addMobile" className={classes.postBar}>
            <PostMobileButton onClick={onClickOnAdd}>Post</PostMobileButton>
          </div>
        )}
        {!loading && posts.length === 0 ? (
          sort === 'MY_GRID' ? (
            <div className={classes.noResult}>
              Feed your Grid by following curators
            </div>
          ) : (
            <div className={classes.noResult}>
              {noResult ? noResult : 'No post yet in this section'}
            </div>
          )
        ) : undefined}
        {!hasMoreData && !isMobile && <Footer />}
        {showModal && (
          <ACGModal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={showModal}
            disableAutoFocus
            onClose={onCloseModal}
            id={'postgrid-modal'}
          >
            <Component
              onDone={onCloseModal}
              onUpdate={this.onUpdatePost}
              onCreate={this.onCreatePost}
              onLike={this.onLikeInModal}
              onRemove={this.removeData}
            />
          </ACGModal>
        )}
      </React.Fragment>
    );
  }
}

export default compose<Props, IPostsGridProps>(
  withWidth(),
  withStyles(style),
  withRouter
)(PostsGrid);
