import styles from './supported-by.module.scss';
import Grid from '@mui/material/Grid';
import {
  OUTLIER_VENTURES_URL,
  PRIMAL_URL,
  UNIQUE_URL,
} from '@acg/shared/const';
import {
  OutlierVenturesIcon,
  PrimalIcon,
  themes,
  UniqueIcon,
} from '@acg/assets';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';

export const SupportedBy = () => {
  const isMedium = useMediaQuery(themes.artpoolTheme.breakpoints.down('lg'));

  return (
    <Grid
      container
      className={styles.supporters}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Grid item xs={12}>
        <h4>Supported By</h4>
      </Grid>
      <Grid item xs={isMedium ? 12 : undefined}>
        <a href={PRIMAL_URL} target={'_blank'}>
          <PrimalIcon height={38} />
        </a>
      </Grid>
      <Grid item xs={isMedium ? 12 : undefined}>
        <a href={OUTLIER_VENTURES_URL} target={'_blank'}>
          <OutlierVenturesIcon height={34} />
        </a>
      </Grid>
      <Grid item xs={isMedium ? 12 : undefined}>
        <a href={UNIQUE_URL} target={'_blank'}>
          <UniqueIcon height={62} />
        </a>
      </Grid>
    </Grid>
  );
};
