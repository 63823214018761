import { createStyles, withStyles, WithStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect } from 'react';
import { AccountContext } from './AccountContext';
import UserService from '../../services/UserService';
import {
  IArtistUser,
  ICuratorUser,
  IUser,
  IViewerUser,
} from '../../types/user';
import Loading from '../../components/loading/Loading';
import PendingValidation from '../../components/errors/PendingValidations';
import { Redirect } from 'react-router';
import UnexpectedError from '../../components/errors/UnexpectedError';
import Signup2 from '../signup/Signup2';
import CuratorStep2 from '../signup/CuratorStep2';
import CuratorStep3 from '../signup/CuratorStep3';
import ViewerStep2 from '../signup/ViewerStep2';
import ViewerStep3 from '../signup/ViewerStep3';
import ViewerStep4 from '../signup/ViewerStep4';
import { compose } from 'recompose';
import { checkRight } from '../../utils/userUtils';
import Onboarding from '../onboarding/Onboarding';
import { useDispatch, useSelector } from 'react-redux';
import * as accountActions from '../../store/modules/account/actions';
import OtherArtStep from '../signup/OtherArtStep';
import NewCenterLayout from '../../library/layout/container/NewCenterLayout';
import { IStoreState } from '../../store';
import ViewerStep5 from '../signup/ViewerStep5';
import { ACGModal } from '../../components/modal/ACGModal';

export interface IAccountWrapperProps {
  scrollToTop: (scrolToTop: number) => void;
  hasScrollEvent: boolean;
  disableScroll: boolean;
  children?: React.ReactNode;
}

const styles = createStyles({
  backdrop: {
    backgroundColor: 'rgba(242,242,242,0.92)',
  },

  withScrollEvent: {
    overflowY: 'auto',
  },

  withNoScrollEvent: {
    height: '100%',
    overflowY: 'auto',
  },
});

type Props = IAccountWrapperProps & WithStyles<string>;

const AccountWrapper: React.FC<Props> = (props) => {
  const userService = new UserService();

  const { account, isLoading, error } = useSelector(
    (state: IStoreState) => state.account
  );

  const { user } = useSelector((state: IStoreState) => state.authReducer);

  const [open, setOpen] = React.useState<boolean>(false);
  const [scrollTop, setScrollTop] = React.useState<number>(0);

  const myRef = React.useRef<any | undefined>();

  const dispatch = useDispatch();

  const { scrollToTop, disableScroll, classes, hasScrollEvent } = props;

  useEffect(() => {
    if (user) {
      dispatch(accountActions.getAccount());
    } else {
      dispatch(accountActions.logout());
    }
  }, [user]);

  useEffect(() => {
    if (account) {
      setOpen(!account.hasTakenTour);
    }
  }, [account]);

  useEffect(() => {
    scrollToTop(scrollTop);
  }, [scrollTop]);

  const updateAccountProfile = (accountData: Partial<IUser>) => {
    return userService.updateProfile(accountData).then((data) => {
      const user: IUser = data.data[0]; // The update profile now returns the user and institution data
      dispatch(accountActions.updateAccount({ user }));
      return data;
    });
  };

  const checkCurrentRegistrationStepForCurator = (
    accountCurator: ICuratorUser
  ): 'NONE' | 'STEP_2' | 'STEP_3' => {
    if (!accountCurator.company_type) {
      return 'STEP_2';
    }
    if (
      !accountCurator.profilePicture &&
      !accountCurator.hasSkippedProfilePicture
    ) {
      return 'STEP_3';
    }
    return 'NONE';
  };
  const checkCurrentRegistrationStepForArtist = (
    accountViewer: IArtistUser
  ): 'NONE' | 'STEP_2' | 'STEP_3' | 'STEP_4' | 'STEP_5' => {
    if (
      !accountViewer.profilePicture &&
      !accountViewer.hasSkippedProfilePicture
    ) {
      return 'STEP_3';
    }
    if (!accountViewer.hasWorkedIn) {
      return 'STEP_4';
    }
    if (!accountViewer.hasWorkedWith) {
      return 'STEP_5';
    }
    return 'NONE';
  };

  const checkCurrenRegistrationStepForViewer = (
    accountViewer: IViewerUser | IArtistUser
  ): 'NONE' | 'STEP_2' | 'STEP_3' => {
    if (!accountViewer.type) {
      return 'STEP_2';
    }
    if (
      !accountViewer.profilePicture &&
      !accountViewer.hasSkippedProfilePicture
    ) {
      return 'STEP_3';
    }
    return 'NONE';
  };

  const onClose = () => {
    setOpen(false);
  };

  const onScroll = () => {
    if (disableScroll) {
      return;
    }
    // - QUES
    const scrollTopRef = myRef.current && myRef.current.scrollTop;
    setScrollTop(scrollTopRef);
  };

  let content = props.children;
  let modalContent = null;

  if (isLoading) {
    content = <Loading />;
  }

  // check if user is logged in
  if (
    error &&
    error.status === 'error' &&
    error.error_message === 'NOT_AUTHORIZED'
  ) {
    content = <Redirect to={'/logout'} />;
  }
  if (error && error.status === 'error' && error.error_message === 'PENDING') {
    content = <PendingValidation />;
  }
  if (error) {
    content = <UnexpectedError error={error} />;
  }

  // check if user has taken step 1 / 2 of registration
  if (account) {
    if (account.profile === 'CURATOR') {
      const curatorRegistartionStatus =
        checkCurrentRegistrationStepForCurator(account);
      switch (curatorRegistartionStatus) {
        case 'STEP_2':
          content = (
            <NewCenterLayout>
              <CuratorStep2 />
            </NewCenterLayout>
          );

          break;
        case 'STEP_3':
          content = (
            <NewCenterLayout>
              <CuratorStep3 />
            </NewCenterLayout>
          );

          break;
        case 'NONE':
          // uncomment this line to block a curator from posting
          const right = checkRight(account);
          if (right === 'PENDING') {
            content =
              account.pending && !account.isTouring ? (
                <PendingValidation />
              ) : (
                props.children
              );
          }
          if (right === 'ALL') {
            const onOnboardingEnd = () => {
              setOpen(false);
            };
            modalContent = account.hasTakenTour ? null : (
              <Onboarding onOnboardingEnd={onOnboardingEnd} />
            );
          }
          break;
      }
    }
    if (account.profile === 'ARTIST') {
      const artistRegistrationStatus =
        checkCurrentRegistrationStepForArtist(account);
      switch (artistRegistrationStatus) {
        case 'STEP_3':
          content = (
            <NewCenterLayout>
              <ViewerStep3 />
            </NewCenterLayout>
          );

          break;
        case 'STEP_4':
          content = (
            <NewCenterLayout>
              <ViewerStep4 />
            </NewCenterLayout>
          );
          break;
        case 'STEP_5':
          content = (
            <NewCenterLayout>
              <ViewerStep5 />
            </NewCenterLayout>
          );
          break;
        case 'NONE':
          const onOnboardingEnd = () => {
            setOpen(false);
          };
          modalContent = account.hasTakenTour ? null : (
            <Onboarding onOnboardingEnd={onOnboardingEnd} />
          );
          break;
      }
    }
    if (account.profile === 'VIEWER') {
      const curatorRegistartionStatus =
        checkCurrenRegistrationStepForViewer(account);
      switch (curatorRegistartionStatus) {
        case 'STEP_2':
          content = (
            <NewCenterLayout>
              <ViewerStep2 />
            </NewCenterLayout>
          );

          break;
        case 'STEP_3':
          content = (
            <NewCenterLayout>
              <ViewerStep3 />
            </NewCenterLayout>
          );

          break;
        case 'NONE':
          const onOnboardingEnd = () => {
            setOpen(false);
          };
          modalContent = account.hasTakenTour ? null : (
            <Onboarding onOnboardingEnd={onOnboardingEnd} />
          );
          break;
      }
      if (account.type === 'OTHER ART PROFESSIONAL') {
        content = (
          <NewCenterLayout>
            <OtherArtStep />
          </NewCenterLayout>
        );
      }
    }
    if (!account.profile) {
      content = (
        <NewCenterLayout>
          <Signup2 />
        </NewCenterLayout>
      );
    }
  }

  if (account) {
    return (
      <div
        onScroll={onScroll}
        ref={myRef}
        className={
          hasScrollEvent ? classes.withScrollEvent : classes.withNoScrollEvent
        }
      >
        <AccountContext.Provider
          value={{ account, updateAccount: updateAccountProfile }}
        >
          {content}

          {modalContent && (
            <ACGModal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={open}
              onClose={onClose}
              disableAutoFocus={true}
              BackdropProps={{
                className: classes.backdrop,
              }}
            >
              {modalContent}
            </ACGModal>
          )}
        </AccountContext.Provider>
      </div>
    );
  }

  if (!user) {
    return <Redirect to={'/login'} />;
  }
  return <Loading />;
};

export default compose<Props, IAccountWrapperProps>(withStyles(styles))(
  AccountWrapper
);
