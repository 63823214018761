export class UserFacingError extends Error {}

export const genericErrMsg = 'Something went wrong, please contact support';

export const getErrMsg = (err: unknown, customMsg = genericErrMsg) => {
  if (err instanceof UserFacingError) {
    return err.message;
  }
  if (err instanceof Error) {
    console.error(err.message);
  } else {
    console.error(err);
  }
  return customMsg;
};
