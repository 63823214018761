import { PendingCuratorsAction, IPendingCuratorsState } from './types';

const initialState: IPendingCuratorsState = {
  pendingCurators: [],
  pendingArtists: [],
};

export default function pendingCurators(
  state = initialState,
  action: PendingCuratorsAction
): IPendingCuratorsState {
  switch (action.type) {
    case '@@pendingCurators/FETCH_REQUEST':
      return {
        ...state,
      };
    case '@@pendingCurators/FETCH_SUCCESS':
      return {
        ...state,
        pendingCurators: action.payload,
      };
    case '@@pendingCurators/FETCH_ARTIST_REQUEST':
      return {
        ...state,
      };
    case '@@pendingCurators/FETCH_ARTIST_SUCCESS':
      return {
        ...state,
        pendingArtists: action.payload,
      };
    case '@@pendingCurators/APPROVE_CURATOR':
      return {
        ...state,
      };
    case '@@pendingCurators/CHANGE_CURATOR_PROFILE':
      return {
        ...state,
      };

    default:
      return state;
  }
}
