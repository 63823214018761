import styles from './artpool-tabs.module.scss';
import { styled } from '@mui/material/styles';
import React, { useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { NEUTRAL } from '@acg/assets';

const PREFIX = 'artpool-tabs';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledPaper = styled(Paper)({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    boxShadow: 'none',
  },
});

export interface ArtpoolTabDef {
  label: string;
  count?: number;
}

export interface ArtpoolTabsProps {
  tabs: ArtpoolTabDef[];
  selectedTab: number;
  handleChange: any;
  align?: 'left';
}

export const parseSelectedTab = (
  tabs: ArtpoolTabDef[],
  selectedTabLabel: string | undefined
) => {
  const isValidTab = (tab: string | undefined): boolean =>
    tabs.some((innerTab) => innerTab.label === tab);

  return isValidTab(selectedTabLabel) ? selectedTabLabel : tabs[0].label;
};

export function ArtpoolTabs(props: ArtpoolTabsProps) {
  const { tabs, selectedTab, handleChange, align } = props;

  const projectTabs = useRef<any | undefined>();

  const [hideOnScroll, setHideOnScroll] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const tabsTopPosition = projectTabs.current.getBoundingClientRect().top;
      const isFixed = tabsTopPosition <= 0;
      if (isFixed !== hideOnScroll) setHideOnScroll(isFixed);
    },
    [hideOnScroll],
    undefined,
    false,
    150
  );

  const onChange = (event: any, newValue: any) => {
    handleChange(newValue);
  };

  const flexContainerClasses = `${styles.container} ${
    hideOnScroll && styles.fixed
  } ${align !== 'left' && styles.centered}`;

  return (
    <StyledPaper className={classes.root}>
      <Tabs
        ref={projectTabs}
        value={selectedTab}
        onChange={onChange}
        textColor={'inherit'}
        variant="scrollable"
        classes={{
          indicator: styles.indicator,
          flexContainer: flexContainerClasses,
        }}
      >
        {tabs.map((tab: any, i: number) => {
          return (
            <Tab
              key={i}
              label={
                <div style={{ textTransform: 'capitalize' }}>
                  {tab.label}{' '}
                  {tab.count && tab.count > 0 && (
                    <span style={{ color: NEUTRAL[600] }}>{tab.count}</span>
                  )}
                </div>
              }
              classes={{
                root: styles.root,
                selected: styles.selected,
                textColorInherit: styles.colors,
              }}
            />
          );
        })}
      </Tabs>
    </StyledPaper>
  );
}
