import * as React from 'react';
import { ButtonProps } from '@mui/material/Button';
import { StyledButton } from '@acg/frontend-ui-artpool';

export interface IFormCancelProps extends ButtonProps {
  small?: boolean;
  styleOveride?: any;
  newButton?: boolean;
  children: React.ReactNode;
}

const FormCancel = (props: IFormCancelProps) => {
  const { small, className, styleOveride, children, newButton, ...rest } =
    props;

  const onClick = (e: any) => {
    e.preventDefault();
    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <StyledButton
      {...rest}
      variant="outlined"
      size="large"
      color="secondary"
      aria-label="Add"
      fullWidth
      style={styleOveride}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};

export default FormCancel;
