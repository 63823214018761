import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { generateValidator, IValidationConfig } from '../../utils/formatters';
import { Formik, FormikProps, Field } from 'formik';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import { compose } from 'recompose';
import { Grid } from '@mui/material';
import TextInput from '../../components/form/TextInput';
import FormSubmit from '../../components/form/FormSubmit';
import FormMessage from '../../components/form/FormMessage';
import UserService from '../../services/UserService';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import AuthenticationService from '../../services/AuthenticationService';
import FormLink from '../../library/dataDisplay/typography/FormLink';
import Arrow from '../../components/icons/Arrow';
import {} from '@mui/material/';
import PublicLayout from '../../library/layout/container/PublicLayout';
import { ACGAppPaths } from '@acg/shared/const';

export interface IModifyPasswordProps {}

interface IFormValue {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

interface IState {
  error: boolean;
  success: boolean;
}

type Props = RouteComponentProps<{}> &
  IWithAccountProps &
  IModifyPasswordProps &
  WithStyles<string> &
  WithWidth;

const styles = createStyles({});

class ModifyPassword extends React.Component<Props, IState> {
  private userService = new UserService();
  private authenticationService = new AuthenticationService();

  constructor(props: Props) {
    super(props);
    this.state = {
      error: false,
      success: false,
    };
  }

  public onSubmit = async (values: IFormValue, { setSubmitting }: any) => {
    this.setState({ error: false, success: false });
    try {
      await this.userService.updatePassword(values);
      this.setState({ success: true });
    } catch (err) {
      this.setState({ error: true });
    }
    setSubmitting(false);
  };

  public renderForm = (props: FormikProps<IFormValue>) => {
    return (
      <form onSubmit={props.handleSubmit}>
        <Grid
          container={true}
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item={true} xs={12} style={{ textAlign: 'left' }}>
            <FormLink to={ACGAppPaths.ROOT}>
              <Arrow />
            </FormLink>
          </Grid>

          {!!this.state.error && (
            <FormMessage
              type={'ERROR'}
              message={
                'An unexpected error has happened, please check with the support'
              }
              visible={!!this.state.error}
            />
          )}
          {!!this.state.success && (
            <FormMessage
              type={'SUCCESS'}
              message={
                'Your password has been modified, you can close this window to continue'
              }
              visible={!!this.state.success}
            />
          )}

          <Grid item={true} xs={12}>
            <Field
              name="oldPassword"
              component={TextInput}
              naked={true}
              newTextInput={true}
              inputProps={{
                id: 'oldPassword',
                label: 'Type your old password',
                type: 'password',
              }}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              name="password"
              component={TextInput}
              naked={true}
              newTextInput={true}
              inputProps={{
                id: 'password',
                label: 'Type your new password',
                type: 'password',
              }}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              name="confirmPassword"
              component={TextInput}
              naked={true}
              newTextInput={true}
              inputProps={{
                id: 'confirmPassword',
                label: 'Confirm your new password',
                type: 'password',
              }}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <FormSubmit
              disabled={props.isSubmitting}
              newButton={true}
              variant="contained"
            >
              Submit
            </FormSubmit>
          </Grid>
        </Grid>
      </form>
    );
  };

  public render() {
    const {
      // history,
      // width
    } = this.props;

    // const onHomeClick = () => history.push('/')
    //
    // const hidden = isWidthDown(BREAKPOINT, width);
    // const logoHidden = isWidthDown("xs", width)

    if (!this.authenticationService.isAuthenticated()) {
      return <Redirect to="/login" />;
    }

    const config: IValidationConfig = {
      oldPassword: ['REQUIRED'],
      password: ['REQUIRED'],
      confirmPassword: ['REQUIRED', { eq: 'password' }],
    };

    const validate = generateValidator(config);

    const initialValues: IFormValue = {
      oldPassword: '',
      password: '',
      confirmPassword: '',
    };

    return (
      <PublicLayout>
        <Formik
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          render={this.renderForm}
          validate={validate}
        />
      </PublicLayout>
    );
  }
}

export default compose<Props, IModifyPasswordProps>(
  withAccount,
  withRouter,
  withStyles(styles),
  withWidth()
)(ModifyPassword);
