import * as React from 'react';
import UserService from '../../services/UserService';
import { IFollower } from '../../types/user';
import { withRouter, RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import ProfileList from '../../components/profile/ProfileList';
import ProfileLarge from '../../library/dataDisplay/profile/ProfileLarge';
import Follow from './Follow';
import { withAccount, IWithAccountProps } from '../account/InjectAccount';
import ProfileInstitutionLarge from '../../components/institution/ProfileInstitutionLarge';
import FollowInstitution from '../institution/FollowInstitution';
import { ACGAppPaths } from '@acg/shared/const';

export interface IFollowingsProps {
  following: string[];
  onAddFollower: (id: string) => void;
  onRemoveFollower: (id: string) => void;
}

interface IState {}

type Props = IFollowingsProps &
  RouteComponentProps<{ userId: string }> &
  IWithAccountProps;

const PAGE_SIZE = 10;

class Followings extends React.Component<Props, IState> {
  private userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public fetchData = (pageNumber: number) => {
    const {
      match: {
        params: { userId },
      },
    } = this.props;
    // return this.userService.getUserFollowings(userId, { first_result: pageNumber * PAGE_SIZE, max_results: PAGE_SIZE, profile: "CURATOR" });
    return this.userService.getUserFollowingsList(userId, {
      first_result: pageNumber * PAGE_SIZE,
      max_results: PAGE_SIZE,
      profile: 'CURATOR',
    });
  };

  public render() {
    const {
      history,
      location: { pathname },
      account,
      onAddFollower,
      following,
      onRemoveFollower,
    } = this.props;

    const renderSingle = (user: IFollower) => {
      if (!user.following) {
        return undefined;
      }

      let followUrl = `/users/${user.following.url}/posts`;
      if (user.following.institution_profile_type) {
        followUrl = `/venues/${user.following.url}`;
      }

      const onSelect = () => history.push(followUrl, { from: pathname });
      const callback = () => {
        if (following.indexOf(user.following.url) > -1) {
          onRemoveFollower(user.following.url);
        } else {
          onAddFollower(user.following.url);
        }
      };
      const onTagClick = (id: string) => history.push(ACGAppPaths.ROOT, { tags: [id] });

      if (user.following.institution_profile_type) {
        return (
          <ProfileInstitutionLarge
            onTagClick={onTagClick}
            onSelect={onSelect}
            institution={user.following}
            additionalButton={
              account.url !== user.following.url ? (
                <FollowInstitution
                  institution={user.following}
                  callback={callback}
                  isFollowing={following.indexOf(user.following.url) > -1}
                />
              ) : undefined
            }
          />
        );
      }

      return (
        <React.Fragment>
          <ProfileLarge
            key={`_following_${user.id}_`}
            onTagClick={onTagClick}
            onSelect={onSelect}
            user={user.following}
            additionalButton={
              account.url !== user.following.url ? (
                <Follow
                  user={user.following}
                  callback={callback}
                  isFollowing={following.indexOf(user.following.url) > -1}
                />
              ) : undefined
            }
          />
        </React.Fragment>
      );
    };

    return (
      <ProfileList
        loadUsers={this.fetchData}
        pageSize={PAGE_SIZE}
        renderSingle={renderSingle}
        noDataMessage={'This user does not follow any users'}
      />
    );
  }
}

export default compose<Props, IFollowingsProps>(
  withAccount,
  withRouter
)(Followings);
