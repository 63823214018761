import * as React from 'react';

export interface IIconWideDownArrowProps {
  fill?: string;
  height?: number;
  style?: any;
  isSelectInputArrow?: boolean;
}

// isSelectInputArrow is adding the classes that the original arrow has to be able to make it clickable
const IconWideDownArrow = (props: IIconWideDownArrowProps) => {
  const style: React.CSSProperties = {
    height: props.height || 23,
    backgroundColor:
      (props.style && props.style.backgroundColor) || 'transparent',
    borderRadius: (props.style && props.style.borderRadius) || 6,
  };

  return (
    <svg
      className={
        props.isSelectInputArrow
          ? 'MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined'
          : ''
      }
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
      fill={props.fill || 'gray'}
      style={style}
    >
      <path
        transform="rotate(80 303.806213378906,550.9320068359376) "
        id="svg_1"
        d="m497.09619,376.24199l-10.81,8.91l-29.42,24.23l-43.51,35.83l-52.77,43.47l-58.1,47.86l-58.68,48.32l-54.68,45l-46,37.86l-33.24,27.38l-15.73,13l-0.67,0.54c-2.24,1.85 -3.51,5.71 -3.51,8.49a12,12 0 0 0 12,12a13.67,13.67 0 0 0 8.48,-3.51l10.81,-8.91l29.42,-24.23l43.51,-35.83l52.77,-43.47l58.1,-47.86l58.74,-48.32l54.68,-45l46,-37.86l33.24,-27.38l15.73,-13l0.67,-0.54c2.24,-1.85 3.51,-5.71 3.51,-8.49a12,12 0 0 0 -12,-12a13.67,13.67 0 0 0 -8.48,3.51l-0.06,0z"
      />
      <path
        transform="rotate(100 782.7556762695312,548.1967773437499) "
        id="svg_2"
        d="m572.75572,389.76176l10.86,9l29.36,24.32l43.36,35.92l52.87,43.81l57.91,48l58.45,48.43l54.5,45.15l46.29,38.35l33.1,27.38l15.64,13l0.67,0.55c2.51,2.08 5.11,3.51 8.48,3.51a12.2,12.2 0 0 0 8.49,-3.51c4.06,-4.42 5.16,-12.69 0,-17l-10.86,-9l-29.36,-24.32l-43.36,-35.93l-52.87,-43.81l-57.91,-48l-58.45,-48.42l-54.5,-45.16l-46.29,-38.35l-33.08,-27.4l-15.64,-13l-0.67,-0.55c-2.51,-2.09 -5.11,-3.52 -8.48,-3.52a12.21,12.21 0 0 0 -8.49,3.52c-4.06,4.42 -5.16,12.69 0,17l-0.02,0.03z"
      />
    </svg>
  );
};

export default IconWideDownArrow;
