import ApiServices, { IDataResponse } from './ApiServices';

export interface IAwsS3 {
  ETag: string;
  VersionId: string;
  Location: string;
  key: string;
  Key: string;
  Bucket: string;
  size?: [number, number];
}

export type IRotateRespBody = Array<{
  body: {
    updateFileName: string;
  };
}>;

class UploadService {
  public uploadFile = (file: FormData): Promise<IDataResponse<IAwsS3>> => {
    return ApiServices.postRequest('file_upload', file);
  };

  public rotateFile = (
    fileName: string
  ): Promise<IDataResponse<IRotateRespBody>> => {
    return ApiServices.postRequest('file_rotate', { filename: fileName });
  };
}

export default UploadService;
