import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import rotate from '../../img/rotate_icon.svg';
import { compose } from 'recompose';
import {} from '@mui/material';

export interface IRotateProps {
  big?: boolean;
}

type Props = IRotateProps & WithStyles<string>;

const styles = createStyles({
  size: {
    width: 14,
    cursor: 'pointer',
  },
  big: {
    width: 34,
    cursor: 'pointer',
  },
});

class Rotate extends React.Component<Props, any> {
  public render() {
    const { classes, big } = this.props;

    return <img src={rotate} className={big ? classes.big : classes.size} />;
  }
}

export default compose<Props, IRotateProps>(withStyles(styles))(Rotate);
