import { createStyles, withStyles, WithStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { Grid } from '@mui/material';
import FeaturedExhibitionSection from './FeaturedExhibitionSection';

const style = createStyles({
  tabs: {
    minWidth: 350,
  },
});

type Props = WithStyles<string>;

const GeneralSection: React.FC<Props> = (props) => {
  return (
    <Grid container={true} style={{ paddingTop: 10 }}>
      <FeaturedExhibitionSection />
    </Grid>
  );
};

export default compose<Props, any>(withStyles(style))(GeneralSection);
