import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { BREAKPOINT, PRIMARY_COLOR } from '../../styles/constants';
import {} from '@mui/material';

export interface IACGLinkProps {
  onClick?: () => void;
  resize?: number;
  children: React.ReactNode;
}

type Props = IACGLinkProps & WithStyles<string> & WithWidth;

const styles = createStyles({
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: PRIMARY_COLOR,
    },
  },
});

const ACGLink: React.FC<Props> = (props) => {
  const { classes, width, resize, ...rest } = props;

  const isMobile = isWidthDown(BREAKPOINT, width);

  const classname = isMobile ? classes.mobileText : classes.desktopText;

  return (
    <p
      className={`${classes.link} ${classname}`}
      style={{ fontSize: resize ? resize : isMobile ? 12 : 15 }}
      {...rest}
    >
      {props.children}
    </p>
  );
};

export default compose<Props, IACGLinkProps>(
  withStyles(styles),
  withWidth()
)(ACGLink);
