import Grid from '@mui/material/Grid';
import {
  clippedCorners,
  StyledButton,
  Subtitle1,
  Body1,
} from '@acg/frontend-ui-artpool';
import { ARTPOOL_MAIL_URL } from '@acg/shared/const';
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import { themes } from '@acg/assets';

export const BlockchainSection = () => {
  const isSmall = useMediaQuery(themes.artpoolTheme.breakpoints.down('md'));

  return (
    <Grid container>
      <Grid item xs={12} md={6} sx={{ p: 5 }}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Subtitle1>
              <b>
                Use blockchain technology to create new revenue in the digital
                space
              </b>
            </Subtitle1>
          </Grid>
          <Grid item xs={12}>
            <Body1>
            Our team guides professional creatives to not only curate in the digital space but to use blockchain technology to expand possibilities.
            </Body1>
          </Grid>
          <Grid item xs={12}>
            <a href={ARTPOOL_MAIL_URL}>
              <StyledButton variant={'contained'} fullWidth={isSmall}>
                Get Started
              </StyledButton>
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} sx={{ ...clippedCorners }}>
        <img
          src={'/assets/images/artpool/landing-page/celeste-soliman.png'}
          style={{ width: '100%', height: '100%' }}
        />
      </Grid>
    </Grid>
  );
};
