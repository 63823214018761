import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { FieldProps } from 'formik';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import {} from '@mui/material';
import { compose } from 'recompose';

export interface ITextInputProps extends FieldProps {
  inputProps: TextFieldProps;
  naked?: boolean;
  small?: boolean;
  newTextInput?: boolean;
}

// const backgroundGradient = 'linear-gradient(to right, #f5f5f5, #f6f6f6 10%, #f7f7f7 20%, #f8f8f8 30%, #f9f9f9 40%, #fafafa 50%, #fbfbfb 60%, #fcfcfc 70%, #fdfdfd 80%, #fefefe 90%, #ffffff)'

const style = createStyles({
  root: {
    border: 'none',
  },
  backgroundGradient: {
    backgroundImage:
      'linear-gradient(to right, #f5f5f5, #f6f6f6 10%, #f7f7f7 20%, #f8f8f8 30%, #f9f9f9 40%, #fafafa 50%, #fbfbfb 60%, #fcfcfc 70%, #fdfdfd 80%, #fefefe 90%, #ffffff)',
  },
  newBackground: {
    backgroundColor: 'white',
  },
  input: {},
  label: {},
  labelSmall: {
    fontSize: 12,
  },
  inputSmall: {
    fontSize: 12,
  },
});

type Props = ITextInputProps & WithStyles<string>;

class TextInput extends React.Component<Props, any> {
  public render() {
    const { inputProps, field, form, classes, naked, newTextInput } =
      this.props;

    let stl: any = {
      margin: 'normal',
      variant: 'filled',
    };

    if (naked) {
      stl = {
        variant: 'outlined',
        margin: 'none',
      };
    }

    return (
      <TextField
        value={field.value}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        disabled={form.isSubmitting || inputProps.disabled}
        error={!!(form.touched[field.name] && form.errors[field.name])}
        helperText={form.touched[field.name] && form.errors[field.name]}
        {...inputProps}
        fullWidth={true}
        {...stl}
        className={classes.root}
        // classes={classes}
        InputProps={{
          className: `${
            this.props.small ? classes.inputSmall : classes.input
          } ${
            newTextInput ? classes.newBackground : classes.backgroundGradient
          }`,
        }}
        InputLabelProps={{
          className: this.props.small ? classes.labelSmall : classes.label,
        }}
      />
    );
  }
}

export default compose<Props, ITextInputProps>(withStyles(style))(TextInput);
