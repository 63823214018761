// https://jscharting.com/editor/#name=WaffleChart.htm
import styles from './waffle-graph.module.scss';
import React from 'react';
import { ALTERNATIVE, NEUTRAL } from '@acg/assets';
import { Subtitle2 } from './../../typography/subtitle2/subtitle2';

export interface WaffleGraphProps {
  total: number;
  raised: number;
}

export function WaffleGraph(props: WaffleGraphProps) {
  // Waffle chart settings;
  const heatMapEmptyCellsColor = NEUTRAL['50'];
  const heatMapCellsColor = ALTERNATIVE.ARTPOOL_BLUE;
  const raised = props.raised;
  const total = props.total;
  const percent = (raised * 100) / total;

  const generatePoints = (value: any) => {
    let heatMapData = Array(100).fill(-1).fill(1, 0, Math.round(value));
    heatMapData = heatMapData.reduce(
      (rows, key, index) =>
        (index % 10 === 0
          ? rows.push([key])
          : rows[rows.length - 1].push(key)) && rows,
      []
    );
    const points: any = [];
    heatMapData.forEach(function (item1, i) {
      item1.forEach(function (item2: any, j: any) {
        points.push({
          x: j,
          y: i,
          z: item2,
          color: item2 === 1 ? heatMapCellsColor : heatMapEmptyCellsColor,
        });
      });
    });
    return points.reverse();
  };

  return (
    <div className={styles.container}>
      <Subtitle2
        style={{ position: 'absolute', top: 100, left: 83, zIndex: 1 }}
      >
        {percent}%
      </Subtitle2>
      {generatePoints(percent).map((point: any, i: number) => {
        return (
          <div
            className={styles.item}
            key={i}
            style={{ backgroundColor: point.color }}
          />
        );
      })}
    </div>
  );
}

export default WaffleGraph;
