import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import {} from '@mui/material';
import { compose } from 'recompose';
import { PRIMARY_COLOR } from '../../../styles/constants';

export interface ITextProps {
  html?: string;
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
}

type Props = ITextProps & WithStyles<string>;

const styles = createStyles({
  root: {
    fontSize: '12px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: '#afb0b3',
    fontFamily: 'Replica-Regular',
    whiteSpace: 'pre-wrap',
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer',
      color: PRIMARY_COLOR,
    },
  },
});

class Text extends React.Component<Props, any> {
  public render() {
    const { classes, className, onClick, html } = this.props;
    return (
      <span
        className={`${classes.root} ${className} ${
          onClick && classes.clickable
        }`}
        dangerouslySetInnerHTML={html ? { __html: html } : undefined}
        children={this.props.children}
        onClick={onClick}
      />
    );
  }
}

export default compose<Props, ITextProps>(withStyles(styles))(Text);
