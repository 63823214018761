import styles from './body2.module.scss';
import React from 'react';

export interface Body2Props extends React.HTMLAttributes<HTMLParagraphElement> {
  children?: React.ReactNode;
}

export function Body2(props: Body2Props) {
  const { children, ...rest } = props;

  return <p {...rest}>{children}</p>;
}

export default Body2;
