import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import twitter from '../../../../img/circles/twitter.svg';
import twitterGray from '../../../../img/circles/gray/twitter.svg';
import { compose } from 'recompose';
import {} from '@mui/material';

export interface ITwitterProps {
  fontSize?: number;
  type?: 'STANDARD' | 'GRAY';
}

type Props = ITwitterProps & WithStyles<string>;

const styles = createStyles({
  size: {
    width: 12,
    cursor: 'pointer',
  },
});

class Twitter extends React.Component<Props, any> {
  public render() {
    const { classes, fontSize, type } = this.props;

    return (
      <img
        src={type === 'GRAY' ? twitterGray : twitter}
        className={classes.size}
        style={fontSize ? { width: fontSize } : undefined}
      />
    );
  }
}

export default compose<Props, ITwitterProps>(withStyles(styles))(Twitter);
