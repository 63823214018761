import * as React from 'react';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../../styles/constants';

const IconInviteUser = () => {
  const [isHovering, setIsHovering] = React.useState<boolean>(false);

  const style: React.CSSProperties = {
    cursor: 'pointer',
    height: 40,
    backgroundColor: 'transparent',
    borderRadius: 6,
    padding: '0px 2.8px',
  };

  const toggleHovering = () => setIsHovering(!isHovering);

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
      fill={isHovering ? PRIMARY_COLOR : SECONDARY_COLOR}
      style={style}
      onMouseOver={toggleHovering}
      onMouseOut={toggleHovering}
    >
      <path d="M374.59,470.38a191.05,191.05,0,0,0,36.85,43.22,194,194,0,0,0,48.59,31,191.47,191.47,0,0,0,59.69,16.07,199.63,199.63,0,0,0,61.16-2.8,195.39,195.39,0,0,0,100.65-54.42A189.36,189.36,0,0,0,715,457.28a183.83,183.83,0,0,0,18.09-54.73,197.84,197.84,0,0,0,2.55-30.36,185.32,185.32,0,0,0-35.41-109,200.1,200.1,0,0,0-42-42.34,194.35,194.35,0,0,0-51-27,200.13,200.13,0,0,0-119.27-4.1,195.42,195.42,0,0,0-53.1,23.61A191.26,191.26,0,0,0,360.6,301.5a185.37,185.37,0,0,0,14,168.88Zm-2.67-120a174.14,174.14,0,0,1,11.24-41.07,177.82,177.82,0,0,1,21.34-36.49A182.94,182.94,0,0,1,435,242.38,172.38,172.38,0,0,1,517.51,208a190.82,190.82,0,0,1,47.16,0,172.4,172.4,0,0,1,82.53,34.38,182.94,182.94,0,0,1,30.47,30.46A177.82,177.82,0,0,1,699,309.33a174.77,174.77,0,0,1,11.25,41.07,172.72,172.72,0,0,1,0,43.58A174.72,174.72,0,0,1,699,435.06a178.09,178.09,0,0,1-21.34,36.49A182.62,182.62,0,0,1,647.19,502,184.89,184.89,0,0,1,609,524.3a187.22,187.22,0,0,1-44.34,12.09,190.9,190.9,0,0,1-47.17,0,187.22,187.22,0,0,1-44.34-12.09A184.89,184.89,0,0,1,435,502a182.62,182.62,0,0,1-30.48-30.46,178.09,178.09,0,0,1-21.34-36.49A174.27,174.27,0,0,1,371.92,394,176.61,176.61,0,0,1,371.92,350.4Z" />
      <path d="M944.82,893.28H135.31c-7.32,0-14.66-.14-22-.13.31-7.63.9-15.25,1.83-22.83.06-.5.13-1,.19-1.5l0-.16c.19-1.25.37-2.49.56-3.74q.66-4.21,1.46-8.41,1.53-7.95,3.57-15.79a247.41,247.41,0,0,1,9.73-29.2c0-.11.08-.2.11-.29.2-.47.4-.93.61-1.4.46-1.05.92-2.1,1.4-3.15,1.08-2.38,2.21-4.73,3.37-7.07q3.36-6.75,7.18-13.25,3.66-6.24,7.72-12.23,2-3,4.14-5.91l2-2.71,1.16-1.53.07-.1.11-.13a252.2,252.2,0,0,1,19.52-21.93,263.5,263.5,0,0,1,22.61-20l1.22-1c.87-.66,1.74-1.32,2.62-2,2.12-1.59,4.26-3.14,6.42-4.67q6.34-4.47,12.91-8.62c8.78-5.56,17.83-10.69,27.07-15.44q7.18-3.69,14.54-7.08,3.58-1.66,7.21-3.25l3.4-1.47.92-.39,1.61-.67a504.49,504.49,0,0,1,64-21.23,661.4,661.4,0,0,1,68.69-14c2.91-.44,5.81-.86,8.72-1.27l2.34-.32.31,0,4.69-.61q9.12-1.14,18.26-2.09,18.17-1.86,36.38-3c59.24-3.61,119.24-2.21,178.05,5.73,7.91,1.07,15.79,2.3,23.65,3.62,6.39,1.08,12.93-1.68,14.77-8.38,1.58-5.75-2-13.68-8.39-14.76-62.87-10.61-127-13.79-190.63-11.11-49.85,2.11-99.8,8-148.4,19.4-44.46,10.47-88.36,25.87-128,48.81-36.71,21.24-69.48,49.44-93.21,84.79C105,799.75,92.23,843.26,89.58,887.46c-.35,5.94-.51,11.88-.52,17.82a12.16,12.16,0,0,0,12,12H910.57c10.93,0,21.88.17,32.82,0h1.43a12,12,0,0,0,0-24Z" />
      <path d="M990.94,869.63a262.94,262.94,0,0,0-10.52-48.78,240.48,240.48,0,0,0-18.9-43.16,224.57,224.57,0,0,0-13.17-20.64c-1.86-2.6-4-4.64-7.17-5.51a12.25,12.25,0,0,0-9.25,1.21,12.12,12.12,0,0,0-5.51,7.17c-.68,3-.65,6.65,1.21,9.24A224.3,224.3,0,0,1,951.27,811a235.27,235.27,0,0,1,13.49,45.3q1.28,6.65,2.18,13.35a9.06,9.06,0,0,0,1,4.59,8.9,8.9,0,0,0,2.48,3.86,12,12,0,0,0,17,0C989.42,875.94,991.33,872.7,990.94,869.63Z" />
      <path d="M810.67,816.26c6.5-.29,12-5.28,12-12V733.2a12.34,12.34,0,0,0-3.52-8.48,12.16,12.16,0,0,0-8.48-3.52c-6.5.29-12,5.27-12,12v71.06a12.39,12.39,0,0,0,3.51,8.48A12.23,12.23,0,0,0,810.67,816.26Z" />
      <path d="M810.67,674.15c6.5-.29,12-5.28,12-12V591.2a12.36,12.36,0,0,0-3.52-8.48,12.21,12.21,0,0,0-8.48-3.52c-6.5.29-12,5.28-12,12v70.95a12.39,12.39,0,0,0,3.51,8.48A12.23,12.23,0,0,0,810.67,674.15Z" />
      <path d="M847.11,709.73h73a12.38,12.38,0,0,0,8.49-3.52,12.2,12.2,0,0,0,3.51-8.48c-.29-6.5-5.27-12-12-12h-73a12.42,12.42,0,0,0-8.49,3.51,12.24,12.24,0,0,0-3.51,8.49C835.4,704.23,840.38,709.73,847.11,709.73Z" />
      <path d="M701.22,685.73a12.39,12.39,0,0,0-8.48,3.51,12.25,12.25,0,0,0-3.52,8.49c.29,6.5,5.28,12,12,12h72.89a12.38,12.38,0,0,0,8.49-3.52,12.2,12.2,0,0,0,3.51-8.48c-.29-6.5-5.27-12-12-12H701.22Z" />
    </svg>
  );
};

export default IconInviteUser;
