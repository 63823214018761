import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import {} from '@mui/material';
import { compose } from 'recompose';
import { PRIMARY_COLOR } from '../../../styles/constants';

export interface IPrimaryTitleProps {
  children: React.ReactNode;
}

const style = createStyles({
  root: {
    padding: 20,
    textAlign: 'left',
    fontFamily: 'Replica-Regular',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: PRIMARY_COLOR,
    cursor: 'default',
  },
  mobile: {
    fontSize: 20,
  },
  desktop: {
    fontSize: 30,
  },
});
type Props = WithStyles<string> & IPrimaryTitleProps & WithWidth;

class PrimaryTitle extends React.Component<Props, any> {
  public render() {
    const { classes, width } = this.props;
    const isMobile = isWidthDown('xs', width);

    return (
      <div
        className={`${isMobile ? classes.mobile : classes.desktop} ${
          classes.root
        }`}
      >
        {this.props.children}
      </div>
    );
  }
}

export default compose<Props, IPrimaryTitleProps>(
  withStyles(style),
  withWidth()
)(PrimaryTitle);
