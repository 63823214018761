import * as React from 'react';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../../styles/constants';

export interface IIconUploadProps {
  fill?: string;
  height?: number;
  style?: any;
  hoveringFill?: string;
  hoveringColor?: string;
  onClick?: () => void;
}

const IconUpload = (props: IIconUploadProps) => {
  const [isHovering, setIsHovering] = React.useState<boolean>(false);

  const { fill, height, hoveringFill, hoveringColor, ...rest } = props;

  const style: React.CSSProperties = {
    cursor: 'pointer',
    height: props.height || 40,
    backgroundColor: isHovering
      ? (props.style && props.hoveringColor) || 'transparent'
      : (props.style && props.style.backgroundColor) || 'transparent',
    borderRadius: (props.style && props.style.borderRadius) || 6,
    padding: '0px 2.8px',
  };

  const toggleHovering = () => {
    setIsHovering(!isHovering);
  };

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
      fill={
        isHovering
          ? props.hoveringFill || PRIMARY_COLOR
          : props.fill || SECONDARY_COLOR
      }
      style={style}
      onMouseOver={toggleHovering}
      onMouseOut={toggleHovering}
      {...rest}
    >
      <path d="M527.51,174.89V735.28c0,7.57-.1,15.15,0,22.72,0,.33,0,.66,0,1,0,6.28,5.52,12.29,12,12s12-5.27,12-12V198.6c0-7.57.1-15.15,0-22.72v-1c0-6.28-5.52-12.29-12-12s-12,5.27-12,12Z" />
      <path d="M403.13,403.14l3.94-6L417.76,381l15.75-23.85,19.29-29.21,21.13-32,21.24-32.17,19.89-30.11,16.8-25.44,12-18.23c1.91-2.89,3.85-5.76,5.73-8.68l.24-.37H529.15l3.91,5.88,10.6,15.92,15.66,23.53,19.18,28.81,20.93,31.45,21.12,31.74L640.33,348,657,373l12,18c1.9,2.86,3.78,5.72,5.69,8.56.09.12.17.24.25.37a12.71,12.71,0,0,0,7.17,5.51,12.26,12.26,0,0,0,9.24-1.21,12.09,12.09,0,0,0,5.52-7.17c.69-3.05.58-6.56-1.21-9.25l-3.91-5.88L681.1,366l-15.66-23.52-19.18-28.82-20.93-31.44-21.12-31.75-19.78-29.7-16.64-25-12-18c-1.9-2.85-3.78-5.72-5.69-8.56l-.25-.36a12,12,0,0,0-20.72,0l-3.93,5.95L514.52,191l-15.75,23.85L479.49,244l-21.13,32-21.25,32.17L417.23,338.3l-16.8,25.45-12,18.23c-1.91,2.89-3.86,5.75-5.73,8.67-.08.13-.17.25-.25.37a12,12,0,0,0,20.72,12.12Z" />
      <path d="M86.91,759V905.11a12.17,12.17,0,0,0,12,12H944.15c11.42,0,22.85.18,34.27,0h1.5a12.17,12.17,0,0,0,12-12V759c0-15.44-24-15.47-24,0V905.11l12-12H134.67c-11.4,0-22.87-.35-34.27,0-.49,0-1,0-1.49,0l12,12V759c0-15.44-24-15.47-24,0Z" />
    </svg>
  );
};

export default IconUpload;
