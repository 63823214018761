import React from 'react';
import './acg-logo-icon.module.scss';
import { SvgIcon, SvgIconProps } from '@mui/material';

// tslint:disable-next-line:no-empty-interface
export interface AcgLogoIconProps {}

type Props = AcgLogoIconProps & SvgIconProps;

export function AcgLogoIcon(props: Props) {
  return (
    <SvgIcon {...props} viewBox={'0 0 28 27'}>
      <path d="M11.9513 13.8709V13.0776L11.8381 12.9644H0.731392L0.618164 13.0776V13.8709L0.731392 13.9841H11.8381L11.9513 13.8709ZM27.5677 13.8709V13.0776L27.4538 12.9644H16.3478L16.2346 13.0776V13.8709L16.3478 13.9841H27.4538L27.5677 13.8709Z" />
      <path d="M14.4895 15.6164H13.6962L13.583 15.7297V26.8356L13.6962 26.9496H14.4895L14.6027 26.8356V15.7297L14.4895 15.6164ZM14.4895 0H13.6962L13.583 0.113228V11.2199L13.6962 11.3331H14.4895L14.6027 11.2199V0.113228L14.4895 0Z" />
    </SvgIcon>
  );
}

export default AcgLogoIcon;
