import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { Grid } from '@mui/material';
import {} from '@mui/material/';
import { GridProps } from '@mui/material/Grid';
import { BREAKPOINT } from '../../../styles/constants';

export interface IOnlineExhibitionLayoutProps {
  type: 'ONLINEEXHIBITION' | 'ARTPOINT' | 'ARTPOINTMOBILE';
  children: React.ReactNode;
}

const styles = createStyles({
  fullScreen: {
    height: '100vh',
    flexGrow: 1,
  },
  backgroundExhibition: {
    // background: `url(${backgroundColor}) no-repeat center fixed`,
    backgroundColor: '#fff',
    backgroundSize: 'cover',
  },

  backgroundArtPoint: {
    backgroundColor: 'white',
    backgroundSize: 'cover',
    marginTop: 20,
  },

  backgroundArtPointMobile: {
    backgroundColor: 'white',
    backgroundSize: 'cover',
  },
});

type Props = IOnlineExhibitionLayoutProps & WithStyles<string> & WithWidth;

class OnlineExhibitionLayout extends React.Component<Props, any> {
  public render() {
    const { classes, width, type } = this.props;

    const props: Partial<GridProps> = {
      justifyContent: isWidthDown(BREAKPOINT, width) ? 'flex-start' : 'center',
      alignItems: isWidthDown(BREAKPOINT, width) ? 'flex-start' : 'center',
    };

    const lgSize = type === 'ONLINEEXHIBITION' ? 12 : 12;

    return (
      <Grid
        container={true}
        direction="row"
        {...props}
        style={{ height: '100%' }}
        className={`${classes.fullScreen} ${classes.backgroundArtPointMobile}`}
      >
        <Grid xs={12} sm={12} md={lgSize} lg={lgSize} item={true}>
          {this.props.children}
        </Grid>
      </Grid>
    );
  }
}

export default compose<Props, IOnlineExhibitionLayoutProps>(
  withStyles(styles),
  withWidth()
)(OnlineExhibitionLayout);
