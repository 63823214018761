import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { FieldProps } from 'formik';
import { compose } from 'recompose';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import PostCover from '../posts/PostCover';

export interface IImagesCheckboxSelectProps extends FieldProps {
  label: string;
  resize?: boolean;
  inputProps: any;
}

type Props = WithStyles<string> & IImagesCheckboxSelectProps & WithWidth;

const style = createStyles({
  resizeClass: {
    fontSize: 12,
  },
  controlLabelRoot: {
    margin: 0,
  },
  formControl: {
    width: '85vw',
  },
});

const ImagesCheckboxSelect: React.FC<Props> = (props) => {
  const {
    form,
    field,
    inputProps,
    width,
    // label,
    classes,
    // resize
  } = props;

  const [state, setState] = React.useState({});
  // const [selectAll, setSelectAll] = React.useState(false);

  const handleChange = (event: any) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  // TODO - Handle Image Click
  const handleClick = (id: string) => {
    return;
    // const posts = form.values.posts
    // posts[id] = !state[id]
    // setState({...state, [id]: state[id]});
    // form.setFieldValue(field.name, {...posts, [id]: state[id]})
  };

  // TODO - Handle Select All
  // const handleSelectAll = (event: any) => {
  //     window.console.log("Handle select all")
  //     inputProps.posts.map((option: any) => {
  //         setState({...state, [option._id]: !selectAll});
  //     })
  //     setSelectAll(!selectAll)
  // };

  useEffect(() => {
    let initialState = {};
    if (inputProps.posts) {
      inputProps.posts.map((option: any) => {
        initialState = { ...initialState, [option._id]: false };
      });
    }
    setState(initialState);
  }, [inputProps.posts]);

  useEffect(() => {
    form.setFieldValue(field.name, state);
  }, [state]);

  const onSClick = () => {
    return;
  };
  const onCommentClick = () => {
    return;
  };
  const onClickOnAvatar = () => {
    return;
  };
  const onCategoryButtonClick = () => {
    return;
  };
  const onLike = () => {
    return;
  };

  return (
    <React.Fragment>
      {/*<p style={{color: DARK_GREY_COLOR, textDecoration: 'underline', cursor: 'pointer'}}*/}
      {/*   onClick={handleSelectAll}>Select All</p>*/}

      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup>
          <Grid container={true} alignItems={'center'}>
            {inputProps.posts &&
              inputProps.posts.map((option: any, i: number) => {
                const onClick = () => {
                  handleClick(option._id);
                };

                const hasRightBorder = () => {
                  if (isWidthDown('xs', width)) {
                    return false;
                  }
                  if (isWidthDown('sm', width)) {
                    return !((i + 1) % 2 === 0);
                  }
                  if (isWidthDown('md', width)) {
                    return !((i + 1) % 3 === 0);
                  } else {
                    return true;
                  }
                };

                return (
                  <Grid item={true} xs={12} sm={6} md={4} key={option._id}>
                    <Grid container={true} style={{ textAlign: 'center' }}>
                      <Grid item={true} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={form.values.posts[option._id]}
                              onChange={handleChange}
                              name={option._id}
                              classes={{ root: classes.controlLabelRoot }}
                              color="primary"
                            />
                          }
                          label={''}
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <PostCover
                          nonClickable={true}
                          noProfile={true}
                          onShare={onSClick}
                          onCommentClick={onCommentClick}
                          onLike={onLike}
                          onClickOnAvatar={onClickOnAvatar}
                          onCategoryButtonClick={onCategoryButtonClick}
                          isDraft={option.status === 'DRAFT'}
                          post={option}
                          onClick={onClick}
                          hasRightBorder={hasRightBorder()}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </FormGroup>
      </FormControl>
    </React.Fragment>
  );
};

export default compose<Props, IImagesCheckboxSelectProps>(
  withStyles(style),
  withWidth()
)(ImagesCheckboxSelect);
