import { ErrorType, ReqOptions, ResOptions, Route } from 'typesafe-api';
import {
  ArtpoolApiEndpoint,
  DefaultErrorCodes,
  JsonUserOrVenue,
} from '@acg/artpool-api-spec';
import { UserProfileType } from '@acg/api-interfaces';

export interface GetUsersOptions {
  profiles?: UserProfileType[];
  sort?: { [key: string]: 1 | 0 | -1 };
  limit?: number;
  search: string;
}

/**
 * Get Users
 */

export const getUsersRoute: Route = {
  method: 'get',
  path: '/users',
};

export interface GetUsersReq extends ReqOptions {
  query?: GetUsersOptions;
}

export interface GetUsersResp extends ResOptions {
  body: JsonUserOrVenue[];
}

export type GetUsersEndpointDef = ArtpoolApiEndpoint<
  GetUsersReq,
  GetUsersResp,
  ErrorType<DefaultErrorCodes | 404>
>;

/**
 * Get Main Curators
 */
export const getMainCuratorsRoute: Route = {
  method: 'get',
  path: '/main-curators',
};

export type GetMainCuratorsEndpointDef = ArtpoolApiEndpoint<
  ReqOptions,
  GetUsersResp,
  ErrorType<DefaultErrorCodes | 404>
>;
