import './close-icon.module.scss';
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

// tslint:disable-next-line:no-empty-interface
export interface CloseIconProps {}

type Props = CloseIconProps & SvgIconProps;

export function CloseIcon(props: Props) {
  return (
    <SvgIcon {...props} viewBox={'0 0 16 16'}>
      <path d="M9.02588 8.0002L13.1192 3.90019C13.2545 3.76493 13.3305 3.58148 13.3305 3.39019C13.3305 3.19891 13.2545 3.01545 13.1192 2.88019C12.984 2.74493 12.8005 2.66895 12.6092 2.66895C12.4179 2.66895 12.2345 2.74493 12.0992 2.88019L7.99922 6.97353L3.89922 2.88019C3.83224 2.81322 3.75273 2.76009 3.66523 2.72385C3.57772 2.6876 3.48393 2.66895 3.38922 2.66895C3.2945 2.66895 3.20071 2.6876 3.11321 2.72385C3.0257 2.76009 2.94619 2.81322 2.87922 2.88019C2.74396 3.01545 2.66797 3.19891 2.66797 3.39019C2.66797 3.58148 2.74396 3.76493 2.87922 3.90019L6.97255 8.0002L2.87922 12.1002C2.74396 12.2355 2.66797 12.4189 2.66797 12.6102C2.66797 12.8015 2.74396 12.9849 2.87922 13.1202C3.01448 13.2555 3.19793 13.3314 3.38922 13.3314C3.5805 13.3314 3.76396 13.2555 3.89922 13.1202L7.99922 9.02686L12.0992 13.1202C12.1662 13.1872 12.2457 13.2403 12.3332 13.2765C12.4207 13.3128 12.5145 13.3314 12.6092 13.3314C12.7039 13.3314 12.7977 13.3128 12.8852 13.2765C12.9727 13.2403 13.0522 13.1872 13.1192 13.1202C13.1862 13.0532 13.2393 12.9737 13.2756 12.8862C13.3118 12.7987 13.3305 12.7049 13.3305 12.6102C13.3305 12.5155 13.3118 12.4217 13.2756 12.3342C13.2393 12.2467 13.1862 12.1672 13.1192 12.1002L9.02588 8.0002Z" />
    </SvgIcon>
  );
}

export default CloseIcon;
