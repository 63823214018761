import { IFormProps } from '../components/auth-body';
import React from 'react';
import { AuthResetPasswordForm } from './auth-reset-password';

export const AuthMustResetPassword = (props: IFormProps) => (
  <AuthResetPasswordForm
    {...props}
    title="Reset your password to verify your account!"
  />
);
