import * as React from 'react';
import { PRIMARY_COLOR } from '../../styles/constants';
import { useState } from 'react';
import IconWideDownArrow from '../../library/dataDisplay/icons/IconWideDownArrow';

export interface IButtonDownArrowProps {}

const ButtonDownArrow: React.FC<IButtonDownArrowProps> = (
  props: IButtonDownArrowProps
) => {
  const [arrowHovering, setArrowHovering] = useState<boolean>(false);
  const toggleArrowHovering = () => {
    setArrowHovering(!arrowHovering);
  };
  return (
    <div
      onMouseOver={toggleArrowHovering}
      onMouseOut={toggleArrowHovering}
      style={{ display: 'inline-block', cursor: 'pointer' }}
    >
      <IconWideDownArrow
        height={40}
        fill={arrowHovering ? PRIMARY_COLOR : 'black'}
      />
    </div>
  );
};

export default ButtonDownArrow;
