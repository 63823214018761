import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import * as _ from 'lodash';
import { compose } from 'recompose';
import { Grid, Alert, AlertTitle } from '@mui/material';
import { IExhibitionPoint } from '../../types/exhibitions';
import TextInput from './TextInput';
import { Field, FieldArray, FieldProps, getIn } from 'formik';
import FileUpload from './MultipleFilesUpload';
import VideoLinkInput from './VideoLinkInput';
import LinkInput from './LinkInput';
import ColorPickerInput from './ColorPickerInput';
import DesignedButton from '../../library/inputs/DesignedButton';
import { IImage } from '../../types/images';

export interface IInfoPointInputProps extends FieldProps {
  inputProps: IArtWorkFiledProps;
}

export interface IArtWorkFiledProps {
  exhibition_point?: IExhibitionPoint;
  exhibition_point_index: number;
  OnUpdatePoint: (updatedPoint: IExhibitionPoint) => void;
  OnCancel: () => void;
  isCreate: boolean;

  defaultArtWorkColor: string;
  defaultArtWorkBackColor: string;
  defaultArtWorkLabelColor: string;
}

interface IState {
  error?: any;
  artworksColor?: string;
  artworksLabelColor?: string;
  artworksBackColor?: string;
  errors?: any;
}

const style = createStyles({
  artWorkContainer: {
    padding: 20,
  },
  errorMessage: {
    color: '#f44336',
    marginLeft: 14,
    marginRight: 14,
    fontSize: '0.75rem',
    marginTop: 3,
    textAlign: 'left',
    fontFamily: 'Replica-Regular',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  buttonsContainer: {
    paddingTop: 30,
  },
  resetButton: {
    fontSize: 12,
    textDecoration: 'underline',
    padding: '20px 0',
    cursor: 'pointer',
    paddingBottom: '20px',
  },
});

type Props = IInfoPointInputProps & WithStyles<string>;

class InfoPointInput extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    const {
      form: { values },
      inputProps: { exhibition_point_index },
    } = this.props;

    this.state = {
      artworksColor:
        values.exhibition_points[exhibition_point_index].artworks_color,
      artworksLabelColor:
        values.exhibition_points[exhibition_point_index].artworks_label_color,
      artworksBackColor:
        values.exhibition_points[exhibition_point_index].artworks_back_color,
    };
  }

  public updateLinksData() {
    const {
      form: { values },
      inputProps: { exhibition_point_index, exhibition_point },
    } = this.props;

    const artWorksName = `exhibition_points[${exhibition_point_index}]`;

    const regexURLFormula =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

    const linkTitle = _.get(values, artWorksName + '.links_link_title');
    const linkUrl = _.get(values, artWorksName + '.links_link_url');

    if (regexURLFormula.test(linkUrl)) {
      const linksArray = _.get(values, artWorksName + '.links');

      linksArray.push({
        link_title: linkTitle,
        link_url: linkUrl,
      });
      _.set(values, artWorksName + '.links', linksArray);

      _.set(values, artWorksName + '.links_link_title', '');
      _.set(values, artWorksName + '.links_link_url', '');
      if (exhibition_point) {
        exhibition_point.links = linksArray;
      }
    }
  }

  public updateVideoData() {
    const {
      form: { values },
      inputProps: { exhibition_point_index, exhibition_point },
    } = this.props;

    const artWorksName = `exhibition_points[${exhibition_point_index}]`;

    const regexURLFormula =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

    const videoTitle = _.get(values, artWorksName + '.videos_video_title');
    const videoUrl = _.get(values, artWorksName + '.videos_video_link');

    if (regexURLFormula.test(videoUrl)) {
      const videoArray = _.get(values, artWorksName + '.videos');

      videoArray.push({
        video_title: videoTitle,
        video_link: videoUrl,
      });
      _.set(values, artWorksName + '.videos', videoArray);

      _.set(values, artWorksName + '.videos_video_title', '');
      _.set(values, artWorksName + '.videos_video_link', '');
      if (exhibition_point) {
        exhibition_point.videos = videoArray;
      }
    }
  }

  public updatePointColors() {
    const {
      form: { values },
      inputProps: {
        exhibition_point_index,
        exhibition_point,

        defaultArtWorkColor,
        defaultArtWorkBackColor,
        defaultArtWorkLabelColor,
      },
    } = this.props;

    const artWorksPoint = `exhibition_points[${exhibition_point_index}].artworks_color`;
    const artWorksLabel = `exhibition_points[${exhibition_point_index}].artworks_label_color`;
    const artWorkPointBackColor = `exhibition_points[${exhibition_point_index}].artworks_back_color`;

    const artworksColor =
      defaultArtWorkColor === values[artWorksPoint]
        ? ''
        : values[artWorksPoint];
    const artworksLabelColor =
      defaultArtWorkLabelColor === values[artWorksLabel]
        ? ''
        : values[artWorksLabel];
    const artworksBackColor =
      defaultArtWorkBackColor === values[artWorkPointBackColor]
        ? ''
        : values[artWorkPointBackColor];

    _.set(
      values,
      ['exhibition_points', exhibition_point_index, 'artworks_color'],
      artworksColor
    );
    _.set(
      values,
      ['exhibition_points', exhibition_point_index, 'artworks_label_color'],
      artworksLabelColor
    );
    _.set(
      values,
      ['exhibition_points', exhibition_point_index, 'artworks_back_color'],
      artworksBackColor
    );

    if (exhibition_point) {
      exhibition_point.artworks_color = _.get(values, [
        'exhibition_points',
        exhibition_point_index,
        'artworks_color',
      ]);
      exhibition_point.artworks_label_color = _.get(values, [
        'exhibition_points',
        exhibition_point_index,
        'artworks_label_color',
      ]);
      exhibition_point.artworks_back_color = _.get(values, [
        'exhibition_points',
        exhibition_point_index,
        'artworks_back_color',
      ]);
    }

    this.setState({
      artworksColor,
      artworksLabelColor,
      artworksBackColor,
    });
  }

  public resetColorsToDefault() {
    const {
      form: { values },
      inputProps: {
        exhibition_point_index,
        defaultArtWorkColor,
        defaultArtWorkBackColor,
        defaultArtWorkLabelColor,
      },
    } = this.props;

    const artWorksPoint = `exhibition_points[${exhibition_point_index}].artworks_color`;
    const artWorksLabel = `exhibition_points[${exhibition_point_index}].artworks_label_color`;
    const artWorkPointBackColor = `exhibition_points[${exhibition_point_index}].artworks_back_color`;

    const artworksColor = values[artWorksPoint];
    const artworksLabelColor = values[artWorksLabel];
    const artworksBackColor = values[artWorkPointBackColor];

    if (artworksColor) {
      _.set(values, artWorksPoint, '');
      values[artWorksPoint] = defaultArtWorkColor;
    }
    if (artworksLabelColor) {
      _.set(values, artWorksLabel, '');
      values[artWorksLabel] = defaultArtWorkLabelColor;
    }
    if (artworksBackColor) {
      _.set(values, artWorkPointBackColor, '');
      values[artWorkPointBackColor] = defaultArtWorkBackColor;
    }
    _.set(
      values,
      ['exhibition_points', exhibition_point_index, 'artworks_color'],
      ''
    );
    _.set(
      values,
      ['exhibition_points', exhibition_point_index, 'artworks_label_color'],
      ''
    );
    _.set(
      values,
      ['exhibition_points', exhibition_point_index, 'artworks_back_color'],
      ''
    );

    this.setState({
      artworksColor: defaultArtWorkColor,
      artworksLabelColor: defaultArtWorkLabelColor,
      artworksBackColor: defaultArtWorkBackColor,
    });
  }

  public render() {
    const {
      classes,
      form: { errors, values },
      inputProps: {
        exhibition_point,
        exhibition_point_index,
        OnCancel,
        isCreate,
        OnUpdatePoint,

        defaultArtWorkColor,
        defaultArtWorkBackColor,
        defaultArtWorkLabelColor,
      },
    } = this.props;

    const { artworksColor, artworksLabelColor, artworksBackColor } = this.state;

    const artWorksName = `exhibition_points[${exhibition_point_index}]`;
    const artWorksPointColor = `exhibition_points[${exhibition_point_index}].artworks_color`;
    const artWorksPointBackColor = `exhibition_points[${exhibition_point_index}].artworks_back_color`;
    const artWorksPointLabel = `exhibition_points[${exhibition_point_index}].artworks_label_color`;

    if (!exhibition_point) {
      return (
        <React.Fragment>
          <div>Missing data</div>
        </React.Fragment>
      );
    }
    const onUpdateEvent = () => {
      const titleError =
        values.exhibition_points[exhibition_point_index].title &&
        values.exhibition_points[exhibition_point_index].title !== ''
          ? true
          : false;
      const imagesWithCredit =
        values.exhibition_points[exhibition_point_index].imagesWithCredit;
      let imagesWithCreditError = true;

      if (imagesWithCredit) {
        imagesWithCreditError = true;
        imagesWithCredit.forEach((image: IImage) => {
          if (!image.image_credit || image.image_credit === '') {
            imagesWithCreditError = false;
          }
        });
      }
      if (!titleError || !imagesWithCreditError) {
        this.setState({
          errors: {
            title: !titleError,
            imagesWithCredit: !imagesWithCreditError,
          },
        });
      }
      if (!errorArtPoint && titleError && imagesWithCreditError) {
        this.updateLinksData();
        this.updateVideoData();
        this.updatePointColors();
        OnUpdatePoint(exhibition_point);
      }
    };

    const resetToDefaultColors = () => {
      this.setState(
        {
          artworksColor: '',
          artworksLabelColor: '',
          artworksBackColor: '',
        },
        () => {
          this.resetColorsToDefault();
        }
      );
    };

    const getErrors = (data: any) => {
      const valueArray = Object.keys(data).map((key) => data[key]);
      const errorsFound = valueArray.some((e) => e === true);
      return errorsFound;
    };

    const errorArtPoint = getIn(errors, artWorksName);

    return (
      <Grid container={true} className={classes.artWorkContainer} spacing={2}>
        <Grid item={true} xs={12}>
          {isCreate && <h2>Creating new information point</h2>}
          {!isCreate && <h2>Editing information point</h2>}
        </Grid>

        <Grid item={true} xs={12}>
          <Field
            name={artWorksName + '.title'}
            component={TextInput}
            newTextInput={true}
            naked={true}
            inputProps={{
              id: `exhibition_points[${exhibition_point_index}]` + '.title',
              label: 'Information point title*',
              type: 'title',
            }}
          />
          {this.state.errors && this.state.errors.title && (
            <p className={classes.errorMessage}>
              Add the information point title
            </p>
          )}
        </Grid>
        <Grid item={true} xs={12}>
          <Field
            name={artWorksName + '.curatorial_text'}
            component={TextInput}
            newTextInput={true}
            naked={true}
            inputProps={{
              id:
                `exhibition_points[${exhibition_point_index}]` +
                '.curatorial_text',
              label: 'Information point specific curatorial text',
              type: 'curatorial_text',
              multiline: true,
              rows: 5,
              maxRows: 20,
            }}
          />
        </Grid>

        <Grid item={true} xs={12}>
          <Field
            name={artWorksName + '.group'}
            component={TextInput}
            newTextInput={true}
            naked={true}
            inputProps={{
              id: artWorksName + '.group',
              label: 'Information point group / section / thematic ',
              type: 'group',
            }}
          />
        </Grid>

        <Grid item={true} xs={12}>
          <FieldArray
            name={artWorksName + '.imagesWithCredit'}
            component={FileUpload as any}
          />
        </Grid>
        {this.state.errors && this.state.errors.imagesWithCredit && (
          <p className={classes.errorMessage}>The image credit is missing</p>
        )}

        <Grid item={true} xs={12}>
          <FieldArray
            name={artWorksName + '.videos'}
            component={VideoLinkInput as any}
          />
        </Grid>

        <Grid item={true} xs={12}>
          <FieldArray
            name={artWorksName + '.links'}
            component={LinkInput as any}
          />
        </Grid>

        <Grid item={true} xs={12}>
          <p style={{ fontSize: 12, paddingTop: 20 }}>
            Set colors for this information point on the map:
          </p>
          <div style={{ paddingLeft: 10 }}>
            <Field
              name={artWorksPointColor}
              component={ColorPickerInput}
              // classes={classes}
              inputProps={{
                id: artWorksPointColor,
                type: 'artworks_color',
              }}
              label={'Border'}
              initialColor={artworksColor ? artworksColor : defaultArtWorkColor}
            />
            <br />
            <Field
              name={artWorksPointBackColor}
              component={ColorPickerInput}
              // classes={classes}
              inputProps={{
                id: artWorksPointBackColor,
                type: 'artworks_back_color',
              }}
              label={'Fill'}
              initialColor={
                artworksBackColor ? artworksBackColor : defaultArtWorkBackColor
              }
            />
            <br />
            <Field
              name={artWorksPointLabel}
              component={ColorPickerInput}
              // classes={classes}
              inputProps={{
                id: artWorksPointLabel,
                type: 'artworks_label_color',
              }}
              label={'Number'}
              initialColor={
                artworksLabelColor
                  ? artworksLabelColor
                  : defaultArtWorkLabelColor
              }
            />
            <br />
          </div>

          <p onClick={resetToDefaultColors} className={classes.resetButton}>
            Revert to default
          </p>
        </Grid>
        <Grid item={true} xs={12} className={classes.buttonsContainer}>
          <Grid container={true} spacing={2}>
            {this.state.errors && getErrors(this.state.errors) && (
              <Grid item={true} xs={12}>
                <Alert severity="error">
                  <AlertTitle>
                    Error on the information point creation
                  </AlertTitle>
                  {this.state.errors && this.state.errors.title && (
                    <>
                      <b>Title: </b> Required
                      <br />
                    </>
                  )}
                  {this.state.errors && this.state.errors.imagesWithCredit && (
                    <>
                      <b>Images: </b> Credit field is required
                      <br />
                    </>
                  )}
                </Alert>
              </Grid>
            )}
            <Grid item={true} xs={6}>
              <DesignedButton
                ACGType={'neutral'}
                size="large"
                aria-label="Add"
                fullWidth={true}
                onClick={OnCancel}
              >
                Cancel
              </DesignedButton>
            </Grid>
            <Grid item={true} xs={6}>
              <DesignedButton
                ACGType={'main'}
                size="large"
                fullWidth={true}
                onClick={onUpdateEvent}
              >
                Save Information Point
              </DesignedButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default compose<Props, IInfoPointInputProps>(withStyles(style))(
  InfoPointInput
);
