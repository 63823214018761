import styles from '../auth.module.scss';
import { FormControl, TextField } from '@mui/material';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import { resetPassword, sendForgotPasswordCode } from '@acg/auth/frontend';
import { useFormikMui } from '@acg/frontend-utils';
import FormMessage from '../../inputs/form-message/form-message';
import { AUTH_STEPS, IFormProps } from '../components/auth-body';
import { useState } from 'react';
import { passwordValidation } from '@acg/auth/auth';

export interface ResetPasswordParams {
  code: string;
  password: string;
}

interface ErrorValues {
  serverError?: string;
}

interface FormValues extends ResetPasswordParams {
  confirmPassword: string;
}

interface AuthResetPasswordProps extends IFormProps {
  title?: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  code: Yup.string().required('Code is required'),
  password: passwordValidation,
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export const AuthResetPasswordForm = ({
  user,
  setAuthStep,
  title = 'Reset your password',
}: AuthResetPasswordProps) => {
  const [isResendingCode, setIsResendingCode] = useState(false);
  const { formik, formikProps } = useFormikMui<FormValues & ErrorValues>({
    initialValues: {
      code: '',
      password: '',
      confirmPassword: '',
    },
    initialErrors: {
      serverError: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => submitResetPassword(values),
  });

  const submitResetPassword = async (values: ResetPasswordParams) => {
    try {
      const { code, password } = values;
      await resetPassword(user.username, code, password);
      setAuthStep(AUTH_STEPS.SIGN_IN);
    } catch (error: any) {
      formik.setFieldError('serverError', error.message);
    }
  };

  const resendCode = async () => {
    try {
      setIsResendingCode(true);
      await sendForgotPasswordCode(user.username);
    } catch (error: any) {
      formik.setFieldError('serverError', error.message);
    } finally {
      setIsResendingCode(false);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <p className={styles.title}>{title}</p>
        </Grid>
        <Grid item xs={12}>
          <p className={styles.description}>
            Please check your email to find the verification code.
          </p>
        </Grid>
        <Grid item={true} xs={12}>
          <FormControl fullWidth>
            <TextField
              {...formikProps('code')}
              label="Code"
              placeholder="Code"
              color={'secondary'}
            />
          </FormControl>
        </Grid>
        <Grid item={true} xs={12}>
          <FormControl fullWidth>
            <TextField
              {...formikProps('password')}
              label="New Password"
              type={'password'}
              color={'secondary'}
              onChange={(event) => {
                formik.setFieldTouched('password', true);
                formik.handleChange(event);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item={true} xs={12}>
          <FormControl fullWidth>
            <TextField
              {...formikProps('confirmPassword')}
              label="Confirm password"
              type={'password'}
              color={'secondary'}
            />
          </FormControl>
        </Grid>
        <FormMessage
          visible={!!formik.errors.serverError}
          message={formik.errors.serverError}
        />
        <Grid item={true} xs={12}>
          <button
            className={styles.buttonPrimary}
            type="submit"
            disabled={formik.isSubmitting || isResendingCode}
          >
            {formik.isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </Grid>
        <Grid item={true} xs={12}>
          <button
            onClick={resendCode}
            className={styles.buttonSecondary}
            type="button"
            disabled={formik.isSubmitting || isResendingCode}
          >
            {isResendingCode ? 'Resending...' : 'Resend Code'}
          </button>
        </Grid>
      </Grid>
    </form>
  );
};
