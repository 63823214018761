import * as React from 'react';
import { GREY_COLOR, PRIMARY_COLOR } from '../../../styles/constants';

export interface IIconPostProps {
  fill?: string;
  height?: number;
  style?: any;
  hoveringFill?: string;
  hoveringColor?: string;
}

const IconPost = (props: IIconPostProps) => {
  const [isHovering, setIsHovering] = React.useState<boolean>(false);

  const style: React.CSSProperties = {
    cursor: 'pointer',
    height: props.height || 40,
    backgroundColor: isHovering
      ? (props.style && props.hoveringColor) || GREY_COLOR
      : (props.style && props.style.backgroundColor) || PRIMARY_COLOR,
    borderRadius: (props.style && props.style.borderRadius) || 6,
    padding: '0px 2.8px',
  };

  const toggleHovering = () => {
    setIsHovering(!isHovering);
  };

  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
      fill={
        isHovering ? props.hoveringFill || PRIMARY_COLOR : props.fill || 'black'
      }
      style={style}
      onMouseOver={toggleHovering}
      onMouseOut={toggleHovering}
    >
      <path d="M943.74,163H131.62c-9.44,0-20.67-1.33-29.38,2.82-17,8.1-15.11,25.63-15.11,41V886.55c0,9.68,2.69,18.8,10.87,25,5.86,4.43,12.57,5.47,19.59,5.47H963.22C978.3,917,991,906.8,992,891.23c.55-8.05.05-16.29.05-24.35V211.49c0-15.44-24-15.46-24,0v654.7c0,7.93,0,15.87,0,23.8,0,.33-.08,2.43,0,.42,0,.23-.21,2.33,0,.42,0,.23-1.26,2-.06.44a7,7,0,0,1-1.2,1.24c-1.47,1.12,1.35-.59-.36.16s1.49-.61-.36.16c-.84.19-.75.23.25.12l-1.23,0H114.18a11,11,0,0,1-1.28,0c2.08.29-.14-.13-.32-.29.45.4,1.21,1.15-.29-.27.37.36,1.38,1.39-.28-.27l-.78-.85c.59.75.67.77.22.07-.53-1.28-.15,0,0,.21-.55-.63-.48-1.69-.29-.32a5.74,5.74,0,0,1-.05-1.25V888.9c0-1.32,0-2.64,0-4V192.67c0-.83,0-1.66,0-2.49V190a5.74,5.74,0,0,1,.05-1.25c-.19,1.37-.26.31.29-.32-1.26,1.44.82-1,0,0s2.53-2.46.81-.83c1.5-1.42.74-.67.29-.27.63-.55,1.69-.48.32-.29a9.42,9.42,0,0,1,1.3-.05c7.12,0,14.24,0,21.35,0H943.74c15.44,0,15.46-24,0-24Z" />
      <path d="M527.6,576.49V748.43c0,2.33,0,4.65,0,7,0,.1,0,.2,0,.3a12.38,12.38,0,0,0,3.52,8.49,12.17,12.17,0,0,0,8.48,3.51c6.51-.29,12-5.27,12-12V583.76c0-2.32,0-4.65,0-7v-.3a12.33,12.33,0,0,0-3.51-8.48,12.19,12.19,0,0,0-8.49-3.52c-6.5.29-12,5.27-12,12Z" />
      <path d="M527.6,321v175c0,2.37,0,4.75,0,7.12,0,.1,0,.2,0,.3a12.38,12.38,0,0,0,3.52,8.49,12.17,12.17,0,0,0,8.48,3.51c6.51-.29,12-5.27,12-12v-175c0-2.38,0-4.75,0-7.12V321a12.37,12.37,0,0,0-3.51-8.48A12.23,12.23,0,0,0,539.6,309c-6.5.3-12,5.28-12,12Z" />
      <path d="M576.65,552H752.71c2.39,0,4.78,0,7.17,0h.31a12.42,12.42,0,0,0,8.49-3.51,12.18,12.18,0,0,0,3.51-8.49c-.29-6.5-5.27-12-12-12H584.13c-2.39,0-4.78,0-7.16,0h-.32a12.34,12.34,0,0,0-8.48,3.52,12.21,12.21,0,0,0-3.52,8.48c.29,6.5,5.27,12,12,12Z" />
      <path d="M319.69,552H495.75c2.39,0,4.78,0,7.17,0h.32a12.41,12.41,0,0,0,8.48-3.51,12.23,12.23,0,0,0,3.52-8.49c-.3-6.5-5.28-12-12-12H327.17c-2.38,0-4.77,0-7.16,0h-.32a12.36,12.36,0,0,0-8.48,3.52,12.21,12.21,0,0,0-3.52,8.48c.29,6.5,5.28,12,12,12Z" />
    </svg>
  );
};

export default IconPost;
