import { Grid } from '@mui/material';
import useAsyncEffect from 'use-async-effect';
import React, { useState } from 'react';
import { JsonProject } from '@acg/artpool-api-spec';
import { EventsCard } from '@acg/frontend-ui-artpool';
import { NEUTRAL } from '@acg/assets';
import { artpoolApiClient } from 'apps/artcuratorgrid/src/services/ArtpoolApiClient';
import {environment} from "../../../environments/environment";

const { REACT_APP_CLOUDFRONT_URL } = environment


export const EventsAndProjects = () => {
  const artpoolApi = artpoolApiClient();
  const [highlightedProjects, setHighlightedProjects] =
    useState<JsonProject[]>();

  useAsyncEffect(async () => {
    const resp = await artpoolApi.projectApi().getHighlightedProjects();
    setHighlightedProjects(resp.data);
  }, []);

  if (!highlightedProjects) {
    return <></>;
  }

  return (
    <section
      style={{
        padding: '80px 20px',
        textAlign: 'center',
        backgroundColor: NEUTRAL[50],
      }}
    >
      <h1>
        <b>Our Events & Projects</b>
      </h1>
      <Grid container spacing={2} justifyContent={'center'} sx={{ pt: 8 }}>
        {highlightedProjects.map((project) => {
          return (
            <Grid item key={project._id}>
              <EventsCard project={project} cloudFrontUrl={REACT_APP_CLOUDFRONT_URL} />
            </Grid>
          );
        })}
      </Grid>
    </section>
  );
};
