import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import * as _ from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import { Grid, Modal, Tab, Tabs } from '@mui/material';
import { IInstitution } from '../../types/institution';
import InstitutionService from '../../services/InstitutionService';
import PaddedLayout from '../../library/layout/container/PaddedLayout';
import Loading from '../../components/loading/Loading';
import ProfileInstitutionHeader from '../../components/institution/ProfileInstitutionHeader';
import SocialModal from '../social/SocialModal';
import CreateOrEditInstitutionModal from '../backoffice/InstitutionsSection/CreateOrEditInstitutionModal';

import { PostInstitutionsTypeOptions } from '../../types/posts';
import Posts from '../users/Posts';
import { PRIMARY_COLOR, SPACING } from '../../styles/constants';
import { checkRight } from '../../utils/userUtils';
import { generateMetaForVenue } from '../../components/meta';
import Followers from '../users/Followers';
import { sumPostTotals } from '../../utils/post-totals-utils';
import { ACGModal } from '../../components/modal/ACGModal';
// import * as queryString from "query-string";

type TabValue = 'POSTS' | 'FOLLOWERS' | 'FOLLOWINGS';

export interface IInstitutionProfileProps {
  tabValue: TabValue;
}

interface IState {
  institution?: IInstitution;
  loading: boolean;
  isManager: boolean;
  error: boolean;
  following: string[];
  shareDialogOpen: boolean;
  editDialogOpen: boolean;
  selectedTab: string;
  postTotals?: {
    exhibition: number;
    art_project: number;
    publication: number;
    studio_visit: number;
    interview: number;
    online_exhibition: number;
    misc: number;
  };
}

type Props = IInstitutionProfileProps &
  WithWidth &
  RouteComponentProps<{ institutionId: string }> &
  WithStyles<string> &
  IWithAccountProps;

const style = createStyles({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  tabRoot: {
    textTransform: 'none',
  },
  backdrop: {
    backgroundColor: 'rgba(242,242,242,0.92)',
  },
  tabs: {
    fontSize: '0.715rem',
    letterSpacing: -0.12,
    textAlign: 'right',
    minWidth: 120,
    textColor: '#000',
    '& button': {
      minWidth: 120,
      // fontSize: '0.715rem'
      fontSize: 16,
    },
  },
  scrollButton: {
    width: 10,
  },
  indicator: {
    backgroundColor: PRIMARY_COLOR,
  },

  tabsContainer: {
    marginTop: `-${SPACING}px`,
    paddingRight: `${SPACING}px`,
    paddingLeft: `${SPACING}px`,
  },

  postsContainer: {
    marginTop: `30px`,
  },
});

class InstitutionProfile extends React.Component<Props, IState> {
  private institutionService = new InstitutionService();

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      following: [],
      isManager: false,
      shareDialogOpen: false,
      editDialogOpen: false,
      selectedTab: 'ALL',
    };
  }

  // public componentDidUpdate(prevProps: Props) {
  //     const {
  //         match: {
  //             params: {
  //                 institutionId
  //             }
  //         }
  //     } = this.props;
  //
  //     const {
  //         match: {
  //             params: {
  //                 institutionId: prevInstitutionId
  //             }
  //         }
  //     } = prevProps;
  //
  //     if (institutionId !== prevInstitutionId) {
  //         this.getInstitution(institutionId);
  //     }
  //
  // }
  //
  public componentDidMount() {
    const {
      match: {
        params: { institutionId },
      },
    } = this.props;

    this.getInstitution(institutionId);
  }

  public componentDidUpdate(prevProps: Props) {
    const {
      match: {
        params: { institutionId },
      },
      location: {
        pathname,
        // search
      },
    } = this.props;

    const {
      match: {
        params: { institutionId: prevInstitutionId },
      },
    } = prevProps;

    if (institutionId !== prevInstitutionId) {
      // window.console.log("pathname: ", pathname)
      if (pathname.includes('followers')) {
        this.setState({ selectedTab: 'FOLLOWERS' });
      }
      this.getInstitution(institutionId);
    }
  }

  public getInstitution = async (institutionId: string) => {
    this.setState({ loading: true });

    const { account } = this.props;

    return Promise.all([
      this.institutionService.getInstitution(institutionId),
      // this.institutionService.getInstitutionPosts(institutionId),
      this.institutionService.getUserFollowings(account.id),
      this.institutionService.getInstitutionPostTotals(institutionId),
    ])
      .then(([institutionData, userFollowing, postTotalsData]) => {
        const isManager =
          _.find(institutionData.data.managedBy, (manager) => {
            return manager.id === this.props.account.id;
          }) !== undefined;
        this.setState({
          loading: false,
          error: false,
          institution: institutionData.data,
          following: userFollowing.data.map((d) =>
            d.following ? d.following.url : ''
          ),
          isManager,
          postTotals: postTotalsData.data || null,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          error: true,
        });
      });
  };

  public render() {
    const {
      classes,
      match: {
        params: { institutionId },
      },
      account,
      history,
    } = this.props;

    const {
      loading,
      institution,
      following,
      isManager,
      editDialogOpen,
      selectedTab,
      postTotals,
    } = this.state;

    const onConnectHandler = (institutionConnected?: IInstitution) => {
      // window.console.log('onConnectHandler', institutionConnected)
    };
    const onFollowHandler = (institutionFollowed?: IInstitution) => {
      if (following.indexOf(institutionId) > -1) {
        onRemoveFollower(institutionId);
      } else {
        onAddFollower(institutionId);
      }
    };

    const onRemoveFollower = (id: string) => {
      if (institution) {
        institution.followers_number--;
        this.setState({
          following: this.state.following.filter((d) => d !== id),
          institution: { ...institution },
        });
      }
    };

    const onAddFollower = (id: string) => {
      if (institution) {
        institution.followers_number++;
        this.setState({
          following: [...this.state.following, id],
          institution: { ...institution },
        });
      }
    };

    const onShareClickHandler = () => {
      this.setState({ ...this.state, shareDialogOpen: true });
    };

    const onShareClose = () => {
      this.setState({ ...this.state, shareDialogOpen: false });
    };

    const onEditClickHandler = () => {
      this.setState({ ...this.state, editDialogOpen: true });
    };

    const handleCloseChangeTypeModal = () =>
      this.setState({ editDialogOpen: false });

    const updateInstitution = (updatedInstitution?: IInstitution) => {
      this.setState({ institution: updatedInstitution, editDialogOpen: false });
      if (updatedInstitution) {
        history.push(`/venues/${updatedInstitution.url}`);
      }
    };

    const onTabClick = (e: any, val: string) => {
      this.setState({ selectedTab: val });
      switch (val) {
        case 'FOLLOWERS':
          return history.push(`/venues/${institutionId}/followers`);
        case 'FOLLOWINGS':
          return history.push(`/venues/${institutionId}/followings`);
        default:
          return history.push(`/venues/${institutionId}/posts`);
      }
    };

    const isFollowing = following.indexOf(institutionId) > -1;

    const right = checkRight(account);
    const isViewer = right === 'VIEWER' || right === 'PENDING';
    const isCurrentUserProfile = isManager;
    const shouldDisplayPost = !(isCurrentUserProfile && isViewer);

    return (
      <PaddedLayout>
        <Grid container={true} className={classes.root}>
          {loading && <Loading />}
          {institution && (
            <>
              {generateMetaForVenue(institution)}

              <ProfileInstitutionHeader
                institution={institution}
                isManager={isManager}
                isFollowing={isFollowing}
                onConnectHandler={onConnectHandler}
                onFollowHandler={onFollowHandler}
                onEditClick={onEditClickHandler}
                onShareClick={onShareClickHandler}
                showConnect={true}
              />

              <Grid item={true} xs={12} className={classes.tabsContainer}>
                <Tabs
                  value={selectedTab}
                  onChange={onTabClick}
                  centered={false}
                  classes={{
                    root: classes.tabs,
                    indicator: classes.indicator,
                    scrollButtons: classes.scrollButton,
                  }}
                  scrollButtons={true}
                  variant="scrollable"
                >
                  {shouldDisplayPost && postTotals && (
                    <Tab
                      label={'(' + sumPostTotals(postTotals) + ') All'}
                      value={'ALL'}
                      key={'ALL'}
                      classes={{
                        root: classes.tabRoot,
                      }}
                    />
                  )}
                  {shouldDisplayPost &&
                    PostInstitutionsTypeOptions.map((type) => {
                      let tabLabel = type.tabLabel;

                      if (postTotals && postTotals.exhibition >= 0) {
                        switch (type.id) {
                          case 'EXHIBITION':
                            tabLabel =
                              '(' +
                              postTotals.exhibition +
                              ') ' +
                              type.tabLabel;
                            break;
                          case 'ARTPROJECT':
                            tabLabel =
                              '(' +
                              postTotals.art_project +
                              ') ' +
                              type.tabLabel;
                            break;
                          case 'PUBLICATION':
                            tabLabel =
                              '(' +
                              postTotals.publication +
                              ') ' +
                              type.tabLabel;
                            break;
                          case 'STUDIOVISIT':
                            tabLabel =
                              '(' +
                              postTotals.studio_visit +
                              ') ' +
                              type.tabLabel;
                            break;
                          case 'INTERVIEW':
                            tabLabel =
                              '(' + postTotals.interview + ') ' + type.tabLabel;
                            break;
                          case 'ONLINEEXHIBITION':
                            tabLabel =
                              '(' +
                              postTotals.online_exhibition +
                              ') ' +
                              type.tabLabel;
                            break;
                          default:
                            tabLabel =
                              '(' + postTotals.misc + ') ' + type.tabLabel;
                            break;
                        }
                      }

                      return (
                        <Tab
                          label={tabLabel}
                          value={type.id}
                          key={type.id}
                          classes={{
                            root: classes.tabRoot,
                          }}
                        />
                      );
                    })}

                  <Tab
                    label="Followers"
                    value={'FOLLOWERS'}
                    key={'FOLLOWERS'}
                    classes={{
                      root: classes.tabRoot,
                    }}
                  />
                </Tabs>
                {selectedTab !== 'FOLLOWERS' && selectedTab !== 'FOLLOWINGS' && (
                  <div className={classes.postsContainer}>
                    <Posts
                      noProfile={true}
                      selectedTab={selectedTab}
                      noTopMargin={true}
                      venueId={institution.url}
                    />
                  </div>
                )}
              </Grid>

              {selectedTab === 'FOLLOWERS' && (
                <Followers
                  institution={true}
                  following={following}
                  onRemoveFollower={onRemoveFollower}
                  onAddFollower={onAddFollower}
                />
              )}
            </>
          )}
        </Grid>

        {institution && (
          <>
            <SocialModal
              open={this.state.shareDialogOpen}
              onClose={onShareClose}
              object={{
                type: 'URL',
                object: {
                  url: `venues/${institution.url}`,
                  title: institution.name,
                },
                showIframeOption: false,
              }}
            />
            <ACGModal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={editDialogOpen}
              disableAutoFocus={true}
              onClose={handleCloseChangeTypeModal}
              BackdropProps={{
                className: classes.backdrop,
              }}
            >
              <div>
                <CreateOrEditInstitutionModal
                  isBackOffice={false}
                  onCreateOrUpdate={updateInstitution}
                  institution={institution}
                  onClickAway={handleCloseChangeTypeModal}
                />
              </div>
            </ACGModal>
          </>
        )}
      </PaddedLayout>
    );
  }
}

export default compose<Props, IInstitutionProfileProps>(
  withWidth(),
  withRouter,
  withAccount,
  withStyles(style)
)(InstitutionProfile);
