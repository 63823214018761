import styles from './file-uploader.module.scss';
import { ACLOptions } from '@acg/api-interfaces';
import ReactS3Uploader, { S3Response } from 'react-s3-uploader';
import Grid from '@mui/material/Grid';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Caption, Subtitle2, Subtitle3 } from '@acg/frontend-ui-artpool';
import LinearProgress from '@mui/material/LinearProgress';
import { ALTERNATIVE } from '@acg/assets';
import React, { useEffect, useState } from 'react';
import { OptionalPromise } from '@acg/shared/interfaces';
import { SignedUrlS3Ref } from '@acg/artpool-api-spec';

export enum ImageExtensions {
  GIF = 'gif',
  JPEG = 'jpeg',
  PNG = 'png',
  TIFF = 'tiff',
}

const allImageExtensions = Object.values(ImageExtensions);

export type GetSignedUrlFnc = (file: File) => OptionalPromise<SignedUrlS3Ref>;

export interface FileUploaderProps {
  text: string;
  caption?: string;
  isPublic: boolean;
  getSignedUrl: GetSignedUrlFnc;
  maxSize?: number;
  supportedFiles?: ImageExtensions[];
  handleErrMsg?: (errMsg: string) => OptionalPromise<void>;
  onUploaded?: (params: SignedUrlS3Ref) => OptionalPromise<void>;
  currentUploadedUrl?: string;
}

export const FileUploader = (props: FileUploaderProps) => {
  const {
    text,
    caption,
    supportedFiles = allImageExtensions,
    maxSize,
    isPublic,
    handleErrMsg,
    onUploaded,
    getSignedUrl,
    currentUploadedUrl = '',
  } = props;

  const [errMsg, setErrMsg] = useState('');
  const [progress, setProgress] = useState<number>(0);
  const [fileName, setFileName] = useState<string>();
  const [signedUrlS3Ref, setSignedUrlS3Ref] = useState<SignedUrlS3Ref>();
  const [uploadedFileUrl, setUploadedFileUrl] = useState(currentUploadedUrl);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    if (!signedUrlS3Ref || !finished) {
      return;
    }

    setUploadedFileUrl(signedUrlS3Ref.signedGetUrl);
    if (onUploaded) {
      onUploaded(signedUrlS3Ref);
    }

    setProgress(0);
    setFinished(false);
  }, [finished, signedUrlS3Ref]);

  const onUploadProgress = (percent: number, status: string, file: File) => {
    setProgress(percent);
    if (status === 'Upload completed') {
      setFileName(file.name);
    }
  };

  const validate = (file: File): string => {
    const fileSupported = supportedFiles.some((type) =>
      file.type.includes(type)
    );

    if (!fileSupported) {
      return `File type not supported. Supported types: ${supportedFiles.join(
        ','
      )}`;
    }

    if (maxSize && file.size > maxSize) {
      return `Image max size of ${maxSize / 1000}KB exceeded`;
    }

    return '';
  };

  const validateAndGetSignedUrl = async (
    file: File,
    callback: (params: S3Response) => void
  ) => {
    const newErrMsg = validate(file);
    setErrMsg(newErrMsg);

    if (handleErrMsg) {
      handleErrMsg(newErrMsg);
    }

    if (!newErrMsg) {
      const newSignedUrlS3Ref = await getSignedUrl(file);
      setSignedUrlS3Ref(newSignedUrlS3Ref);
      if (newSignedUrlS3Ref) {
        const callBackResp: Partial<S3Response> = {
          signedUrl: newSignedUrlS3Ref.signedPutUrl,
          publicUrl: newSignedUrlS3Ref.publicUrl,
        };
        callback(callBackResp as S3Response);
      }
    }
  };

  return (
    <div className={styles.input_parent}>
      <div
        className={styles.image_input}
        style={{
          // TODO at some point this will need to handle the case where the uploaded file is not an image
          backgroundImage: `url("${uploadedFileUrl}")`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: 'center',
          backgroundPositionY: 'center',
        }}
      />
      <ReactS3Uploader
        className={styles.btn_input}
        style={{
          width: 300,
          height: 360,
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 100,
        }}
        getSignedUrl={validateAndGetSignedUrl}
        onProgress={onUploadProgress}
        onFinish={() => setFinished(true)}
        uploadRequestHeaders={{
          'x-amz-acl': isPublic ? ACLOptions.PUBLIC_READ : ACLOptions.PRIVATE,
        }}
      />
      <Grid container className={styles.image_input_text}>
        <Grid item xs={12}>
          <span style={{ fontSize: 80 }}>
            <AddPhotoAlternateIcon fontSize={'inherit'} />
          </span>
        </Grid>
        <Grid item xs={12}>
          <Subtitle2>{text}</Subtitle2>
        </Grid>
        <Grid item xs={12}>
          <p>{caption}</p>
        </Grid>
        <Grid item xs={12}>
          {fileName && <Caption>{fileName}</Caption>}
          <Subtitle3>
            Click to {uploadedFileUrl ? 'change the' : 'choose an'} image
          </Subtitle3>
        </Grid>
        <Grid item xs={12}>
          {maxSize && <p>Maximum upload size: {maxSize / 1000}KB</p>}
          <p>Supported files: {supportedFiles.join(', ')} </p>
        </Grid>
        <Grid item xs={12}>
          {!!progress && (
            <LinearProgress variant="determinate" value={progress} />
          )}
        </Grid>
      </Grid>
      {errMsg && (
        <Caption style={{ color: ALTERNATIVE.ARTPOOL_ORANGE }}>
          {errMsg}
        </Caption>
      )}
    </div>
  );
};
