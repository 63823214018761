import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import {
  TextField,
  TextFieldProps,
  FormControl,
  MenuItem,
} from '@mui/material';
import { SelectProps } from '@mui/material/Select';
import { InputLabelProps } from '@mui/material/InputLabel';
import { FieldProps } from 'formik';
import { compose } from 'recompose';

export type SelectOptions = Array<{ label: string; value: string }>;

export interface ISelectInputProps extends FieldProps {
  selectProps: SelectProps;
  inputProps?: any;
  inputLabelProps: InputLabelProps;
  options: SelectOptions;
  defaultValue?: any;
  textFieldProps: TextFieldProps;
  placeholder: string;
  onChange?: any;
}

type Props = WithStyles<string> & ISelectInputProps;

const style = createStyles({
  root: {},
  input: {
    backgroundImage: 'white',
  },
});

class SelectInput extends React.Component<Props, any> {
  public render() {
    const { placeholder, form, field, options, classes, inputProps } =
      this.props;

    return (
      <FormControl style={{ marginTop: 16, marginBottom: 8 }} fullWidth={true}>
        {/* <InputLabel htmlFor="type" {...inputLabelProps} /> */}
        <TextField
          variant="outlined"
          select={true}
          // classes={classes}
          value={form.values[field.name]}
          id={field.name}
          name={field.name}
          type={field.name}
          label={placeholder}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          {...inputProps}
          inputProps={{
            className: classes.input,
          }}
        >
          {options.map((op) => {
            const randKey = `option_key_${Math.random().toString(4)}`;
            return (
              <MenuItem key={randKey} value={op.value}>
                {op.label}
              </MenuItem>
            );
          })}
        </TextField>
      </FormControl>
    );
  }
}

export default compose<Props, ISelectInputProps>(withStyles(style))(
  SelectInput
);
