import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import PaddedLayout from '../../library/layout/container/PaddedLayout';
import { compose } from 'recompose';
import { Grid, Button } from '@mui/material';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import {
  PRIMARY_COLOR,
  DARK_GREY_COLOR,
  GREY_COLOR,
} from '../../styles/constants';
import BlogService from '../../services/BlogService';
import { dateToStringParser } from '../../utils/datetimehelper';
import Loading from '../../components/loading/Loading';

export interface IBlogProps {}

type Props = IBlogProps & WithStyles<string> & IWithAccountProps & WithWidth;

const style = createStyles({
  wrapper: {
    fontFamily: 'Replica-Regular',
    display: 'flex',
    alignContent: 'stretch',
    justifyItems: 'center',
    flexDirection: 'column',
  },
  main: {
    flex: '1 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  letterWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: ' 0 1em',
    '& p': {
      margin: 0,
    },
    '& h2': {
      margin: 0,
    },
  },
  bg: {
    backgroundColor: GREY_COLOR,
    minHeight: 570,
    display: 'flex',
    padding: 20,
  },
  margin: {
    margin: '30px 0',
  },
  title: {
    color: PRIMARY_COLOR,
    fontWeight: 'normal',
  },
  subtitle: {
    color: DARK_GREY_COLOR,
  },
  office: {
    width: '100%',
  },
  bottomContent: {
    paddingTop: 30,
  },
  border: {
    borderBottom: `1px solid ${PRIMARY_COLOR}`,
  },
  arrow: {
    height: '50px',
  },
  flex: {
    display: 'flex',
  },
  textMobile: {
    '& h1': {
      fontSize: 35,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 16,
    },
    padding: '0 15px',
  },
  textDesktop: {
    '& h1': {
      fontSize: 50,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 30,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 16,
    },
    padding: '0 20px',
  },
  letterWrapperMobile: {
    padding: '0',
  },
  link: {
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    cursor: 'pointer',
    color: 'black',
    '&:hover': {
      color: PRIMARY_COLOR,
    },
  },
  post_card_image: {
    width: '100%',
    height: 350,
    objectFit: 'cover',
  },
});

class Blog extends React.Component<Props, any> {
  private blogService = new BlogService();

  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      hasMoreData: true,
    };
  }

  public componentDidMount(): void {
    this.getData(1);
  }

  public getData = (page: number) => {
    this.setState({ loading: true });
    this.blogService
      .getBlogPosts(page)
      .then((result) => {
        this.setState({
          data: [...this.state.data, ...result.posts],
          loading: false,
          nextPage: result.meta.pagination.next,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        return null;
      });
    this.setState({ loading: false });
  };

  public render() {
    const { classes, width } = this.props;

    const { data, loading, nextPage } = this.state;

    const isPad = isWidthDown('sm', width);

    const loadNextPage = () => this.getData(nextPage);

    return (
      <div className={classes.wrapper}>
        <div
          className={`${classes.bg} ${
            isPad ? classes.textMobile : classes.textDesktop
          }`}
        >
          <Grid container={true} alignContent="flex-end">
            <Grid item={true} xs={12} sm={6} style={{ marginBottom: 60 }}>
              <h1>
                Welcome to <br />
                Artpool's blog
              </h1>
              <h3>
                Here we'll share exciting news about our platform updates and
                current information about our activities!
                <br />
                Get to know us: who we are, what we do, who we meet, what's
                happening in our Lisbon-based office.
              </h3>
            </Grid>
          </Grid>
        </div>

        <div className={classes.main}>
          <PaddedLayout>
            <div className={isPad ? classes.textMobile : classes.textDesktop}>
              {data &&
                data.map((post: any) => {
                  return (
                    <div className={classes.border} key={post.id}>
                      <Grid
                        className={classes.margin}
                        container={true}
                        alignContent="center"
                      >
                        {post.feature_image && (
                          <Grid
                            item={true}
                            xs={12}
                            md={6}
                            style={{ paddingRight: isPad ? 0 : 80 }}
                          >
                            <a href={post.url}>
                              <img
                                className={classes.post_card_image}
                                src={post.feature_image}
                                alt={post.title}
                              />
                            </a>
                          </Grid>
                        )}
                        <Grid
                          item={true}
                          xs={12}
                          md={6}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div>
                            <h3 style={{ marginTop: isPad ? 5 : 10 }}>
                              {post.title}
                            </h3>
                            <p style={{ color: DARK_GREY_COLOR, marginTop: 5 }}>
                              {post.primary_tag && (
                                <a
                                  href={post.primary_tag.url}
                                  style={{
                                    color: 'inherit',
                                    textDecoration: 'none',
                                  }}
                                >
                                  {post.primary_tag.name.toUpperCase()} -{' '}
                                </a>
                              )}
                              {dateToStringParser(
                                post.created_at,
                                'D MMM YYYY'
                              ).toUpperCase()}
                            </p>
                          </div>
                          <h3
                            style={{
                              marginTop: isPad ? 5 : 10,
                              fontWeight: 300,
                            }}
                          >
                            {post.excerpt}
                          </h3>
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}
            </div>
            <div>
              {nextPage && !loading ? (
                <Grid
                  key="showMore"
                  item={true}
                  className={classes.bottomContent}
                  xs={12}
                >
                  <Button onClick={loadNextPage}>Load More</Button>
                </Grid>
              ) : null}
              {loading ? (
                <Grid
                  key="loading"
                  item={true}
                  className={classes.bottomContent}
                  xs={12}
                >
                  <Loading />
                </Grid>
              ) : null}
            </div>
          </PaddedLayout>
        </div>
      </div>
    );
  }
}

export default compose<Props, IBlogProps>(
  withStyles(style),
  withAccount,
  withWidth()
)(Blog);
