import { ReqOptions, ResOptions, Route } from 'typesafe-api';
import { ArtpoolApiEndpoint } from '@acg/artpool-api-spec';
import { AuthReqOpts } from '@acg/shared/api-spec-utils';

/**
 * Attempt Migrate
 */

export const postAttemptMigrateRoute: Route = {
  method: 'post',
  path: '/auth-migration/attempt-migrate',
};

export interface PostAttemptMigrateReq extends ReqOptions {
  body: {
    email: string;
    password: string;
  };
}

export interface PostAttemptMigrateResp extends ResOptions {
  body: {
    shouldRetryLogin: boolean;
    mustResetPassword: boolean;
  };
}

export type PostAttemptMigrateEndpointDef = ArtpoolApiEndpoint<
  PostAttemptMigrateReq,
  PostAttemptMigrateResp
>;

/**
 * Forgot password
 */

export const postForgotPasswordRoute: Route = {
  method: 'post',
  path: '/auth-migration/forgot-password',
};

export interface PostForgotPasswordReq extends ReqOptions {
  body: {
    email: string;
  };
}

export type PostForgotPasswordEndpointDef = ArtpoolApiEndpoint<
  PostForgotPasswordReq,
  ResOptions
>;
