import { createStyles, withStyles, WithStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { Avatar, Button, Chip, Grid } from '@mui/material';
import { IInstitution } from '../../types/institution';
import { GREY_COLOR, PRIMARY_COLOR, SPACING } from '../../styles/constants';
import Title from '../../library/dataDisplay/typography/Title';
import SubTitle from '../../library/dataDisplay/typography/SubTitle';
import { generateCityLocation } from '../../utils/locationHelper';
import { getSmallmageUrl } from '../../utils/imageUrl';
import Tags from '../tags/Tags';
import { IUser } from '../../types/user';
import { dateToStringParser } from '../../utils/datetimehelper';
import Biography from '../profile/Biography';

const style = createStyles({
  container: {
    fontSize: 12,
    width: '100%',
    backgroundColor: GREY_COLOR,
    padding: SPACING * 3,
    margin: `${SPACING * 2}px ${SPACING}px ${SPACING}px ${SPACING}px`,
    flexBasis: 'unset',
  },
  button: {
    fontSize: 12,
    borderWidth: '3px',
    backgroundColor: PRIMARY_COLOR,
    padding: '10px 0',
    paddingTop: '10px',
  },
  buttonChange: {
    fontSize: 12,
    borderColor: PRIMARY_COLOR,
    borderWidth: '3px',
    backgroundColor: GREY_COLOR,
    padding: '10px 0',
    paddingTop: '10px',
  },
  header: {
    fontWeight: 'bold',
    // fontSize: 20
  },
  subText: {
    // fontSize: 16
  },
  large: {
    width: 69,
    height: 69,
  },
  chip: {
    marginLeft: 5,
    marginBottom: 5,
  },
  creationDate: {
    fontFamily: 'Replica-Regular',
    fontWeight: 'bold',
    fontSize: 12,
    letterSpacing: -0.12,
  },
});

interface IInstitutionCardProps {
  institution: IInstitution;
  onOpenChangeTypeModal: (institution: IInstitution) => void;
}

type props = IInstitutionCardProps & WithStyles<string>;

const InstitutionCard: React.FC<props> = (Props) => {
  const { institution, classes, onOpenChangeTypeModal } = Props;

  const handleChangeClick = () => onOpenChangeTypeModal(institution);
  const handleViewClick = () => {
    window.open(`/venues/${institution.url}`, '_blank');
  };

  const institutionType = institution.institution_type
    ? institution.institution_type
        .toLowerCase()
        .replace('_', ' ')
        .replace(/\w\S*/g, (w: string) =>
          w.replace(/^\w/, (c: string) => c.toUpperCase())
        )
    : '';
  const locationText = institution.city
    ? institution.city
    : generateCityLocation(institution.location);

  return (
    <Grid container={true} className={classes.container} xs={12} spacing={1}>
      <Grid container={true} xs={1} alignItems="center">
        <Avatar
          alt={`${institution.name}`}
          src={getSmallmageUrl(institution.profilePicture)}
          className={classes.large}
        />
      </Grid>
      <Grid container={true} xs={3} alignItems="center">
        <div>
          <Title className={classes.header}>{institution.name}</Title> <br />
          <SubTitle className={classes.subText}>{institutionType}</SubTitle>
          <SubTitle className={classes.subText}>{locationText}</SubTitle>
        </div>
      </Grid>

      <Grid container={true} xs={3} alignItems="center">
        <div>
          <Title className={classes.creationDate}>
            Creation Date:{' '}
            {dateToStringParser(institution.createdAt.toString(), 'DD/MM/YYYY')}
          </Title>{' '}
          <br />
          <Tags tags={institution.tags} tagName={''} />
          <Title className={classes.header}>
            <Biography
              user={institution}
              height={152}
              socialFontSize={16}
              background={'white'}
            >
              <p
                style={{
                  margin: 0,
                  lineHeight: '19px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {institution.about}
              </p>
            </Biography>
          </Title>{' '}
          <br />
        </div>
      </Grid>
      <Grid container={true} xs={2} alignItems="center">
        <Grid container={true} alignItems="center" spacing={2}>
          <Grid xs={12} alignItems="center">
            <SubTitle className={classes.subText}>Managed by:</SubTitle>
          </Grid>
          <Grid xs={12} alignItems="center" justifyContent="flex-start">
            {!institution.managedBy || institution.managedBy.length < 1 ? (
              <div>NO MANAGERS FOR ORGANIZATION</div>
            ) : (
              institution.managedBy.map((manager: IUser) => {
                const handleManagerClick = () => {
                  window.open(`/users/${manager.url}/posts`, '_blank');
                };
                return (
                  <>
                    <Chip
                      onClick={handleManagerClick}
                      key={manager.id}
                      avatar={
                        <Avatar
                          alt={`${manager.firstName} ${manager.lastName}`}
                          src={getSmallmageUrl(manager.profilePicture)}
                        />
                      }
                      label={
                        <>
                          {manager.firstName} {manager.lastName}
                        </>
                      }
                      className={classes.chip}
                    />
                    <br />
                  </>
                );
              })
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container={true} xs={3} alignItems="center" spacing={2}>
        <Grid xs={6} item={true}>
          <Button
            onClick={handleViewClick}
            variant="outlined"
            component="label"
            fullWidth={true}
            className={classes.button}
          >
            VIEW
          </Button>
        </Grid>
        <Grid xs={6} item={true}>
          <Button
            onClick={handleChangeClick}
            variant="outlined"
            component="label"
            fullWidth={true}
            className={classes.buttonChange}
          >
            UPDATE
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default compose<props, IInstitutionCardProps>(withStyles(style))(
  InstitutionCard
);
