import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import {
  MenuItem,
  Checkbox,
  ListItemText,
  Menu,
  Theme,
  Chip,
} from '@mui/material';
import { ITag } from '../../types/tags';
import { PRIMARY_COLOR } from '../../styles/constants';
import {
  withAccount,
  IWithAccountProps,
} from '../../containers/account/InjectAccount';
import { checkRight } from '../../utils/userUtils';
import { setColorOpacity } from '../../utils/theme';
import Caret from '../icons/Caret';

export interface ITagFilterProps {
  availableTags: ITag[];
  selectedTags: string[];
  handleChange: (tags: string[]) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      display: 'none',
    },
    button: {
      cursor: 'pointer',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.25,
      letterSpacing: 'normal',
      color: '#afb0b3',
      fontFamily: 'Replica',
      whiteSpace: 'pre-wrap',
    },
    selected: {
      fontWeight: 500,
      color: PRIMARY_COLOR,
      cursor: 'pointer',
    },
    menuItem: {
      '&:hover': {
        backgroundColor: setColorOpacity(theme.palette.primary.light, 70),
      },
    },
    menu: {
      // backgroundColor: '#f5f5f5',
      maxHeight: 238,
      width: 200,
      marginTop: 45,
    },
    label: {
      fontSize: 14,
    },
    chip: {
      margin: 2,
    },
    chipColor: {
      color: '#6236ff',
      backgroundColor: 'rgba(98, 54, 255, 0.3)',
    },
    deleteIconColorPrimary: {
      color: 'rgba(98, 54, 255, 0.7)',
      '&:hover': {
        color: 'rgba(98, 54, 255, 0.9)',
      },
    },
  });

type Props = ITagFilterProps & WithStyles<string> & IWithAccountProps;

interface IState {
  selectedTags: string[];
  anchorEl?: HTMLDivElement;
}

class TagFilter extends React.Component<Props, IState> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      selectedTags: props.selectedTags,
    };
  }

  public componentDidMount() {
    const { selectedTags } = this.props;
    this.setState({ selectedTags });
  }

  public componentDidUpdate(prevProps: Props) {
    const { selectedTags } = this.props;
    const { selectedTags: prevSelectedTags } = prevProps;
    if (selectedTags !== prevSelectedTags) {
      this.setState({ selectedTags });
    }
  }

  public generateTags = () => {
    const { selectedTags } = this.state;
    const { availableTags, classes, handleChange } = this.props;

    return availableTags
      .filter((t) => selectedTags.indexOf(t.id) > -1)
      .map((t) => {
        const onClose = (id: string) => () => {
          this.setState(
            {
              selectedTags: this.state.selectedTags.filter((st) => st !== id),
            },
            () => {
              handleChange(this.state.selectedTags);
            }
          );
        };
        return (
          <Chip
            key={t.id}
            className={classes.chip}
            color={'primary'}
            onDelete={onClose(t.id)}
            label={t.label}
            classes={{
              colorPrimary: classes.chipColor,
              deleteIconColorPrimary: classes.deleteIconColorPrimary,
            }}
          />
        );
      });
  };

  public render() {
    const { classes, availableTags, handleChange, account } = this.props;
    const { selectedTags } = this.state;

    const handleClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      this.setState({
        anchorEl: event.currentTarget,
      });
    };

    const handleClose = () => {
      this.setState({
        anchorEl: undefined,
      });
      handleChange(this.state.selectedTags);
    };

    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const isFiltering = this.state.selectedTags.length > 0;

    const rights = checkRight(account);

    const tags =
      this.state.selectedTags.length > 0 ? this.generateTags() : <div />;

    return (
      rights === 'ALL' && (
        <div>
          <div aria-describedby={id}>
            <span
              onClick={handleClick}
              className={isFiltering ? classes.selected : classes.button}
            >
              {this.state.selectedTags.length > 0
                ? 'Filtering by Tags'
                : 'Filter by Tags'}{' '}
              <Caret fontSize={12} />
            </span>{' '}
            {tags}
          </div>
          <Menu
            id="long-menu"
            anchorEl={this.state.anchorEl}
            keepMounted={true}
            open={open}
            onClose={handleClose}
            classes={{
              paper: classes.menu,
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {availableTags.map((tag) => {
              const onTagClick = () =>
                this.setState({
                  selectedTags: this.state.selectedTags.find(
                    (t) => t === tag.id
                  )
                    ? this.state.selectedTags.filter((t) => t !== tag.id)
                    : this.state.selectedTags.concat([tag.id]),
                });
              return (
                <MenuItem
                  className={classes.menuItem}
                  key={tag.id}
                  value={tag.id}
                  onClick={onTagClick}
                >
                  <Checkbox
                    color="primary"
                    checked={selectedTags.indexOf(tag.id) > -1}
                  />
                  <ListItemText
                    primaryTypographyProps={{ className: classes.label }}
                    primary={tag.label}
                  />
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      )
    );
  }
}

export default compose<Props, ITagFilterProps>(
  withAccount,
  withStyles(styles)
)(TagFilter);
