import * as React from 'react';
import {
  default as withWidth,
  WithWidth,
} from '@mui/material/Hidden/withWidth';
import { ButtonProps, Modal } from '@mui/material';
import { compose } from 'recompose';
import {
  IWithAccountProps,
  withAccount,
} from '../../containers/account/InjectAccount';
import InviteForm from '../../containers/institution/InviteForm';
import { ButtonColors, StyledButton } from '@acg/frontend-ui-artpool';
import { ACGModal } from '../../components/modal/ACGModal';

export interface IInviteButtonProps extends ButtonProps {
  buttonSize?: any;
  profileInvited: string;
}

type Props = IInviteButtonProps & IWithAccountProps & WithWidth;

const InviteButton: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return (
    <>
      <StyledButton
        size={props.buttonSize || 'large'}
        fullWidth={props.fullWidth}
        onClick={openModal}
        bgColor={ButtonColors.GREEN}
        variant={'contained'}
      >
        Invite {props.profileInvited}
      </StyledButton>
      <ACGModal open={open} onClose={closeModal} key={'modal_service'}>
        <InviteForm
          onClickAway={closeModal}
          profileInvited={props.profileInvited}
        />
      </ACGModal>
    </>
  );
};

export default compose<Props, IInviteButtonProps>(
  withAccount,
  withWidth()
)(InviteButton);
