import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { Grid } from '@mui/material';
import { IUser } from '../../../types/user';
import {
  GREY_COLOR,
  SPACING,
  DARK_GREY_COLOR,
  PRIMARY_COLOR,
} from '../../../styles/constants';
import UserPicture from './UserPicture';
import {} from '@mui/material/';
import Biography from '../../../components/profile/Biography';
import Tags from '../../../components/tags/Tags';
import ShareProfileButton from '../../../containers/services/ShareProfilleButton';
import { generateLocation } from '../../../utils/locationHelper';
import VisitorService, { IPostTotals } from '../../../services/VisitorServices';
import { useState } from 'react';
import { sumPostTotals } from '../../../utils/post-totals-utils';
import { StyledButton } from '@acg/frontend-ui-artpool';

export interface IProfileLargeProps {
  user: IUser;
  goBack?: () => void;
  onSelect?: () => void;
  showEditButton?: boolean;
  onEditClick?: () => void;
  additionalButton?: React.ReactNode;
  onTagClick: (id: string) => void;
  onShareClick?: (user: IUser) => void;
  showBio?: boolean;
}

type Props = IProfileLargeProps & WithStyles<string> & WithWidth;

const styles = createStyles({
  textMobile: {
    fontFamily: 'Replica-Regular',
    '& h1': {
      fontSize: 35,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 15,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 12,
    },
  },
  textDesktop: {
    fontFamily: 'Replica-Regular',
    '& h1': {
      fontSize: 50,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 30,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 16,
    },
  },
  gray: {
    color: DARK_GREY_COLOR,
  },
  textMargin: {
    margin: 0,
  },
  profile: {
    backgroundColor: GREY_COLOR,
    padding: SPACING,
    margin: 10,
  },
  mobileProfile: {
    backgroundColor: GREY_COLOR,
    padding: SPACING,
    margin: 10,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '120px',
  },
  mobileWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapperReverse: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    minHeight: 90,
  },
  mobileButtonWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-around',
    flexDirection: 'column-reverse',
  },
  picture: {
    width: 90,
    height: 90,
  },
  mobilePicture: {
    width: 60,
    height: 60,
  },
  right: {
    textAlign: 'right',
    width: '100%',
  },
  button: {
    textTransform: 'none',
    borderRadius: 4,
    // padding: '25px 25px'
  },
  marginLeft: {
    marginLeft: 20,
  },
});

function ProfileLarge(props: Props) {
  const {
    classes,
    user,
    onSelect,
    showEditButton,
    onEditClick,
    width,
    additionalButton,
    onTagClick,
    onShareClick,
  } = props;

  const style: React.CSSProperties = {
    position: 'relative',
  };
  if (onSelect) {
    style.cursor = 'pointer';
  }

  let companyName: string = '';
  if (user.profile === 'CURATOR' && user.company_type === 'INSTITUTION') {
    companyName = user.company_name;
  }
  if (user.profile === 'CURATOR' && user.company_type !== 'INSTITUTION') {
    companyName = 'Independent curator';
  }

  const isPad = isWidthDown('md', width);

  const onClickOnShare = () => {
    if (onShareClick) {
      onShareClick(user);
    }
  };

  const visitorService = new VisitorService();

  const [totalPosts, setTotalPosts] = useState(0);
  React.useEffect(() => {
    visitorService.getPublicUserTotalPosts(user.id).then((postTotalsResp) => {
      setTotalPosts(sumPostTotals(postTotalsResp.data));
    });
  }, []);

  return (
    <React.Fragment>
      {/*-------- PROFILE DESKTOP DESIGN -----------*/}
      {!isPad && (
        <Grid
          container={true}
          className={`${classes.profile} ${
            isPad ? classes.textMobile : classes.textDesktop
          }`}
        >
          <Grid item={true} xs={12} sm={4}>
            <div className={classes.wrapper}>
              <UserPicture
                className={classes.picture}
                user={user}
                style={{ float: 'left', marginRight: 12, marginLeft: 16 }}
                onClick={onSelect}
              />
              <div onClick={onSelect} style={style}>
                {user.firstName && user.lastName && (
                  <h3 className={classes.textMargin}>
                    {user.firstName} {user.lastName}
                  </h3>
                )}
                <p className={`${classes.gray} ${classes.textMargin}`}>
                  {companyName}
                </p>
                <p className={`${classes.gray} ${classes.textMargin}`}>
                  {generateLocation(user)}
                </p>
              </div>
            </div>
          </Grid>
          <Grid
            item={true}
            xs={12}
            sm={5}
            style={{
              minHeight: 120,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                minHeight: 60,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {user.profile === 'CURATOR' && (
                  <h3 style={{ margin: '0 35px 0 0' }}>
                    <strong>{totalPosts}</strong> Posts
                  </h3>
                )}
                <h3 style={{ margin: '0 35px 0 0' }}>
                  <strong>{user.followers_number}</strong> Followers
                </h3>
                <h3 style={{ margin: '0 0px 0 0' }}>
                  <strong> {user.followings_number}</strong> Following
                </h3>
              </div>
              <div>
                <Tags
                  onTagClick={onTagClick}
                  tags={user.tags}
                  tagName={
                    props.user.profile === 'CURATOR'
                      ? 'Curatorial Expertise'
                      : props.user.profile === 'ARTIST'
                      ? 'Art movement'
                      : ''
                  }
                  style={{ fontSize: 16, margin: 0 }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3}>
            {showEditButton && (
              <Grid
                container
                spacing={2}
                alignItems={'center'}
                className={`${classes.wrapperReverse}`}
              >
                <Grid item xs={6}>
                  <StyledButton
                    size="large"
                    onClick={onEditClick}
                    className={classes.button}
                    variant="outlined"
                    fullWidth
                  >
                    Edit
                  </StyledButton>
                </Grid>
                {onShareClick && (
                  <Grid item xs={6}>
                    <ShareProfileButton onClick={onClickOnShare} />
                  </Grid>
                )}
              </Grid>
            )}
            {additionalButton && (
              <div className={`${classes.wrapperReverse}`}>
                {additionalButton}
              </div>
            )}
          </Grid>
        </Grid>
      )}
      {/*-------- PROFILE MOBILE DESIGN -----------*/}
      {isPad && (
        <Grid
          container={true}
          className={`${classes.mobileProfile} ${classes.textMobile}`}
        >
          <Grid item={true} xs={9} sm={10} className={classes.mobileWrapper}>
            <UserPicture
              className={classes.mobilePicture}
              user={user}
              style={{ float: 'left', marginRight: 12, marginLeft: 0 }}
              onClick={onSelect}
            />
            <div onClick={onSelect}>
              {user.firstName && user.lastName && (
                <h3 className={classes.textMargin} style={{ fontSize: 16 }}>
                  {user.firstName} {user.lastName}
                </h3>
              )}
              <p className={`${classes.gray} ${classes.textMargin}`}>
                {companyName}
              </p>
              <p className={`${classes.gray} ${classes.textMargin}`}>
                {generateLocation(user)}
              </p>
            </div>
          </Grid>
          <Grid item={true} xs={3} sm={2}>
            {additionalButton && (
              <div className={`${classes.mobileButtonWrapper}`}>
                {additionalButton}
              </div>
            )}
            {showEditButton && (
              <div className={`${classes.mobileButtonWrapper}`}>
                {onShareClick && (
                  <ShareProfileButton
                    buttonSize="small"
                    onClick={onClickOnShare}
                  />
                )}
                <StyledButton
                  size="small"
                  onClick={onEditClick}
                  className={classes.button}
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: 5 }}
                >
                  Edit
                </StyledButton>
              </div>
            )}
          </Grid>
          <Grid item={true} xs={3} sm={2} />
          <Grid
            item={true}
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            {user.profile === 'CURATOR' && (
              <p style={{ margin: '5px 10px 0px 0px', fontSize: 12 }}>
                <strong>{totalPosts}</strong> Posts
              </p>
            )}
            <p style={{ margin: '5px 10px 0px 0px', fontSize: 12 }}>
              <strong>{user.followers_number}</strong> Followers
            </p>
            <p style={{ margin: '5px 0px 0px 0px', fontSize: 12 }}>
              <strong> {user.followings_number}</strong> Following
            </p>
          </Grid>
        </Grid>
      )}
      {props.showBio && user.bio && user.bio !== '' && (
        <Grid
          container={true}
          className={isPad ? classes.textMobile : classes.textDesktop}
          style={{
            margin: '10px 20px',
            borderBottom: `1px solid ${PRIMARY_COLOR}`,
            paddingBottom: 20,
          }}
        >
          <Grid item={true} xs={12} sm={4}>
            <h3 style={{ marginTop: 0 }}>Biography</h3>
          </Grid>
          <Grid item={true} xs={12} sm={8}>
            <Biography
              user={user}
              height={isPad ? 135 : 152}
              socialFontSize={16}
              background={'white'}
            >
              <p
                style={{
                  margin: 0,
                  lineHeight: isPad ? '15px' : '19px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {user.bio}
              </p>
            </Biography>

            {isPad && (
              <div>
                <Tags
                  onTagClick={onTagClick}
                  tags={user.tags}
                  tagName={
                    props.user.profile === 'CURATOR'
                      ? 'Curatorial Expertise'
                      : props.user.profile === 'ARTIST'
                      ? 'Art movement'
                      : ''
                  }
                  style={{ fontSize: 12, margin: 0 }}
                />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default compose<Props, IProfileLargeProps>(
  withStyles(styles),
  withWidth()
)(ProfileLarge);
