import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { compose } from 'recompose';
import { RouteComponentProps, withRouter } from 'react-router';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import VisitorService from '../../services/VisitorServices';
import {
  DARK_GREY_COLOR,
  PRIMARY_COLOR,
  SPACING,
} from '../../styles/constants';
import PostCover from '../../components/posts/PostCover';
import { getImageUrl } from '../../utils/imageUrl';
import { dateToStringParser } from '../../utils/datetimehelper';
import { exhibitionRedirectNoUser } from '../../utils/exhibition';
import SubTitle from '../../library/dataDisplay/typography/SubTitle';
import Title from '../../library/dataDisplay/typography/Title';
import Text from '../../library/dataDisplay/typography/Text';

type Props = RouteComponentProps<{}> &
  WithStyles<string> &
  IWithAccountProps &
  WithWidth;

export const gridConfig: {
  [keys in 'xs' | 'sm' | 'md' | 'lg' | 'xl']:
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12;
} = {
  xs: 12,
  sm: 6,
  md: 6,
  lg: 4,
  xl: 4,
};

const MAP_HEIGHT = '580px';

const style = createStyles({
  root: {
    flexGrow: 1,
  },
  subtitle: {
    textDecoration: 'none',
    '& h1': {
      lineHeight: 0.7,
    },
  },
  paddled: {
    padding: '30px 136px 30px 136px',
  },
  paddledMobile: {
    padding: '30px 15px 30px 15px',
  },
  container: {
    paddingTop: 80,
    paddingRight: 10,
    paddingLeft: 10,
    backgroundColor: '#fff',
    flexGrow: 1,
  },
  textMobile: {
    fontFamily: 'Replica-Regular',
    '& h1': {
      fontSize: 35,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 15,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 12,
    },
  },
  textDesktop: {
    fontFamily: 'Replica-Regular',
    '& h1': {
      fontSize: 50,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 30,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 16,
    },
  },
  curatedBy: {
    fontFamily: 'Replica-Regular',
    fontWeight: 'normal',
    cursor: 'pointer',
    '&:hover': {
      color: PRIMARY_COLOR,
    },
  },
  venueLink: {
    color: '#000',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: PRIMARY_COLOR,
    },
  },
  mapContainer: {
    // padding: 30,
  },
  mapContainerMobile: {},
  pointsContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: `calc(${MAP_HEIGHT} + 20px)`,
    minHeight: `calc(${MAP_HEIGHT} + 20px)`,
  },
  exhibitionTitle: {
    fontSize: 30,
    // paddingLeft:20,
  },
  exhibitionTitleMobile: {
    fontSize: 20,
  },
  exhibitionTextMobile: {
    fontSize: 15,
  },
  exhibitionDate: {
    fontSize: 20,
    color: '#afb0b3',
    // paddingLeft:20,
  },
  artistTitle: {
    fontSize: 20,
    color: '#afb0b3',
  },
  postOuter: {
    padding: `${SPACING}px ${SPACING}px`,
  },
  postInner: {
    display: 'block',
    margin: '10px 0',
    padding: '0 10px',
    borderRight: '1px solid black',
  },
  postInnerMobile: {
    display: 'block',
    margin: '10px 0',
    // padding: '0 10px',
  },
  lineBottomWrapper: {
    padding: `0 ${SPACING}px`,
    display: 'block',
  },
  lineBottom: {
    borderBottom: '1px solid',
  },
  lineRight: {
    paddingTop: 10,
    borderRight: '1px solid black',
  },
  draftSection: {
    paddingTop: 10,
    height: 50,
    borderColor: PRIMARY_COLOR,
    borderStyle: 'dotted',
    fontSize: 20,
    color: PRIMARY_COLOR,
    textAlign: 'center',
  },
  creditText: {
    textAlign: 'center',
    fontSize: 16,
    color: '#afb0b3',
  },
});

interface IExhibitionPageProps {}

const ExhibitionsPage = (props: Props) => {
  const { history, width, classes } = props;

  const isMobile = isWidthDown('md', width);

  const [exhibitions, setExhibitions] = React.useState<any>([]);
  const [selectedExhibition, setSelectedExhibition] =
    React.useState<any>(undefined);

  const getData = async () => {
    const visitorService = new VisitorService();
    const publicExhibitions = await visitorService.getPublicExhibitions({});
    const featuredExhibition = await visitorService.getPublicExhibition();

    setSelectedExhibition(featuredExhibition);
    setExhibitions(publicExhibitions.data);
  };

  const onClickOnAvatar = (url: string, isVenue?: boolean) => {
    if (isVenue) {
      history.push(`/venues/${url}/posts`);
    } else {
      history.push(`/users/${url}/posts`);
    }
  };

  const renderCuratedBy = () => {
    if (selectedExhibition) {
      const createdBy = selectedExhibition.created_by;
      const curatorsList = selectedExhibition.curated_by || [];

      // If the creator is a curator, add him/her to curatorsList
      if (
        createdBy.profile === 'CURATOR' &&
        !curatorsList.some((curator: any) => curator.id === createdBy.id)
      ) {
        curatorsList.unshift(createdBy);
      }

      return curatorsList.length > 0 ? (
        <div style={{ paddingTop: 45 }}>
          <p style={{ display: 'inline' }}>Curated by: </p>
          {curatorsList.map((curator: any, i: number) => {
            const handleCuratorClick = () => {
              props.history.push(`/users/${curator.url}/posts`);
            };

            return (
              <React.Fragment key={'curator' + i + '_' + curator.id}>
                <h3 style={{ display: 'inline' }}>{i ? ', ' : ''}</h3>
                <h3
                  style={{ display: 'inline' }}
                  className={classes.curatedBy}
                  onClick={handleCuratorClick}
                >
                  {curator.firstName} {curator.lastName}
                </h3>
              </React.Fragment>
            );
          })}
        </div>
      ) : (
        <></>
      );
    } else {
      return;
    }
  };

  const getVenueName = () => {
    if (selectedExhibition && selectedExhibition.venue_id) {
      return (
        <a
          href={`/venues/${selectedExhibition.venue_id.url}`}
          className={classes.venueLink}
        >
          {selectedExhibition.venue_id.name}
        </a>
      );
    } else if (selectedExhibition && selectedExhibition.gallery) {
      return selectedExhibition.gallery.gallery_name;
    }
    return '';
  };

  const handleExhibitionRedirect = () => {
    exhibitionRedirectNoUser(props.history, selectedExhibition);
  };

  const onClickOnAction = () => null;

  useEffect(() => {
    getData();
  }, []);

  const modulo = 12 / gridConfig[width];

  let mask = 0;
  mask = selectedExhibition && selectedExhibition.start_date ? mask | 1 : mask;
  mask = selectedExhibition && selectedExhibition.end_date ? mask | 2 : mask;
  mask = selectedExhibition ? mask | 4 : mask;

  return (
    <>
      {selectedExhibition && (
        <Grid
          container={true}
          style={{
            height: isMobile ? undefined : 'calc(100vh - 80px)',
            paddingTop: 100,
          }}
          className={isMobile ? classes.paddledMobile : classes.paddled}
        >
          {selectedExhibition.featured_imageWithCredit && (
            <Grid
              item={true}
              xs={12}
              md={6}
              style={{ height: isMobile ? 260 : '90%', textAlign: 'center' }}
            >
              <img
                src={getImageUrl(
                  selectedExhibition.featured_imageWithCredit.image_url
                )}
                style={{
                  objectFit: 'cover',
                  height: ' 100%',
                  width: '100%',
                  cursor: 'pointer',
                }}
                onClick={handleExhibitionRedirect}
              />
              <p className={classes.creditText}>
                {selectedExhibition.featured_imageWithCredit.image_credit}
              </p>
            </Grid>
          )}
          <Grid
            item={true}
            xs={12}
            md={6}
            style={{
              height: isMobile ? undefined : '90%',
              paddingLeft: isMobile ? 0 : 20,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ paddingBottom: 80, paddingTop: isMobile ? 5 : 0 }}>
              {(selectedExhibition.venue_id || selectedExhibition.gallery) && (
                <h2 style={{ margin: 0 }} className={classes.curatedBy}>
                  {getVenueName()}
                </h2>
              )}
            </div>
            <div>
              <Title
                className={
                  isMobile
                    ? classes.exhibitionTitleMobile
                    : classes.exhibitionTitle
                }
              >
                <span
                  onClick={handleExhibitionRedirect}
                  style={{ cursor: 'pointer' }}
                >
                  {selectedExhibition.title}
                </span>
              </Title>
              {(mask === 1 || mask === 5) && (
                <>
                  <SubTitle
                    className={
                      isMobile
                        ? classes.exhibitionTextMobile
                        : classes.exhibitionDate
                    }
                  >
                    Start Date{' '}
                    {dateToStringParser(
                      selectedExhibition.start_date,
                      'MMM Do YYYY'
                    )}{' '}
                  </SubTitle>
                </>
              )}
              {(mask === 2 || mask === 6) && (
                <>
                  <SubTitle
                    className={
                      isMobile
                        ? classes.exhibitionTextMobile
                        : classes.exhibitionDate
                    }
                  >
                    End Date{' '}
                    {dateToStringParser(
                      selectedExhibition.end_date,
                      'MMM Do YYYY'
                    )}{' '}
                  </SubTitle>
                </>
              )}
              {(mask === 3 || mask === 7) && (
                <>
                  <SubTitle
                    className={
                      isMobile
                        ? classes.exhibitionTextMobile
                        : classes.exhibitionDate
                    }
                  >
                    {dateToStringParser(
                      selectedExhibition.start_date,
                      'MMM Do YYYY'
                    )}{' '}
                    -
                    {dateToStringParser(
                      selectedExhibition.end_date,
                      'MMM Do YYYY'
                    )}
                  </SubTitle>
                </>
              )}
              <div style={{ marginTop: 24 }}>
                {selectedExhibition.artists &&
                  selectedExhibition.artists.map((artist: any, i: any) => {
                    return (
                      <Text
                        className={
                          isMobile
                            ? classes.exhibitionTextMobile
                            : classes.artistTitle
                        }
                        key={'artist_' + artist._id}
                      >
                        {(i ? ', ' : '') + artist.name}
                      </Text>
                    );
                  })}
              </div>
              {renderCuratedBy()}
              {selectedExhibition.co_curator_list &&
                selectedExhibition.co_curator_list.length > 0 && (
                  <div>
                    <p style={{ color: DARK_GREY_COLOR, display: 'inline' }}>
                      Co-curated with:{' '}
                    </p>
                    {selectedExhibition.co_curator_list.map(
                      (coCurator: any, i: number) => {
                        return (
                          <Text
                            className={
                              isMobile
                                ? classes.exhibitionTextMobile
                                : classes.artistTitle
                            }
                            key={coCurator}
                          >
                            {`${coCurator.trim()}${
                              i + 1 < selectedExhibition.co_curator_list!.length
                                ? ', '
                                : ''
                            }`}
                          </Text>
                        );
                      }
                    )}
                  </div>
                )}
            </div>
          </Grid>
        </Grid>
      )}

      <Grid
        container={true}
        className={isMobile ? classes.paddledMobile : classes.paddled}
        alignItems={'center'}
        justifyContent="space-between"
        style={{
          paddingLeft: `${isMobile ? '20px' : '135px'}`,
          paddingRight: `${isMobile ? '20px' : '135px'}`,
        }}
      >
        <Grid item xs={12}>
          <a style={{ textDecoration: 'none' }}>
            <h2
              className={classes.subtitle}
              style={{ fontFamily: 'Replica-Regular', fontWeight: 'normal' }}
            >
              Exhibitions
            </h2>
          </a>
        </Grid>
        {exhibitions.map((data: any, n: number) => {
          const onClick = () => {
            if (data.sub_type === 'ONLINEEXHIBITION') {
              history.push(`/online-exhibition/${data.url}`);
            }
          };
          const onCClick = () => onClickOnAction();
          const onSClick = () => onClickOnAction();
          const onLikeClick = () => onClickOnAction();
          const onCategoryButtonClick = () => onClickOnAction();
          const computedIndex = n + 1;

          return (
            <Grid
              key={data.id}
              item
              xs={gridConfig.xs}
              sm={gridConfig.sm}
              md={gridConfig.md}
              lg={gridConfig.lg}
              xl={gridConfig.xl}
            >
              <PostCover
                onShare={onSClick}
                landingPage={false}
                onCategoryButtonClick={onCategoryButtonClick}
                onCommentClick={onCClick}
                onLike={onLikeClick}
                onClickOnAvatar={onClickOnAvatar}
                isDraft={data.status === 'DRAFT'}
                post={data}
                onClick={onClick}
                hasRightBorder={
                  !(computedIndex % modulo === 0 && computedIndex !== 0)
                }
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default compose<Props, IExhibitionPageProps>(
  withStyles(style),
  withRouter,
  withAccount,
  withWidth()
)(ExhibitionsPage);
