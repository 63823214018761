import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import {} from '@mui/material';
import { compose } from 'recompose';
import { IImagePost } from '../../types/posts';
import Title from '../../library/dataDisplay/typography/Title';
import SubTitle from '../../library/dataDisplay/typography/SubTitle';
import ContentContainer from './ContentContainer2';
import { getImageUrl } from '../../utils/imageUrl';

export interface IImageProps {
  post: IImagePost;
  onClick: () => void;
  onCommentClick: () => void;
  onLike: (post: IImagePost) => void;
  onShare: () => void;
  like: boolean;
  numberOfLike: number;
  onCategoryButtonClick: (selectedCategory: string) => void;
  nonClickable?: boolean;
}

const styles = createStyles({
  img: {
    height: '100%',
  },
  oneLiner: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  secondLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
});

type Props = WithStyles<string> & IImageProps;

class ImageContent extends React.Component<Props, any> {
  public render() {
    const {
      post,
      classes,
      onClick,
      numberOfLike,
      like,
      onCommentClick,
      onShare,
      onCategoryButtonClick,
    } = this.props;

    const footer = (
      <div>
        <Title className={classes.oneLiner}>{post.title}</Title>
        {!post.title && <Title className={classes.oneLiner}>&nbsp;</Title>}
        <SubTitle className={classes.secondLine}>{post.description}</SubTitle>
        {post.description === ' ' && <SubTitle>&nbsp;</SubTitle>}
        <SubTitle>&nbsp;</SubTitle>
      </div>
    );

    const featured = (
      <>
        <div
          style={{
            width: '100%',
            height: '100%',
            minHeight: 379,
            background: `url(${getImageUrl(
              post.featured_image
            )}) no-repeat center`,
            backgroundSize: 'cover',
          }}
        />
      </>
    );

    const onLike = () => this.props.onLike(post);

    return (
      <ContentContainer
        onCommentClick={onCommentClick}
        footer={footer}
        featured={featured}
        onClick={onClick}
        onLike={onLike}
        liked={like}
        numberOfLike={numberOfLike}
        post={post}
        onShare={onShare}
        categorySubType={post.sub_type}
        onCategoryButtonClick={onCategoryButtonClick}
        nonClickable={this.props.nonClickable}
      />
    );
  }
}

export default compose<Props, IImageProps>(withStyles(styles))(ImageContent);
