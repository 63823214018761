import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { FieldProps } from 'formik';
import Button, { ButtonProps } from '@mui/material/Button';
import { Grid, Theme } from '@mui/material';
import UploadService from '../../services/UploadService';
import FormMessage from './FormMessage';
import { compose } from 'recompose';
import { SECONDARY_COLOR } from '../../styles/constants';
import Dropzone, { DropzoneState } from 'react-dropzone';
import { getImageUrl } from '../../utils/imageUrl';

export interface IImageUploadProps extends FieldProps {
  inputProps: ButtonProps;
  label?: string;
}

interface IState {
  error: boolean;
}

const style = (theme: Theme) =>
  createStyles({
    uploadButton: {
      height: 160,
      width: '90%',
      // borderRadius: 200,
      background: 'transparent',
      border: `1px dashed ${SECONDARY_COLOR}`,
      zIndex: 99,
    },
    uploadButtonDrag: {
      height: 160,
      width: '90%',
      // borderRadius: 200,
      background: 'rgba(0, 0, 0, 0.08)',
      border: `1px dashed ${SECONDARY_COLOR}`,
      zIndex: 99,
    },
    fileInput: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      opacity: 0,
      zIndex: 200,
      width: '90%',
      left: '50%',
      transform: 'translate(-50%, 0)',
      cursor: 'pointer',
    },
    label: {
      fontFamily: 'Replica-Regular',
      fontSize: 12,
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.25,
      letterSpacing: 'normal',
      color: '#2b2e34',
    },
    avatar: {
      position: 'absolute',
      top: 0,
      height: 160,
      // width: '90%',
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 1,
    },
    error: {
      color: theme.palette.error.main,
    },
  });

type Props = IImageUploadProps & WithStyles<string>;

class ImageUpload extends React.Component<Props, IState> {
  private uploadService = new UploadService();

  constructor(props: Props) {
    super(props);
    this.state = {
      error: false,
    };
  }

  public submitFile = (event: any) => {
    event.preventDefault();
    this.uploadFile(event.target.files);
  };

  public uploadFile = (files: File[]) => {
    const { field, form } = this.props;
    this.setState({ error: false });
    const formData = new FormData();
    if (files[0]) {
      formData.append('file', files[0]);
      this.uploadService
        .uploadFile(formData)
        .then((response) => {
          form.setValues({
            ...form.values,
            [field.name]: `/${response.data.Key}`,
          });
        })
        .catch((error) => {
          this.setState({ error: true });
        });
    }
  };

  public renderDropArea = ({
    getRootProps,
    getInputProps,
    isDragActive,
  }: DropzoneState) => {
    const { field, form, classes, label } = this.props;

    const value = form.values[field.name];

    return (
      <Grid
        item={true}
        xs={12}
        style={{ textAlign: 'center', position: 'relative' }}
        {...getRootProps()}
      >
        {value && <img className={classes.avatar} src={getImageUrl(value)} />}
        <Button
          className={
            isDragActive ? classes.uploadButtonDrag : classes.uploadButton
          }
        >
          +
        </Button>
        <div className={classes.label}>{label || 'upload your image'}</div>
        {!!(form.touched[field.name] && form.errors[field.name]) ? (
          <div className={classes.error}>
            {form.errors[field.name] as string}
          </div>
        ) : undefined}
        <input
          type="file"
          {...getInputProps()}
          onChange={this.submitFile}
          className={classes.fileInput}
          accept="image/*"
        />
      </Grid>
    );
  };

  public render() {
    return (
      <Grid
        container={true}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <FormMessage
          visible={this.state.error}
          message={'An unexpected error has happened, please try again'}
          type={'ERROR'}
        />
        <Dropzone onDrop={this.uploadFile} children={this.renderDropArea} />
      </Grid>
    );
  }
}

export default compose<Props, IImageUploadProps>(withStyles(style))(
  ImageUpload
);
