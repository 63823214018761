import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Chip, Paper, Popper, TextField, MenuItem } from '@mui/material';
import { compose } from 'recompose';
import { ITag } from '../../types/tags';
import Autosuggest from 'react-autosuggest';
import { FieldProps } from 'formik';
import TagService from '../../services/TagService';
import { PRIMARY_COLOR } from '../../styles/constants';
import InfoTooltip from '../../library/dataDisplay/tooltips/InfoTooltip';

export interface IFormTagWrapperProps extends FieldProps {
  inputProps: any;
  helpText?: string;
}

type Props = IFormTagWrapperProps & WithStyles<string>;

const style = createStyles({
  root: {},
  input: {
    backgroundColor: 'white',
    // backgroundImage: 'linear-gradient(to right, #f5f5f5, #f6f6f6 10%, #f7f7f7 20%, #f8f8f8 30%, #f9f9f9 40%, #fafafa 50%, #fbfbfb 60%, #fcfcfc 70%, #fdfdfd 80%, #fefefe 90%, #ffffff)',
  },
  chip: {
    marginLeft: 5,
    marginBottom: 5,
    borderRadius: 8,
  },
  popper: {
    '& ul': {
      margin: 0,
      padding: 0,
      '& li': {
        listStyleType: 'none',
      },
    },
  },
  icon: {
    backgroundColor: PRIMARY_COLOR,
    borderRadius: 20,
    padding: 2,
    color: 'white',
    cursor: 'pointer',
    width: 20,
    height: 20,
    lineHeight: '20px',
    textAlign: 'center',
    fontWeight: 500,
  },
});

interface IState {
  suggestions: ITag[];
  value: string;
}

// const LightTooltip = withStyles((theme: Theme) => ({
//   tooltip: {
//     backgroundColor: theme.palette.common.white,
//     color: 'rgba(0, 0, 0, 0.87)',
//     boxShadow: theme.shadows[1],
//     fontSize: 11,
//   },
// }))(Tooltip);

class FormTagWrapper extends React.Component<Props, IState> {
  private tagService = new TagService();
  private ref: any;
  private nodeRef: any;

  public constructor(props: Props) {
    super(props);
    this.state = {
      suggestions: [],
      value: '',
    };
  }

  public onChange = (event: any, { newValue }: any) => {
    const { form, field } = this.props;
    this.setState({
      value: newValue,
    });
    form.setFieldTouched(field.name);
  };

  public onSuggestionSelected = (event: any, { suggestion }: any) => {
    if (suggestion.id === 'create') {
      this.createNewTag(this.state.value);
    } else {
      this.addSuggestion(suggestion);
    }
  };

  public addSuggestion = (suggestion: ITag) => {
    const { form, field } = this.props;
    form.setFieldTouched(field.name);
    const foundValue = (field.value || []).find(
      (s: ITag) => s.id === suggestion.id
    );
    if (!foundValue) {
      form.setFieldValue(field.name, (field.value || []).concat([suggestion]));
    }
  };

  public createNewTag = (value: string) => {
    return this.tagService.createTag({ label: value }).then((tag) => {
      return this.addSuggestion(tag.data);
    });
  };

  public onSuggestionsFetchRequested = (event: any) => {
    return this.tagService.getTags({ search: event.value }).then((r) => {
      const newSuggestion = [
        { id: 'create', label: `Create Tag ${this.state.value}` },
      ];
      if (r.data.find((t) => t.label === this.state.value)) {
        this.setState({
          suggestions: r.data,
        });
      } else {
        this.setState({
          suggestions: r.data.concat(newSuggestion),
        });
      }
    });
  };

  public onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
      value: '',
    });
  };

  public renderInputComponent = (inputProps: any) => {
    const { classes, inputRef = () => ({}), ref, ...other } = inputProps;

    return (
      <TextField
        fullWidth={true}
        margin="normal"
        variant="outlined"
        className={classes.root}
        InputProps={{
          inputRef: (node) => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: classes.input,
          },
        }}
        {...other}
      />
    );
  };

  public renderSuggestionsContainer =
    (coordinate: any) =>
    ({ containerProps, children }: any) => {
      const { classes } = this.props;
      return (
        // @ts-ignore
        <Popper
          open={Boolean(children)}
          anchorEl={this.ref}
          style={{ zIndex: 10000 }}
        >
          <div>
            <Paper
              square={true}
              {...containerProps}
              className={classes.popper}
              style={{
                marginTop: 8,
                width: this.ref ? this.ref.clientWidth : null,
                position: 'absolute',
                ...coordinate,
              }}
            >
              {children}
            </Paper>
          </div>
        </Popper>
      );
    };

  public handleDelete = (tag: ITag) => () => {
    const { form, field } = this.props;
    form.setFieldTouched(field.name);
    const newValues = (field.value || []).filter((v: ITag) => v.id !== tag.id);
    form.setFieldValue(field.name, newValues);
  };

  public handleOnKeyDOwn = (e: any) => {
    if (e.key === 'Enter') {
      // window.console.log('do validate');
    }
  };

  public componentDidMount(): void {
    this.nodeRef = ReactDOM.findDOMNode(this.ref);
  }

  public render() {
    const { field, form, classes, inputProps, helpText } = this.props;

    const { suggestions } = this.state;

    const getSuggestionValue = (suggestion: ITag) => {
      return suggestion.id;
    };

    const renderSuggestion = (suggestion: ITag) => {
      return (
        <MenuItem key={suggestion.id}>
          <span>{suggestion.label}</span>
        </MenuItem>
      );
    };

    const assignRef = (node: any) => {
      this.ref = node;
    };

    const b = this.nodeRef;
    const coordinate = {
      top: b
        ? (b as any).getBoundingClientRect().y +
          (b as any).getBoundingClientRect().height +
          window.scrollY
        : 0 + window.scrollY,
      left: b ? (b as any).getBoundingClientRect().x : 0,
      width: b ? (b as any).getBoundingClientRect().width : 0,
    };

    const chips = field.value.map((v: ITag) => {
      return (
        <Chip
          key={v.id}
          label={v.label}
          onDelete={this.handleDelete(v)}
          className={classes.chip}
        />
      );
    });

    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flexGrow: 1, flexBasis: 0 }}>{chips}</div>
          <div style={{ flexGrow: 0, flexBasis: 0, marginTop: 3 }}>
            <InfoTooltip
              helpText={
                helpText
                  ? helpText
                  : 'Please input your curatorial theme, the art movement, or any words describing this exhibition…'
              }
            />
            {/*<LightTooltip placement="top-end" title={helpText ? helpText : 'Please input your curatorial theme, the art movment, or any words describing this exhibition…'}>*/}
            {/*  <div className={classes.icon}>?</div>*/}
            {/*</LightTooltip>*/}
          </div>
        </div>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderSuggestionsContainer={this.renderSuggestionsContainer(
            coordinate
          )}
          renderInputComponent={this.renderInputComponent}
          inputProps={{
            ...inputProps,
            disabled: form.isSubmitting || inputProps.disabled,
            error: !!(form.touched[field.name] && form.errors[field.name]),
            helperText:
              form.touched[field.name] && form.errors[field.name]
                ? form.errors[field.name]
                : inputProps.helperText,
            value: this.state.value,
            onChange: this.onChange,
            classes,
          }}
          ref={assignRef}
        />
      </div>
    );
  }
}

export default compose<Props, IFormTagWrapperProps>(withStyles(style))(
  FormTagWrapper
);
