import { themes } from '@acg/assets';
import {
  Body1,
  Body2,
  StyledButton,
  clippedCorners,
} from '@acg/frontend-ui-artpool';
import { ARTPOOL_MAIL_URL } from '@acg/shared/const';
import { Grid, Stack } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import { ServicesMetaData } from '../../components/meta';
import { BannerLayout } from './sections/BannerLayout';
import { EventsAndProjects } from './sections/EventsAndProjects';
import { InfoSection } from './sections/info-section';

export const ServicesPage = () => {
  const isMedium = useMediaQuery(themes.artpoolTheme.breakpoints.down('lg'));
  const isSmall = useMediaQuery(themes.artpoolTheme.breakpoints.down('md'));
  const IMAGE_PATH = '/assets/images/artpool/services/';
  return (
    <div style={{ paddingTop: 80 }}>
      <ServicesMetaData />
      <BannerLayout
        hideTabs
        imageComponent={
          <Stack
            spacing={1}
            textAlign={isSmall ? 'center' : 'left'}
            alignItems={'center'}
          >
            <img
              src={IMAGE_PATH + 'activate-audience.png'}
              style={{
                maxWidth: 600,
                height: isSmall ? 350 : 530,
                ...clippedCorners,
                objectFit: 'cover',
              }}
            ></img>
            <Body2 style={{ textAlign: 'center' }}>
              <b>Interview</b> - Pauline Foessel, founder of Artpool for Further
              Lisbon, Facing Hypperreality - Video: Eldon van Aswegen
            </Body2>
          </Stack>
        }
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <h1>
              <b>Discover expert curators for your projects</b>
            </h1>
          </Grid>
          <Grid item xs={12}>
            <Body1>
              We have a dedicated team of expert curators who can bring their
              specialized knowledge and experience to your artistic endeavors.
              Our platform provides a curated selection of top-tier curators,
              meticulously vetted and ready to collaborate on your projects.
              <br />
              To access our network of curators and explore their expertise,
              please get in touch, our team will be delighted to discuss your
              specific requirements and connect you with the right curator for
              your project.
            </Body1>
          </Grid>
          <Grid item xs={12}>
            <a href={ARTPOOL_MAIL_URL}>
              <StyledButton fullWidth={isMedium} variant={'contained'}>
                <span style={{ padding: '0px 20px' }}>Book a consultation</span>
              </StyledButton>
            </a>
          </Grid>
        </Grid>
      </BannerLayout>
      <InfoSection />
      <EventsAndProjects />
    </div>
  );
};
