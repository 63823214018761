import { SVGProps } from 'react';

export const PrimalIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="214"
      height="40"
      viewBox="0 0 214 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M95.4863 0.0625894C97.1309 0.0625894 98.5598 0.00866898 99.9797 0.0625894C100.189 0.115744 100.386 0.209817 100.558 0.339381C100.731 0.468945 100.877 0.631435 100.986 0.817476C105.255 7.1082 109.488 13.3989 113.738 19.6896C114.35 20.5883 114.979 21.487 115.698 22.5474C116.776 20.6692 117.782 18.8718 118.78 17.0745C121.737 11.6825 124.756 6.35331 127.641 0.988224C127.752 0.66222 127.974 0.385228 128.268 0.204833C128.561 0.0244377 128.908 -0.0480997 129.25 -0.000317762C130.427 0.0805629 131.604 -0.000317762 132.844 -0.000317762C133.5 13.336 134.156 26.5645 134.812 39.8739H130.229L128.351 3.0372L128.162 2.96531L113.891 28.6854L95.4863 0.0625894Z" />
      <path d="M178.182 40H173.177C171.379 35.3898 169.366 29.8899 167.605 25.2438C167.546 24.9332 167.369 24.6576 167.11 24.475C166.852 24.2923 166.534 24.2168 166.221 24.2642C162.06 24.2642 157.89 24.2642 153.729 24.2642H152.534C152.534 23.3655 152.876 22.9611 153.828 22.9701C158.061 23.024 162.294 22.9701 166.679 22.9701C165.844 20.8043 159.876 4.89778 159.876 4.89778L161.314 0H163.192C166.868 9.68771 178.083 39.6675 178.182 40Z" />
      <path d="M46.0952 20.5527C47.5331 20.0854 48.953 19.7079 50.31 19.1597C56.6816 16.5626 57.3556 10.9818 55.7021 6.3716C54.5158 3.13637 51.703 1.87823 48.5846 1.13233C47.7847 0.943608 45.7987 0.503258 45.6279 0.395417L45.6729 0C46.7423 0.0808807 47.8117 0.134801 48.8811 0.242642C51.6051 0.438427 54.2571 1.20708 56.6637 2.49832C57.9144 3.18039 58.9794 4.15804 59.7658 5.34606C60.5521 6.53407 61.0359 7.89639 61.175 9.31426C61.3142 10.7321 61.1044 12.1625 60.564 13.4807C60.0236 14.7989 59.1689 15.9649 58.0746 16.8771C56.3091 18.3489 54.2815 19.474 52.0984 20.1932L50.984 20.5976L65.9559 40C64.0957 40 62.4331 40 60.7706 40C60.5355 39.9353 60.3334 39.7845 60.2044 39.5776C55.5583 33.3139 46.1402 20.6695 46.0952 20.5527Z" />
      <path d="M0.739258 0.107422H5.28655V39.9187H0.739258V0.107422Z" />
      <path d="M37.5225 0.0898438H42.0787V39.9371H37.5225V0.0898438Z" />
      <path d="M81.261 39.9732H76.7676V0.125977H81.261V39.9732Z" />
      <path d="M189.856 0.116211H194.413V39.9185H189.856V0.116211Z" />
      <path d="M9.28558 0C10.6931 0.0961928 12.0941 0.270203 13.4824 0.521231C15.5502 0.917264 17.5776 1.50082 19.5395 2.26466C27.5197 5.72456 25.8302 15.6549 21.2649 18.9351C18.6269 20.7204 15.5898 21.8284 12.4219 22.1613C11.3885 22.3051 10.355 22.359 9.32152 22.4489L9.24064 22.0445C9.51923 21.9546 13.1858 21.1458 14.6327 20.6066C15.984 20.1538 17.1805 19.3294 18.0849 18.2279C18.9893 17.1264 19.565 15.7924 19.7461 14.3788C20.0768 12.2707 20.1251 10.128 19.8899 8.00719C19.3867 4.21478 16.7985 2.21074 13.2218 1.34801C12.1074 1.07841 9.4024 0.557178 9.17773 0.449337L9.28558 0Z" />
      <path d="M213.833 39.9633H198.286C198.565 39.6937 198.762 39.5319 198.906 39.4151C200.003 39.1725 213.609 36.2068 213.833 36.1709V39.9633Z" />
      <path d="M98.4701 29.5297V39.9274H94.0576C94.2014 39.5679 98.1106 29.6645 98.2994 29.377L98.4701 29.5297Z" />
      <path d="M145.192 39.9363C146.792 37.0695 150.054 31.4977 150.297 31.1562C150.378 31.354 150.522 31.7584 150.522 31.7584C150.297 33.349 150.036 34.9307 149.794 36.5124C149.623 37.6357 149.461 38.759 149.29 39.9363H145.192Z" />
    </svg>
  );
};
