import { urlJoin } from 'url-join-ts';

export const OPEN_EDITION_LIMIT = 9999;

export enum MembershipTiers {
  NON_MEMBER = 0,
  FRIEND = 1,
  SUPPORTER = 2,
  PATRON = 3,
}

export enum CurrencySymbol {
  // Fiat
  USD = 'usd',
  EUR = 'eur',
  GBP = 'gbp',

  // Substrate
  DOT = 'dot',
  KSM = 'ksm',
  WND = 'wnd',

  // Ethereum
  ETH = 'eth',
}

export enum ArtpoolAppPaths {
  ROOT = '/',
  THE_WORK_OF_A_CURATOR = '/the-work-of-a-curator',
  SENTRY_TEST = '/sentry-test',
  NFT_COLLECTION = '/nft-collection',
  CHECKOUT = '/checkout',
  FUNDRAISING_PROJECT = '/fundraising-project',
  PROJECT_MANAGEMENT = '/project-management',
  PROJECT_CREATION = '/create',
  MEMBERSHIP = '/membership',
  MY_ACCOUNT = '/my-account',
}

export enum ACGAppPaths {
  ROOT = '/',
  EXHIBITIONS = '/exhibitions',
  FOR_CURATORS_PAGE = '/for-curators',
  LOGIN = '/login',
  RESET = '/reset/:token',
  LOGOUT = '/logout',
  ABOUT_US = '/about-us',
  FAQ = '/FAQ',
  SERVICES = '/services',
  FOR_INSTITUTIONS_PAGE = '/for-institutions',
  BLOG = '/blog',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  PRIVACY_POLICY = '/privacy-policy',
  MODIFY_PASSWORD = '/account/modify-password',
  CONTINUE_SIGN_UP = '/continue',
  POSTS = '/posts',
  POST = '/posts/:postId',
  USERS = '/users',
  USER_POSTS = '/users/:userId/posts',
  USER_FOLLOWERS = '/users/:userId/followers',
  USER_FOLLOWINGS = '/users/:userId/followings',
  USER_POST = '/users/:userId/posts/:postId',
  CREATE_ONLINE_EXHIBITION = '/create-online-exhibition',
  UPDATE_ONLINE_EXHIBITION = '/create-online-exhibition/:exhibitionId',
  ONLINE_EXHIBITION = '/online-exhibition/:exhibitionId',
  IFRAME_ONLINE_EXHIBITION = '/container/online-exhibition/:exhibitionId',
  LAB_BOX_2 = '/labbox2',
  BACK_OFFICE = '/back-office',
  VENUES = '/venues',
  VENUE = '/venues/:institutionId',
  VENUE_POSTS = '/venues/:institutionId/posts',
  VENUE_FOLLOWERS = '/venues/:institutionId/followers',
  VENUE_POST = '/venues/:institutionId/posts/:postId',
}

// This is a little confusing as the app is called acg but the url is artpool.xyz
// This is because the app was originally called acg but we changed it to artpool
export const ACG_URL = 'https://www.artpool.xyz';
export const ACG_SERVICES_URL = urlJoin(ACG_URL, ACGAppPaths.SERVICES)
export const ACG_ABOUT_US_URL = urlJoin(ACG_URL, ACGAppPaths.ABOUT_US)
export const ACG_TS_AND_CS_URL = urlJoin(ACG_URL, ACGAppPaths.TERMS_AND_CONDITIONS)
export const ACG_PRIVACY_POLICY_URL = urlJoin(ACG_URL, ACGAppPaths.PRIVACY_POLICY)
export const FAQ_URL = urlJoin(ACG_URL, ACGAppPaths.FAQ);

// This is a little confusing as the app is called artpool but is hosted at marketplace.artpool.xyz
export const ARTPOOL_URL = 'https://marketplace.artpool.xyz';
export const ARTPOOL_MY_ACCOUNT_URL = urlJoin(ARTPOOL_URL, ArtpoolAppPaths.MY_ACCOUNT);
export const ARTPOOL_CHECKOUT_URL = urlJoin(ARTPOOL_URL, ArtpoolAppPaths.CHECKOUT);


export const EVM_MEMBERSHIP_WHITELIST_LIMIT = 1000;

export const DISCORD_URL = 'https://discord.gg/ARxaN4qSQV';
export const INSTAGRAM_URL = 'https://www.instagram.com/artpool.xyz/?hl=en';
export const FACEBOOK_URL = 'https://www.facebook.com/artpool.xyz';
export const UNIQUE_WALLET_URL = 'https://wallet.unique.network/';
export const ARTPOOL_MAIL_URL = 'mailto:hello@artpool.xyz';
export const METAMASK_URL = 'https://metamask.io/';
export const TELEGRAM_URL = 'https://t.me/artpoolxyz';
export const CURTAIN_URL = 'https://curtain.artcuratorgrid.com/';
export const BLOG_URL = 'https://www.artcuratorgrid.com/blog';
export const TWITTER_URL = 'https://twitter.com/Artpool_xyz';
export const LINKEDIN_URL =
  'https://www.linkedin.com/company/artpoolplatform/mycompany/';
export const ARTPOOL_MARKETPLACE = 'https://opensea.io/Artpool_xyz?tab=created';
export const TERMS_CONDITIONS =
  'https://acg-public.s3.eu-west-2.amazonaws.com/legal/Terms+and+Conditions+of+Artpool.pdf';
export const LOGION_ABOUT =
  'https://help.artpool.xyz/what-is-the-logion-nft-certificate';

// Partners
export const UNIQUE_URL = 'https://unique.network/';
export const OUTLIER_VENTURES_URL = 'https://outlierventures.io/';
export const PRIMAL_URL = 'https://www.primalcapital.io/';

export enum MembershipData {
  PROJECT_ID = '6241e6dbc12aa0e5233e3286',
  NFT_ID = '6241e838c12aa0e5233e3287',
  EDITIONS = 3000,
}

export enum MembershipNftIds {
  FRIEND = '6241e838c12aa0e5233e3287',
  SUPPORTER = '6241e845c12aa0e5233e3288',
  PATRON = '6241e857c12aa0e5233e3289',
}

export const getNftMembershipTier = (nftId: string) => {
  switch (nftId) {
    case MembershipNftIds.FRIEND:
      return MembershipTiers.FRIEND;
    case MembershipNftIds.SUPPORTER:
      return MembershipTiers.SUPPORTER;
    case MembershipNftIds.PATRON:
      return MembershipTiers.PATRON;
    default:
      return MembershipTiers.NON_MEMBER;
  }
};

export enum ArtpoolApiUrls {
  LOCAL = 'http://localhost:3001',
  DEVELOP = 'https://artpool-api-stage.herokuapp.com',
  PRODUCTION = 'https://artpool-api-prod.herokuapp.com',
}

export enum ArtcuratorgridApiUrls {
  LOCAL = 'http://localhost:3000',
  DEVELOP = 'https://artcuratorgrid-api-develop.herokuapp.com',
  PRODUCTION = 'https://artcuratorgrid-api-production.herokuapp.com',
}

export const GET_WHITELISTED_QUERY_PARAM = 'get-whitelisted';

export enum EnvLabels {
  LOCAL = 'local',
  TEST = 'test',
  DEVELOP = 'develop',
  RELEASE = 'release',
  PRODUCTION = 'production',
}

export const readEnv = (key: string): string => {
  const val = process.env[key];
  if (val === undefined) {
    throw Error(`${key} must be set`);
  }
  return val;
};

export const readEnvOptional = (key: string): string | undefined =>
  process.env[key];

export const readPortFromEnv = (): number => parseInt(readEnv('PORT'));

export const noReplyEmail = 'noreply@artpool.xyz';

export const metaMaskDappLink = (url = '') =>
  `https://metamask.app.link/dapp/${url.replace(/^https?:\/\//, '')}`;
