import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';

import { IArtistUser, ICuratorUser } from '../../types/user';

import { Button, Grid } from '@mui/material';
import Title from '../../library/dataDisplay/typography/Title';
import UserPicture from '../../library/dataDisplay/profile/UserPicture';
import SubTitle from '../../library/dataDisplay/typography/SubTitle';
import Tags from '../tags/Tags';
import Biography from '../profile/Biography';

import { generateLocation } from '../../utils/locationHelper';
import { GREY_COLOR, SPACING, PRIMARY_COLOR } from '../../styles/constants';
import { dateToStringParser } from '../../utils/datetimehelper';

const style = createStyles({
  container: {
    backgroundColor: GREY_COLOR,
    padding: SPACING * 3,
    margin: `${SPACING * 2}px ${SPACING}px ${SPACING}px ${SPACING}px`,
    flexBasis: 'unset',
  },
  button: {
    borderWidth: '3px',
    backgroundColor: PRIMARY_COLOR,
    color: '#fff',
    padding: '20px 0',
    paddingTop: '20px',
  },
  buttonChange: {
    borderColor: PRIMARY_COLOR,
    borderWidth: '3px',
    backgroundColor: GREY_COLOR,
    color: PRIMARY_COLOR,
    padding: '20px 0',
    paddingTop: '20px',
  },
  title: {
    paddingBottom: '0.5em',
  },
});

interface IUserCardProps {
  user: ICuratorUser | IArtistUser;
  onOpenApproveModal: (user: ICuratorUser | IArtistUser) => void;
  onOpenChangeTypeModal: (user: ICuratorUser | IArtistUser) => void;
}

type props = IUserCardProps & WithWidth & WithStyles<string>;

const UserCard: React.FC<props> = (Props) => {
  const { user, classes, onOpenApproveModal, onOpenChangeTypeModal } = Props;

  const handleAcceptClick = () => onOpenApproveModal(user);

  const handleChangeClick = () => onOpenChangeTypeModal(user);

  let companyName: string = '';
  if (user.profile === 'CURATOR' && user.company_type === 'INSTITUTION') {
    companyName = user.company_name;
  }
  if (user.profile === 'CURATOR' && user.company_type === 'INDEPENDANT') {
    companyName = 'Independent';
  }

  const getCuratorsList = () => {
    const curatorsList: string[] = [];
    if (user.hasWorkedWith) {
      user.hasWorkedWith.map((curator) => {
        curatorsList.push(
          `${curator.firstName || ''} ${curator.lastName || ''}${
            curator.email && ` - ${curator.email}`
          }`
        );
      });
    }
    return curatorsList.join(', ');
  };

  return (
    <Grid container={true} className={classes.container} xs={12}>
      <Grid container={true} xs={3} alignItems="center">
        <UserPicture
          user={user}
          style={{
            marginRight: 10,
            marginLeft: 16,
            width: 69,
            height: 69,
          }}
        />
        <div>
          <Title>
            {`${user.firstName ? user.firstName : ''} ${
              user.lastName ? user.lastName : ''
            }`}
          </Title>
          <SubTitle>{companyName}</SubTitle>
          <SubTitle>{generateLocation(user)}</SubTitle>
        </div>
      </Grid>
      <Grid xs={6}>
        {user.hasWorkedWith && user.hasWorkedWith.length > 0 && (
          <Title className={classes.title}>
            Has worked with: {getCuratorsList()}
          </Title>
        )}
        <Title className={classes.title}>
          <strong>Sign Up Date:</strong>{' '}
          {dateToStringParser(user.createdAt.toString(), 'DD/MM/YYYY')}
        </Title>
        <Tags tags={user.tags} tagName={'Curatorial Expertise'} />
        <Title>
          <Biography user={user}>{user.bio}</Biography>
        </Title>
      </Grid>
      <Grid xs={3} container={true} spacing={2}>
        <Grid xs={6} item={true}>
          <Button
            onClick={handleAcceptClick}
            variant="outlined"
            disabled={user.profile === 'ARTIST' && !user.hasWorkedWith}
            component="label"
            fullWidth={true}
            className={classes.button}
          >
            ACCEPT
          </Button>
        </Grid>
        <Grid xs={6} item={true}>
          <Button
            onClick={handleChangeClick}
            variant="outlined"
            component="label"
            fullWidth={true}
            className={classes.buttonChange}
          >
            CHANGE
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default compose<props, IUserCardProps>(
  withWidth(),
  withStyles(style),
  withRouter
)(UserCard);
