import { ARTPOOL_CHECKOUT_URL } from '@acg/shared/const';
import { INft } from '../../types/artpool-types/nfts';
import { INftData } from '../../types/nftData';

export const OPEN_EDITION_LIMIT = 9999;

export const editionStr = (nft: INft): string => {
  const { sold, maximum } = nft.editions;

  if (sold >= maximum) {
    return '';
  }

  if (maximum >= OPEN_EDITION_LIMIT) {
    return `Open Editions`;
  }

  const maxStr = maximum.toFixed(0);
  const editionNumber = sold + 1;
  const soldStr = editionNumber.toFixed(0);
  const paddedSold = String(soldStr).padStart(maxStr.length, '0');
  return `Edition ${paddedSold} of ${maxStr}`;
};

export function formatUserOrVenueData(userOrVenue: any): any {
  // TODO - Check the most secure way to define if it a venue or a user
  const isVenue = userOrVenue.name ? true : false;

  return {
    name: isVenue
      ? userOrVenue.name
      : `${userOrVenue.firstName} ${userOrVenue.lastName}`,
    profilePicture: userOrVenue.profilePicture,
    bio: isVenue
      ? (userOrVenue.about && userOrVenue.about) || ''
      : userOrVenue.bio,
    url: userOrVenue.url,
    edited_bio: userOrVenue.edited_bio,
    location: userOrVenue.location ? userOrVenue.location.address : '',
    type: isVenue ? 'Institution' : userOrVenue.profile,
  };
}

export function format3digitNumber(nb: number): any {
  if (nb < 10) {
    return '00' + nb;
  } else if (nb < 100) {
    return '0' + nb;
  } else {
    return nb;
  }
}

export function getCheckoutPageUrl(projectId: string, nftId: string): string {
  return `${ARTPOOL_CHECKOUT_URL}?projectId=${projectId}&nftId=${nftId}`;
}

export function onBuyNftClick(nftData: INftData | undefined) {
  if (!nftData) {
    return;
  }

  const projectId = (nftData.project as any)._id;
  const nftId = (nftData.nft as any)._id;
  window.open(getCheckoutPageUrl(projectId, nftId));
}
