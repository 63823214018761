import { ErrorType, ReqOptions, ResOptions, Route } from 'typesafe-api';
import {
  ArtpoolApiEndpoint,
  DefaultErrorCodes,
  JsonUserOrVenue,
} from '@acg/artpool-api-spec';
import { UserProfileType } from '@acg/api-interfaces';

export interface GetVenuesOptions {
  search: string;
}

/**
 * Get Venues
 */

export const getVenuesRoute: Route = {
  method: 'get',
  path: '/venues/search',
};

export interface GetVenuesReq extends ReqOptions {
  query?: GetVenuesOptions;
}

export interface GetVenuesResp extends ResOptions {
  body: JsonUserOrVenue[];
}

export type GetVenuesEndpointDef = ArtpoolApiEndpoint<
  GetVenuesReq,
  GetVenuesResp,
  ErrorType<DefaultErrorCodes | 404>
>;
