import * as React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { GREY_COLOR, PRIMARY_COLOR } from '../../styles/constants';
import TextInput from './TextInput';
import { Field, Formik, FormikProps } from 'formik';
import LocationFinder from './LocationFinder';
import { IPost } from '../../types/posts';

import ArtistService from '../../services/ArtistService';
import DesignedButton from '../../library/inputs/DesignedButton';
import { useIsWidthDown } from '../../hooks/theme';

const PREFIX = 'ArtistInput';

const classes = {
  artistInput: `${PREFIX}-artistInput`,
  button: `${PREFIX}-button`,
  errorMessage: `${PREFIX}-errorMessage`,
  buttonsContainer: `${PREFIX}-buttonsContainer`,
};

const StyledForm = styled('form')({
  [`& .${classes.artistInput}`]: {
    padding: 20,
    fontFamily: 'Replica-Regular',
  },
  [`& .${classes.button}`]: {
    backgroundColor: GREY_COLOR,
    padding: '20px 0',
    paddingTop: '20px',
    borderRadius: 100,
  },
  [`& .${classes.errorMessage}`]: {
    color: '#f44336',
    marginLeft: 14,
    marginRight: 14,
    fontSize: '0.75rem',
    marginTop: 3,
    textAlign: 'left',
    fontFamily: 'Replica-Regular',
    fontWeight: 400,
    lineHeight: 1.66,
  },

  [`& .${classes.buttonsContainer}`]: {
    paddingTop: 30,
  },
});

export interface IArtistInputProps {
  artist?: any;
  onArtistUpdate: (artist: any) => void;
  OnArtistCancel: () => void;
}

export interface IArtistInputFormProps {
  onDone: () => void;
  onCreate: (post: IPost) => void;
  onUpdate: (post: IPost) => void;
  initialValues?: IArtistInputValue;
  subType?: string;
}

export interface IArtistInputValue {
  email: string;
  name: string;
  location: {
    value: string;
    address: string;
    placeId: string;
    latLng: [number, number];
  };
  deathYear: number;
  birthYear: number;
  nationality: string;
}

const artistService = new ArtistService();

export default (props: IArtistInputProps) => {
  const { artist, OnArtistCancel, onArtistUpdate } = props;
  const artistName = artist && artist.name ? artist.name : '';

  const [deceased, setDeceased] = useState(!!artist.deathYear);
  const isMobile = useIsWidthDown('sm');

  const renderForm = (props: FormikProps<IArtistInputValue>) => {
    if (!artist) {
      return (
        <React.Fragment>
          <div>Missing data</div>
        </React.Fragment>
      );
    }

    const artistUpdate = async () => {
      try {
        const newArtist = { ...props.values, name: artistName };
        onArtistUpdate(newArtist);
        await artistService.editArtist(newArtist);
      } catch (err) {
        window.console.log('ERROR', err);
      }
    };
    return (
      <StyledForm>
        <Grid container={true} className={classes.artistInput} spacing={2}>
          <Grid item={true} xs={12}>
            <h2>{artistName}</h2>
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              name="location"
              component={LocationFinder}
              naked={true}
              newLocationFinder={true}
              inputProps={{
                id: 'location',
                label: 'Location',
                type: 'location',
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={deceased}
                  onChange={() => setDeceased(!deceased)}
                  name="deathYear"
                  color="primary"
                />
              }
              label="Deceased"
            />
          </Grid>

          <Grid
            item={true}
            xs={deceased ? 6 : 12}
            style={{ paddingRight: deceased ? 20 : 0 }}
          >
            <Field
              name="birthYear"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'birthYear',
                label: 'Birth year (YYYY)',
                type: 'number',
              }}
            />
            {/*{errorArtPoint && errorArtPoint.title && <p className={classes.errorMessage}>Add the artwork title</p>}*/}
          </Grid>

          {deceased && (
            <Grid item={true} xs={6} style={{ paddingLeft: 20 }}>
              <Field
                name="deathYear"
                component={TextInput}
                newTextInput={true}
                naked={true}
                inputProps={{
                  id: 'deathYear',
                  label: 'Death year (YYYY)',
                  type: 'number',
                }}
              />
              {/*{errorArtPoint && errorArtPoint.year && <p className={classes.errorMessage}>Add the artwork title</p>}*/}
            </Grid>
          )}

          <Grid item={true} xs={12}>
            <Field
              name="nationality"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'nationality',
                label: 'Nationality',
                type: 'text',
              }}
            />
            {/*{errorArtPoint && errorArtPoint.year && <p className={classes.errorMessage}>Add the artwork technic used</p>}*/}
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              name="bio"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'bio',
                label: 'Bio',
                type: 'text',
                multiline: true,
                rows: 5,
                maxRows: 20,
              }}
            />
          </Grid>
          <Grid
            item={true}
            xs={12}
            sm={6}
            style={{ paddingRight: isMobile ? 0 : 20 }}
          >
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <Field
                  name="website_url"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'website_url',
                    label: 'Website URL',
                    type: 'website_url',
                  }}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Field
                  name="twitter_url"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'twitter_url',
                    label: 'Twitter Account',
                    type: 'twitter_url',
                  }}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Field
                  name="facebook_url"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'facebook_url',
                    label: 'Facebook Account',
                    type: 'facebook_url',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item={true}
            xs={12}
            sm={6}
            style={{ paddingLeft: isMobile ? 0 : 20 }}
          >
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <Field
                  name="instagram_url"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'instagram_url',
                    label: 'Instagram Account',
                    type: 'instagram_url',
                  }}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Field
                  name="linkedin_url"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'linkedin_url',
                    label: 'Linkedin Account',
                    type: 'linkedin_url',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item={true} xs={12}>
            <p style={{ color: PRIMARY_COLOR }}>
              Invite this artist to join the platform and create his own profile
            </p>
            <Field
              name="email"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'email',
                label: 'Email',
                type: 'email',
              }}
            />
            {/*{errorArtPoint && errorArtPoint.year && <p className={classes.errorMessage}>Add the artwork technic used</p>}*/}
          </Grid>

          <Grid item={true} xs={12} className={classes.buttonsContainer}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={6}>
                <DesignedButton
                  ACGType="neutral"
                  size="large"
                  onClick={OnArtistCancel}
                  aria-label="Add"
                  fullWidth={true}
                >
                  Cancel
                </DesignedButton>
              </Grid>
              <Grid item={true} xs={6}>
                <DesignedButton
                  ACGType="main"
                  size="large"
                  fullWidth={true}
                  onClick={artistUpdate}
                >
                  Save
                </DesignedButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StyledForm>
    );
  };

  const initialValues: any = {
    ...artist,
    location: {
      ...artist.location,
      value: artist.location.address,
    },
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      render={renderForm}
    />
  );
};
