import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import PostModal from '../../components/modal/ACGModalContent';
import {} from '@mui/material';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import ExhibitionForm from './ExhibitionForm';
import InspirationForm from './InspirationForm';
import ArtProjectForm from './ArtProjectForm';
import { withRouter, RouteComponentProps } from 'react-router';
import { IPost } from '../../types/posts';
import { PRIMARY_COLOR } from '../../styles/constants';
import PostTypeForm from './PostTypeForm';

export interface IAddPostProps {
  onCreate: (post: IPost) => void;
  onUpdate: (post: IPost) => void;
  onDone: () => void;
  onLike: (post: IPost) => void;
  onRemove: (post: IPost) => void;
}

const style = createStyles({
  root: {
    backgroundColor: '#f5f5f5',
    borderRadius: '100px',
    '&$selected': {
      borderRadius: '100px',
      color: '#fff',
      backgroundColor: PRIMARY_COLOR,
    },
  },
  selected: {
    borderRadius: '100px',
    color: '#fff',
    backgroundColor: PRIMARY_COLOR,
  },
  indicator: {
    opacity: 0,
  },
});

type Props = IAddPostProps & WithStyles<string> & RouteComponentProps<{}>;

interface IState {
  value: number;
  subType: string;
}

class AddPost extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: 0,
      subType: '',
    };
  }

  public onClickSelect = (id: string) => {
    const { history } = this.props;

    if (id === 'EXHIBITION') {
      this.setState({ value: 2, subType: id });
    } else if (id === 'ARTPROJECT') {
      this.setState({ value: 3, subType: id });
    } else if (id === 'ONLINEEXHIBITION') {
      this.setState({ value: 100, subType: 'ONLINEEXHIBITION' });
      history.push('/create-online-exhibition/');
    } else {
      this.setState({ value: 1, subType: id });
    }
  };

  public render() {
    const {
      // onCloseModal,
      // classes,
      onDone,
      onCreate,
      onUpdate,
    } = this.props;

    const { value, subType } = this.state;

    // const handleChange = (e: any, val: number) => this.setState({ value: val })

    let content: React.ReactNode = '';

    switch (value) {
      case 1:
        content = (
          <InspirationForm
            onDone={onDone}
            onCreate={onCreate}
            onUpdate={onUpdate}
            subType={subType}
          />
        );
        break;
      case 2:
        content = (
          <ExhibitionForm
            onDone={onDone}
            onCreate={onCreate}
            onUpdate={onUpdate}
            subType={subType}
          />
        );
        break;
      case 3:
        content = (
          <ArtProjectForm
            onDone={onDone}
            onCreate={onCreate}
            onUpdate={onUpdate}
            subType={subType}
          />
        );
        break;
      default:
        content = (
          <PostTypeForm
            show={true}
            onClickSelect={this.onClickSelect}
            onDone={onDone}
          />
        );
        break;
    }

    return <PostModal>{content}</PostModal>;
  }
}

export default compose<Props, IAddPostProps>(
  withRouter,
  withStyles(style)
)(AddPost);
