import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
// import moment from 'moment';
import { compose } from 'recompose';
import { Grid } from '@mui/material';
import { withAccount } from '../../containers/account/InjectAccount';
import PrimaryTitle from '../../library/dataDisplay/typography/PrimaryTitle';
import { Field, Formik, FormikHelpers, FormikProps } from 'formik';
import { generateValidator, IValidationConfig } from '../../utils/formatters';
import FormMessage from '../form/FormMessage';
import TextInput from '../form/TextInput';
import FormSubmit from '../form/FormSubmit';
import { ICatalogRequest } from '../../types/exhibitions';
import VisitorService from '../../services/VisitorServices';
import {
  DARK_GREY_COLOR,
  GREY_COLOR,
  PRIMARY_COLOR,
} from '../../styles/constants';
import FormCancel from '../form/FormCancel';
import { IGallery } from '../../types/galleries';

export interface IRequestCatalogProps {
  onCloseEvent: () => void;
  exhibitionId: string;
  galleryInfo?: IGallery;
  title?: string;
}

const style = createStyles({
  root: {
    backgroundColor: '#FFF',
  },
  wrapper: {
    fontFamily: 'Replica-Regular',
    display: 'flex',
    alignContent: 'stretch',
    justifyItems: 'center',
    flexDirection: 'column',
  },
  main: {
    flex: '1 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  border: {
    borderBottom: `1px solid ${PRIMARY_COLOR}`,
  },

  margin: {
    margin: '30px 0',
  },
  title: {
    color: PRIMARY_COLOR,
  },
  textMobile: {
    width: '95vw',
    '& h1': {
      fontSize: 35,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: '20px',
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: '20px',
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: '15px',
    },
  },
  textDesktop: {
    width: '70vw',
    '& p': {
      fontSize: '16px',
    },
    '& h3': {
      fontSize: '20px',
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: '30px',
      fontWeight: 'normal',
    },
    '& h1': {
      fontSize: '50px',
      fontWeight: 'normal',
    },
  },
  bg: {
    backgroundColor: GREY_COLOR,
    minHeight: '530px',
    padding: 20,
    display: 'flex',
  },
  formWrapper: {
    border: `1px solid ${DARK_GREY_COLOR}`,
    minHeight: 543,
    borderRadius: 8,
    padding: 20,
  },
  content: {
    paddingTop: 35,
  },

  container: {
    height: '100%',
    overflowY: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
  },
});

interface IState {
  error: boolean;
  success: boolean;
}
type IInitialValues = ICatalogRequest;

type Props = IRequestCatalogProps & WithStyles<string> & WithWidth;

class RequestCatalog extends React.Component<Props, IState> {
  private visitorService = new VisitorService();

  constructor(props: Props) {
    super(props);
    this.state = {
      error: false,
      success: false,
    };
  }

  public onSubmit = async (
    valuesFromForm: IInitialValues,
    actions: FormikHelpers<IInitialValues>
  ) => {
    this.setState({ error: false, success: false });

    try {
      const response = await this.visitorService.catalogRequest(valuesFromForm);
      if (response.status === 'ok') {
        actions.resetForm();
        this.setState({ error: false, success: true });
      }
    } catch (err) {
      window.console.log(err);
      this.setState({ error: true, success: false });
    }
    // track(account.id, 'ServiceSubmitted', account);
    actions.setSubmitting(false);
  };

  public renderForm = (props: FormikProps<IInitialValues>) => {
    const { onCloseEvent } = this.props;

    return (
      <form onSubmit={props.handleSubmit}>
        <Grid container={true} spacing={1}>
          {!!this.state.error && (
            <FormMessage
              message={'An unexpected error happened, please try again.'}
              visible={!!this.state.error}
              type={'ERROR'}
            />
          )}
          {!!this.state.success && (
            <FormMessage
              message={
                'Your request is being processed, we will follow back shortly via email. Thanks for your consideration.'
              }
              visible={!!this.state.success}
              type={'SUCCESS'}
            />
          )}

          <Grid item={true} xs={12}>
            <Field
              name="name"
              component={TextInput}
              disabled={true}
              inputProps={{
                id: 'name',
                label: 'Name',
                type: 'name',
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              name="email"
              component={TextInput}
              disabled={true}
              inputProps={{
                id: 'email',
                label: 'Email',
                type: 'email',
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              name="subject"
              component={TextInput}
              disabled={true}
              inputProps={{
                id: 'subject',
                label: 'Subject',
                type: 'subject',
                value: 'Request catalogue',
                disabled: true,
              }}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              name="description"
              component={TextInput}
              inputProps={{
                id: 'description',
                label: 'Description',
                type: 'description',
                multiline: true,
                rows: 5,
                maxRows: 20,
                // placeholder: "Tell us more about your project?"
              }}
            />
          </Grid>

          <Grid xs={12} container={true} justifyContent="space-between">
            <Grid item={true} xs={3}>
              <FormCancel
                disabled={props.isSubmitting}
                onClick={onCloseEvent}
                newButton={true}
                variant="contained"
              >
                Cancel
              </FormCancel>
            </Grid>

            <Grid item={true} xs={3}>
              <FormSubmit
                disabled={props.isSubmitting}
                newButton={true}
                variant="contained"
              >
                Save your changes
              </FormSubmit>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  };

  public render() {
    const { classes, width, exhibitionId } = this.props;

    const initialValues: IInitialValues = {
      description: '',
      subject: 'Request Catalogue',
      name: '',
      email: '',
      exhibitionId,
    };
    const config: IValidationConfig = {
      name: ['REQUIRED'],
      email: ['REQUIRED'],
      // subject: ['REQUIRED'],
      // description: ['REQUIRED'],
    };

    const validate = generateValidator(config);

    const isMobile = isWidthDown('xs', width);

    return (
      <Grid container={true} className={classes.root} justifyContent="center">
        <div
          className={`${isMobile ? classes.textMobile : classes.textDesktop} ${
            classes.content
          }`}
        >
          <PrimaryTitle>{`Request Catalogue`} </PrimaryTitle>
          <div className={classes.formWrapper}>
            <Formik
              initialValues={initialValues}
              validate={validate}
              onSubmit={this.onSubmit}
              render={this.renderForm}
            />
          </div>
        </div>
      </Grid>
    );
  }
}

export default compose<Props, IRequestCatalogProps>(
  withStyles(style),
  withAccount
)(RequestCatalog);
