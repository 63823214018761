import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { Button, Grid } from '@mui/material';
import { getMediumImageUrl } from '../../utils/imageUrl';
import ReactPlayer from 'react-player';
import { compose } from 'recompose';
import { Popup } from 'react-leaflet';
import { INFO_POINT_ICON } from '../../styles/constants';
import { getExhibitionArtists } from '../../utils/exhibition';

interface IArtWorkPopupProps {
  point: any;
  popup: any;
  edit_mode?: boolean;
  create_mode?: boolean;
  handleClickOpen?: any;
  markerEditClick?: any;
  onPointClick?: any;
  onPopupCloseClick?: any;
}

const MAX_WIDTH = 140;
const HEIGHT = 70;
const styles = createStyles({
  artPointImage: {
    maxHeight: HEIGHT,
    maxWidth: MAX_WIDTH,
    objectFit: 'cover',
  },
  textPopupArtist: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Helvetica',
    fontSize: 15,
  },
  textPopupWork: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Helvetica',
    fontSize: 10,
  },
  popup_layout: {
    backgroundColor: '#FFF',
    display: 'flex',
    alignItems: 'center',
  },
});

type Props = IArtWorkPopupProps & WithStyles<string> & WithWidth;

function ArtWorkPopup(props: Props) {
  const {
    point,
    classes,
    edit_mode,
    create_mode,
    handleClickOpen,
    markerEditClick,
    popup,
    onPointClick,
    onPopupCloseClick,
  } = props;

  const renderPointData = () => {
    if (point.info_point) {
      return (
        <React.Fragment>
          <Grid item={true} xs={12} style={{ textAlign: 'center' }}>
            {point &&
              point.imagesWithCredit &&
              point.imagesWithCredit[0] &&
              point.imagesWithCredit[0].image_url && (
                <img
                  src={getMediumImageUrl(point.imagesWithCredit[0].image_url)}
                  className={classes.artPointImage}
                  style={
                    point.imagesWithCredit[0].image_url === INFO_POINT_ICON
                      ? { maxWidth: 46 }
                      : {}
                  }
                />
              )}

            {point &&
              !point.featured_imageWithCredit &&
              (!point.imagesWithCredit || !point.imagesWithCredit[0]) &&
              point.videos &&
              point.videos[0] &&
              point.videos[0].video_link && (
                <>
                  <div className={classes.playerPopup}>
                    <ReactPlayer
                      className="reactPlayer"
                      url={point.videos[0].video_link}
                      playing={false}
                      width={MAX_WIDTH}
                      height={HEIGHT}
                      controls={true}
                      vimeo={{
                        playerOptions: {
                          height: HEIGHT,
                          width: MAX_WIDTH,
                        },
                      }}
                    />
                  </div>
                </>
              )}
          </Grid>
          <Grid
            item={true}
            xs={12}
            className={classes.textPopupArtist}
            style={{ textAlign: 'center' }}
          >
            {point.title}
          </Grid>
        </React.Fragment>
      );
    } else if (point.art_work && point.art_work.nft_data) {
      const { title, artist, image } = point.art_work.nft_data.nft;
      return (
        <React.Fragment>
          <Grid item={true} xs={12} style={{ textAlign: 'center' }}>
            {image && (
              <img
                src={getMediumImageUrl(image.src)}
                alt={image.label}
                className={classes.artPointImage}
              />
            )}
          </Grid>
          <Grid item={true} xs={12}>
            {artist && (
              <div className={classes.textPopupArtist}>
                {artist.firstName} {artist.lastName}
              </div>
            )}
            <div className={classes.textPopupWork}>{title}</div>
          </Grid>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Grid item={true} xs={12} style={{ textAlign: 'center' }}>
            {point.art_work &&
              point.art_work.imagesWithCredit &&
              point.art_work.imagesWithCredit[0] &&
              point.art_work.imagesWithCredit[0].image_url && (
                <img
                  src={getMediumImageUrl(
                    point.art_work.imagesWithCredit[0].image_url
                  )}
                  className={classes.artPointImage}
                />
              )}

            {point.art_work &&
              !point.art_work.featured_imageWithCredit &&
              (!point.art_work.imagesWithCredit ||
                !point.art_work.imagesWithCredit[0]) &&
              point.art_work.videos &&
              point.art_work.videos[0] &&
              point.art_work.videos[0].video_link && (
                <>
                  <div className={classes.playerPopup}>
                    <ReactPlayer
                      className="reactPlayer"
                      url={point.art_work.videos[0].video_link}
                      playing={false}
                      width={MAX_WIDTH}
                      height={HEIGHT}
                      controls={true}
                      vimeo={{
                        playerOptions: {
                          height: HEIGHT,
                          width: MAX_WIDTH,
                        },
                      }}
                    />
                  </div>
                </>
              )}
          </Grid>

          <Grid item={true} xs={12}>
            {point.art_work && point.art_work.artists_list && (
              <div className={classes.textPopupArtist}>
                {point.art_work.artists_list.join(', ')}
              </div>
            )}
            {point.art_work && (
              <div className={classes.textPopupArtist}>
                {' '}
                {getExhibitionArtists(point.art_work).map(
                  (artist: any, index: any) => {
                    return (
                      <span key={'_artist_' + index + '_' + artist}>
                        {(index ? ', ' : '') + artist.name}
                      </span>
                    );
                  }
                )}
              </div>
            )}
            {point.art_work && !point.art_work.artists_list && <br />}
            {point.art_work && (
              <div className={classes.textPopupWork}>
                {point.art_work.title}
                {point.art_work.year && `, ${point.art_work.year}`}
              </div>
            )}
          </Grid>
        </React.Fragment>
      );
    }
  };

  return (
    // @ts-ignore
    <Popup
      className={classes.popup_layout}
      ref={popup}
      autoPan={true}
      // autoClose={false}
      maxWidth={MAX_WIDTH}
      minWidth={110}
      closeOnClick={false}
      onClose={onPopupCloseClick}
    >
      <Grid
        style={{ height: markerEditClick && handleClickOpen ? 180 : 120 }}
        container={true}
        justifyContent="center"
        alignItems="center"
        onClick={onPointClick}
      >
        {renderPointData()}
        {markerEditClick && handleClickOpen && (
          <Grid item={true} xs={12} style={{ paddingTop: 10 }}>
            {!edit_mode && !create_mode && (
              <React.Fragment>
                <Button value={point.id} onClick={handleClickOpen}>
                  Remove
                </Button>
                <Button value={point.id} onClick={markerEditClick}>
                  Edit
                </Button>
              </React.Fragment>
            )}
          </Grid>
        )}
      </Grid>
      <br />
    </Popup>
  );
}

export default compose<Props, IArtWorkPopupProps>(
  withStyles(styles),
  withWidth()
)(ArtWorkPopup);
