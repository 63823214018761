import { IServiceRequest, IUser } from '../types/user';
import ApiServices, { IDataResponse, IDataListResponse } from './ApiServices';
import { IPost } from '../types/posts';
import { ICatalogRequest } from '../types/exhibitions';

export interface IPublicPostFilters {
  first_result?: number;
  max_results?: number;
  sub_type?: string;
  status?: string;
  venueId?: string;
}

export interface IPostTotals {
  exhibition: number;
  art_project: number;
  publication: number;
  studio_visit: number;
  interview: number;
  online_exhibition: number;
  misc: number;
}

class VisitorService {
  public getPublicUser = (userId: string): Promise<IDataResponse<IUser>> => {
    return ApiServices.getRequest(
      `public/users/${userId}`,
      {},
      {},
      { authenticated: false }
    );
  };

  public getSelectedCurator = async (): Promise<any> => {
    return ApiServices.getRequest(
      `/public/curator`,
      {},
      {},
      { authenticated: false }
    );
  };

  public getPublicUserPosts = (
    userId: string,
    filters?: IPublicPostFilters
  ): Promise<IDataListResponse<IPost>> => {
    return ApiServices.getRequest(
      `public/users/${userId}/posts`,
      filters,
      {},
      { authenticated: false }
    );
  };

  public getPublicUserPost = (
    userId: string,
    postId: string
  ): Promise<IDataResponse<IPost>> => {
    return ApiServices.getRequest(
      `public/users/${userId}/posts/${postId}`,
      {},
      {},
      { authenticated: false }
    );
  };

  public getSelectedPosts = async (): Promise<IDataListResponse<IPost>> => {
    return ApiServices.getRequest(
      `public/selection`,
      {},
      {},
      { authenticated: false }
    );
  };

  public getSelectedUser = async (): Promise<IDataListResponse<IPost>> => {
    return ApiServices.getRequest(
      `public/curator`,
      {},
      {},
      { authenticated: false }
    );
  };

  public getPublicUserTotalPosts = (
    userId: string
  ): Promise<IDataResponse<IPostTotals>> => {
    return ApiServices.getRequest(
      `public/users/${userId}/poststotals`,
      {},
      {},
      { authenticated: false }
    );
  };

  public getAcceptedCurators = (): Promise<IDataResponse<any>> => {
    return ApiServices.getRequest(
      `public/users`,
      {},
      {},
      { authenticated: false }
    );
  };

  public catalogRequest = (
    catalogRequest: ICatalogRequest
  ): Promise<IDataResponse<IServiceRequest>> => {
    return ApiServices.postRequest(
      `public/request_catalog`,
      catalogRequest,
      {},
      {},
      { authenticated: false }
    );
  };

  public getPublicPosts = (
    userId: string,
    filters?: IPublicPostFilters
  ): Promise<IDataListResponse<IPost>> => {
    return ApiServices.getRequest(
      `public/posts`,
      filters,
      {},
      { authenticated: false }
    );
  };

  public getPublicExhibitions = (
    filters?: IPublicPostFilters
  ): Promise<IDataListResponse<IPost>> => {
    return ApiServices.getRequest(
      `public/exhibitions`,
      filters,
      {},
      { authenticated: false }
    );
  };

  public getPublicExhibition = (): Promise<IDataListResponse<IPost>> => {
    return ApiServices.getRequest(
      `public/exhibition`,
      {},
      {},
      { authenticated: false }
    );
  };

  public getPublicPost = (postId: string): Promise<IDataResponse<IPost>> => {
    return ApiServices.getRequest(
      `public/posts/${postId}`,
      {},
      {},
      { authenticated: false }
    );
  };
}

export default VisitorService;
