import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const EventIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox={'0 0 25 24'}>
      <path
        d="M8.64282 14.1312L8.7083 14.2H10.2916L10.3571 14.1312V12.4685L10.2916 12.3998H8.7083L8.64282 12.4685V14.1312Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M6.5 10.231L6.56548 10.2998H8.14879L8.21426 10.231V8.5684L8.14879 8.49965H6.56548L6.5 8.5684V10.231Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M14.2141 13.6641L14.296 13.75H16.2751L16.3569 13.6641V11.5857L16.2751 11.4998H14.296L14.2141 11.5857V13.6641Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M10.3572 15.9312L10.4227 16H12.006L12.0714 15.9312V14.2686L12.006 14.1998H10.4227L10.3572 14.2686V15.9312Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M6.92847 15.9312L6.99394 16H8.57726L8.64273 15.9312V14.2686L8.57726 14.1998H6.99394L6.92847 14.2686V15.9312Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M8.64282 15.9312L8.7083 16H10.2916L10.3571 15.9312V14.2686L10.2916 14.1998H8.7083L8.64282 14.2686V15.9312Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M16.3569 15.9141L16.4388 16H18.4179L18.4998 15.9141V13.8357L18.4179 13.7498H16.4388L16.3569 13.8357V15.9141Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M12.0713 15.9141L12.1531 16H14.1323L14.2141 15.9141V13.8357L14.1323 13.7498H12.1531L12.0713 13.8357V15.9141Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M14.2141 15.9141L14.296 16H16.2751L16.3569 15.9141V13.8357L16.2751 13.7498H14.296L14.2141 13.8357V15.9141Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M4.05943 3L3.92847 3.1375V6.4625L4.05943 6.6H7.22633L7.3573 6.4625V3.1375L7.22633 3H4.05943Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M4.05943 17.3999L3.92847 17.5374V20.8624L4.05943 20.9999H7.22633L7.3573 20.8624V17.5374L7.22633 17.3999H4.05943Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M0.630962 6.6001L0.5 6.7376V10.0626L0.630962 10.2001H3.79787L3.92883 10.0626V6.7376L3.79787 6.6001H0.630962Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M21.2023 6.6001L21.0713 6.7376V10.0626L21.2023 10.2001H24.3692L24.5001 10.0626V6.7376L24.3692 6.6001H21.2023Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M0.630962 10.2L0.5 10.3375V13.6625L0.630962 13.8H3.79787L3.92883 13.6625V10.3375L3.79787 10.2H0.630962Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M21.2023 10.2L21.0713 10.3375V13.6625L21.2023 13.8H24.3692L24.5001 13.6625V10.3375L24.3692 10.2H21.2023Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M7.48814 3L7.35718 3.1375V6.4625L7.48814 6.6H10.655L10.786 6.4625V3.1375L10.655 3H7.48814Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M10.9166 3L10.7856 3.1375V6.4625L10.9166 6.6H14.0835L14.2145 6.4625V3.1375L14.0835 3H10.9166Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M14.3451 3L14.2141 3.1375V6.4625L14.3451 6.6H17.512L17.6429 6.4625V3.1375L17.512 3H14.3451Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M17.7735 3L17.6426 3.1375V6.4625L17.7735 6.6H20.9404L21.0714 6.4625V3.1375L20.9404 3H17.7735Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M0.630962 13.8L0.5 13.9375V17.2625L0.630962 17.4H3.79787L3.92883 17.2625V13.9375L3.79787 13.8H0.630962Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M21.2023 13.8L21.0713 13.9375V17.2625L21.2023 17.4H24.3692L24.5001 17.2625V13.9375L24.3692 13.8H21.2023Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M7.48814 17.3999L7.35718 17.5374V20.8624L7.48814 20.9999H10.655L10.786 20.8624V17.5374L10.655 17.3999H7.48814Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M10.9166 17.3999L10.7856 17.5374V20.8624L10.9166 20.9999H14.0835L14.2145 20.8624V17.5374L14.0835 17.3999H10.9166Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M14.3451 17.3999L14.2141 17.5374V20.8624L14.3451 20.9999H17.512L17.6429 20.8624V17.5374L17.512 17.3999H14.3451Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M17.7735 17.3999L17.6426 17.5374V20.8624L17.7735 20.9999H20.9404L21.0714 20.8624V17.5374L20.9404 17.3999H17.7735Z"
        fill={props.fill || '#707070'}
      />
    </SvgIcon>
  );
};

export const CuratorsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox={'0 0 25 24'}>
      <path
        d="M11.1807 2L11.0715 2.10913V10.4623L11.1807 10.5714H13.8198L13.9289 10.4623V2.10913L13.8198 2H11.1807Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M11.1807 13.4285L11.0715 13.5376V21.8908L11.1807 21.9999H13.8198L13.9289 21.8908V13.5376L13.8198 13.4285H11.1807Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M2.5 13.3195L2.60913 13.4287H10.9623L11.0714 13.3195V10.6804L10.9623 10.5713H2.60913L2.5 10.6804V13.3195Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M13.9285 13.3195L14.0376 13.4287H22.3908L22.4999 13.3195V10.6804L22.3908 10.5713H14.0376L13.9285 10.6804V13.3195Z"
        fill={props.fill || '#707070'}
      />
    </SvgIcon>
  );
};

export const ArtistsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox={'0 0 25 24'}>
      <path
        d="M9.29407 2L9.16675 2.12731V5.20602L9.29407 5.33333H12.373L12.5004 5.20602V2.12731L12.373 2H9.29407Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M12.6273 2L12.5 2.12731V5.20602L12.6273 5.33333H15.7063L15.8336 5.20602V2.12731L15.7063 2H12.6273Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M9.29407 12L9.16675 12.1273V15.206L9.29407 15.3333H12.373L12.5004 15.206V12.1273L12.373 12H9.29407Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M2.62733 18.6667L2.5 18.7941V21.8728L2.62733 22.0001H5.7063L5.83363 21.8728V18.7941L5.7063 18.6667H2.62733Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M5.96058 15.3333L5.83325 15.4606V18.5393L5.96058 18.6666H9.03955L9.16688 18.5393V15.4606L9.03955 15.3333H5.96058Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M22.3727 18.6667L22.5 18.7941V21.8728L22.3727 22.0001H19.2937L19.1664 21.8728V18.7941L19.2937 18.6667H22.3727Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M19.0397 15.3333L19.167 15.4606V18.5393L19.0397 18.6666H15.9607L15.8334 18.5393V15.4606L15.9607 15.3333H19.0397Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M12.6273 12L12.5 12.1273V15.206L12.6273 15.3333H15.7063L15.8336 15.206V12.1273L15.7063 12H12.6273Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M15.9606 5.33325L15.8333 5.46057V8.53927L15.9606 8.66659H19.0396L19.1669 8.53927V5.46057L19.0396 5.33325H15.9606Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M15.9606 8.66675L15.8333 8.79406V11.8728L15.9606 12.0001H19.0396L19.1669 11.8728V8.79406L19.0396 8.66675H15.9606Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M5.96058 5.33325L5.83325 5.46057V8.53927L5.96058 8.66659H9.03955L9.16688 8.53927V5.46057L9.03955 5.33325H5.96058Z"
        fill={props.fill || '#707070'}
      />
      <path
        d="M5.96058 8.66675L5.83325 8.79406V11.8728L5.96058 12.0001H9.03955L9.16688 11.8728V8.79406L9.03955 8.66675H5.96058Z"
        fill={props.fill || '#707070'}
      />
    </SvgIcon>
  );
};
