import styles from '../auth.module.scss';
import { FormControl, TextField } from '@mui/material';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import { useFormikMui } from '@acg/frontend-utils';
import { sendSignUpCode, signIn } from '@acg/auth/frontend';
import FormMessage from '../../inputs/form-message/form-message';
import { AUTH_STEPS, IFormProps } from '../components/auth-body';
import { sendResetPasswordCode } from '@acg/frontend-ui-artpool';

interface FormValues {
  email: string;
  password: string;
}
interface ErrorValues {
  serverError?: string;
  tmError?: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Enter a valid email'),
  password: Yup.string().required('Password is required'),
});

export const AuthLoginForm = ({
  setUser,
  setAuthStep,
  timeOutError,
  artcuratorgridApi,
}: IFormProps) => {
  const { formik, formikProps } = useFormikMui<FormValues & ErrorValues>({
    initialValues: {
      email: '',
      password: '',
    },
    initialErrors: {
      serverError: '',
      tmError: timeOutError,
    },
    validationSchema,
    onSubmit: (values) => submitLogin(values, true),
  });

  const { serverError, tmError } = formik.errors;

  /**
   * @return true if the error was handled
   */
  const attemptHandelUserNotFound = async (
    values: FormValues
  ): Promise<boolean> => {
    const { email, password } = values;
    const migrationResp = await artcuratorgridApi
      .authMigrationApi()
      .attemptMigrate(email, password);
    const { shouldRetryLogin, mustResetPassword } = migrationResp.data;

    if (mustResetPassword) {
      setUser((user) => ({ ...user, username: email }));
      await sendResetPasswordCode(artcuratorgridApi, email);
      setAuthStep(AUTH_STEPS.MUST_RESET_PASSWORD);
      return true;
    } else if (shouldRetryLogin) {
      await submitLogin(values, false);
      return true;
    }

    return false;
  };

  const submitLogin = async (values: FormValues, attemptMigrate: boolean) => {
    const { email, password } = values;
    try {
      await signIn(email, password);
    } catch (error: any) {
      if (
        attemptMigrate &&
        error.code === 'UserNotFoundException' &&
        (await attemptHandelUserNotFound(values))
      ) {
        return;
      }

      if (error.code === 'UserNotConfirmedException') {
        await sendConfirmationCode(email);
      }

      formik.setFieldError('serverError', error.message);
    }
  };

  async function sendConfirmationCode(userName: string) {
    try {
      await sendSignUpCode(userName);

      setUser((user) => ({ ...user, username: userName }));
      setAuthStep(AUTH_STEPS.CONFIRM_SIGNUP);
    } catch (error: any) {
      formik.setFieldError('serverError', error.message);
    }
  }

  const goToForgotPassword = () => {
    setAuthStep(AUTH_STEPS.FORGOT_PASSWORD);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <FormControl fullWidth>
            <TextField
              {...formikProps('email')}
              label="Email"
              type="email"
              color={'secondary'}
            />
          </FormControl>
        </Grid>
        <Grid item={true} xs={12}>
          <FormControl fullWidth>
            <TextField
              {...formikProps('password')}
              label="Password"
              type={'password'}
              color={'secondary'}
            />
          </FormControl>
        </Grid>
        <FormMessage
          visible={!!tmError || !!serverError}
          message={!!tmError ? tmError : serverError}
        />
        <Grid item={true} xs={12}>
          <button
            className={styles.buttonPrimary}
            type="submit"
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? 'Signing In' : 'Sign In'}
          </button>
        </Grid>
        <Grid item={true} xs={12}>
          <button className={styles.link} onClick={goToForgotPassword}>
            Forgot your password?
          </button>
        </Grid>
      </Grid>
    </form>
  );
};
