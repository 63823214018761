import { environment } from '../environments/environment';

const { REACT_APP_CLOUDFRONT_URL } = environment;

export const getImageUrl = (path?: string): string => {
  if (!path) {
    return '';
  }
  if (path.includes('http')) {
    return path;
  }
  const newPath = path.startsWith('http')
    ? path
    : path.startsWith('/')
    ? path
    : '/' + path;

  // window.console.log(`getImageUrl REACT_APP_CLOUDFRONT_URL - ${process.env.REACT_APP_CLOUDFRONT_URL}${newPath}`)
  return `${REACT_APP_CLOUDFRONT_URL}${newPath}`;
};

export const getMediumImageUrl = (path?: string): string => {
  if (!path) {
    return '';
  }

  let newPath = path.startsWith('http')
    ? path
    : path.startsWith('/')
    ? path
    : '/' + path;

  // gif files are only saved with original size in our database
  if (path.includes('.gif')) {
    newPath = path;
  } else {
    if (path.includes('-lg')) {
      newPath = path.replace('-lg', '-md');
    }
    if (path.includes('-original')) {
      newPath = path.replace('-original', '-md');
    }
  }

  if (newPath.includes('http')) {
    return newPath;
  }
  // window.console.log(`getMediumImageUrl REACT_APP_CLOUDFRONT_URL - ${process.env.REACT_APP_CLOUDFRONT_URL}${newPath}`)
  return `${REACT_APP_CLOUDFRONT_URL}${newPath}`;
};

export const getSmallmageUrl = (path?: string): string => {
  if (!path) {
    return '';
  }
  let newPath = path.startsWith('http')
    ? path
    : path.startsWith('/')
    ? path
    : '/' + path;

  // gif files are only saved with original size in our database
  if (path.includes('.gif')) {
    newPath = path;
  } else {
    if (path.includes('-lg')) {
      newPath = path.replace('-lg', '-sm');
    }
    if (path.includes('-original')) {
      newPath = path.replace('-original', '-sm');
    }
  }

  if (newPath.includes('http')) {
    return newPath;
  }
  return `${REACT_APP_CLOUDFRONT_URL}${newPath}`;
};

export const getOriginalImageUrl = (path?: string): string => {
  if (!path) {
    return '';
  }
  let newPath = path.startsWith('http')
    ? path
    : path.startsWith('/')
    ? path
    : '/' + path;

  // gif files are only saved with original size in our database
  if (path.includes('.gif')) {
    newPath = path;
  } else {
    if (path.includes('-lg')) {
      newPath = path.replace('-lg', '-original');
    }
    if (path.includes('-md')) {
      newPath = path.replace('-md', '-original');
    }
    if (path.includes('-sm')) {
      newPath = path.replace('-sm', '-original');
    }
    if (path.includes('-original')) {
      newPath = path.replace('-original', '-original');
    }
  }

  if (newPath.includes('http')) {
    return newPath;
  }
  return `${REACT_APP_CLOUDFRONT_URL}${newPath}`;
};
