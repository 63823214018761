import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';

import { withAccount, IWithAccountProps } from '../../account/InjectAccount';
import { Grid } from '@mui/material';
import { IRegisterInstitutionRequest } from '../../../services/BackOfficeServices';
import {
  generateValidator,
  IValidationConfig,
} from '../../../utils/formatters';
import ACGModalContent from '../../../components/modal/ACGModalContent';
import { Field, Formik, FormikHelpers, FormikProps } from 'formik';
import FormMessage from '../../../components/form/FormMessage';
import ProfileUpload from '../../../components/form/ProfileUpload';
import ImageUpload from '../../../components/form/ImageUpload';
import TextInput from '../../../components/form/TextInput';
import SelectInput from '../../../components/form/SelectInput';
import LocationFinder from '../../../components/form/LocationFinder';
import FormUserNameWrapper from '../../../components/form/FormUserNameWrapper';
import { IInstitution } from '../../../types/institution';
import { PRIMARY_COLOR } from '../../../styles/constants';
import FormTagWrapper from '../../tags/FormTagWrapper';
import RadioInput from '../../../components/form/RadioInput';
import { useDispatch, useSelector } from 'react-redux';
import * as institutionActions from '../../../store/modules/institutions/actions';
import { useEffect, useState } from 'react';
import { IStoreState } from '../../../store';
import DesignedButton from '../../../library/inputs/DesignedButton';

export interface ICreateOrEditInstitutionModalProps {
  onClickAway: () => void;
  institution?: IInstitution;
  isEdit?: boolean;
  onCreateOrUpdate: (institution?: IInstitution) => void;
  isBackOffice: boolean;
}

type Props = ICreateOrEditInstitutionModalProps &
  IWithAccountProps &
  WithStyles<string>;

type IInitialValues = IRegisterInstitutionRequest;

const style = createStyles({
  featuredImage: {
    position: 'absolute',
    top: 0,
    height: 260,
    width: 360,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1,
  },

  profileHeadingViewer: {
    color: 'rgba(0, 0, 0, 0.84)',
    fontSize: '0.875rem',
    paddingTop: 16,
  },

  text: {
    color: 'rgba(0, 0, 0, 0.64)',
    fontSize: '0.9em',
  },

  details: {
    alignItems: 'center',
    paddingLeft: 15,
  },
});

const CreateOrEditInstitutionModal: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const { currentInstitution } = useSelector(
    (state: IStoreState) => state.institutions
  );
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const {
    institution,
    // onCreateOrUpdate,
    onClickAway,
    onCreateOrUpdate,
    classes,
    isBackOffice,
  } = props;

  // Submiting form data
  const onSubmit = async (
    values: IInitialValues,
    actions: FormikHelpers<IInitialValues>
  ) => {
    setError(false);
    setSuccess(false);

    try {
      const payload: IRegisterInstitutionRequest = {
        ...values,
        tags: values.tags.map((t) => t.id) as any,
      };

      if (institution) {
        dispatch(institutionActions.updateInstitution(payload, isBackOffice));

        setError(false);
        setSuccess(true);
      } else {
        dispatch(institutionActions.createInstitution(payload));

        setError(false);
        setSuccess(true);
      }
    } catch (err) {
      setError(true);
      setSuccess(false);
    }
    actions.setSubmitting(false);
  };

  useEffect(() => {
    if (currentInstitution) {
      onCreateOrUpdate(currentInstitution);
      dispatch(institutionActions.resetCurrentInstitution());
      setError(false);
      setSuccess(true);
    }
  });

  const renderForm = (formProps: FormikProps<IInitialValues>) => {
    const { values } = formProps;

    if (institution) {
      values.location.value = values.location.address;
    }

    return (
      <form onSubmit={formProps.handleSubmit}>
        <Grid container={true} spacing={1}>
          <h2 style={{ color: PRIMARY_COLOR }}>
            {institution ? 'Edit Venue' : 'Create Venue'}
          </h2>
        </Grid>
        <Grid container={true} spacing={1}>
          {success && (
            <FormMessage
              message={'Venue updated.'}
              visible={!!success}
              type={'SUCCESS'}
            />
          )}
          {!!error && (
            <FormMessage
              message={'There is an unexpected error, please try again.'}
              visible={!!error}
              type={'ERROR'}
            />
          )}
          <Grid item={true} xs={12}>
            <Field
              name="profilePicture"
              component={ProfileUpload}
              label={'upload profile picture'}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              name="name"
              component={TextInput}
              newTextInput={true}
              naked={true}
              disabled={true}
              inputProps={{
                id: 'name',
                label: 'Venue Name',
                type: 'name',
              }}
            />
          </Grid>
          <Grid item={true} xs={12} sm={12}>
            <Field
              name="institution_type"
              component={SelectInput}
              placeholder="Institution type"
              inputLabelProps={{
                children: 'Venue type',
              }}
              options={[
                { label: 'Museum', value: 'MUSEUM' },
                {
                  label: 'Non-profit Organization',
                  value: 'NON-PROFIT_ORGANIZATION',
                },
                { label: 'Art Center', value: 'ART_CENTER' },
                { label: 'Art Foundation', value: 'ART_FOUNDATION' },
                { label: 'Artist-run space ', value: 'ARTIST-RUN_SPACE' },
                { label: 'Art Gallery ', value: 'ART_GALLERY' },
                { label: 'Art Residency', value: 'ART_RESIDENCY' },
              ]}
            />
          </Grid>

          <Grid item={true} xs={12} sm={12} md={12}>
            <Field
              name="location"
              component={LocationFinder}
              naked={true}
              newLocationFinder={true}
              inputProps={{
                id: 'location',
                label:
                  'Exact Location (please write the place name as it appears on Google Map)',
                type: 'location',
              }}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              name="city"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'city',
                label: 'City, Country',
                type: 'city',
              }}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              name="tags"
              component={FormTagWrapper}
              inputProps={{
                id: 'tags',
                label: 'Tags (you can input one or several tags)',
                type: 'tags',
              }}
              helpText={
                'Please input any keywords describing the venue, such as the art movement, stylistic elements, etc…'
              }
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              name="about"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'about',
                label: 'About',
                type: 'about',
                multiline: true,
                rows: 5,
                maxRows: 20,
              }}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              name="email"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'email',
                label: 'Contact Email',
                type: 'email',
              }}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              name="featuredPicture"
              component={ImageUpload}
              label={'Upload featured picture'}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <span>Your Website & Social Profiles:</span>
          </Grid>
          <Grid item={true} xs={12} sm={12}>
            <Field
              name="website_url"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'website_url',
                label: 'Website URL',
                type: 'website_url',
              }}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <Grid container={true} spacing={1}>
              <Grid item={true} xs={12}>
                <Field
                  name="twitter_url"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'twitter_url',
                    label: 'Twitter Account',
                    type: 'twitter_url',
                  }}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Field
                  name="facebook_url"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'facebook_url',
                    label: 'Facebook Account',
                    type: 'facebook_url',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item={true} xs={12} md={6}>
            <Grid container={true} spacing={1}>
              <Grid item={true} xs={12}>
                <Field
                  name="instagram_url"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'instagram_url',
                    label: 'Instagram Account',
                    type: 'instagram_url',
                  }}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Field
                  name="linkedin_url"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'linkedin_url',
                    label: 'Linkedin Account',
                    type: 'linkedin_url',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container={true} xs={12} className={classes.details}>
            <Grid item={true} xs={8}>
              <span>Venue Profile Settings</span>
            </Grid>
            <Grid
              container={true}
              xs={12}
              direction="row"
              className={classes.text}
            >
              <Grid item={true} xs={4} className={classes.profileHeading}>
                Your profile’s public visibility:
              </Grid>
              <Grid item={true} xs={8}>
                <Field
                  name="institution_profile_type"
                  component={RadioInput}
                  inputProps={{
                    id: 'institution_profile_type',
                    label: 'Your profile’s public visibility',
                    type: 'institution_profile_type',
                  }}
                  options={[
                    {
                      label: 'Public (recommended)',
                      value: 'PUBLIC',
                      helper:
                        'Build your portfolio, share it and make it visible on search results (Google, Bing…)',
                    },
                    {
                      label: 'Artpool members only',
                      value: 'PRIVATE',
                    },
                  ]}
                />
              </Grid>
            </Grid>

            <Grid item={true} xs={8}>
              <span>Profile Managed By:</span>
            </Grid>
            <Grid item={true} xs={12}>
              <Field
                name={'managedBy'}
                component={FormUserNameWrapper}
                inputProps={{
                  id: 'managedBy',
                  label: 'Managed by:',
                  type: 'users',
                }}
              />
            </Grid>
          </Grid>
          {/* Buttons sections */}
          <Grid xs={12} container={true} spacing={1}>
            <Grid item={true} xs={6}>
              <DesignedButton
                fullWidth={true}
                size="large"
                ACGType="neutral"
                disabled={formProps.isSubmitting}
                onClick={onClickAway}
              >
                Cancel
              </DesignedButton>
            </Grid>

            <Grid item={true} xs={6}>
              <DesignedButton
                fullWidth={true}
                size="large"
                ACGType="main"
                type={'submit'}
                disabled={formProps.isSubmitting}
              >
                Save your changes
              </DesignedButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  };

  const initialValues: IRegisterInstitutionRequest = {
    name: '',
    email: '',
    profilePicture: '',
    featuredPicture: '',
    about: '',
    city: '',
    location: {
      address: '',
      value: '',
      placeId: '',
      latLng: [0, 0],
      country: '',
      city: '',
      state: '',
    },
    instagram_url: '',
    linkedin_url: '',
    website_url: '',
    twitter_url: '',
    facebook_url: '',
    url: '',
    institution_profile_type: 'PUBLIC',
    institution_type: 'MUSEUM',
    tags: [],
    managedBy: undefined,
  };

  const config: IValidationConfig = {
    email: ['REQUIRED'],
    name: ['REQUIRED'],
    managedBy: ['REQUIRED'],
  };

  const validate = generateValidator(config);

  return (
    <ACGModalContent>
      <Formik
        initialValues={institution ?? (initialValues as any)}
        validate={validate}
        onSubmit={onSubmit}
        render={renderForm}
      />
    </ACGModalContent>
  );
};

export default compose<Props, ICreateOrEditInstitutionModalProps>(
  withStyles(style),
  withAccount
)(CreateOrEditInstitutionModal);
