import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import {} from '@mui/material';
import { DARK_GREY_COLOR } from '../../styles/constants';
import IconACGCross from '../dataDisplay/icons/IconACGCross';

export interface IACGCommentButtonProps {
  fontSize?: number;
  isButton?: boolean;
  iconSize?: number;
}

type Props = IACGCommentButtonProps & WithStyles<string>;

const styles = createStyles({
  button: {
    border: `1px solid ${DARK_GREY_COLOR}`,
    borderRadius: 5,
    padding: 5,
    '&:hover': {
      border: 'px solid #2b2e34',
      backgroundColor: 'rgba(43, 46, 52, 0.08)',
    },
  },
  text: {
    margin: '0px 5px',
    color: 'black',
  },
  container: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
  },
});

const ACGCommentButton = (props: Props) => {
  const { classes, isButton, fontSize, iconSize } = props;

  return (
    <div className={`${isButton && classes.button} ${classes.container}`}>
      <IconACGCross height={iconSize || 20} />
      {/*<IconShare height={iconSize || 25} v2={true} fill={'black'}/>*/}
      <p className={classes.text} style={{ fontSize: fontSize || 12 }}>
        Comment
      </p>
    </div>
  );
};

export default compose<Props, IACGCommentButtonProps>(withStyles(styles))(
  ACGCommentButton
);
