import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import * as _ from 'lodash';
import UploadService from '../../services/UploadService';
import { Field, FieldArrayRenderProps, getIn } from 'formik';
import { Grid, Button, CircularProgress, Hidden } from '@mui/material';
import { compose } from 'recompose';
import upArrow from '../../img/expand_up.svg';
import downArrow from '../../img/expand_down.svg';
import { GREY_COLOR } from '../../styles/constants';
import Trash from '../icons/Trash';
// import Star from '../icons/Star';
import { getImageUrl } from '../../utils/imageUrl';
import { IImage } from '../../types/images';
import TextInput from './TextInput';
import Rotate from '../icons/Rotate';
import IconUpload from '../../library/dataDisplay/icons/iconUpload';

export interface IMultipleFilesUploadProps extends FieldArrayRenderProps {
  label?: string;
}

interface IState {
  error?: any;
  file?: File;
  uploading: boolean;
  rotating: boolean;
  rotatingId: number;
}

const style = createStyles({
  container: {
    paddingTop: '20px',
  },
  root: {
    position: 'relative',
    '&:hover overlay': {
      backgroundColor: 'black',
      opacity: 1,
    },
  },
  overlay: {
    textAlign: 'center',
    marginLeft: 10,
  },
  button: {
    backgroundColor: GREY_COLOR,
    border: '1px dashed rgba(43, 46, 52, 0.5)',
    padding: '8px 0',
    boxShadow: 'none',
    textTransform: 'none',
  },
  buttonWithError: {
    color: 'red',
  },
  errorMessage: {
    color: '#f44336',
    marginLeft: 14,
    marginRight: 14,
    fontSize: '0.75rem',
    marginTop: 3,
    textAlign: 'left',
    fontFamily: 'Replica-Regular',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  imageRow: {
    width: '100%',
    display: 'flex',
    alignContent: 'center',
  },

  paddingDown: {
    paddingDown: '10px !important',
  },
  textButton: {
    fontSize: 12,
    marginBottom: 10,
  },
  header: {
    marginBottom: 12,
  },
  headerText: {
    fontSize: 12,
    color: '#797979',
    marginBottom: 12,
  },
  arrows: {
    cursor: 'pointer',
  },
});

type Props = IMultipleFilesUploadProps & WithStyles<string>;

class MultipleFilesUpload extends React.Component<Props, IState> {
  private uploadService = new UploadService();

  constructor(props: Props) {
    super(props);
    this.state = {
      uploading: false,
      rotating: false,
      rotatingId: -1,
    };
  }

  public submitFile = (event: any) => {
    const { push } = this.props;

    event.preventDefault();

    const fileList: File[] = [];

    for (const f of event.target.files) {
      fileList.push(f);
    }
    fileList.forEach((file) => {
      const formData = new FormData();
      formData.append('file', file);
      this.setState({ uploading: true });
      this.uploadService
        .uploadFile(formData)
        .then((response) => {
          this.setState({ uploading: false });
          // push(`/${response.data.Key}`);
          push({
            image_url: `/${response.data.Key}`,
            image_credit: '',
            id: -1,
          });
        })
        .catch((error) => {
          this.setState({ uploading: false });
          this.setState({ error });
        });
    });
  };

  public onClickRotate = (index: number, filename: string) => {
    const {
      form: { values },
      name,
    } = this.props;

    this.setState({ rotating: true, rotatingId: index });
    this.uploadService
      .rotateFile(filename)
      .then((response) => {
        let fileUploadValues = values[name];

        if (!fileUploadValues) {
          fileUploadValues = _.get(values, name);
        }

        fileUploadValues[index].image_url =
          response.data[0].body.updateFileName;

        this.setState({ rotating: false, rotatingId: -1 });
      })
      .catch((error) => {
        this.setState({ rotating: false, rotatingId: -1 });
        this.setState({ error });
      });
  };

  public render() {
    const {
      form: {
        values,
        errors,
        // touched
      },
      name,
      remove,
      classes,
      move,
      label,
    } = this.props;

    const error = getIn(errors, name);
    // const touch = getIn(touched, name);

    let fileUploadValues = values[name];

    if (!fileUploadValues) {
      fileUploadValues = _.get(values, name);
    }

    return (
      <React.Fragment>
        <div className={classes.container}>
          <Grid container={true}>
            <Grid
              item={true}
              xs={12}
              key={'header'}
              className={classes.header}
              // wrap="nowrap"
            >
              <span className={classes.headerText}>
                Select the order with the up and down buttons:
              </span>
            </Grid>

            {fileUploadValues &&
              fileUploadValues.map((elem: IImage, i: number) => {
                const onClick = () => remove(i);
                // const onClickOnFeature = () => move(i, 0);

                const onClickUpFeature = () => move(i, i - 1);
                const onClickDownFeature = () => move(i, i + 1);
                const onClickRotate = () =>
                  this.onClickRotate(i, elem.image_url);

                elem.id = i;

                const totalElements = fileUploadValues.length;

                return (
                  <Grid
                    item={true}
                    xs={12}
                    key={'image_' + i}
                    className={classes.root}
                    // wrap="nowrap"
                  >
                    <Grid
                      container={true}
                      // xs={12}
                      className={classes.imageRow}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item={true} xs={12} sm={3}>
                        {this.state.rotatingId === i && this.state.rotating ? (
                          <CircularProgress />
                        ) : (
                          <React.Fragment>
                            <img
                              style={{ maxWidth: '100%' }}
                              src={getImageUrl(elem.image_url)}
                            />
                          </React.Fragment>
                        )}
                      </Grid>
                      <Grid
                        item={true}
                        xs={2}
                        sm={1}
                        className={classes.overlay}
                      >
                        <Grid
                          container={true}
                          direction={'column'}
                          // xs={12}
                        >
                          <Grid
                            item={true}
                            xs={12}
                            className={classes.header}
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <span onClick={onClickRotate}>
                              <Rotate big={true} />
                            </span>
                            <span className={classes.textButton}>Rotate</span>
                          </Grid>

                          <Grid
                            item={true}
                            xs={12}
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <span onClick={onClick}>
                              <Trash big={true} />
                            </span>
                            <span className={classes.textButton}>Delete</span>
                          </Grid>
                        </Grid>

                        {/* <span onClick={onClick} className={classes.overlay}><Rotate big={true} /></span> */}
                      </Grid>
                      <Grid
                        item={true}
                        xs={2}
                        sm={1}
                        className={classes.overlay}
                      >
                        <Grid
                          container={true}
                          // xs={12}
                          direction={'column'}
                        >
                          <Grid item={true} xs={12}>
                            {i !== 0 && (
                              <span
                                onClick={onClickUpFeature}
                                className={classes.arrows}
                              >
                                <img src={upArrow} />
                              </span>
                            )}
                          </Grid>
                          {/*<Grid item={true} xs={12}>*/}
                          {/*    <span onClick={onClickOnFeature}>*/}
                          {/*        <Star filled={i === 0 ? true : false} big={true}/>*/}
                          {/*    </span>*/}
                          {/*</Grid>*/}
                          <Grid item={true} xs={12}>
                            {totalElements - 1 !== i && (
                              <span
                                onClick={onClickDownFeature}
                                className={classes.arrows}
                              >
                                <img src={downArrow} />
                              </span>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Hidden only={'xs'}>
                        <Grid item={true} xs={false} sm={6}>
                          <Field
                            name={`${name}.${i}.image_credit`}
                            value={elem.image_credit}
                            component={TextInput}
                            newTextInput={true}
                            naked={true}
                            inputProps={{
                              id: `${name}.${i}.image_credit`,
                              label: 'Photo credit*',
                              type: 'image_credit',
                            }}
                            className={classes.paddingDown}
                          />
                        </Grid>
                      </Hidden>
                    </Grid>
                    <Hidden smUp={true}>
                      <Grid
                        container={true}
                        // xs={12}
                        // sm={false}
                        className={classes.ShowMobileRow}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item={true} xs={12}>
                          <Field
                            name={`${name}.${i}.image_credit`}
                            value={elem.image_credit}
                            component={TextInput}
                            inputProps={{
                              id: `${name}.${i}.image_credit`,
                              label: 'Photo credit*',
                              type: 'image_credit',
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Hidden>
                  </Grid>
                );
              })}
          </Grid>

          <Button
            variant="contained"
            component="label"
            fullWidth={true}
            className={`${classes.button} ${error && classes.buttonWithError}`}
          >
            {this.state.uploading ? (
              <CircularProgress />
            ) : (
              <React.Fragment>
                <IconUpload />
                {label ? label : 'Upload your images'}
                <input
                  id={`file_${name}`}
                  name={`file_${name}`}
                  type="file"
                  multiple={true}
                  onChange={this.submitFile}
                  style={{ display: 'none' }}
                  accept="image/*"
                />
              </React.Fragment>
            )}
          </Button>

          {error && (
            <p key={name + '-helper-text'} className={classes.errorMessage}>
              {/* error */}
              Required
            </p>
          )}
          {/* error && touch && typeof errors[name] === 'string' ? <div>{error}</div> : undefined */}
        </div>
      </React.Fragment>
    );
  }
}

export default compose<Props, IMultipleFilesUploadProps>(withStyles(style))(
  MultipleFilesUpload
);
