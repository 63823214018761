import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import PostsGrid from '../../components/posts/PostsGrid';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import VisitorService from '../../services/VisitorServices';
import { VisitorPostModal } from './VisitorPostModal';
import { Grid } from '@mui/material';
import PaddedLayout from '../../library/layout/container/PaddedLayout';
import { IPost } from '../../types/posts';
import AddPostForm from '../post/AddPostForm';
import { ComponentType } from 'react';

export interface IVisitorPostsProps {
  onClickOnAction: () => void;
  forceLogin?: boolean;
  selectedTab?: string;
  noTopMargin?: boolean;
  venueId?: string;
  noProfile?: boolean;
}

type Props = IVisitorPostsProps &
  RouteComponentProps<{ userId: string; postId?: string }> &
  WithStyles<string>;

interface IState {
  open: boolean;
}

const style = createStyles({
  root: {
    flexGrow: 1,
  },
  backdrop: {
    backgroundColor: 'rgba(242,242,242,0.92)',
  },
});

const INITIAL_PAGE_SIZE = 12;

class VisitorPosts extends React.Component<Props, IState> {
  private visitorService = new VisitorService();

  constructor(props: Props) {
    super(props);
    this.state = {
      open: props.match.params.postId ? true : false,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    const {
      match: {
        params: { postId },
      },
    } = this.props;

    const {
      match: {
        params: { postId: prevPostId },
      },
    } = prevProps;

    if (!prevPostId && postId) {
      this.setState({ open: true });
    }

    if (prevPostId && !postId) {
      this.setState({ open: false });
    }

    // if (prevPostId !== postId) {
    //   // load data
    // }
  }

  public getData = (pageNumber = 0) => {
    const {
      match: {
        params: { userId },
      },
      location: {
        // pathname
      },
      selectedTab,
      venueId,
    } = this.props;

    if (selectedTab) {
      if (userId) {
        return this.visitorService.getPublicUserPosts(userId, {
          first_result: INITIAL_PAGE_SIZE * pageNumber,
          max_results: INITIAL_PAGE_SIZE,
          sub_type: selectedTab,
          status: 'PUBLISHED',
          venueId,
        });
      } else {
        return this.visitorService.getPublicPosts(userId, {
          first_result: INITIAL_PAGE_SIZE * pageNumber,
          max_results: INITIAL_PAGE_SIZE,
          sub_type: selectedTab,
          status: 'PUBLISHED',
          venueId,
        });
      }
    }

    if (userId) {
      return this.visitorService.getPublicUserPosts(userId, {
        first_result: INITIAL_PAGE_SIZE * pageNumber,
        max_results: INITIAL_PAGE_SIZE,
        status: 'PUBLISHED',
        venueId,
      });
    }
    return this.visitorService.getPublicPosts(userId, {
      first_result: INITIAL_PAGE_SIZE * pageNumber,
      max_results: INITIAL_PAGE_SIZE,
      status: 'PUBLISHED',
      venueId,
    });
  };

  public handleClose = (): void => {
    const {
      history,
      match: {
        params: { userId },
      },
      venueId,
    } = this.props;
    if (venueId) {
      history.push(`/venues/${venueId}/posts`);
    } else {
      history.push(`/users/${userId}/posts`);
    }
  };

  public render() {
    const {
      history,
      classes,
      match: {
        params: { userId },
      },
      selectedTab,
      noTopMargin,
      venueId,
      forceLogin,
    } = this.props;

    const { open } = this.state;

    const onPostClick = (postId: string, postType: string) => {
      if (postType === 'ONLINEEXHIBITION') {
        history.push(`/online-exhibition/${postId}`);
      } else if (forceLogin) {
        history.push(`/login`);
      } else if (venueId) {
        history.push(`/venues/${venueId}/posts/${postId}`);
      } else {
        history.push(`/users/${userId}/posts/${postId}`);
      }
    };
    const onPageChange = (pageNumber: number) => ({});

    const onLike = (post: IPost) => {
      this.props.onClickOnAction();
      return Promise.resolve({ status: 'ok', data: post } as any);
    };

    const onComment = () => {
      this.props.onClickOnAction();
    };

    const isLiking = (post: IPost) => false;

    let content: ComponentType<any> = AddPostForm;
    if (open) {
      content = VisitorPostModal;
    }

    const onShareClick = () => this.props.onClickOnAction();

    return (
      <PaddedLayout noPadded={noTopMargin}>
        <Grid container={true} className={classes.root}>
          <PostsGrid
            forceLogin={forceLogin}
            noProfile={this.props.noProfile}
            showAddButton={false}
            loadPosts={this.getData}
            pageSize={INITIAL_PAGE_SIZE}
            offset={0}
            onPageChange={onPageChange}
            onPostClick={onPostClick}
            onLike={onLike}
            isLiking={isLiking}
            modalComponent={content as React.ComponentClass<any>}
            showModal={open}
            onCloseModal={this.handleClose}
            onCommentClick={onComment}
            onShareClick={onShareClick}
            selectedTab={selectedTab}
          />
        </Grid>
      </PaddedLayout>
    );
  }
}

export default compose<Props, IVisitorPostsProps>(
  withRouter,
  withStyles(style)
)(VisitorPosts);
