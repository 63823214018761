import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import * as _ from 'lodash';
import { Grid } from '@mui/material';
import { compose } from 'recompose';
import {
  InjectedFormikProps,
  Formik,
  FormikHelpers,
  FormikProps,
  FieldArray,
  Field,
} from 'formik';
import PostService from '../../services/PostService';
import FileUpload from '../../components/form/MultipleFilesUpload';
import { IPost, PostTypeOptions } from '../../types/posts';
import TextInput from '../../components/form/TextInput';
import { IValidationConfig, generateValidator } from '../../utils/formatters';
import FormMessage from '../../components/form/FormMessage';
import FormSubmit from '../../components/form/FormSubmit';
import FormCancel from '../../components/form/FormCancel';
import { ITag } from '../../types/tags';
import FormTagWrapper from '../tags/FormTagWrapper';
import { IImage } from '../../types/images';
import { ILink } from '../../types/links';
import { IVideoLink } from '../../types/videos';
import LinkInput from '../../components/form/LinkInput';
import VideoLinkInput from '../../components/form/VideoLinkInput';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import SocialModal from '../social/SocialModal';
import SelectInput from '../../components/form/SelectInput';
import { checkRight } from '../../utils/userUtils';
import FormUserNameWrapper from '../../components/form/FormUserNameWrapper';
import { ICuratorUser } from '../../types/user';
import FormArtistNameWrapper from '../../components/form/FormArtistNameWrapper';
import ArtistEmailInput from '../../components/form/ArtistEmailInput';

export interface IInspirationFormProps {
  onDone: () => void;
  onCreate: (post: IPost) => void;
  onUpdate: (post: IPost) => void;
  initialValues?: IInspirationInitialValue;
  subType?: string;
}

const style = createStyles({
  root: {},
  headerSection: {
    fontSize: 24,
    textAlign: 'center',
    letterSpacing: -0.24,
  },
});

export interface IInspirationInitialValue {
  description: string;
  curated_by?: any[];
  created_by?: any;
  artists: any[];
  existing_artists?: any[];
  start_date?: string;
  images: string[];
  title: string;
  id?: string;
  tags: ITag[];
  imagesWithCredit: IImage[];
  links: ILink[];
  videos: IVideoLink[];

  status: string;
  link_title?: string;
  link_url?: string;

  video_title?: string;
  video_url?: string;
  sub_type?: string;

  venue_id?: any;
}

/*
const initialValues: IInspirationInitialValue = {
  description: '',
  title: '',
  images: [],
  tags: [],
  imagesWithCredit: [],

  links: [],
  videos:[],

  link_url: "",
  link_title: "",
}
*/

type Props = WithStyles<string> &
  InjectedFormikProps<IInspirationFormProps, IInspirationInitialValue> &
  IWithAccountProps;

interface IState {
  error: boolean;
  shareDialog: boolean;
  savedPost?: IPost;
}

class InspirationForm extends React.Component<Props, IState> {
  private postService = new PostService();

  constructor(props: Props) {
    super(props);
    this.state = {
      error: false,
      shareDialog: false,
    };
  }

  public addLinksToPost = (
    typeArray: string,
    theArray: any,
    theUrl: string,
    theTitle?: string
  ) => {
    const regexURLFormula =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

    if (!regexURLFormula.test(theUrl)) {
      return false;
    } else {
      if (typeArray === 'links') {
        const linkList = {
          link_title: theTitle,
          link_url: theUrl,
        };

        theArray.push(linkList);
      } else {
        const linkList = {
          video_title: theTitle,
          video_link: theUrl,
        };

        theArray.push(linkList);
      }

      return true;
    }
  };

  public onSubmit = async (
    values: IInspirationInitialValue,
    actions: FormikHelpers<IInspirationInitialValue>
  ) => {
    this.setState({ error: false });
    const { account } = this.props;

    const { initialValues: propsInitialValues } = this.props;
    try {
      if (values.status === 'DRAFT') {
        if (values.description === '') {
          values.description = ' ';
        }
        if (values.title === '') {
          values.title = ' ';
        }
      } else {
        values.description = values.description.trim();
        values.title = values.title.trim();
      }

      const artistList = _.uniqBy(values.artists, '_id');

      const curatedByList = values.curated_by
        ? values.curated_by.map((curator: any) => {
            return curator._id;
          })
        : [];

      if (values.imagesWithCredit.length > 0) {
        const [featuredImage] = values.imagesWithCredit;
        const payload: Partial<IPost> = {
          ...values,
          artists_list: artistList,
          curated_by: curatedByList,
          tags: values.tags.map((t) => t.id) as any,
          featured_image: featuredImage ? featuredImage.image_url : ' ',
          featured_imageWithCredit: featuredImage,
          // images: rest,
          type: 'IMAGE',
        };

        const linkTitle = values.link_title;
        const linkUrl = values.link_url;
        if (linkUrl && linkUrl.length > 0) {
          this.addLinksToPost('links', values.links, linkUrl, linkTitle);
        }

        const videoTitle = values.video_title;
        const videoUrl = values.video_url;
        if (videoUrl && videoUrl.length > 0) {
          this.addLinksToPost('videos', values.videos, videoUrl, videoTitle);
        }

        if (propsInitialValues && propsInitialValues.id) {
          // updating image post
          const updatedImagePost = await this.postService.updatePost(
            propsInitialValues.id,
            payload
          );
          this.props.onUpdate(updatedImagePost.data);
          // this.setState({shareDialog: true, savedPost:updatedImagePost.data });
          this.props.onDone();
        } else {
          // creating image post
          const createdImagePost = await this.postService.createPost(payload);

          this.props.onCreate(createdImagePost.data);
          this.setState({
            shareDialog: true,
            savedPost: createdImagePost.data,
          });
          // this.props.onDone();
        }
      } else {
        const payload: Partial<IPost> = {
          ...values,
          artists_list: artistList,
          curated_by: curatedByList,
          tags: values.tags.map((t) => t.id) as any,
        };

        const linkTitle = values.link_title;
        const linkUrl = values.link_url;
        if (linkUrl && linkUrl.length > 0) {
          this.addLinksToPost('links', values.links, linkUrl, linkTitle);
        }

        const videoTitle = values.video_title;
        const videoUrl = values.video_url;
        if (videoUrl && videoUrl.length > 0) {
          this.addLinksToPost('videos', values.videos, videoUrl, videoTitle);
        }
        if (propsInitialValues && propsInitialValues.id) {
          // updating standard post
          const updatedStandardPost = await this.postService.updatePost(
            propsInitialValues.id,
            {
              ...payload,
              type: 'STANDARD',
            }
          );
          this.props.onUpdate(updatedStandardPost.data);
          this.setState({
            shareDialog: true,
            savedPost: updatedStandardPost.data,
          });
          // this.props.onDone();
        } else {
          // creating standard post
          const createdStandardPost = await this.postService.createPost({
            ...payload,
            type: 'STANDARD',
          });
          this.props.onCreate(createdStandardPost.data);
          this.setState({
            shareDialog: true,
            savedPost: createdStandardPost.data,
          });
          // this.props.onDone();
        }
      }
    } catch (err) {
      actions.setSubmitting(false);
      this.setState({ error: true });
    }
    actions.setSubmitting(false);
  };

  public renderForm = (props: FormikProps<IInspirationInitialValue>) => {
    const { onDone, classes, account, initialValues, subType } = this.props;

    const right = checkRight(account);

    const getPostType = () => {
      const subTypeDetails = PostTypeOptions.find((obj) => {
        return obj.id === subType;
      });

      return subTypeDetails ? subTypeDetails.text : '';
    };

    const curatedByText =
      subType === 'EXHIBITION' ||
      subType === 'ARTPROJECT' ||
      subType === 'ONLINEEXHIBITION' ||
      subType === 'PUBLICATION'
        ? 'Curated by:'
        : 'Shared by:';

    const saveAsPublished = () => {
      props.setFieldValue('status', 'PUBLISHED');
    };

    const saveAsDraft = () => {
      props.setFieldValue('status', 'DRAFT');
    };

    const getSelectVenues = (managesObj: any) => {
      const venuesOption = [];
      // const venuesOption = [{ label: '', value: '' }]

      if (right === 'ALL') {
        venuesOption.push({ label: '', value: '' });
      }
      managesObj.forEach((manage: any) => {
        venuesOption.push({ label: manage.name, value: manage._id });
      });
      return venuesOption;
    };

    let curator: ICuratorUser | undefined;
    if (!initialValues && account.profile === 'CURATOR') {
      curator = account;
    } else if (
      initialValues &&
      initialValues.created_by &&
      initialValues.created_by.profile === 'CURATOR'
    ) {
      curator = initialValues.created_by;
    } else {
      curator = undefined;
    }

    return (
      <form onSubmit={props.handleSubmit}>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12} className={classes.headerSection}>
            <p>Create Your {getPostType()} Post </p>
          </Grid>

          <FormMessage
            message={'There was an unexpected error, please try again...'}
            visible={!!this.state.error}
            type={'ERROR'}
          />

          {account.manages && account.manages.length > 0 && (
            <Grid item={true} xs={12}>
              <Field
                name="venue_id"
                placeholder={"Share on your venue's profile page:"}
                component={SelectInput}
                inputLabelProps={{
                  children: "Share on your venue's profile page:",
                }}
                options={getSelectVenues(account.manages)}
              />
            </Grid>
          )}

          <Grid item={true} xs={12}>
            <Field
              name="title"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'title',
                label: 'Title',
                type: 'title',
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              name="description"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'description',
                label: 'Description (please input your text in English)',
                type: 'description',
                multiline: true,
                rows: 5,
                maxRows: 20,
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              name={'curated_by'}
              defaultUser={curator}
              component={FormUserNameWrapper}
              inputProps={{
                id: 'curatedBy',
                label: curatedByText,
                type: 'curators',
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              name="artists"
              component={FormArtistNameWrapper}
              inputProps={{
                id: 'artists',
                label: 'Artist Name',
                type: 'artists',
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              name="start_date"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'start_date',
                label: 'Publication Date',
                type: 'date',
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              name="tags"
              component={FormTagWrapper}
              inputProps={{
                id: 'tags',
                label: 'Tags (you can input one or several tags)',
                type: 'tags',
              }}
              helpText={
                'Please input any keywords describing this inspiration, such as the curatorial theme, the art movement, stylistic elements, etc…'
              }
            />
          </Grid>

          <Grid xs={12}>
            <FieldArray
              name="links"
              component={LinkInput as unknown as React.ComponentType<any>}
            />
          </Grid>

          <Grid xs={12}>
            <FieldArray
              name="videos"
              component={VideoLinkInput as unknown as React.ComponentType<any>}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <FieldArray
              name="imagesWithCredit"
              component={FileUpload as unknown as React.ComponentType<any>}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              name="email"
              component={ArtistEmailInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'email',
                label: 'email',
                type: 'email',
              }}
            />
          </Grid>
          <Field
            id="status"
            name="status"
            type="hidden"
            inputProps={{
              id: 'status',
              type: 'status',
            }}
          />
        </Grid>
        <Grid container={true} spacing={1}>
          <Grid xs={3} item={true}>
            <FormCancel onClick={onDone} newButton={true} variant="contained">
              Cancel
            </FormCancel>
          </Grid>
          <Grid xs={3} item={true} />
          <Grid xs={3} item={true}>
            <FormSubmit
              disabled={props.isSubmitting}
              onClick={saveAsDraft}
              newButton={true}
              outlined={true}
              variant="contained"
              style={{ paddingRight: '3px' }}
            >
              {this.props.subType
                ? 'SAVE AS DRAFT'
                : props.values.status === 'DRAFT'
                ? 'SAVE AS DRAFT'
                : 'SWITCH TO DRAFT'}
            </FormSubmit>
          </Grid>
          <Grid xs={3} item={true}>
            <FormSubmit
              disabled={props.isSubmitting}
              draft={false}
              newButton={true}
              onClick={saveAsPublished}
              variant="contained"
            >
              {this.props.subType
                ? 'POST NOW!'
                : props.values.status === 'DRAFT'
                ? 'POST NOW!'
                : 'UPDATE'}
            </FormSubmit>
          </Grid>
        </Grid>
      </form>
    );
  };

  public render() {
    const { initialValues: propsInitialValues, subType, account } = this.props;

    let venueId;
    if (account.manages && account.manages.length > 0) {
      venueId = _.find(account.manages, (ele: any) => {
        if (location.pathname.indexOf(ele.url) > 0) {
          return ele._id;
        }
      });
    }
    const initialValues: IInspirationInitialValue = {
      description: '',
      title: '',
      images: [],
      tags: [],
      artists: [],
      existing_artists: [],
      imagesWithCredit: [],

      links: [],
      videos: [],

      status: 'PUBLISHED',
      link_url: '',
      link_title: '',
      sub_type: subType,
      venue_id: venueId ? venueId._id : '',
    };

    const validationRule: IValidationConfig = {
      title: ['REQUIRED'],
      description: ['REQUIRED'],
    };

    const validation = (values: IInspirationInitialValue) => {
      if (values.status === 'DRAFT') {
        return;
      } else {
        values.description = values.description.trim();
        values.title = values.title.trim();
      }

      return generateValidator(validationRule)(values);
    };

    const onShareClose = () => {
      this.setState({ shareDialog: false, savedPost: undefined });
      this.props.onDone();
    };

    if (propsInitialValues && propsInitialValues.existing_artists) {
      const artists = [
        ...propsInitialValues.existing_artists,
        ...propsInitialValues.artists,
      ];
      propsInitialValues.artists = artists;
    }

    return (
      <React.Fragment>
        <Formik
          initialValues={
            propsInitialValues ? propsInitialValues : initialValues
          }
          onSubmit={this.onSubmit}
          render={this.renderForm}
          validate={validation}
        />
        {this.state.savedPost && (
          <SocialModal
            open={this.state.shareDialog}
            onClose={onShareClose}
            object={{
              type: 'POST',
              object: this.state.savedPost,
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default compose<Props, IInspirationFormProps>(
  withStyles(style),
  withAccount
)(InspirationForm);
