import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import linkedin from '../../../../img/circles/linkedin.svg';
import linkedinGray from '../../../../img/circles/gray/linkedin.svg';
import { compose } from 'recompose';
import {} from '@mui/material';

export interface ILinkedinProps {
  fontSize?: number;
  type?: 'STANDARD' | 'GRAY';
}

type Props = ILinkedinProps & WithStyles<string>;

const styles = createStyles({
  size: {
    width: 12,
    cursor: 'pointer',
  },
});

class Linkedin extends React.Component<Props, any> {
  public render() {
    const { classes, fontSize, type } = this.props;

    return (
      <img
        src={type === 'GRAY' ? linkedinGray : linkedin}
        className={classes.size}
        style={fontSize ? { width: fontSize } : undefined}
      />
    );
  }
}

export default compose<Props, ILinkedinProps>(withStyles(styles))(Linkedin);
