import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';

export interface IContentManagerProps {
  // onClickopenConsentManager: ()=>void;
}

const styles = createStyles({});

type Props = IContentManagerProps & WithStyles<string>;

const openConsentManagerDialog = () => {
  // openConsentManager();
};

class ContentManagerView extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openConsentManager: false,
      showCookies: true,
    };
  }

  public render() {
    /*
            When the page is within a container then we override the cookies window
         */
    const location = window.location.pathname;

    const showCookies = location.indexOf('/container') > 0 ? false : true;

    return (
      <div id="target-container">
        {showCookies && <>{/*  Consent Manager UI*/}</>}

        {/*{*/}
        {/*    process.env.NODE_ENV !== 'production' &&*/}
        {/*    <button onClick={openConsentManagerDialog}>*/}
        {/*        Show cookies*/}
        {/*    </button>*/}
        {/*}*/}
      </div>
    );
  }
}

export default compose<Props, IContentManagerProps>(withStyles(styles))(
  ContentManagerView
);
