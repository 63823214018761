import * as React from 'react';
import UserService from '../../services/UserService';
import { IFollower } from '../../types/user';
import { withRouter, RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import ProfileList from '../../components/profile/ProfileList';
import ProfileLarge from '../../library/dataDisplay/profile/ProfileLarge';
import Follow from './Follow';
import { withAccount, IWithAccountProps } from '../account/InjectAccount';
import InstitutionService from '../../services/InstitutionService';
import { ACGAppPaths } from '@acg/shared/const';

export interface IFollowersProps {
  following: string[];
  institution?: boolean;
  onAddFollower: (id: string) => void;
  onRemoveFollower: (id: string) => void;
}

interface IState {}

type Props = IFollowersProps &
  RouteComponentProps<{ userId: string; institutionId: string }> &
  IWithAccountProps;

const PAGE_SIZE = 10;

class Followers extends React.Component<Props, IState> {
  private userService = new UserService();
  private institutionService = new InstitutionService();

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public fetchData = (pageNumber: number) => {
    const {
      match: {
        params: { userId, institutionId },
      },
    } = this.props;

    if (institutionId) {
      return this.institutionService.getInstitutionsFollowers(institutionId, {
        first_result: pageNumber * PAGE_SIZE,
        max_results: PAGE_SIZE,
        profile: 'CURATOR',
      });
    } else {
      return this.userService.getUserFollowers(userId, {
        first_result: pageNumber * PAGE_SIZE,
        max_results: PAGE_SIZE,
        profile: 'CURATOR',
      });
    }
  };

  public render() {
    const {
      history,
      location: { pathname },
      account,
      following,
      onRemoveFollower,
      onAddFollower,
    } = this.props;

    const renderSingle = (user: IFollower) => {
      if (!user.created_by) {
        return undefined;
      }
      const onSelect = () =>
        history.push(`/users/${user.created_by.url}/posts`, {
          from: pathname,
          selectedTab: 'POSTS',
        });
      const callback = () => {
        if (following.indexOf(user.created_by.url) > -1) {
          onRemoveFollower(user.created_by.url);
        } else {
          onAddFollower(user.created_by.url);
        }
      };
      const onTagClick = (id: string) => history.push(ACGAppPaths.ROOT, { tags: [id] });
      return (
        <React.Fragment>
          <ProfileLarge
            key={`_follower_${user.id}_`}
            onTagClick={onTagClick}
            onSelect={onSelect}
            user={user.created_by}
            additionalButton={
              account.url !== user.created_by.url ? (
                <Follow
                  user={user.created_by}
                  callback={callback}
                  isFollowing={following.indexOf(user.created_by.url) > -1}
                />
              ) : undefined
            }
          />
        </React.Fragment>
      );
    };

    return (
      <ProfileList
        loadUsers={this.fetchData}
        pageSize={PAGE_SIZE}
        renderSingle={renderSingle}
        noDataMessage={'This user does not follow any users'}
      />
    );
  }
}

export default compose<Props, IFollowersProps>(
  withAccount,
  withRouter
)(Followers);
