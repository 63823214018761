import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { WHITE } from '../../../styles/artpool/constants';

export enum CardBrand {
  VISA = 'visa',
  MASTER = 'master',
}

interface CardIconProps extends SvgIconProps {
  cardBrand?: CardBrand;
}

export const CardIcon = (props: CardIconProps) => {
  switch (props.cardBrand) {
    case CardBrand.MASTER:
      return (
        <SvgIcon {...props} viewBox={'0 0 32 22'}>
          <rect width="32" height="22" rx="2" fill="#1A1A1A" />
          <circle cx="12" cy="11" r="7" fill="#E90B27" />
          <circle cx="21" cy="11" r="7" fill="#F69E31" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.5 16.3622C18.0285 15.0781 19 13.1525 19 11.0001C19 8.84758 18.0285 6.92201 16.5 5.63794C14.9715 6.92201 14 8.84758 14 11.0001C14 13.1525 14.9715 15.0781 16.5 16.3622Z"
            fill="#FD6020"
          />
        </SvgIcon>
      );
    case CardBrand.VISA:
    default:
      return (
        <SvgIcon {...props} viewBox={'0 0 32 22'} fill={'none'}>
          <rect
            x="0.25"
            y="0.25"
            width="31.5"
            height="21.5"
            rx="1.75"
            stroke="#CCCCCC"
            stroke-width="0.5"
            fill={WHITE}
          />
          <path
            d="M21.6274 7.36865C20.9999 7.11996 20.3367 6.99516 19.6688 7.00014C17.5092 7.00014 15.9839 8.18348 15.9731 9.88215C15.9596 11.1301 17.057 11.8333 17.8871 12.2503C18.7388 12.679 19.0236 12.9492 19.0209 13.3323C19.0155 13.9152 18.3406 14.1795 17.713 14.1795C16.8505 14.1795 16.3794 14.0488 15.6546 13.7214L15.3859 13.5849L15.0795 15.5405C15.6046 15.7783 16.5508 15.9824 17.5281 16C19.8241 16 21.325 14.824 21.3426 13.0167C21.3615 12.0198 20.7676 11.2666 19.5177 10.6441C18.7577 10.2404 18.2867 9.97024 18.2867 9.56062C18.2867 9.19799 18.6902 8.81039 19.5339 8.81039C20.0953 8.79562 20.6533 8.91019 21.1712 9.1466L21.3736 9.24497L21.68 7.35544L21.6274 7.36865ZM27.2318 7.1587H25.5445C25.0194 7.1587 24.624 7.3158 24.3945 7.88251L21.1496 15.8781H23.4456L23.9045 14.5671L26.7067 14.57C26.7728 14.8769 26.9753 15.8781 26.9753 15.8781H29L27.2318 7.1587ZM12.8672 7.0853H15.0552L13.6865 15.8091H11.5012L12.8672 7.08236V7.0853ZM7.3128 11.8935L7.53957 13.1048L9.67765 7.1587H11.9952L8.55192 15.8664H6.23971L4.35 8.49327C4.31939 8.37169 4.24751 8.26728 4.14888 8.2011C3.46777 7.81841 2.74609 7.52799 2 7.33635L2.0297 7.15283H5.55132C6.02915 7.17339 6.41384 7.33635 6.54207 7.89132L7.31145 11.8979V11.8935H7.3128ZM24.5322 12.7832L25.4041 10.3432C25.3933 10.3696 25.5837 9.83958 25.6943 9.51217L25.8442 10.2653L26.3503 12.7818H24.5308V12.7832H24.5322Z"
            fill="#18216A"
          />
        </SvgIcon>
      );
  }
};
