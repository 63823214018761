import { createRouteRequest } from 'typesafe-api';
import { AuthApiClient } from '@acg/shared/api-spec-utils';
import {
  GetVenuesEndpointDef,
  GetVenuesOptions,
  getVenuesRoute,
} from '../endpoints/venue';

export class VenueClient extends AuthApiClient {
  /**
   * Get Venues
   */
  private _getVenues = createRouteRequest<GetVenuesEndpointDef>(
    this,
    getVenuesRoute
  );

  public getVenues = (options: GetVenuesOptions) =>
    this._getVenues({
      query: { search: options.search },
    });
}
