import { IPostTotals } from '../services/VisitorServices';

export const sumPostTotals = (postTotals: IPostTotals): number => {
  const keys = Object.keys(postTotals) as Array<keyof IPostTotals>;
  let total = 0;
  for (const key of keys) {
    total += postTotals[key] ?? 0;
  }
  return total;
};
