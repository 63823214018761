import * as React from 'react';
import { useState } from 'react';
import ConnectInstitutionForm from './ConnectInstitutionForm';
import { IInstitution } from '../../types/institution';
import { StyledButton } from '@acg/frontend-ui-artpool';
import { ACGModal } from '../../components/modal/ACGModal';

export interface IConnectInstitutionButtonProps {
  institution: IInstitution;
  buttonSize?: any;
}

const ConnectInstitutionButton = (props: IConnectInstitutionButtonProps) => {
  const { institution, buttonSize } = props;

  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return (
    <>
      <StyledButton
        size={buttonSize || 'large'}
        variant="outlined"
        onClick={openModal}
        fullWidth
      >
        Message
      </StyledButton>
      <ACGModal open={open} onClose={closeModal} key={'modal_service'}>
        <ConnectInstitutionForm
          onClickAway={closeModal}
          institution={institution}
        />
      </ACGModal>
    </>
  );
};

export default ConnectInstitutionButton;
