import * as React from 'react';
import { IUser } from '../types/user';
import { IPost } from '../types/posts';
import { getImageUrl } from '../utils/imageUrl';
import { IExhibition } from '../types/exhibitions';
import { IInstitution } from '../types/institution';
import { Helmet } from 'react-helmet';
import { META_DATA_APP_NAME } from '@acg/frontend-ui-artpool';

export const generateMetaForUser = (user: IUser) => {
  const title = `${user.firstName} ${user.lastName}${
    user.lastName.endsWith('s') ? "'" : "'s"
  } Curator Profile | ${META_DATA_APP_NAME}`;
  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="description"
        content={`Discover this art curator’s exhibitions, inspirations and biography on ${META_DATA_APP_NAME}. Comment, like, and share his work.`}
      />
      <meta name="twitter:card" content={getImageUrl(user.profilePicture)} />
      <meta
        name="twitter:image:alt"
        content={`Portrait of ${user.firstName} ${user.lastName}`}
      />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={getImageUrl(user.profilePicture)} />
      <meta
        property="og:description"
        content={`Discover this art curator’s exhibitions, inspirations and biography on ${META_DATA_APP_NAME}. Comment, like, and share his work.`}
      />
      <meta
        property="og:url"
        content={`https://${window.location.host}/users/${user.url}/posts`}
      />
    </Helmet>
  );
};

export const generateMetaForVenue = (venue: IInstitution) => {
  const title = `${venue.name}${
    venue.name.endsWith('s') ? "'" : "'s"
  } Art Venue Profile | ${META_DATA_APP_NAME}`;
  const type = venue.institution_type
    ? venue.institution_type.split('_').join(' ').toLowerCase()
    : undefined;
  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="description"
        content={`Discover this ${
          type || 'venue'
        }’s exhibitions, art projects, events and about on ${META_DATA_APP_NAME}. Comment, like, and share its page.`}
      />
      <meta name="twitter:card" content={getImageUrl(venue.profilePicture)} />
      <meta name="twitter:image:alt" content={`Portrait of ${venue.name}`} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={getImageUrl(venue.profilePicture)} />
      <meta
        property="og:description"
        content={`Discover this ${
          type || 'venue'
        }’s exhibitions, art projects, events and about on ${META_DATA_APP_NAME}. Comment, like, and share its page.`}
      />
      <meta
        property="og:url"
        content={`https://${window.location.host}/venues/${venue.url}/posts`}
      />
    </Helmet>
  );
};

export const generateMetaForPost = (post: IPost) => {
  const title = `${post.title} | ${post.created_by.firstName} ${
    post.created_by.lastName
  }${
    post.created_by.lastName.endsWith('s') ? "'" : "'s"
  } Curator Profile | ${META_DATA_APP_NAME}`;
  const postImage =
    post.type === 'EXHIBITION' || post.type === 'IMAGE'
      ? getImageUrl(post.featured_image)
      : getImageUrl(post.created_by.profilePicture);
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={post.description} />
      <meta name="twitter:card" content={postImage} />
      <meta
        name="twitter:image:alt"
        content={`Post of ${post.created_by.firstName} ${post.created_by.lastName}`}
      />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={postImage} />
      <meta property="og:description" content={post.description} />
      <meta
        property="og:url"
        content={`https://${window.location.host}/users/${post.created_by.url}/posts/${post.url}`}
      />
    </Helmet>
  );
};

export const generateGenericMeta = () => {
  return (
    <Helmet>
      <meta
        property="og:title"
        content={`${META_DATA_APP_NAME}: Join the community that makes exhibitions a reality`}
      />
      <meta
        property="og:description"
        content="Be part of the first digital platform that connects a global community of curators, institutions and artists for audiences to discover their exhibitions and projects."
      />
      <meta
        property="og:image"
        content={`${process.env.PUBLIC_URL}/artpool-logo/artpool-logo-green.png`}
      />
      <meta property="og:url" content="https://www.artcuratorgrid.com/" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image:alt" content="Artpool Logo" />
    </Helmet>
  );
};

export const generateMetaForOnlineExhibition = (
  onlineExhibition: IExhibition
) => {
  const createdBy = onlineExhibition.venue_id
    ? onlineExhibition.venue_id.name
    : `${onlineExhibition.created_by.firstName} ${onlineExhibition.created_by.lastName}`;
  const title = `${onlineExhibition.title} | ${createdBy} | ${META_DATA_APP_NAME}`;
  const postImage = onlineExhibition.featured_imageWithCredit
    ? getImageUrl(onlineExhibition.featured_imageWithCredit.image_url)
    : getImageUrl(onlineExhibition.created_by.profilePicture);
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={onlineExhibition.description} />
      <meta name="twitter:card" content={postImage} />
      <meta
        name="twitter:image:alt"
        content={`Post of ${onlineExhibition.created_by.firstName} ${onlineExhibition.created_by.lastName}`}
      />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={postImage} />
      <meta property="og:description" content={onlineExhibition.description} />
      <meta
        property="og:url"
        content={`https://${window.location.host}/online-exhibition/${onlineExhibition.url}`}
      />
    </Helmet>
  );
};

export const ServicesMetaData = () => {
  const title = 'Use NFTs & digital art to innovate your marketing - Artpool';
  const description =
    'The Artpool Team and our exclusive network of curators can develop your brand’s interaction with customers on a new level through digital art and blockchain technology.';
  return (
    <Helmet>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
    </Helmet>
  );
};
