import * as React from 'react';
import { CircularProgress } from '@mui/material';

export interface ILoadingProps {}

export default class Loading extends React.Component<ILoadingProps, any> {
  public render() {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
}
