import { IInstitution, IUser } from '@acg/api-interfaces';
import { ACG_URL, ArtpoolAppPaths, MembershipData } from '@acg/shared/const';
import {
  JsonImage,
  JsonNft,
  JsonProject,
  JsonUserOrVenue,
  NftEditions,
} from '@acg/artpool-api-spec';
import { getImageUrl } from './image-utilities';
import { IInstitutionModel, IUserModel } from '@acg/backend-database-models';
import { JsonPost } from '@acg/artcuratorgrid-api-spec';

export const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const OPEN_EDITION_LIMIT = 9999;

export const toJsonPost = (post: any, cloudFrontUrl: string): JsonPost => {
  const {
    featured_imageWithCredit,
    featured_image,
    title,
    url,
    number_of_likes,
    number_of_views,
    venue_id,
    created_by,
    type,
  } = post;

  const image = featured_imageWithCredit?.imageUrl || featured_image;
  const creator = venue_id
    ? venueToJsonUserOrVenue(venue_id, cloudFrontUrl)
    : userToJsonUserOrVenue(created_by, cloudFrontUrl);
  const baseUrl = `${ACG_URL}/`;
  const path =
    type === 'ONLINEEXHIBITION' ? `online-exhibition/${url}` : `posts/${url}`;

  return {
    creator,
    imageUrl: getImageUrl(cloudFrontUrl, image),
    title,
    url: baseUrl + path,
    social: { likes: number_of_likes, views: number_of_views },
  };
};

export const userToJsonUserOrVenue = (user: IUserModel, cloudFrontUrl: string): JsonUserOrVenue => {
  const { profilePicture, firstName, lastName, bio, profile, location, _id } =
    user;
  return {
    name: `${firstName} ${lastName}`,
    profilePicture: getImageUrl(cloudFrontUrl, profilePicture),
    acgPostsUrl: getACGUserPostsUrl(user),
    type: profile,
    bio: bio,
    location: location?.address,
    _id: _id.toString(),
  };
};

export const venueToJsonUserOrVenue = (
  venue: IInstitutionModel,
  cloudFrontUrl: string,
): JsonUserOrVenue => {
  const { profilePicture, name, about, location, _id } = venue;
  return {
    _id,
    name,
    type: 'Institution',
    profilePicture: getImageUrl(cloudFrontUrl, profilePicture),
    acgPostsUrl: getACGVenuePostsUrl(venue),
    bio: about,
    location: location.address,
  };
};

export const getOrganizedOrCuratedByUser = (
  project: JsonProject,
  nft: JsonNft
): JsonUserOrVenue | undefined => {
  const organizer = project.organizers[0];
  const hasVenueOrganizer = organizer && organizer.type === 'Institution';
  if (hasVenueOrganizer) {
    return project.organizers[0];
  } else if (nft?.curated_by) {
    return nft.curated_by;
  }
  return undefined;
};

export interface JsonNftAndProject {
  nft: JsonNft;
  project: JsonProject;
}

export const projectsToAvailableNftList = async (
  projects: JsonProject[]
): Promise<JsonNftAndProject[]> => {
  const nfts: JsonNftAndProject[] = [];

  for (const project of projects) {
    for (const nft of project.nfts) {
      if (!nft.soldOut) nfts.push({ nft, project });
    }
  }
  return nfts;
};

export const isLiveProject = (project: JsonProject) => {
  const now = new Date();
  const started = new Date(project.startDate) <= now;
  const finished = new Date(project.endDate) < now;
  const hasNoEnd = new Date(project.endDate).getTime() === 0;
  return started && (!finished || hasNoEnd);
};

export const curatedOrOrganizedStr = (user: JsonUserOrVenue) =>
  user.type === 'CURATOR' ? 'Curated by' : 'Organized by';

export const editionType = (editions: NftEditions) => {
  if (editions.maximum === 1) {
    return 'Unique Piece';
  } else if (editions.maximum >= OPEN_EDITION_LIMIT) {
    return 'Open Edition';
  } else if (editions.maximum) {
    return `Edition of ${editions.maximum}`;
  }
  return '';
};

export const editionStr = (
  nft: JsonNft,
  project: JsonProject | undefined
): string => {
  const membershipProject = nft.projectId === MembershipData.PROJECT_ID;
  const { sold, maximum } = nft.editions;

  if (sold >= maximum) {
    return '';
  }

  if (maximum >= OPEN_EDITION_LIMIT) {
    return `Open Editions`;
  }

  const maxStr = membershipProject
    ? MembershipData.EDITIONS.toFixed(0)
    : maximum.toFixed(0);
  const editionNumber = sold + 1;
  const soldStr = editionNumber.toFixed(0);
  const paddedSold = String(soldStr).padStart(maxStr.length, '0');
  const showNumSold =
    !membershipProject && ((project && isLiveProject(project)) || !project);
  return `Edition${showNumSold ? ` ${paddedSold}` : ''} of ${maxStr}`;
};

export function getACGUserPostsUrl(user: IUser): string {
  return `${ACG_URL}/users/${user.url}/posts`;
}

export function getACGVenuePostsUrl(venue: IInstitution): string {
  return `${ACG_URL}/venues/${venue.url}/posts`;
}

export function getNftCollectionPageUrl(
  projectId: string,
  nftId?: string
): string {
  const nftIdParam = nftId ? `&nftId=${nftId}` : '';
  return `${ArtpoolAppPaths.NFT_COLLECTION}?projectId=${projectId}${nftIdParam}`;
}

export function getCheckoutPageUrl(projectId: string, nftId: string): string {
  return `${ArtpoolAppPaths.CHECKOUT}?projectId=${projectId}&nftId=${nftId}`;
}

export function getMembershipCheckoutPageUrl(): string {
  return getCheckoutPageUrl(MembershipData.PROJECT_ID, MembershipData.NFT_ID);
}

export const getLandingPageSectionUrl = (section: string): string => {
  return `/?section=${section}`;
};

export function getFundraisingProjectUrl(projectId: string): string {
  if (projectId === MembershipData.PROJECT_ID) {
    return `${ArtpoolAppPaths.MEMBERSHIP}`;
  }
  return `${ArtpoolAppPaths.FUNDRAISING_PROJECT}?projectId=${projectId}`;
}

export function getProjectCreationPage(
  projectId: string,
  section: string = 'project'
): string {
  return `${ArtpoolAppPaths.PROJECT_CREATION}?projectId=${projectId}&section=${section}`;
}

export function format3digitNumber(number: number): any {
  if (number < 10) {
    return '00' + number;
  } else if (number < 100) {
    return '0' + number;
  } else return number;
}

// @ts-ignore
export const ConditionalWrapper = ({ wrap, wrapper, children }) =>
  wrap ? wrapper(children) : children;

//TODO - Change profile of errors to IValidationError (see in art curator grid frontend)
export const formatErrors = (
  errors: any
): { [key: string]: string } | undefined => {
  if (errors.status === 'validation_error') {
    const validations = errors.error_message;
    return validations.reduce((a: any, i: any) => {
      return { ...a, [i.param]: i.msg };
    }, {});
  }
  return undefined;
};

// ---- FORM VALIDATORS ----
export interface IValidationConfig {
  [key: string]: ValidationConfigType[];
}

type RequiredMatch = (value: any) => 'REQUIRED' | null;

export type ValidationConfigType =
  | 'REQUIRED'
  | 'IS_EMAIL'
  | 'SHORT_PASS'
  | { eq: string }
  | RequiredMatch;

export const generateValidator =
  (config: IValidationConfig) => (values: any) => {
    const errors: any = {};
    Object.keys(config).map((key) => {
      return config[key].map((configType) => {
        if (configType === 'REQUIRED') {
          if (!values[key]) {
            errors[key] = `This field is required`;
          }
          return;
        }
        if (configType === 'SHORT_PASS') {
          if (values[key].length < 4) {
            errors[key] = `The password must be at least 4 characters long`;
          }
          return;
        }
        if (configType === 'IS_EMAIL') {
          if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)
          ) {
            errors[key] = `Please enter a valid email address.`;
          }
          return;
        }
        if ((configType as { eq: string }).eq) {
          if (values[key] !== values[(configType as { eq: string }).eq]) {
            errors[key] = `Should be the same value as ${
              (configType as { eq: string }).eq
            }`;
          }
          return;
        }
        if (typeof configType === 'function') {
          const configTypeFunc = configType as RequiredMatch;
          const result = configTypeFunc(values);
          if (result === 'REQUIRED') {
            errors[key] = `This field is required`;
          }
        }
      });
    });

    return errors;
  };
