import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { AppBar, Toolbar } from '@mui/material';
import {} from '@mui/material/';
import SimpleBottomNavigation from '../../../containers/menu/BottomNavegation';
import { ArtpoolLogoWithPortugal2020, Footer } from '@acg/frontend-ui-artpool';

export interface IMainLayoutProps {
  profileComponent?: React.ReactNode;
  menuComponent?: React.ReactNode;
  searchComponent?: React.ReactNode;
  extraInfoComponent?: React.ReactNode;
  scrolToTop: number;
  notTransparent?: boolean;
  noFooter?: boolean;
  children: React.ReactNode;
}

const styles = createStyles({
  main: {
    width: '100%',
    position: 'relative',
    zIndex: 1,
    minHeight: '100%',
  },
  navbar: {
    flexGrow: 1,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
  },
  colorDefault: {
    height: 60,
  },
  content: {
    zIndex: 2,
    minHeight: '100vh',
    backgroundColor: 'white',
  },
  search: {
    display: 'inline-block',
    width: 'calc(100% - 150px)',
  },
  searchWrapper: {
    width: '75%',
  },
  toolbar: {
    paddingLeft: 0,
    justifyContent: 'right',
  },
  profile: {
    float: 'right',
    height: 60,
    lineHeight: 60,
    width: 40,
    marginRight: 20,
  },
});

type Props = IMainLayoutProps & WithStyles<string> & WithWidth;

function MainLayout(props: Props) {
  const { classes, width, scrolToTop, notTransparent } = props;

  const transparencyValue = Math.min((1 * scrolToTop) / 60, 1);

  const isMobile = isWidthDown('sm', width);
  const isPad = isWidthDown('md', width);
  const isNotDesktop = isWidthDown('lg', width);

  return (
    <div className={`${classes.main}`}>
      <div>
        <div className={classes.navbar}>
          <AppBar
            style={{
              minHeight: 80,
              backgroundColor: notTransparent
                ? 'white'
                : `rgba(255,255,255,${transparencyValue})`,
            }}
            classes={{ colorDefault: classes.colorDefault }}
            color="default"
            elevation={0}
            position="static"
          >
            <Toolbar className={classes.toolbar}>
              <div
                style={{ position: 'absolute', left: '-10px', display: 'flex' }}
              >
                <ArtpoolLogoWithPortugal2020
                  linkToArtpool={true}
                  isPad={isPad}
                  isMobile={isMobile}
                  hoverEffect={true}
                />
              </div>
              {!isPad && props.searchComponent && (
                <div className={classes.search}>
                  <div className={classes.searchWrapper}>
                    {props.searchComponent}
                  </div>
                </div>
              )}

              {props.extraInfoComponent}

              {props.profileComponent}

              {props.menuComponent}
            </Toolbar>
          </AppBar>
        </div>
      </div>
      <div
        className={classes.content}
        style={{ paddingBottom: isMobile ? 75 : 0 }}
      >
        {props.children}
      </div>
      {isNotDesktop ? (
        <SimpleBottomNavigation />
      ) : (
        !props.noFooter && <Footer />
      )}
    </div>
  );
}

export default compose<Props, IMainLayoutProps>(
  withStyles(styles),
  withWidth()
)(MainLayout);
