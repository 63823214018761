import {
  default as withWidth,
  isWidthDown,
  WithWidth,
} from '@mui/material/Hidden/withWidth';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import UserService from '../../services/UserService';
import VisitorService from '../../services/VisitorServices';
import { Grid, Tab, Tabs } from '@mui/material';
import ProfileLarge from '../../library/dataDisplay/profile/ProfileLarge';
import { IUser } from '../../types/user';
import PaddedLayout from '../../library/layout/container/PaddedLayout';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import ProfileModalContent from '../profile/ProfileModalContent';
import Followers from './Followers';
import Followings from './Followings';
import Posts from './Posts';
import Follow from './Follow';
import Loading from '../../components/loading/Loading';
import { checkRight } from '../../utils/userUtils';
import SocialModal from '../social/SocialModal';
import ConnectButton from '../services/ConnectButton';
import { PRIMARY_COLOR, SPACING } from '../../styles/constants';
import { PostTypeOptions } from '../../types/posts';
import * as queryString from 'query-string';
import { sumPostTotals } from '../../utils/post-totals-utils';
import { ACGModal } from '../../components/modal/ACGModal';
import { ACGAppPaths } from '@acg/shared/const';

type TabValue = 'POSTS' | 'FOLLOWERS' | 'FOLLOWINGS';

export interface IUserProfileProps {
  tabValue: TabValue;
}

type Props = IUserProfileProps &
  RouteComponentProps<{ userId: string }> &
  WithStyles<string> &
  IWithAccountProps &
  WithWidth;

interface IState {
  user?: IUser;
  loading: boolean;
  openProfile: boolean;
  following: string[];
  dialogOpen: boolean;
  gettingUser: boolean;
  selectedTab: string;
  isViewerProfile?: boolean;
  postTotals?: {
    exhibition: number;
    art_project: number;
    publication: number;
    studio_visit: number;
    interview: number;
    online_exhibition: number;
    misc: number;
  };
}

const style = createStyles({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  tabRoot: {
    textTransform: 'none',
  },
  backdrop: {
    backgroundColor: 'rgba(242,242,242,0.92)',
  },
  tabs: {
    fontSize: '0.715rem',
    letterSpacing: -0.12,
    textAlign: 'right',
    minWidth: 120,
    textColor: '#000',
    '& button': {
      minWidth: 120,
      // fontSize: '0.715rem'
      fontSize: 16,
    },
  },
  scrollButton: {
    width: 10,
  },
  indicator: {
    backgroundColor: PRIMARY_COLOR,
  },

  tabsContainer: {
    marginTop: `-${SPACING}px`,
    paddingRight: `${SPACING}px`,
    paddingLeft: `${SPACING}px`,
  },

  postsContainer: {
    marginTop: `30px`,
  },
});

class UserProfile extends React.Component<Props, IState> {
  private userService = new UserService();
  private visitorService = new VisitorService();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      gettingUser: false,
      openProfile: false,
      following: [],
      dialogOpen: false,
      selectedTab:
        this.props.tabValue === 'POSTS' ? 'All' : this.props.tabValue,
    };
  }

  public componentDidMount() {
    const {
      location: { search },
      match: {
        params: { userId },
      },
      account: { id },
    } = this.props;

    this.getUser(userId, id);

    const edit = queryString.parse(search).edit;
    if (edit) {
      this.openEditProfile();
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const {
      match: {
        params: { userId },
      },
      location: { pathname, search },
    } = this.props;

    const {
      match: {
        params: { userId: prevUserId },
      },
      account: { id, profile, pending },
    } = prevProps;

    const { selectedTab } = this.state;

    if (userId !== prevUserId) {
      this.getUser(userId, id);
    }
    if (
      pathname.includes('posts') &&
      profile !== 'VIEWER' &&
      pending !== true &&
      (selectedTab === 'FOLLOWERS' || selectedTab === 'FOLLOWINGS')
    ) {
      this.setState({ selectedTab: 'ALL' });
    }
    // --- This is not allowing to change tabs when the user viewing a curator profile is a viewer --
    // else if( profile === "VIEWER" &&
    //     !(selectedTab === 'FOLLOWERS' || selectedTab === 'FOLLOWINGS' ) ) {
    //         this.setState({selectedTab: 'FOLLOWERS'})
    // }

    const edit = queryString.parse(search).edit;
    if (edit && this.state.openProfile === false) {
      this.openEditProfile();
    }
  }

  public openEditProfile = () => {
    const right = checkRight(this.props.account);
    const profileUrl =
      right === 'VIEWER'
        ? `/users/${this.props.account.url}/followers`
        : `/users/${this.props.account.url}/posts`;
    this.props.history.push(profileUrl);
    this.setState({ openProfile: true });
  };

  public getUser = (userId: string, accountId: string) => {
    this.setState({ loading: true, gettingUser: true });
    return Promise.all([
      this.userService.getUser(userId),
      this.visitorService.getPublicUserTotalPosts(userId),
      // this.userService.getUserFollowings(accountId)
      this.userService.getUserFollowingsList(accountId),
    ]).then(([userData, postsTotals, userFollowing]) => {
      if (userData.data.profile === 'VIEWER') {
        this.props.history.push(`/users/${userId}/followers`);
      }

      const selectedTabValue =
        userData.data.profile === 'VIEWER' || userData.data.profile === 'ARTIST'
          ? 'FOLLOWERS'
          : userData.data.pending
          ? 'FOLLOWERS'
          : 'ALL';
      this.setState({
        loading: false,
        user: userData.data,
        following: userFollowing.data.map((d) =>
          d.following ? d.following.url : ''
        ),
        gettingUser: false,
        postTotals: postsTotals.data || null,
        // selectedTab: userData.data.profile === 'VIEWER' ? "FOLLOWERS" : "ALL",
        selectedTab: selectedTabValue,
        isViewerProfile: userData.data.profile === 'VIEWER' ? true : false,
      });
    });
  };

  public render() {
    const {
      history,
      classes,
      match: {
        params: { userId },
      },
      account,
      width,
    } = this.props;

    const { user, following, loading, gettingUser, selectedTab, postTotals } =
      this.state;

    const onGoBack = () => history.push(ACGAppPaths.ROOT);
    const showEditButton = userId === account.url;
    const onEditClick = () => this.setState({ openProfile: true });
    const handleProfileClose = () => {
      this.setState({ openProfile: false });
    };

    const right = checkRight(account);
    const isViewer = right === 'VIEWER' || right === 'PENDING';
    const isCurrentUserProfile = account.url === userId;

    let shouldDisplayPost = !(
      isCurrentUserProfile &&
      (isViewer || account.profile === 'VIEWER')
    );

    // Allow artist to see the posts in their own profiles
    if (account.profile === 'ARTIST') {
      shouldDisplayPost = true;
    }

    const onTabClick = (e: any, val: string) => {
      this.setState({ selectedTab: val });
      switch (val) {
        case 'FOLLOWERS':
          return history.push(`/users/${userId}/followers`);
        case 'FOLLOWINGS':
          return history.push(`/users/${userId}/followings`);
        default:
          return history.push(`/users/${userId}/posts`);
      }
    };

    const onRemoveFollower = (id: string) => {
      if (user) {
        user.followers_number--;
        this.setState({
          following: this.state.following.filter((d) => d !== id),
          user: { ...user },
        });
      }
    };

    const onAddFollower = (id: string) => {
      if (user) {
        user.followers_number++;
        this.setState({
          following: [...this.state.following, id],
          user: { ...user },
        });
      }
    };

    if (loading) {
      return <Loading />;
    }

    if (
      gettingUser === false &&
      user &&
      user.id === userId &&
      user.url !== userId
    ) {
      history.push(`/users/${user.url}/posts`);
    }

    const callback = () => {
      if (following.indexOf(userId) > -1) {
        onRemoveFollower(userId);
      } else {
        onAddFollower(userId);
      }
    };

    const isPad = isWidthDown('md', width);
    const additionalButton: React.ReactNode | undefined =
      account.url !== userId ? (
        <>
          {!isViewer && (
            <ConnectButton
              user={user!}
              buttonSize={isPad ? 'small' : 'large'}
            />
          )}
          <Follow
            buttonSize={isPad ? 'small' : 'large'}
            user={user!}
            callback={callback}
            isFollowing={following.indexOf(userId) > -1}
          />
        </>
      ) : undefined;

    const onShareClose = () => {
      this.setState({
        dialogOpen: false,
      });
    };

    const onShareOpen = () =>
      this.setState({
        dialogOpen: true,
      });

    const onTagClick = (id: string) =>
      isViewer ? {} : history.push(ACGAppPaths.ROOT, { tags: [id] });

    return (
      <PaddedLayout>
        <Grid container={true} className={classes.root}>
          {user && (
            <ProfileLarge
              onTagClick={onTagClick}
              user={user}
              goBack={onGoBack}
              showEditButton={showEditButton}
              onEditClick={onEditClick}
              additionalButton={additionalButton}
              onShareClick={onShareOpen}
              showBio={true}
            />
          )}
          {user && (
            <SocialModal
              open={this.state.dialogOpen}
              onClose={onShareClose}
              object={{
                type: 'USER',
                object: user,
              }}
            />
          )}
          <Grid item={true} xs={12} className={classes.tabsContainer}>
            <Tabs
              value={selectedTab}
              onChange={onTabClick}
              centered={false}
              classes={{
                root: classes.tabs,
                indicator: classes.indicator,
                scrollButtons: classes.scrollButton,
              }}
              scrollButtons={true}
              variant="scrollable"
            >
              {shouldDisplayPost && postTotals && !this.state.isViewerProfile && (
                <Tab
                  label={'(' + sumPostTotals(postTotals) + ') All'}
                  value={'ALL'}
                  key={'ALL'}
                  classes={{
                    root: classes.tabRoot,
                  }}
                />
              )}
              {shouldDisplayPost &&
                !this.state.isViewerProfile &&
                PostTypeOptions.map((type) => {
                  let tabLabel = type.tabLabel;

                  if (postTotals && postTotals.exhibition >= 0) {
                    switch (type.id) {
                      case 'EXHIBITION':
                        tabLabel =
                          '(' + postTotals.exhibition + ') ' + type.tabLabel;
                        break;
                      case 'ARTPROJECT':
                        tabLabel =
                          '(' + postTotals.art_project + ') ' + type.tabLabel;
                        break;
                      case 'PUBLICATION':
                        tabLabel =
                          '(' + postTotals.publication + ') ' + type.tabLabel;
                        break;
                      case 'STUDIOVISIT':
                        tabLabel =
                          '(' + postTotals.studio_visit + ') ' + type.tabLabel;
                        break;
                      case 'INTERVIEW':
                        tabLabel =
                          '(' + postTotals.interview + ') ' + type.tabLabel;
                        break;
                      case 'ONLINEEXHIBITION':
                        tabLabel =
                          '(' +
                          postTotals.online_exhibition +
                          ') ' +
                          type.tabLabel;
                        break;
                      default:
                        tabLabel = '(' + postTotals.misc + ') ' + type.tabLabel;
                        break;
                    }
                  }

                  return (
                    <Tab
                      label={tabLabel}
                      value={type.id}
                      key={type.id}
                      classes={{
                        root: classes.tabRoot,
                      }}
                    />
                  );
                })}

              <Tab
                label="Followers"
                value={'FOLLOWERS'}
                key={'FOLLOWERS'}
                classes={{
                  root: classes.tabRoot,
                }}
              />
              <Tab
                label="Following"
                value={'FOLLOWINGS'}
                key={'FOLLOWINGS'}
                classes={{
                  root: classes.tabRoot,
                }}
              />
            </Tabs>
            {selectedTab !== 'FOLLOWERS' &&
              selectedTab !== 'FOLLOWINGS' &&
              shouldDisplayPost && (
                <div className={classes.postsContainer}>
                  <Posts
                    selectedTab={selectedTab}
                    noTopMargin={true}
                    noProfile={true}
                  />
                </div>
              )}
          </Grid>

          {selectedTab === 'FOLLOWERS' && (
            <Followers
              following={following}
              onRemoveFollower={onRemoveFollower}
              onAddFollower={onAddFollower}
            />
          )}

          {selectedTab === 'FOLLOWINGS' && (
            <Followings
              following={following}
              onRemoveFollower={onRemoveFollower}
              onAddFollower={onAddFollower}
            />
          )}

          <ACGModal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.openProfile}
            disableAutoFocus={true}
            onClose={handleProfileClose}
            BackdropProps={{
              className: classes.backdrop,
            }}
          >
            <div>
              <ProfileModalContent onClickAway={handleProfileClose} />
            </div>
          </ACGModal>
        </Grid>
      </PaddedLayout>
    );
  }
}

export default compose<Props, IUserProfileProps>(
  withRouter,
  withAccount,
  withStyles(style),
  withWidth()
)(UserProfile);
