import { AppBar, Grid, Menu, MenuItem, Toolbar } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Dots from '../../../components/icons/Dots';

import { ALTERNATIVE, NEUTRAL, PRIMARY, themes } from '@acg/assets';
import { ArtpoolLogo, ArtpoolLogoWithPortugal2020, Footer, StyledButton } from '@acg/frontend-ui-artpool';
import { ACGAppPaths, FAQ_URL } from '@acg/shared/const';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';

export interface IMainPublicLayoutProps {
  navColor?: string;
  scrolToTop: number;
  noFooter?: boolean;
  children: React.ReactNode;
}

const useStyles = makeStyles(() =>
  createStyles({
    main: {
      width: '100%',
      position: 'relative',
      zIndex: 1,
      minHeight: '100%',
    },
    navbar: {
      flexGrow: 1,
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
    },
    colorDefault: {
      height: 80,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    content: {
      zIndex: 2,
      minHeight: '100vh',
      backgroundColor: 'white',
    },
    logo: {
      height: 40,
      float: 'left',
      marginRight: 15,
    },
    mobileLogo: {
      height: 45,
      float: 'left',
      marginRight: 15,
    },
    title: {
      textDecoration: 'none',
      color: 'rgba(0, 0, 0, 0.87)',
      float: 'left',
      minWidth: 220,
      cursor: 'pointer',
    },
    titleSmall: {
      textDecoration: 'none',
      color: 'rgba(0, 0, 0, 0.87)',
      float: 'left',
      cursor: 'pointer',
    },
    left: {
      float: 'left',
      width: '100%',
    },
    right: {
      float: 'right',
      width: 250,
    },
    companyName: {
      lineHeight: '40px',
    },
    companyNameSmall: {
      lineHeight: '40px',
      fontSize: '15px',
    },
    search: {
      display: 'inline-block',
      width: 'calc(100% - 250px)',
    },
    searchWrapper: {
      width: '50%',
    },
    service: {
      float: 'left',
      height: 60,
      lineHeight: '60px',
      marginRight: 20,
    },
    profile: {
      float: 'right',
      height: 60,
      lineHeight: 60,
      width: 40,
      marginRight: 20,
    },
    grow: {
      flex: 1,
    },
    menuLink: {
      whiteSpace: 'nowrap',
      fontSize: 14,
      color: 'black',
      cursor: 'pointer',
      marginRight: 30,
      '&:hover': {
        color: ALTERNATIVE.ARTPOOL_PINK,
      },
    },
    menuLink_grey: {
      color: NEUTRAL[500],
    },
    avatar: {
      margin: 10,
      marginLeft: 0,
    },
  })
);

const menuItemStyle = {
  backgroundColor: 'white',
  '&:hover': {
    backgroundColor: `${PRIMARY.ARTPOOL_GREEN}70`,
  },
};

interface MenuItem {
  name: string;
  path: string;
  external?: boolean;
}

const sitePublicMenuItems: MenuItem[] = [
  { name: 'For Curators', path: ACGAppPaths.FOR_CURATORS_PAGE },
  { name: 'Services', path: ACGAppPaths.SERVICES },
  { name: 'Editorial', path: ACGAppPaths.BLOG },
  { name: 'About', path: ACGAppPaths.ABOUT_US },
  { name: 'FAQ', path: ACGAppPaths.FAQ },
];

const MainPublicLayout = (props: IMainPublicLayoutProps) => {
  const [element, setElement] = useState();
  const classes = useStyles();

  const MenuLink = ({
    item,
    children,
  }: {
    item: MenuItem;
    children: React.ReactNode | string;
  }) => {
    if (item.external) {
      return (
        <a href={item.path} target={'_blank'}>
          {children}
        </a>
      );
    }
    return <Link to={item.path}>{children}</Link>;
  };

  const handleMenuClose = () => {
    setElement(undefined);
  };

  const handleMenuClick = (event: any) => {
    setElement(event.currentTarget);
  };

  const isMobile = useMediaQuery(themes.artpoolTheme.breakpoints.down('md'));
  const isPad = useMediaQuery(themes.artpoolTheme.breakpoints.down('lg'));
  const isDesktop = useMediaQuery(themes.artpoolTheme.breakpoints.down('xl'));

  const DotsMenu = () => {
    return (
      <Menu
        id="simple-menu"
        anchorEl={element}
        open={!!element}
        onClose={handleMenuClose}
        style={{ maxWidth: '700px' }}
      >
        {sitePublicMenuItems.map((item) => (
          <MenuItem
            key={item.name}
            sx={menuItemStyle}
            onClick={handleMenuClose}
          >
            <MenuLink item={item}>{item.name}</MenuLink>
          </MenuItem>
        ))}
      </Menu>
    );
  };

  return (
    <div className={classes.main}>
      <div>
        <div className={classes.navbar}>
          <AppBar
            classes={{ colorDefault: classes.colorDefault }}
            color="default"
            elevation={0}
            position="static"
            style={{ backgroundColor: 'white' }}
          >
            <Toolbar style={{ paddingLeft: 0 }}>
              <div
                className={`${classes.grow} ${
                  isPad ? classes.titleSmall : classes.title
                }`}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <ArtpoolLogoWithPortugal2020
                  isPad={isPad}
                  isMobile={isMobile}
                  hoverEffect
                  linkToArtpool
                />
                {!isDesktop && (
                  <Grid
                    container
                    style={{ width: '100%', justifyContent: 'end' }}
                  >
                    {sitePublicMenuItems.map((item) => (
                      <Grid item key={item.name}>
                        <MenuLink item={item}>
                          <span
                            className={`${classes.menuLink} ${classes.menuLink_grey}`}
                          >
                            {item.name}
                          </span>
                        </MenuLink>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </div>
              {isDesktop && (
                <div>
                  <Dots
                    className={classes.avatar}
                    aria-owns={element ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                    onClick={handleMenuClick}
                    big
                  />
                  <DotsMenu />
                </div>
              )}
              <Link to={ACGAppPaths.LOGIN}>
                <StyledButton
                  style={{ marginRight: 10, whiteSpace: 'nowrap' }}
                  variant="contained"
                >
                  Sign In
                </StyledButton>
              </Link>
            </Toolbar>
          </AppBar>
        </div>
      </div>
      <div className={classes.content}>{props.children}</div>
      {!props.noFooter && <Footer />}
    </div>
  );
};

export default MainPublicLayout;
