import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';
import { default as React } from 'react';

export const ArtpoolSkeleton = (props: SkeletonProps) => {
  return (
    <Skeleton
      variant="rectangular"
      animation="wave"
      {...props}
      style={{ borderRadius: 8, ...props.style }}
    />
  );
};
