import { ILoginRequest, ISignupRequest } from '../../../services/UserService';
import { IUser } from '../../../types/user';
import { action } from 'typesafe-actions';

// LOGIN
export function login({ request }: { request: ILoginRequest }) {
  return action('@@account/LOGIN', { request });
}
export function loginSuccess() {
  return action('@@account/LOGIN_SUCCESS');
}
export function loginError({ error }: { error: Error }) {
  return action('@@account/ERROR', { error });
}

export function getAccount() {
  return action('@@account/GET_ACCOUNT');
}
export function getAccountSuccess({ user }: { user: IUser }) {
  return action('@@account/GET_ACCOUNT_SUCCESS', { user });
}
export function getAccountError({ error }: { error: Error }) {
  return action('@@account/ERROR', { error });
}

// LOGOUT
export function logout() {
  return action('@@account/LOGOUT');
}
export function logoutSuccess() {
  return action('@@account/LOGOUT_SUCCESS');
}
export function logoutError({ error }: { error: Error }) {
  return action('@@account/ERROR', { error });
}

// UPDATE
export function update({ request }: { request: Partial<IUser> }) {
  return action('@@account/UPDATE_ACCOUNT', { request });
}
export function updateSuccess({ user }: { user: IUser }) {
  return action('@@account/UPDATE_ACCOUNT_SUCCESS', { user });
}
export function updateError({ error }: { error: Error }) {
  return action('@@account/UPDATE_ACCOUNT_ERROR', { error });
}

// ACCOUNT
export function updateAccount({ user }: { user: IUser }) {
  return action('@@account/UPDATE_ACCOUNT_DATA', { user });
}

// ETHIC CODE
export function acceptEthicCode() {
  return action('@@account/ACCEPT_ETHIC_CODE');
}

export function acceptEthicCodeSuccess({ data }: any) {
  return action('@@account/ACCEPT_ETHIC_CODE_SUCCESS', { data });
}

export function acceptEthicCodeError() {
  return action('@@account/ACCEPT_ETHIC_CODE_ERROR');
}
