import { Auth, Hub } from 'aws-amplify';
import {
  ClientMetaData,
  ConfirmSignUpOptions,
  SignUpParams,
  UsernamePasswordOpts,
} from '@aws-amplify/auth/lib-esm/types';
import { authenticatedUser } from './util';
import { CustomAttributeKeys, User } from '@acg/auth/auth';
import { CognitoUser } from 'amazon-cognito-identity-js';

const fiveMinsInMs = 1000 * 60 * 5;

type OnTokenRefreshFnc = (accessToken: string) => Promise<void> | void;

const refreshToken = async (): Promise<string | undefined> => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.currentSession();
  return new Promise((resolve) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cognitoUser.refreshSession(
      currentSession.getRefreshToken(),
      (err: any, session: any) => {
        if (err) {
          console.error(err);
          resolve(undefined);
        }
        resolve(session.idToken);
      }
    );
  });
};

export const getBearerToken = async (): Promise<string | undefined> => {
  try {
    let session = await Auth.currentSession();
    if (!session.isValid()) {
      await refreshToken();
    }
    session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (err) {
    console.debug(
      'Failed to get bearer token this may just be as not authenticated',
      err
    );
    return undefined;
  }
};

export interface AuthListenersParams {
  onSignInOrOut: (user?: User) => Promise<void> | void;
  onTokenRefresh: OnTokenRefreshFnc;
}

export const createAuthListeners = (params: AuthListenersParams) => {
  const { onSignInOrOut, onTokenRefresh } = params;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let interval: any;
  Hub.listen('auth', async (data) => {
    switch (data.payload.event) {
      case 'signIn':
      case 'tokenRefresh':
        onSignInOrOut(await authenticatedUser());
        interval = setInterval(async () => {
          const token = await refreshToken();
          if (token) {
            onTokenRefresh(token);
          }
        }, fiveMinsInMs);
        break;
      case 'signOut':
        clearInterval(interval);
        onSignInOrOut();
        break;
    }
  });
};

export const signIn = async (
  usernameOrSignInOpts: string | UsernamePasswordOpts,
  password?: string,
  clientMetadata?: ClientMetaData
) => {
  return Auth.signIn(usernameOrSignInOpts, password, clientMetadata);
};

export const signOut = async () => {
  return Auth.signOut();
};

export const signUp = async (params: SignUpParams) => {
  return Auth.signUp(params);
};

export const confirmSignUp = async (
  username: string,
  code: string,
  options?: ConfirmSignUpOptions | undefined
) => {
  return Auth.confirmSignUp(username, code, options);
};

export const sendSignUpCode = async (
  username: string,
  clientMetadata?: ClientMetaData
) => {
  return Auth.resendSignUp(username, clientMetadata);
};

export const sendForgotPasswordCode = async (
  username: string,
  clientMetadata?: ClientMetaData
) => {
  return Auth.forgotPassword(username, clientMetadata);
};

export const resetPassword = async (
  username: string,
  code: string,
  password: string,
  clientMetadata?: ClientMetaData
) => {
  return Auth.forgotPasswordSubmit(username, code, password, clientMetadata);
};

const getAuthenticatedUser = async (): Promise<CognitoUser> => {
  const user = await Auth.currentAuthenticatedUser();
  if (!user) {
    throw Error('Must be authenticated to set country of residence');
  }
  return user;
};

export const setCountryOfResidence = async (
  country: string
): Promise<string> => {
  const user = await getAuthenticatedUser();
  return Auth.updateUserAttributes(user, {
    [CustomAttributeKeys.COUNTRY_OF_RESIDENCE]: country,
  });
};
