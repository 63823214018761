import React from 'react';
import './ethereum-icon.module.scss';
import { SvgIcon, SvgIconProps } from '@mui/material';

// tslint:disable-next-line:no-empty-interface
export interface EthereumIconProps {}

type Props = EthereumIconProps & SvgIconProps;

export function EthereumIcon(props: Props) {
  return (
    <SvgIcon {...props} viewBox={'0 0 9 14'}>
      <path d="M4.09279 10.316L0 7.90064L4.09029 13.6667L8.18516 7.90064L4.09029 10.316H4.09279ZM4.15362 0.333374L0.0624981 7.12358L4.15362 9.54309L8.24641 7.12608L4.15362 0.333374Z" />
    </SvgIcon>
  );
}

export default EthereumIcon;
