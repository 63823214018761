import { createRouteRequest } from 'typesafe-api';
import {
  GetEvmAccountLinkedDef,
  getEvmAccountLinkedRoute,
  GetEvmAccountsEndpointDef,
  getEvmAccountsRoute,
  GetMembershipWhitelistedEvmAccountDef,
  getMembershipWhitelistedEvmAccountRoute,
  PostAddEvmAccountEndpointDef,
  postAddEvmAccountRoute,
  PostEvmSignaturePayloadEndpointDef,
  postEvmSignaturePayloadRoute,
  PostMembershipWhitelistEvmAccountDef,
  postMembershipWhitelistEvmAccountRoute,
} from '../endpoints/evm';
import { AuthApiClient } from '@acg/shared/api-spec-utils';

export class EvmClient extends AuthApiClient {
  /**
   * Get Evm Accounts
   */
  private _getEvmAccounts = createRouteRequest<GetEvmAccountsEndpointDef>(
    this,
    getEvmAccountsRoute
  );
  public evmAccounts = () => this._getEvmAccounts({});

  /**
   * Get Evm Account linked
   */
  private _getEvmAccountLinked = createRouteRequest<GetEvmAccountLinkedDef>(
    this,
    getEvmAccountLinkedRoute
  );
  public accountLinked = (address: string) =>
    this._getEvmAccountLinked({
      params: {
        address,
      },
    });

  /**
   * Post Evm Signature payload
   */
  private _postEvmSignaturePayload =
    createRouteRequest<PostEvmSignaturePayloadEndpointDef>(
      this,
      postEvmSignaturePayloadRoute
    );

  public evmSignaturePayload = (address: string) =>
    this._postEvmSignaturePayload({
      body: {
        address,
      },
    });

  /**
   * Post Membership Whitelist account
   */
  private _postWhitelistAccount =
    createRouteRequest<PostMembershipWhitelistEvmAccountDef>(
      this,
      postMembershipWhitelistEvmAccountRoute
    );

  public setMembershipWhiteListAccount = (address: string) =>
    this._postWhitelistAccount({
      body: {
        address,
      },
    });

  /**
   * Get Membership Whitelisted account
   */
  private _getMembershipWhitelistedEvmAccount =
    createRouteRequest<GetMembershipWhitelistedEvmAccountDef>(
      this,
      getMembershipWhitelistedEvmAccountRoute
    );

  public getMembershipWhitelistedAccount = (address: string) =>
    this._getMembershipWhitelistedEvmAccount({
      body: {
        address,
      },
    });

  /**
   * Add Evm Account
   */
  private _postAddEvmAccount = createRouteRequest<PostAddEvmAccountEndpointDef>(
    this,
    postAddEvmAccountRoute
  );

  public addEvmAccount = (signature: string) =>
    this._postAddEvmAccount({
      body: {
        signature,
      },
    });
}
