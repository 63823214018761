import React from 'react';
import styles from './project-card.module.scss';
import artpoolStyles from './../../styles/artpool-styles.module.scss';
import { Grid } from '@mui/material';
import { JsonProject } from '@acg/artpool-api-spec';
import {
  getFundraisingProjectUrl,
  getImageUrl,
  projectArtists,
  projectImage,
} from '@acg/shared/utilities';
import { StyledButton } from '../../inputs/styled-button/styled-button';
import { UserOrVenueThumbnail } from '../../data-display/user-thumbnail/user-or-venue-thumbnail';
import Subtitle3 from '../../data-display/typography/subtitle3/subtitle3';
import {
  lineClamp1,
  lineClamp3,
  lineClamp4,
} from '../../styles/artpool-styles';
import { Link } from 'react-router-dom';

export interface ProjectCardProps {
  project: JsonProject;
  cloudFrontUrl: string;
}

export const ProjectCard = (props: ProjectCardProps) => {
  const { project, cloudFrontUrl } = props;

  const image = projectImage(project);
  const artists = projectArtists(project);

  return (
    <Grid container className={styles.container}>
      <Grid item xs={12} className={artpoolStyles.clipped_corners}>
        <img className={styles.image} src={image.src} />
      </Grid>
      <Grid
        item
        xs={12}
        className={`${styles.content} ${artpoolStyles.clipped_corners}`}
      >
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={12}>
            <Subtitle3 style={lineClamp1}>{project.title}</Subtitle3>
          </Grid>
          <Grid item xs={12}>
            <UserOrVenueThumbnail userOrVenue={project.organizers[0]} cloudFrontUrl={cloudFrontUrl} />
          </Grid>
          <Grid item xs={12}>
            <p style={lineClamp3}>
              <b>Artist{artists.length > 1 && 's'}</b>: {artists.join(', ')}
            </p>
          </Grid>
          <Grid item xs={12} alignSelf={'flex-end'}>
            <Link to={getFundraisingProjectUrl(project._id)}>
              <StyledButton variant={'contained'} fullWidth>
                See Collection
              </StyledButton>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const EventsCard = (props: ProjectCardProps) => {
  const { project, cloudFrontUrl } = props;

  const image = projectImage(project);

  return (
    <Grid container className={styles.container}>
      <Grid item xs={12} className={artpoolStyles.clipped_corners}>
        <img className={styles.image} src={getImageUrl(image.src, cloudFrontUrl)} />
      </Grid>
      <Grid
        item
        xs={12}
        className={`${styles.content} ${artpoolStyles.clipped_corners}`}
      >
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={12}>
            <Subtitle3 style={lineClamp1}>{project.title}</Subtitle3>
          </Grid>
          <Grid item xs={12}>
            <p style={lineClamp4}>{project.description}</p>
          </Grid>
          <Grid item xs={12} alignSelf={'flex-end'}>
            <Link to={getFundraisingProjectUrl(project._id)}>
              <StyledButton variant={'contained'} fullWidth>
                Discover this project
              </StyledButton>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
