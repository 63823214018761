import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { compose } from 'recompose';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import { PRIMARY_COLOR } from '../../styles/constants';
import copyRight from '../../assets/ARTPOOL_ATHORIZATIONFORM_COPYRIGHT.6b95a683.pdf';
import { useDispatch, useSelector } from 'react-redux';
import * as accountActions from '../../store/modules/account/actions';
import { IStoreState } from '../../store';

const style = createStyles({
  title: {
    color: PRIMARY_COLOR,
    fontFamily: 'Replica-Regular',
  },
  buttonsSection: {
    padding: 20,
  },
  contentSection: {
    height: 400,
    overflowY: 'scroll',
    fontFamily: 'Replica-Regular',
    fontSize: 16,
    color: '#000',
    fontWeight: 300,
  },
  dashList: {
    listStyle: 'square',
  },
  numberList: {
    listStyle: 'decimal',
  },
  bold: {
    fontWeight: 900,
  },
  link: {
    color: '#afb0b3',
    fontWeight: 900,
  },
  center: {
    textAlign: 'center',
    'input:checked': {
      backgroundColor: '#339922',
    },
  },
});

type Props = WithStyles<string> & IWithAccountProps & WithWidth;

const EthicalCodeModal: React.FC<Props> = (props: any) => {
  const { classes } = props;

  const { account } = useSelector((state: IStoreState) => state.account);
  const dispatch = useDispatch();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [agreeCheck, setAgreeCheck] = useState(false);

  const agreeChangeEvt = () => {
    setAgreeCheck(!agreeCheck);
  };

  useEffect(() => {
    if (
      account &&
      account.codeOfEthic &&
      account.codeOfEthic.readAndAgreeStatus
    ) {
      setDialogOpen(false);
    }
    if (!account) {
      setDialogOpen(false);
    }
  }, [account]);

  const acceptAgreeEthicCode = () => {
    dispatch(accountActions.acceptEthicCode());
    setDialogOpen(false);
  };

  if (!dialogOpen) {
    return <></>;
  }

  return (
    <Dialog
      open={dialogOpen}
      maxWidth={'md'}
      fullWidth={true}
      style={{ zIndex: 1000 }}
    >
      <DialogTitle id="ethic-dialog-title" className={classes.title}>
        Artpool Code of Ethics
      </DialogTitle>
      <DialogContent
        className={classes.ethicTextSection}
        id={'ethic-dialog-title-description'}
      >
        <DialogContentText className={classes.contentSection}>
          <Grid container={true} className={classes.ethicTextSection}>
            <Grid item={true} sm={12} className={classes.ethicTextSection}>
              <p>
                <b className={classes.bold}>Introduction</b>
              </p>
              <p>
                Users of Artpool interact via chat, messages, posting pictures,
                films or links and sharing content.
              </p>
              <p>
                Artpool does not directly communicate content to the public but
                provides facilities allowing users to make their contributions.
              </p>
              <p>
                Artpool would like to remind users of the importance of ensuring
                they comply with intellectual property rights and the right of
                publicity.
              </p>
              <p>
                Any still or filmed picture may be concerned by the following
                rights:
              </p>
              <p>
                <ul className={classes.dashList}>
                  <li>
                    Copyright in a work of art such as a painting, sculpture,
                    audio-visual work, photograph, commentary, interview, etc.
                  </li>
                  <li>
                    The right of publicity applying to a person's image and
                    voice, when it is identifiable.
                  </li>
                </ul>
              </p>
              <p>
                These rights are held by the author of the work or their rights
                holder, or the person themselves in the case of a right of
                publicity.
              </p>
              <p>The Artpool website provides the following services:</p>
              <p>
                <ul className={classes.numberList}>
                  <li>Profile visibility</li>
                  <li>Content sharing between users</li>
                  <li>
                    Content sharing with third parties whose links are featured
                    on users' pages
                  </li>
                  <li>Simple or advanced search engine</li>
                  <li>
                    Special mission on request to find profiles and establish
                    contact
                  </li>
                  <li>
                    Organisation of virtual exhibitions or thematic events
                  </li>
                </ul>
              </p>
              <p>
                As a user of the Artpool website, you make your contributions.
                These contributions meet the goals of and enhance the website.
                Contributions made must never be detrimental to the holders of
                rights. Artpool therefore provides the public with an email
                address to which any third party may address a complaint:{' '}
                <a className={classes.link} href={'mailto:hello@artpool.xyz'}>
                  hello@artpool.xyz
                </a>
                .
              </p>
              <p>
                By registering on the website, the user agrees to comply with
                the procedure implemented by Artpool.
              </p>
              <p>
                <span className={classes.bold}>Article 1: Contributions</span>
              </p>
              <p>
                Contributions may only be made on the website after ensuring
                that each holder of rights{' '}
                <span className={classes.bold}>
                  has given their express consent.
                </span>
              </p>
              <p>
                To assist you, Artpool provides a form so that you can obtain
                the consent of each holder.
              </p>
              <p className={classes.center}>
                <span className={classes.bold}>
                  <a
                    href={copyRight}
                    target={'_blank'}
                    rel="noopener noreferrer"
                    download={true}
                    style={{ color: '#000' }}
                  >
                    Authorisation Form
                  </a>
                </span>
              </p>
              <p>
                The user undertakes to hold the necessary authorisations for use
                mentioned above and to provide them to Artpool on request.
              </p>
              <p>
                <span className={classes.bold}>Article 2: Duty to inform</span>
              </p>
              <p>
                In the event of a complaint by a third party in connection with
                any contribution, for any reason inherent to their rights, the
                user undertakes to inform Artpool immediately and within 24
                hours at the latest, by writing to:{' '}
                <a className={classes.link} href={'mailto:hello@artpool.xyz'}>
                  hello@artpool.xyz
                </a>
                .
              </p>
              <p>
                The user shall provide details of the third party's identity and
                the means of contact used, and shall present brief observations
                about the disputed rights.
              </p>
              <p>Artpool reserves the right to take one or more actions:</p>
              <p>
                <ul className={classes.numberList}>
                  <li>Ask the user for the necessary authorisations</li>
                  <li>Contact the third party</li>
                  <li>Offer them a contract directly with Artpool</li>
                  <li>Remove the disputed content</li>
                  <li>Cancel the user's account</li>
                </ul>
              </p>
              <p>
                <span className={classes.bold}>
                  Article 3: Dispute resolution
                </span>
              </p>
              <p>
                Artpool shall not substitute for the Courts and shall not play
                any role in settling disputes.
              </p>
              <p>
                The user undertakes to cooperate with Artpool in order to find a
                solution consistent with the interests of Artpool.
              </p>
              <p>
                In the event of legal proceedings, if Artpool is a defendant,
                the user acknowledges being exclusively liable for any
                infringement and shall indemnify and hold Artpool harmless from
                and against any court order handed down against it.
              </p>
              <p className={classes.center}>
                <Checkbox
                  checked={agreeCheck}
                  onChange={agreeChangeEvt}
                  name="I Agree"
                  color="primary"
                />
                <b>I agree</b>
              </p>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.buttonsSection}>
        <Grid container={true} direction="row" justifyContent="flex-end">
          <Button
            variant="contained"
            size="medium"
            color="primary"
            aria-label="I Read & Agree"
            className={classes.buttonSmall}
            disabled={!agreeCheck}
            onClick={acceptAgreeEthicCode}
          >
            I Read & Agree
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default compose<Props, any>(
  withStyles(style),
  withWidth(),
  withAccount
)(EthicalCodeModal);
