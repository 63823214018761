import { JsonPost } from '@acg/artcuratorgrid-api-spec';
import style from './post-card.module.scss';
import artpoolStyles from '../../styles/artpool-styles.module.scss';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { AcgLogoIcon, NEUTRAL } from '@acg/assets';
import { UserOrVenueThumbnail } from '../../data-display/user-thumbnail/user-or-venue-thumbnail';
import { lineClamp1 } from '../../styles/artpool-styles';
import Subtitle3 from '../../data-display/typography/subtitle3/subtitle3';
import { ArtpoolSkeleton } from '../artpool-skeleton/artpool-skeleton';
import { UserSkeleton } from '../artpool-skeleton/user-skeleton';

export const PostCard = (props: { post: JsonPost, cloudFrontUrl: string }) => {
  const {post, cloudFrontUrl } = props;
  const { title, imageUrl, creator, social, url } = post;
  const { likes, views } = social;
  return (
    <Grid container className={style.container} spacing={2}>
      <Grid item xs={12}>
        <UserOrVenueThumbnail userOrVenue={creator} cloudFrontUrl={cloudFrontUrl} />
      </Grid>
      <Grid
        item
        xs={12}
        style={{ position: 'relative' }}
        className={style.img_container}
      >
        <a href={url} target={'_blank'}>
          {imageUrl ? (
            <img src={imageUrl} className={style.img} />
          ) : (
            <div className={`${style.img} ${style.no_img}`}>
              <p style={{ ...lineClamp1, padding: 20 }}>{title}</p>
            </div>
          )}
          <div className={style.hover_logo}>
            <AcgLogoIcon fontSize={'inherit'} />
          </div>
        </a>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={8} className={artpoolStyles.link}>
            <Subtitle3
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {title}
            </Subtitle3>
          </Grid>
          <Grid item xs={4} style={{ color: NEUTRAL[600], fontSize: 18 }}>
            <Stack
              spacing={1}
              direction={'row'}
              alignItems={'center'}
              justifyContent={'flex-end'}
            >
              <FavoriteBorderIcon htmlColor={NEUTRAL[600]} fontSize={'small'} />
              <p>
                <b>{likes}</b>
              </p>
              <VisibilityOutlinedIcon
                htmlColor={NEUTRAL[600]}
                fontSize={'small'}
              />
              <p>
                <b>{views}</b>
              </p>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const PostCardSkeleton = () => {
  return (
    <Grid container className={style.container} spacing={2}>
      <Grid item xs={12}>
        <UserSkeleton />
      </Grid>
      <Grid
        item
        xs={12}
        style={{ position: 'relative' }}
        className={style.img_container}
      >
        <div className={`${style.img} ${style.no_img}`}>
          <ArtpoolSkeleton />
        </div>
        <div className={style.hover_logo}>
          <AcgLogoIcon fontSize={'inherit'} />
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={8} className={artpoolStyles.link}>
            <Subtitle3
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              <ArtpoolSkeleton />
            </Subtitle3>
          </Grid>
          <Grid item xs={4} style={{ color: NEUTRAL[600], fontSize: 18 }}>
            <Stack
              spacing={1}
              direction={'row'}
              alignItems={'center'}
              justifyContent={'flex-end'}
            >
              <FavoriteBorderIcon htmlColor={NEUTRAL[600]} fontSize={'small'} />
              <p>
                <ArtpoolSkeleton />
              </p>
              <VisibilityOutlinedIcon
                htmlColor={NEUTRAL[600]}
                fontSize={'small'}
              />
              <p>
                <ArtpoolSkeleton />
              </p>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
