import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
  Paper,
  Popper,
  TextField,
  MenuItem,
  Chip,
  Avatar,
} from '@mui/material';
import { compose } from 'recompose';
import Autosuggest from 'react-autosuggest';
import { FieldProps } from 'formik';
import { PRIMARY_COLOR } from '../../styles/constants';
import UserService from '../../services/UserService';

import {
  withAccount,
  IWithAccountProps,
} from '../../containers/account/InjectAccount';
import { IUser } from '../../types/user';
import { getSmallmageUrl } from '../../utils/imageUrl';

export interface IUserNameWrapperProps extends FieldProps {
  inputProps: any;
  helpText?: string;
  defaultUser?: IUser;
}

export interface IUserChip {
  id: string;
  firstName: string;
  lastName: string;
  profilePicture: string;
}

type Props = IUserNameWrapperProps & WithStyles<string> & IWithAccountProps;

const style = createStyles({
  input: {
    backgroundColor: 'white',
    // backgroundImage: 'linear-gradient(to right, #f5f5f5, #f6f6f6 10%, #f7f7f7 20%, #f8f8f8 30%, #f9f9f9 40%, #fafafa 50%, #fbfbfb 60%, #fcfcfc 70%, #fdfdfd 80%, #fefefe 90%, #ffffff)',
  },
  chip: {
    marginLeft: 5,
    marginBottom: 5,
    borderRadius: 8,
  },
  popper: {
    '& ul': {
      margin: 0,
      padding: 0,
      '& li': {
        listStyleType: 'none',
      },
    },
  },
  icon: {
    backgroundColor: PRIMARY_COLOR,
    borderRadius: 20,
    padding: 2,
    color: 'white',
    cursor: 'pointer',
    width: 20,
    height: 20,
    lineHeight: '20px',
    textAlign: 'center',
    fontWeight: 500,
  },
});

interface IState {
  suggestions: IUserChip[];
  value: string;
}

class UserNameWrapper extends React.Component<Props, IState> {
  private userService = new UserService();
  private ref: any;
  private nodeRef: any;

  public constructor(props: Props) {
    super(props);
    this.state = {
      suggestions: [],
      value: '',
    };
  }

  public onChange = (event: any, { newValue }: any) => {
    const { form, field } = this.props;
    this.setState({
      value: newValue,
    });
    form.setFieldTouched(field.name);
  };

  public onSuggestionSelected = (event: any, { suggestion }: any) => {
    if (suggestion.id === 'create') {
      this.createNewArtist(this.state.value);
    } else {
      this.addSuggestion(suggestion);
    }
  };

  public addSuggestion = (suggestion: any) => {
    const { form, field } = this.props;
    form.setFieldTouched(field.name);
    const foundValue = (field.value || []).find(
      (s: IUser) => s.id === suggestion.id
    );
    const existingCurator =
      this.props.defaultUser && this.props.defaultUser.id === suggestion.id;
    if (!foundValue && !existingCurator) {
      form.setFieldValue(field.name, (field.value || []).concat([suggestion]));
    }
  };

  public createNewArtist = (value: string) => {
    return null;
    /*
        return this.artistService.createArtist({name: value, created_by: this.props.account.url})
            .then(artist => {
                return this.addSuggestion(artist.data);
            })
        */
  };

  public onSuggestionsFetchRequested = () => {
    if (this.state.value.length < 3) {
      return;
    }
    return this.userService
      .getUserRef({
        search: this.state.value,
        type: this.props.inputProps.type,
      })
      .then((r) => {
        this.setState({
          suggestions: r.data,
        });
      })
      .catch((error) => {
        window.console.log('ERROR FETCHING SUGGESTIONS', error);
      });
  };

  public onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
      value: '',
    });
  };

  public renderInputComponent = (inputProps: any) => {
    const { classes, inputRef = () => ({}), ref, ...other } = inputProps;

    return (
      <TextField
        fullWidth={true}
        margin="normal"
        variant="outlined"
        className={classes.root}
        InputProps={{
          inputRef: (node) => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: classes.input,
          },
        }}
        {...other}
      />
    );
  };

  public renderSuggestionsContainer =
    (coordinate: any) =>
    ({ containerProps, children }: any) => {
      const { classes } = this.props;

      return (
        // @ts-ignore
        <Popper
          open={Boolean(children)}
          anchorEl={this.ref}
          style={{ zIndex: 10000 }}
        >
          <div>
            <Paper
              square={true}
              {...containerProps}
              className={classes.popper}
              style={{
                marginTop: 8,
                width: this.ref ? this.ref.clientWidth : null,
                position: 'absolute',
                ...coordinate,
              }}
            >
              {children}
            </Paper>
          </div>
        </Popper>
      );
    };

  public handleDelete = (userId: string) => () => {
    const { form, field } = this.props;
    form.setFieldTouched(field.name);
    const newValues = (field.value || []).filter((value: any) => {
      return value.id !== userId;
    });
    form.setFieldValue(field.name, newValues);
  };

  public componentDidMount(): void {
    this.nodeRef = ReactDOM.findDOMNode(this.ref);
  }

  public render() {
    const { field, form, classes, inputProps, defaultUser } = this.props;

    const { suggestions } = this.state;

    const getSuggestionValue = (suggestion: IUserChip) => {
      return suggestion.id;
    };

    const renderSuggestion = (suggestion: IUserChip) => {
      return (
        <MenuItem key={suggestion.id}>
          <Avatar
            alt={`${suggestion.firstName} ${suggestion.lastName}`}
            src={getSmallmageUrl(suggestion.profilePicture)}
          />
          <span style={{ marginLeft: 10 }}>
            {suggestion.firstName} {suggestion.lastName}
          </span>
        </MenuItem>
      );
    };

    const assignRef = (node: any) => {
      this.ref = node;
    };

    const b = this.nodeRef;
    const coordinate = {
      top: b
        ? (b as any).getBoundingClientRect().y +
          (b as any).getBoundingClientRect().height +
          window.scrollY
        : 0 + window.scrollY,
      left: b ? (b as any).getBoundingClientRect().x : 0,
      width: b ? (b as any).getBoundingClientRect().width : 0,
    };

    const usersFromForm = this.props.form.values[field.name];

    const chips =
      usersFromForm &&
      usersFromForm.length > 0 &&
      usersFromForm.map((user: IUserChip) => {
        return (
          <Chip
            key={user.id}
            avatar={
              <Avatar
                alt={`${user.firstName} ${user.lastName}`}
                src={getSmallmageUrl(user.profilePicture)}
              />
            }
            label={
              <>
                {' '}
                {user.firstName} {user.lastName}
              </>
            }
            onDelete={this.handleDelete(user.id)}
            className={classes.chip}
          />
        );
      });

    return (
      <div>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderSuggestionsContainer={this.renderSuggestionsContainer(
            coordinate
          )}
          renderInputComponent={this.renderInputComponent}
          inputProps={{
            ...inputProps,
            disabled: form.isSubmitting || inputProps.disabled,
            error: !!(form.touched[field.name] && form.errors[field.name]),
            helperText:
              form.touched[field.name] && form.errors[field.name]
                ? form.errors[field.name]
                : inputProps.helperText,
            value: this.state.value,
            onChange: this.onChange,
            classes,
          }}
          ref={assignRef}
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flexGrow: 1, flexBasis: 0 }}>
            {chips}
            {defaultUser && (
              <Chip
                key={defaultUser.id}
                avatar={
                  <Avatar
                    alt={`${defaultUser.firstName} ${defaultUser.lastName}`}
                    src={getSmallmageUrl(defaultUser.profilePicture)}
                  />
                }
                label={
                  <>
                    {' '}
                    {defaultUser.firstName} {defaultUser.lastName}
                  </>
                }
                // onDelete={this.handleDelete(defaultUser.id)}
                className={classes.chip}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default compose<Props, IUserNameWrapperProps>(
  withStyles(style),
  withAccount
)(UserNameWrapper);
