import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { Backdrop, Grid } from '@mui/material';
import {
  IWithAccountProps,
  withAccount,
} from '../../containers/account/InjectAccount';
import SubTitle from '../../library/dataDisplay/typography/SubTitle';
import EditExhibitionButton from '../icons/EditExhibitionButton';
import { useState } from 'react';
import SocialModal from '../../containers/social/SocialModal';
import RequestCatalog from './RequestCatalog';
import ACGShareButton from '../../library/inputs/ACGShareButton';
import ACGLikeButton from '../../library/inputs/ACGLikeButton';
import IconLike from '../../library/dataDisplay/icons/IconLike';
import { DARK_GREY_COLOR, PRIMARY_COLOR } from '../../styles/constants';
import IconViews from '../../library/dataDisplay/icons/IconViews';
import { ACGModal } from '../modal/ACGModal';

export interface IOnlineExhibitionActionButtonsProps {
  exhibition: any;
  showShareButton: boolean;
  editOnlineExhibition: (env: any) => void;
  deleteOnlineExhibition: (env: any) => void;
  onLikeClick: () => void;
}

const styles = createStyles({
  actionButtons: {
    paddingTop: 5,
  },
  actionItems: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  actionItem: {
    display: 'inline-block',
    cursor: 'pointer',
    fontSize: 15,
  },
  icon: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    fontSize: 12,
    color: DARK_GREY_COLOR,
    '&:hover': {
      color: PRIMARY_COLOR,
    },
  },
});

type Props = WithStyles<string> &
  IWithAccountProps &
  IOnlineExhibitionActionButtonsProps &
  WithWidth;

function OnlineExhibitionActionButtons(props: Props) {
  const {
    exhibition,
    showShareButton,
    classes,
    account,
    editOnlineExhibition,
    onLikeClick,
    width,
    deleteOnlineExhibition,
  } = props;

  const isMobile = isWidthDown('xs', width);

  const [modalCatalog, setModalCatalog] = useState<boolean>(false);
  const [shareDialogOpen, setShareDialogOpen] = useState<boolean>(false);

  // const requestCatalogModal = (evt: any) => { setModalCatalog(true);};
  const onCloseCatalog = () => setModalCatalog(false);

  const onShareClick = () => {
    setShareDialogOpen(true);
  };
  const onShareClose = () => {
    setShareDialogOpen(false);
  };

  const onlineExhibitionUrl = {
    title: exhibition.title,
    url:
      exhibition.visibility !== 'PUBLIC'
        ? `online-exhibition/${exhibition.url}?key=${exhibition.access_key}`
        : `online-exhibition/${exhibition.url}`,
  };

  const isLiking = () => {
    if (account) {
      return !!exhibition.likes.find((i: any) => i.id === account.id);
    }
    return false;
  };

  const liked = isLiking();

  return (
    <>
      <Grid
        container={true}
        alignItems={'center'}
        style={
          isMobile ? { margin: '40px 0px' } : { justifyContent: 'flex-end' }
        }
        spacing={1}
      >
        {showShareButton && (
          <Grid
            item={true}
            className={classes.actionItem}
            onClick={onShareClick}
          >
            <ACGShareButton isButton={true} />
          </Grid>
        )}
        <Grid item={true} className={classes.actionItem} onClick={onLikeClick}>
          <ACGLikeButton isButton={true} liked={liked} />
        </Grid>
        <Grid item={true}>
          <SubTitle className={classes.icon}>
            <IconLike height={20} liked={false} fill={DARK_GREY_COLOR} />
            {exhibition.number_of_likes}
          </SubTitle>
        </Grid>
        <Grid item={true}>
          <SubTitle className={classes.icon}>
            <IconViews height={20} />
            {exhibition.number_of_views}
          </SubTitle>
        </Grid>

        {account && exhibition.created_by.id === account.id && (
          <>
            <div
              className={classes.actionItem}
              style={{ marginLeft: 10 }}
              onClick={editOnlineExhibition}
            >
              <EditExhibitionButton />
            </div>

            <div
              className={classes.actionItem}
              style={{ marginLeft: 10 }}
              onClick={deleteOnlineExhibition}
            >
              <SubTitle className={`${classes.link}`}>
                <u>Delete Exhibition</u>
              </SubTitle>
            </div>
          </>
        )}
      </Grid>
      {/* MODALS SECTION */}
      {modalCatalog && (
        <ACGModal
          open={modalCatalog}
          onClose={onCloseCatalog}
          BackdropComponent={Backdrop}
          className={classes.modal}
        >
          <div className={isMobile ? classes.contentMobile : classes.content}>
            <RequestCatalog
              onCloseEvent={onCloseCatalog}
              exhibitionId={exhibition.id}
              galleryInfo={
                exhibition.gallery || exhibition.venue_id || undefined
              }
              title={exhibition.title}
            />
          </div>
        </ACGModal>
      )}

      {exhibition && (
        <SocialModal
          open={shareDialogOpen}
          onClose={onShareClose}
          object={{
            type: 'URL',
            object: onlineExhibitionUrl,
            showIframeOption: false,
          }}
        />
      )}
    </>
  );
}

export default compose<Props, IOnlineExhibitionActionButtonsProps>(
  withStyles(styles),
  withAccount,
  withWidth()
)(OnlineExhibitionActionButtons);
