import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { withAccount, IWithAccountProps } from '../account/InjectAccount';
import { ButtonProps } from '@mui/material/Button';
import ConnectForm from './ConnectForm';
import { IUser } from '../../types/user';
import { StyledButton } from '@acg/frontend-ui-artpool';
import { ACGModal } from '../../components/modal/ACGModal';

export interface IConnectButtonProps {
  user: IUser;
  buttonSize?: any;
}

interface IState {
  open: boolean;
}

const styles = createStyles({
  button: {
    textTransform: 'none',
    borderRadius: 4,
  },
  mobileButton: {
    marginTop: 5,
  },
});

type Props = IConnectButtonProps &
  IWithAccountProps &
  WithWidth &
  WithStyles<string>;

class ConnectButton extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  public openModal = () => this.setState({ open: true });
  public closeModal = () => this.setState({ open: false });

  public render() {
    const { user, classes, width } = this.props;

    const buttonProps: ButtonProps = {
      variant: 'outlined',
    };

    const isPad = isWidthDown('md', width);

    return (
      <>
        <StyledButton
          size={this.props.buttonSize || 'large'}
          {...buttonProps}
          className={`${classes.button} ${isPad && classes.mobileButton}`}
          onClick={this.openModal}
        >
          Message
        </StyledButton>
        <ACGModal
          open={this.state.open}
          onClose={this.closeModal}
          key={'modal_service'}
        >
          <ConnectForm onClickAway={this.closeModal} user={user} />
        </ACGModal>
      </>
    );
  }
}

export default compose<Props, IConnectButtonProps>(
  withAccount,
  withStyles(styles),
  withWidth()
)(ConnectButton);
