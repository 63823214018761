import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import trash from '../../img/trash.svg';
import { compose } from 'recompose';
import {} from '@mui/material';

export interface ITrashProps {
  big?: boolean;
}

type Props = ITrashProps & WithStyles<string>;

const styles = createStyles({
  size: {
    width: 14,
    cursor: 'pointer',
  },
  big: {
    width: 37,
    cursor: 'pointer',
  },
});

class Trash extends React.Component<Props, any> {
  public render() {
    const { classes, big } = this.props;

    return <img src={trash} className={big ? classes.big : classes.size} />;
  }
}

export default compose<Props, ITrashProps>(withStyles(styles))(Trash);
