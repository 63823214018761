import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { Grid } from '@mui/material';
import { compose } from 'recompose';
import {
  IWithAccountProps,
  withAccount,
} from '../../containers/account/InjectAccount';
import { useSelector } from 'react-redux';
import { IStoreState } from '../../store';
import CommentForm from '../../containers/comment/CommentForm';
import { IComment } from '../../types/comments';
import { DARK_GREY_COLOR, GREY_COLOR } from '../../styles/constants';
import ProfileComment from '../profile/ProfileComment';
import Caption from '../../library/dataDisplay/typography/Caption';
import moment from 'moment';
import { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { IPost } from '../../types/posts';

export interface ICommentSectionProps {
  post?: IPost;
  additionalButton?: React.ReactNode;
}

const styles = createStyles({
  commentContainer: {
    backgroundColor: GREY_COLOR,
    borderRadius: 8,
    marginTop: 10,
  },
});

type Props = WithStyles<string> &
  IWithAccountProps &
  ICommentSectionProps &
  WithWidth &
  RouteComponentProps<{}>;

const CommentSection: React.FC<Props> = (props) => {
  const { account, classes, history, post } = props;

  const {
    comments,
    // isLoading,
    // error
  } = useSelector((storeState: IStoreState) => storeState.comments);

  const [editing, setEditing] = useState<string | undefined>();

  const onUpdate = () => {
    setEditing(undefined);
  };
  const onCancel = () => {
    setEditing(undefined);
  };

  const renderComment = (comment: IComment) => {
    const onEdit = () => setEditing(comment.id);

    const onProfileClick = () => {
      history.push(`/users/${comment.created_by.id}/posts`);
    };

    const additionalButton =
      comment.created_by.id === account.id ? (
        <Caption>
          <div
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={onEdit}
          >
            Edit Comment
          </div>
        </Caption>
      ) : (
        <div />
      );

    return (
      <Grid
        key={comment.post_id}
        container={true}
        spacing={1}
        style={{ padding: 10 }}
      >
        <Grid item={true} xs={12} style={{ textAlign: 'right' }}>
          {additionalButton}
        </Grid>
        <Grid item={true} xs={12} md={3} style={{ cursor: 'pointer' }}>
          <ProfileComment
            user={comment.created_by}
            additionalButton={<></>}
            onClick={onProfileClick}
          />
        </Grid>
        <Grid item={true} xs={12} md={9} style={{ alignSelf: 'center' }}>
          <p style={{ fontSize: 12 }}>{comment.message}</p>
        </Grid>
        <Grid item={true} xs={12} style={{ textAlign: 'right' }}>
          <Caption>{moment(comment.created_at).fromNow()}</Caption>
        </Grid>
        <Grid
          item={true}
          xs={12}
          style={{ borderTop: `1px solid ${DARK_GREY_COLOR}`, marginLeft: 10 }}
        />
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Grid container={true}>
        <Grid item={true} xs={12}>
          {post && account && (
            <CommentForm
              post={post}
              isExhibition={true}
              user={account}
              onUpdate={onUpdate}
              onDone={onCancel}
            />
          )}
        </Grid>
        <Grid item={true} xs={1} />
        <Grid item={true} xs={12} sm={9} className={classes.commentContainer}>
          {post &&
            comments.length > 0 &&
            comments.map((comment: IComment) => {
              return editing === comment.id ? (
                <React.Fragment key={comment.post_id}>
                  <CommentForm
                    initialValues={comment}
                    post={post}
                    isExhibition={true}
                    user={account}
                    onUpdate={onUpdate}
                    onDone={onCancel}
                  />
                </React.Fragment>
              ) : (
                renderComment(comment)
              );
            })}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default compose<Props, ICommentSectionProps>(
  withStyles(styles),
  withWidth(),
  withRouter,
  withAccount
)(CommentSection);
