import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { UserViewerType } from '../../types/user';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Formik, FormikProps, Field } from 'formik';
import { Grid } from '@mui/material';
import { compose } from 'recompose';
import { withAccount, IWithAccountProps } from '../account/InjectAccount';
import { IValidationConfig, generateValidator } from '../../utils/formatters';
import SelectInput from '../../components/form/SelectInput';
import FormSubmit from '../../components/form/FormSubmit';
import HighlightedTitle from '../../library/dataDisplay/typography/HighlightedTitle';
import LocationFinder from '../../components/form/LocationFinder';
export interface IViewerStep2Props {}

type Props = RouteComponentProps<{}> &
  IViewerStep2Props &
  IWithAccountProps &
  WithStyles<string>;

interface IFormValue {
  type: UserViewerType;
  location: {
    address: string;
    value: string;
    placeId: string;
    latLng: [number, number];
  };
}

interface IState {
  error: boolean;
}

const styles = createStyles({
  main: {
    width: '100%',
    position: 'relative',
    zIndex: 1,
    minHeight: '100%',
  },
  navbar: {
    flexGrow: 1,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
  },
  colorDefault: {
    backgroundColor: 'white',
    height: 60,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  content: {
    zIndex: 2,
    height: '100%',
    width: '100%',
    minHeight: '100%',
    justifyContent: 'center',
  },
  wrapper: {},
  logo: {
    height: 40,
    float: 'left',
    marginRight: 15,
  },
  title: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    float: 'left',
    minWidth: 220,
    cursor: 'pointer',
  },
  titleSmall: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    float: 'left',
    minWidth: 125,
    cursor: 'pointer',
  },
  left: {
    float: 'left',
    width: '100%',
  },
  right: {
    float: 'right',
    width: 250,
  },
  companyName: {
    lineHeight: '40px',
  },
  companyNameSmall: {
    lineHeight: '40px',
    fontSize: '15px',
  },
  beta: {
    width: 26,
    height: 14,
    fontFamily: 'Replica-Regular',
    fontSize: 12,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -0.12,
    textAlign: 'center',
    color: '#6236ff',
    marginLeft: 5,
  },
  grow: {
    flex: 1,
  },

  formArea: {
    justifyContent: 'center',
    maxWidth: '50%',
    flexBasis: '50%',
  },
  contentMobile: {
    zIndex: 2,
    height: '100%',
    width: '100%',
    minHeight: '100%',
    justifyContent: 'center',
    paddingTop: 65,
  },
});

class ViewerStep2 extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: false,
    };
  }

  public onSubmit = async (values: IFormValue, { setSubmitting }: any) => {
    const { history, account, updateAccount } = this.props;

    this.setState({ error: false });
    try {
      await updateAccount({
        ...account,
        ...values,
      });
      history.push('/login');
    } catch (err) {
      this.setState({ error: true });
    }
    setSubmitting(false);
  };

  public renderForm = (props: FormikProps<IFormValue>) => {
    return (
      <form onSubmit={props.handleSubmit}>
        <Grid
          container={true}
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item={true} xs={12}>
            <HighlightedTitle>Tell us a bit about yourself</HighlightedTitle>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <Field
              name="type"
              component={SelectInput}
              inputLabelProps={{
                children: 'You are a:',
              }}
              options={[
                { label: 'Company', value: 'COMPANY' },
                { label: 'Art Consultant', value: 'ART CONSULTANT' },
                { label: 'Art Dealer', value: 'ART DEALER' },
                { label: 'Art Fair', value: 'ART FAIR' },
                { label: 'Auction House', value: 'AUCTION HOUSE' },
                { label: 'Collector', value: 'COLLECTOR' },
                { label: 'Art Lover', value: 'ART LOVER' },
                { label: 'Institution', value: 'INSTITUTION' },
                { label: 'Journalist', value: 'JOURNALIST' },
                { label: 'Gallery', value: 'GALLERY' },
                { label: 'Artist', value: 'ARTIST' },
              ]}
            />

            <Field
              name="location"
              component={LocationFinder}
              inputProps={{
                id: 'location',
                label: 'Your City',
                type: 'location',
              }}
            />
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <FormSubmit disabled={props.isSubmitting}>Next Step</FormSubmit>
          </Grid>
        </Grid>
      </form>
    );
  };

  public render() {
    const initialValues: IFormValue = {
      type: 'ART CONSULTANT',
      location: {
        address: '',
        value: '',
        placeId: '',
        latLng: [0, 0],
      },
    };

    const config: IValidationConfig = {
      type: ['REQUIRED'],
      location: ['REQUIRED'],
    };

    const validate = generateValidator(config);

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        validate={validate}
        render={this.renderForm}
      />
    );
  }
}

export default compose<Props, IViewerStep2Props>(
  withRouter,
  withAccount,
  withStyles(styles)
)(ViewerStep2);
