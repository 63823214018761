import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import {} from '@mui/material';
import { compose } from 'recompose';
import { IArtProjectPost } from '../../types/posts';
import Title from '../../library/dataDisplay/typography/Title';
import SubTitle from '../../library/dataDisplay/typography/SubTitle';
import ContentContainer from './ContentContainer2';
import { getImageUrl } from '../../utils/imageUrl';
import { dateToStringParser } from '../../utils/datetimehelper';

export interface IArtProjectProps {
  post: IArtProjectPost;
  onClick: () => void;
  onCommentClick: () => void;
  onLike: (post: IArtProjectPost) => void;
  onShare: () => void;
  like: boolean;
  numberOfLike: number;
  onCategoryButtonClick: (selectedCategory: string) => void;
  nonClickable?: boolean;
}

const styles = createStyles({
  img: {},
  oneLiner: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  secondLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  description: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    backgroundColor: '#fff',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    position: 'relative',
    fontWeight: 600,
    lineHeight: '1.4em',
  },

  inspiration: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'table',
    height: '100%',
    width: '100%',
    minHeight: 379,
  },
  span: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
});

type Props = WithStyles<string> & IArtProjectProps;

class ArtProjectContent extends React.Component<Props, any> {
  public render() {
    const {
      post,
      onClick,
      like,
      numberOfLike,
      classes,
      onCommentClick,
      onShare,
      onCategoryButtonClick,
    } = this.props;

    const postVenue =
      post.venue && typeof post.venue === 'string'
        ? post.venue
        : post.venue_name;

    const wrapText = (val?: string) => {
      const maxLength = 400;
      if (!val) {
        return '';
      }
      const trimmedVal = val.replace(/\r?\n|\r/g, '');
      if (trimmedVal.length > maxLength) {
        const subVal = `${trimmedVal.substr(0, maxLength)}...`;
        return subVal;
      }
      return trimmedVal;
    };

    // TEXT FLAGS
    // 0 - NO START DATE, NO END DATE, NO VENUE
    // 1 - START DATE, NO END DATE, NO VENUE
    // 2 - NO START DATE, END DATE, NO VENUE
    // 4 - NO START DATE, NO END DATE, VENUE
    let mask = 0;

    mask = post.start_date ? mask | 1 : mask;
    mask = post.end_date ? mask | 2 : mask;
    mask = postVenue ? mask | 4 : mask;

    const footer = (
      <div>
        <Title className={classes.oneLiner}>{post.title}</Title>
        {!post.title && <Title className={classes.oneLiner}>&nbsp;</Title>}

        {mask === 0 && (
          <>
            <SubTitle>&nbsp;</SubTitle>
            <SubTitle>&nbsp;</SubTitle>
          </>
        )}
        {(mask === 1 || mask === 5) && (
          <>
            <SubTitle className={classes.secondLine}>
              Start Date {dateToStringParser(post.start_date, 'MMM Do YYYY')}{' '}
            </SubTitle>
          </>
        )}
        {(mask === 2 || mask === 6) && (
          <>
            <SubTitle className={classes.secondLine}>
              End Date {dateToStringParser(post.end_date, 'MMM Do YYYY')}{' '}
            </SubTitle>
          </>
        )}
        {(mask === 3 || mask === 7) && (
          <>
            <SubTitle className={classes.secondLine}>
              {dateToStringParser(post.start_date, 'MMM Do YYYY')} -{' '}
              {dateToStringParser(post.end_date, 'MMM Do YYYY')}
            </SubTitle>
          </>
        )}
        {mask === 4 && (
          <>
            <SubTitle className={classes.secondLine}>{postVenue}</SubTitle>
            <SubTitle>&nbsp;</SubTitle>
          </>
        )}
        {mask > 0 && mask < 4 && (
          <>
            <SubTitle>&nbsp;</SubTitle>
          </>
        )}
        {mask > 4 && (
          <>
            <SubTitle className={classes.secondLine}>{postVenue}</SubTitle>
          </>
        )}
      </div>
    );

    let featured = (
      <div className={classes.description}>
        <div className={classes.inspiration}>
          <span className={classes.span}>{wrapText(post.title)}</span>
        </div>
      </div>
    );

    if (post.featured_image) {
      featured = (
        <>
          <div
            style={{
              width: '100%',
              height: '100%',
              minHeight: 379,
              background: `url(${getImageUrl(
                post.featured_image
              )}) no-repeat center`,
              backgroundSize: 'cover',
            }}
          />
        </>
      );
    }

    const onLike = () => this.props.onLike(post);

    return (
      <ContentContainer
        footer={footer}
        onCommentClick={onCommentClick}
        featured={featured}
        onClick={onClick}
        onLike={onLike}
        liked={like}
        numberOfLike={numberOfLike}
        post={post}
        onShare={onShare}
        categorySubType={post.sub_type}
        onCategoryButtonClick={onCategoryButtonClick}
        nonClickable={this.props.nonClickable}
      />
    );
  }
}

export default compose<Props, IArtProjectProps>(withStyles(styles))(
  ArtProjectContent
);
