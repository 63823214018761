import {
  applyMiddleware,
  combineReducers,
  createStore,
  Middleware,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './modules/rootSaga';
import pendingCurators from './modules/pendingCurators/reducer';
import institutions from './modules/institutions/reducer';
import exhibitions from './modules/exhibitions/reducer';
import account from './modules/account/reducer';
import comments from './modules/comments/reducer';
import { authSlice, AuthState } from '@acg/auth/frontend';
import { IPendingCuratorsState } from './modules/pendingCurators/types';
import { IInstitutionsState } from './modules/institutions/types';
import { IExhibitionsState } from './modules/exhibitions/types';
import { IAccountState } from './modules/account/types';
import { ICommentState } from './modules/comments/types';
import { composeWithDevTools } from 'redux-devtools-extension';

export interface IStoreState {
  pendingCurators: IPendingCuratorsState;
  institutions: IInstitutionsState;
  exhibitions: IExhibitionsState;
  account: IAccountState;
  comments: ICommentState;
  authReducer: AuthState;
}

const reducer = combineReducers<IStoreState>({
  pendingCurators,
  institutions,
  exhibitions,
  account,
  comments,
  authReducer: authSlice.reducer,
});

const sagaMiddleware = createSagaMiddleware();
const middlewares: Middleware[] = [sagaMiddleware];

const enhancer = applyMiddleware(...middlewares);
export const store = createStore(reducer, composeWithDevTools(enhancer));

sagaMiddleware.run(rootSaga);
