import * as React from 'react';
import { useState } from 'react';
import { IPost } from '../../types/posts';
import PostModal from '../../components/modal/ACGModalContent';
import FullPost from '../../components/post/FullPost';
import Loading from '../../components/loading/Loading';
import { IError } from '../../types/returns';
import VisitorService from '../../services/VisitorServices';
import FormCancel from '../../components/form/FormCancel';
import { generateMetaForPost } from '../../components/meta';
import SocialModal from '../social/SocialModal';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import { useDispatch, useSelector } from 'react-redux';
import { IAuthRootState, showAuthUI } from '@acg/auth/frontend';
import { ACGAppPaths } from '@acg/shared/const';

export interface VisitorPostModalProps {
  onLike: (post: IPost) => void;
  onDone: () => void;
}

interface QueryParams {
  userId: string;
  postId: string;
}

export const VisitorPostModal = (props: VisitorPostModalProps) => {
  const [loading, setLoading] = useState(true);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [error, setError] = useState<IError>();
  const [post, setPost] = useState<IPost>();
  const dispatch = useDispatch();
  const { user, renderAuthenticator } = useSelector(
    (root: IAuthRootState) => root.authReducer
  );
  const history = useHistory();
  const { params } = useRouteMatch<QueryParams>();
  const { postId, userId } = params;

  const visitorService = new VisitorService();
  const authenticateUser = () => dispatch(showAuthUI());

  useAsyncEffect(async () => {
    setLoading(true);
    try {
      const postResp = await visitorService.getPublicPost(postId);
      setPost(postResp.data);
    } catch (err) {
      setError(err as IError);
    }
    setLoading(false);
  }, [postId, userId]);

  const { onDone } = props;

  const onAuthorClick = () => {
    if (post && post.venue_id) {
      return history.push(`/venues/${post.venue_id.url}/posts`);
    } else if (post) {
      return history.push(`/users/${post.created_by.url}/posts`);
    }
    return;
  };

  if (loading) {
    return (
      <PostModal onClickAway={onDone}>
        <Loading />
      </PostModal>
    );
  }

  if (!post) {
    return (
      <PostModal onClickAway={onDone}>
        <div>
          this content has either been deleted or not available for you.
        </div>
        <FormCancel onClick={onDone}>Close</FormCancel>
      </PostModal>
    );
  }

  const redirectToAuth = () => history.push(ACGAppPaths.LOGIN, {from: history.location});

  const liking = (lpost: IPost) => false;
  const onLike = (lpost: IPost) => {
    redirectToAuth()
    return { data: lpost } as any;
  };



  const onShareClick = () => setShareDialogOpen(true);
  const onShareClose = () => setShareDialogOpen(false);
  const onTagClick = () => ({});

  if (renderAuthenticator) {
    return <></>;
  }

  return (
    <PostModal onClickAway={shareDialogOpen ? undefined : onDone}>
      <div>
        {generateMetaForPost(post)}
        {
          <FullPost
            onTagClick={onTagClick}
            onShareClick={onShareClick}
            liking={liking}
            onLike={redirectToAuth}
            post={post}
            onAuthorClick={onAuthorClick}
            onCommentClick={redirectToAuth}
          />
        }
        {
          <SocialModal
            open={shareDialogOpen}
            onClose={onShareClose}
            object={{
              type: 'POST',
              object: post,
            }}
          />
        }
        {<FormCancel onClick={onDone}>Close</FormCancel>}
      </div>
    </PostModal>
  );
};
