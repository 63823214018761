import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { withAccount, IWithAccountProps } from '../account/InjectAccount';
import { Box, Grid } from '@mui/material';
import StyledButton from '../../components/buttons/StyledButton';
import * as accountActions from '../../store/modules/account/actions';
import { useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { DARK_GREY_COLOR, PRIMARY_COLOR } from '../../styles/constants';

type Props = IWithAccountProps & WithStyles<string>;

const styles = createStyles({
  title: {
    color: DARK_GREY_COLOR,
    textAlign: 'center',
  },
  button: {
    color: '#000000',
    padding: '12px',
    '&:hover': {
      backgroundColor: PRIMARY_COLOR,
      color: '#ffffff',
    },
  },
});

const OtherArtStep: React.FC<Props> = (props) => {
  const { account, classes } = props;

  const dispatch = useDispatch();

  const handleSubmit = (event: any) => {
    const profile: any =
      event.target.textContent === 'Curator' ? 'CURATOR' : 'VIEWER';

    dispatch(
      accountActions.update({
        request: {
          ...account,
          profile,
          type: event.target.textContent.toUpperCase(),
        },
      })
    );

    setTimeout(() => location.reload(), 1000);
  };

  return (
    <Box
      mx={2}
      style={{ height: 'calc(100vh - 90px)', paddingLeft: 15 }}
      justifyContent="center"
      display="flex"
    >
      <Grid container={true} direction="column" spacing={2}>
        <Grid
          container={true}
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid
            item={true}
            container={true}
            justifyContent={'center'}
            xs={12}
            sm={12}
            md={12}
          >
            <label className={classes.title}>You are:</label>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <StyledButton
              type="submit"
              fullWidth={true}
              variant="outlined"
              onClick={handleSubmit}
              className={classes.button}
            >
              Art Dealer
            </StyledButton>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <StyledButton
              type="submit"
              fullWidth={true}
              variant="outlined"
              onClick={handleSubmit}
              className={classes.button}
            >
              Art Consultant
            </StyledButton>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <StyledButton
              type="submit"
              fullWidth={true}
              variant="outlined"
              onClick={handleSubmit}
              className={classes.button}
            >
              Art Critic
            </StyledButton>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <StyledButton
              type="submit"
              fullWidth={true}
              variant="outlined"
              onClick={handleSubmit}
              className={classes.button}
            >
              Journalist
            </StyledButton>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <StyledButton
              type="submit"
              fullWidth={true}
              variant="outlined"
              onClick={handleSubmit}
              className={classes.button}
            >
              PR
            </StyledButton>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <StyledButton
              type="submit"
              fullWidth={true}
              variant="outlined"
              onClick={handleSubmit}
              className={classes.button}
            >
              Company
            </StyledButton>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <StyledButton
              type="submit"
              fullWidth={true}
              variant="outlined"
              onClick={handleSubmit}
              className={classes.button}
            >
              Auction House Staff
            </StyledButton>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <StyledButton
              type="submit"
              fullWidth={true}
              variant="outlined"
              onClick={handleSubmit}
              className={classes.button}
            >
              Art Fair Staff
            </StyledButton>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <StyledButton
              type="submit"
              fullWidth={true}
              variant="outlined"
              onClick={handleSubmit}
              className={classes.button}
            >
              Auction House Staff
            </StyledButton>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <StyledButton
              type="submit"
              fullWidth={true}
              variant="outlined"
              onClick={handleSubmit}
              className={classes.button}
            >
              Artist Studio Staff
            </StyledButton>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <StyledButton
              type="submit"
              fullWidth={true}
              variant="outlined"
              onClick={handleSubmit}
              className={classes.button}
            >
              Other
            </StyledButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default compose(
  withAccount,
  withStyles(styles)
)(OtherArtStep as unknown as any);
