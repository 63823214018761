/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-west-3',
  aws_cognito_identity_pool_id:
    'eu-west-3:ce25f206-8d37-4a17-ad64-eef258bf3476',
  aws_cognito_region: 'eu-west-3',
  aws_user_pools_id: 'eu-west-3_ALmedZfT3',
  aws_user_pools_web_client_id: '44v9s9mvekgf0g530go05ol7sn',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
};

export default awsmobile;
