import { BigNumber, BigNumberish, ethers, Signer } from 'ethers';
import {
  BusinessLogicV1,
  BusinessLogicV1__factory,
  LazyMintingNftParams,
} from '..';
import {
  LazyMinting,
  LazyMinting__factory,
  Membership,
  Membership__factory,
  NftCollectionV1,
  NftCollectionV1__factory,
} from '../../typechain-types';
import { UserFacingError } from '@acg/shared/utilities';

export const ZERO_WALLET = '0x0000000000000000000000000000000000000000';

// Captures 0x + 4 characters, then the last 4 characters.
const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

export interface AllContractAddresses {
  businessLogic: string;
}

export interface DeployLazyMintingParams {
  name: string;
  symbol: string;
  baseUri: string;
  dropStart: number;
  dropEnd: number;
  nftParamsArr: LazyMintingNftParams[];
}

export interface DeployNftCollectionV1Params extends DeployLazyMintingParams {
  artpoolWallet: string;
  businessLogicAddress: string;
}

export interface DeployMembershipParams extends DeployLazyMintingParams {
  artpoolWallet: string;
  tierList: number[];
  presaleAccess: string[];
}

export const randomEvmClient = (rcpUrl: string) => {
  const wallet = ethers.Wallet.createRandom().connect(
    ethers.getDefaultProvider(rcpUrl)
  );
  return new EvmClient(wallet);
};

/**
 * Truncates an ethereum address and wraps in brackets e.g. "(0x0000…0000)"
 */
export const formatAddress = (address: string) => {
  const match = address.match(truncateRegex);
  if (!match) return address;
  return `(${match[1]}…${match[2]})`;
};

export enum Web3Errors {
  NO_PROVIDER = `We couldn't detect your Ethereum wallet`,
  NO_ACCOUNTS = 'MetaMask is locked or no accounts are connected',
  NOT_METAMASK = 'Sorry, the wallet you tried to connect is not supported, we recommend using MetaMask',
  MULTIPLE_PROVIDERS = 'Do you have multiple wallets installed? If so please uninstall all but one, we recommend using MetaMask',
  NOT_ETH_MAIN_NET = 'Please switch network to the Ethereum Mainnet',
  PROCESSING_REQUEST = 'Already processing a request',
}

export enum CheckNftMintAllowedErrors {
  WRONG_PRICE_SET = 'Please submit the asking price',
  INSUFFICIENT_MEMBERSHIP_TIER = 'Insufficient membership tier',
  DROP_ENDED = 'This drop has ended',
  NOT_STARTED = 'This drop has not started yet',
  SOLD_OUT = 'All editions of this NFT have been minted',
}

export enum CheckMembershipMintAllowedErrors {
  NO_PRICE_SET = 'You need to submit the correct nft price',
  PRE_SALE_MAX = 'You have reached the maximum pre-sales mints allowed per wallet',
  ONLY_WHITELISTED = 'Only whitelisted users can make a pre-sale purchase',
  SOLD_OUT = 'All editions of this NFT have been minted',
}

export const membershipErrors = [
  CheckNftMintAllowedErrors.INSUFFICIENT_MEMBERSHIP_TIER,
  CheckMembershipMintAllowedErrors.ONLY_WHITELISTED,
];

export const metaMaskProvider = (window: any, onStatusChange: () => void) => {
  const ethereum = window.ethereum;
  if (!ethereum) {
    throw new UserFacingError(Web3Errors.NO_PROVIDER);
  }
  ethereum.on('chainChanged', onStatusChange);
  ethereum.on('accountsChanged', onStatusChange);
  ethereum.on('connect', onStatusChange);

  if (!ethereum.isMetaMask) {
    throw new UserFacingError(Web3Errors.NOT_METAMASK);
  }
  if (ethereum.providers) {
    throw new UserFacingError(Web3Errors.MULTIPLE_PROVIDERS);
  }
  return ethereum;
};

export const toEpochSeconds = (d: Date) => Math.floor(d.getTime() / 1000);

export const dropEndEpochSeconds = (d?: Date) => (d ? toEpochSeconds(d) : 0);

export const fromEpochSeconds = (seconds: BigNumberish): Date => {
  return new Date(BigNumber.from(seconds).toNumber() * 1000);
};

export const normalizeAddress = (address: string) =>
  ethers.utils.getAddress(address);

export class EvmClient {
  constructor(private signer: Signer) {}

  public async deployNftCollectionV1(
    params: DeployNftCollectionV1Params,
    gasLimit: number
  ): Promise<NftCollectionV1> {
    const {
      name,
      symbol,
      artpoolWallet,
      dropStart,
      dropEnd,
      businessLogicAddress,
      baseUri,
      nftParamsArr,
    } = params;
    const factory = new NftCollectionV1__factory(this.signer);
    return factory.deploy(
      name,
      symbol,
      baseUri,
      artpoolWallet,
      dropStart,
      dropEnd,
      businessLogicAddress,
      nftParamsArr,
      { gasLimit }
    );
  }

  public async deployMembership(
    params: DeployMembershipParams
  ): Promise<Membership> {
    const {
      name,
      symbol,
      baseUri,
      artpoolWallet,
      dropStart,
      dropEnd,
      nftParamsArr,
      tierList,
      presaleAccess,
    } = params;
    const factory = new Membership__factory(this.signer);

    return factory.deploy(
      name,
      symbol,
      baseUri,
      artpoolWallet,
      dropStart,
      dropEnd,
      nftParamsArr,
      tierList,
      presaleAccess
    );
  }

  public connectLazyMinting(address: string): LazyMinting {
    return LazyMinting__factory.connect(address, this.signer);
  }

  public connectNftCollectionV1(address: string): NftCollectionV1 {
    return NftCollectionV1__factory.connect(address, this.signer);
  }

  public connectBusinessLogicV1(address: string): BusinessLogicV1 {
    return BusinessLogicV1__factory.connect(address, this.signer);
  }

  public connectMembership(address: string): Membership {
    return Membership__factory.connect(address, this.signer);
  }
}

export const getValidAddress = () => ethers.Wallet.createRandom().getAddress();

export enum LazyMintingEventType {
  // These events are emitted when a token is minted
  EDITION_MINTED = 'EditionMinted',
  // These events are emitted when a token is transferred from one address to another
  TRANSFER = 'Transfer',
  // These events are emitted when an edition is minted and the payment is transferred from the contract to a stakeholder
  // For each edition minted event there may be multiple minting transfers
  MINTING_TRANSFER = 'MintingTransfer',
}
