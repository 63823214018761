import { NftClient, ProjectClient } from '@acg/artpool-api-spec';
import { EvmClient } from './evm';
import { EmailClient } from './email';
import { AuthApiRoot } from '@acg/shared/api-spec-utils';
import { MyAccountClient } from './my-account';
import { UserClient } from './user';
import { VenueClient } from './venue';
import { FileClient } from './file';

export class ArtpoolApiClient extends AuthApiRoot {
  public nftApi = () => new NftClient(this.getChildParams());

  public projectApi = () => new ProjectClient(this.getChildParams());

  public evmApi = () => new EvmClient(this.getChildParams());

  public myAccountApi = () => new MyAccountClient(this.getChildParams());

  public emailApi = () => new EmailClient(this.getChildParams());

  public usersApi = () => new UserClient(this.getChildParams());

  public venuesApi = () => new VenueClient(this.getChildParams());

  public fileApi = () => new FileClient(this.getChildParams());
}
