import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import Login from './login/Login';
import Reset from './reset/Reset';
import Logout from './logout/Logout';
import { FaqPage } from './FaqPage';
import ForInstitutionsPage from './home/ForInstitutionsPage';
import ExhibitionsPage from './home/ExhibitionsPage';
import Blog from './home/Blog';
import Legal from './legal/Legal';
import ModifyPassword from './profile/ModifyPassword';
import Posts from './posts/Posts';
import UserList from './users/CuratorListing';
import UserProfile from './users/UserProfile';
import VisitorComponent from './visitors/VisitorComponent';
import OnlineExhibitionForm from './onlineexhibition/OnlineExhibitionForm';
import OnlineExhibition from './onlineexhibition/OnlineExhibition';
import LabBox2 from './home/LabBox2';
import BackOffice from './backoffice/BackOffice';
import Institutions from './institutions/Institutions';
import InstitutionProfile from './institution/InstitutionProfile';
import VisitorInstitutionProfile from './visitors/VisitorInstitutionProfile';
import NotFound from '../components/errors/NotFound';
import * as React from 'react';
import { useState } from 'react';
import PublicOnlineLayout from '../library/layout/container/PublicOnlineLayout';
import MainPublicLayout from '../library/layout/container/MainPublicLayout';
import { generateGenericMeta } from '../components/meta';
import DocumentTitle from 'react-document-title';
import AccountWrapper from './account/AccountWrapper';
import MainLayout from '../library/layout/container/MainLayout';
import Menu from './menu/Menu';
import ProfileDropdown from './profile/ProfileDropdown';
import ExtraInfoButton from './services/ExtraInfoButton';
import PublicIFrameLayout from '../library/layout/container/PublicIFrameLayout';
import { useSelector } from 'react-redux';
import { IStoreState } from '../store';
import { ServicesPage } from './servicesPage/ServicesPage';
import { AboutPage } from './about/AboutPage';
import { ACGAppPaths } from '@acg/shared/const';
import { ForCuratorsPage } from './forCuratorsPage/ForCuratorsPage';

export interface RoutesProps {}

const APP_NAME = 'Artpool';

export const Routes = (props: RoutesProps) => {
  const [scrollToTop, setScrollToTop] = useState(0);

  const { user } = useSelector((root: IStoreState) => root.authReducer);
  const history = useHistory();

  const renderRoutes =
    (component: any, title: string, additionalProps?: any) => (props: any) => {
      let Component = component;
      const disableScroll = additionalProps && additionalProps.notTransparent;

      const generateMetadata = !additionalProps?.noMetadata;
      if (!user || additionalProps?.login) {
        if (additionalProps && additionalProps.forceRedirect) {
          return <Redirect to="/login" />;
        }

        Component =
          additionalProps && additionalProps.publicComponent
            ? additionalProps.publicComponent
            : component;

        return (
          <PublicOnlineLayout
            scrollToTop={setScrollToTop}
            {...additionalProps}
            disableScroll={disableScroll}
            hasScrollEvent={
              additionalProps && additionalProps.hasScrollEvent
                ? additionalProps.hasScrollEvent
                : false
            }
          >
            <MainPublicLayout {...additionalProps} scrolToTop={scrollToTop}>
              {generateMetadata && generateGenericMeta()}
              <DocumentTitle title={`${title} | ${APP_NAME}`}>
                <Component {...props} {...additionalProps} />
              </DocumentTitle>
            </MainPublicLayout>
          </PublicOnlineLayout>
        );
      }

      return (
        <AccountWrapper
          scrollToTop={setScrollToTop}
          disableScroll={disableScroll}
          {...additionalProps}
          hasScrollEvent={
            additionalProps && additionalProps.hasScrollEvent
              ? additionalProps.hasScrollEvent
              : false
          }
        >
          <MainLayout
            menuComponent={<Menu />}
            profileComponent={<ProfileDropdown />}
            extraInfoComponent={<ExtraInfoButton />}
            scrolToTop={scrollToTop}
            {...additionalProps}
          >
            {generateMetadata && generateGenericMeta()}
            <DocumentTitle title={`${title} | ${APP_NAME}`}>
              <Component {...props} {...additionalProps} />
            </DocumentTitle>
          </MainLayout>
        </AccountWrapper>
      );
    };

  const renderIframeRoutes =
    (component: any, title: string, additionalProps?: any) => (props: any) => {
      const Component = component;
      return (
        <PublicIFrameLayout {...additionalProps}>
          <DocumentTitle title={`${title} | ${APP_NAME}`}>
            <Component {...props} {...additionalProps} />
          </DocumentTitle>
        </PublicIFrameLayout>
      );
    };

  return (
    <Switch>
      <Route
        render={renderRoutes(
          Posts,
          "Discover Curators' Art Exhibitions & Inspirations",
          {
            notTransparent: true,
            noFooter: true,
            hasScrollEvent: true,
          }
        )}
        exact={true}
        path={ACGAppPaths.ROOT}
      />
      <Route
        render={renderRoutes(Login, 'Login', { login: true })}
        exact={true}
        path={ACGAppPaths.LOGIN}
      />
      <Route
        exact={true}
        path={ACGAppPaths.LOGIN}
        location={{
          ...history.location,
          pathname: '/login',
        }}
      />
      <Route
        render={renderRoutes(Reset, 'Reset Your Password', {
          notTransparent: true,
        })}
        exact={true}
        path={ACGAppPaths.RESET}
      />
      <Route
        render={renderRoutes(Logout, 'Logout', {
          notTransparent: true,
        })}
        exact={true}
        path={ACGAppPaths.LOGOUT}
      />
      <Route
        render={renderRoutes(AboutPage, 'About Us', {
          notTransparent: true,
        })}
        exact={true}
        path={ACGAppPaths.ABOUT_US}
      />
      <Route
        render={renderRoutes(FaqPage, 'FAQ', { notTransparent: true })}
        exact={true}
        path={ACGAppPaths.FAQ}
      />
      <Route
        render={renderRoutes(ServicesPage, 'Services', {
          notTransparent: true,
        })}
        exact={true}
        path={ACGAppPaths.SERVICES}
      />
      <Route
        render={renderRoutes(ForCuratorsPage, 'For Curators')}
        exact={true}
        path={ACGAppPaths.FOR_CURATORS_PAGE}
      />
      <Route
        render={renderRoutes(ForInstitutionsPage, 'For Institutions')}
        exact={true}
        path={ACGAppPaths.FOR_INSTITUTIONS_PAGE}
      />
      <Route
        render={renderRoutes(ExhibitionsPage, 'Exhibitions')}
        exact={true}
        path={ACGAppPaths.EXHIBITIONS}
      />
      <Route
        render={renderRoutes(Blog, 'Blog', {})}
        exact={true}
        path={ACGAppPaths.BLOG}
      />
      <Route
        render={renderRoutes(Legal, 'Terms And Conditions', {
          tabValue: 'CONDITIONS',
          notTransparent: true,
        })}
        exact={true}
        path={ACGAppPaths.TERMS_AND_CONDITIONS}
      />
      <Route
        render={renderRoutes(Legal, 'Privacy Policy', {
          tabValue: 'PRIVACY',
          notTransparent: true,
        })}
        exact={true}
        path={ACGAppPaths.PRIVACY_POLICY}
      />
      <Route
        render={renderRoutes(ModifyPassword, 'Modify you password', {
          notTransparent: true,
        })}
        exact={true}
        path={ACGAppPaths.MODIFY_PASSWORD}
      />
      <Route
        render={renderRoutes('undefined', 'Continue Sign Up', {
          forceRedirect: true,
          notTransparent: true,
        })}
        exact={true}
        path={ACGAppPaths.CONTINUE_SIGN_UP}
      />
      <Route
        render={renderRoutes(Posts, 'Post', {
          notTransparent: true,
        })}
        exact={true}
        path={ACGAppPaths.POST}
      />
      <Route
        render={renderRoutes(UserList, 'Curator List', {
          forceRedirect: true,
          notTransparent: true,
          noFooter: true,
          hasScrollEvent: true,
        })}
        exact={true}
        path={ACGAppPaths.USERS}
      />
      <Route
        render={renderRoutes(UserProfile, 'Posts', {
          tabValue: 'POSTS',
          publicComponent: VisitorComponent,
          notTransparent: true,
          noFooter: true,
          hasScrollEvent: true,
        })}
        exact={true}
        path={ACGAppPaths.USER_POSTS}
      />
      <Route
        render={renderRoutes(UserProfile, 'Followers', {
          tabValue: 'FOLLOWERS',
          forceRedirect: true,
          notTransparent: true,
        })}
        exact={true}
        path={ACGAppPaths.USER_FOLLOWERS}
      />
      <Route
        render={renderRoutes(UserProfile, 'Following', {
          tabValue: 'FOLLOWINGS',
          forceRedirect: true,
          notTransparent: true,
        })}
        exact={true}
        path={ACGAppPaths.USER_FOLLOWINGS}
      />
      <Route
        render={renderRoutes(UserProfile, 'Posts', {
          tabValue: 'POSTS',
          publicComponent: VisitorComponent,
          notTransparent: true,
        })}
        exact={true}
        path={ACGAppPaths.USER_POST}
      />

      <Route
        render={renderRoutes(
          OnlineExhibitionForm,
          'Create Online Exhibitions',
          {
            forceRedirect: true,
            notTransparent: true,
            hasScrollEvent: true,
          }
        )}
        exact={true}
        path={ACGAppPaths.CREATE_ONLINE_EXHIBITION}
      />
      <Route
        render={renderRoutes(
          OnlineExhibitionForm,
          'Update Online Exhibitions',
          {
            forceRedirect: true,
            notTransparent: true,
            hasScrollEvent: true,
          }
        )}
        exact={true}
        path={ACGAppPaths.UPDATE_ONLINE_EXHIBITION}
      />
      <Route
        render={renderRoutes(OnlineExhibition, 'Online Exhibitions', {
          notTransparent: true,
          noMetadata: true,
          hasScrollEvent: true,
        })}
        exact={true}
        path={ACGAppPaths.ONLINE_EXHIBITION}
      />

      <Route
        render={renderIframeRoutes(OnlineExhibition, 'Online Exhibitions')}
        exact={true}
        path={ACGAppPaths.IFRAME_ONLINE_EXHIBITION}
      />

      <Route
        render={renderRoutes(
          LabBox2,
          'Lab Box - En El Principio Era El Delirio',
          { notTransparent: true }
        )}
        exact={true}
        path={ACGAppPaths.LAB_BOX_2}
      />
      <Route
        render={renderRoutes(BackOffice, 'Back Office', {
          forceRedirect: true,
          notTransparent: true,
        })}
        exact={true}
        path={ACGAppPaths.BACK_OFFICE}
      />
      <Route
        render={renderRoutes(Institutions, 'Venues List', {
          notTransparent: true,
          noFooter: true,
          hasScrollEvent: true,
        })}
        exact={true}
        path={ACGAppPaths.VENUES}
      />
      <Route
        render={renderRoutes(InstitutionProfile, 'Venues', {
          publicComponent: VisitorInstitutionProfile,
          notTransparent: true,
          noFooter: true,
          noMetadata: true,
          hasScrollEvent: true,
        })}
        exact={true}
        path={ACGAppPaths.VENUE}
      />
      <Route
        render={renderRoutes(InstitutionProfile, 'Venues', {
          publicComponent: VisitorInstitutionProfile,
          notTransparent: true,
          noFooter: true,
          noMetadata: true,
          hasScrollEvent: true,
        })}
        exact={true}
        path={ACGAppPaths.VENUE_POSTS}
      />
      <Route
        render={renderRoutes(InstitutionProfile, 'Followers', {
          publicComponent: VisitorInstitutionProfile,
          tabValue: 'FOLLOWERS',
          forceRedirect: true,
          notTransparent: true,
        })}
        exact={true}
        path={ACGAppPaths.VENUE_FOLLOWERS}
      />
      <Route
        render={renderRoutes(InstitutionProfile, 'Venues', {
          publicComponent: VisitorInstitutionProfile,
          noFooter: true,
          notTransparent: true,
          noMetadata: true,
          hasScrollEvent: true,
        })}
        exact={true}
        path={ACGAppPaths.VENUE_POST}
      />
      <Route component={NotFound} />
    </Switch>
  );
};
