import { ReqOptions, ResOptions, Route } from 'typesafe-api';
import {
  ArtpoolApiEndpoint,
  JsonImage,
  JsonUserOrVenue,
} from '@acg/artpool-api-spec';

export interface JsonPost {
  creator: JsonUserOrVenue;
  imageUrl: string;
  title: string;
  url: string;
  social: { likes: number; views: number };
}

/**
 * get posts
 */

export const getPostsRoute: Route = {
  method: 'get',
  path: 'public/posts',
};

export type GetPostsEndpointDef = ArtpoolApiEndpoint<ReqOptions, ResOptions>;
