import * as React from 'react';

export interface IIconBurgerProps {
  fill?: string;
  height?: number;
  style?: any;
}

const IconBurger = (props: IIconBurgerProps) => {
  const style: React.CSSProperties = {
    height: props.height || 40,
    backgroundColor:
      (props.style && props.style.backgroundColor) || 'transparent',
    borderRadius: (props.style && props.style.borderRadius) || 6,
  };

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
      fill={props.fill}
      style={style}
    >
      <path d="M99.53,260H944.71c11.42,0,22.85.18,34.27,0h1.49c15.45,0,15.47-24,0-24H135.29c-11.42,0-22.85-.18-34.27,0H99.53c-15.45,0-15.47,24,0,24Z" />
      <path d="M99.53,552H944.71c11.42,0,22.85.18,34.27,0h1.49c15.45,0,15.47-24,0-24H135.29c-11.42,0-22.85-.18-34.27,0H99.53c-15.45,0-15.47,24,0,24Z" />
      <path d="M99.53,844H944.71c11.42,0,22.85.18,34.27,0h1.49c15.45,0,15.47-24,0-24H135.29c-11.42,0-22.85-.18-34.27,0H99.53c-15.45,0-15.47,24,0,24Z" />
    </svg>
  );
};

export default IconBurger;
