import { environment } from '../environments/environment';
import { ArtpoolApiClient } from '@acg/artpool-api-spec';
import { getBearerToken } from '@acg/auth/frontend';

const { apiURL } = environment;

export const artpoolApiClient = () =>
  new ArtpoolApiClient({
    baseUri: apiURL,
    getAccessToken: getBearerToken,
  });
