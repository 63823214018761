import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { Button, Grid, Modal, Tab, Tabs } from '@mui/material';
import { GREY_COLOR, PRIMARY_COLOR, SPACING } from '../../../styles/constants';
import { IArtistUser, ICuratorUser, UserViewerType } from '../../../types/user';
import { IWithAccountProps, withAccount } from '../../account/InjectAccount';
import UserCard from '../../../components/backoffice/UserCard';
import CreateUserModal from './CreateUserModal';
import ModalAcceptUser from '../../../components/backoffice/ModalAcceptUser';
import ModalChangeUserType from '../../../components/backoffice/ModalChangeUserType';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '../../../store';
import * as pendingCuratorsActions from '../../../store/modules/pendingCurators/actions';
import PaddedLayout from '../../../library/layout/container/PaddedLayout';
import TabPanel from '../../../components/tabs/TabPanel';
import { ACGModal } from '../../../components/modal/ACGModal';

const style = createStyles({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  titleContainer: {
    padding: SPACING,
  },
  button: {
    padding: 25,
    borderRadius: 100,
    marginBottom: 20,
  },
  buttonSmall: {
    padding: '7px 20px',
    minHeight: 'auto',
    borderRadius: 30,
    display: 'inline-block',
    width: 'auto',
    marginLeft: 5,
    fontSize: '0.6em',
    minWidth: 0,
  },
  closeButton: {
    position: 'absolute',
    right: '2px',
    top: '2px',
  },

  userSection: {
    paddingTop: 20,
  },
});

export interface IPostTypeFormProps {
  // onDone: () => void;
  // onClickSelect: (id: string) => void;
  // show: boolean
}

type Props = WithStyles<string> & IPostTypeFormProps & IWithAccountProps;

const UserSection: React.FC<Props> = (props) => {
  const { pendingCurators, pendingArtists } = useSelector(
    (state: IStoreState) => state.pendingCurators
  );
  const dispatch = useDispatch();

  const [modalApproveOpen, setModalApproveOpen] = useState(false);
  const [modalChangeTypeOpen, setModalChangeTypeOpen] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [currentUser, setCurrentUser] = useState<ICuratorUser | IArtistUser>();

  const [tabValue, setTabValue] = useState(1);

  const handleOpenApproveModal = (user: ICuratorUser | IArtistUser) => {
    setModalApproveOpen(true);
    setCurrentUser(user);
  };

  const handlCloseApproveModal = () => {
    setModalApproveOpen(false);
    setCurrentUser(undefined);
  };

  const handleOpenChangeTypeModal = (user: ICuratorUser | IArtistUser) => {
    setModalChangeTypeOpen(true);
    setCurrentUser(user);
  };
  const handleCloseChangeTypeModal = () => {
    setModalChangeTypeOpen(false);
  };

  const handleChangeUserType = (type: UserViewerType) => {
    if (!currentUser) {
      alert('Error: No current User');
    } else {
      dispatch(
        pendingCuratorsActions.changeCuratorProfile({
          viewer: {
            ...currentUser,
            profile: 'VIEWER',
            type,
          },
        })
      );
      if (currentUser.profile === 'CURATOR') {
        dispatch(pendingCuratorsActions.fetchRequest());
      } else {
        dispatch(pendingCuratorsActions.fetchArtistRequest());
      }
      setModalChangeTypeOpen(false);
    }
  };

  const handleApproveUser = () => {
    if (!currentUser) {
      alert('Error: No current User');
    } else {
      dispatch(
        pendingCuratorsActions.approveCurator({
          userId: currentUser.id,
          profile: currentUser.profile,
        })
      );
      if (currentUser.profile === 'CURATOR') {
        dispatch(pendingCuratorsActions.fetchRequest());
      } else {
        dispatch(pendingCuratorsActions.fetchArtistRequest());
      }
      setModalApproveOpen(false);
    }
  };

  const handleProfileOpen = () => setOpenProfile(true);
  const handleProfileClose = () => setOpenProfile(false);

  useEffect(() => {
    dispatch(pendingCuratorsActions.fetchRequest());
    dispatch(pendingCuratorsActions.fetchArtistRequest());
  }, []);

  const handleChange = (evt: any, value: number) => {
    setTabValue(value);
  };

  const a11yProps = (index: any) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  const { account, classes } = props;

  if (!account.isBackOfficeAdmin) {
    return (
      <PaddedLayout>
        <h1>You don't have access to this page</h1>
      </PaddedLayout>
    );
  } else {
    return (
      <>
        <PaddedLayout>
          <Grid container={true} justifyContent="center">
            <Button
              size="large"
              variant="contained"
              component="label"
              style={{ backgroundColor: GREY_COLOR }}
              onClick={handleProfileOpen}
            >
              Create new user
            </Button>
          </Grid>

          <Grid container={true} className={classes.root}>
            <Grid container={true} className={classes.titleContainer}>
              <h2 style={{ color: PRIMARY_COLOR }}>Users pending</h2>
            </Grid>
            <Grid container={true}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                centered={true}
              >
                <Tab label="Curators" value={1} {...a11yProps(1)} />
                <Tab label="Artists" value={2} {...a11yProps(2)} />
              </Tabs>
            </Grid>

            <TabPanel index={1} value={tabValue}>
              <Grid
                container={true}
                direction="column"
                alignItems="center"
                xs={12}
              >
                {pendingCurators.length === 0 ? (
                  <h2>No Pending Curators</h2>
                ) : (
                  pendingCurators.map((user: ICuratorUser) => (
                    <UserCard
                      key={user.id}
                      user={user}
                      onOpenApproveModal={handleOpenApproveModal}
                      onOpenChangeTypeModal={handleOpenChangeTypeModal}
                    />
                  ))
                )}
              </Grid>
            </TabPanel>
            <TabPanel index={2} value={tabValue}>
              <Grid
                container={true}
                direction="column"
                alignItems="center"
                xs={12}
              >
                {pendingArtists.length === 0 ? (
                  <h2>No Pending Artists</h2>
                ) : (
                  pendingArtists.map((user: IArtistUser) => (
                    <UserCard
                      key={user.id}
                      user={user}
                      onOpenApproveModal={handleOpenApproveModal}
                      onOpenChangeTypeModal={handleOpenChangeTypeModal}
                    />
                  ))
                )}
              </Grid>
            </TabPanel>
          </Grid>
          <ACGModal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={openProfile}
            disableAutoFocus={true}
            onClose={handleProfileClose}
            BackdropProps={{
              className: classes.backdrop,
            }}
          >
            <CreateUserModal onClickAway={handleProfileClose} />
          </ACGModal>
        </PaddedLayout>
        <ModalAcceptUser
          onAccept={handleApproveUser}
          onClose={handlCloseApproveModal}
          isOpen={modalApproveOpen}
          user={currentUser}
        />
        <ModalChangeUserType
          onChange={handleChangeUserType}
          onClose={handleCloseChangeTypeModal}
          isOpen={modalChangeTypeOpen}
          user={currentUser}
        />
      </>
    );
  }
};

export default compose<Props, IPostTypeFormProps>(
  withStyles(style),
  withAccount
)(UserSection);
