import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { Theme } from '@mui/material';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
import SearchUserFilterForm from './SearchUserFilterForm';
import { compose } from 'recompose';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  DARK_GREY_COLOR,
  // GREY_COLOR
} from '../../styles/constants';
import { environment } from '../../environments/environment';

const {
  REACT_APP_ALGOLIA_ID,
  REACT_APP_ALGOLIA_SEARCH_KEY,
  REACT_APP_ALGOLIA_INDEX,
} = environment;

const searchClient = algoliasearch(
  `${REACT_APP_ALGOLIA_ID}`,
  `${REACT_APP_ALGOLIA_SEARCH_KEY}`
);

export interface ISearchWrapperProps {
  placeholder?: string;
  disabled?: boolean;
  name: string;
  title: string;
  setFilters: any;
  filters: any;
  toggleFilter: any;
}

const style = (theme: Theme) =>
  createStyles({
    searchMobileMargin: {
      marginLeft: 10,
    },
    searchInput: {
      // backgroundColor: GREY_COLOR,
      height: 40,
      width: '100%',
      fontSize: 14,
      borderRadius: 8,
      border: `1px solid ${DARK_GREY_COLOR}`,
    },
    title: {
      fontSize: 12,
      fontWeight: 'normal',
      fontFamily: 'Replica-Regular',
      textTransform: 'capitalize',
    },
  });

type Props = ISearchWrapperProps & RouteComponentProps<{}> & WithStyles<string>;

function SearchUserFilter(props: Props) {
  const { classes, title, disabled, name, setFilters, filters, toggleFilter } =
    props;

  return (
    <React.Fragment>
      <div className={classes.title}>{title}</div>
      <InstantSearch
        searchClient={searchClient}
        indexName={`${REACT_APP_ALGOLIA_INDEX}_curators`}
      >
        <SearchUserFilterForm
          // placeholder={isPad ? `Filter by curator ${placeholder.toUpperCase()}…`.toUpperCase() : placeholder.toUpperCase()}
          disabled={disabled}
          customClass={classes.searchInput}
          name={name}
          setFilters={setFilters}
          filters={filters}
          toggleFilter={toggleFilter}
        />
      </InstantSearch>
    </React.Fragment>
  );
}

export default compose<Props, ISearchWrapperProps>(
  withStyles(style),
  withRouter
)(SearchUserFilter);
