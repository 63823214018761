import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import {} from '@mui/material';
import { compose } from 'recompose';
import { IStandardPost } from '../../types/posts';
import Title from '../../library/dataDisplay/typography/Title';
import ContentContainer from './ContentContainer2';
import ReactPlayer from 'react-player';
import { ReactTinyLink } from 'react-tiny-link';
import SubTitle from '../../library/dataDisplay/typography/SubTitle';

export interface IStandardProps {
  post: IStandardPost;
  onClick: () => void;
  onCommentClick: () => void;
  onShare: () => void;
  onLike: (post: IStandardPost) => void;
  like: boolean;
  numberOfLike: number;
  onCategoryButtonClick: (selectedCategory: string) => void;
  nonClickable?: boolean;
}

const styles = createStyles({
  description: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    backgroundColor: '#fff',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    position: 'relative',
    fontWeight: 600,
    lineHeight: '1.4em',
  },
  inspiration: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'table',
    height: '100%',
    width: '100%',
    minHeight: 379,
  },

  inspirationVideo: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'table',
    height: '100%',
    width: '100%',
    minHeight: 379,
  },

  span: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  oneLiner: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  secondLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },

  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
    /* 720 / 1280 = 0.5625 */
  },

  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

type Props = WithStyles<string> & IStandardProps;

class ImageContent extends React.Component<Props, any> {
  public getLinkPreview(linkObj: any) {
    const { post, classes } = this.props;

    const wrapText = (val?: string) => {
      const maxLength = 400;
      if (!val) {
        return '';
      }
      const trimmedVal = val.replace(/\r?\n|\r/g, '');
      if (trimmedVal.length > maxLength) {
        const subVal = `${trimmedVal.substr(0, maxLength)}...`;
        return subVal;
      }
      return trimmedVal;
    };

    const eventPrevent = (event: any) => {
      event.preventDefault();
    };
    try {
      // <Title className={classes.oneLiner}>{linkObj.link_title}</Title> <br/>
      return (
        <div className={classes.description}>
          <div className={classes.inspiration} onClick={eventPrevent}>
            <ReactTinyLink
              cardSize="large"
              showGraphic={true}
              // maxLine={2}
              // minLine={1}
              width={'100%'}
              url={linkObj.link_url}
              // proxyUrl={"https://cors-anywhere.herokuapp.com"}
              // onError={ window.console.log('ERROR onErrorLink', linkObj.link_url)}
            />
          </div>
        </div>
      );
    } catch (e) {
      return (
        <div className={classes.description}>
          <div className={classes.inspiration}>
            <span className={classes.span}>{wrapText(post.description)}</span>
          </div>
        </div>
      );
    }
  }

  public render() {
    const {
      post,
      classes,
      onClick,
      like,
      numberOfLike,
      onCommentClick,
      onShare,
      onCategoryButtonClick,
    } = this.props;

    const wrapText = (val?: string) => {
      const maxLength = 400;
      if (!val) {
        return '';
      }
      const trimmedVal = val.replace(/\r?\n|\r/g, '');
      if (trimmedVal.length > maxLength) {
        const subVal = `${trimmedVal.substr(0, maxLength)}...`;
        return subVal;
      }
      return trimmedVal;
    };

    // const footer = <div><Title className={classes.oneLiner}>{post.title}</Title></div>
    const footer = (
      <div>
        <Title className={classes.oneLiner}>{post.title} </Title>
        {!post.title && <Title className={classes.oneLiner}>&nbsp;</Title>}
        <SubTitle className={classes.secondLine}>
          {wrapText(post.description)}
        </SubTitle>
        {post.description === ' ' && <SubTitle>&nbsp;</SubTitle>}
        <SubTitle>&nbsp;</SubTitle>
      </div>
    );

    let featured = (
      <div className={classes.description}>
        <div className={classes.inspiration}>
          <span className={classes.span}>{wrapText(post.title)}</span>
        </div>
      </div>
    );

    if (post.videos && post.videos[0]) {
      // <Title className={classes.oneLiner}>{post.videos[0].video_title}</Title> <br/>
      featured = (
        <div className={classes.description}>
          <div className={classes.inspiration}>
            <div className="playerWrapper">
              <ReactPlayer
                className="reactPlayer"
                url={post.videos[0].video_link}
                playing={false}
                height="263px"
                width="100%"
                controls={true}
                vimeo={{ playerOptions: { height: 263 } }}
              />
              <br />
            </div>
          </div>
        </div>
      );
    } else if (post.links && post.links[0]) {
      if (!post.links[0].link_url.includes('http')) {
        post.links[0].link_url = 'http://' + post.links[0].link_url;
      }
      const regexURLFormula =
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
      // Case the url is miss-speld
      if (regexURLFormula.test(post.links[0].link_url)) {
        featured = this.getLinkPreview(post.links[0]);
      }
    }

    const onLike = () => this.props.onLike(post);

    return (
      <ContentContainer
        onCommentClick={onCommentClick}
        footer={footer}
        featured={featured}
        onClick={onClick}
        onLike={onLike}
        liked={like}
        numberOfLike={numberOfLike}
        post={post}
        onShare={onShare}
        categorySubType={post.sub_type}
        onCategoryButtonClick={onCategoryButtonClick}
        nonClickable={this.props.nonClickable}
      />
    );
  }
}

export default compose<Props, IStandardProps>(withStyles(styles))(ImageContent);
