import ListItemIcon from '@mui/material/ListItemIcon';
import { styled } from '@mui/material/styles';
import IconCross from '../../library/dataDisplay/icons/IconCross';
import {
  DARK_GREY_COLOR,
  GREY_COLOR,
  PRIMARY_COLOR,
} from '../../styles/constants';
import List from '@mui/material/List';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { ACGAppPaths, ARTPOOL_URL } from '@acg/shared/const';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { isWidthDown } from '@mui/material/Hidden/withWidth';
import { checkRight } from '../../utils/userUtils';
import { IUser } from '../../types/user';
import { Breakpoint } from '@mui/system/createTheme/createBreakpoints';

const PREFIX = 'MenuList';

const classes = {
  list: `${PREFIX}-list`,
  mobileList: `${PREFIX}-mobileList`,
  link: `${PREFIX}-link`,
  listItem: `${PREFIX}-listItem`,
  mobileListItem: `${PREFIX}-mobileListItem`,
  listText: `${PREFIX}-listText`,
  mobileListText: `${PREFIX}-mobileListText`,
  footer: `${PREFIX}-footer`,
  burgerButton: `${PREFIX}-burgerButton`,
};

const Root = styled('div')({
  [`& .${classes.list}`]: {
    width: 613,
  },
  [`& .${classes.mobileList}`]: {
    width: '100vw',
  },
  [`& .${classes.link}`]: {
    fontWeight: 400,
    width: '100%',
    textDecoration: 'none',
    color: '#000',
    fontFamily: 'Replica',
    '&:hover': {
      color: PRIMARY_COLOR,
    },
  },
  [`& .${classes.listItem}`]: {
    height: 80,
    '&:hover': {
      // color: PRIMARY_COLOR,
      // backgroundColor: setColorOpacity(PRIMARY_COLOR, 60)
      backgroundColor: 'transparent',
    },
  },
  [`& .${classes.mobileListItem}`]: {
    height: 50,
  },
  [`& .${classes.listText}`]: {
    fontSize: 60,
  },
  [`& .${classes.mobileListText}`]: {
    fontSize: 35,
  },
  [`& .${classes.footer}`]: {
    color: DARK_GREY_COLOR,
    display: 'block',
    paddingBottom: 8,
    '&:hover': {
      color: PRIMARY_COLOR,
    },
  },
  [`& .${classes.burgerButton}`]: {
    minWidth: 0,
    padding: '0px 5px',
    marginBottom: 20,
    marginLeft: 15,
    '&:hover': {
      backgroundColor: GREY_COLOR,
    },
  },
});

export interface MenuListProps {
  onInteraction: () => void;
  account: IUser;
  width: Breakpoint;
}

export const MenuList = (props: MenuListProps) => {
  const { onInteraction, width } = props;

  const [isHovering, setIsHovering] = React.useState(false);

  const toggleHover = () => {
    setIsHovering(!isHovering);
  };

  const isPad = isWidthDown('md', width);
  const right = checkRight(props.account);

  return (
    <Root
      className={isPad ? classes.mobileList : classes.list}
      role="presentation"
      onClick={onInteraction}
      onKeyDown={onInteraction}
      style={{
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <ListItemIcon>
        <div
          style={{
            position: 'absolute',
            right: 20,
            top: 20,
            zIndex: 100,
            cursor: 'pointer',
          }}
          onClick={onInteraction}
          onMouseOver={toggleHover}
          onMouseOut={toggleHover}
        >
          <IconCross fill={isHovering ? PRIMARY_COLOR : 'black'} />
        </div>
      </ListItemIcon>
      <List>
        <Link className={classes.link} to={ACGAppPaths.SERVICES}>
          <ListItem
            button={true}
            className={isPad ? classes.mobileListItem : classes.listItem}
          >
            <ListItemText
              classes={{
                primary: isPad ? classes.mobileListText : classes.listText,
              }}
              primary="Services"
            />
          </ListItem>
        </Link>
        <ListItem
          button={true}
          className={isPad ? classes.mobileListItem : classes.listItem}
        >
          {right !== 'VIEWER' && (
            <Link className={classes.link} to={ACGAppPaths.FOR_CURATORS_PAGE}>
              <ListItemText
                classes={{
                  primary: isPad ? classes.mobileListText : classes.listText,
                }}
                primary="For Curators"
              />
            </Link>
          )}
        </ListItem>
        <Link className={classes.link} to={ACGAppPaths.FAQ}>
          <ListItem
            button={true}
            className={isPad ? classes.mobileListItem : classes.listItem}
          >
            <ListItemText
              classes={{
                primary: isPad ? classes.mobileListText : classes.listText,
              }}
              primary="FAQ"
            />
          </ListItem>
        </Link>
        <p
          style={
            isPad
              ? {
                  padding: '35px 16px 0px 16px',
                  fontSize: 20,
                  fontFamily: 'Replica',
                }
              : {
                  padding: '60px 16px 0px 16px',
                  fontSize: 20,
                  fontFamily: 'Replica',
                }
          }
        >
          Editorial
        </p>
        <Link className={classes.link} to={ACGAppPaths.BLOG}>
          <ListItem
            button={true}
            className={isPad ? classes.mobileListItem : classes.listItem}
          >
            <ListItemText
              classes={{
                primary: isPad ? classes.mobileListText : classes.listText,
              }}
              primary="Our Blog"
            />
          </ListItem>
        </Link>
        <a
          className={classes.link}
          href={'https://curtain.artcuratorgrid.com/'}
          target="_blank"
        >
          <ListItem
            button={true}
            className={isPad ? classes.mobileListItem : classes.listItem}
          >
            <ListItemText
              classes={{
                primary: isPad ? classes.mobileListText : classes.listText,
              }}
              primary="Curtain Magazine"
            />
          </ListItem>
        </a>
        <a
          href={`/assets/images/acg/Portugal2020Doc.pdf`}
          target={'_blank'}
          rel="noopener noreferrer"
          download={false}
        >
          <img
            src={'/assets/images/acg/Lisboa2020_RGB_1.png'}
            style={{
              paddingTop: 20,
              width: '80%',
              maxWidth: 600,
              marginLeft: 12,
            }}
          />
        </a>
      </List>
      <Grid container={true} style={{ padding: '0px 16px', fontSize: 16 }}>
        <Grid item={true} xs={6} sm={4}>
          <List>
            <Link
              className={`${classes.link} ${classes.footer}`}
              to={ACGAppPaths.TERMS_AND_CONDITIONS}
            >
              Our Conditions
            </Link>
            <Link
              className={`${classes.link} ${classes.footer}`}
              to={ACGAppPaths.PRIVACY_POLICY}
            >
              Privacy Policy
            </Link>
          </List>
        </Grid>
        <Grid item={true} xs={6} sm={4}>
          <List>
            <a
              className={`${classes.link} ${classes.footer}`}
              href={'https://www.instagram.com/artcuratorgrid/'}
              target="_blank"
            >
              Instagram
            </a>
            <a
              className={`${classes.link} ${classes.footer}`}
              href={'https://www.facebook.com/artcuratorgrid/'}
              target="_blank"
            >
              Facebook
            </a>
            <a
              className={`${classes.link} ${classes.footer}`}
              href={'https://www.linkedin.com/company/art-curator-grid/'}
              target="_blank"
            >
              Linkedin
            </a>
            <a
              className={`${classes.link} ${classes.footer}`}
              href={'https://vimeo.com/user109275680'}
              target="_blank"
            >
              Vimeo
            </a>
          </List>
        </Grid>
      </Grid>
    </Root>
  );
};
