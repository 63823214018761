import { all } from 'redux-saga/effects';

import pendingCurators from './pendingCurators/sagas';
import account from './account/sagas';
import institutions from './institutions/sagas';
import exhibitions from './exhibitions/sagas';
import comments from './comments/sagas';

export default function* rootSaga(): any {
  return yield all([
    pendingCurators,
    institutions,
    exhibitions,
    account,
    comments,
  ]);
}
