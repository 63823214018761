import { IExhibition, IExhibitionPoint } from '../types/exhibitions';
import { IArtwork } from '../types/artworks';

export enum PointType {
  NFT = 'Nft',
  ARTWORK = 'Artwork',
  INFO_POINT = 'InfoPoint',
}

export const getExhibitionPointType = (point: IExhibitionPoint): PointType => {
  if (
    point.art_work &&
    point.art_work.nft_data &&
    point.art_work.nft_data.nft
  ) {
    return PointType.NFT;
  } else if (point.art_work) {
    return PointType.ARTWORK;
  } else {
    return PointType.INFO_POINT;
  }
};

export const exhibitionRedirectNoUser = (
  history: any,
  exhibition: IExhibition
) => {
  const redirectUrl = `/online-exhibition/${exhibition.url}`;
  history.push(redirectUrl);
};

export const getExhibitionArtists = (document: any) => {
  const artistList: any[] = [];
  if (document && document.existing_artists) {
    document.existing_artists.forEach((artist: any) => {
      artistList.push({
        name: `${artist.firstName} ${artist.lastName}`,
        url: artist.url,
      });
    });
  }
  if (document && document.artists) {
    document.artists.forEach((artist: any) => {
      artistList.push({ name: `${artist.name}` });
    });
  }
  return artistList;
};

export const getArtworkArtistList = (point: IExhibitionPoint): string => {
  switch (getExhibitionPointType(point)) {
    case PointType.ARTWORK:
      const artistList =
        getExhibitionArtists(point.art_work).length > 0
          ? getExhibitionArtists(point.art_work).map(
              (artist: any, i: number) => {
                return artist.name;
              }
            )
          : point.art_work && point.art_work.artists_list
          ? point.art_work.artists_list
          : [];
      return artistList.join(',');
    case PointType.NFT:
      return `${point.art_work!!.nft_data!!.nft.artist.name}`;
    case PointType.INFO_POINT:
      return '';
    default:
      return '';
  }
};

export const getExhibitionPointTitle = (point: IExhibitionPoint): string => {
  switch (getExhibitionPointType(point)) {
    case PointType.ARTWORK:
      return point.art_work!!.title;
    case PointType.INFO_POINT:
      return point.title || '';
    case PointType.NFT:
      return point.art_work!!.nft_data!!.nft.title;
    default:
      return '';
  }
};

export const getExhibitionPointYear = (point: IExhibitionPoint): string => {
  switch (getExhibitionPointType(point)) {
    case PointType.ARTWORK:
      return `${
        point.art_work!!.year && point.art_work!!.year !== 0
          ? point.art_work!!.year
          : ''
      }`;
    default:
      return '';
  }
};

export const getArtworkDescriptionText = (artwork: IArtwork): string => {
  if (artwork.nft_data) {
    return artwork.nft_data.nft.description || '';
  } else if (artwork.curatorial_text) {
    return artwork.curatorial_text;
  }
  return '';
};

export const getFormattedPointOrder = (point: IExhibitionPoint): string => {
  return point.point_order < 10
    ? '0' + point.point_order
    : point.point_order.toString();
};
