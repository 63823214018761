import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import PostService, { IPostFilters } from '../../services/PostService';
import SearchService from '../../services/SearchService';
import { Grid } from '@mui/material';
import { compose } from 'recompose';
import PostModal from '../post/Post';
import { IUser } from '../../types/user';
import { RouteComponentProps, withRouter } from 'react-router';
import PostsGrid, { Sorts, IFilters } from '../../components/posts/PostsGrid';
import { withAccount, IWithAccountProps } from '../account/InjectAccount';
import AddPostForm from '../post/AddPostForm';
import PaddedLayout from '../../library/layout/container/PaddedLayout';
import { checkRight } from '../../utils/userUtils';
import { IPost } from '../../types/posts';
import { parseSearch, QUERY_SEARCH_SETTINGS } from '../../utils/router';
import {} from '@mui/material/';
import TagService from '../../services/TagService';
import SocialModal from '../social/SocialModal';
import { BREAKPOINT } from '../../styles/constants';
import * as queryString from 'query-string';

import EthicalCodeModal from '../ethicalCodeModal/EthicalCodeModal';
import { ACGAppPaths } from '@acg/shared/const';

export interface IPostsProps {}

interface IState {
  open: boolean;
  contentType: 'POST' | 'ADD';
  search: boolean;
  socialModalOpen: boolean;
  socialModalObject?: IPost;
}

const style = createStyles({
  root: {
    flexGrow: 1,
  },
  backdrop: {
    backgroundColor: 'rgba(242,242,242,0.92)',
  },

  ethicContainer: {
    minWidth: '66%',
  },
});

type Props = IPostsProps &
  WithWidth &
  WithStyles<string> &
  RouteComponentProps<{ postId?: string }> &
  IWithAccountProps &
  WithWidth;

const INITIAL_PAGE_SIZE = 12;

class Posts extends React.Component<Props, IState> {
  private postService = new PostService();
  private searchService = new SearchService();
  private tagService = new TagService();

  constructor(props: Props) {
    super(props);
    this.state = {
      open: props.match.params.postId ? true : false,
      contentType: 'POST',
      search: false,
      socialModalOpen: false,
    };
  }

  public componentDidMount() {
    const {
      location: { search },
      history,
    } = this.props;

    const filter = parseSearch(search, QUERY_SEARCH_SETTINGS);
    const create = queryString.parse(search).create;

    if (filter.query && this.state.search === false) {
      this.setState({ search: true });
    } else if (!filter.query && this.state.search === true) {
      this.setState({ search: false });
    } else if (create) {
      history.push(ACGAppPaths.ROOT);
      this.onAddPost();
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const {
      match: {
        params: { postId },
      },
      location: { search },
      history,
    } = this.props;

    const {
      match: {
        params: { postId: prevPostId },
      },
    } = prevProps;

    if (!prevPostId && postId) {
      this.setState({ open: true, contentType: 'POST' });
    }

    if (prevPostId && !postId) {
      this.setState({ open: false, contentType: 'POST' });
    }

    const filter = parseSearch(search, QUERY_SEARCH_SETTINGS);
    const create = queryString.parse(search).create;

    if (filter.query && this.state.search === false) {
      this.setState({ search: true });
    } else if (!filter.query && this.state.search === true) {
      this.setState({ search: false });
    } else if (create && this.state.open === false) {
      history.push(ACGAppPaths.ROOT);
      this.onAddPost();
    }
  }

  public getData = (
    pageNumber: number = 0,
    sorts: Sorts,
    subType?: string,
    orderBy?: string,
    connectedUser?: IUser,
    filters?: IFilters
  ) => {
    const pageSize = pageNumber === 0 ? INITIAL_PAGE_SIZE : 12;
    const query: IPostFilters = {
      first_result: pageSize * pageNumber,
      max_results: pageSize,
      sort: sorts === 'MY_GRID' ? 'FEED' : undefined,
      sub_type: subType,
      order_by: orderBy,
      status: 'PUBLISHED',
      ignoreArtistPosts: true,
    };

    if (connectedUser) {
      query.connected_user = connectedUser.url;
    }
    if (filters && filters.tags) {
      query.tags = JSON.stringify(filters.tags);
    }
    return this.postService.getPosts(query);
  };

  public getFilters = () => {
    return this.tagService.getTags();
  };

  public searchData = (
    pageNumber: number = 0,
    query: string,
    subType?: string,
    orderBy?: string
  ) => {
    const pageSize = 12;
    return this.searchService.searchPosts({
      query,
      first_result: pageSize * pageNumber,
      max_results: pageSize,
      sub_type: subType,
      order_by: orderBy,
      status: 'PUBLISHED',
    });
  };

  public handleClose = (): void => {
    const {
      history,
      location: { search },
    } = this.props;
    history.push(`/${search}`);
    this.setState({ open: false });
  };

  public onAddPost = () => {
    this.setState({ open: true, contentType: 'ADD' });
  };

  public render() {
    const {
      classes,
      history,
      location: {
        search,
        // pathname
      },
      width,
    } = this.props;

    // This is a little hacky it would probably be better to create a different HOC
    // for when account is optional
    const account = this.props.account as IUser | undefined;

    const { contentType } = this.state;

    const onPostClick = (postId: string, postType: string) => {
      if (postType === 'ONLINEEXHIBITION') {
        history.push(`/online-exhibition/${postId}`);
      } else {
        history.push(`/posts/${postId}${search}`);
      }
    };
    const onCommentClick = (postId: string) =>
      history.push(`/posts/${postId}${search}`, { comment: true });
    const onClickOnAvatar = (userId: string, isVenue?: boolean) =>
      isVenue
        ? history.push(`/venues/${userId}/posts`)
        : history.push(`/users/${userId}/posts`);
    const onPageChange = (pageNumber: number) => ({});

    let content: any = AddPostForm;
    switch (contentType) {
      case 'ADD':
        content = AddPostForm;
        break;
      case 'POST':
        content = PostModal;
        break;
    }

    const userRight = checkRight(account);
    const showAddButton = userRight === 'ALL';

    const onLike = (post: IPost) => {
      if (post.type === 'ONLINEEXHIBITION') {
        return this.postService.likeOnlineExhibitionPost(post.id);
      }
      return this.postService.likePost(post.id);
    };

    const isLiking = (post: IPost) =>
      !!post.likes.find((i) => i.id === account?.id);

    const isPad = isWidthDown(BREAKPOINT, width);

    const filter = parseSearch(search, QUERY_SEARCH_SETTINGS);

    // const shouldShowmobileSearch = isWidthDown(BREAKPOINT, width);

    const onShareClick = (post: IPost) => {
      this.setState({
        socialModalOpen: true,
        socialModalObject: post,
      });
    };

    const onSocialModalClose = () => {
      this.setState({
        socialModalOpen: false,
        socialModalObject: undefined,
      });
    };

    const onTabSelection = (tabSelected: string) => {
      // window.console.log('tabSelected: ', tabSelected);
    };

    return (
      <PaddedLayout noPadded={true}>
        <Grid
          container={true}
          className={classes.root}
          style={{ paddingTop: isPad ? 190 : 140 }}
        >
          {/* shouldShowmobileSearch && <Search /> */}
          <PostsGrid
            onClickOnAdd={this.state.search ? undefined : this.onAddPost}
            connectedUser={account}
            showAddButton={this.state.search ? undefined : showAddButton}
            loadPosts={this.getData}
            pageSize={INITIAL_PAGE_SIZE}
            offset={0}
            onClickOnAvatar={onClickOnAvatar}
            onPageChange={onPageChange}
            onPostClick={onPostClick}
            onCommentClick={onCommentClick}
            onLike={onLike}
            isLiking={isLiking}
            modalComponent={content as React.ComponentClass<any>}
            showModal={this.state.open}
            onCloseModal={this.handleClose}
            // showFilter={this.state.search ? false : true}
            showFilter={false}
            query={filter.query}
            searchPosts={this.searchData}
            loadFilters={this.getFilters}
            onShareClick={onShareClick}
            noResult={<div />}
            showTabs={true}
            onTabSelection={onTabSelection}
          />
          {this.state.socialModalObject && (
            <SocialModal
              open={this.state.socialModalOpen}
              onClose={onSocialModalClose}
              object={{
                type: 'POST',
                object: this.state.socialModalObject,
              }}
            />
          )}
        </Grid>

        <EthicalCodeModal />
      </PaddedLayout>
    );
  }
}

export default compose<Props, IPostsProps>(
  withWidth(),
  withRouter,
  withStyles(style),
  withAccount,
  withWidth()
)(Posts);
