import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import IconBurger from '../../library/dataDisplay/icons/IconBurger';
import * as React from 'react';
import { makeStyles } from '@mui/material/styles';
import { GREY_COLOR } from '../../styles/constants';

const PREFIX = 'BurgerButton';

const classes = {
  burgerButton: `${PREFIX}-burgerButton`,
};

const Root = styled('div')({
  [`& .${classes.burgerButton}`]: {
    minWidth: 0,
    padding: '0px 5px',
    marginBottom: 20,
    marginLeft: 15,
    '&:hover': {
      backgroundColor: GREY_COLOR,
    },
  },
});

export interface BurgerButtonProps {
  onClick: () => void;
}

export const BurgerButton = (props: BurgerButtonProps) => {
  const { onClick } = props;

  return (
    <Root style={{ padding: '20px 0px' }}>
      <Button onClick={onClick} classes={{ root: classes.burgerButton }}>
        <IconBurger height={47} />
      </Button>
    </Root>
  );
};
