import { InstitutionsAction, IInstitutionsState } from './types';
import * as _ from 'lodash';

const initialState: IInstitutionsState = {
  institutions: [],
  currentInstitution: undefined,
};

export default function (
  state = initialState,
  action: InstitutionsAction
): IInstitutionsState {
  switch (action.type) {
    case '@@institutions/FETCH_REQUEST':
    case '@@institutions/CREATE_INSTITUTION':
    case '@@institutions/UPDATE_INSTITUTION':
      return {
        ...state,
      };
    case '@@institutions/FETCH_SUCCESS':
      return {
        ...state,
        institutions: action.payload,
        currentInstitution: undefined,
      };
    case '@@institutions/FETCH_ERROR':
    case '@@institutions/CREATE_INSTITUTION_ERROR':
    case '@@institutions/UPDATE_INSTITUTION_ERROR':
      return {
        ...state,
        currentInstitution: undefined,
      };
    case '@@institutions/CREATE_INSTITUTION_SUCCESS':
      const institutionsWithCreated = [...state.institutions];
      institutionsWithCreated.push(action.payload.institution);
      return {
        ...state,
        institutions: institutionsWithCreated,
        currentInstitution: action.payload.institution,
      };

    case '@@institutions/UPDATE_INSTITUTION_SUCCESS':
      const institutionsToUpdate = [...state.institutions];
      const updatedInstitution = action.payload.updatedInstitution;

      const index = _.findIndex(institutionsToUpdate, {
        id: updatedInstitution.id,
      });

      if (index === -1) {
        institutionsToUpdate.push(updatedInstitution);
      } else {
        institutionsToUpdate.splice(index, 1, updatedInstitution);
      }
      return {
        ...state,
        institutions: institutionsToUpdate,
        currentInstitution: action.payload.updatedInstitution,
      };
    case '@@institutions/RESET_CURRENT_INSTITUTION': {
      return {
        ...state,
        currentInstitution: undefined,
      };
    }
    default:
      return state;
  }
}
