import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import {} from '@mui/material';
import { compose } from 'recompose';
import { IOnlineExhibitionPost } from '../../types/posts';
import Title from '../../library/dataDisplay/typography/Title';
import ContentContainer from './ContentContainer2';
import { getImageUrl } from '../../utils/imageUrl';
import SubTitle from '../../library/dataDisplay/typography/SubTitle';
import { dateToStringParser } from '../../utils/datetimehelper';

export interface IExhibitionProps {
  post: IOnlineExhibitionPost;
  onClick: () => void;
  onCommentClick: () => void;
  onLike: (post: IOnlineExhibitionPost) => void;
  onShare: () => void;
  like: boolean;
  numberOfLike: number;
  onCategoryButtonClick: (selectedCategory: string) => void;
  nonClickable?: boolean;
}

const styles = createStyles({
  img: {},
  oneLiner: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  secondLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
});

type Props = WithStyles<string> & IExhibitionProps;

class OnlineExhibitionContent extends React.Component<Props, any> {
  public render() {
    const {
      post,
      onClick,
      like,
      numberOfLike,
      classes,
      onCommentClick,
      onShare,
      onCategoryButtonClick,
    } = this.props;

    const renderCuratedBy = () => {
      const { curated_by, created_by } = post;

      const createdBy = created_by;
      const curatorsList = curated_by || [];

      if (
        createdBy.profile === 'CURATOR' &&
        !curatorsList.some((curator: any) => curator.id === createdBy.id)
      ) {
        curatorsList.unshift(createdBy);
      }
      let curatedByText =
        post.sub_type === 'EXHIBITION' ||
        post.sub_type === 'ARTPROJECT' ||
        post.sub_type === 'ONLINEEXHIBITION' ||
        post.sub_type === 'PUBLICATION'
          ? 'Curated by: '
          : 'Shared by: ';

      if (!curatorsList || curatorsList.length === 0) {
        return '';
      }

      for (let i = 0; i < curatorsList.length; i++) {
        // Final or only one curator
        if (i + 1 === curatorsList.length) {
          curatedByText = curatedByText.concat(
            `${curatorsList[i].firstName} ${curatorsList[i].lastName}`
          );
        }

        if (i + 1 < curatorsList.length) {
          curatedByText = curatedByText.concat(
            `${curatorsList[i].firstName} ${curatorsList[i].lastName}, `
          );
        }
      }
      return curatedByText;
    };
    const renderArtists = () => {
      const { artists } = post;
      let artistsText = '';

      if (!artists || artists.length === 0) {
        return '';
      }

      for (let i = 0; i < artists.length; i++) {
        if (i === 5) {
          artistsText = artistsText.slice(0, -2);
          artistsText = artistsText.concat('...');
          break;
        }

        // Final or only one curator
        if (i + 1 === artists.length) {
          artistsText = artistsText.concat(`${artists[i].name}`);
        }
        if (i + 1 < artists.length) {
          artistsText = artistsText.concat(`${artists[i].name}, `);
        }
      }
      return artistsText;
    };

    const footer = (
      <div>
        <Title className={classes.oneLiner}>{post.title}</Title>
        {!post.title && <Title className={classes.oneLiner}>&nbsp;</Title>}
        <SubTitle>
          {post.start_date && post.end_date ? (
            `${dateToStringParser(
              post.start_date,
              'MMM Do YYYY'
            )} - ${dateToStringParser(post.end_date, 'MMM Do YYYY')}`
          ) : (
            <div>&nbsp;</div>
          )}
        </SubTitle>
        <SubTitle>{renderArtists()}</SubTitle>
        <SubTitle>{renderCuratedBy()}</SubTitle>
      </div>
    );

    const featured = (
      <>
        <div
          style={{
            width: '100%',
            height: '100%',
            minHeight: 379,
            background: `url(${getImageUrl(
              post.featured_image
            )}) no-repeat center`,
            backgroundSize: 'cover',
          }}
        />
      </>
    );

    const onLike = () => this.props.onLike(post);

    return (
      <ContentContainer
        footer={footer}
        onCommentClick={onCommentClick}
        featured={featured}
        onClick={onClick}
        onLike={onLike}
        liked={like}
        numberOfLike={numberOfLike}
        post={post}
        onShare={onShare}
        categorySubType={post.sub_type}
        onCategoryButtonClick={onCategoryButtonClick}
        nonClickable={this.props.nonClickable}
      />
    );
  }
}

export default compose<Props, IExhibitionProps>(withStyles(styles))(
  OnlineExhibitionContent
);
