import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { Grid, Button, Avatar } from '@mui/material';
import {
  GREY_COLOR,
  SPACING,
  DARK_GREY_COLOR,
  PRIMARY_COLOR,
} from '../../styles/constants';
import {} from '@mui/material/';
import Biography from '../profile/Biography';
import Tags from '../tags/Tags';
import ShareProfileButton from '../../containers/services/ShareProfilleButton';
import { generateCityLocation } from '../../utils/locationHelper';
import { IInstitution } from '../../types/institution';
import { getMediumImageUrl } from '../../utils/imageUrl';
import { useState } from 'react';
import InstitutionService from '../../services/InstitutionService';
import { sumPostTotals } from '../../utils/post-totals-utils';

export interface IProfileInstitutionLargeProps {
  institution: IInstitution;
  goBack?: () => void;
  onSelect?: () => void;
  showEditButton?: boolean;
  onEditClick?: () => void;
  additionalButton?: React.ReactNode;
  onTagClick: (id: string) => void;
  onShareClick?: (institution: IInstitution) => void;
  showBio?: boolean;
}

type Props = IProfileInstitutionLargeProps & WithStyles<string> & WithWidth;

const styles = createStyles({
  textMobile: {
    fontFamily: 'Replica-Regular',
    '& h1': {
      fontSize: 35,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 15,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 12,
    },
  },
  textDesktop: {
    fontFamily: 'Replica-Regular',
    '& h1': {
      fontSize: 50,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 30,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 16,
    },
  },
  gray: {
    color: DARK_GREY_COLOR,
  },
  textMargin: {
    margin: 0,
  },
  profile: {
    backgroundColor: GREY_COLOR,
    padding: SPACING,
    margin: 10,
  },
  mobileProfile: {
    backgroundColor: GREY_COLOR,
    padding: SPACING,
    margin: 10,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '120px',
  },
  mobileWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapperReverse: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    minHeight: 90,
  },
  mobileButtonWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-around',
    flexDirection: 'column-reverse',
  },
  picture: {
    width: 90,
    height: 90,
  },
  mobilePicture: {
    width: 60,
    height: 60,
  },
  right: {
    textAlign: 'right',
    width: '100%',
  },
  button: {
    textTransform: 'none',
    borderRadius: 8,
    // padding: '25px 25px'
  },
  marginLeft: {
    marginLeft: 20,
  },
});

const ProfileInstitutionLarge: React.FC<Props> = (props) => {
  const {
    classes,
    institution,
    onSelect,
    showEditButton,
    onEditClick,
    width,
    additionalButton,
    onTagClick,
    onShareClick,
  } = props;

  const style: React.CSSProperties = {
    position: 'relative',
  };
  if (onSelect) {
    style.cursor = 'pointer';
  }

  const institutionType = institution.institution_type
    ? institution.institution_type
        .toLowerCase()
        .replace('_', ' ')
        .replace(/\w\S*/g, (w: string) =>
          w.replace(/^\w/, (c: string) => c.toUpperCase())
        )
    : '';

  // const hideExtra = isWidthDown(BREAKPOINT, width);
  const isPad = isWidthDown('sm', width);

  const onClickOnShare = () => {
    if (onShareClick) {
      onShareClick(institution);
    }
  };

  const institutionService = new InstitutionService();
  const [totalPost, setTotalPosts] = useState(0);
  React.useEffect(() => {
    institutionService
      .getInstitutionPostTotals(institution.id)
      .then((postTotalsResp) => {
        setTotalPosts(sumPostTotals(postTotalsResp.data));
      });
  }, []);

  return (
    <React.Fragment>
      {/*-------- PROFILE DESKTOP DESIGN -----------*/}
      {!isPad && (
        <Grid
          container={true}
          className={`${classes.profile} ${
            isPad ? classes.textMobile : classes.textDesktop
          }`}
        >
          <Grid item={true} xs={12} sm={4}>
            <div className={classes.wrapper}>
              <Avatar
                alt={`${institution.name}`}
                onClick={onSelect}
                className={classes.picture}
                src={getMediumImageUrl(institution.profilePicture)}
                style={{ float: 'left', marginRight: 10, marginLeft: 16 }}
              />

              <div onClick={onSelect} style={style}>
                {institution.name && (
                  <h3 className={classes.textMargin}>{institution.name}</h3>
                )}
                <p className={`${classes.gray} ${classes.textMargin}`}>
                  {institutionType}
                </p>
                <p className={`${classes.gray} ${classes.textMargin}`}>
                  {generateCityLocation(institution.location)}
                </p>
              </div>
            </div>
          </Grid>
          <Grid
            item={true}
            xs={12}
            sm={5}
            style={{
              minHeight: 120,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                minHeight: 60,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <h3 style={{ margin: '0 35px 0 0' }}>
                  <strong>{totalPost}</strong> Posts
                </h3>
                <h3 style={{ margin: '0 35px 0 0' }}>
                  <strong>{institution.followers_number}</strong> Followers
                </h3>
              </div>
              <div>
                <Tags
                  onTagClick={onTagClick}
                  tags={institution.tags}
                  tagName={''}
                  style={{ fontSize: 16, margin: 0 }}
                />
              </div>
            </div>
          </Grid>
          <Grid item={true} xs={12} sm={3}>
            {showEditButton && (
              <div className={`${classes.wrapperReverse}`}>
                <Button
                  size="large"
                  onClick={onEditClick}
                  className={classes.button}
                  variant="outlined"
                >
                  Edit
                </Button>
                {onShareClick ? (
                  <ShareProfileButton onClick={onClickOnShare} />
                ) : null}
              </div>
            )}
            {additionalButton && (
              <div className={`${classes.wrapperReverse}`}>
                {additionalButton}
              </div>
            )}
          </Grid>
        </Grid>
      )}
      {/*-------- PROFILE MOBILE DESIGN -----------*/}
      {isPad && (
        <Grid
          container={true}
          className={`${classes.mobileProfile} ${classes.textMobile}`}
        >
          <Grid item={true} xs={9} sm={10} className={classes.mobileWrapper}>
            <Avatar
              alt={`${institution.name}`}
              onClick={onSelect}
              className={classes.mobilePicture}
              src={getMediumImageUrl(institution.profilePicture)}
              style={{ float: 'left', marginRight: 12, marginLeft: 0 }}
            />
            <div onClick={onSelect}>
              {institution.name && (
                <h3 className={classes.textMargin} style={{ fontSize: 16 }}>
                  {institution.name}
                </h3>
              )}
              <p className={`${classes.gray} ${classes.textMargin}`}>
                {institutionType}
              </p>
              <p className={`${classes.gray} ${classes.textMargin}`}>
                {generateCityLocation(institution.location)}
              </p>
            </div>
          </Grid>
          <Grid item={true} xs={3} sm={2}>
            {showEditButton && (
              <div className={`${classes.mobileButtonWrapper}`}>
                {onShareClick ? (
                  <ShareProfileButton
                    buttonSize="small"
                    onClick={onClickOnShare}
                  />
                ) : null}
                <Button
                  size="small"
                  onClick={onEditClick}
                  className={classes.button}
                  variant="outlined"
                >
                  Edit
                </Button>
              </div>
            )}
            {additionalButton && (
              <div className={`${classes.mobileButtonWrapper}`}>
                {additionalButton}
              </div>
            )}
          </Grid>
          <Grid item={true} xs={3} sm={2} />
          <Grid
            item={true}
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <p style={{ margin: '5px 10px 0px 0px', fontSize: 12 }}>
              <strong>{totalPost}</strong> Posts
            </p>
            <p style={{ margin: '5px 10px 0px 0px', fontSize: 12 }}>
              <strong>{institution.followers_number}</strong> Followers
            </p>
          </Grid>
        </Grid>
      )}
      {props.showBio && institution.about && institution.about !== '' && (
        <Grid
          container={true}
          className={isPad ? classes.textMobile : classes.textDesktop}
          style={{
            margin: '10px 20px',
            borderBottom: `1px solid ${PRIMARY_COLOR}`,
            paddingBottom: 20,
          }}
        >
          <Grid item={true} xs={12} sm={4}>
            <h3 style={{ color: PRIMARY_COLOR, marginTop: 0 }}>Biography</h3>
          </Grid>
          <Grid item={true} xs={12} sm={8}>
            <Biography
              user={institution}
              showAddress={true}
              height={isPad ? 135 : 152}
              socialFontSize={16}
              background={'white'}
            >
              <p
                style={{
                  margin: 0,
                  lineHeight: isPad ? '15px' : '19px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {institution.about}
              </p>
            </Biography>

            {isPad && (
              <div>
                <Tags
                  onTagClick={onTagClick}
                  tags={institution.tags}
                  tagName={'Curatorial Expertise'}
                  style={{ fontSize: 12, margin: 0 }}
                />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default compose<Props, IProfileInstitutionLargeProps>(
  withStyles(styles),
  withWidth()
)(ProfileInstitutionLarge);
