import * as React from 'react';
import { compose } from 'recompose';
import { DARK_GREY_COLOR, PRIMARY_COLOR } from '../../styles/constants';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import Button, { ButtonProps } from '@mui/material/Button';

const style = createStyles({
  root: {
    width: '100%',
    height: 40,
    marginRight: 10,
    borderRadius: 8,
    borderColor: DARK_GREY_COLOR,
    textTransform: 'none',
  },
  filterIcon: {
    height: 9,
    opacity: 0.5,
  },
  text: {
    fontSize: 14,
    padding: 5,
  },
  number: {
    fontSize: 12,
  },
  filterCircle: {
    backgroundColor: PRIMARY_COLOR,
    width: 20,
    height: 20,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
  },
});

type Props = WithStyles<string> & IIconButtonProps;

export interface IIconButtonProps extends ButtonProps {
  // src: string;
  handleClick: (value: any) => any;
  filters: any;
  icon: any;
}

class IconButton extends React.Component<Props, IIconButtonProps> {
  public getFilterLength = () => {
    const filters = Object.keys(this.props.filters).filter((key) => {
      return key !== 'query' && this.props.filters[key] !== '';
    });
    return filters.length;
  };

  public render() {
    const { classes, icon, handleClick, filters } = this.props;

    return (
      <Button
        onClick={handleClick}
        variant="outlined"
        color="secondary"
        className={classes.root}
      >
        {filters && this.getFilterLength() !== 0 ? (
          <div className={classes.filterCircle}>
            <span className={classes.number}>{this.getFilterLength()}</span>
          </div>
        ) : (
          icon
        )}
        <span className={classes.text}>{this.props.children}</span>
      </Button>
    );
  }
}

export default compose<Props, IIconButtonProps>(withStyles(style))(IconButton);
