import { ICommentState, CommentAction } from './types';

const initialState: ICommentState = {
  comments: [],
  isLoading: false,
  error: {},
};

export default function comments(
  state = initialState,
  action: CommentAction
): ICommentState {
  switch (action.type) {
    case '@@comments/GET_COMMENTS':
      return {
        ...state,
        comments: [],
        isLoading: true,
      };
    case '@@comments/GET_COMMENTS_SUCCESS':
      return {
        ...state,
        comments: action.payload.comments,
        isLoading: false,
      };
    case '@@comments/UPDATE_COMMENT':
      return {
        ...state,
        isLoading: true,
      };
    case '@@comments/UPDATE_COMMENT_SUCCESS':
      return {
        ...state,
        comments: state.comments.map((comment) =>
          comment.id === action.payload.comment.id
            ? action.payload.comment
            : comment
        ),
        isLoading: false,
      };
    case '@@comments/CREATE_COMMENT':
      return {
        ...state,
        isLoading: true,
      };
    case '@@comments/CREATE_COMMENT_SUCCESS':
      return {
        ...state,
        comments: [action.payload.comment].concat(state.comments),
        isLoading: false,
      };
    case '@@comments/DELETE_COMMENT':
      return {
        ...state,
        isLoading: true,
      };
    case '@@comments/DELETE_COMMENT_SUCCESS':
      return {
        ...state,
        comments: state.comments.filter(
          (c) => c.id !== action.payload.commentId
        ),
        isLoading: false,
      };
    case '@@comments/ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
