import {
  default as withWidth,
  isWidthDown,
  WithWidth,
} from '@mui/material/Hidden/withWidth';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import * as React from 'react';
import { useRef, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Grid } from '@mui/material';
import { compose } from 'recompose';
import {
  IWithAccountProps,
  withAccount,
} from '../../containers/account/InjectAccount';
import { DARK_GREY_COLOR, PRIMARY_COLOR } from '../../styles/constants';
import { getImageUrl, getMediumImageUrl } from '../../utils/imageUrl';
import { ILink } from '../../types/links';
import Title from '../../library/dataDisplay/typography/Title';
import { ReactTinyLink } from 'react-tiny-link';
import ReactPlayer from 'react-player';
import Biography from '../profile/Biography';
import Tags from '../tags/Tags';
import OnlineExhibitionMapContainer from '../../containers/onlineexhibition/OnlineExhibitionMapContainer';
import CommentSection from './CommentSection';
import { IPost } from '../../types/posts';
import {
  getArtworkArtistList,
  getExhibitionPointTitle,
  getExhibitionPointType,
  getExhibitionPointYear,
  getFormattedPointOrder,
  PointType,
} from '../../utils/exhibition';
import { Link } from 'react-router-dom';
import Follow from '../../containers/users/Follow';
import { StyledButton } from '@acg/frontend-ui-artpool';
import { onBuyNftClick } from '../../artpool-library/utilities/shared-utilities';

export interface IOnlineExhibitionTabsProps {
  onlineExhibition?: any;
  actionButtons?: any;
  onTagClick?: any;
  post?: IPost;
}

const HEADER_HEIGHT = 80;

const styles = createStyles({
  textMobile: {
    fontFamily: 'Replica-Regular',

    '& h1': {
      fontSize: 35,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: '20px',
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: '20px',
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: '15px',
    },
  },
  textDesktop: {
    fontFamily: 'Replica-Regular',
    '& h1': {
      fontSize: '50px',
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: '30px',
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: '20px',
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: '16px',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: PRIMARY_COLOR,
    },
  },
  tabs: {
    // marginTop: 10,
    fontSize: 16,
    letterSpacing: -0.12,
    textAlign: 'right',
    minWidth: 120,
    // color: '#acabac',
    '& button': {
      minWidth: 120,
      fontSize: 16,
    },
  },
  fixedTabs: {
    position: 'fixed',
    top: HEADER_HEIGHT,
    backgroundColor: 'white',
    zIndex: 1000,
    paddingRight: 20,
  },
  flexContainer: {
    justifyContent: 'space-between',
  },

  indicator: {
    backgroundColor: PRIMARY_COLOR,
  },
  scrollButton: {
    width: 10,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    '&:hover': {
      opacity: '0.7',
    },
  },
  artworkContent: {
    width: '95%',
  },
  artworkContainer: {
    paddingTop: '1em',
    borderBottom: '1px solid black',
    cursor: 'pointer',
  },
  borderPrimary: {
    borderTop: `1px solid ${PRIMARY_COLOR}`,
    paddingLeft: 20,
  },
  borderBlack: {
    paddingTop: 10,
    borderBottom: `1px solid black`,
  },
  contentContainer: {
    padding: '30px 20px 0px 20px',
  },
  mobileContentContainer: {
    padding: '10px 0px 0px 0px',
  },
  creditText: {
    textAlign: 'center',
    fontSize: 12,
    color: '#afb0b3',
  },
  creditTextMobile: {
    textAlign: 'center',
    fontSize: 12,
    color: '#afb0b3',
  },
  headerSection: {
    margin: 0,
    color: DARK_GREY_COLOR,
    padding: '40px 0px 20px 0px',
  },
});

type Props = WithStyles<string> &
  IWithAccountProps &
  IOnlineExhibitionTabsProps &
  WithWidth;

function OnlineExhibitionTabs(props: Props) {
  const {
    onlineExhibition,
    width,
    classes,
    onTagClick,
    actionButtons,
    post,
    account,
  } = props;

  const [tabSelected, setTabSelected] = React.useState('Exhibition Map');
  const [hasMoreInfo, setHasMoreInfo] = React.useState(false);
  const [reachedTop, setReachedTop] = React.useState(false);
  const [initialIndex, setInitialIndex] = useState(-1);
  const [modalOpen, setModalOpen] = useState(false);

  const mapContainer = useRef<any | undefined>();
  const artWorkContainer = useRef<any | undefined>();
  const curatorialTextContainer = useRef<any | undefined>();
  const artistInfoContainer = useRef<any | undefined>();
  const moreInfoContainer = useRef<any | undefined>();
  const commentContainer = useRef<any | undefined>();
  const pressReleaseContainer = useRef<any | undefined>();
  const exhibitionTabs = useRef<any | undefined>();

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    if (
      (onlineExhibition.other_contributors_list &&
        onlineExhibition.other_contributors_list.length > 0) ||
      (onlineExhibition.acknowledgement_text &&
        onlineExhibition.acknowledgement_text !== '') ||
      (onlineExhibition.imagesWithCredit &&
        onlineExhibition.imagesWithCredit.length > 1) ||
      (onlineExhibition.videos && onlineExhibition.videos.length > 0) ||
      (onlineExhibition.links && onlineExhibition.links.length > 0)
    ) {
      setHasMoreInfo(true);
    }
  });

  const isMobile = isWidthDown('xs', width);
  const isPad = isWidthDown('sm', width);
  const TAB_HEADER_HEIGHT = HEADER_HEIGHT + 50;

  const renderLinks = (links: ILink[]) => {
    const linksList = links.map((link, index) => {
      if (!link.link_url.includes('http')) {
        link.link_url = 'http://' + link.link_url;
      }
      const regexURLFormula =
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

      const onErrorEvt = () => {
        return (
          <Grid
            item={true}
            xs={12}
            key={`_link_${index}`}
            style={{ margin: '20px 0' }}
          >
            <Title>{link.link_title}</Title> <br />
            <a href={link.link_url} target="_blank">
              {' '}
              {link.link_url}
            </a>
            <br />
          </Grid>
        );
      };

      // Case the url is miss-speld
      if (!regexURLFormula.test(link.link_url)) {
        return (
          <Grid
            item={true}
            xs={12}
            key={`__link_${index}`}
            style={{ margin: '20px 0' }}
          >
            <Title>{link.link_title}</Title> <br />
            <a href={link.link_url} target="_blank">
              {' '}
              {link.link_url}
            </a>
            <br />
          </Grid>
        );
      }
      return (
        <Grid
          item={true}
          xs={12}
          key={`___link_${index}`}
          style={{ margin: '20px 0' }}
        >
          <Title>{link.link_title}</Title> <br />
          <ReactTinyLink
            cardSize="small"
            showGraphic={true}
            maxLine={2}
            minLine={1}
            width={'100%'}
            url={link.link_url}
            // proxyUrl={"https://cors-anywhere.herokuapp.com"}
            onError={onErrorEvt}
          />
          <br />
        </Grid>
      );
    });

    const linksSection = (
      <Grid
        item={true}
        xs={12}
        key={'links_section'}
        style={{ margin: '20px 0' }}
      >
        {linksList}
      </Grid>
    );

    return linksSection;
  };

  const handleTabChange = (e: any, val: string) => {
    setTabSelected(val);
    switch (val) {
      case 'Exhibition Map':
        window.scroll({
          top: mapContainer.current.offsetTop - TAB_HEADER_HEIGHT,
          behavior: 'smooth',
        });
        break;
      case 'Curatorial Text':
        window.scroll({
          top: curatorialTextContainer.current.offsetTop - TAB_HEADER_HEIGHT,
          behavior: 'smooth',
        });
        break;
      case 'More Info':
        window.scroll({
          top: moreInfoContainer.current.offsetTop - TAB_HEADER_HEIGHT,
          behavior: 'smooth',
        });
        break;
      case 'Press Release':
        window.scroll({
          top: pressReleaseContainer.current.offsetTop - TAB_HEADER_HEIGHT,
          behavior: 'smooth',
        });
        break;
      case 'Artist Info':
        window.scroll({
          top: artistInfoContainer.current.offsetTop - TAB_HEADER_HEIGHT,
          behavior: 'smooth',
        });
        break;
      case 'Artwork List':
        window.scroll({
          top: artWorkContainer.current.offsetTop - TAB_HEADER_HEIGHT,
          behavior: 'smooth',
        });
        break;
      case 'Comments':
        window.scroll({
          top: commentContainer.current.offsetTop - TAB_HEADER_HEIGHT,
          behavior: 'smooth',
        });
        break;
    }
  };

  const renderMoreInfo = () => {
    return (
      onlineExhibition &&
      hasMoreInfo && (
        <Grid item={true} xs={12} sm={8}>
          <h3 className={classes.headerSection}>More Info</h3>
          {onlineExhibition.other_contributors_list &&
            onlineExhibition.other_contributors_list.length > 0 && (
              <React.Fragment>
                <h3>
                  Other contributors:{' '}
                  {onlineExhibition.other_contributors_list.join(', ')}
                </h3>
              </React.Fragment>
            )}

          {onlineExhibition.acknowledgement_text && (
            <React.Fragment>
              <h3 style={{ whiteSpace: 'pre-wrap' }}>
                {onlineExhibition.acknowledgement_text}
              </h3>
            </React.Fragment>
          )}

          {onlineExhibition.videos &&
            onlineExhibition.videos.map((video: any, index: number) => {
              return (
                <Grid
                  item={true}
                  xs={12}
                  key={`videos_${index}`}
                  style={{ margin: '20px 0' }}
                >
                  {/*<Title>{video.video_title}</Title>*/}
                  <br />

                  <div className="playerWrapper">
                    <ReactPlayer
                      className="reactPlayer"
                      url={video.video_link}
                      playing={false}
                      width="100%"
                      controls={true}
                      vimeo={{ playerOptions: { height: '463px' } }}
                    />
                  </div>
                  <p
                    className={
                      isMobile ? classes.creditTextMobile : classes.creditText
                    }
                  >
                    {video.video_title}
                  </p>
                </Grid>
              );
            })}

          {onlineExhibition.links && renderLinks(onlineExhibition.links)}

          {onlineExhibition.imagesWithCredit &&
            onlineExhibition.imagesWithCredit.map((image: any) => {
              return (
                <Grid item={true} xs={12} key={'_image_' + image.id}>
                  <img
                    style={{ maxWidth: '100%' }}
                    src={getImageUrl(image.image_url)}
                  />
                  <p
                    className={
                      isMobile ? classes.creditTextMobile : classes.creditText
                    }
                  >
                    {image.image_credit}
                  </p>
                </Grid>
              );
            })}
        </Grid>
      )
    );
  };
  const renderComments = () => {
    return (
      <Grid item={true} xs={12} sm={8}>
        <h3 className={classes.headerSection}>Comments</h3>
        <CommentSection post={post} />
      </Grid>
    );
  };

  const renderPressRelease = () => {
    return (
      onlineExhibition &&
      onlineExhibition.press_release && (
        <Grid item={true} xs={12} sm={8}>
          <h3 className={classes.headerSection}>Press Release</h3>
          <h3 style={{ whiteSpace: 'pre-wrap' }}>
            {onlineExhibition.press_release}
          </h3>
        </Grid>
      )
    );
  };

  const renderArtistInfo = () => {
    const getArtistsInfo = () => {
      const artistsList: any[] = [];
      if (onlineExhibition && onlineExhibition.existing_artists) {
        onlineExhibition.existing_artists.forEach((artist: any) => {
          artistsList.push(artist);
        });
      }
      if (onlineExhibition && onlineExhibition.artists) {
        onlineExhibition.artists.forEach((artist: any) => {
          artistsList.push(artist);
        });
      }
      return artistsList;
    };
    return (
      onlineExhibition && (
        <React.Fragment>
          {/*<h3 className={classes.headerSection}>Artist Info</h3>*/}
          {getArtistsInfo().map((artist: any, i: any) => {
            return (
              <Grid
                item={true}
                xs={12}
                sm={8}
                key={i}
                className={classes.borderBlack}
              >
                <Grid
                  container={true}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Grid item={true}>
                    <h2 style={{ margin: 0 }}>
                      {artist.name ? (
                        artist.name
                      ) : (
                        <Link
                          to={`/users/${artist.url}/posts`}
                          className={classes.link}
                        >
                          {artist.firstName} {artist.lastName}
                        </Link>
                      )}
                    </h2>
                  </Grid>
                  {artist.url && (
                    <Grid item={true}>
                      <Link
                        to={`/users/${artist.url}/posts`}
                        className={classes.link}
                      >
                        <Follow
                          user={artist}
                          isFollowing={false}
                          buttonSize={isPad ? 'small' : 'large'}
                        />
                      </Link>
                    </Grid>
                  )}
                </Grid>
                {artist.location && artist.location.address && (
                  <h3
                    style={{
                      margin: 0,
                      color: '#afb0b3',
                    }}
                  >
                    {artist.location.address}
                  </h3>
                )}
                <h3
                  style={{
                    margin: 0,
                    color: '#afb0b3',
                  }}
                >
                  {artist.nationality}
                  {artist.nationality && artist.birthYear && ','}{' '}
                  {artist.birthYear && `b. ${artist.birthYear}`}
                </h3>
                <Biography
                  user={artist}
                  height={400}
                  socialFontSize={16}
                  background={'white'}
                >
                  <h3 style={{ whiteSpace: 'pre-wrap' }}>{artist.bio}</h3>
                </Biography>
              </Grid>
            );
          })}
          {/* To display old online exhibition artists*/}
          {onlineExhibition.artists_list.length > 0 &&
            onlineExhibition.artists_list[0] !== '' &&
            onlineExhibition.artists_list.map((artist: any, i: any) => {
              return (
                <Grid
                  item={true}
                  xs={12}
                  sm={8}
                  key={i}
                  className={classes.borderBlack}
                >
                  <h2>{artist}</h2>
                </Grid>
              );
            })}
        </React.Fragment>
      )
    );
  };

  const renderCuratorialText = () => {
    return (
      <Grid item={true} xs={12} sm={8} style={{ marginBottom: 10 }}>
        <h3 className={classes.headerSection}>Curatorial Text</h3>
        <h3 style={{ whiteSpace: 'pre-wrap' }}>
          {onlineExhibition.description}
        </h3>

        {onlineExhibition.tags && (
          <Tags
            onTagClick={onTagClick}
            tags={onlineExhibition.tags}
            key={'tags'}
            style={
              onlineExhibition.tags.length
                ? {
                    margin: '20px 0',
                    fontSize: isMobile ? 16 : 20,
                  }
                : {}
            }
          />
        )}
      </Grid>
    );
  };

  const renderArtworkList = () => {
    return (
      onlineExhibition && (
        <Grid container={true}>
          {onlineExhibition.exhibition_points &&
            onlineExhibition.exhibition_points.map((point: any) => {
              const handleClick = () => {
                onArtWorkClick(point);
              };

              const onBuyClick = () => {
                onBuyNftClick(point.art_work.nft_data);
              };

              const renderArtworkImage = () => {
                switch (getExhibitionPointType(point)) {
                  case PointType.ARTWORK:
                    return (
                      <>
                        {point.art_work.imagesWithCredit &&
                          point.art_work.imagesWithCredit[0] &&
                          point.art_work.imagesWithCredit[0].image_url && (
                            <img
                              className={classes.image}
                              src={getMediumImageUrl(
                                point.art_work.imagesWithCredit[0].image_url
                              )}
                            />
                          )}
                        {!point.art_work.featured_imageWithCredit &&
                          (!point.art_work.imagesWithCredit ||
                            !point.art_work.imagesWithCredit[0]) &&
                          point.art_work.videos &&
                          point.art_work.videos[0] &&
                          point.art_work.videos[0].video_link && (
                            <ReactPlayer
                              className="reactPlayer"
                              url={point.art_work.videos[0].video_link}
                              playing={false}
                              width="95%"
                              controls={true}
                              vimeo={{
                                playerOptions: {
                                  /*height: '76px'*/
                                },
                              }}
                            />
                          )}
                      </>
                    );
                  case PointType.NFT:
                    return (
                      <img
                        className={classes.image}
                        src={getMediumImageUrl(
                          point.art_work.nft_data.nft.image.src
                        )}
                      />
                    );
                  default:
                    return <></>;
                }
              };

              if (point.info_point) {
                return;
              } else {
                return (
                  <Grid
                    item={true}
                    xs={12}
                    sm={4}
                    key={'_image_' + point.id}
                    className={classes.artworkContainer}
                    onClick={handleClick}
                  >
                    <div className={classes.artworkContent}>
                      {renderArtworkImage()}
                      <Grid container={true} alignItems={'center'}>
                        <Grid item={true} xs={2}>
                          <p>{getFormattedPointOrder(point)}.</p>
                        </Grid>
                        <Grid
                          item={true}
                          xs={
                            getExhibitionPointType(point) === PointType.NFT
                              ? 6
                              : 10
                          }
                        >
                          <p style={{ marginBottom: 0 }}>
                            {getArtworkArtistList(point)}
                          </p>
                          <p style={{ marginTop: 0 }}>
                            {getExhibitionPointTitle(point).toUpperCase()}{' '}
                            {getExhibitionPointYear(point)}
                          </p>
                        </Grid>
                        {getExhibitionPointType(point) === PointType.NFT && (
                          <Grid item={true} xs={4}>
                            <StyledButton
                              fullWidth={true}
                              variant={'contained'}
                              size={'small'}
                              style={{ padding: '10px 25px' }}
                              onClick={onBuyClick}
                            >
                              Buy
                            </StyledButton>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  </Grid>
                );
              }
            })}
        </Grid>
      )
    );
  };

  // let content = <React.Fragment/>

  const TabTypeOptions = [
    {
      id: 'Exhibition Map',
      label: 'Exhibition Map',
      text: 'Exhibition Map',
      tabLabel: 'Exhibition Map',
    },
    {
      id: 'Curatorial Text',
      label: 'Curatorial Text',
      text: 'Curatorial Text',
      tabLabel: 'Curatorial Text',
    },
    {
      id: 'Artwork List',
      label: 'Artwork List',
      text: 'Artwork List',
      tabLabel: 'Artwork List',
    },
    {
      id: 'Artist Info',
      label: 'Artist Info',
      text: 'Artist Info',
      tabLabel: 'Artist Info',
    },
  ];

  if (onlineExhibition.press_release) {
    TabTypeOptions.push({
      id: 'Press Release',
      label: 'Press Release',
      text: 'Press Release',
      tabLabel: 'Press Release',
    });
  }
  if (hasMoreInfo) {
    TabTypeOptions.push({
      id: 'More Info',
      label: 'More Info',
      text: 'More Info',
      tabLabel: 'More Info',
    });
  }
  if (account) {
    TabTypeOptions.push({
      id: 'Comments',
      label: 'Comments',
      text: 'Comments',
      tabLabel: 'Comments',
    });
  }

  const handleScroll = (event: any) => {
    // ----- To fix the tabs to the top ------
    if (
      exhibitionTabs.current &&
      exhibitionTabs.current.getBoundingClientRect().y < HEADER_HEIGHT &&
      !reachedTop
    ) {
      setReachedTop(true);
    } else if (window.scrollY < 644 && reachedTop) {
      // 644 is the position of the exhibition tabs container minus the height of the header
      setReachedTop(false);
    }

    // ----- To change tabs on scroll ------
    if (
      mapContainer.current &&
      window.scrollY >= mapContainer.current.offsetTop - TAB_HEADER_HEIGHT &&
      window.scrollY <
        curatorialTextContainer.current.offsetTop - TAB_HEADER_HEIGHT
    ) {
      setTabSelected('Exhibition Map');
    } else if (
      curatorialTextContainer.current &&
      window.scrollY >=
        curatorialTextContainer.current.offsetTop - TAB_HEADER_HEIGHT &&
      window.scrollY < artWorkContainer.current.offsetTop - TAB_HEADER_HEIGHT
    ) {
      setTabSelected('Curatorial Text');
    } else if (
      artWorkContainer.current &&
      window.scrollY >=
        artWorkContainer.current.offsetTop - TAB_HEADER_HEIGHT &&
      window.scrollY < artistInfoContainer.current.offsetTop - TAB_HEADER_HEIGHT
    ) {
      setTabSelected('Artwork List');
    } else if (
      artistInfoContainer.current &&
      window.scrollY >=
        artistInfoContainer.current.offsetTop - TAB_HEADER_HEIGHT &&
      window.scrollY <
        pressReleaseContainer.current.offsetTop - TAB_HEADER_HEIGHT
    ) {
      setTabSelected('Artist Info');
    } else if (
      pressReleaseContainer.current &&
      window.scrollY >=
        pressReleaseContainer.current.offsetTop - TAB_HEADER_HEIGHT &&
      window.scrollY < moreInfoContainer.current.offsetTop - TAB_HEADER_HEIGHT
    ) {
      setTabSelected('Press Release');
    } else if (
      moreInfoContainer.current &&
      window.scrollY >=
        moreInfoContainer.current.offsetTop - TAB_HEADER_HEIGHT &&
      window.scrollY < commentContainer.current.offsetTop - TAB_HEADER_HEIGHT
    ) {
      setTabSelected('More Info');
    } else if (
      commentContainer.current &&
      window.scrollY >= commentContainer.current.offsetTop - TAB_HEADER_HEIGHT
    ) {
      setTabSelected('Comments');
    }
  };
  const onArtWorkClick = (point: any) => {
    setInitialIndex(point.point_order - 1);
    setModalOpen(true);
  };
  return (
    <React.Fragment>
      <Grid
        container={true}
        ref={exhibitionTabs}
        className={`${isMobile ? classes.textMobile : classes.textDesktop} ${
          reachedTop && classes.fixedTabs
        }`}
        justifyContent="space-between"
        alignItems={'center'}
      >
        <Grid item={true} xs={12} md={8} className={classes.tabs}>
          {tabSelected && (
            <Tabs
              value={tabSelected}
              classes={{
                root: classes.tabs,
                indicator: classes.indicator,
                scrollButtons: classes.scrollButton,
                flexContainer: classes.flexContainer,
              }}
              style={{ justifyContent: 'space-between' }}
              onChange={handleTabChange}
              scrollButtons={true}
              variant="scrollable"
            >
              {TabTypeOptions.map((type) => {
                return (
                  <Tab
                    style={{ textTransform: 'none' }}
                    label={type.tabLabel}
                    value={type.id}
                    key={type.id}
                  />
                );
              })}
            </Tabs>
          )}
        </Grid>
        {!isMobile && (
          <Grid item={true} xs={12} md={4}>
            {actionButtons}
          </Grid>
        )}
      </Grid>
      {reachedTop && <div style={{ height: 54 }} />}
      <Grid
        container={true}
        className={
          isMobile ? classes.mobileContentContainer : classes.contentContainer
        }
      >
        <Grid item={true} xs={12} ref={mapContainer}>
          <OnlineExhibitionMapContainer
            initialIndex={initialIndex}
            modalOpen={modalOpen}
            onArtWorkClick={onArtWorkClick}
            setModalOpen={setModalOpen}
            setInitialIndex={setInitialIndex}
          />
        </Grid>
        <Grid item={true} xs={12} ref={curatorialTextContainer}>
          {renderCuratorialText()}
        </Grid>
        <Grid item={true} xs={12} ref={artWorkContainer}>
          {renderArtworkList()}
        </Grid>
        <Grid item={true} xs={12} ref={artistInfoContainer}>
          {renderArtistInfo()}
        </Grid>
        <Grid item={true} xs={12} ref={pressReleaseContainer}>
          {renderPressRelease()}
        </Grid>
        <Grid item={true} xs={12} ref={moreInfoContainer}>
          {renderMoreInfo()}
        </Grid>
        {account && (
          <Grid item={true} xs={12} ref={commentContainer}>
            {renderComments()}
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}

export default compose<Props, IOnlineExhibitionTabsProps>(
  withStyles(styles),
  withWidth(),
  withAccount
)(OnlineExhibitionTabs);
