import * as React from 'react';
import { IExhibitionPoint } from '../../types/exhibitions';
import { Polyline } from 'react-leaflet';

export interface IOnlineExhibitionRenderLinesProps {
  pointsList: IExhibitionPoint[];
  artworksLineColor: string;
  markersArray: any;
}

function OnlineExhibitionRenderLines(props: IOnlineExhibitionRenderLinesProps) {
  const { pointsList, artworksLineColor, markersArray } = props;

  const latlangs: any = [];

  pointsList.forEach((point: IExhibitionPoint) => {
    latlangs.push(point.point_position);
  });

  return (
    <Polyline
      positions={latlangs}
      // @ts-ignore
      color={artworksLineColor}
      weight={2}
      ref={markersArray}
    />
  );
}

export default OnlineExhibitionRenderLines;
