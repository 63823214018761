import * as React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Field, Formik, FormikHelpers, FormikProps } from 'formik';
import {
  generateValidator,
  IValidationConfig,
} from '../../../utils/formatters';
import BackOfficeServices, {
  IRegisterUserRequest,
} from '../../../services/BackOfficeServices';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ACGModalContent from '../../../components/modal/ACGModalContent';
import FormMessage from '../../../components/form/FormMessage';
import TextInput from '../../../components/form/TextInput';
import ProfileUpload from '../../../components/form/ProfileUpload';
import SelectInput from '../../../components/form/SelectInput';
import FormSubmit from '../../../components/form/FormSubmit';
import FormCancel from '../../../components/form/FormCancel';
import LocationFinder from '../../../components/form/LocationFinder';
import RadioInput from '../../../components/form/RadioInput';
import CheckboxInput from '../../../components/form/CheckboxInput';
import { environment } from '../../../environments/environment';

const PREFIX = 'CreateUserModal';

const classes = {
  text: `${PREFIX}-text`,
  heading: `${PREFIX}-heading`,
  profileHeading: `${PREFIX}-profileHeading`,
  profileHeadingViewer: `${PREFIX}-profileHeadingViewer`,
  radioHelper: `${PREFIX}-radioHelper`,
  details: `${PREFIX}-details`,
  column: `${PREFIX}-column`,
};

const StyledACGModal = styled(ACGModalContent)({
  [`& .${classes.text}`]: {
    color: 'rgba(0, 0, 0, 0.64)',
    fontSize: '0.9em',
  },
  [`& .${classes.heading}`]: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  [`& .${classes.profileHeading}`]: {
    color: 'rgba(0, 0, 0, 0.84)',
    fontSize: '0.875rem',
    paddingTop: 47,
  },
  [`& .${classes.profileHeadingViewer}`]: {
    color: 'rgba(0, 0, 0, 0.84)',
    fontSize: '0.875rem',
  },
  [`& .${classes.radioHelper}`]: {
    fontSize: 12,
    color: '#acabac',
    margin: 0,
    marginBottom: 10,
  },
  [`& .${classes.details}`]: {
    alignItems: 'center',
    paddingLeft: 15,
  },
  [`& .${classes.column}`]: {
    flexBasis: '33.33%',
  },
});

const { REACT_APP_DEFAULT_PASSWORD } = environment;

export interface ICreateUserModalProps {
  onClickAway: () => void;
}

type IInitialValues = IRegisterUserRequest;

const CreateUserModal = (props: ICreateUserModalProps) => {
  const backOfficeServices = new BackOfficeServices();

  const { onClickAway } = props;

  const [error, setError] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const onSubmit = async (
    values: IInitialValues,
    actions: FormikHelpers<IInitialValues>
  ) => {
    setError(false);
    try {
      if (values.profile === 'VIEWER') {
        delete values.company_type;
        delete values.company_name;
      }
      await backOfficeServices.createUser({ ...values });
      location.reload();
      setError(false);
    } catch (err) {
      setError(true);
    }
    actions.setSubmitting(false);
  };

  const renderForm = (formProps: FormikProps<IInitialValues>) => {
    const { values } = formProps;

    const handleChange = () => (event: React.ChangeEvent<{}>) => {
      setIsExpanded(!isExpanded);
    };

    const onProfileChange = (event: React.ChangeEvent<any>) => {
      formProps.handleChange(event);
      values.isExhibitionAdmin = event.target.value === 'CURATOR';
    };

    return (
      <form onSubmit={formProps.handleSubmit}>
        <Grid container={true} spacing={1}>
          {!!error && (
            <FormMessage
              message={'There is an unexpected error, please try again.'}
              visible={!!error}
              type={'ERROR'}
            />
          )}
          <Grid item={true} xs={12}>
            <Field name="profilePicture" component={ProfileUpload} />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              name="email"
              component={TextInput}
              inputProps={{
                id: 'email',
                label: 'Email',
                type: 'email',
              }}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <Field
              name="firstName"
              component={TextInput}
              disabled={true}
              inputProps={{
                id: 'firstName',
                label: 'First Name',
                type: 'firstName',
              }}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <Field
              name="lastName"
              component={TextInput}
              inputProps={{
                id: 'lastName',
                label: 'Last Name',
                type: 'lastName',
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              name="profile"
              inputProps={{ onChange: onProfileChange }}
              component={SelectInput}
              options={[
                { label: 'Curator', value: 'CURATOR' },
                { label: 'Artist', value: 'ARTIST' },
                { label: 'Viewer', value: 'VIEWER' },
              ]}
            />
          </Grid>
          {values.profile === 'VIEWER' && (
            <Grid item={true} xs={12}>
              <Field
                name="type"
                component={SelectInput}
                options={[
                  { label: 'Company', value: 'COMPANY' },
                  { label: 'Art Consultant', value: 'ART CONSULTANT' },
                  { label: 'Art Dealer', value: 'ART DEALER' },
                  { label: 'Art Fair', value: 'ART FAIR' },
                  { label: 'Auction House', value: 'AUCTION HOUSE' },
                  { label: 'Collector', value: 'COLLECTOR' },
                  { label: 'Art Lover', value: 'ART LOVER' },
                  { label: 'Institution', value: 'INSTITUTION' },
                  { label: 'Journalist', value: 'JOURNALIST' },
                  { label: 'Gallery', value: 'GALLERY' },
                  { label: 'Artist', value: 'ARTIST' },
                ]}
              />
            </Grid>
          )}
          {values.profile === 'CURATOR' && (
            <>
              <Grid item={true} xs={12} md={6}>
                <Field
                  name="company_type"
                  component={SelectInput}
                  options={[
                    { label: 'Independent', value: 'INDEPENDANT' },
                    { label: 'Institution', value: 'INSTITUTION' },
                  ]}
                />
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <Field
                  name="company_name"
                  component={TextInput}
                  inputProps={{
                    id: 'company_name',
                    label: 'Institution Name',
                    type: 'company_name',
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item={true} xs={12}>
            <Field
              name="location"
              component={LocationFinder}
              inputProps={{
                id: 'location',
                label: 'Your City',
                type: 'location',
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              name="bio"
              component={TextInput}
              inputProps={{
                id: 'bio',
                label: 'Bio',
                type: 'bio',
                multiline: true,
                rows: 5,
                maxRows: 20,
              }}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <Field
              name="website_url"
              component={TextInput}
              inputProps={{
                id: 'website_url',
                label: 'Your Website URL',
                type: 'website_url',
              }}
            />
            <Field
              name="twitter_url"
              component={TextInput}
              inputProps={{
                id: 'twitter_url',
                label: 'Your Twitter Account',
                type: 'twitter_url',
              }}
            />
            <Field
              name="facebook_url"
              component={TextInput}
              inputProps={{
                id: 'facebook_url',
                label: 'Your Facebook Account',
                type: 'facebook_url',
              }}
            />
          </Grid>

          <Grid item={true} xs={12} md={6}>
            <Field
              name="instagram_url"
              component={TextInput}
              inputProps={{
                id: 'instagram_url',
                label: 'Your Instagram Account',
                type: 'instagram_url',
              }}
            />
            <Field
              name="linkedin_url"
              component={TextInput}
              inputProps={{
                id: 'linkedin_url',
                label: 'Your Linkedin Account',
                type: 'linkedin_url',
              }}
            />
          </Grid>
          <Grid item={true} xs={12} className={classes.text}>
            <Accordion
              expanded={isExpanded}
              onChange={handleChange()}
              style={{
                boxShadow: '0px 0px',
                marginTop: 10,
                marginBottom: 20,
                backgroundColor: 'rgb(250, 250, 250)',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
                className={classes.details}
              >
                <div className={classes.column}>
                  <Typography className={classes.heading}>
                    Profile Settings
                  </Typography>
                </div>
              </AccordionSummary>

              <AccordionDetails className={classes.details}>
                <Grid item={false} xs={12} className={classes.text}>
                  <Grid
                    container={true}
                    xs={12}
                    className={classes.text}
                    direction="row"
                  >
                    <Grid item={true} xs={4} className={classes.profileHeading}>
                      Your profile’s public visibility:
                    </Grid>
                    <Grid item={true} xs={8} className={classes.text}>
                      <Field
                        name="profile_type"
                        component={RadioInput}
                        classes={classes}
                        inputProps={{
                          id: 'profile_type',
                          label: 'Your profile’s public visibility',
                          type: 'profile_type',
                        }}
                        options={[
                          {
                            label: 'Public (recommended)',
                            value: 'PUBLIC',
                            helper:
                              'Build your portfolio, share it and make it visible on search results (Google, Bing…)',
                          },
                          {
                            label: 'Artpool members only',
                            value: 'PRIVATE',
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container={true}
                    xs={12}
                    className={classes.text}
                    direction="row"
                  >
                    <Grid
                      item={true}
                      xs={4}
                      className={classes.profileHeading}
                      style={{}}
                    >
                      Newsletters preference:
                    </Grid>
                  </Grid>
                  <Grid
                    container={true}
                    xs={12}
                    className={classes.text}
                    direction="row"
                  >
                    <Grid item={true} xs={12} className={classes.text}>
                      <Field
                        name="newsletter_network"
                        component={CheckboxInput}
                        classes={classes}
                        inputProps={{
                          id: 'newsletter_network',
                          type: 'newsletter_network',
                        }}
                        label={
                          'I agree to receive latest news from our network (sent approximately once a month).'
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container={true}
                    xs={12}
                    className={classes.text}
                    direction="row"
                  >
                    <Grid item={true} xs={12} className={classes.text}>
                      <Field
                        name="newsletter_curtain"
                        component={CheckboxInput}
                        classes={classes}
                        inputProps={{
                          id: 'newsletter_curtain',
                          type: 'newsletter_curtain',
                        }}
                        label={
                          'I subscribe to Curtain, the Artpool Magazine newsletter. Twice a month, get your insider’s roundup of the latest curators interviewed.'
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              name="isExhibitionAdmin"
              component={CheckboxInput}
              inputProps={{
                id: 'isExhibitionAdmin',
                type: 'isExhibitionAdmin',
              }}
              label={'Has access to the exhibition tool'}
            />
          </Grid>
          <Grid xs={12} container={true} justifyContent="space-between">
            <Grid item={true} xs={3}>
              <FormCancel
                disabled={formProps.isSubmitting}
                onClick={onClickAway}
                newButton={true}
                variant="contained"
              >
                Cancel
              </FormCancel>
            </Grid>

            <Grid item={true} xs={3}>
              <FormSubmit
                disabled={formProps.isSubmitting}
                newButton={true}
                variant="contained"
              >
                Save your changes
              </FormSubmit>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  };

  const initialValues: IRegisterUserRequest = {
    profilePicture: '',
    firstName: '',
    lastName: '',
    email: '',
    bio: '',
    city: '',
    password: REACT_APP_DEFAULT_PASSWORD,
    confirmPassword: REACT_APP_DEFAULT_PASSWORD,
    company_name: '',
    company_type: 'INDEPENDANT',
    profile: 'CURATOR',
    location: {
      address: '',
      value: '',
      placeId: '',
      latLng: [0, 0],
    },
    instagram_url: '',
    linkedin_url: '',
    website_url: '',
    twitter_url: '',
    facebook_url: '',
    url: '',
    newsletter_network: true,
    newsletter_curtain: true,
    profile_type: 'PUBLIC',
    isExhibitionAdmin: true,
  };

  const config: IValidationConfig = {
    email: ['REQUIRED'],
    firstName: ['REQUIRED'],
    lastName: ['REQUIRED'],
  };

  const validate = generateValidator(config);

  return (
    <StyledACGModal>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
        render={renderForm}
      />
    </StyledACGModal>
  );
};

export default CreateUserModal;
