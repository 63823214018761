import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { Grid, Paper } from '@mui/material';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {
  IWithAccountProps,
  withAccount,
} from '../../containers/account/InjectAccount';
import { ACGAppPaths } from '@acg/shared/const';

export interface INotFoundRedirectProps {}

const style = createStyles({
  fullScreen: {
    height: '100vh',
  },
  backgroundFilled: {
    backgroundColor: '#f5f5f5',
    backgroundSize: 'cover',
  },
});

type Props = WithStyles<string> &
  IWithAccountProps &
  WithWidth &
  RouteComponentProps<{}>;

class NotFoundRedirect extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const { classes, history, account } = this.props;

    setTimeout(() => {
      if (account) {
        history.push(ACGAppPaths.ROOT);
      } else {
        history.push('/');
      }
    }, 5000);

    return (
      <Grid
        container={true}
        direction="row"
        justifyContent={'center'}
        alignItems={'center'}
        style={{ overflowY: 'auto' }}
        className={`${classes.fullScreen} ${classes.backgroundFilled}`}
      >
        <Grid xs={12} sm={12} md={8} lg={6} item={true}>
          <Paper elevation={1} style={{ padding: 30, textAlign: 'center' }}>
            Something went wrong!
            <br />
            <Link to={'/'}>Back to the Grid</Link>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default compose<Props, INotFoundRedirectProps>(
  withRouter,
  withStyles(style),
  withWidth(),
  withAccount
)(NotFoundRedirect);
