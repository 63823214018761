import { IComment } from '../../../types/comments';
import { action } from 'typesafe-actions';

/**
 *
 * GET COMMENTS
 */
export function getComments(id: string) {
  return action('@@comments/GET_COMMENTS', { id });
}

export function getCommentsSuccess(comments: IComment[]) {
  return action('@@comments/GET_COMMENTS_SUCCESS', { comments });
}

export function getCommentsError(error: Error) {
  return action('@@comments/ERROR', { error });
}

/**
 *
 * CREATE COMMENT
 */
export function createComment(postId: string, comment: Partial<IComment>) {
  return action('@@comments/CREATE_COMMENT', { postId, comment });
}

export function createCommentSuccess(comment: IComment) {
  return action('@@comments/CREATE_COMMENT_SUCCESS', { comment });
}

export function createCommentError(error: Error) {
  return action('@@comments/ERROR', { error });
}

/**
 *
 * UPDATE COMMENTS
 */
export function updateComment(
  id: string,
  commentId: string,
  comment: Partial<IComment>
) {
  return action('@@comments/UPDATE_COMMENT', { id, commentId, comment });
}

export function updateCommentSuccess(comment: IComment) {
  return action('@@comments/UPDATE_COMMENT_SUCCESS', { comment });
}

export function updateCommentError(error: Error) {
  return action('@@comments/ERROR', { error });
}

/**
 *
 * DELETE COMMENTS
 */
export function deleteComment(id: string, commentId: string) {
  return action('@@comments/DELETE_COMMENT', { id, commentId });
}

export function deleteCommentSuccess(commentId: string) {
  return action('@@comments/DELETE_COMMENT_SUCCESS', { commentId });
}

export function deleteCommentError(error: Error) {
  return action('@@comments/ERROR', { error });
}
