import { WithWidth } from '@mui/material/Hidden/withWidth';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { Grid } from '@mui/material';
import { withAccount } from '../../containers/account/InjectAccount';
import Title from '../../library/dataDisplay/typography/Title';
import Text from '../../library/dataDisplay/typography/Text';
import { getImageUrl } from '../../utils/imageUrl';
import { ILink } from '../../types/links';
import { ReactTinyLink } from 'react-tiny-link';
import ReactPlayer from 'react-player';
import OnlineExhibitionLayout from '../../library/layout/container/OnlineExhibitionLayout';
import SubTitle from '../../library/dataDisplay/typography/SubTitle';
import { dateToStringParser } from '../../utils/datetimehelper';
import {
  getArtworkDescriptionText,
  getExhibitionArtists,
  getExhibitionPointTitle,
  getExhibitionPointType,
  getExhibitionPointYear,
  PointType,
} from '../../utils/exhibition';
import { Link } from 'react-router-dom';
import { NEUTRAL, PRIMARY_COLOR } from '../../styles/constants';
import {
  editionStr,
  formatUserOrVenueData,
  onBuyNftClick,
} from '../../artpool-library/utilities/shared-utilities';
import { ButtonColors, StyledButton } from '@acg/frontend-ui-artpool';
import { IExhibitionPoint } from '../../types/exhibitions';
import SquaredCardContainer from '../../artpool-library/components/surfaces/squared-card-container';

export interface IExhibitionArtPointProps {
  orderValue: string;
  exhibitionPoint: IExhibitionPoint;
  onlineExhibitionData: any;
  mobile: boolean;
}

const style = createStyles({
  root: {
    padding: '0 22px 22px 22px',
    minHeight: '100vh',
  },
  link: {
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
      color: PRIMARY_COLOR,
    },
  },
  containerPadding: {
    paddingTop: 20,
  },
  orderValue: {
    fontSize: 70,
    textAlign: 'center',
  },
  exhibitionTitle: {
    fontSize: 30,
    paddingLeft: 20,
    paddingTop: 10,
  },
  exhibitionDate: {
    fontSize: 20,
    color: '#afb0b3',
    paddingLeft: 20,
  },
  artistTitle: {
    fontSize: 30,
    color: '#000',
  },
  artistTitleMobile: {
    fontSize: 20,
    color: '#000',
  },
  descriptionText: {
    fontSize: 20,
    color: '#afb0b3',
  },
  curatorialText: {
    fontSize: 20,
    color: '#000',
  },
  text: {
    fontSize: 16,
    overflowWrap: 'break-word',
  },
  textInBlack: {
    fontSize: 16,
    overflowWrap: 'break-word',
    color: 'black',
  },
  centerImage: {
    width: '100%',
    textAlign: 'center',
  },
  imageArtPoint: {
    width: 480,
  },
  mainImage: {
    maxHeight: 500,
    maxWidth: '100%',
  },
  image: {
    width: '100%',
  },
  imageCredit: {
    fontSize: 15,
    color: '#afb0b3',
    textAlign: 'center',
  },
  creditText: {
    textAlign: 'center',
    fontSize: 12,
    color: '#afb0b3',
  },
});

interface IState {}

type Props = IExhibitionArtPointProps & WithStyles<string> & WithWidth;

class ExhibitionArtPoint extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
  }

  public renderLinks = (links: ILink[]) => {
    const linksList = links.map((link, index) => {
      if (!link.link_url.includes('http')) {
        link.link_url = 'http://' + link.link_url;
      }
      const regexURLFormula =
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

      const onErrorEvt = () => {
        return (
          <Grid
            item={true}
            xs={12}
            key={`artpoint_link_${index}`}
            style={{ margin: '20px 0' }}
          >
            <Title>{link.link_title}</Title> <br />
            <a href={link.link_url} target="_blank">
              {' '}
              {link.link_url}
            </a>
            <br />
          </Grid>
        );
      };

      // Case the url is miss-speld
      if (!regexURLFormula.test(link.link_url)) {
        return (
          <Grid
            item={true}
            xs={12}
            key={`artpoint_link_${index}`}
            style={{ margin: '20px 0' }}
          >
            <Title>{link.link_title}</Title> <br />
            <a href={link.link_url} target="_blank">
              {' '}
              {link.link_url}
            </a>
            <br />
          </Grid>
        );
      }
      return (
        <Grid
          item={true}
          xs={12}
          key={`link_${index}`}
          style={{ margin: '20px 0' }}
        >
          <Title>{link.link_title}</Title>
          <br />
          <ReactTinyLink
            cardSize="small"
            showGraphic={true}
            maxLine={2}
            minLine={1}
            width={'100%'}
            url={link.link_url}
            onError={onErrorEvt}
          />
          <br />
        </Grid>
      );
    });

    const linksSection = (
      <Grid
        item={true}
        xs={12}
        key={'links_section'}
        style={{ margin: '20px 0' }}
      >
        {linksList}
      </Grid>
    );

    return linksSection;
  };

  public render() {
    const {
      onlineExhibitionData,
      orderValue,
      exhibitionPoint,
      classes,
      mobile,
    } = this.props;

    const artWork = exhibitionPoint.art_work;

    let mask = 0;

    if (onlineExhibitionData) {
      mask = onlineExhibitionData.start_date ? mask | 1 : mask;
      mask = onlineExhibitionData.end_date ? mask | 2 : mask;
      mask = onlineExhibitionData ? mask | 4 : mask;
    }

    if (!artWork) {
      return <></>;
    }

    const onBuyClick = () => {
      onBuyNftClick(artWork.nft_data);
    };

    return (
      <OnlineExhibitionLayout type={mobile ? 'ARTPOINTMOBILE' : 'ARTPOINT'}>
        <Grid container={true} className={classes.root}>
          {!mobile && (
            <Grid container={true} spacing={2}>
              <Grid item={true} sm={1}>
                <Title className={classes.orderValue}>{orderValue}</Title>
              </Grid>
              <Grid item={true} sm={11}>
                <Title className={classes.exhibitionTitle}>
                  {onlineExhibitionData.title}
                </Title>
                {(mask === 1 || mask === 5) && (
                  <>
                    <SubTitle className={classes.exhibitionDate}>
                      Start Date{' '}
                      {dateToStringParser(
                        onlineExhibitionData.start_date,
                        'MMM Do YYYY'
                      )}{' '}
                    </SubTitle>
                  </>
                )}
                {(mask === 2 || mask === 6) && (
                  <>
                    <SubTitle className={classes.exhibitionDate}>
                      End Date{' '}
                      {dateToStringParser(
                        onlineExhibitionData.end_date,
                        'MMM Do YYYY'
                      )}{' '}
                    </SubTitle>
                  </>
                )}
                {(mask === 3 || mask === 7) && (
                  <>
                    <SubTitle className={classes.exhibitionDate}>
                      {dateToStringParser(
                        onlineExhibitionData.start_date,
                        'MMM Do YYYY'
                      )}{' '}
                      -{' '}
                      {dateToStringParser(
                        onlineExhibitionData.end_date,
                        'MMM Do YYYY'
                      )}
                    </SubTitle>
                  </>
                )}
              </Grid>
            </Grid>
          )}
          {/*  ART_WORK IMAGE */}
          {getExhibitionPointType(exhibitionPoint) !== PointType.NFT &&
            artWork.imagesWithCredit &&
            artWork.imagesWithCredit[0] && (
              <Grid container={true} className={classes.containerPadding}>
                <Grid
                  item={true}
                  xs={12}
                  sm={12}
                  className={classes.centerImage}
                >
                  <img
                    src={getImageUrl(artWork.imagesWithCredit[0].image_url)}
                    className={classes.mainImage}
                  />
                </Grid>
                <Grid
                  item={true}
                  xs={12}
                  sm={12}
                  className={classes.imageCredit}
                >
                  <p className={classes.creditText}>
                    {artWork.imagesWithCredit[0].image_credit}
                  </p>
                </Grid>
              </Grid>
            )}
          {(!artWork.imagesWithCredit ||
            (artWork.imagesWithCredit &&
              artWork.imagesWithCredit.length === 0)) &&
            artWork.videos &&
            artWork.videos[0] && (
              <Grid container={true} className={classes.containerPadding}>
                <Grid
                  item={true}
                  xs={12}
                  key={`_videos_0`}
                  style={{ margin: '20px 0', textAlign: 'center' }}
                >
                  <div className="playerWrapper">
                    <ReactPlayer
                      className="reactPlayer"
                      url={artWork.videos[0].video_link}
                      playing={false}
                      width="100%"
                      controls={true}
                      vimeo={{ playerOptions: { height: '463px' } }}
                    />
                    <br />
                  </div>
                </Grid>
                <Grid
                  item={true}
                  xs={12}
                  sm={12}
                  className={classes.imageCredit}
                >
                  <p className={classes.creditText}>
                    {artWork.videos[0].video_title}
                  </p>
                </Grid>
              </Grid>
            )}

          {/* NFT IMAGE */}
          {getExhibitionPointType(exhibitionPoint) === PointType.NFT && (
            <Grid container={true} className={classes.containerPadding}>
              <Grid item={true} xs={12} sm={12} className={classes.centerImage}>
                <img
                  src={getImageUrl(artWork.nft_data!!.nft.image.src)}
                  className={classes.mainImage}
                />
              </Grid>
              <Grid item={true} xs={12} sm={12} className={classes.imageCredit}>
                <p className={classes.creditText}>
                  {artWork.nft_data!!.nft.image.label}
                </p>
              </Grid>
            </Grid>
          )}
          <Grid
            item={true}
            xs={12}
            md={
              getExhibitionPointType(exhibitionPoint) === PointType.NFT ? 8 : 12
            }
          >
            <Grid
              container={true}
              className={classes.containerPadding}
              style={{ paddingTop: 10 }}
            >
              <Grid item={true} xs={12}>
                {getExhibitionArtists(artWork).map((artist: any, i: any) => {
                  return (
                    <Text
                      className={`${
                        mobile ? classes.artistTitleMobile : classes.artistTitle
                      } `}
                      key={'_artist_' + i}
                    >
                      {artist.url ? (
                        <Link
                          to={`/users/${artist.url}/posts`}
                          className={classes.link}
                        >
                          {(i ? ', ' : '') + artist.name}
                        </Link>
                      ) : (
                        `${(i ? ', ' : '') + artist.name}`
                      )}
                    </Text>
                  );
                })}
                {artWork.nft_data && (
                  <Text
                    className={`${
                      mobile ? classes.artistTitleMobile : classes.artistTitle
                    } `}
                  >
                    <Link
                      to={`/users/${artWork.nft_data.nft.artist.url}/posts`}
                      className={classes.link}
                    >
                      {formatUserOrVenueData(artWork.nft_data.nft.artist).name}
                    </Link>
                  </Text>
                )}
              </Grid>
              <Grid item={true} xs={12}>
                <Text
                  className={
                    mobile ? classes.artistTitleMobile : classes.artistTitle
                  }
                >
                  {getExhibitionPointTitle(exhibitionPoint)}{' '}
                  {getExhibitionPointYear(exhibitionPoint)}
                </Text>
              </Grid>
            </Grid>
            <Grid container={true} className={classes.containerPadding}>
              <Grid item={true} xs={12}>
                <Text className={classes.descriptionText}>
                  {artWork.technic_used}
                </Text>
                <br />
                <Text className={classes.descriptionText}>
                  {artWork.dimensions}
                </Text>
                <br />
                <Text className={classes.descriptionText}>
                  {artWork.rarity}
                </Text>
                <br />
              </Grid>
            </Grid>

            <Grid container={true} className={classes.containerPadding}>
              <Grid item={true} xs={12}>
                <Title className={classes.curatorialText}>
                  {artWork.artwork_group}
                </Title>
              </Grid>
              <Grid item={true} xs={12}>
                <Title className={classes.curatorialText}>
                  {getArtworkDescriptionText(artWork)}
                </Title>
              </Grid>
            </Grid>

            <Grid container={true} className={classes.containerPadding}>
              <Grid
                item={true}
                xs={12}
                key={'videos_section'}
                style={{ margin: '20px 0' }}
              >
                {artWork.videos &&
                  artWork.videos.map((video, index) => {
                    if (
                      (!artWork.imagesWithCredit ||
                        (artWork.imagesWithCredit &&
                          artWork.imagesWithCredit.length === 0)) &&
                      index === 0
                    ) {
                      return;
                    }

                    return (
                      <>
                        <Grid
                          item={true}
                          xs={12}
                          key={`_videos_${index}`}
                          style={{ margin: '20px 0' }}
                        >
                          <div className="playerWrapper">
                            <ReactPlayer
                              className="reactPlayer"
                              url={video.video_link}
                              playing={false}
                              width="100%"
                              controls={true}
                              vimeo={{ playerOptions: { height: '463px' } }}
                            />
                            <br />
                          </div>
                        </Grid>
                        <Grid
                          item={true}
                          xs={12}
                          sm={12}
                          className={classes.imageCredit}
                        >
                          <p className={classes.creditText}>
                            {video.video_title}
                          </p>
                        </Grid>
                      </>
                    );
                  })}
              </Grid>
            </Grid>

            <Grid container={true} className={classes.containerPadding}>
              <Grid item={true} xs={12}>
                {artWork.links && this.renderLinks(artWork.links)}
              </Grid>
            </Grid>

            <Grid container={true} className={classes.containerPadding}>
              {artWork.imagesWithCredit &&
                artWork.imagesWithCredit.map((image, index) => {
                  if (index > 0) {
                    return (
                      <Grid
                        item={true}
                        xs={12}
                        key={'_image_' + image.id}
                        className={classes.centerImage}
                      >
                        <img
                          className={classes.image}
                          src={getImageUrl(image.image_url)}
                        />
                        <p className={classes.creditText}>
                          {image.image_credit}
                        </p>
                      </Grid>
                    );
                  }
                  return;
                })}
            </Grid>
          </Grid>
          {getExhibitionPointType(exhibitionPoint) === PointType.NFT && (
            <Grid item={true} xs={12} md={4}>
              <SquaredCardContainer bg={NEUTRAL[50]} noShadow={true}>
                <Grid container={true}>
                  <Grid item={true} xs={12} style={{ textAlign: 'center' }}>
                    <h3>NFT Artwork</h3>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Grid
                      container={true}
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Grid item={true}>
                        <p style={{ color: NEUTRAL[600] }}>PRICE</p>
                      </Grid>
                      <Grid item={true}>
                        <p style={{ color: NEUTRAL[600] }}>
                          {editionStr(artWork.nft_data!!.nft)}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <p>
                      <b>
                        {Number(artWork.nft_data!!.nft.price.value)}{' '}
                        {artWork.nft_data!!.nft.price.currency}
                      </b>
                    </p>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <StyledButton
                      variant={'contained'}
                      fullWidth={true}
                      bgColor={ButtonColors.GREEN}
                      onClick={onBuyClick}
                    >
                      Buy it now
                    </StyledButton>
                  </Grid>
                </Grid>
              </SquaredCardContainer>
            </Grid>
          )}
        </Grid>
      </OnlineExhibitionLayout>
    );
  }
}

export default compose<Props, IExhibitionArtPointProps>(
  withStyles(style),
  withAccount
)(ExhibitionArtPoint);
