import { isEmpty } from 'lodash';
import 'whatwg-fetch';
import AuthenticationService from './AuthenticationService';
import { environment } from '../environments/environment';
import { v1 as uuid } from 'uuid';
import { getBearerToken } from '@acg/auth/frontend';

const { REACT_APP_API_URL, REACT_APP_ARTPOOL_API_URL } = environment;

export type StatusCode = 'ok' | 'error';

interface IApiResponse {
  status: StatusCode;
}

export interface IDataResponse<T> extends IApiResponse {
  data: T;
}

export interface IDataListResponse<T> extends IApiResponse {
  data: T[];
  count: number;
  first_result?: number;
  max_results?: number;
  total?: number;
}

interface IApiOptions {
  adminApi?: object;
  withCredentials?: boolean;
  authenticated?: boolean;
  sessionToken?: string;
  backOffice?: boolean;
  artPool?: boolean;
}

type RequestMethod = 'get' | 'post' | 'put' | 'delete';

function paramsToQueryString(paramsArg: { [key: string]: any } = {}) {
  if (!paramsArg) {
    return '';
  }
  const paramsToArray: string[] = Object.keys(paramsArg);
  const str: string = paramsToArray
    .filter((key) => paramsArg[key] !== undefined)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(paramsArg[key])}`
    )
    .join('&');
  return str.length ? `?${str}` : '';
}

async function request(
  method: RequestMethod,
  endpoint: string,
  options: {
    params?: { [key: string]: any };
    body?: any;
    headers?: { [key: string]: any };
    localUrl?: boolean;
    adminApi?: object;
    withCredentials?: boolean;
    authenticated?: boolean;
    sessionToken?: string;
    backOffice?: boolean;
    artPool?: boolean;
  } = {}
) {
  const artcuratorgridApiUrl = `${REACT_APP_API_URL}/${
    options.backOffice ? 'back-office' : 'v1'
  }`;
  const baseUrl = options.artPool
    ? REACT_APP_ARTPOOL_API_URL
    : artcuratorgridApiUrl;

  const url = `${baseUrl}/${endpoint}${paramsToQueryString(options.params)}`;

  const requestHeaders = new Headers(options.headers || {});

  if (options.authenticated) {
    const bearer = await getBearerToken();
    if (!bearer) {
      throw new Error(
        `Error. Authenticated without token, endpoint:${endpoint}`
      );
    }
    requestHeaders.append('Authorization', `Bearer ${bearer}`);
  }

  if (options.sessionToken) {
    requestHeaders.append('SessionToken', `${options.sessionToken}`);
  }

  requestHeaders.append('credentials', 'same-origin');

  const config: RequestInit = {
    headers: requestHeaders,
    mode: 'cors',
    credentials: 'same-origin',
    method,
  };

  if (options.body instanceof FormData || options.body instanceof Blob) {
    config.body = options.body;
  } else if (options.body) {
    if (isEmpty(options.headers)) {
      // default headers: application/json
      requestHeaders.append('Accept', 'application/json');
      requestHeaders.append('Content-Type', 'application/json');
      config.body = JSON.stringify(options.body);
    } else {
      config.body = options.body;
    }
  }

  if (options.withCredentials) {
    config.credentials = 'include';
  }

  const checkAndParse = (response: Response) => {
    const contentType = response.headers.get('Content-Type');

    if (response.status === 401) {
      const event = new Event('unauthorizedEvent');
      document.dispatchEvent(event);
    }

    if (
      contentType &&
      (contentType.indexOf('image/png') !== -1 ||
        contentType.indexOf('application/octet-stream') !== -1)
    ) {
      return response.blob().then((blob) => {
        if (!response.ok) {
          Promise.reject(blob);
        }
        return blob;
      });
    } else if (contentType && contentType.indexOf('text/html') !== -1) {
      return response.status < 400
        ? Promise.resolve()
        : Promise.reject(response);
    }

    // Considered as a json response by default
    return response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }

      return json;
    });
  };

  return fetch(url, config) // eslint-disable-line no-undef
    .then(checkAndParse);
}

function getRequest<T>(
  endpoint: string,
  params: { [key: string]: any } = {},
  headers: { [key: string]: any } = {},
  options: IApiOptions = {}
): Promise<T> {
  // options.withCredentials = true

  try {
    options.sessionToken = new AuthenticationService().getSessionToken();
  } catch (e) {
    options.sessionToken = uuid();
  }

  return request('get', endpoint, {
    headers,
    params,
    ...options,
    authenticated:
      options.authenticated !== undefined ? options.authenticated : true,
  }) as Promise<T>;
}

function postRequest<T>(
  endpoint: string,
  body: any,
  params: { [key: string]: any } = {},
  headers: { [key: string]: any } = {},
  options: IApiOptions = {}
): Promise<T> {
  return request('post', endpoint, {
    body,
    headers,
    params,
    ...options,
    authenticated:
      options.authenticated !== undefined ? options.authenticated : true,
  }) as Promise<T>;
}

function putRequest<T>(
  endpoint: string,
  body: any,
  params: { [key: string]: any } = {},
  headers: { [key: string]: any } = {},
  options: IApiOptions = {}
): Promise<T> {
  options.sessionToken = new AuthenticationService().getSessionToken();

  return request('put', endpoint, {
    body,
    headers,
    params,
    ...options,
    authenticated:
      options.authenticated !== undefined ? options.authenticated : true,
  }) as Promise<T>;
}

function deleteRequest<T>(
  endpoint: string,
  params: { [key: string]: any } = {},
  headers: { [key: string]: any } = {},
  options: IApiOptions = {}
): Promise<T> {
  return request('delete', endpoint, {
    headers,
    params,
    ...options,
    authenticated:
      options.authenticated !== undefined ? options.authenticated : true,
  }) as Promise<T>;
}

export default {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  request,
};
