import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { Button, Grid, Modal, Tabs } from '@mui/material';
import { IWithAccountProps, withAccount } from '../../account/InjectAccount';
import {
  BREAKPOINT,
  GREY_COLOR,
  PRIMARY_COLOR,
  SPACING,
} from '../../../styles/constants';
import CreateOrEditInstitutionModal from './CreateOrEditInstitutionModal';
import InstitutionCard from '../../../components/backoffice/InstitutionCard';
import { IInstitution } from '../../../types/institution';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '../../../store';
import { useEffect, useState } from 'react';
import * as institutionActions from '../../../store/modules/institutions/actions';
import Tab from '@mui/material/Tab';

import PaddedLayout from '../../../library/layout/container/PaddedLayout';
import { ACGModal } from '../../../components/modal/ACGModal';

const style = createStyles({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  titleContainer: {
    padding: SPACING,
  },

  institutionSection: {
    // paddingTop: 20
  },

  button: {
    padding: 25,
    borderRadius: 100,
    marginBottom: 20,
  },
  buttonSmall: {
    padding: '7px 20px',
    minHeight: 'auto',
    borderRadius: 30,
    display: 'inline-block',
    width: 'auto',
    marginLeft: 5,
    fontSize: '0.6em',
    minWidth: 0,
  },
  closeButton: {
    position: 'absolute',
    right: '2px',
    top: '2px',
  },
  tabs: {
    letterSpacing: -0.12,
    textAlign: 'right',
    minWidth: 100,
    '& button': {
      minWidth: 100,
      fontSize: 14,
    },
  },
  indicator: {
    backgroundColor: PRIMARY_COLOR,
  },
  scrollButton: {
    width: 10,
  },

  infoNavBar: {
    // height: 60,
    width: '100%',
    marginBottom: 20,
    paddingBottom: 10,
    // position: 'fixed',
    // top: 80,
    backgroundColor: '#FFF',
    // zIndex: 100,
    alignItems: 'center',
  },

  infoNavBarMobile: {
    // height: 180,
    marginBottom: 20,
    paddingBottom: 10,
    // position: 'fixed',
    // top: 80,
    backgroundColor: '#FFF',
    // zIndex: 100
  },
});

/*
interface IState {
    openInstitution: boolean,
    modalChangeTypeOpen: boolean,
    institutions: IInstitution[],
    currentInstitution?: IInstitution
}
*/

type Props = WithStyles<string> & IWithAccountProps & WithWidth;

const InstitutionSection: React.FC<Props> = (props) => {
  const { institutions } = useSelector(
    (state: IStoreState) => state.institutions
  );
  const dispatch = useDispatch();

  const [openInstitution, setOpenInstitution] = useState(false);
  const [modalChangeTypeOpen, setModalChangeTypeOpen] = useState(false);
  const [currentInstitution, setCurrentInstitution] = useState<IInstitution>();

  const [tabSelected, setTabSelected] = useState('ALL');
  // Create new institution
  const handleInstitutionOpen = () => {
    setOpenInstitution(true);
  };
  const handleInstitutionClose = () => {
    setOpenInstitution(false);
  };

  // Edit institution
  const handleOpenChangeTypeModal = (institution: IInstitution) => {
    setModalChangeTypeOpen(true);
    setCurrentInstitution(institution);
  };
  const handleCloseChangeTypeModal = () => {
    setModalChangeTypeOpen(false);
    setCurrentInstitution(undefined);
  };

  const updateList = (institution?: IInstitution) => {
    setCurrentInstitution(institution);
    setModalChangeTypeOpen(false);
    setOpenInstitution(false);
    dispatch(institutionActions.fetchRequest(tabSelected));
  };

  const handleTabChange = (e: any, val: string) => {
    setTabSelected(val);
    dispatch(institutionActions.fetchRequest(val));
  };

  useEffect(() => {
    dispatch(institutionActions.fetchRequest());
  }, []);

  const { classes, width } = props;

  const isMobile = isWidthDown(BREAKPOINT, width);
  const isPad = isWidthDown('sm', width);

  return (
    <>
      <PaddedLayout>
        <div className={classes.institutionSection}>
          <Grid container={true} justifyContent="center">
            <Button
              size="large"
              variant="contained"
              component="label"
              style={{ backgroundColor: GREY_COLOR }}
              onClick={handleInstitutionOpen}
            >
              Create new venue
            </Button>
          </Grid>

          <Grid container={true} className={classes.root}>
            <Grid container={true} className={classes.titleContainer}>
              <h2 style={{ color: PRIMARY_COLOR }}>Venues List</h2>
            </Grid>

            <Grid
              container={true}
              className={
                !isMobile ? classes.infoNavBar : classes.infoNavBarMobile
              }
            >
              <Grid item={true} xs={12}>
                <Tabs
                  value={tabSelected}
                  classes={{
                    root: classes.tabs,
                    indicator: classes.indicator,
                    scrollButtons: classes.scrollButton,
                  }}
                  onChange={handleTabChange}
                  scrollButtons={true}
                  variant="scrollable"
                >
                  <Tab
                    label="All"
                    value={'ALL'}
                    key={'ALL'}
                    classes={{
                      root: `${classes.root} ${!isPad && classes.topBorder}`,
                    }}
                  />
                  <Tab
                    label="Institutions"
                    value={'INSTITUTION'}
                    key={'INSTITUTION'}
                    classes={{
                      root: `${classes.root} ${!isPad && classes.topBorder}`,
                    }}
                  />
                  <Tab
                    label="Art Galleries"
                    value={'ART_GALLERY'}
                    key={'ART_GALLERY'}
                    classes={{
                      root: `${classes.root} ${!isPad && classes.topBorder}`,
                    }}
                  />
                </Tabs>
              </Grid>
            </Grid>

            <Grid
              container={true}
              alignItems="center"
              className={
                !isMobile ? classes.infoNavBar : classes.infoNavBarMobile
              }
            >
              {institutions.length === 0 ? (
                <></>
              ) : (
                institutions.map((institution: any) => (
                  <InstitutionCard
                    key={`institutionCard_${institution.id}`}
                    institution={institution}
                    onOpenChangeTypeModal={handleOpenChangeTypeModal}
                  />
                ))
              )}
            </Grid>
          </Grid>
        </div>
      </PaddedLayout>
      <ACGModal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openInstitution}
        disableAutoFocus={true}
        onClose={handleInstitutionClose}
        BackdropProps={{
          className: classes.backdrop,
        }}
      >
        <CreateOrEditInstitutionModal
          institution={currentInstitution}
          onCreateOrUpdate={updateList}
          isBackOffice={true}
          onClickAway={handleInstitutionClose}
        />
      </ACGModal>

      <ACGModal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={modalChangeTypeOpen}
        disableAutoFocus={true}
        onClose={handleCloseChangeTypeModal}
        BackdropProps={{
          className: classes.backdrop,
        }}
      >
        <CreateOrEditInstitutionModal
          onCreateOrUpdate={updateList}
          institution={currentInstitution}
          isBackOffice={true}
          onClickAway={handleCloseChangeTypeModal}
        />
      </ACGModal>
    </>
  );
};

export default compose<Props, any>(
  withWidth(),
  withStyles(style),
  withAccount
)(InstitutionSection);
