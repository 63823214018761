import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import * as _ from 'lodash';
import { FormControlLabel } from '@mui/material';
import { FieldProps } from 'formik';
import { compose } from 'recompose';
import { SketchPicker } from 'react-color';
import TextField from '@mui/material/TextField/TextField';
import {
  DARK_GREY_COLOR,
  GREY_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
} from '../../styles/constants';

export interface IColorPickerInputProps extends FieldProps {
  label: string;
  initialColor: any;
  setColor: any;
  updatedColor?: () => void;
}
interface IState {
  color: any;
  colorHex: any;
  // colorHex: any;
  displayColorPicker: boolean;
}

type Props = WithStyles<string> & IColorPickerInputProps;

const style = createStyles({
  root: {},
  colorPickerClass: {
    paddingLeft: 15,
    fontSize: 12,
  },
  color: {
    width: 20,
    height: 20,
    borderRadius: 3,
  },
  swatch: {
    padding: 0,
    background: '#fff',
    borderRadius: 3,
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  popover: {
    position: 'absolute',
    zIndex: 2,
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
    height: 1700,
  },
});

class ColorPickerInput extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);

    const { initialColor, form, field } = this.props;

    let stateColor = initialColor ? initialColor : '#FFFFFFFF';
    if (stateColor.length === 7) {
      stateColor = stateColor + 'FF';
    }

    form.values[field.name] = stateColor;

    this.state = {
      displayColorPicker: false,
      color: this.hexToRgb(stateColor),
      colorHex: stateColor,
    };
  }

  public componentDidUpdate() {
    const { form, field, initialColor } = this.props;

    if (
      form.values[field.name] !== this.state.colorHex &&
      !this.state.displayColorPicker
    ) {
      // const colorToSet = _.get(form.values, field.name);
      // window.console.log('colorToSet ', colorToSet);
      this.setState({
        color: this.hexToRgb(form.values[field.name]),
        colorHex: form.values[field.name],
      });
    } else if (form.values[field.name] === '') {
      this.setState({
        color: this.hexToRgb(initialColor),
        colorHex: initialColor,
      });
    }

    /*
           if(form.values[field.name] === '' && initialColor !== this.state.color){
            this.handleChangeColor(initialColor)
        }
         */
  }

  public handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  public handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  public handleChange = (color: any) => {
    const { form, field, updatedColor } = this.props;

    let alpha = Math.round(color.rgb.a * 255).toString(16);
    if (alpha.length === 1) {
      alpha = 0 + alpha;
    }

    form.values[field.name] = color.hex + alpha;

    this.setState({ color: color.rgb, colorHex: color.hex + alpha });
    if (updatedColor !== undefined) {
      updatedColor();
    }
  };

  public handleChangeColor(newColor: string) {
    this.setState({ color: newColor });
  }

  public render() {
    const { form, field, label, classes } = this.props;

    return (
      <FormControlLabel
        classes={{ label: classes.colorPickerClass }}
        control={
          <>
            <TextField
              value={form.values[field.name]}
              name={field.name}
              style={{ display: 'none' }}
            />
            <div>
              <div className={classes.swatch} onClick={this.handleClick}>
                <div
                  className={classes.color}
                  style={{ backgroundColor: `${this.state.colorHex}` }}
                />
              </div>
              {this.state.displayColorPicker ? (
                <div className={classes.popover}>
                  <div className={classes.cover} onClick={this.handleClose} />
                  <SketchPicker
                    color={this.state.color}
                    onChange={this.handleChange}
                    disableAlpha={false}
                    presetColors={[
                      PRIMARY_COLOR,
                      'white',
                      GREY_COLOR,
                      DARK_GREY_COLOR,
                      SECONDARY_COLOR,
                      'black',
                    ]}
                  />
                </div>
              ) : null}
            </div>
          </>
        }
        label={label}
      />
    );
  }

  public hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
      hex
    );
    const alpha = result && result[4] ? result[4] : '255';
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          a: parseInt(alpha, 16) / 255,
        }
      : null;
  }
}

export default compose<Props, IColorPickerInputProps>(withStyles(style))(
  ColorPickerInput
);
