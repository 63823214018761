import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import { Grid } from '@mui/material';
import { compose } from 'recompose';
import {
  InjectedFormikProps,
  Formik,
  FormikProps,
  FormikHelpers,
  Field,
  FieldArray,
} from 'formik';
import TextInput from '../../components/form/TextInput';
import FileUpload from '../../components/form/MultipleFilesUpload';
import { IPost } from '../../types/posts';
import PostService from '../../services/PostService';
import {
  IValidationConfig,
  generateValidator,
  formatErrors,
} from '../../utils/formatters';
import FormMessage from '../../components/form/FormMessage';
import FormSubmit from '../../components/form/FormSubmit';
import FormCancel from '../../components/form/FormCancel';
import { withAccount, IWithAccountProps } from '../account/InjectAccount';
import LocationFinder from '../../components/form/LocationFinder';
import { ITag } from '../../types/tags';
import { ILink } from '../../types/links';
import { IVideoLink } from '../../types/videos';
import FormTagWrapper from '../tags/FormTagWrapper';
import LinkInput from '../../components/form/LinkInput';
import VideoLinkInput from '../../components/form/VideoLinkInput';
import { IImage } from '../../types/images';
import { PostTypeOptions } from '../../types/posts';
import SocialModal from '../social/SocialModal';
import FormArtistNameWrapper from '../../components/form/FormArtistNameWrapper';
import SelectInput from '../../components/form/SelectInput';
import { checkRight } from '../../utils/userUtils';
import FormUserNameWrapper from '../../components/form/FormUserNameWrapper';
import { ICuratorUser } from '../../types/user';
import ArtistEmailInput from '../../components/form/ArtistEmailInput';
import { IValidationError } from '../../types/returns';

export interface IArtProjectFormProps {
  onDone: () => void;
  onCreate: (post: IPost) => void;
  onUpdate: (post: IPost) => void;
  initialValues?: IArtProjectInitialValue;
  subType?: string;
}

const style = createStyles({
  root: {},
  headerSection: {
    fontSize: 24,
    textAlign: 'center',
    letterSpacing: -0.24,
  },
});

export interface IArtProjectInitialValue {
  description: string;
  title: string;
  curated_by?: any[];
  created_by?: any;
  type: 'ARTPROJECT';
  artists: any[];
  existing_artists: any[];
  end_date: string;
  start_date: string;
  images: string[];
  imagesWithCredit: IImage[];
  venue: {
    address: string;
    value: string;
    placeId: string;
    latLng: [0, 0];
  };
  venue_name: string;
  status: string;
  tags: ITag[];
  press_release: string;
  links: ILink[];
  videos: IVideoLink[];

  link_title?: string;
  link_url?: string;

  video_title?: string;
  video_url?: string;

  co_curator_list: string;
  other_contributors_list: string;
  sub_type: 'ARTPROJECT';

  venue_id?: any;
}

interface IState {
  error: boolean;
  imageError?: string;
  shareDialog: boolean;
  savedPost?: IPost;
}

type Props = WithStyles<string> &
  InjectedFormikProps<IArtProjectFormProps, IArtProjectInitialValue> &
  IWithAccountProps;

class ArtProjectForm extends React.Component<Props, IState> {
  private postService = new PostService();

  constructor(props: Props) {
    super(props);
    this.state = {
      error: false,
      shareDialog: false,
    };
  }

  public addLinksToPost = (
    typeArray: string,
    theArray: any,
    theUrl: string,
    theTitle?: string
  ) => {
    const regexURLFormula =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

    if (!regexURLFormula.test(theUrl)) {
      return false;
    } else {
      if (typeArray === 'links') {
        const linkList = {
          link_title: theTitle,
          link_url: theUrl,
        };

        theArray.push(linkList);
      } else {
        const linkList = {
          video_title: theTitle,
          video_link: theUrl,
        };

        theArray.push(linkList);
      }

      return true;
    }
  };

  public onSubmit = async (
    values: IArtProjectInitialValue,
    actions: FormikHelpers<IArtProjectInitialValue>
  ) => {
    this.setState({ error: false });
    const { account } = this.props;
    try {
      if (values.status === 'DRAFT') {
        if (values.description === '') {
          values.description = ' ';
        }
        if (values.title === '') {
          values.title = ' ';
        }
        if (values.venue_name === '') {
          values.venue_name = ' ';
        }
      } else {
        values.description = values.description.trim();
        values.title = values.title.trim();
        values.venue_name = values.venue_name.trim();
      }

      if (!moment(values.start_date).isValid()) {
        values.start_date = '';
      }
      if (!moment(values.end_date).isValid()) {
        values.end_date = '';
      }
      const [featuredImage] = values.imagesWithCredit;

      const artistList = _.uniqBy(values.artists, '_id');

      const coCuratorList = values.co_curator_list
        ? values.co_curator_list.split(',')
        : [];
      const otherContributorsList = values.other_contributors_list
        ? values.other_contributors_list.split(',')
        : [];
      const curatedByList = values.curated_by
        ? values.curated_by.map((curator: any) => {
            return curator._id;
          })
        : [];
      const payload: Partial<IPost> = {
        ...values,
        artists_list: artistList,
        curated_by: curatedByList,
        co_curator_list: coCuratorList,
        other_contributors_list: otherContributorsList,
        featured_image: featuredImage ? featuredImage.image_url : ' ',
        featured_imageWithCredit: featuredImage ? featuredImage : undefined,
        // images: rest,
        tags: values.tags.map((t) => t.id) as any,
      };

      const linkTitle = values.link_title;
      const linkUrl = values.link_url;
      if (linkUrl && linkUrl.length > 0) {
        this.addLinksToPost('links', values.links, linkUrl, linkTitle);
      }

      const videoTitle = values.video_title;
      const videoUrl = values.video_url;
      if (videoUrl && videoUrl.length > 0) {
        this.addLinksToPost('videos', values.videos, videoUrl, videoTitle);
      }

      if (payload.id) {
        // modify exhibition
        const modifiedExhibition = await this.postService.updatePost(
          payload.id,
          _.omit(payload, ['id'])
        );
        this.props.onUpdate(modifiedExhibition.data);
        // this.setState({shareDialog: true, savedPost: modifiedExhibition.data});
        this.props.onDone();
      } else {
        const createdExhibition = await this.postService.createPost(payload);
        this.props.onCreate(createdExhibition.data);
        this.setState({ shareDialog: true, savedPost: createdExhibition.data });

        // this.props.onDone();
      }
    } catch (err) {
      window.console.log(err);
      // handle err
      const formattedErrors = formatErrors(err as IValidationError);
      if (formattedErrors && formattedErrors.featured_image) {
        formattedErrors.images = 'Image must be set';
      }
      this.setState({
        error: formattedErrors ? false : true,
        imageError: formattedErrors ? formattedErrors.images : undefined,
      });
    }

    actions.setSubmitting(false);
  };

  public renderForm = (props: FormikProps<IArtProjectInitialValue>) => {
    const { onDone, classes, initialValues, account } = this.props;

    const right = checkRight(account);

    const getPostType = () => {
      const { subType } = this.props;

      const subTypeDetails = PostTypeOptions.find((obj) => {
        return obj.id === subType;
      });

      return subTypeDetails ? subTypeDetails.text : '';
    };

    const saveAsPublished = () => {
      props.setFieldValue('status', 'PUBLISHED');
    };

    const saveAsDraft = () => {
      props.setFieldValue('status', 'DRAFT');
    };

    const getSelectVenues = (managesObj: any) => {
      const venuesOption = [{ label: '', value: '' }];

      if (right === 'ALL') {
        venuesOption.push({ label: '', value: '' });
      }
      managesObj.forEach((manage: any) => {
        venuesOption.push({ label: manage.name, value: manage._id });
      });
      return venuesOption;
    };

    let curator: ICuratorUser | undefined;
    if (!initialValues && account.profile === 'CURATOR') {
      curator = account;
    } else if (
      initialValues &&
      initialValues.created_by &&
      initialValues.created_by.profile === 'CURATOR'
    ) {
      curator = initialValues.created_by;
    } else {
      curator = undefined;
    }

    return (
      <form onSubmit={props.handleSubmit}>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12} className={classes.headerSection}>
            {initialValues && <p>Update Your Art Project Post </p>}
            {!initialValues && <p>Create Your {getPostType()} Post</p>}
          </Grid>

          <FormMessage
            message={
              this.state.imageError
                ? this.state.imageError
                : 'There is an unexpected error, please try again.'
            }
            visible={!!(this.state.error || this.state.imageError)}
            type={'ERROR'}
          />

          {account.manages && account.manages.length > 0 && (
            <Grid item={true} xs={12}>
              <Field
                name="venue_id"
                component={SelectInput as React.ComponentType<any>}
                placeholder={"Share on your venue's profile page:"}
                inputLabelProps={{
                  children: "Share on your venue's profile page:",
                }}
                options={getSelectVenues(account.manages)}
              />
            </Grid>
          )}

          <Grid item={true} xs={12}>
            <Field
              name="title"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'title',
                label: 'Title',
                type: 'title',
              }}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              name="description"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'description',
                label: 'Curatorial text (please input your text in English)',
                type: 'description',
                multiline: true,
                rows: 5,
                maxRows: 20,
              }}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              name="tags"
              component={FormTagWrapper}
              inputProps={{
                id: 'tags',
                label: 'Tags (you can input one or several tags)',
                type: 'tags',
              }}
              helpText={
                'Please input any keywords describing this exhibition, such as the curatorial theme, the art movement, stylistic elements, etc…'
              }
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              name={'curated_by'}
              component={FormUserNameWrapper}
              defaultUser={curator}
              inputProps={{
                id: 'curatedBy',
                label: 'Curated by',
                type: 'curators',
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              name="co_curator_list"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'co_curator_list',
                label:
                  'Co-curators who are not on our platform (please separate the names with a comma)',
                type: 'co_curator_list',
              }}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              name="artists"
              component={FormArtistNameWrapper}
              inputProps={{
                id: 'artists',
                label: 'Artist Name',
                type: 'artists',
              }}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              name="venue_name"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'venue_name',
                label: 'Venue Name',
                type: 'venue_name',
              }}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              name="venue"
              component={LocationFinder}
              naked={true}
              newLocationFinder={true}
              inputProps={{
                id: 'venue',
                label:
                  'Venue Location (please write the venue name as it appears on Google Maps)',
                type: 'venue',
              }}
            />
          </Grid>

          <Grid item={true} xs={12} sm={6}>
            <Field
              name="start_date"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'start_date',
                label: 'Start Date',
                type: 'date',
              }}
            />
          </Grid>

          <Grid item={true} xs={12} sm={6}>
            <Field
              name="end_date"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'end_date',
                label: 'End Date',
                type: 'date',
              }}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              name="press_release"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'press_release',
                label: 'Press Release Text (please input your text in English)',
                type: 'press_release',
                multiline: true,
                rows: 5,
                maxRows: 20,
              }}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              name="other_contributors_list"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'other_contributors_list',
                label:
                  'Other contributors (please separate contributors with a comma)',
                type: 'other_contributors_list',
              }}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <FieldArray
              name="links"
              component={LinkInput as React.ComponentType<any>}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <FieldArray
              name="videos"
              component={VideoLinkInput as React.ComponentType<any>}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <FieldArray
              name="imagesWithCredit"
              component={FileUpload as React.ComponentType<any>}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              name="email"
              component={ArtistEmailInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'email',
                label: 'email',
                type: 'email',
              }}
            />
          </Grid>
          <Field
            id="status"
            name="status"
            type="hidden"
            inputProps={{
              id: 'status',
              type: 'status',
            }}
          />
        </Grid>
        <Grid container={true} spacing={1}>
          <Grid xs={3} item={true}>
            <FormCancel onClick={onDone} newButton={true} variant="contained">
              Cancel
            </FormCancel>
          </Grid>
          <Grid xs={3} item={true} />
          <Grid xs={3} item={true}>
            <FormSubmit
              disabled={props.isSubmitting}
              onClick={saveAsDraft}
              newButton={true}
              outlined={true}
              variant="contained"
              style={{ paddingRight: '3px' }}
            >
              {this.props.subType
                ? 'SAVE AS DRAFT'
                : props.values.status === 'DRAFT'
                ? 'SAVE AS DRAFT'
                : 'SWITCH TO DRAFT'}
            </FormSubmit>
          </Grid>
          <Grid xs={3} item={true}>
            <FormSubmit
              disabled={props.isSubmitting}
              draft={false}
              newButton={true}
              onClick={saveAsPublished}
              variant="contained"
            >
              {this.props.subType
                ? 'POST NOW!'
                : props.values.status === 'DRAFT'
                ? 'POST NOW!'
                : 'UPDATE'}
            </FormSubmit>
          </Grid>
        </Grid>
      </form>
    );
  };

  public render() {
    const { initialValues: propsInitialValues, account } = this.props;

    let venueId;
    if (account.manages && account.manages.length > 0) {
      venueId = _.find(account.manages, (ele: any) => {
        if (location.pathname.indexOf(ele.url) > 0) {
          return ele._id;
        }
      });
    }

    const initialValues: IArtProjectInitialValue = {
      description: '',
      title: '',
      type: 'ARTPROJECT',
      artists: [],
      existing_artists: [],
      // end_date: moment().format('YYYY-MM-DD'),
      // start_date: moment().format('YYYY-MM-DD'),
      end_date: '',
      start_date: '',
      images: [],
      imagesWithCredit: [],

      venue: {
        address: '',
        value: '',
        placeId: '',
        latLng: [0, 0],
      },
      venue_name: '',
      status: 'PUBLISHED',
      tags: [],
      press_release: '',
      links: [],
      videos: [],

      link_url: '',
      link_title: '',

      co_curator_list: '',
      other_contributors_list: '',
      sub_type: 'ARTPROJECT',
      venue_id: venueId ? venueId._id : '',
    };

    const validationArtProjectRule: IValidationConfig = {
      title: ['REQUIRED'],
      description: ['REQUIRED'],
    };

    const validation = (values: IArtProjectInitialValue) => {
      if (values.status === 'DRAFT') {
        return;
      } else {
        values.description = values.description.trim();
        values.title = values.title.trim();
        values.venue_name = values.venue_name.trim();
      }

      return generateValidator(validationArtProjectRule)(values);
    };

    if (
      propsInitialValues &&
      propsInitialValues.imagesWithCredit.length === 1 &&
      propsInitialValues.imagesWithCredit[0] &&
      !propsInitialValues.imagesWithCredit[0].image_url
    ) {
      propsInitialValues.imagesWithCredit = [];
    }

    const onShareClose = () => {
      this.setState({ shareDialog: false, savedPost: undefined });
      this.props.onDone();
    };

    if (propsInitialValues && propsInitialValues.existing_artists) {
      const artists = [
        ...propsInitialValues.existing_artists,
        ...propsInitialValues.artists,
      ];
      propsInitialValues.artists = artists;
    }

    return (
      <React.Fragment>
        <Formik
          initialValues={
            propsInitialValues ? propsInitialValues : initialValues
          }
          onSubmit={this.onSubmit}
          render={this.renderForm}
          validate={validation}
        />
        {this.state.savedPost && (
          <SocialModal
            open={this.state.shareDialog}
            onClose={onShareClose}
            object={{
              type: 'POST',
              object: this.state.savedPost,
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default compose<Props, IArtProjectFormProps>(
  withStyles(style),
  withAccount
)(ArtProjectForm);
