import * as React from 'react';
import { ButtonColors, StyledButton } from '@acg/frontend-ui-artpool';

const EditExhibitionButton = () => {
  return (
    <StyledButton
      bgColor={ButtonColors.GREEN}
      size={'small'}
      variant={'contained'}
    >
      Edit Exhibition
    </StyledButton>
  );
};

export default EditExhibitionButton;
