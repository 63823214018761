import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import * as _ from 'lodash';
import UploadService from '../../services/UploadService';
import { Field, FieldProps, getIn } from 'formik';
import { Grid, Button, CircularProgress, Hidden } from '@mui/material';
import { compose } from 'recompose';
import { GREY_COLOR } from '../../styles/constants';
import { getImageUrl } from '../../utils/imageUrl';
import TextInput from './TextInput';
import Rotate from '../icons/Rotate';
import IconUpload from '../../library/dataDisplay/icons/iconUpload';

export interface ISingleFileUploaderProps extends FieldProps {
  label?: string;
}

const style = createStyles({
  container: {
    paddingTop: '20px',
  },
  root: {
    position: 'relative',
    '&:hover overlay': {
      backgroundColor: 'black',
      opacity: 1,
    },
  },
  overlay: {
    textAlign: 'center',
    marginLeft: 10,
  },
  button: {
    backgroundColor: GREY_COLOR,
    border: '1px dashed rgba(43, 46, 52, 0.5)',
    padding: '8px 0',
    boxShadow: 'none',
    textTransform: 'none',
  },
  buttonWithError: {
    color: 'red',
  },
  errorMessage: {
    color: '#f44336',
    marginLeft: 14,
    marginRight: 14,
    fontSize: '0.75rem',
    marginTop: 3,
    textAlign: 'left',
    fontFamily: 'Replica-Regular',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  imageRow: {
    width: '100%',
    display: 'flex',
    alignContent: 'center',
  },

  paddingDown: {
    paddingDown: '10px !important',
  },
  textButton: {
    fontSize: 12,
    marginBottom: 10,
  },
  header: {
    marginBottom: 12,
  },
  headerText: {
    fontSize: 12,
    color: '#797979',
    marginBottom: 12,
  },
  arrows: {
    cursor: 'pointer',
  },
});

type Props = ISingleFileUploaderProps & WithStyles<string>;

const SingleFileUploader: React.FC<Props> = (props) => {
  const {
    form: { errors, values },
    field: { name },
    classes,
    label,
  } = props;

  const uploadService = new UploadService();

  const [uploading, setUploading] = React.useState<boolean>(false);
  const [rotating, setRotating] = React.useState<boolean>(false);

  const submitFile = (event: any) => {
    event.preventDefault();

    const fileList: File[] = [];

    for (const f of event.target.files) {
      fileList.push(f);
    }
    fileList.forEach((file) => {
      const formData = new FormData();
      formData.append('file', file);
      setUploading(true);

      uploadService
        .uploadFile(formData)
        .then((response) => {
          values[name] = {
            image_url: `/${response.data.Key}`,
            image_credit: '',
          };
          setUploading(false);
        })
        .catch((errorResp) => {
          setUploading(false);
          window.console.log('errorSubmit ', errorResp);
        });
    });
  };

  const handleClickRotate = () => {
    setRotating(true);
    uploadService
      .rotateFile(fileUpload.image_url)
      .then((response) => {
        let fileUploadValue = values[name];

        if (!fileUploadValue) {
          fileUploadValue = _.get(values, name);
        }

        fileUploadValue.image_url = response.data[0].body.updateFileName;
        setRotating(false);
      })
      .catch((err) => {
        window.console.log('ERROR ROTATING', err);
      });
  };

  const error = getIn(errors, name);
  // const touch = getIn(touched, name);

  let fileUpload = values[name];

  if (!fileUpload) {
    fileUpload = _.get(values, name);
  }

  return (
    <React.Fragment>
      <div className={classes.container}>
        <Grid container={true}>
          {fileUpload && (
            <Grid item={true} xs={12} className={classes.root}>
              <Grid
                container={true}
                className={classes.imageRow}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item={true} xs={12} sm={3}>
                  {rotating ? (
                    <CircularProgress />
                  ) : (
                    <React.Fragment>
                      <img
                        style={{ maxWidth: '100%' }}
                        src={getImageUrl(fileUpload.image_url)}
                      />
                    </React.Fragment>
                  )}
                </Grid>
                <Grid item={true} xs={2} sm={1} className={classes.overlay}>
                  <Grid
                    container={true}
                    direction={'column'}
                    // xs={12}
                  >
                    <Grid
                      item={true}
                      xs={12}
                      className={classes.header}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <span onClick={handleClickRotate}>
                        <Rotate big={true} />
                      </span>
                      <span className={classes.textButton}>Rotate</span>
                    </Grid>
                  </Grid>

                  {/* <span onClick={onClick} className={classes.overlay}><Rotate big={true} /></span> */}
                </Grid>
                <Hidden only={'xs'}>
                  <Grid item={true} xs={false} sm={6}>
                    <Field
                      name={`${name}.image_credit`}
                      value={`${name}.image_credit`}
                      component={TextInput}
                      newTextInput={true}
                      naked={true}
                      inputProps={{
                        id: `${name}.image_credit`,
                        label: 'Photo credit*',
                        type: 'image_credit',
                      }}
                      className={classes.paddingDown}
                    />
                  </Grid>
                </Hidden>
              </Grid>
              <Hidden smUp={true}>
                <Grid
                  container={true}
                  // xs={12}
                  // sm={false}
                  className={classes.ShowMobileRow}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item={true} xs={12}>
                    <Field
                      name={`${name}.image_credit`}
                      value={`${name}.image_credit`}
                      component={TextInput}
                      inputProps={{
                        id: `${name}.image_credit`,
                        label: 'Photo credit*',
                        type: 'image_credit',
                      }}
                    />
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
          )}
        </Grid>

        <Button
          variant="contained"
          component="label"
          fullWidth={true}
          className={`${classes.button} ${error && classes.buttonWithError}`}
        >
          {uploading ? (
            <CircularProgress />
          ) : (
            <React.Fragment>
              <IconUpload />
              {label ? label : 'Upload your images'}
              <input
                id={name}
                name={name}
                type="file"
                multiple={false}
                onChange={submitFile}
                style={{ display: 'none' }}
                accept="image/*"
              />
            </React.Fragment>
          )}
        </Button>

        {error && (
          <p key={name + '-helper-text'} className={classes.errorMessage}>
            {/* error */}
            Required
          </p>
        )}
        {/* error && touch && typeof errors[name] === 'string' ? <div>{error}</div> : undefined */}
      </div>
    </React.Fragment>
  );
};

export default compose<Props, ISingleFileUploaderProps>(withStyles(style))(
  SingleFileUploader
);
