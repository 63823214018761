import {
  default as withWidth,
  isWidthDown,
  WithWidth,
} from '@mui/material/Hidden/withWidth';
import * as React from 'react';
import { compose } from 'recompose';
import { ClickAwayListener, Theme, Tooltip } from '@mui/material';
import { PRIMARY_COLOR } from '../../../styles/constants';
import withStyles from '@mui/styles/withStyles/withStyles';

export interface IInfoSquareTooltipProps {
  helpText: any;
  size?: number;
  font?: number;
}

type Props = IInfoSquareTooltipProps & WithWidth;

function InfoSquareTooltip(props: Props) {
  const { helpText, width } = props;

  const [open, setOpen] = React.useState(false);

  const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  const iconStyles: React.CSSProperties = {
    backgroundColor: PRIMARY_COLOR,
    borderColor: PRIMARY_COLOR,
    borderRadius: 8,
    padding: 2,
    cursor: 'pointer',
    width: props.size || 30,
    height: props.size || 30,
    lineHeight: '20px',
    textAlign: 'center',
    fontWeight: 500,
    fontSize: props.font || 18,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const isPad = isWidthDown('sm', width);

  // @ts-ignore
  return isPad ? (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div style={{ display: 'inherit' }}>
        <HtmlTooltip
          onClose={handleTooltipClose}
          title={<React.Fragment>{helpText}</React.Fragment>}
          open={open}
          disableFocusListener={true}
          disableHoverListener={true}
          disableTouchListener={true}
        >
          <div onClick={handleTooltipOpen} style={iconStyles}>
            ?
          </div>
        </HtmlTooltip>
      </div>
    </ClickAwayListener>
  ) : (
    <HtmlTooltip
      onClose={handleTooltipClose}
      title={<React.Fragment>{helpText}</React.Fragment>}
    >
      <div onClick={handleTooltipOpen} style={iconStyles}>
        ?
      </div>
    </HtmlTooltip>
  );
}

export default compose<Props, IInfoSquareTooltipProps>(withWidth())(
  InfoSquareTooltip
);
