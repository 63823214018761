import './linkedin-icon.module.scss';
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

// tslint:disable-next-line:no-empty-interface
export interface LinkedinIconProps {}

type Props = LinkedinIconProps & SvgIconProps;

export function LinkedinIcon(props: Props) {
  return (
    <SvgIcon {...props} viewBox={'0 0 25 24'}>
      <path d="M5.73921 23.4706V7.99231H0.594548V23.4706H5.73921ZM3.16755 5.87774C4.96158 5.87774 6.07828 4.68919 6.07828 3.20389C6.04486 1.6851 4.96165 0.529541 3.20159 0.529541C1.44181 0.529541 0.291016 1.68513 0.291016 3.20389C0.291016 4.68926 1.40744 5.87774 3.13396 5.87774H3.16739H3.16755ZM8.58677 23.4706H13.7314V14.8268C13.7314 14.3642 13.7649 13.902 13.9007 13.5713C14.2726 12.6471 15.1191 11.6898 16.5403 11.6898C18.402 11.6898 19.1467 13.1092 19.1467 15.1899V23.4705H24.2911V14.5954C24.2911 9.84106 21.753 7.62892 18.3681 7.62892C15.5927 7.62892 14.3742 9.18024 13.6972 10.2368H13.7316V7.99199H8.58689C8.6544 9.44438 8.58689 23.4703 8.58689 23.4703L8.58677 23.4706Z" />
    </SvgIcon>
  );
}

export default LinkedinIcon;
