import { AUTH_STEPS } from './auth-body';
import { Tabs as MuiTabs, Tab as MuiTab } from '@mui/material';

export const Tabs = MuiTabs;

export const Tab = MuiTab;

export const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export const a11yProps = (index: AUTH_STEPS) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
};
