import styles from '../auth.module.scss';
import { FormControl, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import { useFormikMui, useTimeout } from '@acg/frontend-utils';
import {
  confirmSignUp,
  hideAuthUI,
  sendSignUpCode,
  signIn,
} from '@acg/auth/frontend';
import FormMessage from '../../inputs/form-message/form-message';
import { AUTH_STEPS, IFormProps } from '../components/auth-body';
import { useState } from 'react';

const TIMEOUT_MS = 1000 * 60 * 5;

export interface FormValues {
  code: string;
}

interface ErrorValues {
  serverError?: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  code: Yup.string().required('Code is required'),
});

export const AuthConfirmSignupForm = ({
  user,
  setUser,
  setAuthStep,
  setTimeOutError,
}: IFormProps) => {
  const { username, password } = user;
  const [isResendingCode, setIsResendingCode] = useState(false);
  const dispatch = useDispatch();

  const resetFlow = () => {
    if (!password) {
      return;
    }

    setUser({ username: '', password: '' });
    setTimeOutError(
      'It took you too long to complete the sign up process. Try to log in to get a new code.'
    );
    setAuthStep(AUTH_STEPS.SIGN_IN);
  };

  useTimeout(resetFlow, TIMEOUT_MS);

  const { formik, formikProps } = useFormikMui<FormValues & ErrorValues>({
    initialValues: {
      code: '',
    },
    initialErrors: {
      serverError: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => submitConfirmSignUp(values),
  });

  const submitConfirmSignUp = async (values: FormValues) => {
    try {
      await confirmSignUp(username, values.code);

      if (!!password) {
        await logUserIn();
        return;
      }
      setAuthStep(AUTH_STEPS.SIGN_IN);
    } catch (error: any) {
      formik.setFieldError('serverError', error.message);
    }
  };

  const logUserIn = async () => {
    try {
      await signIn(username, password);
      dispatch(hideAuthUI());
    } catch (error: any) {
      formik.setFieldError('serverError', error.message);
    }
  };

  const resendConfirmationCode = async () => {
    try {
      setIsResendingCode(true);
      await sendSignUpCode(username);
    } catch (error: any) {
      formik.setFieldError('serverError', error.message);
    } finally {
      setIsResendingCode(false);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <p className={styles.title}>We Emailed You</p>
        </Grid>
        <Grid item={true} xs={12}>
          <p className={styles.description}>
            {`Your code is on the way. To log in, enter the code we emailed to ${username}. It may take a minute to arrive.`}
          </p>
        </Grid>
        <Grid item={true} xs={12}>
          <FormControl fullWidth>
            <TextField
              {...formikProps('code')}
              label="Code"
              placeholder="Enter your code"
              color={'secondary'}
            />
          </FormControl>
        </Grid>
        <FormMessage
          visible={!!formik.errors.serverError}
          message={formik.errors.serverError}
        />
        <Grid item={true} xs={12}>
          <button
            className={styles.buttonPrimary}
            type="submit"
            disabled={formik.isSubmitting || isResendingCode}
          >
            {formik.isSubmitting ? 'Confirming...' : 'Confirm'}
          </button>
        </Grid>
        <Grid item={true} xs={12}>
          <button
            onClick={resendConfirmationCode}
            className={styles.buttonSecondary}
            type="button"
            disabled={formik.isSubmitting || isResendingCode}
          >
            {isResendingCode ? 'Resending' : 'Resend code'}
          </button>
        </Grid>
      </Grid>
    </form>
  );
};
