import moment from 'moment';

export const dateToStringParser = (
  dateTime?: string,
  formatString?: string
) => {
  const momentUtcOffset = -(moment().utcOffset() - 120); // This way makes the Lisbon time as default

  return moment(dateTime).utcOffset(momentUtcOffset).format(formatString);
};

export const dateToLocalStringParser = (
  dateTime?: string,
  formatString?: string
) => {
  const testDateUtc = moment.utc(dateTime);
  const localDate = moment(testDateUtc).local();

  return localDate.format(formatString);
};
