import * as React from 'react';
import { ButtonProps } from '@mui/material/Button';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import { IInstitution } from '../../types/institution';
import InstitutionService from '../../services/InstitutionService';
import { compose } from 'recompose';
import { StyledButton } from '@acg/frontend-ui-artpool';
import { useMediaQuery, useTheme } from '@mui/material';

export interface IFollowInstitutionProps {
  institution: IInstitution;
  callback: (institution?: IInstitution) => void;
  isFollowing: boolean;
  buttonSize?: any;
}

type Props = IWithAccountProps & IFollowInstitutionProps;

const institutionService = new InstitutionService();

const FollowInstitution = (props: Props) => {
  const { institution, callback, account, isFollowing, buttonSize } = props;

  const theme = useTheme();
  const isPad = useMediaQuery(theme.breakpoints.down('md'));

  const onFollow = async () => {
    if (!account) {
      return callback();
    }

    await institutionService
      .followUnfollowInstitution(institution.url)
      .then(() => callback(institution))
      .catch(() => callback());
  };

  const buttonProps: ButtonProps = {
    variant: isFollowing ? 'contained' : 'outlined',
  };

  return (
    <StyledButton
      size={buttonSize || 'large'}
      {...buttonProps}
      onClick={onFollow}
      fullWidth
      style={{ marginBottom: isPad ? 5 : 0 }}
    >
      {isFollowing ? 'Following' : 'Follow'}
    </StyledButton>
  );
};

export default compose<Props, IFollowInstitutionProps>(withAccount)(
  FollowInstitution
);
