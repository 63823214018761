import * as React from 'react';
import { GREY_COLOR } from '../../styles/constants';
import { IUser } from '../../types/user';
import Instagram from '../icons/social/standard/Instagram';
import Linkedin from '../icons/social/standard/Linkedin';
import Twitter from '../icons/social/circle/Twitter';
import Facebook from '../icons/social/circle/Facebook';
import Website from '../icons/Website';
import { IInstitution } from '../../types/institution';
import { generateAddressLocation } from '../../utils/locationHelper';

export interface IBiographyProps {
  user?: IUser | IInstitution;
  height?: string | number;
  background?: string;
  socialFontSize?: number;
  showAddress?: boolean;
  children: React.ReactNode;
}

interface IState {
  height?: string | number;
  el?: HTMLDivElement;
  overflow: boolean;
}

const HEIGHT = 90;

export default class Biography extends React.Component<
  IBiographyProps,
  IState
> {
  constructor(props: IBiographyProps) {
    super(props);
    this.state = {
      height: this.props.height ? this.props.height : HEIGHT,
      overflow: false,
    };
  }

  public checkOverflow = (el?: HTMLDivElement) => {
    if (!el) {
      return false;
    }
    const curOverflow = el.style.overflow;

    if (!curOverflow || curOverflow === 'visible') {
      el.style.overflow = 'hidden';
    }
    const isOverflowing =
      el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

    el.style.overflow = curOverflow;

    return isOverflowing;
  };

  public render() {
    const { children, user, showAddress } = this.props;

    const style: React.CSSProperties = {
      maxHeight: this.state.height,
      overflow: 'hidden',
      display: 'flex',
      transition: '0.5s height',
    };

    const onClick = () => {
      this.setState({
        height:
          this.state.height === (this.props.height ? this.props.height : HEIGHT)
            ? undefined
            : this.props.height
            ? this.props.height
            : HEIGHT,
      });
    };

    const setRef = (r: HTMLDivElement) =>
      !this.state.el
        ? this.setState({
            el: r,
            overflow: this.checkOverflow(r),
          })
        : undefined;

    if (this.checkOverflow(this.state.el) && !this.state.overflow) {
      this.setState({ overflow: true });
    }

    const buttonStyle: React.CSSProperties = {
      height: 17,
      lineHeight: '17px',
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      background: this.state.height
        ? this.props.background || `${GREY_COLOR}`
        : 'none',
    };

    const elswhereStyle: React.CSSProperties = {
      fontSize: this.props.socialFontSize || 12,
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.25,
      letterSpacing: 'normal',
      color: '#afb0b3',
      fontFamily: 'Replica-Regular',
      whiteSpace: 'pre-wrap',
      marginTop: 10,
    };

    const addressStyle: React.CSSProperties = {
      fontSize: this.props.socialFontSize || 12,
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.25,
      letterSpacing: 'normal',
      color: '#afb0b3',
      fontFamily: 'Replica-Regular',
      whiteSpace: 'pre-wrap',
      marginTop: 10,
    };

    const icon: React.CSSProperties = {
      color: '#afb0b3',
      marginLeft: 8,
      textDecoration: 'none',
    };

    const elsewhere = [];

    if (user) {
      if (user.website_url) {
        elsewhere.push(
          <a
            style={icon}
            href={user.website_url}
            target="_blank"
            key={'user.website_url'}
          >
            <Website fontSize={16} />
          </a>
        );
      }
      if (user.instagram_url) {
        if (
          user.instagram_url.toLocaleLowerCase().indexOf('instagram.com') === -1
        ) {
          user.instagram_url = `https://instagram.com/${user.instagram_url}`;
        }
        elsewhere.push(
          <a
            style={icon}
            href={user.instagram_url}
            target="_blank"
            key={'user.instagram_url'}
          >
            <Instagram type={'GRAY'} fontSize={16} />
          </a>
        );
      }
      if (user.facebook_url) {
        if (
          user.facebook_url.toLocaleLowerCase().indexOf('facebook.com') === -1
        ) {
          user.facebook_url = `https://facebook.com/${user.facebook_url}`;
        }
        elsewhere.push(
          <a
            style={icon}
            href={user.facebook_url}
            target="_blank"
            key={'user.facebook_url'}
          >
            <Facebook type={'GRAY'} fontSize={16} />
          </a>
        );
      }
      if (user.twitter_url) {
        if (
          user.twitter_url.toLocaleLowerCase().indexOf('twitter.com') === -1
        ) {
          user.twitter_url = `https://twitter.com/${user.twitter_url}`;
        }
        elsewhere.push(
          <a
            style={icon}
            href={user.twitter_url}
            target="_blank"
            key={'user.twitter_url'}
          >
            <Twitter type={'GRAY'} fontSize={16} />
          </a>
        );
      }
      if (user.linkedin_url) {
        if (
          user.linkedin_url.toLocaleLowerCase().indexOf('linkedin.com') === -1
        ) {
          user.linkedin_url = `https://linkedin.com/in/${user.linkedin_url}`;
        }
        elsewhere.push(
          <a
            style={icon}
            href={user.linkedin_url}
            target="_blank"
            key={'user.linkedin_url'}
          >
            <Linkedin type={'GRAY'} fontSize={16} />
          </a>
        );
      }
    }

    const address = [];
    if (user && showAddress) {
      if (user.location.placeId) {
        address.push(
          <a
            style={icon}
            href={`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${user.location.placeId}`}
            target={'_blank'}
          >
            {generateAddressLocation(user.location)}
          </a>
        );
      } else {
        address.push(<p>{generateAddressLocation(user.location)}</p>);
      }
    }

    return children ? (
      <div style={style}>
        <div
          style={{ padding: '0 0 10px 0', position: 'relative' }}
          ref={setRef}
        >
          {children}

          {elsewhere.length > 0 ? (
            <div style={elswhereStyle}>Elsewhere: {elsewhere}</div>
          ) : null}
          {address.length > 0 ? (
            <div style={addressStyle}>Address: {address}</div>
          ) : null}
          {this.state.overflow ? (
            <div style={buttonStyle}>
              {this.state.overflow ? (
                <span
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    float: 'right',
                    fontSize: 12,
                  }}
                  onClick={onClick}
                >
                  {this.state.height ? 'see more' : 'see less'}
                </span>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    ) : (
      <div />
    );
  }
}
