import { ICuratorUser, IUser, IViewerUser } from '../types/user';
import ApiServices, { IDataResponse } from './ApiServices';
import { ITag } from '../types/tags';

export interface IRegisterUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  profilePicture: string;
  city: string;
  bio: string;
  password?: string;
  confirmPassword?: string;
  company_type?: 'INDEPENDANT' | 'INSTITUTION';
  company_name?: string;
  show?: boolean;
  location: {
    address: string;
    value: string;
    placeId: string;
    latLng: [number, number];
  };
  instagram_url: string;
  linkedin_url: string;
  website_url: string;
  twitter_url: string;
  facebook_url: string;
  url: string;
  newsletter_network: boolean;
  newsletter_curtain: boolean;
  profile: 'CURATOR' | 'VIEWER' | 'ARTIST';
  profile_type: 'PUBLIC' | 'PRIVATE';
  isExhibitionAdmin: boolean;
}

export interface IRegisterInstitutionRequest {
  id?: string;
  name: string;
  email: string;
  profilePicture: string;
  featuredPicture: string;
  about: string;
  city: string;
  location: {
    address: string;
    value: string;
    placeId: string;
    latLng: [number, number];
    city: string;
    state: string;
    country: string;
  };
  instagram_url: string;
  linkedin_url: string;
  website_url: string;
  twitter_url: string;
  facebook_url: string;
  url: string;
  institution_profile_type: 'PUBLIC' | 'PRIVATE';
  institution_type?:
    | 'MUSEUM'
    | 'ART_FOUNDATION'
    | 'ART_CENTER'
    | 'RESIDENCY'
    | 'CULTURAL_ASSOCIATION';

  tags: ITag[];
  managedBy?: [IUser];
}

class BackOfficeServices {
  public getPendingUsers = (): Promise<IDataResponse<ICuratorUser[]>> => {
    return ApiServices.getRequest(`pending`, {}, {}, { backOffice: true });
  };
  public getPendingArtists = (): Promise<IDataResponse<ICuratorUser[]>> => {
    return ApiServices.getRequest(
      `pending_artist`,
      {},
      {},
      { backOffice: true }
    );
  };

  public approveUser = (
    id: string,
    profile?: string
  ): Promise<IDataResponse<any>> => {
    if (profile) {
      return ApiServices.putRequest(
        `pending/${id}/approve_user`,
        { profile },
        {},
        {},
        { backOffice: true }
      );
    }
    return ApiServices.putRequest(
      `pending/${id}/approve_user`,
      {},
      {},
      {},
      { backOffice: true }
    );
  };

  public updateUser = (user: IViewerUser): Promise<IDataResponse<any>> => {
    return ApiServices.postRequest(
      `pending/${user.id}/update_user_type`,
      user,
      {},
      {},
      { backOffice: true }
    );
  };

  public createUser = (
    user: IRegisterUserRequest
  ): Promise<IDataResponse<any>> => {
    return ApiServices.postRequest(`users`, user, {}, {}, { backOffice: true });
  };

  public createInstitution = (
    institution: IRegisterInstitutionRequest
  ): Promise<IDataResponse<any>> => {
    return ApiServices.postRequest(
      `venues`,
      institution,
      {},
      {},
      { backOffice: true }
    );
  };

  public updateInstitution = (
    institution: IRegisterInstitutionRequest
  ): Promise<IDataResponse<any>> => {
    return ApiServices.putRequest(
      `venues/${institution.id}`,
      institution,
      {},
      {},
      { backOffice: true }
    );
  };

  public getInstitutions = (type?: string): Promise<IDataResponse<any>> => {
    const institutionQuery = type ? { institutions_type: type } : {};
    return ApiServices.getRequest(
      `venues`,
      institutionQuery,
      {},
      { authenticated: false }
    );
  };

  public setFeaturedExhibition = (
    exhibitionUrl: string
  ): Promise<IDataResponse<any>> => {
    return ApiServices.postRequest(
      `exhibition`,
      { exhibitionUrl },
      {},
      {},
      { backOffice: true }
    );
  };
}

export default BackOfficeServices;
