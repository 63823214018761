import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { AppBar, Theme, Toolbar } from '@mui/material';
import backgroundColor from '../../../img/ACG_background_landingPage.jpg';
import {} from '@mui/material/';
import { BREAKPOINT, PRIMARY_COLOR } from '../../../styles/constants';
import { setColorOpacity } from '../../../utils/theme';
import { RouteComponentProps } from 'react-router';
import { ArtpoolLogo } from '@acg/frontend-ui-artpool';

export interface INewCenterLayoutProps {
  children: React.ReactNode;
}

const style = (theme: Theme) =>
  createStyles({
    main: {
      width: '100%',
      position: 'relative',
      zIndex: 1,
      minHeight: '100%',
    },
    navbar: {
      flexGrow: 1,
      position: 'sticky',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
    },
    colorDefault: {
      height: 60,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    content: {
      zIndex: 2,
      backgroundColor: 'white',
      display: 'flex',
      justifyContent: 'center',
      marginTop: '20px',
    },
    wrapper: {},
    mobileLogo: {
      height: 45,
      float: 'left',
      marginRight: 15,
    },
    title: {
      textDecoration: 'none',
      color: 'rgba(0, 0, 0, 0.87)',
      float: 'left',
      minWidth: 220,
      cursor: 'pointer',
    },
    titleSmall: {
      textDecoration: 'none',
      color: 'rgba(0, 0, 0, 0.87)',
      float: 'left',
      cursor: 'pointer',
    },
    left: {
      float: 'left',
      width: '100%',
    },
    right: {
      float: 'right',
      width: 250,
    },
    companyName: {
      lineHeight: '40px',
    },
    companyNameSmall: {
      lineHeight: '40px',
      fontSize: '15px',
    },
    search: {
      display: 'inline-block',
      width: 'calc(100% - 250px)',
    },
    searchWrapper: {
      width: '50%',
    },
    service: {
      float: 'left',
      height: 60,
      lineHeight: '60px',
      marginRight: 20,
    },
    profile: {
      float: 'right',
      height: 60,
      lineHeight: 60,
      width: 40,
      marginRight: 20,
    },
    grow: {
      display: 'flex',
      justifyContent: 'center',
      flex: 1,
    },
    button: {
      fontFamily: 'Replica-Regular',
      fontSize: 14,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: -0.12,
      textAlign: 'center',
      color: 'black',
      cursor: 'pointer',
      textDecoration: 'none',
      marginRight: 30,
      '&:hover': {
        color: PRIMARY_COLOR,
      },
    },

    avatar: {
      margin: 10,
      marginLeft: 0,
    },
    link: {
      fontWeight: 500,
      textDecoration: 'none',
    },
    menuItem: {
      color: 'black',
      '&:hover': {
        backgroundColor: setColorOpacity(theme.palette.primary.light, 70),
      },
    },
    menu: {
      backgroundColor: '#fff',
    },

    section: {
      marginTop: '64px',
      background: `url(${backgroundColor}) no-repeat center fixed`,
      backgroundSize: 'cover',
      paddingLeft: '15%',
      paddingRight: '15%',
      paddingTop: 96,
      paddingBottom: 96,
    },
    headerLeft: {
      justifyContent: 'start',
    },

    beta: {
      width: 26,
      height: 14,
      fontFamily: 'Replica',
      fontSize: 12,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: -0.12,
      textAlign: 'center',
      color: '#6236ff',
      marginLeft: 5,
    },

    aligner: {
      padding: '0 20px',
      textAlign: 'center',
    },

    landingButton: {
      width: '75%',
      borderRadius: '30px',
    },
  });

type Props = INewCenterLayoutProps &
  WithStyles<string> &
  WithWidth &
  RouteComponentProps<{}>;

const NewCenterLayout: React.FC<Props> = (props) => {
  const { classes, history, width, children } = props;

  const onHomeClick = () => history.push('/');
  const isPad = isWidthDown(BREAKPOINT, width);
  const isMobile = isWidthDown('xs', width);

  return (
    <div className={`${classes.main}`}>
      <div className={classes.wrapper}>
        <div className={classes.navbar}>
          <AppBar
            classes={{ colorDefault: classes.colorDefault }}
            color="default"
            elevation={0}
            position="static"
            style={{ backgroundColor: 'white' }}
          >
            <Toolbar>
              <div
                className={`${classes.grow} ${
                  isPad
                    ? `${classes.titleSmall} ${classes.headerLeft}`
                    : classes.title
                }`}
              >
                <ArtpoolLogo
                  className={classes.logo}
                  hoverEffect={true}
                  isMobile={isMobile}
                  isPad={isPad}
                  onClick={onHomeClick}
                  linkToArtpool={true}
                />
              </div>
            </Toolbar>
          </AppBar>
        </div>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default compose<Props, INewCenterLayoutProps>(
  withStyles(style),
  withWidth()
)(NewCenterLayout);
