import { IBaseEnvironment } from '@acg/shared/interfaces';
import { ArtcuratorgridApiUrls } from '@acg/shared/const';
import { EvmNetworkUrls } from '../../../../libs/shared/const/src/lib/evm';

export interface IArtpoolEnvironment extends IBaseEnvironment {
  artpoolSubstrateWallet: string;
  apiURL: string;
  artcuratorgridApiUrl: ArtcuratorgridApiUrls;
  sentryEnabled: boolean;
  sentryDNS: string;
  stripePublishableKey: string;
  substrateTestMode: boolean;
  artpoolEtherWallet: string;
  allowedChainIds: ChainIds[];
  acgCloudFrontUrl: string;
  evm: {
    ethereum: {
      rpcUrl: EvmNetworkUrls;
    };
  };
}

export enum ChainIds {
  MAIN_NET = '0x1',
  GOERLI = '0x5',
  LOCAL = '0x7a69',
}
