import * as React from 'react';
import { styled } from '@mui/material/styles';
import { FieldProps } from 'formik';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Grid } from '@mui/material';
import { GREY_COLOR, PRIMARY_COLOR } from '../../styles/constants';

const PREFIX = 'ArtistEmailInput';

const classes = {
  root: `${PREFIX}-root`,
  backgroundGradient: `${PREFIX}-backgroundGradient`,
  newBackground: `${PREFIX}-newBackground`,
  input: `${PREFIX}-input`,
  label: `${PREFIX}-label`,
  labelSmall: `${PREFIX}-labelSmall`,
  inputSmall: `${PREFIX}-inputSmall`,
  textMobile: `${PREFIX}-textMobile`,
  textDesktop: `${PREFIX}-textDesktop`,
};

const Root = styled('div')({
  [`& .${classes.root}`]: {
    border: 'none',
  },
  [`& .${classes.backgroundGradient}`]: {
    backgroundImage:
      'linear-gradient(to right, #f5f5f5, #f6f6f6 10%, #f7f7f7 20%, #f8f8f8 30%, #f9f9f9 40%, #fafafa 50%, #fbfbfb 60%, #fcfcfc 70%, #fdfdfd 80%, #fefefe 90%, #ffffff)',
  },
  [`& .${classes.newBackground}`]: {
    backgroundColor: 'white',
  },
  [`& .${classes.input}`]: {},
  [`& .${classes.label}`]: {},
  [`& .${classes.labelSmall}`]: {
    fontSize: 12,
  },
  [`& .${classes.inputSmall}`]: {
    fontSize: 12,
  },
  [`& .${classes.textMobile}`]: {
    '& h2': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 16,
    },
    fontFamily: 'Replica-Regular',
  },
  [`&.${classes.textDesktop}`]: {
    '& h2': {
      fontSize: 30,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 16,
    },
    fontFamily: 'Replica-Regular',
  },
});

export interface IArtistEmailInputProps extends FieldProps {
  inputProps: TextFieldProps;
  naked?: boolean;
  small?: boolean;
  newTextInput?: boolean;
}

const ArtistEmailInput = (props: IArtistEmailInputProps) => {
  const {
    inputProps,
    // field,
    form,
    naked,
    newTextInput,
  } = props;

  let stl: any = {
    margin: 'normal',
    variant: 'filled',
  };

  if (naked) {
    stl = {
      variant: 'outlined',
      margin: 'none',
    };
  }
  const handleChange = (event: React.ChangeEvent<any>) => {
    form.setFieldValue(
      `artists[${event.target.name}].email`,
      event.target.value
    );
  };

  let artistsLength = 0;
  if (form.values.artists) {
    artistsLength = form.values.artists.filter((artist: any) =>
      artist.hasOwnProperty('created_by')
    ).length;
  }

  return (
    <Root className={classes.textDesktop}>
      {artistsLength > 0 && (
        <h3 style={{ color: PRIMARY_COLOR }}>{`Invite the artist${
          artistsLength > 1 ? 's' : ''
        } to create a profile on Artpool`}</h3>
      )}

      {form.values.artists &&
        form.values.artists.map((artist: any, i: number) => {
          if (artist.created_by) {
            return (
              <Grid
                container={true}
                key={i}
                style={{
                  backgroundColor: GREY_COLOR,
                  borderRadius: 8,
                  margin: '7px 0px',
                }}
              >
                <Grid item={true} xs={3} style={{ padding: 10 }}>
                  <p>{artist.name}</p>
                </Grid>
                <Grid item={true} xs={9} style={{ padding: 10 }}>
                  <TextField
                    key={i}
                    value={artist.email}
                    name={i}
                    onChange={handleChange}
                    onBlur={form.handleBlur}
                    disabled={form.isSubmitting || inputProps.disabled}
                    // error={!!(form.touched[field.name] && form.errors[field.name])}
                    // helperText={form.touched[field.name] && form.errors[field.name]}
                    {...inputProps}
                    fullWidth={true}
                    {...stl}
                    className={classes.root}
                    // classes={classes}
                    InputProps={{
                      className: `${
                        props.small ? classes.inputSmall : classes.input
                      } ${
                        newTextInput
                          ? classes.newBackground
                          : classes.backgroundGradient
                      }`,
                    }}
                    InputLabelProps={{
                      className: props.small
                        ? classes.labelSmall
                        : classes.label,
                    }}
                  />
                </Grid>
              </Grid>
            );
          } else {
            return;
          }
        })}
    </Root>
  );
};

export default ArtistEmailInput;
