import * as React from 'react';
import { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { IExhibitionPoint } from '../../types/exhibitions';
import { PRIMARY_COLOR, SPACING } from '../../styles/constants';
import OnlineExhibitionMap from '../../components/onlineexhibition/OnlineExhibitionMap';
import OnlineExhibitionArtPointsList from '../../components/onlineexhibition/OnlineExhibitionArtPointsList';
import { useSelector } from 'react-redux';
import ExhibitionPointModal from '../../components/modal/ExhibitionPointModal';
import ExhibitionInfoPoint from '../../components/onlineexhibition/ExhibitionInfoPoint';
import ExhibitionArtPoint from '../../components/onlineexhibition/ExhibitionArtPoint';
import ExhibitionPointSlider from '../../components/modal/ExhibitionPointSlider';
import { IStoreState } from '../../store';
import { useIsWidthDown } from '../../hooks/theme';

const PREFIX = 'OnlineExhibitionMapContainer';
const MAP_HEIGHT = '580px';

const classes = {
  textMobile: `${PREFIX}-textMobile`,
  textDesktop: `${PREFIX}-textDesktop`,
  curatedBy: `${PREFIX}-curatedBy`,
  venueLink: `${PREFIX}-venueLink`,
  mapContainer: `${PREFIX}-mapContainer`,
  mapContainerMobile: `${PREFIX}-mapContainerMobile`,
  pointsContainer: `${PREFIX}-pointsContainer`,
  exhibitionTitle: `${PREFIX}-exhibitionTitle`,
  exhibitionTitleMobile: `${PREFIX}-exhibitionTitleMobile`,
  exhibitionTextMobile: `${PREFIX}-exhibitionTextMobile`,
  exhibitionDate: `${PREFIX}-exhibitionDate`,
  artistTitle: `${PREFIX}-artistTitle`,
  postOuter: `${PREFIX}-postOuter`,
  postInner: `${PREFIX}-postInner`,
  postInnerMobile: `${PREFIX}-postInnerMobile`,
  lineBottomWrapper: `${PREFIX}-lineBottomWrapper`,
  lineBottom: `${PREFIX}-lineBottom`,
  lineRight: `${PREFIX}-lineRight`,
  draftSection: `${PREFIX}-draftSection`,
  creditText: `${PREFIX}-creditText`,
  mobileFeaturedImageWrapper: `${PREFIX}-mobileFeaturedImageWrapper`,
  desktopFeaturedImageWrapper: `${PREFIX}-desktopFeaturedImageWrapper`,
};

const Root = styled('div')({
  [`& .${classes.textMobile}`]: {
    fontFamily: 'Replica-Regular',
    '& h1': {
      fontSize: 35,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 15,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 12,
    },
  },
  [`& .${classes.textDesktop}`]: {
    fontFamily: 'Replica-Regular',
    '& h1': {
      fontSize: 50,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 30,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 16,
    },
  },
  [`& .${classes.curatedBy}`]: {
    cursor: 'pointer',
    '&:hover': {
      color: PRIMARY_COLOR,
    },
  },
  [`& .${classes.venueLink}`]: {
    color: '#000',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: PRIMARY_COLOR,
    },
  },
  [`& .${classes.mapContainer}`]: {
    // padding: 30,
  },
  [`& .${classes.mapContainerMobile}`]: {},
  [`& .${classes.pointsContainer}`]: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: `calc(${MAP_HEIGHT} + 20px)`,
    minHeight: `calc(${MAP_HEIGHT} + 20px)`,
  },
  [`& .${classes.exhibitionTitle}`]: {
    fontSize: 30,
    // paddingLeft:20,
  },
  [`& .${classes.exhibitionTitleMobile}`]: {
    fontSize: 20,
  },
  [`& .${classes.exhibitionTextMobile}`]: {
    fontSize: 15,
  },
  [`& .${classes.exhibitionDate}`]: {
    fontSize: 20,
    color: '#afb0b3',
    // paddingLeft:20,
  },
  [`& .${classes.artistTitle}`]: {
    fontSize: 20,
    color: '#afb0b3',
  },
  [`& .${classes.postOuter}`]: {
    padding: `${SPACING}px ${SPACING}px`,
  },
  [`& .${classes.postInner}`]: {
    display: 'block',
    margin: '10px 0',
    padding: '0 10px',
    borderRight: '1px solid black',
  },
  [`& .${classes.postInnerMobile}`]: {
    display: 'block',
    margin: '10px 0',
    // padding: '0 10px',
  },
  [`& .${classes.lineBottomWrapper}`]: {
    padding: `0 ${SPACING}px`,
    display: 'block',
  },
  [`& .${classes.lineBottom}`]: {
    borderBottom: '1px solid',
  },
  [`& .${classes.lineRight}`]: {
    paddingTop: 10,
    borderRight: '1px solid black',
  },
  [`& .${classes.draftSection}`]: {
    paddingTop: 10,
    height: 50,
    borderColor: PRIMARY_COLOR,
    borderStyle: 'dotted',
    fontSize: 20,
    color: PRIMARY_COLOR,
    textAlign: 'center',
  },
  [`& .${classes.creditText}`]: {
    textAlign: 'center',
    fontSize: 16,
    color: '#afb0b3',
  },
  [`& .${classes.mobileFeaturedImageWrapper}`]: {
    textAlign: 'center',
    minHeight: 300,
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${classes.desktopFeaturedImageWrapper}`]: {
    textAlign: 'center',
    height: '90%',
  },
});

export interface IOnlineExhibitionMapContainerProps {
  initialIndex: number;
  modalOpen: boolean;
  setModalOpen: any;
  setInitialIndex: any;
  onArtWorkClick: (point: any) => void;
}

const OnlineExhibitionMapContainer = (
  props: IOnlineExhibitionMapContainerProps
) => {
  // ----- Reference Section -----
  const isMobile = useIsWidthDown('sm');
  const mapContainer = useRef<any | undefined>();
  const mapRef = useRef<any | undefined>();
  const markersArray = useRef<any | undefined>();
  const artPointsRef = useRef<any | undefined>();

  // ----- State Section -----
  // const [initialIndex, setInitialIndex] = useState(-1);
  // const [modalOpen, setModalOpen] = useState(false);
  const [selectedPointId, setSelectedPointId] = useState<number | undefined>(
    undefined
  );

  // ----- Redux Section -----
  const { exhibition } = useSelector((state: IStoreState) => {
    return state.exhibitions;
  });

  // ----- Function Section -----
  const onCloseModal = () => {
    props.setModalOpen(false);
    setSelectedPointId(undefined);
    props.setInitialIndex(-1);
  };

  const onChangeEvent = (index: number) => {
    if (
      exhibition &&
      exhibition.exhibition_points &&
      exhibition.exhibition_points[index]
    ) {
      const pointChanged = exhibition.exhibition_points[index];
      const latLon: [number, number] = [
        pointChanged.point_position[0],
        pointChanged.point_position[1],
      ];
      setMapLatLonZoom(latLon, pointChanged.point_order);
    }
  };

  const setMapLatLonZoom = (
    latLon: [number, number],
    highlightPoint?: number
  ) => {
    if (!highlightPoint) {
      highlightPoint = -1;
    }
    // setSelectedPointId(highlightPoint);
  };

  const onArtWorkClick = (point: any) => {
    props.onArtWorkClick(point);
  };

  if (!exhibition) {
    return <></>;
  }

  return (
    <Root>
      <Grid
        container={true}
        spacing={2}
        style={{ justifyContent: 'center', paddingTop: 20 }}
        ref={mapContainer}
      >
        <Grid
          item={true}
          xs={12}
          sm={!exhibition.show_artworks_list ? 12 : 8}
          className={classes.postOuter}
        >
          <div
            className={isMobile ? '' : classes.lineBottomWrapper}
            key={'map_line_outer'}
          >
            <div className={classes.lineBottom} />
          </div>
          <div
            className={
              isMobile || !exhibition.show_artworks_list
                ? classes.postInnerMobile
                : classes.postInner
            }
          >
            <div
              className={
                isMobile ? classes.mapContainerMobile : classes.mapContainer
              }
            >
              <OnlineExhibitionMap
                exhibition={exhibition}
                mapRef={mapRef}
                markersArray={markersArray}
                setModalOpen={props.setModalOpen}
                setInitialIndex={props.setInitialIndex}
                selectedPointId={selectedPointId}
                setSelectedPointId={setSelectedPointId}
              />
            </div>
          </div>
          <div
            className={isMobile ? '' : classes.lineBottomWrapper}
            key={'map_line_bottom'}
          >
            <div className={classes.lineBottom} />
          </div>
        </Grid>
        {!isMobile && exhibition.show_artworks_list && (
          <Grid item={true} sm={4} style={{ paddingLeft: 0, paddingRight: 20 }}>
            <div
              className={classes.lineBottomWrapper}
              key={'points_line_mobile'}
            >
              <div className={classes.lineBottom} />
            </div>
            <div
              id={'pointsContainer'}
              key={'_pointsContainer_'}
              className={classes.pointsContainer}
            >
              {exhibition.exhibition_points &&
                exhibition.exhibition_points.map(
                  (point: IExhibitionPoint, i: number) => {
                    return (
                      <OnlineExhibitionArtPointsList
                        key={`__ArtPointList_${i}__`}
                        artPoint={point}
                        onArtWorkClick={onArtWorkClick}
                        refCall={artPointsRef}
                        selectedPointId={selectedPointId}
                        setSelectedPointId={setSelectedPointId}
                      />
                    );
                  }
                )}
            </div>
            <div
              className={classes.lineBottomWrapper}
              key={'points_line_bottom'}
            >
              <div className={classes.lineBottom} />
            </div>
          </Grid>
        )}
      </Grid>
      {props.modalOpen && props.initialIndex > -1 && (
        <ExhibitionPointModal
          open={props.modalOpen}
          onClose={onCloseModal}
          mobile={isMobile}
          hideArrows={false}
          initialIndex={props.initialIndex}
          onChange={onChangeEvent}
        >
          {exhibition &&
            exhibition.exhibition_points.map(
              (exhibitionPoint: IExhibitionPoint, index: number) => {
                const orderValue =
                  exhibitionPoint.point_order < 10
                    ? '0' + exhibitionPoint.point_order
                    : '' + exhibitionPoint.point_order;

                const exhibitionData = {
                  title: exhibition.title,
                  start_date: exhibition.start_date,
                  end_date: exhibition.end_date,
                  curatorial_text: exhibitionPoint.curatorial_text,
                };

                let sliderPointMedia = (
                  <ExhibitionInfoPoint
                    orderValue={orderValue}
                    onlineExhibitionData={exhibitionData}
                    exhibitionPoint={exhibitionPoint}
                    mobile={isMobile}
                  />
                );

                if (exhibitionPoint.art_work) {
                  sliderPointMedia = (
                    <ExhibitionArtPoint
                      orderValue={orderValue}
                      onlineExhibitionData={exhibitionData}
                      exhibitionPoint={exhibitionPoint}
                      mobile={isMobile}
                    />
                  );
                }

                return (
                  <ExhibitionPointSlider
                    key={`slider_point_art_point_${Math.random()
                      .toString(36)
                      .substring(2, 15)}`}
                    // mobile={false}
                    mobile={isMobile}
                    orderValue={orderValue}
                    media={sliderPointMedia}
                    mediaBackgroundStyle={{ height: '100% !important' }}
                  />
                );
              }
            )}
        </ExhibitionPointModal>
      )}
    </Root>
  );
};

export default OnlineExhibitionMapContainer;
