import * as React from 'react';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { PRIMARY_COLOR } from '../../styles/constants';
import SearchUser from '../../containers/search/SearchUser';
import SearchUserFilter from '../../containers/search/SearchUserFilter';
import IconButton from '../buttons/IconButton';
import { deserializeUrl } from '../../utils/router';
import IconFilter from '../../library/dataDisplay/icons/IconFilter';
import InviteButton from '../../library/inputs/InviteButton';
import { useIsWidthDown } from '../../hooks/theme';
import { useLocation } from 'react-router-dom';

const PREFIX = 'UserFilter';

const classes = {
  root: `${PREFIX}-root`,
  topBorder: `${PREFIX}-topBorder`,
  infoNavBar: `${PREFIX}-infoNavBar`,
  infoNavBarMobile: `${PREFIX}-infoNavBarMobile`,
  tabs: `${PREFIX}-tabs`,
  indicator: `${PREFIX}-indicator`,
  scrollButton: `${PREFIX}-scrollButton`,
};

const Root = styled('div')({
  [`& .${classes.root}`]: {
    textTransform: 'none',
  },
  [`& .${classes.topBorder}`]: {
    borderTop: `1px solid ${PRIMARY_COLOR}`,
  },
  [`& .${classes.infoNavBar}`]: {
    paddingBottom: 10,
    position: 'fixed',
    top: 80,
    backgroundColor: '#FFF',
    zIndex: 100,
    justifyContent: 'space-between',
  },
  [`& .${classes.infoNavBarMobile}`]: {
    marginBottom: 20,
    paddingBottom: 10,
    position: 'fixed',
    top: 80,
    backgroundColor: '#FFF',
    zIndex: 100,
  },
  [`& .${classes.tabs}`]: {
    fontSize: '0.715rem',
    letterSpacing: -0.12,
    textAlign: 'right',
    minWidth: 100,
    // color: '#acabac',
    '& button': {
      minWidth: 100,
      fontSize: 14,
    },
  },
  [`& .${classes.indicator}`]: {
    backgroundColor: PRIMARY_COLOR,
  },
  [`& .${classes.scrollButton}`]: {
    width: 10,
  },
});

export interface IUserFilterProps {
  className?: string;
  html?: string;
  onTabSelection?: (tabSelected: string) => void;
  tabSelected?: string;
  fetchData: (pageNumber: number, subType?: string, reset?: boolean) => void;
  searchData: (
    pageNumber: number,
    query: any,
    subType?: string,
    reset?: boolean
  ) => void;
  handleTabChange: (e: any, val: string) => void;
  match?: any;
  location?: any;
  filters?: any;
}

const UserTypeOptions = [
  { id: 'ALL', label: 'ALL', text: 'All', tabLabel: 'All' },
  {
    id: 'INDEPENDANT',
    label: 'INDEPENDENT',
    text: 'Independent',
    tabLabel: 'Independent',
  },
  {
    id: 'INSTITUTION',
    label: 'INSTITUTION',
    text: 'Institution',
    tabLabel: 'Institution',
  },
];

export default (props: IUserFilterProps) => {
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState<Record<string, string>>({});

  const { tabSelected, handleTabChange } = props;

  const location = useLocation();

  const isPad = useIsWidthDown('md');
  const isMobile = useIsWidthDown('sm');

  useEffect(() => {
    setFilters(deserializeUrl(location.search));
  }, []);

  const toggleFilter = () => setShowFilter(!showFilter);

  const updateFilters = (keyValuePair: any) => {
    const newFilters = { ...filters, ...keyValuePair };
    Object.keys(newFilters).forEach((key) => {
      if (newFilters[key] === '') {
        delete newFilters[key];
      }
    });
    setFilters(newFilters);
  };

  return (
    <Root>
      <Grid
        container={true}
        alignItems={'center'}
        className={!isPad ? classes.infoNavBar : classes.infoNavBarMobile}
      >
        {isPad && (
          <React.Fragment>
            <Grid
              item={true}
              md={false}
              xs={8}
              sm={10}
              style={{ paddingRight: 8 }}
            >
              <SearchUser setFilters={updateFilters} filters={filters} />
            </Grid>
            <Grid
              item={true}
              xs={4}
              sm={2}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <IconButton
                icon={<IconFilter />}
                handleClick={toggleFilter}
                filters={filters}
              >
                Filter
              </IconButton>
            </Grid>
          </React.Fragment>
        )}
        <Grid item={true}>
          <Grid container={true} alignItems={'center'}>
            <Grid
              item={true}
              xs={isMobile ? 7 : undefined}
              className={classes.tabs}
              spacing={1}
            >
              <Tabs
                value={tabSelected}
                classes={{
                  root: classes.tabs,
                  indicator: classes.indicator,
                  scrollButtons: classes.scrollButton,
                }}
                onChange={handleTabChange}
                scrollButtons={true}
                variant="scrollable"
              >
                {UserTypeOptions.map((type) => {
                  return (
                    <Tab
                      label={type.tabLabel}
                      value={type.id}
                      key={type.id}
                      classes={{
                        root: `${classes.root} ${!isPad && classes.topBorder}`,
                      }}
                    />
                  );
                })}
              </Tabs>
            </Grid>
            <Grid
              item={true}
              xs={isMobile ? 5 : undefined}
              style={
                isMobile
                  ? {
                      display: 'flex',
                      justifyContent: 'flex-end',
                      padding: 10,
                    }
                  : {}
              }
            >
              <InviteButton
                buttonSize={isPad ? 'small' : 'medium'}
                profileInvited={'Curators'}
              />
            </Grid>
          </Grid>
        </Grid>
        {!isPad && (
          <Grid item={true} xs={3}>
            <Grid container={true}>
              <Grid item={true} xs={7} style={{ paddingRight: 8 }}>
                <SearchUser setFilters={updateFilters} filters={filters} />
              </Grid>
              <Grid
                item={true}
                xs={5}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <IconButton
                  icon={<IconFilter />}
                  handleClick={toggleFilter}
                  filters={filters}
                >
                  Filter
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        )}
        {showFilter && (
          <Grid item={true} xs={12}>
            <Grid container={true} justifyContent={'flex-end'}>
              <Grid item={true} xs={12} md={2} style={{ padding: 10 }}>
                <SearchUserFilter
                  name={'location'}
                  title={'Filter by location'}
                  setFilters={updateFilters}
                  filters={filters}
                  toggleFilter={toggleFilter}
                />
              </Grid>
              <Grid item={true} xs={12} md={2} style={{ padding: 10 }}>
                <SearchUserFilter
                  name={'expertise'}
                  title={'Curatorial expertise'}
                  setFilters={updateFilters}
                  filters={filters}
                  toggleFilter={toggleFilter}
                  disabled={false}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Root>
  );
};
