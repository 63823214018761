import * as React from 'react';
import { styled } from '@mui/material/styles';
import Logo from '../../img/logo.svg';
import LogoGray from '../../img/gray/logoFilled.svg';

const PREFIX = 'Cross';

const classes = {
  size: `${PREFIX}-size`,
  button: `${PREFIX}-button`,
  plain: `${PREFIX}-plain`,
};

const Root = styled('div')({
  [`& .${classes.size}`]: {
    width: 12,
    paddingRight: 2,
  },

  [`& .${classes.button}`]: {
    cursor: 'pointer',
    border: '1px solid',
    borderRadius: 5,
    padding: 5,
    '&:hover': {
      border: 'px solid #2b2e34',
      backgroundColor: 'rgba(43, 46, 52, 0.08)',
    },
  },
  [`& .${classes.plain}`]: {
    cursor: 'pointer',
  },
});

export interface ICrossProps {
  fontSize?: number;
  type?: 'STANDARD' | 'GRAY';
  isButton?: boolean;
}

const Cross = (props: ICrossProps) => {
  const { fontSize, isButton, type } = props;

  return (
    <Root
      className={isButton ? classes.button : classes.plain}
      style={{ display: 'flex' }}
    >
      <img
        src={type === 'GRAY' ? LogoGray : Logo}
        className={classes.size}
        style={fontSize ? { width: fontSize } : undefined}
      />
    </Root>
  );
};

export default Cross;
