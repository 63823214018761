import { createRouteRequest } from 'typesafe-api';
import {
  GetMyAccountEndpointDef,
  getMyAccountRoute,
  GetNftsOwnedEndpointDef,
  getNftsOwnedRoute,
  PatchMyAccountEndpointDef,
  PatchMyAccountReq,
  patchMyAccountRoute,
} from '@acg/artpool-api-spec';
import { AuthApiClient } from '@acg/shared/api-spec-utils';

export type PatchMyAccountParams = PatchMyAccountReq['body'];

export class MyAccountClient extends AuthApiClient {
  /**
   * GET my account
   */
  private _getMyAccount = createRouteRequest<GetMyAccountEndpointDef>(
    this,
    getMyAccountRoute
  );
  public getMyAccount = () => this._getMyAccount({});

  /**
   * PATCH my account
   */
  private _patchMyAccount = createRouteRequest<PatchMyAccountEndpointDef>(
    this,
    patchMyAccountRoute
  );
  public patchMyAccount = (params: PatchMyAccountParams) =>
    this._patchMyAccount({
      body: params,
    });

  /**
   * GET nfts owned
   */
  private _getNftsOwned = createRouteRequest<GetNftsOwnedEndpointDef>(
    this,
    getNftsOwnedRoute
  );
  public getNftsOwned = () => this._getNftsOwned({});
}
