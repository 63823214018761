import React from 'react';
import { Grid, GridProps } from '@mui/material';
import { formatAddress } from '@acg/evm';
import { NEUTRAL } from '@acg/assets';

export interface EvmWalletAddressProps {
  address: string;
}

const containerProps: GridProps = {
  container: true,
  direction: 'column',
  justifyContent: 'center',
  sx: {
    background: NEUTRAL[50],
    borderRadius: '4px',
    padding: '16px 24px',
    width: '100%',
  },
};

const titleProps = {
  item: true,
  sx: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '14px',
    marginBottom: '16px',
  },
};

const addressProps = {
  item: true,
  sx: {
    textAlign: 'center',
  },
};

export const EvmWalletAddress = (props: EvmWalletAddressProps) => {
  const { address } = props;
  return (
    <div style={{ width: '100%' }}>
      <Grid {...containerProps}>
        <Grid {...titleProps}>Wallet Address</Grid>
        <Grid {...addressProps}>{formatAddress(address)}</Grid>
      </Grid>
    </div>
  );
};
