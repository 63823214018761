import ApiServices, { IDataListResponse } from './ApiServices';
import { IPost } from '../types/posts';

export interface ISearchFilters {
  first_result?: number;
  max_results?: number;
  query?: string;
  location?: string;
  expertise?: string;
  sub_type?: string;
  order_by?: string;
  status?: string;
}

class SearchService {
  public searchPosts = (
    filters?: ISearchFilters
  ): Promise<IDataListResponse<IPost>> => {
    return ApiServices.getRequest('search/posts', filters);
  };
  public getPostsSuggestions = (
    filters?: ISearchFilters
  ): Promise<IDataListResponse<string>> => {
    return ApiServices.getRequest(`search/posts_suggestions`, filters);
  };
  public searchUsers = (
    filters?: ISearchFilters
  ): Promise<IDataListResponse<IPost>> => {
    return ApiServices.getRequest('search/users', filters);
  };
}

export default SearchService;
