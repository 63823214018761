import * as React from 'react';
import { DARK_GREY_COLOR } from '../../../styles/constants';

export interface IIconViewsProps {
  fill?: string;
  height?: number;
  style?: any;
  v2?: boolean;
}

const IconViews = (props: IIconViewsProps) => {
  const style: React.CSSProperties = {
    height: props.height || 24,
    backgroundColor:
      (props.style && props.style.backgroundColor) || 'transparent',
    borderRadius: (props.style && props.style.borderRadius) || 6,
  };

  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
      style={style}
    >
      <circle
        fill={props.fill || DARK_GREY_COLOR}
        className="cls-1"
        cx="547.39"
        cy="539.8"
        r="219"
      />
      <path
        fill={props.fill || DARK_GREY_COLOR}
        className="cls-1"
        d="M979.9,560.63c-5.37-3.14-13.45-1.48-16.42,4.3a485.7,485.7,0,0,1-26.58,45.53,435.21,435.21,0,0,1-29.78,40.2A480.75,480.75,0,0,1,832.37,723c.43-.33,1.42-1.08-.5.39l-3,2.24q-2.48,1.85-5,3.68-5.48,4-11.08,7.88-10.7,7.39-21.78,14.19a478.87,478.87,0,0,1-45.9,24.83,428.85,428.85,0,0,1-47.07,19,476.55,476.55,0,0,1-51.29,14.31,444.36,444.36,0,0,1-51.53,8.29,484,484,0,0,1-55.17,2.39A450.71,450.71,0,0,1,435,806.83a458.72,458.72,0,0,1-96.55-35.38A477.57,477.57,0,0,1,293.7,746a464.16,464.16,0,0,1-40.06-28.84,482.92,482.92,0,0,1-72.1-72.26c-5.15-6.39-9-11.46-14.36-18.91q-7.49-10.43-14.4-21.23A476.26,476.26,0,0,1,127.42,560q-4.55-9.12-8.68-18.43a482.23,482.23,0,0,1,48-85c5.15-7.27,9.92-13.62,14.57-19.45q8-10,16.42-19.52a482.34,482.34,0,0,1,35.79-36.28q9.43-8.59,19.3-16.68c7.07-5.79,11.27-9.05,18.72-14.49a479.1,479.1,0,0,1,88.75-51.27c4.32-1.91,6.06-2.65,9.61-4.1q6.28-2.57,12.65-5,12.18-4.57,24.61-8.49a464.93,464.93,0,0,1,102-20A487.51,487.51,0,0,1,564,260q14.16.46,28.26,1.75,6.5.6,13,1.37c5.11.6,6.71.82,12.25,1.66A465,465,0,0,1,812,341.17a427.42,427.42,0,0,1,39.84,30.52,483.65,483.65,0,0,1,37.53,35.9,453.1,453.1,0,0,1,61.31,81.65q6.66,11.25,12.72,22.87c3,5.74,11,7.46,16.41,4.3,5.83-3.4,7.32-10.65,4.31-16.42A497.36,497.36,0,0,0,845.18,335.7a487.17,487.17,0,0,0-195.27-89.42A497.48,497.48,0,0,0,426,251c-69.22,17.66-133.88,50.8-189.2,95.89A490.7,490.7,0,0,0,106.5,511.06q-5.67,11.61-10.7,23.5a16.46,16.46,0,0,0-1.36,6.67,15.86,15.86,0,0,0,1.36,7.39,492.67,492.67,0,0,0,308,274.55c72,21.89,148.71,26.49,222.93,14.53A486.84,486.84,0,0,0,826,757.21,496.92,496.92,0,0,0,971.68,599.94q6.57-11.28,12.52-22.89C987.16,571.28,985.7,564,979.9,560.63Z"
      />
    </svg>
  );
};

export default IconViews;
