import * as React from 'react';
import CenterLayout from '../../library/layout/container/CenterLayout';
import { Paper } from '@mui/material';
import { Link } from 'react-router-dom';

export interface INotFoundProps {}

export default class NotFound extends React.Component<INotFoundProps, any> {
  public render() {
    return (
      <CenterLayout type={'STANDARD'}>
        <Paper elevation={1} style={{ padding: 30, textAlign: 'center' }}>
          Page Not Found <br />
          <Link to={'/'}>Back to the Grid</Link>
        </Paper>
      </CenterLayout>
    );
  }
}
