import { ALTERNATIVE, AcgLogoIcon, PRIMARY, themes } from '@acg/assets';
import { Body2, StyledButton, Subtitle2 } from '@acg/frontend-ui-artpool';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import styles from './community-section.module.scss';
import { ACGAppPaths } from '@acg/shared/const';

export const CommunitySection = ({
  text,
}: {
  text?: { title: string; subtitle: string; ctaLabel: string };
}) => {
  const isSmall = useMediaQuery(themes.artpoolTheme.breakpoints.down('md'));
  const isMedium = useMediaQuery(themes.artpoolTheme.breakpoints.down('lg'));

  const BoxContent = (props: { text: string; number: string }) => {
    return (
      <Stack textAlign={'center'}>
        <Stack
          direction={'row'}
          spacing={1}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <AcgLogoIcon />
          <h1 style={isSmall ? { fontSize: 30 } : {}}>{props.number}</h1>
        </Stack>
        <Body2>{props.text}</Body2>
      </Stack>
    );
  };

  return (
    <Grid container>
      {text && (
        <Grid item xs={12} style={{ padding: isSmall ? 20 : 50 }}>
          <Stack spacing={4} textAlign={'center'}>
            <h1 style={{ fontWeight: 'bold' }}>{text.title}</h1>
            <Subtitle2 style={{ textAlign: 'left' }}>{text.subtitle}</Subtitle2>
            <Link to={ACGAppPaths.LOGIN}>
              <StyledButton variant={'contained'} fullWidth={isSmall}>
                {text.ctaLabel}
              </StyledButton>
            </Link>
          </Stack>
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid
          container
          style={{ height: isSmall ? 280 : isMedium ? 400 : 590 }}
        >
          <Grid
            item
            xs={6}
            style={{
              height: '100%',
              backgroundColor: ALTERNATIVE.ARTPOOL_YELLOW,
            }}
            className={styles.colorBox}
          >
            <BoxContent text={'Professional Curators'} number={'900'} />
          </Grid>
          <Grid item xs={6} style={{ height: '100%' }}>
            <Grid container style={{ height: '100%' }}>
              <Grid
                item
                xs={6}
                style={{
                  height: '60%',
                  backgroundColor: PRIMARY.ARTPOOL_GREEN,
                }}
                className={styles.colorBox}
              >
                <BoxContent text={'Art Venues'} number={'140'} />
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  height: '60%',
                  backgroundColor: ALTERNATIVE.ARTPOOL_ORANGE,
                }}
                className={styles.colorBox}
              >
                <BoxContent text={'Countries Represented'} number={'700'} />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  height: '40%',
                  backgroundColor: ALTERNATIVE.ARTPOOL_PINK,
                }}
                className={styles.colorBox}
              >
                <BoxContent text={'Artists Mentioned'} number={'6000'} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
