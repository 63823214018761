import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import {} from '@mui/material';
import { IWithAccountProps } from '../../containers/account/InjectAccount';
import { DivIcon } from 'leaflet';
import { Marker } from 'react-leaflet';
import ArtWorkPopup from '../popup/ArtWorkPopup';
import { IExhibitionPoint } from '../../types/exhibitions';
import OnlineExhibitionPointIcon from './OnlineExhibitionPointIcon';

export interface IOnlineExhibitionPointProps {
  point: IExhibitionPoint;
  index: number;

  show_artworks_order: boolean;
  artworksColor: string;
  artworksLabelColor: string;
  artworksBackColor: string;
  selectedPointId?: number;
  verticalSize: number;

  setSelectedPointId: (order: number) => void;
  setInitialIndex: (index: number) => void;
  setModalOpen: (isOpen: boolean) => void;

  popup: any;
}

const styles = createStyles({});

type Props = WithStyles<string> &
  IWithAccountProps &
  WithWidth &
  IOnlineExhibitionPointProps;

function OnlineExhibitionPoint(props: Props) {
  const {
    width,
    index,
    point,
    show_artworks_order,
    artworksColor,
    artworksLabelColor,
    artworksBackColor,
    verticalSize,
    selectedPointId,
    setSelectedPointId,
    setInitialIndex,
    setModalOpen,
    popup,
  } = props;

  const isMobile = isWidthDown('xs', width);

  const onMarkerClick = (event: any) => {
    clearTimeout(timeoutHandler);
    onPointClick();
    event.target.closePopup();
  };

  let timeoutHandler: any;

  const onMarkerMouseOver = (event: any) => {
    timeoutHandler = setTimeout(() => {
      if (!isMobile) {
        setSelectedPointId(point.point_order);
        event.target.openPopup();
      }
    }, 1000);
  };

  const onMarkerMouseOut = (event: any) => {
    clearTimeout(timeoutHandler);
    setTimeout(() => {
      if (!isMobile) {
        setSelectedPointId(-1);
        event.target.closePopup();
      }
    }, 1000);
  };

  const onPointClick = () => {
    setModalOpen(true);
    setInitialIndex(point.point_order - 1);
  };

  const onPopupCloseClick = () => null;

  const pointLabel = show_artworks_order
    ? point.point_order < 10
      ? '0' + point.point_order
      : point.point_order
    : '';

  const pointColor = point.artworks_color
    ? point.artworks_color
    : artworksColor;
  const labelColor = point.artworks_label_color
    ? point.artworks_label_color
    : artworksLabelColor;

  const pointBackColor = point.artworks_back_color
    ? point.artworks_back_color
    : artworksBackColor;

  const isSelected = selectedPointId === point.point_order;

  const isInfoPoint = point.info_point ? true : false;

  const iconProps = {
    isInfoPoint,
    isMobile,
    isSelected,
    pointBackColor,
    labelColor,
    pointColor,
    pointLabel,
  };

  const iconToShow = OnlineExhibitionPointIcon(iconProps);

  // --- Code to anchor bottom half popups to the top and top half to the bottom ----
  const verticalCenter = (verticalSize * 0.4) / 4;

  const anchor: [number, number] =
    point.point_position[0] > verticalCenter ? [0, 220] : [0, -35];

  const pointDivIcon = new DivIcon({
    className: '',
    html: iconToShow,
    iconAnchor: [23, 23],
    popupAnchor: anchor,
    iconSize: [50, 50],
  });

  if (selectedPointId === point.point_order) {
    pointDivIcon.options.className = 'leaflet-art-point-selected';
  }

  return (
    <Marker
      position={point.point_position}
      key={point.id}
      // @ts-ignore
      marker_index={index}
      draggable={false}
      icon={pointDivIcon}
      onClick={onMarkerClick}
      onmouseout={onMarkerMouseOut}
      onmouseover={onMarkerMouseOver}
    >
      {!isMobile && (
        <ArtWorkPopup
          popup={popup}
          point={point}
          onPopupCloseClick={onPopupCloseClick}
          onPointClick={onPointClick}
        />
      )}
    </Marker>
  );
}

export default compose<Props, IOnlineExhibitionPointProps>(
  withStyles(styles),
  withWidth()
)(OnlineExhibitionPoint);
