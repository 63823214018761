import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { Grid, Theme } from '@mui/material';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
import SearchUserForm from './SearchUserForm';
import { compose } from 'recompose';

import { RouteComponentProps, withRouter } from 'react-router';
import { DARK_GREY_COLOR } from '../../styles/constants';
import { environment } from '../../environments/environment';

const {
  REACT_APP_ALGOLIA_ID,
  REACT_APP_ALGOLIA_SEARCH_KEY,
  REACT_APP_ALGOLIA_INDEX,
} = environment;

const searchClient = algoliasearch(
  `${REACT_APP_ALGOLIA_ID}`,
  `${REACT_APP_ALGOLIA_SEARCH_KEY}`
);

export interface ISearchWrapperProps {
  setFilters: any;
  filters: any;
}

const style = (theme: Theme) =>
  createStyles({
    gridDefinition: {
      height: 40,
      backgroundColor: '#FFF',
    },
    fullWidthMobile: {
      position: 'absolute',
      width: '99%',
      left: 0,
      backgroundColor: '#FFF',
      marginLeft: '0.5%',
      zIndex: 200,
    },
    fullWidth: {
      position: 'absolute',
      width: '24.5%',
      left: '75%',
      backgroundColor: '#FFF',
      // marginLeft: '0.5%',
      zIndex: 200,
    },
    searchMobileMargin: {
      marginLeft: 10,
    },
    searchInput: {
      // backgroundImage: 'linear-gradient(to left, #ffffff, #e7e8e9), linear-gradient(to bottom, #e7e8e9, #e7e8e9);',
      height: 40,
      width: '100%',
      fontSize: 14,
      border: `1px solid ${DARK_GREY_COLOR}`,
      borderRadius: 8,
    },
  });

type Props = ISearchWrapperProps &
  RouteComponentProps<{}> &
  WithWidth &
  WithStyles<string>;

function SearchUser(props: Props) {
  const { classes, width, setFilters, filters } = props;

  const [fullSearchWidth, setFullSearchWidth] = React.useState(false);
  const isPad = isWidthDown('sm', width);
  const onFocusEvent = () => {
    setFullSearchWidth(true);
  };
  const onBlurEvent = () => {
    setFullSearchWidth(false);
  };

  return (
    <React.Fragment>
      <Grid container={true} className={classes.gridDefinition}>
        <Grid item={true} xs={12}>
          <div
            onClick={onFocusEvent}
            onBlur={onBlurEvent}
            className={
              fullSearchWidth
                ? isPad
                  ? classes.fullWidthMobile
                  : classes.fullWidth
                : !isPad
                ? classes.none
                : classes.searchMobileMargin
            }
          >
            <InstantSearch
              searchClient={searchClient}
              indexName={`${REACT_APP_ALGOLIA_INDEX}_curators`}
            >
              <SearchUserForm
                placeholder={
                  fullSearchWidth
                    ? 'Search Curator Profile by Location, Name, Institution Name or Bio…'
                    : 'Search'
                }
                customClass={classes.searchInput}
                setFilters={setFilters}
                filters={filters}
                closeFullScreen={onBlurEvent}
              />
            </InstantSearch>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default compose<Props, ISearchWrapperProps>(
  withWidth(),
  withStyles(style),
  withRouter
)(SearchUser);
