import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { Select, MenuItem, FormControl, Grid, Theme } from '@mui/material';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
import SearchPostsForm from './SearchPostsForm';
import { compose } from 'recompose';
import { RouteComponentProps, withRouter } from 'react-router';
import { DARK_GREY_COLOR } from '../../styles/constants';
import IconDownArrow from '../../library/dataDisplay/icons/IconDownArrow';
import { environment } from '../../environments/environment';
import { PRIMARY } from '@acg/assets';

const {
  REACT_APP_ALGOLIA_ID,
  REACT_APP_ALGOLIA_SEARCH_KEY,
  REACT_APP_ALGOLIA_INDEX,
} = environment;

const searchClient = algoliasearch(
  `${REACT_APP_ALGOLIA_ID}`,
  `${REACT_APP_ALGOLIA_SEARCH_KEY}`
);

export interface ISearchWrapperProps {
  setOrderBy?: (order: string) => void;
  orderBy?: string;
  showFollowing: boolean;
}

const style = (theme: Theme) =>
  createStyles({
    gridDefinition: {
      height: 40,
      backgroundColor: '#FFF',
      boxSizing: 'border-box',
    },
    fullWidthMobile: {
      position: 'absolute',
      width: '99%',
      left: 0,
      backgroundColor: '#FFF',
      marginLeft: '0.5%',
      zIndex: 200,
    },
    fullWidth: {
      position: 'absolute',
      width: '24.5%',
      left: '75%',
      backgroundColor: '#FFF',
      zIndex: 200,
    },
    searchInput: {
      fontFamily: 'Replica !important',
      height: 40,
      width: '100%',
      fontSize: `14px !important`,
      border: `1px solid ${DARK_GREY_COLOR} !important`,
      borderRadius: 8,
    },
    selectElement: {
      fontFamily: 'Replica !important',
      height: `40px !important`,
      fontSize: `14px !important`,
      color: `${DARK_GREY_COLOR} !important`,
      borderRadius: `8px !important`,
      padding: '10px !important',
    },
    paddingOverride: {
      padding: '0px 15px 0px 0px !important',
    },

    menuItem: {
      fontSize: `14px !important`,
      fontFamily: 'Replica !important',
      fontWeight: 'normal !important',
      '&:hover': {
        backgroundColor: `${PRIMARY.ARTPOOL_GREEN}70 !important`,
      },
    },
    menuItemSelected: {
      backgroundColor: `${PRIMARY.ARTPOOL_GREEN}30 !important`,
    },
    searchMobileMargin: {
      marginLeft: 10,
    },
    none: {},
  });

type Props = ISearchWrapperProps &
  RouteComponentProps<{}> &
  WithWidth &
  WithStyles<string>;

const SearchPosts = (props: Props) => {
  const { classes, orderBy, setOrderBy, showFollowing, width } = props;

  const [fullSearchWidth, setFullSearchWidth] = React.useState(false);

  const isPad = isWidthDown('md', width);

  const orderByChange = (evt: any) => {
    if (evt.target.value && setOrderBy) {
      setOrderBy(evt.target.value);
    }
  };
  const onFocusEvent = () => {
    setFullSearchWidth(true);
  };
  const onBlurEvent = () => {
    setFullSearchWidth(false);
  };

  const searchOptions: { label: string; value: string }[] = [
    { label: 'Current', value: 'CURRENT' },
    { label: 'Last Posted', value: 'LASTPOSTED' },
  ];
  if (showFollowing) {
    searchOptions.push({ label: 'Following', value: 'FOLLOWING' });
  }

  return (
    <>
      <Grid container={true} className={!isPad ? classes.gridDefinition : ''}>
        <Grid
          item={true}
          xs={8}
          sm={10}
          md={7}
          style={{ paddingRight: 8 }}
          className={isPad ? classes.gridDefinitionMobile : ''}
        >
          <div
            onFocus={onFocusEvent}
            onBlur={onBlurEvent}
            className={
              fullSearchWidth
                ? isPad
                  ? classes.fullWidthMobile
                  : classes.fullWidth
                : !isPad
                ? classes.none
                : classes.searchMobileMargin
            }
          >
            <InstantSearch
              searchClient={searchClient}
              indexName={`${REACT_APP_ALGOLIA_INDEX}`}
            >
              <SearchPostsForm
                placeholder={
                  fullSearchWidth
                    ? 'Search Posts by Location, Curator, Artist, Venue, or Title…'
                    : 'Search'
                }
                customClass={classes.searchInput}
              />
            </InstantSearch>
          </div>
        </Grid>
        <Grid item={true} xs={4} sm={2} md={5} style={{ paddingRight: 8 }}>
          <FormControl
            variant={'outlined'}
            style={{
              width: '100%',
              fontSize: '0.715rem',
            }}
            className={classes.gridDefinition}
          >
            <Select
              variant={'outlined'}
              value={orderBy}
              IconComponent={() => (
                <IconDownArrow
                  isSelectInputArrow
                  height={15}
                  style={{ top: 'calc(50% - 8px)' }}
                />
              )}
              onChange={orderByChange}
              className={`${classes.selectElement} ${classes.paddingOverride}`}
            >
              {searchOptions.map((option) => (
                <MenuItem
                  className={classes.menuItem}
                  classes={{ selected: classes.menuItemSelected }}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default compose<Props, ISearchWrapperProps>(
  withWidth(),
  withStyles(style),
  withRouter
)(SearchPosts);
