import * as React from 'react';

export interface IIconSearchProps {
  fill?: string;
  height?: number;
  style?: any;
}

const IconSearch = (props: IIconSearchProps) => {
  const style: React.CSSProperties = {
    height: props.height || 24,
    backgroundColor:
      (props.style && props.style.backgroundColor) || 'transparent',
    borderRadius: (props.style && props.style.borderRadius) || 6,
  };

  return (
    <svg
      fill={props.fill || 'gray'}
      style={style}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
    >
      <path d="M558.92,412.25a244.64,244.64,0,0,1-2.09,31.65l.53-4a238.19,238.19,0,0,1-16.43,60.4l1.52-3.58a239.78,239.78,0,0,1-30.85,52.84l2.34-3a240.86,240.86,0,0,1-43,43l3-2.34A239.78,239.78,0,0,1,421.16,618l3.58-1.51a237.76,237.76,0,0,1-60.4,16.42l4-.53a242.2,242.2,0,0,1-63.31,0l4,.53a238,238,0,0,1-60.4-16.42l3.58,1.51a239.71,239.71,0,0,1-52.83-30.85l3,2.34a240.86,240.86,0,0,1-43-43l2.34,3a239.35,239.35,0,0,1-30.84-52.84l1.51,3.58A238.19,238.19,0,0,1,116,439.91l.54,4a241.34,241.34,0,0,1,0-63.31l-.54,4a238.42,238.42,0,0,1,16.43-60.4l-1.51,3.58a239.28,239.28,0,0,1,30.84-52.83l-2.34,3a240.86,240.86,0,0,1,43-43l-3,2.34a239.28,239.28,0,0,1,52.83-30.84L248.61,208A238.42,238.42,0,0,1,309,191.56l-4,.54a242.2,242.2,0,0,1,63.31,0l-4-.54A238.19,238.19,0,0,1,424.74,208l-3.58-1.51A239.35,239.35,0,0,1,474,237.32L471,235a240.86,240.86,0,0,1,43,43l-2.34-3a239.71,239.71,0,0,1,30.85,52.83l-1.52-3.58a238.42,238.42,0,0,1,16.43,60.4l-.53-4a244.68,244.68,0,0,1,2.09,31.66c0,7.84,6.89,15.36,15,15s15-6.59,15-15a250.71,250.71,0,0,0-45.85-145A261.64,261.64,0,0,0,488.64,211a251.84,251.84,0,0,0-345.79,39.87,261,261,0,0,0-40.21,67.33,249.56,249.56,0,0,0-17.49,75.4,252.57,252.57,0,0,0,6.5,78.73A252.59,252.59,0,0,0,231.56,641.53a259.62,259.62,0,0,0,77.36,21.35A252.21,252.21,0,0,0,562.18,525.32a248.57,248.57,0,0,0,23.46-72.92,267.33,267.33,0,0,0,3.28-40.15c0-7.85-6.91-15.37-15-15S558.93,403.84,558.92,412.25Z" />
      <path d="M535.22,632l2,2,5.68,5.61,9,8.89,11.94,11.77,14.46,14.25,16.48,16.25,18.27,18,19.57,19.3L653,748.13l21,20.68,21.07,20.78,20.77,20.47,20,19.68,19,18.68,17.44,17.19,15.45,15.23,13.21,13,10.49,10.34,7.37,7.26,3.83,3.77.47.47a15.52,15.52,0,0,0,10.61,4.4,15.29,15.29,0,0,0,10.61-4.4c2.62-2.85,4.57-6.61,4.39-10.6a15.58,15.58,0,0,0-4.39-10.61l-2-2-5.68-5.6-9-8.89-11.95-11.78L801.12,852l-16.48-16.25-18.27-18-19.57-19.3-20.38-20.09-21-20.67-21.08-20.78-20.76-20.47-20-19.68-19-18.68-17.43-17.2-15.45-15.23-13.22-13L568.1,622.3,560.74,615l-3.83-3.78-.48-.47a15.51,15.51,0,0,0-10.6-4.39,15.26,15.26,0,0,0-10.61,4.39c-2.62,2.86-4.57,6.61-4.39,10.61A15.6,15.6,0,0,0,535.22,632Z" />
    </svg>
  );
};

export default IconSearch;
