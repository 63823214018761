export const PRODUCTION_ARTPOOL_WALLET =
  '5HjH2N75QMP7nryu5SL6vSbThEWtiQaMHZ1EG8Gkj7wp4A55';
export const PRODUCTION_ARTPOOL_ETHER_WALLET = '';
export const DEVELOP_ARTPOOL_WALLET =
  '5EWfHxPGZsNPQHWoKnRk1veAvT8sYg5uiXt6xXxwxaU2f9sW';
export const DEVELOP_ARTPOOL_ETHER_WALLET = '';
('5EWfHxPGZsNPQHWoKnRk1veAvT8sYg5uiXt6xXxwxaU2f9sW');
export const LOCAL_ARTPOOL_WALLET = DEVELOP_ARTPOOL_WALLET;
export const LOCAL_ARTPOOL_ETHER_WALLET =
  '0xD00b0093A82702c0D843DD6aBFF1b97fd8Efe12B';
