import { WithStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import { StyledButton } from '@acg/frontend-ui-artpool';

export interface IShareProfileButtonProps {
  onClick: () => void;
  buttonSize?: any;
}

interface IState {
  open: boolean;
}

type Props = IShareProfileButtonProps & IWithAccountProps & WithStyles<string>;

class ShareProfileButton extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  public render() {
    const { onClick } = this.props;

    return (
      <>
        <StyledButton
          size={this.props.buttonSize || 'large'}
          variant={'outlined'}
          onClick={onClick}
          fullWidth
        >
          Share
        </StyledButton>
      </>
    );
  }
}

export default compose<Props, IShareProfileButtonProps>(withAccount)(
  ShareProfileButton
);
