import { createStyles, makeStyles, WithStyles } from '@mui/styles';
import * as React from 'react';
import { Avatar, Grid } from '@mui/material';
import { IPost } from '../../types/posts';
import ImageContent from '../../components/posts/ImageContent';
import ExhibitionContent from '../../components/posts/ExhibitionContent';
import ArtProjectContent from '../../components/posts/ArtProjectContent';
import OnlineExhibitionContent from '../../components/posts/OnlineExhibitionContent';
import { SPACING } from '../../styles/constants';
import StandardContent from './StandardContent';
import Title from '../../library/dataDisplay/typography/Title';
import SubTitle from '../../library/dataDisplay/typography/SubTitle';
import UserPicture from '../../library/dataDisplay/profile/UserPicture';
import {
  generateCityLocation,
  generateLocation,
} from '../../utils/locationHelper';
import { getMediumImageUrl } from '../../utils/imageUrl';

export interface IPostCoverProps {
  noProfile?: boolean;
  post: IPost;
  onClick: () => void;
  onCommentClick: () => void;
  onShare: () => void;
  isDraft: boolean;
  hasRightBorder: boolean;
  onClickOnAvatar?: (userId: string, isVenue?: boolean) => void;
  onLike: (post: IPost) => void;
  isLiking?: (post: IPost) => boolean;
  landingPage?: boolean;
  nonClickable?: boolean;
  onCategoryButtonClick: (selectedCategory: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    postOuter: {
      padding: `${SPACING}px 0`,
    },
    postInner: {
      padding: `0 ${SPACING}px`,
    },
    borderRight: {
      borderRight: '1px solid black',
    },
    postContent: {
      height: 450,
    },
    avatar: {
      height: 40,
      marginBottom: `10px !important`,
      cursor: 'pointer',
    },
    user: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '40px',
    },
    userName: {
      fontSize: 16,
    },
    userLocation: {
      fontSize: 12,
    },
    footer: {
      textAlign: 'right',
    },
    lineBottomWrapper: {
      padding: `0 ${SPACING}px`,
      display: 'block',
    },
    lineBottom: {
      borderBottom: '1px solid',
    },
  })
);

const PostCover = (props: IPostCoverProps) => {
  const {
    post,
    landingPage,
    nonClickable,
    onClick,
    hasRightBorder,
    noProfile,
    onClickOnAvatar,
    onLike,
    isLiking,
    onCommentClick,
    onShare,
    onCategoryButtonClick,
  } = props;

  const classes = useStyles();

  let content;
  const liked = isLiking ? isLiking(post) : false;
  switch (post.type) {
    case 'STANDARD':
      content = (
        <StandardContent
          onShare={onShare}
          onCommentClick={onCommentClick}
          post={post}
          onClick={onClick}
          onLike={onLike}
          like={liked}
          onCategoryButtonClick={onCategoryButtonClick}
          numberOfLike={post.likes.length}
          nonClickable={nonClickable}
        />
      );
      break;
    case 'ARTPROJECT':
      content = (
        <ArtProjectContent
          onShare={onShare}
          onCommentClick={onCommentClick}
          post={post}
          onClick={onClick}
          onLike={onLike}
          like={liked}
          onCategoryButtonClick={onCategoryButtonClick}
          numberOfLike={post.likes.length}
          nonClickable={nonClickable}
        />
      );
      break;
    case 'ARTICLE':
      content = <div>article</div>;
      break;
    case 'EXHIBITION':
      content = (
        <ExhibitionContent
          onShare={onShare}
          onCommentClick={onCommentClick}
          post={post}
          onClick={onClick}
          onLike={onLike}
          like={liked}
          onCategoryButtonClick={onCategoryButtonClick}
          numberOfLike={post.likes.length}
          nonClickable={nonClickable}
        />
      );
      break;

    case 'ONLINEEXHIBITION':
      content = (
        <OnlineExhibitionContent
          onShare={onShare}
          onCommentClick={onCommentClick}
          post={post}
          onClick={onClick}
          onLike={onLike}
          like={liked}
          onCategoryButtonClick={onCategoryButtonClick}
          numberOfLike={post.likes.length}
          nonClickable={nonClickable}
        />
      );
      break;

    case 'IMAGE':
      content = (
        <ImageContent
          onShare={onShare}
          onCommentClick={onCommentClick}
          post={post}
          onClick={onClick}
          onLike={onLike}
          like={liked}
          onCategoryButtonClick={onCategoryButtonClick}
          numberOfLike={post.likes.length}
          nonClickable={nonClickable}
        />
      );
      break;
    case 'VIDEO':
      content = <div>video</div>;
      break;
  }

  const onClickOnUser = () =>
    onClickOnAvatar ? onClickOnAvatar(post.created_by.url) : undefined;
  const onClickOnVenue = () =>
    onClickOnAvatar && post.venue_id
      ? onClickOnAvatar(post.venue_id.url, true)
      : undefined;

  return (
    <>
      <Grid key="post" container className={classes.postOuter}>
        <Grid
          item
          xs={12}
          className={`${classes.postInner} ${
            hasRightBorder ? classes.borderRight : ''
          }`}
        >
          {!noProfile && (
            <Grid
              item
              xs={12}
              className={classes.avatar}
              onClick={post.venue_id ? onClickOnVenue : onClickOnUser}
            >
              {!post.venue_id && (
                <>
                  <UserPicture
                    user={post.created_by}
                    style={{ float: 'left', marginRight: 10 }}
                  />
                  <div className={classes.user}>
                    {post.created_by.firstName && post.created_by.lastName && (
                      <Title className={classes.userName}>
                        {post.created_by.firstName} {post.created_by.lastName}
                      </Title>
                    )}
                    <SubTitle className={classes.userLocation}>
                      {generateLocation(post.created_by)}
                    </SubTitle>
                  </div>
                </>
              )}
              {post.venue_id && (
                <>
                  <Avatar
                    alt={`${post.venue_id.name}`}
                    src={getMediumImageUrl(post.venue_id.profilePicture)}
                    style={{ float: 'left', marginRight: 10, marginLeft: 16 }}
                  />
                  <div className={classes.user}>
                    {post.venue_id.name && (
                      <Title className={classes.userName}>
                        {post.venue_id.name}
                      </Title>
                    )}
                    <SubTitle className={classes.userLocation}>
                      {generateCityLocation(post.venue_id.location)}
                    </SubTitle>
                  </div>
                </>
              )}
            </Grid>
          )}
          <Grid
            item
            className={classes.postContent}
            xs={12}
            style={{ height: landingPage ? '300px' : '450px' }}
          >
            {content}
          </Grid>
        </Grid>
      </Grid>
      <div key="line" className={classes.lineBottomWrapper}>
        <div className={classes.lineBottom} />
      </div>
    </>
  );
};

export default PostCover;
