import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { IPost } from '../../types/posts';
import { compose } from 'recompose';
import { Divider } from '@mui/material';
import { IComment } from '../../types/comments';
import Loading from '../../components/loading/Loading';
import Comment from '../../components/comment/Comment';
import CommentForm from './CommentForm';
import { IUser } from '../../types/user';
import Title from '../../library/dataDisplay/typography/Title';
import Caption from '../../library/dataDisplay/typography/Caption';
import { withRouter, RouteComponentProps } from 'react-router';
import { useState } from 'react';
import * as commentActions from '../../store/modules/comments/actions';
import * as _ from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '../../store';

export interface IPostCommentsProps {
  post: IPost;
  user: IUser | undefined;
  onComment: (n: number) => void;
}

const style = createStyles({
  root: {},
  comments: {
    padding: '20px 0',
  },
});

type Props = IPostCommentsProps & WithStyles<string> & RouteComponentProps<{}>;

const PostComments: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { comments, isLoading, error } = useSelector(
    (storeState: IStoreState) => storeState.comments
  );

  const { post, user, classes } = props;

  const [editing, setEditing] = useState<undefined | string>(undefined);

  const fetchComments = () => {
    if (props.post) {
      dispatch(commentActions.getComments(props.post.id));
    }
  };

  useEffect(() => {
    window.scroll({ top: 0 });
    fetchComments();
  }, [props.post]);

  const onEdit = (comment: IComment) => () => {
    setEditing(comment.id);
  };

  const onCreate = () => {
    setEditing(undefined);
    props.onComment(comments.length);
  };

  const onDone = (comment: IComment) => {
    setEditing(undefined);
  };

  const onDelete = () => {
    props.onComment(comments.length);
  };

  const onUpdate = () => {
    setEditing(undefined);
  };

  let additionalContent: React.ReactNode = <div />;

  if (isLoading) {
    additionalContent = <Loading />;
  }

  if (!_.isEmpty(error)) {
    additionalContent = <div>An unexpected error has happened</div>;
  }

  if (comments && comments.length) {
    additionalContent = comments.map((c, i) => {
      const additionalButton =
      user && c.created_by.id === user.id ? (
          <Caption>
            <div
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={onEdit(c)}
            >
              Edit Comment
            </div>
          </Caption>
        ) : (
          <div />
        );
      const isLast = i + 1 === comments.length;
      const onCLick = () =>
        props.history.push(`/users/${c.created_by.id}/posts`);
      return (
        <div key={c.id}>
          {editing === c.id ? (
            <CommentForm
              post={post}
              user={user}
              initialValues={c}
              onCreate={onCreate}
              onUpdate={onUpdate}
              onDelete={onDelete}
              onDone={onDone}
            />
          ) : (
            <Comment
              comment={c}
              additionalButton={additionalButton}
              onClick={onCLick}
            />
          )}
          {!isLast && <Divider />}
        </div>
      );
    });
  }

  return (
    <div className={classes.comments}>
      {comments.length > 0 && (
        <div>
          <Title>
            {comments.length > 0
              ? `${comments.length} Comment${comments.length > 1 ? 's' : ''}`
              : ''}
          </Title>
          <Divider />
        </div>
      )}
      {user && user.profile !== 'VIEWER' && (
        <div style={{ padding: '15px 0px' }}>
          <CommentForm
            post={post}
            user={user}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onDone={onDone}
          />
        </div>
      )}
      {(comments.length > 0 || user?.profile !== 'VIEWER') && <Divider />}
      {additionalContent}
    </div>
  );
};

export default compose<Props, IPostCommentsProps>(
  withRouter,
  withStyles(style)
)(PostComments);
