import { ErrorType, ReqOptions, ResOptions, Route } from 'typesafe-api';
import {
  ArtpoolApiEndpoint,
  DefaultErrorCodes,
  JsonNft,
  JsonNftWithRawFile,
} from '@acg/artpool-api-spec';
import { IFundraisingProject, Stakeholder } from '@acg/backend-database-models';
import { CreateProjectData } from '@acg/shared/interfaces';

export enum ProjectEvmStatus {
  NOT_DEPLOYED = 'not-deployed',
  DEPLOYING = 'deploying',
  DEPLOYED = 'deployed',
  FAILED = 'failed',
}

export interface JsonUserOrVenue {
  name: string;
  profilePicture: string;
  acgPostsUrl: string;
  type: string;
  bio: string;
  location: string;
  _id: string;
}

export interface JsonSpecialOffer {
  body: string;
}

export interface JsonImage {
  src: string;
  label: string;
}

export interface Resources {
  image: JsonImage;
  link: string;
}

export interface JsonProject {
  _id: string;
  title: string;
  description: string;
  preSaleDate: string;
  startDate: string;
  endDate: string;
  organizers: JsonUserOrVenue[];
  nfts: JsonNft[];
  about: string;
  specialOffer?: JsonSpecialOffer;
  images: JsonImage[];
  resources: Resources[];
  membershipProject: boolean;
  membershipExclusive: boolean;
  soldOut: boolean;
  crossMintClientId: string;
  highlightedOpts?: {
    text: {
      title: string;
      subtitle: string;
    };
    banner: {
      desktopImage: JsonImage;
      ipadImage?: JsonImage;
      mobileImage: JsonImage;
    };
  };
  editions: {
    sold: number;
    maximum: number;
  };
  evm: {
    symbol: string;
    status: ProjectEvmStatus;
    address?: string;
  };
  updatedAt: string;
  collectionLocId?: string;
}

export interface JsonSuperAdminProject extends JsonProject {
  nfts: JsonNftWithRawFile[];
  stakeholders: Stakeholder[];
  etherscan: {
    verifyCmd: {
      loading?: boolean;
      cmd?: string;
    };
  };
}

export interface GetProjectOptions {
  live?: boolean;
  highlighted?: boolean;
}

export interface UpdatedProjectOptions {
  cross_mint_client_id?: string;
  about?: string;
  collectionLocId?: string;
  images?: JsonImage[];
  highlightedOpts?: {
    text: {
      title: string;
      subtitle: string;
    };
    banner: {
      desktopImage: JsonImage;
      ipadImage?: JsonImage;
      mobileImage: JsonImage;
    };
  };
}

/**
 * Get Projects
 */

export const getProjectsRoute: Route = {
  method: 'get',
  path: '/project',
};

export interface GetProjectsReq extends ReqOptions {
  query: GetProjectOptions;
}

export interface GetProjectsResp extends ResOptions {
  body: JsonProject[];
}

export type GetProjectsEndpointDef = ArtpoolApiEndpoint<
  GetProjectsReq,
  GetProjectsResp
>;

/**
 * Get Project
 */

export const getProjectRoute: Route = {
  method: 'get',
  path: '/project/:id',
};

export interface GetProjectReq extends ReqOptions {
  params: {
    id: string;
  };
}

export interface GetProjectResp extends ResOptions {
  body: JsonProject;
}

export type GetProjectEndpointDef = ArtpoolApiEndpoint<
  GetProjectReq,
  GetProjectResp,
  ErrorType<DefaultErrorCodes | 404>
>;

/**
 * Get Super Admin Project
 */

export const getSuperAdminProjectRoute: Route = {
  method: 'get',
  path: '/super-admin-project/:id',
};

export interface GetSuperAdminProjectResp extends ResOptions {
  body: JsonSuperAdminProject;
}

export type GetSuperAdminProjectEndpointDef = ArtpoolApiEndpoint<
  GetProjectReq,
  GetSuperAdminProjectResp,
  ErrorType<DefaultErrorCodes | 404>
>;

/**
 * Featured projects
 */

export const getFeaturedProjectsRoute: Route = {
  method: 'get',
  path: '/project/featured',
};

export interface GetFeaturedProjectsReq extends ReqOptions {
  query: {
    limit?: number;
    skip?: number;
  };
}

export interface GetFeaturedProjectsResp extends ResOptions {
  body: JsonProject[];
}

export type GetFeaturedProjectsEndpointDef = ArtpoolApiEndpoint<
  GetFeaturedProjectsReq,
  GetFeaturedProjectsResp
>;

/*
 * Get collection contract deployment params,
 * this is useful to verify the contracts on etherscan
 */

export const buildEtherscanVerifyCmdRoute: Route = {
  method: 'post',
  path: '/project/:id/etherscan/verify-cmd/build',
};

export interface BuildEtherscanVerifyCmdReq extends ReqOptions {
  params: {
    id: string;
  };
}

export interface BuildEtherscanVerifyCmdResp extends ResOptions {
  body: {
    loading: true;
  };
}

export type BuildEtherscanVerifyCmdEndpointDef = ArtpoolApiEndpoint<
  BuildEtherscanVerifyCmdReq,
  BuildEtherscanVerifyCmdResp,
  ErrorType<500 | 400>
>;

/**
 * Deploy project to marketplace
 */
export const deployProjectRoute: Route = {
  method: 'post',
  path: '/project/:id/deploy',
};

export interface DeployProjectReq extends ReqOptions {
  params: {
    id: string;
  };
}

export interface DeployProjectResp extends ResOptions {
  body: JsonProject;
}

export type DeployProjectEndpointDef = ArtpoolApiEndpoint<
  DeployProjectReq,
  DeployProjectResp,
  ErrorType<DefaultErrorCodes | 404 | 400>
>;

export const updateProjectRoute: Route = {
  method: 'put',
  path: '/project/:id',
};

export interface UpdateProjectReq extends ReqOptions {
  params: {
    id: string;
  };
  body: Partial<IFundraisingProject>;
}

export interface UpdateProjectResp extends ResOptions {
  body: JsonProject;
}

export type UpdateProjectEndpointDef = ArtpoolApiEndpoint<
  UpdateProjectReq,
  UpdateProjectResp,
  ErrorType<DefaultErrorCodes | 404 | 400>
>;

export const createProjectRoute: Route = {
  method: 'post',
  path: '/project',
};

export interface CreateProjectReq extends ReqOptions {
  body: CreateProjectData;
}

export interface CreateProjectResp extends ResOptions {
  body: JsonProject;
}

export type CreateProjectEndpointDef = ArtpoolApiEndpoint<
  CreateProjectReq,
  CreateProjectResp,
  ErrorType<DefaultErrorCodes | 404 | 400>
>;
