import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';

import {
  Grid,
  Dialog,
  Backdrop,
  Typography,
  IconButton,
  Button,
  DialogContent,
  DialogActions,
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { SPACING } from '../../styles/constants';
import { IArtistUser, ICuratorUser } from '../../types/user';

const style = createStyles({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  titleContainer: {
    padding: SPACING,
  },
  button: {
    padding: 25,
    borderRadius: 100,
    marginBottom: 20,
  },
  buttonSmall: {
    padding: '7px 20px',
    minHeight: 'auto',
    borderRadius: 30,
    display: 'inline-block',
    width: 'auto',
    marginLeft: 5,
    fontSize: '0.6em',
    minWidth: 0,
  },
  closeButton: {
    position: 'absolute',
    right: '2px',
    top: '2px',
  },
});

interface IModalAcceptUserProps {
  onAccept: () => void;
  onClose: () => void;
  isOpen: boolean;
  user?: ICuratorUser | IArtistUser;
}

type props = IModalAcceptUserProps & WithStyles<string>;

const ModalAcceptUser: React.FC<props> = (Props) => {
  const { onAccept, isOpen, onClose, user, classes } = Props;

  let userName: string = '';
  let profile: string = 'CURATOR';
  if (user) {
    userName = `${user.firstName ? user.firstName : ''} ${
      user.lastName ? user.lastName : ''
    }`;
    profile = user.profile;
  }

  const handleOnClick = () => onAccept();

  return (
    <Dialog open={isOpen} onClose={onClose} BackdropComponent={Backdrop}>
      <MuiDialogTitle>
        <Typography variant="h6">Accept profile</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent>
        <p>
          Are you sure you want to accept {userName} as {profile}?
        </p>
      </DialogContent>
      <DialogActions>
        <Grid container={true} justifyContent="flex-end" xs={12}>
          <Button
            variant="outlined"
            size="large"
            color="secondary"
            aria-label="Add"
            className={classes.buttonSmall}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            aria-label="Add"
            className={classes.buttonSmall}
            onClick={handleOnClick}
          >
            Accept
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default compose<props, IModalAcceptUserProps>(
  withStyles(style),
  withRouter
)(ModalAcceptUser);
