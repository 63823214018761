import { AuthApiClient } from '@acg/shared/api-spec-utils';
import { createRouteRequest } from 'typesafe-api';
import {
  GetPostsEndpointDef,
  getPostsRoute,
} from '@acg/artcuratorgrid-api-spec';

export class PostsClient extends AuthApiClient {
  private _getPosts = createRouteRequest<GetPostsEndpointDef>(
    this,
    getPostsRoute
  );

  public getPosts = () =>
    this._getPosts({
      query: {
        first_result: 1,
        max_results: 6,
        status: 'PUBLISHED',
        ignoreArtistPosts: true,
        ignoreInstitutionPosts: true,
      },
    });
}
