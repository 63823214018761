import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { useState } from 'react';
import {} from '@mui/material';
import { compose } from 'recompose';
import { PRIMARY_COLOR } from '../../styles/constants';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import copyRight from '../../assets/ARTPOOL_ATHORIZATIONFORM_COPYRIGHT.6b95a683.pdf';

export interface IPrivacyPolicyProps {
  content: string;
}

type Props = IPrivacyPolicyProps & WithStyles<string> & WithWidth;

const style = createStyles({
  sectionDesktop: {
    padding: '50px 0',
  },
  sectionMobile: {
    padding: '10px 0',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: PRIMARY_COLOR,
  },
  link: {
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    cursor: 'pointer',
    color: 'black',
    '&:hover': {
      color: PRIMARY_COLOR,
    },
  },
  border: {
    borderTop: `1px solid ${PRIMARY_COLOR}`,
  },
});

const LegalContent: React.FC<Props> = (props) => {
  const { classes, width } = props;

  const isMobile = isWidthDown('sm', width);
  const [showData, setShowData] = useState<number>(0);

  const privacyContent = [
    {
      title: 'Hello',
      text: (
        <React.Fragment>
          Thanks for choosing Artpool! We would be happy if you stay
          with us for longer than only one visit. But first, you must read our
          privacy policy, something so important for us as for your experience
          here. <br />
          <br />
          At Artpool, you can shape and follow your art preferences, as
          well as develop your art connections through the first social art
          network. For this reason, it is extremely crucial that you understand
          how, when and why we address your personal information and online
          activity, so we are able to provide a personalised service especially
          for you. <br />
          <br />
          Personal data relates to an individual who can be identified from that
          data. This privacy policy gives you the basis on which we collect and
          process your personal data through your use of our website (“site”),
          subscribing newsletters, email updates, receiving event invitations,
          registering and logging in, editing your profile, uploading content,
          and connecting to and chatting with other users. By visiting our site,
          you are accepting and consenting to the practices described in this
          policy.
        </React.Fragment>
      ),
    },
    {
      title: 'Who we are',
      text: (
        <React.Fragment>
          We are PWA Art Corporation, Lda., trading as Artpool, and we
          aim to express our commitment with data protection. <br />
          <br />
          Launched in 2019, Artpool is the first digital platform that
          connects an interactive, global community of curators, artists and
          institutions, to share, connect and discover Contemporary Art. Art
          Curator Grid’s growing community works together to make exhibitions
          and projects a reality through engaging content, intuitive software
          and access to global audiences. The centralised resource is a place
          where curators can build and present their work, and institutions can
          effectively manage, promote and archive their programming. In
          conjunction with the platform, Artpool launched{' '}
          <a
            href={'https://curtain.artcuratorgrid.com/'}
            className={classes.link}
          >
            Curtain
          </a>
          , a new online independent magazine, which spotlights curators every
          other month.
          <br />
          <br />
          We are fully committed to respecting your privacy and value the trust
          you place in us when you share information with us through the site.
          This privacy policy sets out how we collect, hold, use, process and
          disclose your personal data, why we process it, with whom and for what
          purposes we share it, the rights to which you may be entitled and your
          choices about our use of your personal information. This privacy
          policy also covers our use of your personal information arising from
          the regular use of the site, alongside with the registering,
          subscribing, buying and/ or using activities related to our products
          and services provided in the site.
          <br />
          <br />
          PWA Art Corporation, Lda. is responsible for your personal data in
          this privacy policy. Our registered office address is Rua da Lapa,
          126, 1200-061 Lisboa, and our Portuguese VAT number is 515531979. If
          you have any queries regarding this privacy policy, the use of your
          personal data or any concern related to the site, please contact us at{' '}
          <a href="mailto:pauline@artcuratorgrid.com" className={classes.link}>
            pauline@artcuratorgrid.com
          </a>
          .
        </React.Fragment>
      ),
    },
    {
      title: 'Your rights',
      text: (
        <React.Fragment>
          As the GDPR added several rights in relation to personal data of
          individuals, we have introduced additional transparency to our site in
          order to help our users take advantage of those rights. <br />
          <br />
          If you are a European Union resident, and unless subject to an
          exception under the GDPR, you have the following rights in respect of
          your personal data:
          <br />
          <ul>
            <li>
              Right of access – the right to request a copy of your personal
              data which we hold;
            </li>
            <li>
              Right to rectification – the right to request the correction of
              any personal data that it is found to be inaccurate or out of
              date;
            </li>
            <li>
              Right to erasure – the right to request that we delete your
              personal data;
            </li>
            <li>
              Right to restrict – the right to request a temporary or permanent
              restriction in relation to the processing of some or all your
              personal data;
            </li>
            <li>
              Right to object – the right to object to the processing of
              personal data when it is linked to direct marketing;
            </li>
            <li>
              Right to complain – the right to lodge a complaint with the
              Founder & Director using the contacts set out below;
            </li>
            <li>
              Right to data portability – the right to request a copy of the
              data subject with your personal data held by the data controller
              in order to transmit the data to another data controller;
            </li>
            <li>
              Right to withdrawal of consent – the right to unsubscribe our
              newsletter at the bottom of each email received or by emailing us
              as set out below.
            </li>
          </ul>
          Normally, you do not need to pay any fee to have access to your
          personal data nor to exercise any of the above-described rights. We
          will only charge a fee if your request has unfounded, repetitive or
          excessive results.
          <br />
          <br />
          If you are not satisfied with the information provided in this privacy
          policy in relation to your rights or want to find out more about the
          rights covered by the GDPR and described above, please contact our
          Founder & Director through the following channel{' '}
          <a href="mailto:pauline@artcuratorgrid.com" className={classes.link}>
            pauline@artcuratorgrid.com
          </a>
          . We will try to resolve your concern within a reasonable period of
          time that it is up to ten working days, after verifying your identity.
          <br />
          <br />
          As you might be aware, the activity online is not completely secure
          yet and any transmission of information has its own risk. However, we
          do our best to prevent any inconvenience related to the use of the
          website by maintaining strict procedures.
        </React.Fragment>
      ),
    },
    {
      title: 'What personal data we may collect from you',
      text: (
        <React.Fragment>
          When you use our site, you may provide us information about you by
          filling in various forms through the site. When you sign up, edit your
          profile and preferences, subscribe to our services, connect with
          others or simply search something, you are providing us information
          about you. We may also collect information about you on Art Curator
          site based on your use and activity.
          <br />
          <br />
          Any information that we may receive from other sources, such as a
          partner that we work closely with and a third party that provides
          services for us, will also be used and held by us.
          <br />
          <br />
          We will not use your profile photos or any images posted or uploaded
          by you into the platform, for any purposes, without your consent.
          <br />
          <br />
          Your billing details will only be collected according to our payment
          partners’ privacy policies.
          <br />
          <br />
          <div style={{ paddingLeft: isMobile ? 50 : 100 }}>
            <h3>
              <strong>4.1. You are an art curator</strong>
            </h3>
            If you are an art curator registered at Artpool, we will
            hold, at a first moment, your name and your contact details. Later,
            we will ask you further information about yourself, as questions
            which will let us know your art preferences.
            <br />
            <br />
            When you login your profile and use certain parts of the site that
            are for your exclusive use, you are also providing personal data to
            the site; when you connect or follow another registered art curator
            at Artpool, you are giving information about you to us.
            <br />
            <br />
            If you subscribe to a paid service at Artpool, such as the
            Membership, we will collect your payment details, and our payment
            partners will do the same, to the extent necessary to process such
            transactions.
            <br />
            <br />
            <h3>
              <strong>4.2. You are not an art curator</strong>
            </h3>
            If you are not an art curator and are registered at our platform, we
            will also collect information about you based on what your searches
            and your use of the site are. As a visitor of the site, you will use
            cookies that will help your experience online. This will essentially
            help us to improve our services.
          </div>
        </React.Fragment>
      ),
    },
    {
      title: 'How we collect personal data about you',
      text: (
        <React.Fragment>
          Your personal data may be collected through the following ways:
          <br />
          <br />
          <div style={{ paddingLeft: isMobile ? 50 : 100 }}>
            <strong>5.1.</strong> Either it is voluntarily provided by you (via
            our site or our social media pages) or it can be obtained by us when
            we request any information to you by telephone or by email;
            <br />
            <strong>5.2.</strong> We collect it when you sign up for Art Curator
            Grid and every time you use it after that moment;
            <br />
            <strong>5.3.</strong> It is collected by third parties that provide
            applications and services for us, using cookies, such as Google
            Analytics, Hubspot, Amplitude and Hotjar. All third parties’
            applications having cookies are collecting, processing and storing
            activities.
            <br />
          </div>
          We may use cookies to collect information about you. Cookies are small
          text files that store Internet settings from the websites you visit
          and are widely used to make sites’ features work, operate more
          efficiently and to improve your experience on the site. To find out
          more about data processing companies that provide services to us using
          cookies, please see our cookie policy.
        </React.Fragment>
      ),
    },
    {
      title: 'How we process your personal data',
      text: (
        <React.Fragment>
          Concerning the processing of data, we take our obligations under the
          GDPR seriously. We keep personal data up to date, retain a minimum
          amount of data, and protect personal data from loss, misuse,
          unauthorised access and disclosure. We try our best to ensure that
          appropriate technical measures are put in place to protect your
          information.
          <br />
          <br />
          We need to collect, store and use your personal data mainly for the
          following purposes:
          <ul>
            <li>
              To administer our business, especially for users’ profiles and
              connections purposes;
            </li>
            <li>
              To allow access to the site, when subscribing, registering, using
              and buying our products and services;
            </li>
            <li>To deal with any queries that we receive from you;</li>
            <li>
              To maintain our own accounts and records and to analyse our sales;
            </li>
            <li>To prevent and detect fraud;</li>
            <li>To meet our legal and accounting obligations;</li>
            <li>
              To carry out our business, including maintain site’s security;
            </li>
            <li>
              To fulfil any other purpose that may occur – and in this case, we
              will make you know – or that are set out in other sections or on
              our Terms of Use.
            </li>
          </ul>
          In the processing of your information, we use MongoDB Atlas, a
          software of MongoDB Inc. USA, for managing and processing data in our
          cloud servers. All the processed data is included in our database.
          MongoDB Atlas is contractually forbidden to sell any of the data that
          have been collected on our behalf. For further details about this
          software, please see the Mongo DB Atlas Legal Notices section. We also
          use Forest Admin, a software developed by Forest Admin Inc. USA for
          viewing our database. This software is contractually forbidden to sell
          any data from the data collected on our behalf. If you intend to know
          more about Forest Admin, please see their Privacy Policy.
          <br />
          <br />
          To find out about all data processing companies using cookies, please
          read our{' '}
          <Link
            to={'#'}
            className={classes.link}
            // onClick={openConsentManagerDialog}
          >
            cookie policy
          </Link>
          .<br />
          <br />
          The processing of personal data is governed and in accordance with the
          European Union law, in particular with the General Data Protection
          Regulation (the “GDPR”).
        </React.Fragment>
      ),
    },
    {
      title: 'How long we keep your personal data for',
      text: (
        <React.Fragment>
          We will only retain your personal data for as long as necessary to
          fulfil the purposes we have collected it for, including for the
          purposes of satisfying any legal, accounting or reporting
          requirements.
          <br />
          <br />
          We are not able to determine with certainty our average retention
          period for personal data. We consider several aspects such as the
          amount of data, its nature, the sensitivity of the personal data, and
          the potential risk of harm from unauthorised use or disclosure of your
          personal data. Additionally, your personal data can be used by us for
          legal purposes and, for this reason, they might stay with us longer.
          <br />
          <br />
        </React.Fragment>
      ),
    },
    {
      title: 'Where we store your personal data',
      text: (
        <React.Fragment>
          Part of the data we collect from you is processed and stored by Art
          Curator Grid.
          <br />
          <br />
          All data about you is stored since the very first moment on our
          servers, which are located in the European Union and have ultimate
          safety innovations. All collected data is stored in Google Cloud
          Platform and all images are stored in Amazon Web Services.
          <br />
          <br />
          We also use other third parties’ applications and websites to store
          personal information about you.
          <br />
          <br />
          We use Hubspot, a software of Hubspot Inc. USA, for managing our
          customer relationship activities. We store information into Hubspot
          (e.g. email addresses) in order to provide you with the best customer
          service. For further details on the functioning of this software,
          please see the Hubspot’s{' '}
          <a
            href={'https://legal.hubspot.com/privacy-policy'}
            className={classes.link}
          >
            Privacy Policy
          </a>
          .<br />
          <br />
          We also use Mailchimp, a software of The Rocket Science Group, USA,
          for newsletters activities, where we store information, including
          subscribers’ email addresses and names. Mailchimp is contractually
          forbidden to sell any data of the data collected on our behalf. If you
          would like further information on Mailchimp’s{' '}
          <a href={'https://mailchimp.com/legal/'} className={classes.link}>
            Legal Policies
          </a>
          , please click here.
          <br />
          <br />
          We use Stripe, a payment technology provided by Stripe, Inc., to
          process your payments of the subscription of our Membership. For
          further information about this software, please read their{' '}
          <a href={'https://stripe.com/en-pt/privacy'} className={classes.link}>
            Privacy Policy
          </a>
          .
        </React.Fragment>
      ),
    },
    {
      title: 'What information we might share about you',
      text: (
        <React.Fragment>
          At Artpool, we might need to share personal data held about
          you. Our system of disclosure of personal information is built in a
          strict form and it only allows the share of information in the cases
          described in this privacy policy.
          <br />
          <br />
          Within Artpool, we may need to share personal information for
          legitimate purposes only.
          <br />
          <br />
          As a general principle, we will not share your personal data with
          third parties without your permission. However, it can happen that
          there are third parties interested in contacting you due to your art
          services or regarding your work in general. In case of these third
          parties are collectors, or analytics, we may share your personal
          information with these third parties, always after a careful selection
          and your consent.
          <br />
          <br />
          We develop our site alongside a professional freelancer who provides
          IT and similar services to us. This person meets the requirements of
          the GDPR and has access to your personal information, as well as to
          the whole information registered on the site of Artpool.
        </React.Fragment>
      ),
    },
    {
      title: 'Changes to our privacy policy',
      text: (
        <React.Fragment>
          Any changes we may make to our privacy policy in the future will be
          posted on this page. We recommend that you check back regularly in
          order to get updates or see modifications regarding this privacy
          policy.
          <br />
          <br />
          For the ones who are registered on our site, you will receive an email
          putting you updated about these further changes, if significant to the
          use of the website.
        </React.Fragment>
      ),
    },
    {
      title: 'How to contact us',
      text: (
        <React.Fragment>
          If you would like more information about how we manage personal data
          that we hold about you or have questions, comments and requests
          regarding this privacy policy please contact us as set out below.
          <br />
          <br />
          In case you are concerned that we may have breached your privacy and
          wish to make a complaint, you should address it to{' '}
          <a href="mailto:pauline@artcuratorgrid.com" className={classes.link}>
            pauline@artcuratorgrid.com
          </a>
          . We will do our best to resolve your complaint within a reasonable
          period of time.
          <br />
          <br />
          <div>
            CONTACT
            <br />
            PWA Art Corporation, Lda
            <br />
            <a
              className={`${classes.link}`}
              href={'https://goo.gl/maps/qjqTkYUpfssVVPjW8'}
            >
              Rua da Lapa, 126, 1200-061 Lisboa
            </a>
            <br />
            <a
              className={`${classes.link}`}
              href="mailto:pauline@artcuratorgrid.com"
            >
              pauline@artcuratorgrid.com
            </a>
            <br />
            All content 2021 © by PWA Art Corporation, Lda, trading as Artpool.
            <br />
            All rights reserved.
          </div>
        </React.Fragment>
      ),
    },
  ];
  const conditionsContent = [
    {
      title: 'Introduction',
      text: (
        <React.Fragment>
          Launched in 2019, Artpool is the first digital platform that
          connects an interactive, global community of curators, artists and
          institutions, to share, connect and discover Contemporary Art. Art
          Curator Grid’s growing community works together to make exhibitions
          and projects a reality through engaging content, intuitive software
          and access to global audiences. The centralised resource is a place
          where curators can build and present their work, and institutions can
          effectively manage, promote and archive their programming. In
          conjunction with the platform, Artpool launched Curtain, a
          new online independent magazine, which spotlights curators every other
          month. From the moment you access, register and use our website, you
          are agreeing to these terms and conditions, to our privacy policy and
          to any other terms to be referenced in this document, which may be
          supplemental and may become available further on, subject to notice by
          us. In addition, whenever you contact other profiles, create an event,
          message or receive a message from any profile, post or share content,
          or even pick your favourite hashtags or identify yourself and your
          interests, you accept our rules and management surrounding your use of
          the site. If you do not agree to a part of these terms of use, or any
          other terms applicable to ACG users, please do not click in any of the
          following expressions: “Sign Up”, “Log In”. This website is operated
          by PWA Art Corporation, Lda., trading as Artpool, and these
          terms and conditions apply to every user. We are registered in
          Portugal under Portuguese VAT number 515531979 and have our registered
          office at Rua da Lapa, 126, 1200-061 Lisboa.
        </React.Fragment>
      ),
    },
    {
      title: 'Using Artpool',
      text: (
        <React.Fragment>
          This paragraph sets out who can use our website and provides you a few
          guidelines to help you start.
          <br />
          <br />
          You must be 16 years or older in order to use and/or apply to the
          website yourself. However, with the consent of a parent or guardian,
          you may be 13 years or older. In such a case, it is our requirement
          that the parent or guardian applies on behalf of you and send us a
          message to{' '}
          <a className={classes.link} href="mailto:support@artcuratorgrid.com">
            support@artcuratorgrid.com
          </a>{' '}
          to inform us that the person who will use the website is a minor who
          is at least 13 years old.
          <br />
          <br />
          Although our social media platform is dedicated to art curators and
          institutions, and they are our main target public, we would love to
          have all art professionals and art lovers with us. The more people we
          are, the more quality we will be able to provide through our platform.
          Therefore, no matter whether you are an art curator, a professional
          working for a venue or an institution, an artist, a journalist, or an
          art lover, you may register and start using our website and our
          profile tools.
          <br />
          <br />
          You may register at our website by your own or you may ask for our
          help to create the login. In the latter case, the login can be created
          during a phone call between our team and you, when you provide us your
          personal details orally, or by sending us such details in written by
          email. If your account is created with us, orally or upon written
          request, our team will then send you an email to reset your password.
          <br />
          <br />
          Once you apply and conclude your registration at Artpool, you
          will have a password. This password should be kept confidential, and
          you shall not share this access with anyone else. The password will be
          associated with the email address that was used to apply to the
          platform, so in the event you do not remember it, you can easily
          change it.
        </React.Fragment>
      ),
    },
    {
      title: 'Your content',
      text: (
        <React.Fragment>
          You agree and accept that everything you post or share, together with
          all of your personal details and your activity online makes up your
          content on Artpool.
          <br />
          <br />
          If you are an art curator looking to apply to our website, you will
          need to register with your name and surname, the institution to which
          you belong, and your city and country.
          <br />
          <br />
          If you are an institution or an art gallery, you may create and add
          content to your venue’s profile. In this case, please have in mind
          that you can allow people to manage the profile, such as art curators,
          your staff members and other users of our website, whenever they are
          involved in your organization and/or are part of your programs and
          exhibitions to be promoted.
          <br />
          <br />
          Otherwise, if you are an artist, an art consultant, an art dealer, a
          collector, a journalist, an art lover, or you work for an art
          institution, an art gallery, an art fair, an auction house, you will
          need to provide us with your name, category and your location. In
          either case, you have the option to add a biography to your profile
          consisting of pictures and texts.
          <br />
          <br />
          By default, the profile page of registered users is visible by anyone
          who has the link of the page event if that person does not have an
          account at Artpool and that profile page is indexed on
          Google. At any time, users can change this in profile settings by
          making their pages visible to Artpool users only and removing
          the Google indexing.
          <br />
          <br />
          If your account is public, please be aware that profile images are
          public into the Internet as people can search for and find public
          profile pages. If you have a private account, profile images and posts
          will not appear as public on the Internet. You can change the privacy
          of your profile at any time.
          <br />
          <br />
          You are not allowed to use, store, copy, display, reproduce,
          redistribute, transfer or make available to the public any part of the
          Artpool website or its content. You are also not allowed to
          create untrue or fake content, nor promote false information of any
          kind.
          <br />
          <br />
          As a social platform, we strongly condemn any offensive, abusive,
          obscene or illegal material, and we take this opportunity to ask you
          to be respectful with other users’ content. Artpool expects
          everyone to contribute to a healthy online environment and is
          particularly committed to your content’s safety.
          <br />
          <br />
          We reserve the right to suspend or terminate your account in the case
          of violation of our terms and guidelines that have been put in place
          to promote the safe and respectful use of our website. You may also be
          subject to suspension or termination if you are responsible for the
          creation of a fake event or profile, if you disseminate fake content
          about other users or modify other users’ profile photos after
          downloading them, without having their consent.
          <br />
          <br />
          However, we do not have any control or play any role around whether
          other users view, use or save the content you make available to the
          public, and, therefore, do not assume any liability around the use and
          re-sharing of publicly available information, including images. For
          this purpose, you grant Artpool and ACG’s users a
          non-exclusive, royalty-free, worldwide license to use, store, copy,
          display, reproduce, distribute, transfer and modify derivative works.
          By using our website, you accept and recognize that this is a
          possibility subject to any online content.
          <br />
          <br />
          You are able to add, edit and modify your personal data at any time.
          The collecting, processing and storing of your personal data is
          subject to the rules of the GDPR. Artpool and the outsourced
          freelance IT developer have entered into a binding contract, which
          ensures that the personal data of the users of this website will be
          kept strictly confidential. Artpool and the outsourced
          freelance IT developer are the exclusive and only ones who have access
          to your personal data, besides the third parties whose applications
          are used in the development of this website.
          <br />
          <br />
        </React.Fragment>
      ),
    },
    {
      title: 'Messaging',
      text: (
        <React.Fragment>
          Artpool provides a messaging service, and you agree by these
          terms to accept our rules when you use it to connect to another
          profile. Our messaging system includes sending and receiving messages
          that may include texts and pictures, and it allows you to start a
          conversation with any profile.
          <br />
          <br />
          In case you are a registered art curator, you can simply start a
          conversation with another art curator registered in this website,
          one-to-one. We do not take any liability for these messages shared
          between art curators.
        </React.Fragment>
      ),
    },
    {
      title: 'Our service',
      text: (
        <React.Fragment>
          At Artpool, we offer various services to art curators and art
          institutions, and other users.
          <br />
          <br />
          Our services include different programs targeting both art curators
          and non-art curators.
          <br />
          <br />
          If you are an art curator, we are able to help you to find new
          projects.
          <br />
          <br />
          If you are not an art curator, we can help you to find and reach art
          curators for new exhibitions that you would like to promote, create or
          announce. We also help you to find turnkey exhibitions that have
          already been curated in other institutions.
          <br />
          <br />
          Whether you are an art curator or not, you may contact us through our
          service page and share the details of your project or your ambitions
          in your message. Once we receive and review your message, you will
          receive a personalised solution from us based on your objectives. If
          you agree to our service offering, you provide consent to receive a
          contract from us customised to your project.
          <br />
          <br />
          By agreeing to these terms and conditions, you accept and provide
          consent to be contacted by us. We will contact you to offer
          appropriate and relevant services after the evaluation of your profile
          and project ambitions.
          <br />
          <br />
          We also offer you an exhibition tool designed for curators and staff
          that manages a venue or an institution profile in our platform.
          <br />
          <br />
          The exhibition tool allows you to pick one of our three different
          programs on how to share your exhibition page or the exhibition page
          that you are managing to the public:
          <br />
          <br />
          <ul>
            <li>
              <strong>For Artpool users only:</strong> anyone who wants
              to see the exhibition page, should be signed up and logged in
              first to have full access to it;
            </li>

            <li>
              <strong>Publicly:</strong> anyone can access the exhibition page,
              which is indexed into Google and can be accessed by anyone from
              anywhere;
            </li>

            <li>
              <strong>Private link:</strong> the exhibition isn’t published at
              our website and isn’t visible when visiting the profile of the
              organizer or curator. Only the people who were provided with the
              link may be able to see the exhibition page, without having to be
              signed up or logged in.
            </li>
          </ul>
          While program 1 is free to everyone, our programs 2 and 3 are free to
          curators and non-profits venues only. Therefore, if you are a
          for-profit venue, you are able to get the programs 2 or 3, for either
          €125 per month or €1350 per year, with our Membership.
          <br />
          <br />
          As our Membership is a paid service, you can subscribe and unsubscribe
          at any time, but if you want to avoid future charges, you should do it
          before the start of the new period of subscription. However, if you
          have opted for the yearly subscription, and you cancel your
          subscription before the end of the year-period, you will not be
          refunded and will be able to use it until the end of it.
          <br />
          <br />
          To unsubscribe our Membership, you should edit your preferences on
          your profile page.
          <br />
          <br />
          If you have purchased one of the paid programs of our Membership, and
          cancel it after a while, your exhibition page will remain on the
          website, but the access will be limited to user only. You can delete
          your exhibition page, whilst on our Membership and after cancelling
          it, by submitting us a request.
          <br />
          <br />
          We use Stripe, a payment technology provided by Stripe Inc., to
          process all payment transactions in relation to the subscription of
          our Membership. For further information about this software, please
          read their{' '}
          <a href={'https://stripe.com/en-pt/privacy'} className={classes.link}>
            {' '}
            privacy policy
          </a>
          .<br />
          <br />
          Invoices of your monthly or yearly payments related to our Membership
          shall be sent to you by email.
          <br />
          <br />
          Please be aware that your purchases at our website may be subject to
          foreign exchange fees or differences in price based on your location.
          We may continue billing your payment method (e.g., credit card or
          debit card) even after it has expired.
          <br />
          <br />
          By purchasing a subscription, your payment method will be
          automatically charged at the start of each subscription period for the
          fees and taxes applicable to such period, until it is cancelled.
          <br />
          <br />
          In the event your subscription cannot be charged (e.g. your method
          payment has no funds), your current program will be downgraded for the
          program 1, and only Artpool users will be able to see your
          exhibition page).
          <br />
          <br />
          If a user of our website, who manages an exhibition or is a curator,
          is looking for specific features to be included in our Membership or
          other services, we may provide customised services for an additional
          price, to be agreed between the parties. In such a case, you should
          submit us a request.
          <br />
          <br />
          You agree that Artpool may change our provided services and
          prices from time to time. Whenever we set out new prices or
          subscription fees for our services, you will be given notice that the
          price changes will take effect at the start of the next subscription
          period. If you do not agree to a price change, or if you do not find
          our service interesting anymore, you have the right to reject the
          change prior to the price change entering into force by sending us a
          message in order to terminate this contract.
        </React.Fragment>
      ),
    },
    {
      title: 'Copyright',
      text: (
        <React.Fragment>
          All the artworks and projects shared in the website are subject to
          copyright.
          <br />
          <br />
          Registered users who upload profile images are entirely responsible
          for its own copyright and for obtaining any authorizations and
          licenses in relation to it as well as they are aware of the
          possibility of copyright breaching from any users of the website.
          Registered users may also be aware that even after the erasure of the
          account or the images, people may have saved their images in their own
          devices.
          <br />
          <br />
          Whenever art curators and staff managing a venue or an institution
          profile create an exhibition page at ACG, they can download our{' '}
          <a
            href={copyRight}
            className={classes.link}
            target={'_blank'}
            rel="noopener noreferrer"
            download={true}
            style={{ color: '#000' }}
          >
            authorisation form
          </a>{' '}
          that shall be signed by the copyright owner.
          <br />
          <br />
          You are not allowed to copy, reproduce, broadcast and repost any part
          of the Artpool website itself, including any kind of
          information, images, logos or photos, as well as the overall
          appearance of the site, without the prior written permission of the
          copyright holders, unless it will be for exclusive personal and
          non-commercial purposes. Copyright breaching from any users of the
          users may imply suspension or termination of your account.
          <br />
          <br />
          You also accept that you may not adapt, modify or create a derivative
          work from any content of the site, except if it is with respect to
          your exclusive personal and non-commercial use.
          <br />
          <br />
          Concerning the exhibition page tool and our Membership, all exhibition
          managers and curators have the right to publish all its content on our
          website.
          <br />
          <br />
          In the event of a copyright breach by a Membership subscriber, we
          reserve the right to terminate such subscription and delete the
          breaching content, and, in cases of serious breach, the account.
        </React.Fragment>
      ),
    },
    {
      title: 'Logos',
      text: (
        <React.Fragment>
          Our logo is designed by João Machado for Artpool and is
          property of PWA Art Corporation, Lda., trading as Artpool.
          All other trademarks and logos used and reproduced in this website are
          the trademarks or logos of their respective owners, including art
          curators, artists, brands and projects, and, therefore, are protected
          by copyrights.
        </React.Fragment>
      ),
    },
    {
      title: 'Linking to the Artpool website',
      text: (
        <React.Fragment>
          Artpool does not take any liability with respect to third
          parties’ websites linking to this website.
        </React.Fragment>
      ),
    },
    {
      title: 'Linking to other websites',
      text: (
        <React.Fragment>
          During your use of our website, you may be linked to other websites
          that are operated by third parties. We do not take any liability
          regarding the content of these websites nor for any consequences that
          may follow from these external links. By using this website, you are
          accepting that clicking on a third party link will be done at your own
          risk.
        </React.Fragment>
      ),
    },
    {
      title: 'Third parties’ applications',
      text: (
        <React.Fragment>
          If you would like to find out more about the third parties’
          applications and services that provide services to us, please read our
          privacy policy{' '}
          <Link className={classes.link} to={'/privacy-policy'}>
            {' '}
            here
          </Link>
          .
        </React.Fragment>
      ),
    },
    {
      title: 'Warranty disclaimer',
      text: (
        <React.Fragment>
          We do not take any responsibility for any content that any user or
          third parties post or send while using this website. You understand
          and accept that you may be exposed to content and messages that are or
          may be improper, inappropriate for children, inaccurate or incorrect.
          <br />
          <br />
          As long as we connect two registered users through the method
          described in paragraph 4, Artpool has significant influence
          on the establishing of a connection, but we do not in any case assume
          liability for the messages exchanged between them.
          <br />
          <br />
          Furthermore, with the possibility of posting, creating and sharing
          content at our platform, does not come a liability for any fake,
          false, incorrect or untrue names, profiles, images, events,
          exhibitions and news. We are not able to control every exhibition that
          may happen on an international scale and we do not have the conditions
          to confirm the real identity of every profile registered at our
          website.
          <br />
          <br />
          You are agreeing, however, that if you have created any fake
          exhibition or content, this may be removed, as well as with any other
          fake content that has been shared or joined by you.
          <br />
          <br />
          Additionally, we do not take any liability for any kind of content
          that may contain either violence or pornography, lead to racism or
          suicide, or use inappropriate language. You acknowledge and agree that
          Art Curator may remove this kind of content.
          <br />
          <br />
          This paragraph does not affect your category as a consumer, with the
          inherent rights set out in the applicable laws.
        </React.Fragment>
      ),
    },
    {
      title: 'Limitation of liability',
      text: (
        <React.Fragment>
          PWA Art Corporation, Lda., trading as Artpool takes no
          liability for any suspension of access to this website nor guarantee
          that the website will be uninterruptedly available.
          <br />
          <br />
          Due to the possible occurrence of the above mentioned events, for any
          unexpected reason or a situation related with technical problems, and
          in a force majeure situation, we do not have absolute control of this
          website and you agree that the use of this website is entirely at your
          own risk.
          <br />
          <br />
          Artpool will not be liable, in no event, for any damages
          whatsoever arising out of or relating to the use of the website.
          <br />
          <br />
          You accept and agree that if you remain unsatisfied with this
          paragraph, you should not use this website.
        </React.Fragment>
      ),
    },
    {
      title: 'Termination',
      text: (
        <React.Fragment>
          These terms and conditions apply to you until terminated by you or Art
          Curator Grid.
          <br />
          <br />
          Artpool may terminate or suspend your right to access or use
          our website at any time, for any reason, which, in this case, will be
          given appropriate notice.
          <br />
          <br />
          In the event of, for good reason, Artpool terminates these
          terms without providing further notice, you remain bound by all the
          rights under these terms and the applicable laws, without regard to
          the right to compensation that may apply to you.
          <br />
          <br />
          You may cancel your Artpool account at any time through by
          sending us a message with the request to cancel your account to our
          customer support team:{' '}
          <a className={classes.link} href="mailto:hello@artcuratorgrid.com">
            hello@artcuratorgrid.com
          </a>{' '}
          and we promise to satisfy your request as soon as possible.
          <br />
          <br />
          If you wish to cancel your Membership subscription, please read
          Section 5.
        </React.Fragment>
      ),
    },
    {
      title: 'Choice of law and arbitration information',
      text: (
        <React.Fragment>
          These terms and conditions shall be governed by the laws of Portugal,
          and if you are a consumer in the EEA, also by the applicable European
          Union legislation in force on consumer rights and protection of
          personal data. These terms are, in particular, subject to the
          Directive 2011/83/EU of the European Parliament and the Council of 25
          October 2011 on consumer rights, and to the Regulation (EU) 2016/679
          of the European Parliament and of the Council of 27 April 2016 on the
          protection of natural persons with regard to the processing of
          personal data and on the free movement of such data (so-called
          “GDPR”).
          <br />
          <br />
          For any dispute arising with Artpool, you accept and agree to
          first contact us and try to resolve the dispute with us informally. In
          the event of the dispute still remains unresolved and if you have any
          claim related to the use of this website, you accept and agree to the
          non-exclusive jurisdiction of the courts of Portugal, in particular
          the courts of the District of Lisbon.
          <br />
          <br />
          You are also given the choice to go resolve any dispute through an
          alternative dispute resolution centre for consumption disputes, and if
          you choose Portugal, please choose accordingly to the list of
          registered forums below:
          <br />
          <br />
          <a
            className={classes.link}
            href="https://www.consumidor.gov.pt/parceiros/sistema-de-defesa-do-consumidor/entidades-de-resolucao-alternativa-de-litigios-de-consumo/ral-mapa-e-lista-de-entidades.aspx"
          >
            https://www.consumidor.gov.pt/parceiros/sistema-de-defesa-do-consumidor/entidades-de-resolucao-alternativa-de-litigios-de-consumo/ral-mapa-e-lista-de-entidades.aspx
          </a>
          .<br />
          <br />
          In case you may want to submit your online consumer problem and make a
          complaint at the European Commission, visit the website:
          <br />
          <br />
          <a
            className={classes.link}
            href={
              'https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=EN'
            }
          >
            https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=EN
          </a>
          .
        </React.Fragment>
      ),
    },
    {
      title: 'Changes to our terms and conditions',
      text: (
        <React.Fragment>
          We may need, for any reason, to modify our terms and conditions, as
          well as our privacy policy and any other terms that may appear
          referenced in this document, which we expect to happen occasionally.
          <br />
          <br />
          Normally, we will not provide you notice about small changes and you
          agree to review these terms and conditions on a regular basis in order
          to be aware of any further modifications.
          <br />
          <br />
          In the event of the implementation of significant changes, we will
          give notice by e-mail or by any other means, letting you know that you
          have the opportunity to review them before they become effective. In
          such cases, we will also post an alert about our changes on our
          website so you can still be kept up-to-date with our most relevant
          modifications even if you do not check your email very often.
        </React.Fragment>
      ),
    },
    {
      title: 'How to contact us',
      text: (
        <React.Fragment>
          If you would like to be informed about any part of our website or our
          services or if you have questions, comments and requests regarding
          these terms and conditions, please contact us through the channels
          below.
          <br />
          <br />
          In case you do not understand any part of these terms and conditions
          or are concerned about any paragraph of this document or of our
          privacy policy and wish to make a complaint, please address it to{' '}
          <a
            className={`${classes.link}`}
            href="mailto:pauline@artcuratorgrid.com"
          >
            pauline@artcuratorgrid.com
          </a>
          . We will do our best to get back to you within a reasonable time.
          <br />
          <br />
          <div>
            CONTACT
            <br />
            PWA Art Corporation, Lda
            <br />
            <a
              className={`${classes.link}`}
              href={'https://goo.gl/maps/qjqTkYUpfssVVPjW8'}
            >
              Rua da Lapa, 126, 1200-061 Lisboa
            </a>
            <br />
            <a
              className={`${classes.link}`}
              href="mailto:pauline@artcuratorgrid.com"
            >
              pauline@artcuratorgrid.com
            </a>
            <br />
            All content 2021 © by PWA Art Corporation, Lda, trading as Artpool
            <br />
            All rights reserved.
          </div>
        </React.Fragment>
      ),
    },
  ];
  let content: any[] = [];
  if (props.content === 'PRIVACY') {
    content = privacyContent;
  } else if (props.content === 'CONDITIONS') {
    content = conditionsContent;
  }

  return (
    <div>
      <div
        style={{
          textAlign: 'center',
          fontSize: 12,
          padding: isMobile ? '10px 0px 20px 0px' : '10px 0px 50px 0px',
        }}
      >
        Last updated on 15 March 2021
      </div>
      {props.content === 'CONDITIONS' && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h3 style={{ width: isMobile ? '100%' : '80%', textAlign: 'center' }}>
            Welcome to our website and thank you for using Artpool
            (so-called “ACG”). By accessing any content available at{' '}
            <Link to={'/'} className={classes.link}>
              www.artcuratorgrid.com
            </Link>
            , registering, signing in, purchasing services, or simply using this
            website, you indicate that you accept our terms of use and you agree
            to be bound by these terms as well as our privacy policy.
            <br />
            <br />
            Please read the following terms and conditions carefully as they
            govern your use of this website. If you do not agree to these terms,
            please do not access or use this website.
          </h3>
        </div>
      )}

      {content.map((el: { title: string; text: any }, i: number) => {
        const index = i + 1;
        const handleClick = () => {
          setShowData(index);
          if (showData === index) {
            setShowData(0);
          }
        };

        return (
          <Grid
            container={true}
            key={i}
            className={`${
              isMobile ? classes.sectionMobile : classes.sectionDesktop
            } ${classes.border}`}
          >
            <Grid item={true} xs={12} md={4} onClick={handleClick}>
              <h2
                className={classes.title}
                style={{ marginTop: 0, width: isMobile ? '100%' : '85%' }}
              >
                {index}. {el.title}
              </h2>
            </Grid>
            {((showData === index && isMobile) || !isMobile) && (
              <Grid item={true} xs={12} md={8}>
                <h3 style={{ marginTop: isMobile ? 5 : 10 }}>{el.text}</h3>
              </Grid>
            )}
          </Grid>
        );
      })}
    </div>
  );
};

export default compose<Props, IPrivacyPolicyProps>(
  withStyles(style),
  withWidth()
)(LegalContent);
