import styles from '../auth.module.scss';
import { FormControl, TextField } from '@mui/material';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import { useFormikMui } from '@acg/frontend-utils';
import FormMessage from '../../inputs/form-message/form-message';
import { AUTH_STEPS, IFormProps } from '../components/auth-body';
import { sendResetPasswordCode } from '@acg/frontend-ui-artpool';

export interface FormValues {
  email: string;
}

interface ErrorValues {
  serverError?: string;
}

interface AuthForgotPasswordFormProps extends IFormProps {
  reason?: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Enter a valid email'),
});

export const AuthForgotPasswordForm = ({
  setUser,
  setAuthStep,
  artcuratorgridApi,
  reason,
}: AuthForgotPasswordFormProps) => {
  const { formik, formikProps } = useFormikMui<FormValues & ErrorValues>({
    initialValues: {
      email: '',
    },
    initialErrors: {
      serverError: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => submitForgotPassword(values),
  });

  const submitForgotPassword = async (values: FormValues) => {
    try {
      const { email } = values;
      await sendResetPasswordCode(artcuratorgridApi, email);
      setUser((user) => ({ ...user, username: email }));
      setAuthStep(AUTH_STEPS.RESET_PASSWORD);
    } catch (error: any) {
      formik.setFieldError('serverError', error.message);
    }
  };

  const goBackToSignIn = () => {
    setAuthStep(AUTH_STEPS.SIGN_IN);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container={true} item spacing={2}>
        <Grid item={true} xs={12}>
          <p className={styles.title}>Reset your password</p>
        </Grid>
        {reason && (
          <Grid item={true} xs={12}>
            <p className={styles.description}>{reason}</p>
          </Grid>
        )}
        <Grid item={true} xs={12}>
          <FormControl fullWidth>
            <TextField
              {...formikProps('email')}
              label="Email"
              placeholder="Enter your email"
              type={'email'}
              color={'secondary'}
            />
          </FormControl>
        </Grid>
        <FormMessage
          visible={!!formik.errors.serverError}
          message={formik.errors.serverError}
        />
        <Grid item={true} xs={12}>
          <button
            className={styles.buttonPrimary}
            type="submit"
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? 'Sending...' : 'Send code'}
          </button>
        </Grid>
        <Grid item={true} xs={12}>
          <button className={styles.link} onClick={goBackToSignIn}>
            Go back to Sign in
          </button>
        </Grid>
      </Grid>
    </form>
  );
};
