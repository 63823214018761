import { AbstractApiClient } from 'typesafe-api';
import { AuthReqOpts } from './endpoints';

export abstract class AuthApiClient extends AbstractApiClient<AuthReqOpts> {}

export interface AuthApiRootParams {
  baseUri: string;
  getAccessToken?: () => Promise<string | undefined>;
}

export class AuthApiRoot extends AuthApiClient {
  constructor(private rootParams: AuthApiRootParams) {
    super({
      baseUrl: rootParams.baseUri,
    });
  }

  public async getDefaultReqOptions(): Promise<AuthReqOpts> {
    const getAccessToken = this.rootParams.getAccessToken;
    const accessToken = getAccessToken && (await getAccessToken());
    return {
      headers: {
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  }
}

export class BlogClient {
  public getBlogPosts = (): Promise<any> => {
    return fetch(
      `https://blog.artcuratorgrid.com/ghost/api/v3/content/posts?key=4a5acf3ad64ed82922be6b4890`
    )
      .then((response) => {
        return response.text();
      })
      .then((body) => {
        let jsonBody = JSON.parse(body);
        return jsonBody;
      });
  };
}
