import 'whatwg-fetch';

class BlogService {
  public getBlogPosts = (page: number, nbOfPosts?: number): Promise<any> => {
    return fetch(
      `https://blog.artcuratorgrid.com/ghost/api/v3/content/posts?key=4a5acf3ad64ed82922be6b4890&include=authors,tags&page=${page}`
    )
      .then((response) => {
        return response.text();
      })
      .then((body) => {
        let jsonBody = JSON.parse(body);
        if (nbOfPosts) {
          jsonBody = JSON.parse(body).posts.slice(0, nbOfPosts);
        }
        // window.console.log(jsonBody)
        return jsonBody;
      });
  };
}

export default BlogService;
