import ApiServices, { IDataListResponse, IDataResponse } from './ApiServices';

import {
  IFollowerInstitution,
  IConnectInstitutionRequest,
  IInstitution,
  IServiceInstitutionRequest,
} from '../types/institution';
import { IFollower } from '../types/user';
import { IUserFilters } from './UserService';
import { IRegisterInstitutionRequest } from './BackOfficeServices';
import { IPostTotals } from './VisitorServices';

class InstitutionService {
  public getInstitution = (
    institutionId: string
  ): Promise<IDataResponse<IInstitution>> => {
    return ApiServices.getRequest(`venues/${institutionId}`, {}, {}, {});
  };

  public getInstitutionPostTotals = (
    institutionId: string
  ): Promise<IDataResponse<IPostTotals>> => {
    return ApiServices.getRequest(
      `venues/${institutionId}/poststotals`,
      {},
      {},
      { authenticated: false }
    );
  };

  public getPublicInstitution = (
    institutionId: string
  ): Promise<IDataResponse<IInstitution>> => {
    return ApiServices.getRequest(
      `venues/${institutionId}`,
      {},
      {},
      { authenticated: false }
    );
  };

  public connectRequest = (
    institutionId: string,
    connectRequest: IConnectInstitutionRequest
  ): Promise<IDataResponse<IServiceInstitutionRequest>> => {
    return ApiServices.postRequest(
      `venues/${institutionId}/connect_requests`,
      connectRequest
    );
  };

  public followUnfollowInstitution = (
    institutionId: string
  ): Promise<IDataResponse<IFollowerInstitution | {}>> => {
    return ApiServices.putRequest(`venues/${institutionId}/follow`, {});
  };

  // public getInstitutionsFollowers = (id: string, options: IUserFilters = {}): Promise<IDataListResponse<IFollower>> => {
  //     return ApiServices.getRequest(`venues/${id}/followers`, {}, {}, options);
  // }

  public getInstitutionsFollowers = (
    id: string,
    options: IUserFilters = {}
  ): Promise<IDataListResponse<IFollower>> => {
    return ApiServices.getRequest(`venues/${id}/followers`, options);
  };

  public getUserFollowings = (
    id: string,
    options: IUserFilters = {}
  ): Promise<IDataListResponse<IFollower>> => {
    return ApiServices.getRequest(
      `venues/${id}/followings`,
      {},
      {},
      { ...options, authenticated: false }
    );
  };

  public updateInstitution = (
    institution: IRegisterInstitutionRequest
  ): Promise<IDataResponse<any>> => {
    return ApiServices.putRequest(
      `venues/${institution.id}`,
      institution,
      {},
      {},
      {}
    );
  };
}

export default InstitutionService;
