import { S3Response } from 'react-s3-uploader';

export type OnFinishFnc = (result: S3Response, file: File) => void;

export interface S3ObjectRef {
  Key: string;
  Bucket: string;
}

export interface S3ExtendedResponse extends S3Response {
  s3ObjectRef: S3ObjectRef;
}

export enum ACLOptions {
  PRIVATE = 'private',
  PUBLIC_READ = 'public-read',
}
