import { FormikConfig, FormikValues } from 'formik/dist/types';
import { useFormik } from 'formik';

export const userIdValidation = (val: string) => {
  if (!/^[a-f\d]{24}$/i.test(val)) {
    return 'Start typing to choose a validated Artpool Member';
  }
  if (!val) {
    return 'Required';
  }
  return undefined;
};

export const useFormikMui = <Values extends FormikValues>(
  config: FormikConfig<Values>
) => {
  const formik = useFormik(config);
  const formikProps = (key: keyof Values) => ({
    id: key,
    name: key,
    value: formik.values[key],
    onChange: formik.handleChange,
    error: formik.touched[key] && !!formik.errors[key],
    helperText: formik.touched[key] && formik.errors[key],
  });

  return {
    formik,
    formikProps,
  };
};
