import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { ITag } from '../../types/tags';
import {} from '@mui/material';
import { compose } from 'recompose';
import { DARK_GREY_COLOR } from '../../styles/constants';

export interface ITagsProps {
  tags: ITag[];
  tagName?: string;
  style?: React.CSSProperties;
  onTagClick?: (id: string) => void;
}

type Props = ITagsProps & WithStyles<string>;

const styles = createStyles({
  tags: {
    fontFamily: 'Replica-Regular',
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: DARK_GREY_COLOR,
    margin: '5px 0',
    cursor: 'pointer',
  },
});

class Tags extends React.Component<Props> {
  public render() {
    const { classes, tags, tagName, style, onTagClick } = this.props;
    return tags.length > 0 ? (
      <div style={style} className={classes.tags}>
        {tagName ? tagName : 'Tags'}:
        {tags.map((t, i) => {
          const onClick = () => onTagClick && onTagClick(t.id);
          return (
            <span onClick={onClick} className={classes.chip} key={t.id}>
              {' '}
              {t.label}
              {i + 1 === tags.length ? '' : ','}
            </span>
          );
        })}
      </div>
    ) : (
      <div />
    );
  }
}

export default compose<Props, ITagsProps>(withStyles(styles))(Tags);
