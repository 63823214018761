import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import ACGModalContent from '../../components/modal/ACGModalContent';
import { Formik, FormikHelpers, FormikProps, Field } from 'formik';
import { compose } from 'recompose';
import { withAccount, IWithAccountProps } from '../account/InjectAccount';
import { IValidationConfig, generateValidator } from '../../utils/formatters';
import FormMessage from '../../components/form/FormMessage';
import { Grid } from '@mui/material';
import TextInput from '../../components/form/TextInput';
import FormSubmit from '../../components/form/FormSubmit';
import FormCancel from '../../components/form/FormCancel';
import HighlightedTitle from '../../library/dataDisplay/typography/HighlightedTitle';
import UserService from '../../services/UserService';
import { IConnectRequest, IUser } from '../../types/user';

export interface IConnectFormProps {
  onClickAway: () => void;
  user: IUser;
}

type Props = IConnectFormProps & IWithAccountProps & WithStyles<string>;

type IInitialValues = IConnectRequest;

interface IState {
  error: boolean;
  success: boolean;
  isSubmitted: boolean;
}

const style = createStyles({
  text: {
    color: 'rgba(0, 0, 0, 0.64)',
    fontSize: '0.9em',
  },
  topContent: {
    width: '100%',
    fontSize: 16,
    marginTop: 40,
    marginBottom: 20,
    fontWeight: 'normal',
  },
  section: {
    marginBottom: 10,
    marginTop: 10,
  },
  highlight: {
    // fontWeight: "bold"
  },
  buttonSection: {
    marginTop: 40,
  },
});

class ConnectForm extends React.Component<Props, IState> {
  private userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      error: false,
      success: false,
      isSubmitted: false,
    };
  }

  public onSubmit = async (
    values: IInitialValues,
    actions: FormikHelpers<IInitialValues>
  ) => {
    const { account /*, user*/ } = this.props;
    this.setState({ error: false, success: false });

    try {
      await this.userService.connectRequest(account.id, values);
      this.setState({ error: false, success: true, isSubmitted: true });
    } catch (err) {
      this.setState({ error: true, success: false });
      actions.setSubmitting(false);
    }
  };

  public renderForm = (props: FormikProps<IInitialValues>) => {
    const { onClickAway, classes, user } = this.props;

    const { isSubmitted } = this.state;

    let content: React.ReactNode;

    content = (
      <div className={classes.topContent}>
        <div className={`${classes.section} ${classes.highlight}`}>
          Send a message to {user.firstName}
        </div>
      </div>
    );

    return (
      <form onSubmit={props.handleSubmit}>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12}>
            <HighlightedTitle>Message</HighlightedTitle>
          </Grid>
          {!!this.state.error && (
            <FormMessage
              message={'An unexpected error happened, please try again.'}
              visible={!!this.state.error}
              type={'ERROR'}
            />
          )}
          {!!this.state.success && (
            <FormMessage
              message={
                <>
                  <p>
                    Your message to {user.firstName} has been properly sent.
                  </p>
                  <p>
                    Please, note this is a temporary solution until the
                    development of our Messaging tool, thank you for your
                    consideration
                  </p>
                </>
              }
              visible={!!this.state.success}
              type={'SUCCESS'}
            />
          )}
          <Grid item={true} xs={12}>
            {content}
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              name="subject"
              component={TextInput}
              disabled={props.isSubmitting}
              inputProps={{
                id: 'subject',
                label: 'Subject',
                type: 'subject',
                // placeholder: "What is your project?"
              }}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              name="description"
              component={TextInput}
              disabled={props.isSubmitting}
              inputProps={{
                id: 'description',
                label: 'Description',
                type: 'description',
                multiline: true,
                rows: 5,
                maxRows: 20,
                // placeholder: "Tell us more about your project?"
              }}
            />
          </Grid>

          {!isSubmitted && (
            <>
              <Grid xs={12} container={true} justifyContent="space-between">
                <Grid item={true} xs={3}>
                  <FormCancel
                    disabled={props.isSubmitting}
                    onClick={onClickAway}
                    newButton={true}
                    variant="contained"
                  >
                    Cancel
                  </FormCancel>
                </Grid>

                <Grid item={true} xs={3}>
                  <FormSubmit
                    disabled={props.isSubmitting}
                    newButton={true}
                    variant="contained"
                  >
                    Send
                  </FormSubmit>
                </Grid>
              </Grid>
            </>
          )}

          {isSubmitted && (
            <>
              <Grid item={true} xs={12}>
                <FormCancel onClick={onClickAway}>Back</FormCancel>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    );
  };

  public render() {
    const { user } = this.props;

    const initialValues: IInitialValues = {
      curatorId: user.id,
      description: '',
      subject: '',
    };

    const config: IValidationConfig = {
      // subject: ['REQUIRED'],
      // description: ['REQUIRED'],
    };

    const validate = generateValidator(config);

    return (
      <ACGModalContent>
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
      </ACGModalContent>
    );
  }
}

export default compose<Props, IConnectFormProps>(
  withStyles(style),
  withAccount
)(ConnectForm);
