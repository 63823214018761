import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { IUser } from '../../types/user';
import { compose } from 'recompose';
import UserService from '../../services/UserService';
import { ButtonProps } from '@mui/material/Button';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import { withRouter, RouteComponentProps } from 'react-router';
import { StyledButton } from '@acg/frontend-ui-artpool';

export interface IFollowProps {
  user: IUser;
  callback?: (user?: IUser) => void;
  isFollowing: boolean;
  buttonSize?: any;
}

interface IState {
  loading: boolean;
}

type Props = WithStyles<string> &
  IFollowProps &
  RouteComponentProps<{}> &
  IWithAccountProps &
  WithWidth;

const styles = createStyles({
  button: {
    textTransform: 'none',
    borderRadius: 4,
  },
  desktopButton: {
    marginRight: 10,
  },
  mobileButton: {
    marginTop: 5,
  },
});

class Follow extends React.Component<Props, IState> {
  private userService = new UserService();

  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public onFollow = async () => {
    const { user, callback, account, isFollowing, history } = this.props;

    if (account && callback) {
      this.setState({ loading: true });
      await this.userService
        .followUnfollowUser(user.url)
        .then(() => callback(user))
        .catch(() => callback());
      this.setState({ loading: false });
    } else {
      history.push('/login');
    }
  };

  public render() {
    const { isFollowing, classes, width } = this.props;

    const buttonProps: ButtonProps = {
      variant: isFollowing ? 'contained' : 'outlined',
    };

    const isPad = isWidthDown('md', width);

    return (
      <StyledButton
        size={this.props.buttonSize || 'large'}
        {...buttonProps}
        className={`${classes.button} ${
          isPad ? classes.mobileButton : classes.desktopButton
        }`}
        onClick={this.onFollow}
      >
        {isFollowing ? 'Following' : 'Follow'}
      </StyledButton>
    );
  }
}

export default compose<Props, IFollowProps>(
  withStyles(styles),
  withAccount,
  withWidth(),
  withRouter
)(Follow);
