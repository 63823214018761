import ApiServices, { IDataResponse, IDataListResponse } from './ApiServices';
import { IPost } from '../types/posts';
import { IUser } from '../types/user';

export interface IPostFilters {
  first_result?: number;
  max_results?: number;
  user_id?: string;
  sort?: 'FEED';
  tags?: string;
  sub_type?: string;
  order_by?: string;
  status?: string;
  connected_user?: string;
  venueId?: string;
  ignoreArtistPosts?: boolean;
}

class PostService {
  public getPosts = (
    filters?: IPostFilters
  ): Promise<IDataListResponse<IPost>> => {
    return ApiServices.getRequest('posts', filters, {}, { authenticated: false });
  };

  public getPost = (id: string): Promise<IDataResponse<IPost>> => {
    return ApiServices.getRequest(`posts/${id}`, {}, {}, { authenticated: false });
  };

  public updatePost = (
    id: string,
    post: Partial<IPost>
  ): Promise<IDataResponse<IPost>> => {
    return ApiServices.putRequest(`posts/${id}`, post);
  };

  public createPost = (post: Partial<IPost>): Promise<IDataResponse<IPost>> => {
    return ApiServices.postRequest('posts', post);
  };

  public deletePost = (id: string) => {
    return ApiServices.deleteRequest(`posts/${id}`);
  };

  public likePost = (id: string): Promise<IDataResponse<IPost>> => {
    return ApiServices.putRequest(`posts/${id}/like`, {});
  };

  public likeOnlineExhibitionPost = (
    id: string
  ): Promise<IDataResponse<IPost>> => {
    return ApiServices.putRequest(`posts/${id}/exhibition_like`, {});
  };

  public viewPost = (id: string): Promise<IDataResponse<IPost>> => {
    return ApiServices.putRequest(
      `posts/${id}/view`,
      {},
      {},
      {},
      { authenticated: false }
    );
  };

  public getArtistPosts = (): Promise<IDataListResponse<IPost>> => {
    return ApiServices.getRequest('artist_posts');
  };

  public addArtistToPosts = (ids: string[]): Promise<IDataResponse<IUser>> => {
    return ApiServices.postRequest('artist_posts', { ids });
  };
}

export default PostService;
