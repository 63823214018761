import * as React from 'react';
import UserService from '../../services/UserService';
import { compose } from 'recompose';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Formik, FormikHelpers, FormikProps, Field } from 'formik';
import { Grid } from '@mui/material';
import TextInput from '../../components/form/TextInput';
import { generateValidator, IValidationConfig } from '../../utils/formatters';
import FormMessage from '../../components/form/FormMessage';
import FormSubmit from '../../components/form/FormSubmit';
import PublicLayout from '../../library/layout/container/PublicLayout';

export interface IResetProps {}

interface IState {
  error: boolean;
  success: boolean;
}

export interface IInitialValues {
  password: string;
  confirmPassword: string;
}

type Props = IResetProps & RouteComponentProps<{ token: string }>;

class Reset extends React.Component<Props, IState> {
  private userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      error: false,
      success: false,
    };
  }

  public onSubmit = async (
    values: IInitialValues,
    actions: FormikHelpers<IInitialValues>
  ) => {
    const { history } = this.props;
    this.setState({ error: false, success: false });
    try {
      await this.userService.resetPassword(
        this.props.match.params.token,
        values
      );
      this.setState({ error: false, success: true });
      setTimeout(() => {
        history.push('/login');
      }, 2000);
    } catch (err) {
      this.setState({ error: true, success: false });
    }

    actions.setSubmitting(false);
  };

  public renderForm = (props: FormikProps<IInitialValues>) => {
    return (
      <form onSubmit={props.handleSubmit}>
        <Grid
          container={true}
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          {!!this.state.success && (
            <FormMessage
              message={
                'Your password has been reset, you will be redirected to the login page.'
              }
              visible={!!this.state.success}
              type={'SUCCESS'}
            />
          )}
          {!!this.state.error && (
            <FormMessage
              message={
                "There was an error trying to reset your password, please check your email address or that your link hasn't expired"
              }
              visible={!!this.state.error}
              type={'ERROR'}
            />
          )}
          <Grid item={true} xs={12}>
            <Field
              name="password"
              component={TextInput}
              naked={true}
              newTextInput={true}
              inputProps={{
                id: 'password',
                label: 'Password',
                type: 'password',
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              name="confirmPassword"
              component={TextInput}
              naked={true}
              newTextInput={true}
              inputProps={{
                id: 'confirmPassword',
                label: 'Confirm your password',
                type: 'password',
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <FormSubmit
              disabled={props.isSubmitting}
              newButton={true}
              variant="contained"
            >
              Reset your password !
            </FormSubmit>
          </Grid>
        </Grid>
      </form>
    );
  };

  public render() {
    const initialValues: IInitialValues = {
      password: '',
      confirmPassword: '',
    };

    const config: IValidationConfig = {
      password: ['REQUIRED'],
      confirmPassword: ['REQUIRED'],
    };

    const validate = generateValidator(config);

    return (
      <PublicLayout>
        <Formik
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          validate={validate}
          render={this.renderForm}
        />
      </PublicLayout>
    );
  }
}

export default compose<IResetProps, Props>(withRouter)(Reset as unknown as any);
