import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { useState } from 'react';
import { compose } from 'recompose';
import { Grid } from '@mui/material';

import DesignedButton from '../../library/inputs/DesignedButton';
import ACGLink from '../../library/inputs/ACGLink';
import IconConfig from '../../library/dataDisplay/icons/iconConfig';
import { EXHIBITION_BREAKPOINT, LIGHT_PRIMARY } from '../../styles/constants';
import { Field } from 'formik';
import {
  IWithAccountProps,
  withAccount,
} from '../../containers/account/InjectAccount';
import SelectInput from '../form/SelectInput';
import RadioInput from '../form/RadioInput';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { checkRight } from '../../utils/userUtils';
import SocialModal from '../../containers/social/SocialModal';
import InviteButton from '../../library/inputs/InviteButton';
import copyRight from '../../assets/ARTPOOL_ATHORIZATIONFORM_COPYRIGHT.6b95a683.pdf';
import ShareEditModal from '../../containers/shareEditModal/ShareEditModal';
import IconInviteUser from '../../library/dataDisplay/icons/IconInviteUser';
import SubTitle from '../../library/dataDisplay/typography/SubTitle';
import { dateToLocalStringParser } from '../../utils/datetimehelper';

export interface IOnlineExhibitionFormHeaderProps {
  isUpdate: boolean;
  isSubmitting: boolean;
  onExhibitionPreview: () => void;
  saveAsDraft: () => void;
  values: any;
  shareAndSubmit: () => void;
  handleSubmit: () => void;
  handleBannerOpen: (isOpen: boolean) => void;
  openConfigBanner: boolean;
}

const styles = createStyles({
  selectRootOverride: {
    backgroundColor: 'white',
    borderRadius: 4,
  },
  headerSection: {
    margin: 0,
    display: 'inline-block',
    marginRight: 15,
  },
  inline: {
    display: 'inline-block',
  },
  fixedHeader: {
    // position: 'sticky',
    position: 'fixed',
    top: 0,
    backgroundColor: 'white',
    zIndex: 10,
    paddingTop: 100,
  },
  actionsContainer: {
    display: 'flex',
  },
  actionsContainerMobile: {
    flexWrap: 'wrap-reverse',
  },
  configContainer: {
    maxWidth: '70%',
  },
  configBackground: {
    backgroundColor: LIGHT_PRIMARY,
    display: 'flex',
    padding: 30,
    justifyContent: 'center',
    transition: '0.5s height ease-out',
  },
  configBackgroundMobile: {
    padding: 15,
  },
  accordionRoot: {
    width: '100%',
    boxShadow: 'none',
    padding: 0,
  },
  accordionSummaryRootDesktop: {
    padding: '0px 30px',
    height: 50,
    '&$expanded': {
      minHeight: 0,
    },
  },
  accordionSummaryRootMobile: {
    padding: '0px 10px',
    height: 75,
  },
  accordionDetailsRoot: {
    padding: 0,
  },
  expanded: {
    minHeight: 0,
  },
  iconConfigOpen: {
    backgroundColor: LIGHT_PRIMARY,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
});

type Props = WithStyles<string> &
  WithWidth &
  IOnlineExhibitionFormHeaderProps &
  IWithAccountProps;

const OnlineExhibitionFormHeader: React.FC<Props> = (props) => {
  const {
    classes,
    width,
    isUpdate,
    onExhibitionPreview,
    saveAsDraft,
    values,
    shareAndSubmit,
    account,
    openConfigBanner,
  } = props;

  const isExhibitionBreakpoint = isWidthDown(EXHIBITION_BREAKPOINT, width);
  const isMobile = isWidthDown('sm', width);

  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [shareIFrameDialogOpen, setShareIFrameDialogOpen] = useState(false);
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);

  const onShareClose = () => setShareDialogOpen(false);
  const onShareIFrameClose = () => setShareIFrameDialogOpen(false);

  const onShareClick = () => setShareDialogOpen(true);
  const onShareIFrameClick = () => setShareIFrameDialogOpen(true);

  const onInviteClick = () => setInviteDialogOpen(true);
  const onInviteClose = () => setInviteDialogOpen(false);

  const handleScroll = () => {
    setTimeout(() => {
      if (openConfigBanner) {
        props.handleBannerOpen(false);
      }
    }, 30);
  };
  window.addEventListener('scroll', handleScroll, { passive: true });

  const toggleIsOpen = () => {
    props.handleBannerOpen(!openConfigBanner);
  };

  const getSelectVenues = (managesObj: any) => {
    const venuesOption = [];
    const right = checkRight(account);
    if (right === 'ALL' || right === 'ARTIST') {
      venuesOption.push({
        label: `${account.firstName} ${account.lastName}`,
        value: '',
      });
    }
    managesObj.forEach((manage: any) => {
      venuesOption.push({ label: manage.name, value: manage._id });
    });
    return venuesOption;
  };

  const getCheckboxAudience = () => {
    const checkboxOptions = [
      { label: 'Artpool members only', value: 'MEMBERS' },
      { label: 'Public', value: 'PUBLIC' },
      // To add the lock Icon to the design
      // {label: 'Public', value: 'PUBLIC', icon: true},
      {
        label: 'Private link',
        value: 'PRIVATE',
        tooltip:
          'To be shared at your discretion. Only people who have the private link will be able to discover your virtual exhibition. This link can be found in the Share button.',
      },
    ];
    return checkboxOptions;
  };

  const getExhibitionStatus = () => {
    if (values.isDraft) {
      return 'Draft';
    } else {
      switch (values.visibility) {
        case 'PUBLIC':
          return 'Public';
          break;
        case 'PRIVATE':
          return 'Private';
          break;
        case 'MEMBERS':
          return 'ACG Members';
          break;
        default:
          return;
      }
    }
  };
  const getUpdateDate = () => {
    if (values.last_creator_update) {
      const date = dateToLocalStringParser(
        values.last_creator_update,
        'DD-MM-YYYY LTS'
      );
      return ' - Last Update: ' + date;
    } else {
      return;
    }
  };

  return (
    <Grid container className={` ${classes.fixedHeader}`}>
      <Accordion
        classes={{ root: classes.accordionRoot }}
        expanded={openConfigBanner}
      >
        <AccordionSummary
          classes={{
            root: isExhibitionBreakpoint
              ? classes.accordionSummaryRootMobile
              : classes.accordionSummaryRootDesktop,
            expanded: classes.expanded,
          }}
        >
          <div
            style={{ width: '100%' }}
            className={`${classes.actionsContainer} ${
              isExhibitionBreakpoint && classes.actionsContainerMobile
            }`}
          >
            <Grid
              container
              alignItems={'center'}
              justifyContent={'space-between'}
              style={{ width: '100%' }}
            >
              <Grid item>
                <h3 className={classes.headerSection}>
                  {isUpdate ? 'Update' : 'Create'} Your Exhibition
                </h3>
                {!isExhibitionBreakpoint && (
                  <SubTitle className={classes.inline}>
                    Status: {getExhibitionStatus()}
                    {getUpdateDate()}
                  </SubTitle>
                )}
              </Grid>
              {isExhibitionBreakpoint && (
                <Grid
                  item
                  onClick={toggleIsOpen}
                  className={`${openConfigBanner && classes.iconConfigOpen}`}
                  style={isExhibitionBreakpoint ? {} : { padding: 4 }}
                >
                  <IconConfig />
                </Grid>
              )}
            </Grid>
            <Grid
              container
              justifyContent={isMobile ? 'space-between' : 'flex-end'}
              alignItems={'center'}
            >
              {isUpdate && (
                <Grid item style={{ marginLeft: 5 }}>
                  <ACGLink onClick={onExhibitionPreview}>
                    Exhibition Preview
                  </ACGLink>
                </Grid>
              )}
              <Grid item style={{ marginLeft: 5 }}>
                <DesignedButton
                  size={'small'}
                  ACGType="neutral"
                  style={{ alignSelf: 'center' }}
                  disabled={props.isSubmitting}
                  onClick={saveAsDraft}
                >
                  {(values.isDraft || !isUpdate) && 'Save as Draft'}
                  {!values.isDraft && isUpdate && 'Switch to Draft'}
                </DesignedButton>
              </Grid>
              <Grid item style={{ marginLeft: 5 }}>
                <DesignedButton
                  size={'small'}
                  ACGType="main"
                  style={{ alignSelf: 'center' }}
                  onClick={shareAndSubmit}
                >
                  {(values.isDraft || !isUpdate) && 'Post Now!'}
                  {!values.isDraft && isUpdate && 'Update!'}
                </DesignedButton>
              </Grid>
              <Grid item onClick={onInviteClick} style={{ marginLeft: 5 }}>
                <IconInviteUser />
              </Grid>
              {!isExhibitionBreakpoint && (
                <Grid
                  item
                  onClick={toggleIsOpen}
                  className={`${openConfigBanner && classes.iconConfigOpen}`}
                  style={{ padding: 4, marginLeft: 5 }}
                >
                  <IconConfig />
                </Grid>
              )}
            </Grid>
          </div>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
          <Grid
            item
            xs={12}
            className={`${classes.configBackground} ${
              isExhibitionBreakpoint && classes.configBackgroundMobile
            }`}
          >
            <Grid
              container
              className={`${
                !isExhibitionBreakpoint && classes.configContainer
              }`}
            >
              <Grid item xs={12} sm={4} style={{ paddingRight: 50 }}>
                <p> Who is the organizer?</p>
                {account.manages && account.manages.length > 0 && (
                  <Field
                    name="venue_id"
                    component={SelectInput}
                    classes={{ root: classes.selectRootOverride }}
                    options={getSelectVenues(account.manages)}
                  />
                )}
                <InviteButton profileInvited={'Venues'} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <p> Set your Audience:</p>
                <Field
                  name="visibility"
                  component={RadioInput}
                  // classes={classes}
                  inputProps={{
                    id: 'visibility',
                    label: 'Your online exhibition’s public visibility',
                    type: 'visibility',
                  }}
                  options={getCheckboxAudience()}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ display: 'flex', alignItems: 'flex-end', padding: 15 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={12}>
                    <DesignedButton
                      fullWidth
                      ACGType={'neutral'}
                      onClick={onShareClick}
                    >
                      Share
                    </DesignedButton>
                  </Grid>
                  <Grid item xs={6} sm={12}>
                    <DesignedButton
                      fullWidth
                      ACGType={'neutral'}
                      onClick={onInviteClick}
                    >
                      Invite to edit
                    </DesignedButton>
                  </Grid>
                  <Grid item xs={6} sm={12}>
                    <DesignedButton
                      fullWidth
                      ACGType={'neutral'}
                      onClick={onShareIFrameClick}
                    >
                      Embed{!isExhibitionBreakpoint && ' Into Your Website  '}
                    </DesignedButton>
                  </Grid>

                  <Grid item xs={12}>
                    <span className={classes.bold}>
                      <a
                        href={copyRight}
                        target={'_blank'}
                        rel="noopener noreferrer"
                        download
                        style={{ color: '#000' }}
                      >
                        Copyright Authorisation Form
                      </a>
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <SocialModal
        open={shareDialogOpen}
        onClose={onShareClose}
        object={{
          type: 'EXHIBITION',
          object: values,
          // @ts-ignore
          showIframeOption: false,
        }}
      />
      <SocialModal
        open={shareIFrameDialogOpen}
        onClose={onShareIFrameClose}
        object={{
          type: 'URL',
          object: values,
          showIframeOption: true,
        }}
        hideShare
      />

      <ShareEditModal
        open={inviteDialogOpen}
        onClose={onInviteClose}
        object={{
          type: 'EXHIBITION',
          object: values,
        }}
      />
    </Grid>
  );
};

export default compose<Props, IOnlineExhibitionFormHeaderProps>(
  withStyles(styles),
  withWidth(),
  withAccount
)(OnlineExhibitionFormHeader);
