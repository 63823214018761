import { DateTime } from 'luxon';
import ordinal from 'ordinal';

type DateOrString = string | Date;

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const toDateTime = (d: DateOrString) => {
  if (typeof d === 'string') {
    return DateTime.fromISO(d);
  } else {
    return DateTime.fromJSDate(d);
  }
};

export const formatDateRange = (
  start: DateOrString,
  end: DateOrString,
  verbose = false
): string => {
  const startDT = toDateTime(start);
  const endDT = toDateTime(end);
  return verbose
    ? formatLuxonDateRangeVerbose(startDT, endDT)
    : formatLuxonDateRange(startDT, endDT);
};

export const getFormattedDateFromCountdown = (
  countdown: any,
  fullDate?: boolean
): string => {
  const addDays = countdown.days * 24 * 60 * 60 * 1000;
  const addHours = countdown.hours * 60 * 60 * 1000;
  const addMinutes = countdown.minutes * 60 * 1000;
  const addSeconds = countdown.seconds * 1000;

  const date = new Date();
  const newDate = date.setTime(
    date.getTime() + (addDays + addHours + addMinutes + addSeconds)
  );

  return formatDate(new Date(newDate), fullDate);
};

export const formatDate = (date: Date, fullDate?: boolean) => {
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  return `${month}${fullDate ? ` ${day}` : ''}, ${year}`;
};

export const formatLuxonDateRange = (
  start: DateTime,
  end: DateTime
): string => {
  const deconstruct = (dt: DateTime) => [dt.day, dt.monthShort, dt.year];

  const [startDay, startMonth, startYear] = deconstruct(start);

  const [endDay, endMonth, endYear] = deconstruct(end);

  const parts = [startMonth, startDay];

  const startEndSameYear = startYear === endYear;
  if (!startEndSameYear) {
    parts.push(startYear);
  }

  parts.push('-');

  if (startMonth !== endMonth || startYear !== endYear) {
    parts.push(endMonth);
  }

  if (startEndSameYear) {
    parts.push(endDay + ',');
  } else {
    parts.push(endDay);
  }

  parts.push(endYear);

  return parts.join(' ');
};

const formatLuxonDateRangeVerbose = (
  start: DateTime,
  end: DateTime
): string => {
  const deconstruct = (dt: DateTime) => [
    ordinal(dt.day),
    dt.monthLong,
    dt.year,
  ];

  const [startDay, startMonth, startYear] = deconstruct(start);

  const [endDay, endMonth, endYear] = deconstruct(end);

  const parts = [startMonth, startDay];

  const startEndSameYear = startYear === endYear;
  if (!startEndSameYear) {
    parts.push(startYear);
  }

  parts.push('until', endMonth, endDay, endYear);

  return parts.join(' ');
};
