import { environment } from '../environments/environment';
import { getBearerToken } from '@acg/auth/frontend';
import { ArtcuratorgridApiClient } from '@acg/artcuratorgrid-api-spec';

const { REACT_APP_API_URL } = environment;

export const artcuratorgridApiClient = () =>
  new ArtcuratorgridApiClient({
    baseUri: REACT_APP_API_URL + '/v1',
    getAccessToken: getBearerToken,
  });
