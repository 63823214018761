import {
  default as withWidth,
  isWidthDown,
  WithWidth,
} from '@mui/material/Hidden/withWidth';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import { Grid } from '@mui/material';
import { ButtonColors, StyledButton } from '@acg/frontend-ui-artpool';
import { GREY_COLOR, PRIMARY_COLOR, SPACING } from '../../styles/constants';
import IconACGCross from '../../img/logo.svg';

const style = createStyles({
  wrapper: {
    fontFamily: 'Replica-Regular',
  },
  bg: {
    padding: 20,
    backgroundColor: GREY_COLOR,
  },
  bgImg: {
    marginTop: 149,
    minHeight: 530,
  },
  primary: {
    color: PRIMARY_COLOR,
    textDecoration: 'none',
    '& h1': {
      lineHeight: 0.7,
    },
  },
  paddled: {
    padding: '30px 20px 30px 20px',
  },
  textMobile: {
    '& h1': {
      fontSize: 35,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 15,
    },
  },

  textDesktop: {
    '& h1': {
      fontSize: 50,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 30,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 16,
    },
  },
  whiteWrapper: {
    backgroundColor: GREY_COLOR,
    padding: '0 20px',
    // display: 'inline-table'
  },
  align: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  blogImg: {
    width: '100%',
    height: 213,
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'flex-end',
  },
  blogMobileImg: {
    width: '100%',
    height: 257,
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'flex-end',
  },
  lineLeftWrapper: {
    padding: `0 ${SPACING}px 0 0`,
    display: 'block',
  },
  lineRightWrapper: {
    padding: `0 0 0 ${SPACING}px`,
    display: 'block',
  },
  lineBottom: {
    borderBottom: '1px solid',
    marginBottom: 10,
  },
  lineRight: {
    borderRight: '1px solid',
    height: '100%',
  },
  blogPostContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  blogLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  blogImgContainer: {
    color: 'white',
    display: 'flex',
    height: 675,
    alignItems: 'flex-end',
  },
  overlayContainer: {
    // height: 100%,
    position: 'relative',
  },
  overlay: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    backgroundColor: 'rgba(246, 246, 246, 0.7)',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      opacity: 1,
    },
  },
  section: {
    padding: '82px 153px',
  },
  bannerButton: {
    borderRadius: 8,
    padding: '10px 37px',
    width: 'fit-content',
  },
  bannerButtonFirefox: {
    width: '-moz-fit-content',
  },

  bulletACG: {
    listStyleImage: `url(${IconACGCross})`,
    '&::marker': {
      fontSize: '2rem',
    },
  },
});

export interface IPostTypeFormProps {
  texts: {
    title: string;
    subTitle: string;
    subTitleBullets?: any;
    buttonText: string;
  };
  image: string;
}

type Props = WithStyles<string> &
  IPostTypeFormProps &
  WithWidth &
  RouteComponentProps<{}>;

const Banner: React.FC<Props> = (props) => {
  const { texts, image, width, classes, history } = props;

  const onSignUpClick = () => {
    window.scrollTo(0, 0);
    history.push('/login');
  };

  const isMobile = isWidthDown('sm', width);
  const isPad = isWidthDown('md', width);

  return isMobile ? (
    <div
      style={{
        padding: 15,
        display: 'flex',
        flexDirection: 'column',
        height: '80vh',
        justifyContent: 'center',
        backgroundColor: '#f4f4f4',
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={7}>
          <h1 style={{ marginTop: 15, marginBottom: 50, fontSize: 30 }}>
            {texts.title}
          </h1>
          <p
            style={{
              paddingTop: 0,
              paddingBottom: 10,
              fontSize: 20,
              marginBottom: 30,
            }}
          >
            {texts.subTitle}
          </p>
          {texts.subTitleBullets && (
            <ul>
              {texts.subTitleBullets.map((bullet: string, index: number) => {
                return (
                  <li key={`blt_${index}_`} className={classes.bulletACG}>
                    {bullet}
                  </li>
                );
              })}
            </ul>
          )}
        </Grid>
        <StyledButton
          variant="contained"
          bgColor={ButtonColors.BLACK}
          onClick={onSignUpClick}
        >
          {texts.buttonText}
        </StyledButton>
      </Grid>
    </div>
  ) : (
    <div className={classes.bg}>
      <Grid container className={classes.bgImg}>
        <Grid item xs={6}>
          <Grid
            container
            direction="column"
            className={classes.whiteWrapper}
            style={{ paddingLeft: 0, height: '100%' }}
          >
            <div>
              <h1
                style={{
                  marginTop: 15,
                  marginBottom: 15,
                  lineHeight: isPad ? '36px' : '56px',
                }}
              >
                {texts.title}
              </h1>
              <h3 style={{ marginTop: 0, marginBottom: 30 }}>
                {texts.subTitle}
              </h3>
              {texts.subTitleBullets && (
                <ul>
                  {texts.subTitleBullets.map(
                    (bullet: string, index: number) => {
                      return (
                        <li key={`blt_${index}_`} className={classes.bulletACG}>
                          {bullet}
                        </li>
                      );
                    }
                  )}
                </ul>
              )}
            </div>
            <Grid item>
              <StyledButton
                bgColor={ButtonColors.BLACK}
                variant="contained"
                onClick={onSignUpClick}
              >
                {texts.buttonText}
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.align}>
          <img
            src={image}
            style={{
              objectFit: 'cover',
              objectPosition: '0% 0%',
              width: '100%',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default compose<Props, IPostTypeFormProps>(
  withStyles(style),
  withRouter,
  withWidth()
)(Banner);
