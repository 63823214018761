import * as React from 'react';
import { compose } from 'recompose';
import { withAccount, IWithAccountProps } from '../account/InjectAccount';
import OnboardingViewer from './OnboardingViewer';
import OnboardingCurator from './OnboardingCurator';

export interface IOnboardingProps {
  onOnboardingEnd: () => void;
}

type Props = IWithAccountProps & IOnboardingProps;

class Onboarding extends React.Component<Props, any> {
  public render() {
    const { account, onOnboardingEnd } = this.props;

    switch (account.profile) {
      case 'CURATOR':
        return <OnboardingCurator onOnboardingEnd={onOnboardingEnd} />;
      case 'VIEWER':
        return <OnboardingViewer onOnboardingEnd={onOnboardingEnd} />;
      case 'ARTIST':
        return <OnboardingViewer onOnboardingEnd={onOnboardingEnd} />;
    }
  }
}

export default compose<Props, IOnboardingProps>(withAccount)(Onboarding);
