import styles from './subtitle4.module.scss';
import React from 'react';

/* eslint-disable-next-line */
export interface Subtitle4Props
  extends React.HTMLAttributes<HTMLParagraphElement> {
  children?: React.ReactNode;
}

export function Subtitle4(props: Subtitle4Props) {
  const { children, ...rest } = props;

  return (
    <p className={styles.root} {...rest}>
      {props.children}
    </p>
  );
}

export default Subtitle4;
