import * as React from 'react';
import { StyledButton } from '@acg/frontend-ui-artpool';
import { ButtonProps } from '@mui/material/Button';

export interface IDesignedButtonProps extends ButtonProps {
  ACGType: 'main' | 'secondary' | 'third' | 'neutral';
  style?: any;
  dashed?: boolean;
}

export default (props: IDesignedButtonProps) => {
  const { ACGType, dashed, ...rest } = props;

  let classname = null;

  return (
    <StyledButton
      variant={ACGType === 'main' ? 'contained' : 'outlined'}
      color={
        ACGType === 'main' || ACGType === 'secondary' ? 'primary' : 'secondary'
      }
      className={`${classname}`}
      {...rest}
    >
      {props.children}
    </StyledButton>
  );
};
