import { INftFile, Price, S3ObjectRef } from '@acg/api-interfaces';
import { ErrorType, ReqOptions, ResOptions, Route } from 'typesafe-api';
import {
  ArtpoolApiEndpoint,
  DefaultErrorCodes,
  IsPurchaseAbleNftError,
} from './api';
import { MembershipTiers } from '@acg/shared/const';
import { JsonUserOrVenue } from './project';
import { CreateNftData } from '@acg/shared/interfaces';

export interface GetNftOptions {
  live?: boolean;
}

export interface NftIdParamReq extends ReqOptions {
  params: {
    id: string;
  };
}

export type GetNftError = ErrorType<DefaultErrorCodes | 404>;

/**
 * Get Nfts
 */

export const getNftsRoute: Route = {
  method: 'get',
  path: '/nft',
};

export interface GetNftsReq extends ReqOptions {
  query?: GetNftOptions;
}

export interface GetNftsResp extends ResOptions {
  body: JsonNft[];
}

export type GetNftsEndpointDef = ArtpoolApiEndpoint<GetNftsReq, GetNftsResp>;

/**
 * Get NFT
 */

export const getNftRoute: Route = {
  method: 'get',
  path: '/nft/:id',
};

export interface NftEditions {
  sold: number;
  maximum: number;
}

export interface JsonNft {
  _id: string;
  image: { src: string; label: string };
  video?: { src: string; label: string };
  title: string;
  printOpts: {
    printable: boolean;
    printNotIncluded?: boolean;
    image: { src: string; label: string };
    size?: { width: string; height: string };
  };
  description: string;
  promotion: { value: number; code: string };
  price: Price;
  usd?: string;
  editions: NftEditions;
  soldOut: boolean;
  minMembershipTier: MembershipTiers;
  curated_by: JsonUserOrVenue;
  artist: JsonUserOrVenue;
  projectId: string;
  certificateUrl?: string;
}

export interface JsonNftWithRawFile extends JsonNft {
  nftFile?: string;
  artistEthWallet?: string;
}

export interface GetNftResp extends ResOptions {
  body: JsonNft;
}

export type GetNftEndpointDef = ArtpoolApiEndpoint<
  NftIdParamReq,
  GetNftResp,
  GetNftError
>;

/**
 * Get is purchasable
 */

export const getIsPurchasableRoute: Route = {
  method: 'get',
  path: '/nft/:id/is-purchasable',
};

export interface GetIsPurchasableResp extends ResOptions {
  body: {
    purchasable: true;
  };
}

export type GetIsPurchasableEndpointDef = ArtpoolApiEndpoint<
  NftIdParamReq,
  GetIsPurchasableResp,
  IsPurchaseAbleNftError
>;

/**
 * Edit NFT
 */

export const patchNftRoute: Route = {
  method: 'patch',
  path: '/nft/:id',
};

export interface PatchNftReq extends ReqOptions {
  params: {
    id: string;
  };
  body: Partial<JsonNft>;
}

export interface PatchNftResp extends ResOptions {
  body: JsonNft;
}

export type PatchNftEndpointDef = ArtpoolApiEndpoint<
  PatchNftReq,
  PatchNftResp,
  GetNftError
>;

/**
 * Post NFT
 */

export const postNftRoute: Route = {
  method: 'post',
  path: '/nft/:projectId',
};

export interface PostNftReq extends ReqOptions {
  params: {
    projectId: string;
  };
  body: CreateNftData;
}

export interface PostNftResp extends ResOptions {
  body: JsonNft;
}

export type PostNftEndpointDef = ArtpoolApiEndpoint<
  PostNftReq,
  PostNftResp,
  GetNftError
>;

/**
 * Set NFT File
 */

export const setNftFileRoute: Route = {
  method: 'patch',
  path: '/nft/:id/nft_file',
};

export interface SetNftFileReq extends ReqOptions {
  params: {
    id: string;
  };
  body: INftFile;
}

export interface SetNftFileResp extends ResOptions {
  body: JsonNft;
}

export type setNftFileEndpointDef = ArtpoolApiEndpoint<
  SetNftFileReq,
  SetNftFileResp,
  GetNftError
>;

/**
 * Upload signed Url
 */

export const uploadSignedUrlRoute: Route = {
  method: 'post',
  path: '/upload-signed-url',
};

export interface UploadSignedUrlParams {
  objectName: string;
  contentType: string;
  isPublic: boolean;
}

export interface UploadSignedUrlReq extends ReqOptions {
  body: UploadSignedUrlParams;
}

export interface SignedUrlS3Ref {
  signedPutUrl: string;
  signedGetUrl: string;
  publicUrl: string;
  s3ObjectRef: S3ObjectRef;
}

export interface UploadSignedUrlResp extends ResOptions {
  body: SignedUrlS3Ref;
}

export type UploadSignedUrlEndpointDef = ArtpoolApiEndpoint<
  UploadSignedUrlReq,
  UploadSignedUrlResp,
  GetNftError
>;
