import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import heart from '../../img/heart.svg';
import heartFilled from '../../img/heartFilled.svg';
import { compose } from 'recompose';
import {} from '@mui/material';

export interface IHeartProps {
  filled?: boolean;
  big?: boolean;
  fontSize?: number;
}

type Props = IHeartProps & WithStyles<string>;

const styles = createStyles({
  size: {
    width: 17,
    cursor: 'pointer',
  },
  big: {
    width: 37,
    cursor: 'pointer',
  },
});

class Heart extends React.Component<Props, any> {
  public render() {
    const { classes, filled, big, fontSize } = this.props;

    return (
      <img
        src={filled ? heartFilled : heart}
        className={big ? classes.big : classes.size}
        style={{ width: fontSize }}
      />
    );
  }
}

export default compose<Props, IHeartProps>(withStyles(styles))(Heart);
