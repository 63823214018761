import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import PostsGrid from '../../components/posts/PostsGrid';
import { withRouter, RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import PostService from '../../services/PostService';
import PostModal from '../post/Post';
import { Grid } from '@mui/material';
import PaddedLayout from '../../library/layout/container/PaddedLayout';
import { withAccount, IWithAccountProps } from '../account/InjectAccount';
import { checkRight } from '../../utils/userUtils';
import { IPost } from '../../types/posts';
import AddPostForm from '../post/AddPostForm';
import SocialModal from '../social/SocialModal';
import * as queryString from 'query-string';

export interface IUserPostsProps {
  selectedTab?: string;
  noTopMargin?: boolean;
  venueId?: string;
  noProfile?: boolean;
}

type Props = IUserPostsProps &
  RouteComponentProps<{ userId: string; postId?: string }> &
  WithStyles<string> &
  IWithAccountProps;

interface IState {
  open: boolean;
  openProfile: boolean;
  openAddPost: boolean;
  socialModalObject?: IPost;
  socialModalOpen: boolean;
}

const style = createStyles({
  root: {
    flexGrow: 1,
  },
  backdrop: {
    backgroundColor: 'rgba(242,242,242,0.92)',
  },
});

const INITIAL_PAGE_SIZE = 12;

class UserPosts extends React.Component<Props, IState> {
  private postService = new PostService();

  constructor(props: Props) {
    super(props);
    this.state = {
      open: props.match.params.postId ? true : false,
      openProfile: false,
      openAddPost: false,
      socialModalOpen: false,
    };
  }

  public componentDidMount(): void {
    const {
      location: { search },
    } = this.props;

    const create = queryString.parse(search).create;
    if (create) {
      this.setState({ openAddPost: true });
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const {
      match: {
        params: { postId },
      },
      location: { search },
    } = this.props;

    const {
      match: {
        params: { postId: prevPostId },
      },
    } = prevProps;

    if (!prevPostId && postId) {
      this.setState({ open: true });
    }

    if (prevPostId && !postId) {
      this.setState({ open: false });
    }

    const create = queryString.parse(search).create;
    if (create && this.state.openAddPost === false) {
      this.setState({ openAddPost: true });
    }
  }

  public getData = (pageNumber: number = 0) => {
    const {
      match: {
        params: { userId },
      },
      selectedTab,
      venueId,
    } = this.props;

    if (selectedTab) {
      return this.postService.getPosts({
        first_result: INITIAL_PAGE_SIZE * pageNumber,
        max_results: INITIAL_PAGE_SIZE,
        user_id: userId,
        sub_type: selectedTab,
        status: 'PUBLISHED',
        connected_user: this.props.account ? this.props.account.url : undefined,
        venueId,
      });
    }

    return this.postService.getPosts({
      first_result: INITIAL_PAGE_SIZE * pageNumber,
      max_results: INITIAL_PAGE_SIZE,
      user_id: userId,
      status: 'PUBLISHED',
      connected_user: this.props.account ? this.props.account.url : undefined,
      venueId,
    });
  };

  public handleClose = (): void => {
    const {
      history,
      match: {
        params: { userId },
      },
      venueId,
    } = this.props;
    if (venueId) {
      history.push(`/venues/${venueId}/posts`);
    } else {
      history.push(`/users/${userId}/posts`);
    }

    this.setState({ openAddPost: false });
  };

  public render() {
    const {
      history,
      classes,
      match: {
        params: { userId },
      },
      account,
      selectedTab,
      noTopMargin,
      venueId,
    } = this.props;

    const { open, openAddPost } = this.state;

    const onPostClick = (postId: string) => {
      if (venueId) {
        history.push(`/venues/${venueId}/posts/${postId}`);
      } else {
        history.push(`/users/${userId}/posts/${postId}`);
      }
    };
    const onCommentClick = (postId: string) => {
      if (venueId) {
        history.push(`/venues/${venueId}/posts/${postId}`, { comment: true });
      } else {
        history.push(`/users/${userId}/posts/${postId}`, { comment: true });
      }
    };
    const onPageChange = (pageNumber: number) => ({});
    const onClickOnAdd = () => this.setState({ openAddPost: true });

    const right = checkRight(account);
    const showAddButton = right === 'ALL' && userId === account.url;

    const onLike = (post: IPost) => {
      if (post.type === 'ONLINEEXHIBITION') {
        return this.postService.likeOnlineExhibitionPost(post.id);
      }

      return this.postService.likePost(post.id);
    };

    const isLiking = (post: IPost) =>
      !!post.likes.find((i) => i.id === account.id);

    let content: React.ComponentClass<any> = AddPostForm;
    if (open) {
      content = PostModal;
    } else if (openAddPost) {
      content = AddPostForm;
    }

    const onShareClick = (post: IPost) => {
      this.setState({
        socialModalOpen: true,
        socialModalObject: post,
      });
    };

    const onSocialModalClose = () => {
      this.setState({
        socialModalOpen: false,
        socialModalObject: undefined,
      });
    };

    return (
      <PaddedLayout noPadded={noTopMargin}>
        <Grid container={true} className={classes.root}>
          <PostsGrid
            noProfile={this.props.noProfile}
            showAddButton={showAddButton}
            loadPosts={this.getData}
            pageSize={INITIAL_PAGE_SIZE}
            offset={0}
            onPageChange={onPageChange}
            onPostClick={onPostClick}
            onClickOnAdd={onClickOnAdd}
            connectedUser={account}
            onLike={onLike}
            isLiking={isLiking}
            modalComponent={content as React.ComponentClass<any>}
            showModal={open || openAddPost}
            onCloseModal={this.handleClose}
            onCommentClick={onCommentClick}
            onShareClick={onShareClick}
            showDraft={true}
            // noResult={<div />}
            selectedTab={selectedTab}
            venueId={venueId}
          />
          {this.state.socialModalObject && (
            <SocialModal
              open={this.state.socialModalOpen}
              onClose={onSocialModalClose}
              object={{
                type: 'POST',
                object: this.state.socialModalObject,
              }}
            />
          )}
        </Grid>
      </PaddedLayout>
    );
  }
}

export default compose<Props, IUserPostsProps>(
  withRouter,
  withAccount,
  withStyles(style)
)(UserPosts);
