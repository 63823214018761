import ApiServices, { IDataResponse } from './ApiServices';

class ArtistService {
  public getArtists = (curator: any): Promise<IDataResponse<any>> => {
    return ApiServices.getRequest('artists', curator);
  };

  public createArtist = (data: any): Promise<IDataResponse<any>> => {
    return ApiServices.postRequest(
      'artist',
      data /*, {}, {}, {authenticated: true}*/
    );
  };

  public editArtist = (data: any): Promise<IDataResponse<any>> => {
    return ApiServices.putRequest(
      'artist',
      data /*, {}, {}, {authenticated: true}*/
    );
  };
}

export default ArtistService;
