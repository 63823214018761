import './telegram-icon.module.scss';
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

// tslint:disable-next-line:no-empty-interface
export interface TelegramIconProps {}

type Props = TelegramIconProps & SvgIconProps;

export function TelegramIcon(props: Props) {
  return (
    <SvgIcon {...props} viewBox={'0 0 25 20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.81676 8.60987C8.29498 5.78741 12.6148 3.92667 14.7763 3.02765C20.9476 0.460774 22.23 0.0148807 23.0658 0.000156987C23.2496 -0.00308132 23.6606 0.0424773 23.9269 0.25852C24.1517 0.440943 24.2136 0.687369 24.2432 0.860326C24.2728 1.03328 24.3096 1.42728 24.2803 1.73514C23.9459 5.249 22.4988 13.7762 21.7626 17.7118C21.4511 19.3771 20.8378 19.9354 20.244 19.9901C18.9535 20.1088 17.9735 19.1372 16.7236 18.3179C14.7678 17.0358 13.6629 16.2377 11.7644 14.9867C9.57036 13.5408 10.9927 12.7462 12.243 11.4475C12.5703 11.1076 18.2562 5.93584 18.3662 5.46668C18.38 5.40801 18.3928 5.18929 18.2628 5.0738C18.1329 4.95831 17.9411 4.9978 17.8027 5.02921C17.6066 5.07373 14.4821 7.1389 8.42925 11.2247C7.54238 11.8337 6.73907 12.1304 6.01934 12.1149C5.22589 12.0977 3.69962 11.6663 2.56499 11.2974C1.17332 10.8451 0.0672475 10.6059 0.163561 9.83761C0.213728 9.43745 0.764792 9.0282 1.81676 8.60987Z"
      />
    </SvgIcon>
  );
}

export default TelegramIcon;
