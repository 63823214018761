import ApiServices, { IDataResponse, IDataListResponse } from './ApiServices';
import { ITag } from '../types/tags';

export interface ITagFilters {
  search?: string;
}

class TagService {
  public getTags = (
    filters?: ITagFilters
  ): Promise<IDataListResponse<ITag>> => {
    return ApiServices.getRequest(
      'tags',
      filters,
      {},
      { authenticated: false }
    );
  };

  public getTag = (id: string): Promise<IDataResponse<ITag>> => {
    return ApiServices.getRequest(
      `tags/${id}`,
      {},
      {},
      { authenticated: false }
    );
  };

  public createTag = (tag: Partial<ITag>): Promise<IDataResponse<ITag>> => {
    return ApiServices.postRequest('tags', tag);
  };
}

export default TagService;
