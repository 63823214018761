import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import ACGModalContent from '../../components/modal/ACGModalContent';
import { Formik, FormikHelpers, FormikProps, Field } from 'formik';
import { compose } from 'recompose';
import { withAccount, IWithAccountProps } from '../account/InjectAccount';
import { IUser } from '../../types/user';
import { IValidationConfig, generateValidator } from '../../utils/formatters';
import FormMessage from '../../components/form/FormMessage';
import {
  Grid,
  // ExpansionPanel,
  // ExpansionPanelSummary,
  // ExpansionPanelDetails,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextInput from '../../components/form/TextInput';
import ProfileUpload from '../../components/form/ProfileUpload';
import SelectInput from '../../components/form/SelectInput';
import RadioInput from '../../components/form/RadioInput';
import FormLink from '../../library/dataDisplay/typography/FormLink';
import LocationFinder from '../../components/form/LocationFinder';
import FormTagWrapper from '../tags/FormTagWrapper';
import CheckboxInput from '../../components/form/CheckboxInput';
import DesignedButton from '../../library/inputs/DesignedButton';

export interface IProfileModalContentProps {
  onClickAway: () => void;
}

type Props = IProfileModalContentProps & IWithAccountProps & WithStyles<string>;

type IInitialValues = IUser;

interface IState {
  error: boolean;
  success: boolean;
  isExpanded: boolean;
}

const style = createStyles({
  text: {
    color: 'rgba(0, 0, 0, 0.64)',
    fontSize: '0.9em',
  },

  textPassword: {
    color: 'rgba(0, 0, 0, 0.64)',
    fontSize: '0.9em',
    paddingTop: 30,
  },
  secondaryText: {
    color: '#acabac',
    fontSize: 12,
  },
  heading: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  profileHeading: {
    color: 'rgba(0, 0, 0, 0.84)',
    fontSize: '0.875rem',
    paddingTop: 47,
  },
  profileHeadingViewer: {
    color: 'rgba(0, 0, 0, 0.84)',
    fontSize: '0.875rem',
  },
  radioLabel: {
    // marginBottom: -10
  },
  radioHelper: {
    fontSize: 12,
    color: '#acabac',
    margin: 0,
    marginBottom: 10,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
    paddingLeft: 15,
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    // borderLeft: `2px solid ${theme.palette.divider}`,
    // padding: theme.spacing(1, 2),
  },
  link: {
    // color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  textInfo: {
    color: '#6d7278',
    letterSpacing: '-0.12px',
    size: 12,
    textAlign: 'center',
    paddingTop: 30,
  },
  radioInput: {},
});

class ProfileModalContent extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: false,
      success: false,
      isExpanded: false,
    };
  }

  public onSubmit = async (
    values: IInitialValues,
    actions: FormikHelpers<IInitialValues>
  ) => {
    this.setState({ error: false, success: false });
    const { updateAccount } = this.props;
    try {
      await updateAccount({
        ...values,
        tags: (values.tags || []).map((t: any) => t.id),
      });
      location.reload();
      this.setState({ error: false, success: true });
    } catch (err) {
      console.error(err)
      this.setState({ error: true, success: false });
    }
    actions.setSubmitting(false);
  };

  public renderForm = (props: FormikProps<IInitialValues>) => {
    const { onClickAway, account, classes } = this.props;

    const { values } = props;

    const handleChange =
      () => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        this.setState({ isExpanded: !this.state.isExpanded });
      };

    return (
      <form onSubmit={props.handleSubmit}>
        <Grid container={true} spacing={1}>
          {!!this.state.error && (
            <FormMessage
              message={'There is an unexpected error, please try again.'}
              visible={!!this.state.error}
              type={'ERROR'}
            />
          )}
          <Grid item={true} xs={12}>
            <Field name="profilePicture" component={ProfileUpload} />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <Field
              name="firstName"
              component={TextInput}
              newTextInput={true}
              naked={true}
              disabled={true}
              inputProps={{
                id: 'firstName',
                label: 'First Name',
                type: 'firstName',
                disabled: true,
              }}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <Field
              name="lastName"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'lastName',
                label: 'last Name',
                type: 'lastName',
                disabled: true,
              }}
            />
          </Grid>
          {account.profile === 'CURATOR' && (
            <Grid item={true} xs={12} md={6}>
              <Field
                name="company_type"
                component={SelectInput}
                options={[
                  { label: 'Independent', value: 'INDEPENDANT' },
                  { label: 'Institution', value: 'INSTITUTION' },
                ]}
              />
            </Grid>
          )}
          {account.profile === 'CURATOR' && (
            <Grid item={true} xs={12} md={6} style={{ paddingTop: 20 }}>
              <Field
                name="company_name"
                component={TextInput}
                newTextInput={true}
                naked={true}
                inputProps={{
                  id: 'company_name',
                  label: 'Institution Name',
                  type: 'company_name',
                  disabled:
                    values.profile === 'CURATOR' &&
                    values.company_type === 'INDEPENDANT',
                }}
              />
            </Grid>
          )}
          {account.profile === 'VIEWER' && (
            <Grid item={true} xs={12} md={6}>
              <Field
                name="type"
                component={SelectInput}
                options={[
                  { label: 'Company', value: 'COMPANY' },
                  { label: 'Art Consultant', value: 'ART CONSULTANT' },
                  { label: 'Art Dealer', value: 'ART DEALER' },
                  { label: 'Art Fair', value: 'ART FAIR' },
                  { label: 'Auction House', value: 'AUCTION HOUSE' },
                  { label: 'Collector', value: 'COLLECTOR' },
                  { label: 'Art Lover', value: 'ART LOVER' },
                  { label: 'Institution', value: 'INSTITUTION' },
                  { label: 'Journalist', value: 'JOURNALIST' },
                  { label: 'Gallery', value: 'GALLERY' },
                  { label: 'Artist', value: 'ARTIST' },
                ]}
              />
            </Grid>
          )}
          <Grid item={true} xs={12} sm={12} md={12}>
            <Field
              name="location"
              component={LocationFinder}
              naked={true}
              newLocationFinder={true}
              inputProps={{
                id: 'location',
                label: 'Your City',
                type: 'location',
              }}
            />
          </Grid>

          <Grid item={true} xs={12} sm={12} md={12}>
            <Field
              name="tags"
              component={FormTagWrapper}
              inputProps={{
                id: 'tags',
                label:
                  values.profile === 'CURATOR'
                    ? 'Your Curatorial Expertise (you can input one or several expertises)'
                    : values.profile === 'ARTIST'
                    ? 'Art movement, expertise (you can input one or several expertises)'
                    : 'Interests, expertises',
                type: 'tags',
              }}
              helpText={
                'Please input any keywords describing your field of study, curatorial focus, specialization, etc...'
              }
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              name="bio"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'bio',
                label: 'Your Bio',
                type: 'bio',
                multiline: true,
                rows: 5,
                maxRows: 20,
              }}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <Field
                  name="website_url"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'website_url',
                    label: 'Your Website URL',
                    type: 'website_url',
                  }}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Field
                  name="twitter_url"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'twitter_url',
                    label: 'Your Twitter Account',
                    type: 'twitter_url',
                  }}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Field
                  name="facebook_url"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'facebook_url',
                    label: 'Your Facebook Account',
                    type: 'facebook_url',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item={true} xs={12} md={6}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <Field
                  name="instagram_url"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'instagram_url',
                    label: 'Your Instagram Account',
                    type: 'instagram_url',
                  }}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Field
                  name="linkedin_url"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'linkedin_url',
                    label: 'Your Linkedin Account',
                    type: 'linkedin_url',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12} className={classes.text}>
            <Accordion
              expanded={this.state.isExpanded}
              onChange={handleChange()}
              style={{
                boxShadow: '0px 0px',
                marginTop: 10,
                marginBottom: 20,
                backgroundColor: 'rgb(250, 250, 250)',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
                className={classes.details}
              >
                <div className={classes.column}>
                  <Typography className={classes.heading}>
                    Profile Settings
                  </Typography>
                </div>
              </AccordionSummary>

              <AccordionDetails className={classes.details}>
                <Grid item={false} className={classes.text}>
                  {account.profile === 'CURATOR' && (
                    <Grid
                      container={true}
                      className={classes.text}
                      direction="row"
                    >
                      <Grid
                        item={true}
                        xs={4}
                        className={classes.profileHeading}
                        style={{}}
                      >
                        Your profile’s public visibility:
                      </Grid>

                      <Grid item={true} xs={8} className={classes.text}>
                        <Field
                          name="profile_type"
                          component={RadioInput}
                          // classes={classes.radioInput}
                          inputProps={{
                            id: 'profile_type',
                            label: 'Your profile’s public visibility',
                            type: 'profile_type',
                          }}
                          options={[
                            {
                              label: 'Public (recommended)',
                              value: 'PUBLIC',
                              helper:
                                'Build your portfolio, share it and make it visible on search results (Google, Bing…)',
                            },
                            {
                              label: 'Artpool members only',
                              value: 'PRIVATE',
                            },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    container={true}
                    className={classes.text}
                    direction="row"
                  >
                    <Grid
                      item={true}
                      xs={4}
                      className={
                        account.profile === 'CURATOR'
                          ? classes.profileHeading
                          : classes.profileHeadingViewer
                      }
                      style={{}}
                    >
                      Newsletters preference:
                    </Grid>
                  </Grid>
                  <Grid
                    container={true}
                    className={classes.text}
                    direction="row"
                  >
                    <Grid
                      item={true}
                      xs={12}
                      className={classes.text}
                      style={{}}
                    >
                      <Field
                        name="newsletter_network"
                        component={CheckboxInput}
                        classes={classes.checkboxClass}
                        inputProps={{
                          id: 'newsletter_network',
                          type: 'newsletter_network',
                        }}
                        label={
                          'I agree to receive latest news from our network (sent approximately once a month).'
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container={true}
                    className={classes.text}
                    direction="row"
                  >
                    <Grid
                      item={true}
                      xs={12}
                      className={classes.text}
                      style={{}}
                    >
                      <Field
                        name="newsletter_curtain"
                        component={CheckboxInput}
                        classes={classes.checkboxClass}
                        inputProps={{
                          id: 'newsletter_curtain',
                          type: 'newsletter_curtain',
                        }}
                        label={
                          'I subscribe to Curtain, the Artpool Magazine newsletter. Twice a month, get your insider’s roundup of the latest curators interviewed.'
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item={true} xs={12} className={classes.textInfo}>
                    For any modification to username or email or to delete your
                    profile, please send your request to{' '}
                    <a href="mailto:hello@artcuratorgrid.com">
                      hello@artcuratorgrid.com
                    </a>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={6}>
              <DesignedButton
                ACGType={'neutral'}
                disabled={props.isSubmitting}
                size={'large'}
                fullWidth={true}
                onClick={onClickAway}
              >
                Cancel
              </DesignedButton>
            </Grid>

            <Grid item={true} xs={6}>
              <DesignedButton
                ACGType={'main'}
                disabled={props.isSubmitting}
                onClick={() => this.onSubmit(values, props)}
                size={'large'}
                fullWidth={true}
              >
                Save your changes
              </DesignedButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  };

  public render() {
    const { account } = this.props;

    const initialValues: IInitialValues = {
      ...account,
    };

    const config: IValidationConfig = {
      email: ['REQUIRED'],
      firstName: ['REQUIRED'],
      lastName: ['REQUIRED'],
      profilePicture: ['REQUIRED'],
    };

    const validate = generateValidator(config);

    return (
      <ACGModalContent>
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={this.onSubmit}
        >
          {(props) => this.renderForm(props)}
        </Formik>
      </ACGModalContent>
    );
  }
}

export default compose<Props, IProfileModalContentProps>(
  withStyles(style),
  withAccount
)(ProfileModalContent);
