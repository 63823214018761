import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { PRIMARY_COLOR, GREY_COLOR, SPACING } from '../../styles/constants';
import { compose } from 'recompose';
import { RouteComponentProps, withRouter } from 'react-router';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import AuthenticationService from '../../services/AuthenticationService';
import Banner from '../../library/sections/Banner';
import Section from '../../library/sections/Section';
import IconWorld from '../../library/dataDisplay/icons/IconWorld';
import { ApplyNow } from './sections/apply-now';
import { ACGAppPaths } from '@acg/shared/const';

type Props = RouteComponentProps<{}> &
  WithStyles<string> &
  IWithAccountProps &
  WithWidth;

interface IForInstitutionsPageProps {}

const style = createStyles({
  wrapper: {
    fontFamily: 'Replica-Regular',
  },
  bg: {
    padding: 20,
    backgroundColor: GREY_COLOR,
  },
  bgImg: {
    marginTop: 149,
    minHeight: 530,
  },
  primary: {
    color: PRIMARY_COLOR,
    textDecoration: 'none',
    '& h1': {
      lineHeight: 0.7,
    },
  },
  textMobile: {
    '& h1': {
      fontSize: 35,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 15,
    },
  },

  textDesktop: {
    '& h1': {
      fontSize: 50,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 30,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 16,
    },
  },
  whiteWrapper: {
    backgroundColor: GREY_COLOR,
    padding: '0 20px',
    // display: 'inline-table'
  },
  align: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  blogImg: {
    width: '100%',
    height: 213,
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'flex-end',
  },
  blogMobileImg: {
    width: '100%',
    height: 257,
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'flex-end',
  },
  lineLeftWrapper: {
    padding: `0 ${SPACING}px 0 0`,
    display: 'block',
  },
  lineRightWrapper: {
    padding: `0 0 0 ${SPACING}px`,
    display: 'block',
  },
  lineBottom: {
    borderBottom: '1px solid',
    marginBottom: 10,
  },
  lineRight: {
    borderRight: '1px solid',
    height: '100%',
  },
  blogPostContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  blogLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  blogImgContainer: {
    color: 'white',
    display: 'flex',
    height: 675,
    alignItems: 'flex-end',
  },
  overlayContainer: {
    // height: 100%,
    position: 'relative',
  },
  overlay: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    backgroundColor: 'rgba(246, 246, 246, 0.7)',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      opacity: 1,
    },
  },
  section: {
    padding: '82px 153px',
  },
  bannerButton: {
    borderRadius: 8,
    padding: '10px 37px',
    width: 'fit-content',
  },
  bannerButtonFirefox: {
    width: '-moz-fit-content',
  },
});

class ForInstitutionsPage extends React.Component<Props, any> {
  private authenticationService = new AuthenticationService();

  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      data: [],
      curatorCount: undefined,
      loading: false,
    };
  }

  public renderBanner = () => {
    const texts = {
      title: 'Are you an art institution?',
      subTitle:
        'Effectively manage and archive your exhibitions, collections and programs to capture local and global audiences!',
      buttonText: 'Apply now!',
    };

    return (
      <Banner
        texts={texts}
        image={
          '/assets/images/acg/for-institutions/For Institution_image_new1.jpg'
        }
      />
    );
  };

  public render() {
    const { classes, width, history } = this.props;

    if (this.authenticationService.isAuthenticated()) {
      history.push(ACGAppPaths.ROOT);
    }

    const isPad = isWidthDown('md', width);

    return (
      <div
        className={`${classes.wrapper} ${
          isPad ? classes.textMobile : classes.textDesktop
        }`}
      >
        {this.renderBanner()}
        {/*---- Curate -----*/}
        <Section
          texts={{
            title: `Save time and money with a comprehensive tool to effectively manage and archive your exhibitions, collections and programs.`,
            subTitle: `Supplemental content like interviews, studio visits, work in progress images and educational texts can easily be added to make your digital exhibition more engaging and informative.`,
          }}
          icon={
            <img
              src={
                '/assets/images/acg/for-institutions/For Institution_image_new2.jpg'
              }
              style={{ width: '100%' }}
            />
          }
        />
        {/*---- Share -----*/}
        <Section
          grey={true}
          reverse={true}
          texts={{
            title: `Capture local and global audiences with engaging and accessible content.`,
            subTitle: `From your local art enthusiast to your major art benefactor, ACG makes it easy for you to promote your programming.`,
          }}
          icon={<IconWorld height={300} fill="#afb0b3" />}
        />
        {/*---- Connect -----*/}
        <Section
          texts={{
            title: `Search for the perfect curatorial fit.`,
            subTitle: `Connect to experts from around the world.`,
          }}
          icon={
            <img
              src={
                '/assets/images/acg/for-institutions/For Institution_image_new3.jpg'
              }
              style={{ width: '100%' }}
            />
          }
        />
        {/*---- Discover -----*/}
        <Section
          grey={true}
          reverse={true}
          texts={{
            title: `Discover how our Exhibition Tool can create a new link with your audiences.`,
            subTitle: `The tool is free for non-profit.`,
          }}
          icon={
            <img
              src={
                '/assets/images/acg/for-institutions/For Institution_image_new4.jpg'
              }
              style={{ width: '100%' }}
            />
          }
        />
        <ApplyNow />
      </div>
    );
  }
}

export default compose<Props, IForInstitutionsPageProps>(
  withStyles(style),
  withRouter,
  withAccount,
  withWidth()
)(ForInstitutionsPage);
