import ApiServices, { IDataResponse, IDataListResponse } from './ApiServices';
import { IComment } from '../types/comments';

export interface ICommentFilters {}

class CommentService {
  public getPostComments = (
    postId: string,
    filters?: ICommentFilters
  ): Promise<IDataListResponse<IComment>> => {
    return ApiServices.getRequest(`posts/${postId}/comments`, filters);
  };

  public createPostComment = (
    postId: string,
    comment: Partial<IComment>
  ): Promise<IDataResponse<IComment>> => {
    return ApiServices.postRequest(`posts/${postId}/comments`, comment);
  };

  public updatePostComment = (
    id: string,
    commentId: string,
    comment: Partial<IComment>
  ): Promise<IDataResponse<IComment>> => {
    return ApiServices.putRequest(`posts/${id}/comments/${commentId}`, comment);
  };

  public deletePostComment = (id: string, commentId: string) => {
    return ApiServices.deleteRequest(`posts/${id}/comments/${commentId}`);
  };
}

export default CommentService;
