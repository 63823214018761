import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';

import { Tabs, Tab, AppBar } from '@mui/material';

import { withAccount, IWithAccountProps } from '../account/InjectAccount';
import PaddedLayout from '../../library/layout/container/PaddedLayout';
import { SPACING } from '../../styles/constants';
import TabPanel from '../../components/tabs/TabPanel';
import UserSection from './UsersSection/UserSection';
import InstitutionSection from './InstitutionsSection/InstitutionSection';
import GeneralSection from './GeneralSection/GeneralSection';

export interface IPostTypeFormProps {
  onDone: () => void;
  onClickSelect: (id: string) => void;
  show: boolean;
}

const style = createStyles({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  titleContainer: {
    padding: SPACING,
  },
  button: {
    padding: 25,
    borderRadius: 100,
    marginBottom: 20,
  },
  buttonSmall: {
    padding: '7px 20px',
    minHeight: 'auto',
    borderRadius: 30,
    display: 'inline-block',
    width: 'auto',
    marginLeft: 5,
    fontSize: '0.6em',
    minWidth: 0,
  },
  closeButton: {
    position: 'absolute',
    right: '2px',
    top: '2px',
  },
  tab: {
    width: '90%',
  },
});

interface IState {
  tabValue: number;
}

type Props = WithStyles<string> & IPostTypeFormProps & IWithAccountProps;

class BackOffice extends React.Component<any, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabValue: 1,
    };
  }

  public render() {
    const { account } = this.props;

    const { tabValue } = this.state;

    const handleChange = (evt: any, value: number) => {
      this.setState({ tabValue: value });
    };

    const a11yProps = (index: any) => {
      return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
      };
    };

    if (!account.isBackOfficeAdmin) {
      return (
        <PaddedLayout>
          <h1>You don't have access to this page</h1>
        </PaddedLayout>
      );
    } else {
      return (
        <PaddedLayout>
          <AppBar position="static" color="default" style={{ zIndex: 90 }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              centered={true}
            >
              <Tab label="Users" value={1} {...a11yProps(1)} />
              <Tab label="Venues" value={2} {...a11yProps(2)} />
              <Tab label="General" value={3} {...a11yProps(3)} />
            </Tabs>
          </AppBar>

          <TabPanel index={1} value={tabValue}>
            <UserSection />
          </TabPanel>
          <TabPanel index={2} value={tabValue}>
            <InstitutionSection />
          </TabPanel>
          <TabPanel index={3} value={tabValue}>
            <GeneralSection />
          </TabPanel>
        </PaddedLayout>
      );
    }
  }
}

export default compose<Props, IPostTypeFormProps>(
  withStyles(style),
  withAccount
)(BackOffice as any);
