import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import instagram from '../../../../img/instagram.svg';
import instagramGray from '../../../../img/gray/instagram.svg';
import { compose } from 'recompose';
import {} from '@mui/material';

export interface IInstagramProps {
  big?: boolean;
  fontSize?: number;
  type?: 'STANDARD' | 'GRAY';
}

type Props = IInstagramProps & WithStyles<string>;

const styles = createStyles({
  size: {
    width: 17,
    cursor: 'pointer',
  },
  big: {
    width: 34,
    cursor: 'pointer',
  },
});

class Instagram extends React.Component<Props, any> {
  public render() {
    const { classes, big, fontSize, type } = this.props;

    return (
      <img
        src={type === 'GRAY' ? instagramGray : instagram}
        className={big ? classes.big : classes.size}
        style={fontSize ? { width: fontSize } : undefined}
        alt="Instagram"
      />
    );
  }
}

export default compose<Props, IInstagramProps>(withStyles(styles))(Instagram);
