import React from 'react';
import { Grid } from '@mui/material';
import styles from './banner.module.scss';
import { JsonProject } from '@acg/artpool-api-spec';
import Stack from '@mui/material/Stack';
import { NEUTRAL, PixeledArrowDownIcon } from '@acg/assets';

interface BannerLayoutProps {
  featuredProject?: JsonProject;
  children: React.ReactNode;
  imageComponent: React.ReactNode;
  hideTabs?: boolean;
}

export const BannerLayout = (props: BannerLayoutProps) => {
  const { featuredProject, hideTabs } = props;

  const highlightedOpts = featuredProject?.highlightedOpts;
  return (
    <Grid
      container
      className={`${!highlightedOpts ? styles.bannerWhite : styles.banner}`}
    >
      <Grid item className={styles.bannerContent} xs={12} lg={7}>
        {props.children}
      </Grid>
      <Grid item xs={12} lg={5} style={{ padding: 30, textAlign: 'center' }}>
        {props.imageComponent}
      </Grid>
    </Grid>
  );
};
