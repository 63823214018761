import { Grid } from '@mui/material';
import { useState } from 'react';
import { ArtpoolLogo, IArtpoolLogoProps } from './artpool-logo';

export const ArtpoolLogoWithPortugal2020 = (
  props: IArtpoolLogoProps
) => {
  const { isMobile, isPad } = props;

  const imageStyles = {
    maxWidth: 250,
    marginTop: isPad ? -50 :  -52,
    marginLeft:  isPad ? -54 :-47,
    display: 'block',
  };

  const withStyles = {
    marginLeft: '-100%',
    marginTop: isPad ? -39 : -42,
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
  };

  return (
    <Grid container direction="row" alignItems="flex-end">
      <Grid item>
        <ArtpoolLogo
          {...props}
        />
      </Grid>
      {!isMobile && (
        <>
          <Grid item>
            <p style={withStyles}>with</p>
          </Grid>
          <Grid item>
            <a
              href={`/assets/images/acg/Portugal2020Doc.pdf`}
              target={'_blank'}
              rel="noopener noreferrer"
              download={false}
            >
              <img
                src={'/assets/images/acg/Lisboa2020_RGB_1.png'}
                style={imageStyles}
              />
            </a>
          </Grid>
        </>
      )}
    </Grid>
  );
};
