import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { Dialog, Grid } from '@mui/material';
import { compose } from 'recompose';
import { RouteComponentProps, withRouter } from 'react-router';
import ACGDialogTitle from '../../components/modal/ACGDialogTitle';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import { IExhibition } from '../../types/exhibitions';
import DesignedButton from '../../library/inputs/DesignedButton';
import { useDispatch } from 'react-redux';
import * as exhibitionsActions from '../../store/modules/exhibitions/actions';
import { FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { FormControl, TextField } from '@mui/material';
import { useFormikMui } from '@acg/frontend-utils';

export type SharedObject = ISharedExhibition;

export interface ISharedExhibition {
  type: 'EXHIBITION';
  object: IExhibition;
}

export interface ISocialModalProps {
  open: boolean;
  onClose: () => void;
  hideShare?: boolean;
  object: SharedObject;
}

interface FormValues {
  inviteEmail: string;
}

type Props = ISocialModalProps &
  WithStyles<string> &
  RouteComponentProps<{}> &
  IWithAccountProps;

const styles = createStyles({
  social: {
    fontFamily: 'Replica-Regular',
    fontSize: 12,
    fontWeight: 300,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#000000',
  },
  socialWrapper: {
    padding: '28px 0',
  },
  linkWrapper: {
    padding: '0 10px',
  },
  noDecoration: {
    textDecoration: 'none',
  },
  socialIcon: {
    textAlign: 'center',
  },
  linkBox: {
    border: 'solid 1px #dadada',
    backgroundColor: '#fafbfb',
    fontFamily: 'Replica-Regular',
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#929292',
    padding: '13px 27px',
    textAlign: 'center',
  },
  buttonWrapper: {
    textAlign: 'center',
    padding: '20px 0',
  },
  socialBlock: {
    cursor: 'pointer',
    display: 'inline-block',
    margin: '0 15px',
  },
  titleHeader: {
    color: '#6e32ff',
    fontSize: 20,
    fontFamily: 'ReplicaPro',
  },
  invitesTitle: {
    color: '#afb0b3',
    fontSize: 12,
    fontFamily: 'ReplicaPro',
  },
  linkTitle: {
    color: '#6e32ff',
    fontSize: 16,
    fontFamily: 'ReplicaPro',
  },
  invitesSection: {
    paddingTop: 20,
    paddingBottom: 20,
  },
});

const ShareEditModal: React.FC<Props> = (props) => {
  let textArea: any;

  const { onClose, open, classes, object, account } = props;

  const [copied, setCopied] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  let inviteUrl = window.location.href;

  inviteUrl = `${window.location.protocol}//${window.location.host}/create-online-exhibition/${object.object.url}?key=${object.object.edit_key}`;

  const onCopy = () => {
    textArea.select();
    document.execCommand('copy');
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const invitations = invitedUsers.map((userEmail: string, index: number) => (
    <>
      <span key={'invite_' + index}>{userEmail}</span> <br />
    </>
  ));

  useEffect(() => {
    if (object.object.invited_users) {
      setInvitedUsers(object.object.invited_users);
    }
  }, []);

  const validationSchema = Yup.object().shape({
    inviteEmail: Yup.string()
      .email('Email is not valid')
      .required('Enter a valid email'),
  });

  const onSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    setIsSubmitting(true);
    if (!object.object.invited_users) {
      object.object.invited_users = [];
    }

    if (_.indexOf(object.object.invited_users, values.inviteEmail) === -1) {
      dispatch(
        exhibitionsActions.inviteEditExhibition(
          object.object.id,
          values.inviteEmail
        )
      );
    }
    setIsSubmitting(false);
    actions.resetForm();
    actions.setSubmitting(false);
  };

  const { formik, formikProps } = useFormikMui<FormValues>({
    initialValues: {
      inviteEmail: '',
    },
    validationSchema,
    onSubmit,
  });

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
      keepMounted={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <ACGDialogTitle id="customized-dialog-title" onClose={onClose}>
        <span className={classes.titleHeader}>
          Invite to edit the Exhibition
        </span>
      </ACGDialogTitle>
      <Grid container={true} className={classes.linkWrapper}>
        <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
          <Grid item={true} xs={12}>
            <Grid container={true} spacing={1}>
              <Grid item={true} xs={9}>
                <FormControl fullWidth>
                  <TextField {...formikProps('inviteEmail')} />
                </FormControl>
              </Grid>
              <Grid item={true} xs={3}>
                <DesignedButton
                  ACGType={'main'}
                  size={'large'}
                  fullWidth={true}
                  type="submit"
                >
                  {isSubmitting ? 'Sending.' : 'Invite'}
                </DesignedButton>
              </Grid>
            </Grid>
          </Grid>
        </form>

        <Grid item={true} xs={12} className={classes.invitesSection}>
          <span className={classes.invitesTitle}>Collaborators invited</span>
          <hr />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flexGrow: 1, flexBasis: 0 }}>{invitations}</div>
          </div>
        </Grid>

        <Grid item={true} xs={12}>
          <span className={classes.linkTitle}>Share the Exhibition Link</span>
        </Grid>
        <Grid item={true} xs={12}>
          <div className={classes.linkBox}>{inviteUrl}</div>

          <div style={{ position: 'absolute', top: -99999, left: -99999 }}>
            <textarea
              ref={(textarea) => (textArea = textarea)}
              defaultValue={inviteUrl}
            />
          </div>
        </Grid>
        <Grid item={true} xs={12} className={classes.buttonWrapper}>
          <DesignedButton onClick={onCopy} size="small" ACGType={'neutral'}>
            <span style={{ padding: '0 10px' }}>
              {copied ? 'Copied to your clipboard !' : 'Copy Link'}
            </span>
          </DesignedButton>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default compose<Props, ISocialModalProps>(
  withRouter,
  withStyles(styles),
  withAccount
)(ShareEditModal);
