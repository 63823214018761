import { createRouteRequest } from 'typesafe-api';
import { AuthApiClient } from '@acg/shared/api-spec-utils';
import {
  PostAttemptMigrateEndpointDef,
  postAttemptMigrateRoute,
  PostForgotPasswordEndpointDef,
  postForgotPasswordRoute,
} from '@acg/artcuratorgrid-api-spec';

export class AuthMigrationClient extends AuthApiClient {
  private _postAttemptMigrate =
    createRouteRequest<PostAttemptMigrateEndpointDef>(
      this,
      postAttemptMigrateRoute
    );

  public attemptMigrate = (email: string, password: string) =>
    this._postAttemptMigrate({
      body: {
        email,
        password,
      },
    });

  private _postForgotPassword =
    createRouteRequest<PostForgotPasswordEndpointDef>(
      this,
      postForgotPasswordRoute
    );

  public forgotPassword = (email: string) =>
    this._postForgotPassword({
      body: {
        email,
      },
    });
}
