import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import facebook from '../../../../img/circles/facebook.svg';
import facebookGray from '../../../../img/circles/gray/facebook.svg';
import { compose } from 'recompose';
import {} from '@mui/material';

export interface IFacebookProps {
  fontSize?: number;
  type?: 'STANDARD' | 'GRAY';
}

type Props = IFacebookProps & WithStyles<string>;

const styles = createStyles({
  size: {
    width: 12,
    cursor: 'pointer',
  },
});

class Facebook extends React.Component<Props, any> {
  public render() {
    const { classes, fontSize, type } = this.props;

    return (
      <img
        src={type === 'GRAY' ? facebookGray : facebook}
        className={classes.size}
        style={fontSize ? { width: fontSize } : undefined}
      />
    );
  }
}

export default compose<Props, IFacebookProps>(withStyles(styles))(Facebook);
