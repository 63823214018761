import { ErrorType, ReqOptions, ResOptions, Route } from 'typesafe-api';
import { ArtpoolApiEndpoint, DefaultErrorCodes } from '@acg/artpool-api-spec';

export interface JsonEvmAccount {
  address: string;
}

/**
 * Get accounts
 */

export const getEvmAccountsRoute: Route = {
  method: 'get',
  path: '/evm/account',
};

export interface GetEvmAccountsResp extends ResOptions {
  body: {
    accounts: JsonEvmAccount[];
  };
}

export type GetEvmAccountsEndpointDef = ArtpoolApiEndpoint<
  ReqOptions,
  GetEvmAccountsResp
>;

/**
 * Account linked
 */

export const getEvmAccountLinkedRoute: Route = {
  method: 'get',
  path: '/evm/account/:address/linked',
};

export interface GetEvmAccountLinkedReq extends ReqOptions {
  params: {
    address: string;
  };
}

export interface GetEvmAccountLinkedResp extends ResOptions {
  body: {
    linked: boolean;
  };
}

export type GetEvmAccountLinkedDef = ArtpoolApiEndpoint<
  GetEvmAccountLinkedReq,
  GetEvmAccountLinkedResp
>;

/**
 * Membership Whitelist account
 */

export const postMembershipWhitelistEvmAccountRoute: Route = {
  method: 'post',
  path: '/evm/account/membership-whitelist',
};

export interface PostMembershipWhitelistEvmAccountReq extends ReqOptions {
  body: {
    address: string;
  };
}

export interface PostMembershipWhitelistEvmAccountResp extends ResOptions {
  body: {
    oldAddress?: string;
    newAddress: string;
  };
}

export type PostMembershipWhitelistEvmAccountDef = ArtpoolApiEndpoint<
  PostMembershipWhitelistEvmAccountReq,
  PostMembershipWhitelistEvmAccountResp,
  ErrorType<DefaultErrorCodes | 400>
>;

/**
 * Get Membership Whitelisted account
 */

export const getMembershipWhitelistedEvmAccountRoute: Route = {
  method: 'get',
  path: '/evm/account/membership-whitelist',
};

export interface GetMembershipWhitelistedEvmAccountResp extends ResOptions {
  body: {
    address?: string;
  };
}

export type GetMembershipWhitelistedEvmAccountDef = ArtpoolApiEndpoint<
  ReqOptions,
  GetMembershipWhitelistedEvmAccountResp,
  ErrorType<DefaultErrorCodes | 400>
>;

/**
 * Get payload
 */

export const postEvmSignaturePayloadRoute: Route = {
  method: 'post',
  path: '/evm/signature-payload',
};

export interface PostEvmSignaturePayloadReq extends ReqOptions {
  body: {
    address: string;
  };
}

export interface PostEvmSignaturePayloadResp extends ResOptions {
  body: {
    payload: string;
  };
}

export type PostEvmSignaturePayloadEndpointDef = ArtpoolApiEndpoint<
  PostEvmSignaturePayloadReq,
  PostEvmSignaturePayloadResp,
  ErrorType<DefaultErrorCodes | 400>
>;

/**
 * Add account
 */

export const postAddEvmAccountRoute: Route = {
  method: 'post',
  path: '/evm/account',
};

export interface PostAddEvmAccountReq extends ReqOptions {
  body: {
    signature: string;
  };
}

export type PostAddEvmAccountErrors = ErrorType<
  DefaultErrorCodes | 404 | 400 | 409
>;

export type PostAddEvmAccountEndpointDef = ArtpoolApiEndpoint<
  PostAddEvmAccountReq,
  ResOptions,
  PostAddEvmAccountErrors
>;
