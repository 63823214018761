import { createRouteRequest } from 'typesafe-api';
import { AuthApiClient } from '@acg/shared/api-spec-utils';
import {
  JsonNftWithRawFile,
  JsonNft,
  SendPrintRequestEmailEndpointDef,
  sendPrintRequestEmailRoute,
  SendPurchasedNFTEmailEndpointDef,
  sendPurchasedNFTEmailRoute,
} from '@acg/artpool-api-spec';

export class EmailClient extends AuthApiClient {
  /**
   * Send Print Request Email
   */
  private _sendPrintRequestEmail =
    createRouteRequest<SendPrintRequestEmailEndpointDef>(
      this,
      sendPrintRequestEmailRoute
    );

  public sendPrintRequestEmail = (body: { nft: JsonNftWithRawFile }) =>
    this._sendPrintRequestEmail({ body });

  /**
   * Send Purchased NFT Email
   */
  private _sendPurchasedNFTEmail =
    createRouteRequest<SendPurchasedNFTEmailEndpointDef>(
      this,
      sendPurchasedNFTEmailRoute
    );

  public sendPurchasedNFTEmail = (body: { nft: JsonNft; tsxHash: string }) =>
    this._sendPurchasedNFTEmail({ body });
}
