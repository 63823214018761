import React, { useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import Grid from '@mui/material/Grid';
import { toJsonPost } from '@acg/shared/utilities';
import { JsonPost } from '@acg/artcuratorgrid-api-spec';
import {
  PostCard,
  PostCardSkeleton,
  StyledButton,
  Subtitle2,
  Subtitle3,
} from '@acg/frontend-ui-artpool';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import { themes } from '@acg/assets';
import { Link } from 'react-router-dom';
import { artcuratorgridApiClient } from '../../../services/ArtcuratorgridApiClient';
import { ACGAppPaths } from '@acg/shared/const';
import { environment } from '../../../environments/environment';

const { REACT_APP_CLOUDFRONT_URL } = environment;

export const PostsSection = () => {
  const [posts, setPosts] = useState<JsonPost[]>([]);
  const isSmall = useMediaQuery(themes.artpoolTheme.breakpoints.down('md'));

  useAsyncEffect(async () => {
    const postsResp = await artcuratorgridApiClient().postsApi().getPosts();
    const jsonPosts = postsResp.data.data.map((post: any) => toJsonPost(post, REACT_APP_CLOUDFRONT_URL));
    setPosts(jsonPosts);
  }, []);

  return (
    <Grid container style={{ padding: '60px 20px' }} spacing={3}>
      <Grid item xs={12} textAlign={'center'} alignItems={'center'}>
        <Subtitle2>
          <b>Meet our Curators</b>
        </Subtitle2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent={'center'}>
          {!posts.length
            ? Array.apply(null, Array(6)).map((el, i) => {
                return (
                  <Grid item key={i}>
                    <PostCardSkeleton />
                  </Grid>
                );
              })
            : posts.map((post) => (
                <Grid item key={post.url}>
                  <PostCard
                    post={post}
                    cloudFrontUrl={REACT_APP_CLOUDFRONT_URL}
                  />
                </Grid>
              ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          textAlign={'center'}
          spacing={4}
          style={{ padding: '50px 20px' }}
        >
          <Grid item xs={12}>
            <Subtitle2>
              <b>We want to hear from you</b>
            </Subtitle2>
          </Grid>
          <Grid item xs={12}>
            <Subtitle3>
              Join our community of art curators globally and <br /> begin your
              collaborations today.
            </Subtitle3>
          </Grid>
          <Grid item xs={12}>
            <Link to={ACGAppPaths.LOGIN}>
              <StyledButton variant={'contained'} fullWidth={isSmall}>
                Join community
              </StyledButton>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
