import { urlJoin } from 'url-join-ts';
import { JsonImage, JsonProject } from '@acg/artpool-api-spec';

export const getImageUrl = (cloudFrontUrl: string, path: string): string => {
  if (!path) {
    return '';
  }
  if (path.includes('http')) {
    return path;
  }
  const newPath = path.startsWith('http')
    ? path
    : path.startsWith('/')
    ? path
    : '/' + path;

  return urlJoin(cloudFrontUrl, newPath);
};

export function getSmallImageUrl(cloudFrontUrl: string, path: string): string {
  if (!path) {
    return '';
  }
  let newPath = path.startsWith('http')
    ? path
    : path.startsWith('/')
    ? path
    : '/' + path;

  // gif files are only saved with original size in our database
  if (path.includes('.gif')) {
    newPath = path;
  } else {
    if (path.includes('-lg')) {
      newPath = path.replace('-lg', '-sm');
    }
    if (path.includes('-original')) {
      newPath = path.replace('-original', '-sm');
    }
  }

  if (newPath.includes('http')) {
    return newPath;
  }
  return urlJoin(cloudFrontUrl, newPath);
}

export const getMediumImageUrl = (cloudFrontUrl: string, path?: string): string => {
  if (!path) {
    return '';
  }

  let newPath = path.startsWith('http')
    ? path
    : path.startsWith('/')
    ? path
    : '/' + path;

  // gif files are only saved with original size in our database
  if (path.includes('.gif')) {
    newPath = path;
  } else {
    if (path.includes('-lg')) {
      newPath = path.replace('-lg', '-md');
    }
    if (path.includes('-original')) {
      newPath = path.replace('-original', '-md');
    }
  }

  if (newPath.includes('http')) {
    return newPath;
  }
  return urlJoin(cloudFrontUrl, newPath);
};

export const getOriginalImageUrl = (cloudFrontUrl: string, path?: string): string => {
  if (!path) {
    return '';
  }
  let newPath = path.startsWith('http')
    ? path
    : path.startsWith('/')
    ? path
    : '/' + path;

  // gif files are only saved with original size in our database
  if (path.includes('.gif')) {
    newPath = path;
  } else {
    if (path.includes('-lg')) {
      newPath = path.replace('-lg', '-original');
    }
    if (path.includes('-md')) {
      newPath = path.replace('-md', '-original');
    }
    if (path.includes('-sm')) {
      newPath = path.replace('-sm', '-original');
    }
    if (path.includes('-original')) {
      newPath = path.replace('-original', '-original');
    }
  }

  if (newPath.includes('http')) {
    return newPath;
  }
  return urlJoin(cloudFrontUrl, newPath);
};

export const projectImage = (project: JsonProject): JsonImage =>
  project.images.length !== 0 ? project.images[0] : project.nfts[0].image;
