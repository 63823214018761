import * as React from 'react';
import styles from './styled-button.module.scss';
import Button, { ButtonClasses, ButtonProps } from '@mui/material/Button';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

export enum ButtonColors {
  BLACK = 'black',
  GREEN = 'green',
  PINK = 'pink',
}

export interface StyledButtonProps extends ButtonProps {
  bgColor?: ButtonColors;
}

export function StyledButton(props: StyledButtonProps) {
  const classes: Partial<ButtonClasses> = {
    root: `${styles.root} ${
      props.size !== 'small' ? styles.padding_override : ''
    }`,
    text: styles[`text--${props.bgColor || ButtonColors.BLACK}`],
    contained: styles[`contained--${props.bgColor || ButtonColors.BLACK}`],
    outlined: styles[`outlined--${props.bgColor || ButtonColors.BLACK}`],
    disabled: styles.disabled,
  };
  return (
    <Button classes={classes} {...props}>
      <span className={styles.button_two}>{props.children}</span>
    </Button>
  );
}

export interface StyledLoadingButtonProps extends LoadingButtonProps {
  bgColor?: ButtonColors;
}

export function StyledLoadingButton(props: StyledLoadingButtonProps) {
  const style: React.CSSProperties = props.style ?? {};
  if (props.loading) {
    style.color = 'transparent';
  }

  const classes = {
    root: `${styles.root}`,
    contained: styles[`contained--${props.bgColor || ButtonColors.BLACK}`],
    outlined: styles[`outlined--${props.bgColor || ButtonColors.BLACK}`],
  };
  return (
    <LoadingButton classes={classes} {...props}>
      {props.children}
    </LoadingButton>
  );
}
