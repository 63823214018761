import * as React from 'react';
import { Redirect } from 'react-router';

import { compose } from 'recompose';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import Loading from '../../components/loading/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '../../store';
import { signOut } from '@acg/auth/frontend';
import useAsyncEffect from 'use-async-effect';

export interface ILogoutProps {}

type Props = ILogoutProps & IWithAccountProps;

const Logout: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (storeState: IStoreState) => storeState.account
  );
  const { user } = useSelector(
    (storeState: IStoreState) => storeState.authReducer
  );

  useAsyncEffect(async () => {
    if (user) {
      await signOut();
    }
  }, []);

  if (isLoading) {
    return (
      <div style={{ marginTop: 70 }}>
        <Loading />
      </div>
    );
  }
  return <Redirect to={'/login'} />;
};

export default compose<Props, ILogoutProps>(withAccount)(Logout);
