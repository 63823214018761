import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { InputBase, InputAdornment, Icon, Popper, Paper } from '@mui/material';
import { withRouter, RouteComponentProps } from 'react-router';
import { FormikProps, Formik } from 'formik';
import { IValidationConfig, generateValidator } from '../../utils/formatters';
import { connectAutoComplete, Highlight } from 'react-instantsearch-dom';
import { AutocompleteProvided, Hit } from 'react-instantsearch-core';
import { GREY_COLOR, PRIMARY_COLOR } from '../../styles/constants';
import {
  serializeUrl,
  parseSearch,
  QUERY_SEARCH_SETTINGS,
} from '../../utils/router';
import UserPicture from '../../library/dataDisplay/profile/UserPicture';
import { IUser } from '../../types/user';
import IconSearch from '../../library/dataDisplay/icons/IconSearch';

export interface ISearchUserFormProps {
  placeholder?: string;
  customClass?: any;
  setFilters: any;
  filters: any;
  closeFullScreen?: any;
}

type Props = ISearchUserFormProps &
  WithStyles<string> &
  RouteComponentProps<{}> &
  AutocompleteProvided<string>;

const style = createStyles({
  wrapper: {
    width: '100%',
  },
  input: {
    backgroundImage:
      'linear-gradient(to left, #ffffff, #e7e8e9), linear-gradient(to bottom, #e7e8e9, #e7e8e9);',
    height: 40,
    width: '100%',
  },
  iconStart: {
    marginLeft: 5,
    color: '#848484',
  },
  iconEnd: {
    marginRight: 25,
    cursor: 'pointer',
    color: '#848484',
  },
  item: {
    padding: 20,
    borderBottom: `1px solid ${GREY_COLOR}`,
    cursor: 'pointer',
  },
  dropdown: {
    marginTop: 8,
    maxHeight: 300,
    overflow: 'scroll',
  },
  separator: {
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.12,
    textAlign: 'center',
    color: PRIMARY_COLOR,
  },
});

interface IFormValue {
  search: string;
}

interface IState {
  shouldShowPredicates: boolean;
}

class SearchUserForm extends React.Component<Props, IState> {
  private initialValue: IFormValue = {
    search: '',
  };

  private ref: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      shouldShowPredicates: false,
    };
  }

  public onChange =
    (onFormChange: React.ChangeEventHandler<HTMLInputElement>) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      this.props.setFilters({ query: e.target.value });
      if (e.target.value) {
        this.setState({ shouldShowPredicates: true });
        this.props.refine(e.target.value);
      }
      onFormChange(e);
    };

  public onSubmit = (values: IFormValue, selected: any) => {
    const { history } = this.props;

    // To close the full width of the sarchbar
    this.props.closeFullScreen();

    const deserializedUrl = serializeUrl(this.props.filters);

    // Clicking on one of the suggestions pushes directly to the Curator profile
    if (typeof selected === 'string') {
      history.push(`/users/${selected}/posts`);
    } else if (this.props.filters) {
      history.push(`/users?${deserializedUrl}`);
    } else {
      history.push(`/users`);
    }
    this.setState({ shouldShowPredicates: false });
  };

  public onClick =
    (query: string, selected: any, setValues: (a: IFormValue) => void) =>
    (event: any) => {
      // window.console.log("EVENT", event)
      event.preventDefault();
      setValues({ search: query });
      this.onSubmit({ search: query }, selected);
    };

  public renderSuggestion = (
    query: any,
    h: Hit<string>,
    setValues: (a: IFormValue) => void
  ) => {
    const { classes } = this.props;
    return (
      <div
        key={h.objectID}
        className={classes.item}
        onMouseDown={this.onClick(query, (h as any).url, setValues)}
      >
        <UserPicture
          user={h as any as IUser}
          style={{ float: 'left', marginRight: 10, width: 20, height: 20 }}
        />
        <Highlight attribute="firstName" hit={h} tagName="strong" />{' '}
        <Highlight attribute="lastName" hit={h} tagName="strong" />
      </div>
    );
  };

  public renderForm = (props: FormikProps<IFormValue>) => {
    const { classes, history, hits, placeholder, customClass } = this.props;

    const { values, setValues, handleSubmit } = props;

    const onClear = (event: any) => {
      setValues({ search: '' });

      const filterObject = this.props.filters;
      delete filterObject.query;

      this.props.setFilters(filterObject);

      const deserializedUrl = serializeUrl(filterObject);

      history.push(`/users?${deserializedUrl}`);
    };

    return (
      <form onSubmit={handleSubmit} ref={(e: any) => (this.ref = e)}>
        <InputBase
          id="outlined-name"
          name="search"
          className={customClass || classes.input}
          placeholder={placeholder}
          autoComplete={'off'}
          onBlur={props.handleBlur}
          onChange={this.onChange(props.handleChange)}
          value={values.search}
          startAdornment={
            <InputAdornment position="start" className={classes.iconStart}>
              <IconSearch />
            </InputAdornment>
          }
          endAdornment={
            values.search && (
              <InputAdornment
                onMouseDown={onClear}
                position="end"
                className={classes.iconEnd}
              >
                <Icon>clear</Icon>
              </InputAdornment>
            )
          }
        />
        {/*@ts-ignore*/}
        <Popper
          open={this.state.shouldShowPredicates && values.search.length > 0}
          anchorEl={this.ref}
          container={this.ref}
          style={{ zIndex: 10000, position: 'inherit' }}
        >
          <div>
            <Paper
              square={true}
              style={{ width: this.ref ? this.ref.clientWidth : null }}
              className={classes.dropdown}
            >
              {hits.map((h: any) => {
                return this.renderSuggestion(values.search, h, setValues);
              })}
            </Paper>
          </div>
        </Popper>
        <button type="submit" style={{ display: 'none' }} />
      </form>
    );
  };

  public render() {
    const {
      location: { search },
      classes,
    } = this.props;

    const config: IValidationConfig = {
      search: [],
    };

    const validate = generateValidator(config);

    const filter = parseSearch(search, QUERY_SEARCH_SETTINGS);
    if (filter.query) {
      this.initialValue = {
        search: filter.query,
      };
    }

    return (
      <div className={classes.wrapper}>
        <Formik
          initialValues={this.initialValue}
          validate={validate}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
      </div>
    );
  }
}

export default compose<Props, ISearchUserFormProps>(
  connectAutoComplete,
  withStyles(style),
  withRouter
)(SearchUserForm);
