import { createStyles, withStyles, WithStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';

export interface IFormClickProps {
  onClick: () => void;
  className?: string;
}

export type IFormLinkProps = (IFormClickProps | IFormToProps) & {
  children: React.ReactNode;
};

export interface IFormToProps {
  to: string;
  target?: string;
  className?: string;
}

const style = createStyles({
  root: {
    fontFamily: 'Replica-Regular',
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: '#2b2e34',
    width: '100%',
  },
});

type Props = WithStyles<string> & IFormLinkProps;

class FormLink extends React.Component<Props, any> {
  public render() {
    const { classes, className } = this.props;

    if ((this.props as IFormToProps).to) {
      const target = (this.props as IFormToProps).target;
      return (
        <Link
          to={(this.props as IFormToProps).to}
          target={target}
          className={`${classes.root} ${className ? className : ''}`}
        >
          {this.props.children}
        </Link>
      );
    }

    if ((this.props as IFormClickProps).onClick) {
      return (
        <a onClick={(this.props as IFormClickProps).onClick}>
          {this.props.children}
        </a>
      );
    }

    throw new Error('missing parameter');
  }
}

export default compose<Props, IFormLinkProps>(withStyles(style))(FormLink);
