import * as React from 'react';
import { IError } from '../../types/returns';
import CenterLayout from '../../library/layout/container/CenterLayout';
import { Paper } from '@mui/material';

export interface IUnexpectedErrorProps {
  error?: IError;
}

export default class UnexpectedError extends React.Component<
  IUnexpectedErrorProps,
  any
> {
  public render() {
    const { error } = this.props;
    return (
      <CenterLayout type={'STANDARD'}>
        <Paper elevation={1} style={{ padding: 30, textAlign: 'center' }}>
          An unexpected error has happened, please contact the support with the
          following error id: {error && error.error_id}
        </Paper>
      </CenterLayout>
    );
  }
}
