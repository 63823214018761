import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import * as React from 'react';
import { Grid } from '@mui/material';
import //
'@mui/material/';
import { compose } from 'recompose';
export interface IPublicIFrameLayoutProps {}

type Props = IPublicIFrameLayoutProps & WithWidth;

const PublicIFrameLayout = (props: any) => {
  return (
    <Grid
      container={true}
      direction="row"
      {...props}
      style={{ height: '100%', overflowY: 'auto' }}
      className="public-layout"
    >
      <Grid xs={12} sm={12} md={12} lg={12} item={true}>
        {props.children}
      </Grid>
    </Grid>
  );
};

export default compose<Props, IPublicIFrameLayoutProps>(withWidth())(
  PublicIFrameLayout
);
