import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import {} from '@mui/material';
import { compose } from 'recompose';
import { GREY_COLOR } from '../../styles/constants';

export interface IExhibitionPointSliderProps {
  mobile: boolean;
  mediaBackgroundStyle: any;
  media: any;
  headerMobile?: any;
  orderValue: string;
}

interface IState {}

const styles = createStyles({
  root: {
    backgroundColor: GREY_COLOR,
    // height: '100%'
    minHeight: '100%',
  },
  media: {
    height: '100%',
    // paddingRight:20,
    // display: 'flex',
    justifyContent: 'center',
    // alignItems: 'center',
    '& > *': {
      maxHeight: '100%',
    },
    overflowY: 'auto',
  },

  mediaMobile: {
    /*
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
        overflowY: 'auto',*/

    // height: '100%',
    // paddingRight:20,
    // display: 'flex',
    justifyContent: 'center',
    // alignItems: 'center',
    '& > *': {
      maxHeight: '100%',
    },
    overflowY: 'auto',
  },

  mediaBackground: {
    backgroundColor: GREY_COLOR,
    height: '100%',
    // textAlign: 'center'
  },
  mediaBackgroundMobile: {
    // height: 'calc(100% - 241px)'
  },
});

type Props = IExhibitionPointSliderProps & WithStyles<string> & WithWidth;

class ExhibitionPointSlider extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const {
      mobile,
      classes,
      mediaBackgroundStyle,
      media,
      headerMobile,
      orderValue,
    } = this.props;

    return (
      <div className={mobile ? classes.rootMobile : classes.root}>
        <div
          className={
            mobile ? classes.mediaBackgroundMobile : classes.mediaBackground
          }
          style={mediaBackgroundStyle}
        >
          <div className={mobile ? classes.mediaMobile : classes.media}>
            {headerMobile(orderValue)}
            {media}
          </div>
        </div>
      </div>
    );
  }
}

export default compose<Props, IExhibitionPointSliderProps>(
  withStyles(styles),
  withWidth()
)(ExhibitionPointSlider);
