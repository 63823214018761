import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import website from '../../img/website.svg';
import { compose } from 'recompose';
import {} from '@mui/material';

export interface IWebsiteProps {
  fontSize?: number;
}

type Props = IWebsiteProps & WithStyles<string>;

const styles = createStyles({
  size: {
    width: 20,
    cursor: 'pointer',
  },
});

class Website extends React.Component<Props, any> {
  public render() {
    const { classes, fontSize } = this.props;

    return (
      <img
        src={website}
        className={classes.size}
        style={fontSize ? { width: fontSize } : undefined}
      />
    );
  }
}

export default compose<Props, IWebsiteProps>(withStyles(styles))(Website);
