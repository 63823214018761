import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { Grid } from '@mui/material';
import { compose } from 'recompose';
import {
  InjectedFormikProps,
  Formik,
  FormikProps,
  FormikHelpers,
  Field,
} from 'formik';
import TextInput from '../../components/form/TextInput';
import { IValidationConfig, generateValidator } from '../../utils/formatters';
import FormMessage from '../../components/form/FormMessage';
import { withAccount, IWithAccountProps } from '../account/InjectAccount';
import ACGModalContent from '../../components/modal/ACGModalContent';
import ProfileSmall from '../../library/dataDisplay/profile/ProfileSmall';
import Title from '../../library/dataDisplay/typography/Title';
import { withRouter, RouteComponentProps } from 'react-router';
import FormTagWrapper from '../tags/FormTagWrapper';
import { ITag } from '../../types/tags';
import DesignedButton from '../../library/inputs/DesignedButton';
import { ACGAppPaths } from '@acg/shared/const';

export interface IOnboardingCuratorFormProps {
  onOnboardingEnd: () => void;
}

const style = createStyles({
  alignCenter: {
    textAlign: 'center',
  },
  title: {
    fontSize: 16,
  },
});

export interface IOnboardingCuratorInitialValue {
  bio: string;
  tags: ITag[];
}

interface IState {
  error: boolean;
}

type Props = WithStyles<string> &
  InjectedFormikProps<
    IOnboardingCuratorFormProps,
    IOnboardingCuratorInitialValue
  > &
  IWithAccountProps &
  RouteComponentProps<{}>;

class OnboardingCurator extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: false,
    };
  }

  public onSubmit = async (
    values: IOnboardingCuratorInitialValue,
    actions: FormikHelpers<IOnboardingCuratorInitialValue>
  ) => {
    const { updateAccount, account, history } = this.props;
    this.setState({ error: false });
    try {
      const newValues: any = {
        ...values,
        tags: values.tags.map((t) => t.id),
      };
      await updateAccount({
        ...account,
        ...newValues,
        hasTakenTour: true,
      });
      this.props.onOnboardingEnd();
      history.push('/login', { from: ACGAppPaths.ROOT });
    } catch (err) {
      this.setState({ error: true });
    }

    actions.setSubmitting(false);
  };

  public renderForm = (props: FormikProps<IOnboardingCuratorInitialValue>) => {
    const { onOnboardingEnd, classes, account } = this.props;

    return (
      <form onSubmit={props.handleSubmit}>
        <Grid container={true} spacing={1}>
          <FormMessage
            message={'There is an unexpected error, please try again.'}
            visible={!!this.state.error}
            type={'ERROR'}
          />

          <Grid item={true} xs={12}>
            <ProfileSmall user={account} />
          </Grid>
          <Grid className={classes.alignCenter} item={true} xs={12}>
            <br />
            <Title className={classes.title}>
              Welcome to Artpool.
            </Title>
            <Title className={classes.title}>
              We are proud to include you in our network, created especially for
              you.
            </Title>
            <br />
          </Grid>
          <Grid className={classes.alignCenter} item={true} xs={12}>
            <Title className={classes.title}>
              As a Curator, you can post your Exhibitions and Inspirations and
              we will help spotlight them in our art community.
            </Title>
            <br />
          </Grid>
          <Grid className={classes.alignCenter} item={true} xs={12}>
            <Title className={classes.title}>
              The first step is to tell us your story.
            </Title>
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              name="bio"
              component={TextInput}
              newTextInput={true}
              naked={true}
              inputProps={{
                id: 'bio',
                label: 'bio',
                type: 'bio',
                multiline: true,
                rows: 5,
                maxRows: 20,
              }}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              name="tags"
              component={FormTagWrapper}
              inputProps={{
                id: 'tags',
                label:
                  'Your Curatorial Expertise (you can input one or several expertises)',
                type: 'tags',
              }}
              helpText={
                'Please input any keywords describing your field of study, curatorial focus, specialization, etc...'
              }
              item={true}
              xs={12}
              md={6}
            />
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <Field
                  name="website_url"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'website_url',
                    label: 'Your Website URL',
                    type: 'website_url',
                  }}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Field
                  name="twitter_url"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'twitter_url',
                    label: 'Your Twitter Account',
                    type: 'twitter_url',
                  }}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Field
                  name="facebook_url"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'facebook_url',
                    label: 'Your Facebook Account',
                    type: 'facebook_url',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <Field
                  name="instagram_url"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'instagram_url',
                    label: 'Your Instagram Account',
                    type: 'instagram_url',
                  }}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Field
                  name="linkedin_url"
                  component={TextInput}
                  newTextInput={true}
                  naked={true}
                  inputProps={{
                    id: 'linkedin_url',
                    label: 'Your Linkedin Account',
                    type: 'linkedin_url',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item={true} xs={12}>
            <Grid container={true} spacing={1}>
              <Grid item={true} xs={6}>
                <DesignedButton
                  ACGType={'neutral'}
                  size={'large'}
                  fullWidth={true}
                  onClick={onOnboardingEnd}
                >
                  Cancel
                </DesignedButton>
              </Grid>
              <Grid item={true} xs={6}>
                <DesignedButton
                  ACGType={'main'}
                  type="submit"
                  size={'large'}
                  fullWidth={true}
                  disabled={props.isSubmitting}
                >
                  Post Now !
                </DesignedButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  };

  public render() {
    const initialValues: IOnboardingCuratorInitialValue = {
      bio: '',
      tags: [],
    };

    const validationRule: IValidationConfig = {
      bio: ['REQUIRED'],
    };

    const validation = generateValidator(validationRule);

    return (
      <ACGModalContent id="modal">
        <Formik
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          render={this.renderForm}
          validate={validation}
        />
      </ACGModalContent>
    );
  }
}

export default compose<Props, IOnboardingCuratorFormProps>(
  withStyles(style),
  withAccount,
  withRouter
)(OnboardingCurator);
