export enum EvmNetworkUrls {
  LOCAL = 'http://127.0.0.1:8545/',
  ROPSTEN = `https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161`,
  ETHEREUM = `https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161`,
  GOERLI = 'https://rpc.ankr.com/eth_goerli',
}

export enum HardhatNetworkName {
  ETHEREUM = 'ethereum',
  GOERLI = 'goerli',
}

export const getHardhatNetworkName = (
  evmNetworkUrl: EvmNetworkUrls
): HardhatNetworkName => {
  switch (evmNetworkUrl) {
    case EvmNetworkUrls.ETHEREUM:
      return HardhatNetworkName.ETHEREUM;
    case EvmNetworkUrls.GOERLI:
      return HardhatNetworkName.GOERLI;
    default:
      throw Error(`Evm network url ${evmNetworkUrl} is not supported`);
  }
};
