export interface IOnlineExhibitionPointIconProps {
  isMobile: boolean;
  isSelected: boolean;
  pointBackColor: string;
  pointColor: string;
  labelColor: string;
  pointLabel: string | number;
  isInfoPoint: boolean;
}

function OnlineExhibitionPointIcon(props: IOnlineExhibitionPointIconProps) {
  const {
    isMobile,
    isInfoPoint,
    isSelected,
    pointBackColor,
    pointColor,
    labelColor,
    pointLabel,
  } = props;

  const iconStyle = `shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality;fill-rule:evenodd; clip-rule:evenodd`;

  const fill = isSelected ? pointColor : pointBackColor;
  const stroke = isSelected ? pointBackColor : pointColor;

  const rectLayout = () => {
    const x = isMobile ? '12.8' : '7.5';
    const y = isMobile ? '12' : '7.5';
    const width = isMobile ? '24' : '35';
    const height = isMobile ? '24' : '35';

    return `<rect x='${x}' y='${y}' width='${width}'
                 height='${height}' rx='6' stroke-width='2'
                 fill='${fill}' stroke='${stroke}'/>`;
  };

  const circleLayout = () => {
    const r = isMobile ? 13 : 20;
    return `<circle cx='25' cy='24' r='${r}' stroke='${stroke}'
                stroke-width='2' fill='${fill}'/>`;
  };

  const labelLayout = () => {
    const fontSize = isMobile ? 12 : 18;
    const fillColor = isSelected ? pointBackColor : labelColor;

    return `<text x='50%' y='50%' text-anchor='middle' dy='.3em'
                font-family='Replica-Regular' font-size='${fontSize}'
                 fill='${fillColor}'>${pointLabel}</text>`;
  };

  const layout = isInfoPoint ? rectLayout : circleLayout;

  return `<svg xmlns="http://www.w3.org/2000/svg" style=${iconStyle} viewBox="0 0 50 50">
            <g>
              ${layout()}
              ${labelLayout()}
            </g>
          </svg>`;
}

export default OnlineExhibitionPointIcon;
