// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.
import {
  ArtcuratorgridApiUrls,
  ArtpoolApiUrls,
  EnvLabels,
  LOCAL_ARTPOOL_ETHER_WALLET,
  LOCAL_ARTPOOL_WALLET,
} from '@acg/shared/const';
import { ChainIds, IArtpoolEnvironment } from './utils';
import { EvmNetworkUrls } from '../../../../libs/shared/const/src/lib/evm';

export const environment: IArtpoolEnvironment = {
  production: false,
  envLabel: EnvLabels.LOCAL,
  sentryEnabled: false,
  sentryDNS:
    'https://1502250d2eff4e659d13350a0c1c50d6@o1107240.ingest.sentry.io/6134305',
  apiURL: ArtpoolApiUrls.LOCAL,
  artcuratorgridApiUrl: ArtcuratorgridApiUrls.LOCAL,
  stripePublishableKey: 'pk_test_SViOmEOcJQreq63TpAc5PHOR',
  substrateTestMode: true,
  artpoolSubstrateWallet: LOCAL_ARTPOOL_WALLET,
  allowedChainIds: [ChainIds.LOCAL],
  artpoolEtherWallet: LOCAL_ARTPOOL_ETHER_WALLET,
  acgCloudFrontUrl: "https://d3d71cpl3fvrzd.cloudfront.net",
  evm: {
    ethereum: {
      rpcUrl: EvmNetworkUrls.LOCAL,
    },
  },
};
