import { IValidationError } from '../types/returns';

export const formatErrors = (
  errors: IValidationError
): { [key: string]: string } | undefined => {
  if (errors.status === 'validation_error') {
    const validations = errors.error_message;
    return validations.reduce((a, i) => {
      return { ...a, [i.param]: i.msg };
    }, {});
  }
  return undefined;
};

export interface IValidationConfig {
  [key: string]: ValidationConfigType[];
}

type RequiredMatch = (value: any) => 'REQUIRED' | null;

export type ValidationConfigType =
  | 'REQUIRED'
  | 'IS_EMAIL'
  | 'SHORT_PASS'
  | { eq: string }
  | RequiredMatch;

export const generateValidator =
  (config: IValidationConfig) => (values: any) => {
    const errors: Record<string, string> = {};
    Object.keys(config).map((key) => {
      return config[key].map((configType) => {
        if (configType === 'REQUIRED') {
          if (!values[key]) {
            errors[key] = `This field is required`;
          }
          return;
        }
        if (configType === 'SHORT_PASS') {
          if (values[key].length < 4) {
            errors[key] = `The password must be at least 4 characters long`;
          }
          return;
        }
        if (configType === 'IS_EMAIL') {
          if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)
          ) {
            errors[key] = `Please enter a valid email address.`;
          }
          return;
        }
        if ((configType as { eq: string }).eq) {
          if (values[key] !== values[(configType as { eq: string }).eq]) {
            errors[key] = `Should be the same value as ${
              (configType as { eq: string }).eq
            }`;
          }
          return;
        }
        if (typeof configType === 'function') {
          const configTypeFunc = configType as RequiredMatch;
          const result = configTypeFunc(values);
          if (result === 'REQUIRED') {
            errors[key] = `This field is required`;
          }
        }
      });
    });

    return errors;
  };
