import { v1 as uuid } from 'uuid';

const BEARER_TOKEN_NAME = 'bearer_token';
const BEARER_TOKEN_EXPIRES_AT = 'bearer_expires_at';

const SESSION_TOKEN = 'session_token';
const SESSION_TOKEN_EXPIRES_AT = 'session_expires_at';
const SESSION_TOKEN_EXPIRATION_DURATION = 3600 * 1000; // 1 hour

export default class AuthenticationService {
  public isAuthenticated = () => {
    const bearerToken = this.getCookie(BEARER_TOKEN_NAME);
    const bearerExpiresAt = this.getCookie(BEARER_TOKEN_EXPIRES_AT);

    if (!this.asValidSessionToken()) {
      this.generateSessionToken();
    }

    return !!(
      bearerToken &&
      bearerExpiresAt &&
      parseInt(bearerExpiresAt, 10) > Date.now()
    );
  };

  public asValidSessionToken = () => {
    const sessionToken = this.getCookie(SESSION_TOKEN);
    const sessionrExpiresAt = this.getCookie(SESSION_TOKEN_EXPIRES_AT);
    return !!(
      sessionToken &&
      sessionrExpiresAt &&
      parseInt(sessionrExpiresAt, 10) > Date.now()
    );
  };

  public getSessionToken = () => {
    return this.getCookie(SESSION_TOKEN);
  };

  private getCookie = (cname: string) => {
    const val = localStorage.getItem(cname);
    if (val) {
      return val;
    }
    return '';
  };

  private generateSessionToken = () => {
    const generatedSession = uuid();

    localStorage.setItem(SESSION_TOKEN, generatedSession);
    localStorage.setItem(
      SESSION_TOKEN_EXPIRES_AT,
      `${Date.now() + SESSION_TOKEN_EXPIRATION_DURATION}`
    );
  };
}
