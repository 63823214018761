import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { InputBase, InputAdornment, Icon, Popper, Paper } from '@mui/material';
import { withRouter, RouteComponentProps } from 'react-router';
import { FormikProps, Formik } from 'formik';
import { IValidationConfig, generateValidator } from '../../utils/formatters';
import { parseSearch, QUERY_SEARCH_SETTINGS } from '../../utils/router';
import { connectAutoComplete, Highlight } from 'react-instantsearch-dom';
import { AutocompleteProvided, Hit } from 'react-instantsearch-core';
import {
  DARK_GREY_COLOR,
  GREY_COLOR,
  PRIMARY_COLOR,
} from '../../styles/constants';
import IconSearch from '../../library/dataDisplay/icons/IconSearch';
import { ACGAppPaths } from '@acg/shared/const';

export interface ISearchProps {
  placeholder?: string;
  customClass?: any;
}

type Props = ISearchProps &
  WithStyles<string> &
  RouteComponentProps<{}> &
  AutocompleteProvided<string>;

const style = createStyles({
  wrapper: {
    width: '100%',
  },
  input: {
    backgroundImage:
      'linear-gradient(to left, #ffffff, #e7e8e9), linear-gradient(to bottom, #e7e8e9, #e7e8e9);',
    height: 40,
    width: '100%',
    color: DARK_GREY_COLOR,
  },
  iconStart: {
    marginLeft: 5,
    color: '#848484',
  },
  iconEnd: {
    marginRight: 25,
    cursor: 'pointer',
    color: '#848484',
  },
  item: {
    padding: 10,
    borderBottom: `1px solid ${GREY_COLOR}`,
    cursor: 'pointer',
  },
  dropdown: {
    marginTop: 8,
    maxHeight: 300,
    overflow: 'scroll',
  },
  separator: {
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.12,
    textAlign: 'center',
    color: PRIMARY_COLOR,
  },
});

interface IFormValue {
  search: string;
}

interface IState {
  shouldShowPredicates: boolean;
}

class SearchPostsForm extends React.Component<Props, IState> {
  private initialValue: IFormValue = {
    search: '',
  };

  private ref: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      shouldShowPredicates: false,
    };
  }

  public onChange =
    (onFormChange: React.ChangeEventHandler<HTMLInputElement>) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value) {
        this.setState({ shouldShowPredicates: true });
        this.props.refine(e.target.value);
      }
      onFormChange(e);
    };

  public onSubmit = (values: IFormValue) => {
    const { history } = this.props;
    if (values.search) {
      history.push(ACGAppPaths.ROOT + `?query=${values.search}`);
    } else {
      history.push(ACGAppPaths.ROOT);
    }
    this.setState({ shouldShowPredicates: false });
  };

  public onClick =
    (value: string, setValues: (a: IFormValue) => void) => (event: any) => {
      event.preventDefault();
      setValues({ search: value });
      this.onSubmit({ search: value });
    };

  public renderSuggestion = (
    h: Hit<string>,
    setValues: (a: IFormValue) => void
  ) => {
    const { classes } = this.props;
    return (
      <div
        key={h.objectID}
        className={classes.item}
        onMouseDown={this.onClick(
          ((h as any).title + ' ' + (h as any).created_by) as string,
          setValues
        )}
      >
        <Highlight attribute="created_by" hit={h} tagName="strong" /> -{' '}
        <Highlight attribute="title" hit={h} tagName="strong" />
      </div>
    );
  };

  public renderForm = (props: FormikProps<IFormValue>) => {
    const { classes, history, hits, placeholder, customClass } = this.props;

    const { values, setValues, handleSubmit } = props;

    const onClear = (event: any) => {
      // event.preventDefault()
      setValues({ search: '' });
      history.push(ACGAppPaths.ROOT);
    };

    return (
      <form onSubmit={handleSubmit} ref={(e: any) => (this.ref = e)}>
        <InputBase
          id="outlined-name"
          name="search"
          className={customClass || classes.input}
          placeholder={
            placeholder ||
            'SearchPostsForm Posts by Location, Curator, Artist, Venue, or Title…'
          }
          autoComplete={'off'}
          onBlur={props.handleBlur}
          onChange={this.onChange(props.handleChange)}
          value={values.search}
          startAdornment={
            <InputAdornment position="start" className={classes.iconStart}>
              <IconSearch />
            </InputAdornment>
          }
          endAdornment={
            values.search && (
              <InputAdornment
                onMouseDown={onClear}
                position="end"
                className={classes.iconEnd}
              >
                <Icon>clear</Icon>
              </InputAdornment>
            )
          }
        />
        {/*@ts-ignore*/}
        <Popper
          open={this.state.shouldShowPredicates && values.search.length > 0}
          anchorEl={this.ref}
          container={this.ref}
          style={{ zIndex: 10000, position: 'inherit' }}
        >
          <div>
            <Paper
              square={true}
              style={{ width: this.ref ? this.ref.clientWidth : null }}
              className={classes.dropdown}
            >
              {hits.map((h) => {
                return this.renderSuggestion(h, setValues);
              })}
            </Paper>
          </div>
        </Popper>
        <button type="submit" style={{ display: 'none' }} />
      </form>
    );
  };

  public render() {
    const {
      location: { search },
      classes,
    } = this.props;

    const config: IValidationConfig = {
      search: [],
    };

    const validate = generateValidator(config);

    const filter = parseSearch(search, QUERY_SEARCH_SETTINGS);
    if (filter.query) {
      this.initialValue = {
        search: filter.query,
      };
    }

    return (
      <div className={classes.wrapper}>
        <Formik
          initialValues={this.initialValue}
          validate={validate}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
      </div>
    );
  }
}

export default compose<Props, ISearchProps>(
  connectAutoComplete,
  withStyles(style),
  withRouter
)(SearchPostsForm);
