import * as React from 'react';
import { IUser } from '../../types/user';
import { IDataResponse } from '../../services/ApiServices';

export interface IAccountContextProps {
  account: IUser | undefined;
  updateAccount:
    | ((account: IUser) => Promise<IDataResponse<IUser>>)
    | undefined;
}

const context: IAccountContextProps = {
  account: undefined,
  updateAccount: undefined,
};

export const AccountContext = React.createContext(context);
