import * as React from 'react';
import { Grid } from '@mui/material';

export interface IFormMessageProps {
  visible: boolean;
  message: string | React.ReactNode;
  type: 'ERROR' | 'SUCCESS';
}

export default class FormMessage extends React.Component<
  IFormMessageProps,
  any
> {
  public render() {
    const { visible, message, type } = this.props;
    return (
      <Grid item={true} xs={12}>
        {visible && (
          <div
            style={{
              marginTop: 15,
              color: type === 'ERROR' ? '#CD5C5C' : '#32CD32',
            }}
          >
            {message}
          </div>
        )}
      </Grid>
    );
  }
}
