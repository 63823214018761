import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import moment from 'moment';
import { IComment } from '../../types/comments';
import { Grid } from '@mui/material';
import ProfileComment from '../profile/ProfileComment';
import Caption from '../../library/dataDisplay/typography/Caption';
import Text from '../../library/dataDisplay/typography/Title';
import { compose } from 'recompose';
import { Field } from 'formik';
import TextInput from '../form/TextInput';

export interface ICommentProps {
  comment: IComment;
  additionalButton: React.ReactNode;
  editing?: boolean;
  onClick: () => void;
  children?: React.ReactNode;
}

const style = createStyles({
  wrapper: {
    padding: '15px 0',
  },
});

type Props = ICommentProps & WithStyles<string>;

class Comment extends React.Component<Props> {
  public render() {
    const { comment, classes, additionalButton, editing, onClick } = this.props;
    return (
      <Grid container={true} spacing={1} className={classes.wrapper}>
        <Grid item={true} xs={12}>
          <ProfileComment
            user={comment.created_by}
            additionalButton={additionalButton}
            onClick={onClick}
          />
        </Grid>
        <Grid item={true} xs={12}>
          {editing ? (
            <Field
              name="message"
              component={TextInput}
              inputProps={{
                id: 'message',
                label: 'Your Comment',
                type: 'message',
                multiline: true,
                rows: 1,
                maxRows: 20,
              }}
              small={true}
              naked={true}
            />
          ) : (
            <Text>{comment.message}</Text>
          )}
        </Grid>
        <Grid
          item={true}
          xs={12}
          style={{
            textAlign: 'right',
          }}
        >
          <Caption>{moment(comment.created_at).fromNow()}</Caption>
        </Grid>
      </Grid>
    );
  }
}

export default compose<Props, ICommentProps>(withStyles(style))(Comment);
