import * as React from 'react';
import { PRIMARY_COLOR } from '../../styles/constants';
import { withStyles, WithStyles, createStyles } from '@mui/styles';

const styles = () =>
  createStyles({
    root: {
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 16,
      padding: '20px 12px',
      border: '1px solid',
      lineHeight: 1.5,
      backgroundColor: '#fff',
      borderColor: '#2F2F37',
      color: '#2F2F37',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        backgroundColor: '#A4AFE3',
        borderColor: '#2F2F37',
        color: '#2F2F37',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#fff',
        borderColor: '#2F2F37',
        color: '#2F2F37',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
    },

    categoryInfo: {
      position: 'absolute',
      bottom: 20,
      right: 10,
    },
    categoryInfoContainer: {
      width: 98,
      height: 32,
      borderRadius: 5,
      backgroundColor: '#f6f6f6',
      fontSize: 12,
      color: '#000',
      fontFamily: 'Replica-Regular',
      textTransform: 'capitalize',
      padding: 10,
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      '&:hover': {
        color: PRIMARY_COLOR,
      },
    },
  });

export interface ICategoryButtonProps {
  subType: string;
  onCategoryClick: (selectedCategory: string) => void;
}

type Props = ICategoryButtonProps & WithStyles<typeof styles>;

class CategoryButton extends React.Component<Props, any> {
  public render() {
    const { classes, subType, onCategoryClick } = this.props;

    let type = '';
    switch (subType) {
      case 'EXHIBITION':
        type = 'Exhibition Archive';
        break;
      case 'ARTPROJECT':
        type = 'Art Project';
        break;
      case 'PUBLICATION':
        type = 'Publication';
        break;
      case 'STUDIOVISIT':
        type = 'Studio Visit';
        break;
      case 'INTERVIEW':
        type = 'Interview';
        break;
      case 'RELATEDTOYOURWORK':
        type = 'Other';
        break;
      case 'ONLINEEXHIBITION':
        type = 'Exhibition';
        break;
      default:
        type = '';
    }

    const onclickEvt = (event: any) => {
      event.preventDefault();
      onCategoryClick(subType);
    };

    return (
      <div className={classes.categoryInfo} onClick={onclickEvt}>
        <span className={classes.categoryInfoContainer}>{type}</span>
      </div>
    );
  }
}

export default withStyles(styles)(CategoryButton);
