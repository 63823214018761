import constants from './artpool.module.scss';

export const COUNTDOWN_DAYS_LIMIT = 60;

export const BREAKPOINTS = {
  X_SMALL: constants.X_SMALL,
  SMALL: constants.SMALL,
  MEDIUM: constants.MEDIUM,
  LARGE: constants.LARGE,
  X_LARGE: constants.X_LARGE,
};

export const PRIMARY = {
  ACG_PURPLE_50: constants.PRIMARY_ACG_PURPLE_50,
  ACG_PURPLE_200: constants.PRIMARY_ACG_PURPLE_200,
  ACG_PURPLE_500: constants.PRIMARY_ACG_PURPLE_500,
  ARTPOOL_GREEN: constants.PRIMARY_ARTPOOL_GREEN,
};

export const SECONDARY = {
  HYPERLINK: constants.SECONDARY_HYPERLINK,
};
export const ALTERNATIVE = {
  ARTPOOL_BLUE: constants.ALTERNATIVE_ARTPOOL_BLUE,
  ARTPOOL_YELLOW: constants.ALTERNATIVE_ARTPOOL_YELLOW,
  ARTPOOL_ORANGE: constants.ALTERNATIVE_ARTPOOL_ORANGE,
  ARTPOOL_PINK: constants.ALTERNATIVE_ARTPOOL_PINK,
};
export const POSITIVE = {
  50: constants.POSITIVE_50,
  300: constants.POSITIVE_300,
  500: constants.POSITIVE_500,
  700: constants.POSITIVE_700,
  900: constants.POSITIVE_900,
};
export const ALERT = {
  50: constants.ALERT_50,
  300: constants.ALERT_300,
  500: constants.ALERT_500,
  700: constants.ALERT_700,
  900: constants.ALERT_900,
};
export const NEGATIVE = {
  50: constants.NEGATIVE_50,
  300: constants.NEGATIVE_300,
  500: constants.NEGATIVE_500,
  700: constants.NEGATIVE_700,
  900: constants.NEGATIVE_900,
};
export const NEUTRAL = {
  50: constants.NEUTRAL_50,
  100: constants.NEUTRAL_100,
  200: constants.NEUTRAL_200,
  300: constants.NEUTRAL_300,
  400: constants.NEUTRAL_400,
  500: constants.NEUTRAL_500,
  600: constants.NEUTRAL_600,
  700: constants.NEUTRAL_700,
  800: constants.NEUTRAL_800,
  900: constants.NEUTRAL_900,
};

export const BLACK = constants.BLACK;
export const LIGHTER_BLACK = constants.LIGHTER_BLACK;
export const WHITE = constants.WHITE;
export const MENU_HEIGHT = constants.MENU_HEIGHT;
