import { createStyles, withStyles, WithStyles } from '@mui/styles';
import * as React from 'react';
import * as _ from 'lodash';
import { compose } from 'recompose';
import { Alert, AlertTitle, Grid } from '@mui/material';
import { GREY_COLOR } from '../../styles/constants';
import { IExhibitionPoint } from '../../types/exhibitions';
import TextInput from './TextInput';
import { Field, FieldArray, FieldProps, getIn } from 'formik';
import FileUpload from './MultipleFilesUpload';
import VideoLinkInput from './VideoLinkInput';
import LinkInput from './LinkInput';
import ColorPickerInput from './ColorPickerInput';
import FormArtistNameWrapper from './FormArtistNameWrapper';
import DesignedButton from '../../library/inputs/DesignedButton';
import { IImage } from '../../types/images';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import ExhibitionService from '../../services/ExhibitionService';
import NftCard from '../../artpool-library/components/surfaces/nft-card';
import InfoSquareTooltip from '../../library/dataDisplay/tooltips/InfoSquareTooltip';

export interface IArtWorkInputProps extends FieldProps {
  inputProps: IArtWorkFiledProps;
}

export interface IArtWorkFiledProps {
  exhibition_point?: IExhibitionPoint;
  exhibition_point_index: number;
  OnUpdatePoint: (updatedPoint: IExhibitionPoint) => void;
  OnCancel: () => void;
  isCreate: boolean;

  defaultArtWorkColor: string;
  defaultArtWorkBackColor: string;
  defaultArtWorkLabelColor: string;
}

interface IState {
  error?: any;
  artworksColor?: string;
  artworksLabelColor?: string;
  artworksBackColor?: string;
  nftError: boolean;
  errors?: any;
  isNftArtwork?: boolean;
  nftData?: {
    project: any;
    nft: any;
  };
}

const style = createStyles({
  artWorkContainer: {
    padding: 20,
  },
  button: {
    backgroundColor: GREY_COLOR,
    padding: '20px 0',
    paddingTop: '20px',
    borderRadius: 100,
  },
  errorMessage: {
    color: '#f44336',
    marginLeft: 14,
    marginRight: 14,
    fontSize: '0.75rem',
    marginTop: 3,
    textAlign: 'left',
    fontFamily: 'Replica-Regular',
    fontWeight: 400,
    lineHeight: 1.66,
  },

  buttonsContainer: {
    paddingTop: 30,
  },
  resetButton: {
    fontSize: 12,
    textDecoration: 'underline',
    padding: '20px 0',
    cursor: 'pointer',
    paddingBottom: '20px',
  },
});

type Props = IArtWorkInputProps & WithStyles<string>;

class ArtWorkInput extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    const {
      form: { values },
      inputProps: { exhibition_point_index, isCreate },
    } = this.props;

    if (values.exhibition_points) {
      const point = values.exhibition_points[exhibition_point_index];
      this.state = {
        errors: {},
        nftError: false,
        isNftArtwork: isCreate ? false : !!point.art_work.nft_data,
        artworksColor: point.artworks_color,
        artworksLabelColor: point.artworks_label_color,
        artworksBackColor: point.artworks_back_color,
      };
    }
  }

  public updateLinksData() {
    const {
      form: { values },
      inputProps: { exhibition_point_index, exhibition_point },
    } = this.props;

    const artWorksName = `exhibition_points[${exhibition_point_index}].art_work`;

    const regexURLFormula =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

    const linkTitle = _.get(values, artWorksName + '.links_link_title');
    const linkUrl = _.get(values, artWorksName + '.links_link_url');

    if (regexURLFormula.test(linkUrl)) {
      const linksArray = _.get(values, artWorksName + '.links');

      linksArray.push({
        link_title: linkTitle,
        link_url: linkUrl,
      });
      _.set(values, artWorksName + '.links', linksArray);

      _.set(values, artWorksName + '.links_link_title', '');
      _.set(values, artWorksName + '.links_link_url', '');
      if (exhibition_point && exhibition_point.art_work) {
        exhibition_point.art_work.links = linksArray;
      }
    }
  }

  public updateVideoData() {
    const {
      form: { values },
      inputProps: { exhibition_point_index, exhibition_point },
    } = this.props;

    const artWorksName = `exhibition_points[${exhibition_point_index}].art_work`;

    const regexURLFormula =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

    const videoTitle = _.get(values, artWorksName + '.videos_video_title');
    const videoUrl = _.get(values, artWorksName + '.videos_video_link');

    if (regexURLFormula.test(videoUrl)) {
      const videoArray = _.get(values, artWorksName + '.videos');

      videoArray.push({
        video_title: videoTitle,
        video_link: videoUrl,
      });
      _.set(values, artWorksName + '.videos', videoArray);

      _.set(values, artWorksName + '.videos_video_title', '');
      _.set(values, artWorksName + '.videos_video_link', '');
      if (exhibition_point && exhibition_point.art_work) {
        exhibition_point.art_work.videos = videoArray;
      }
    }
  }

  public updatePointColors() {
    const {
      form: { values },
      inputProps: {
        exhibition_point_index,
        exhibition_point,

        defaultArtWorkColor,
        defaultArtWorkBackColor,
        defaultArtWorkLabelColor,
      },
    } = this.props;

    const artWorksPoint = `exhibition_points[${exhibition_point_index}].artworks_color`;
    const artWorksLabel = `exhibition_points[${exhibition_point_index}].artworks_label_color`;
    const artWorkPointBackColor = `exhibition_points[${exhibition_point_index}].artworks_back_color`;

    const artworksColor =
      defaultArtWorkColor === values[artWorksPoint]
        ? ''
        : values[artWorksPoint];
    const artworksLabelColor =
      defaultArtWorkLabelColor === values[artWorksLabel]
        ? ''
        : values[artWorksLabel];
    const artworksBackColor =
      defaultArtWorkBackColor === values[artWorkPointBackColor]
        ? ''
        : values[artWorkPointBackColor];

    _.set(
      values,
      ['exhibition_points', exhibition_point_index, 'artworks_color'],
      artworksColor
    );
    _.set(
      values,
      ['exhibition_points', exhibition_point_index, 'artworks_label_color'],
      artworksLabelColor
    );
    _.set(
      values,
      ['exhibition_points', exhibition_point_index, 'artworks_back_color'],
      artworksBackColor
    );

    if (exhibition_point) {
      exhibition_point.artworks_color = _.get(values, [
        'exhibition_points',
        exhibition_point_index,
        'artworks_color',
      ]);
      exhibition_point.artworks_label_color = _.get(values, [
        'exhibition_points',
        exhibition_point_index,
        'artworks_label_color',
      ]);
      exhibition_point.artworks_back_color = _.get(values, [
        'exhibition_points',
        exhibition_point_index,
        'artworks_back_color',
      ]);
    }

    this.setState({
      artworksColor,
      artworksLabelColor,
      artworksBackColor,
    });
  }

  public resetColorsToDefault() {
    const {
      form: { values },
      inputProps: {
        exhibition_point_index,
        defaultArtWorkColor,
        defaultArtWorkBackColor,
        defaultArtWorkLabelColor,
      },
    } = this.props;

    const artWorksPoint = `exhibition_points[${exhibition_point_index}].artworks_color`;
    const artWorksLabel = `exhibition_points[${exhibition_point_index}].artworks_label_color`;
    const artWorkPointBackColor = `exhibition_points[${exhibition_point_index}].artworks_back_color`;

    const artworksColor = values[artWorksPoint];
    const artworksLabelColor = values[artWorksLabel];
    const artworksBackColor = values[artWorkPointBackColor];

    if (artworksColor) {
      _.set(values, artWorksPoint, '');
      values[artWorksPoint] = defaultArtWorkColor;
    }
    if (artworksLabelColor) {
      _.set(values, artWorksLabel, '');
      values[artWorksLabel] = defaultArtWorkLabelColor;
    }
    if (artworksBackColor) {
      _.set(values, artWorkPointBackColor, '');
      values[artWorkPointBackColor] = defaultArtWorkBackColor;
    }
    _.set(
      values,
      ['exhibition_points', exhibition_point_index, 'artworks_color'],
      ''
    );
    _.set(
      values,
      ['exhibition_points', exhibition_point_index, 'artworks_label_color'],
      ''
    );
    _.set(
      values,
      ['exhibition_points', exhibition_point_index, 'artworks_back_color'],
      ''
    );

    this.setState({
      artworksColor: defaultArtWorkColor,
      artworksLabelColor: defaultArtWorkLabelColor,
      artworksBackColor: defaultArtWorkBackColor,
    });
  }

  public render() {
    const {
      classes,
      form: { errors, values },
      inputProps: {
        exhibition_point,
        exhibition_point_index,
        OnCancel,
        isCreate,
        OnUpdatePoint,

        defaultArtWorkColor,
        defaultArtWorkBackColor,
        defaultArtWorkLabelColor,
      },
    } = this.props;

    const {
      artworksColor,
      artworksLabelColor,
      artworksBackColor,
      isNftArtwork,
      nftError,
      nftData,
    } = this.state;

    const artWorksName = `exhibition_points[${exhibition_point_index}].art_work`;
    const artWorksCuratorialText = `exhibition_points[${exhibition_point_index}].curatorial_text`;
    const artWorksPointColor = `exhibition_points[${exhibition_point_index}].artworks_color`;
    const artWorksPointBackColor = `exhibition_points[${exhibition_point_index}].artworks_back_color`;
    const artWorksPointLabel = `exhibition_points[${exhibition_point_index}].artworks_label_color`;

    if (!exhibition_point) {
      return (
        <React.Fragment>
          <div>Missing data</div>
        </React.Fragment>
      );
    }

    const handleNftArtworkChange = () => {
      this.setState({ isNftArtwork: !this.state.isNftArtwork });
    };

    const onGetArtPoolNFTData = () => {
      const artPoolNFT = _.get(values, artWorksName + '.nft_url');

      const urlParams = new URLSearchParams(artPoolNFT.split('?')[1]);

      const projectId = urlParams.get('projectId');
      const nftId = urlParams.get('nftId');
      const service = new ExhibitionService();
      if (projectId && nftId) {
        service
          .getNFTProject(projectId)
          .then((project: any) => {
            const nft = project.nfts.find((el: any) => el._id === nftId);
            if (!nft) {
              this.setState({ nftError: true });
              return;
            }
            this.setState({
              nftData: { project: projectId, nft },
              nftError: false,
            });
          })
          .catch(() => this.setState({ nftError: true }));
      } else {
        this.setState({ nftError: true });
      }
    };
    const onUpdateEvent = () => {
      _.set(values, artWorksName + '.nft_data', undefined);

      const { title, technic_used, imagesWithCredit } =
        values.exhibition_points[exhibition_point_index].art_work;
      const titleError = title && title !== '' ? true : false;
      // const yearError = (year && year !== "") ? true : false
      const technicUsedError =
        technic_used && technic_used !== '' ? true : false;
      let imagesWithCreditError = true;

      if (imagesWithCredit) {
        imagesWithCreditError = true;
        imagesWithCredit.forEach((image: IImage) => {
          if (!image.image_credit || image.image_credit === '') {
            imagesWithCreditError = false;
          }
        });
      }
      if (!titleError || !technicUsedError || !imagesWithCreditError) {
        this.setState({
          errors: {
            title: !titleError,
            technic_used: !technicUsedError,
            imagesWithCredit: !imagesWithCreditError,
          },
        });
      }

      if (
        !errorArtPoint &&
        titleError &&
        technicUsedError &&
        imagesWithCreditError
      ) {
        this.updateLinksData();
        this.updateVideoData();
        this.updatePointColors();
        OnUpdatePoint(exhibition_point);
      }
    };

    const onUpdateNFt = () => {
      if (nftData) {
        _.set(values, artWorksName + '.nft_data', nftData);
        OnUpdatePoint(exhibition_point);
      } else {
        this.setState({ nftError: true });
      }
    };

    const resetToDefaultColors = () => {
      this.setState(
        {
          artworksColor: defaultArtWorkColor,
          artworksLabelColor: defaultArtWorkBackColor,
          artworksBackColor: defaultArtWorkLabelColor,
        },
        () => {
          this.resetColorsToDefault();
        }
      );
    };

    const errorArtPoint = getIn(errors, artWorksName);

    const getErrors = (data: any) => {
      const valueArray = Object.keys(data).map((key) => data[key]);
      const errorsFound = valueArray.some((e) => e === true);
      return errorsFound;
    };

    const nftUrl =
      values.exhibition_points[exhibition_point_index].art_work.nft_url;
    return (
      <Grid container={true} className={classes.artWorkContainer} spacing={2}>
        <Grid item={true} xs={12}>
          {isCreate && <h2>Creating new Artwork</h2>}
          {!isCreate && <h2>Editing Artwork</h2>}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={isNftArtwork}
                  onChange={handleNftArtworkChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Link to Artpool NFT"
              color={'primary'}
            />
            <InfoSquareTooltip
              size={20}
              font={12}
              helpText={
                <>
                  Connect an NFT
                  <br />
                  Copy and paste the URL from the page of your NFT listing
                  <br />
                  *If you can't find your listing or your NFT has not been
                  created yet, please contact support in the chat.
                </>
              }
            />
          </div>
        </Grid>
        {/* ARTWORK INPUT*/}
        {!this.state.isNftArtwork && (
          <>
            <Grid item={true} xs={12}>
              <Field
                name={artWorksName + '.artists'}
                component={FormArtistNameWrapper}
                inputProps={{
                  id: artWorksName + '.artists',
                  label: 'Artist Name',
                  type: 'artists',
                  index: exhibition_point_index,
                }}
              />
            </Grid>

            <Grid item={true} xs={12}>
              <Field
                name={artWorksName + '.title'}
                component={TextInput}
                newTextInput={true}
                naked={true}
                inputProps={{
                  id: artWorksName + '.title',
                  label: 'Work Title*',
                  type: 'title',
                }}
              />

              {this.state.errors && this.state.errors.title && (
                <p className={classes.errorMessage}>Add the artwork title</p>
              )}
            </Grid>

            <Grid item={true} xs={12}>
              <Field
                name={artWorksName + '.year'}
                component={TextInput}
                newTextInput={true}
                naked={true}
                inputProps={{
                  id: artWorksName + '.year',
                  label: 'Year',
                  type: 'number',
                }}
              />
              {/*{this.state.errors && this.state.errors.year &&*/}
              {/*<p className={classes.errorMessage}>Add the artwork year</p>}*/}
            </Grid>

            <Grid item={true} xs={12}>
              <Field
                name={artWorksName + '.technic_used'}
                component={TextInput}
                newTextInput={true}
                naked={true}
                inputProps={{
                  id: artWorksName + '.technic_used',
                  label: 'Technic Used*',
                  type: 'technic_used',
                }}
              />
              {this.state.errors && this.state.errors.technic_used && (
                <p className={classes.errorMessage}>
                  Add the artwork technic used
                </p>
              )}
            </Grid>

            <Grid item={true} xs={12}>
              <Field
                name={artWorksName + '.dimensions'}
                component={TextInput}
                newTextInput={true}
                naked={true}
                inputProps={{
                  id: artWorksName + '.dimensions',
                  label: 'Dimensions',
                  type: 'dimensions',
                }}
              />
            </Grid>

            <Grid item={true} xs={12}>
              <Field
                name={artWorksName + '.rarity'}
                component={TextInput}
                newTextInput={true}
                naked={true}
                inputProps={{
                  id: artWorksName + '.rarity',
                  label: 'Rarity (unique or edition)',
                  type: 'rarity',
                }}
              />
            </Grid>

            <Grid item={true} xs={12}>
              <Field
                name={artWorksCuratorialText}
                component={TextInput}
                newTextInput={true}
                naked={true}
                inputProps={{
                  id: artWorksCuratorialText,
                  label: 'Artwork specific curatorial text',
                  type: 'curatorial_text',
                  multiline: true,
                  rows: 5,
                  rowsMax: 20,
                }}
              />
            </Grid>

            <Grid item={true} xs={12}>
              <Field
                name={artWorksName + '.artwork_group'}
                component={TextInput}
                newTextInput={true}
                naked={true}
                inputProps={{
                  id: artWorksName + '.artwork_group',
                  label: 'Artwork group / section / thematic ',
                  type: 'artwork_group',
                }}
              />
            </Grid>

            <Grid item={true} xs={12}>
              <FieldArray
                name={artWorksName + '.imagesWithCredit'}
                component={FileUpload as any}
              />
              {this.state.errors && this.state.errors.imagesWithCredit && (
                <p className={classes.errorMessage}>
                  The image credit is missing
                </p>
              )}
            </Grid>

            <Grid item={true} xs={12}>
              <FieldArray
                name={artWorksName + '.videos'}
                component={VideoLinkInput as any}
              />
            </Grid>

            <Grid item={true} xs={12}>
              <FieldArray
                name={artWorksName + '.links'}
                component={LinkInput as any}
              />
            </Grid>
          </>
        )}

        {/* NFT INPUT*/}
        {this.state.isNftArtwork && (
          <>
            <Grid item={true} xs={8}>
              <Field
                name={artWorksName + '.nft_url'}
                component={TextInput}
                newTextInput={true}
                naked={true}
                inputProps={{
                  id: artWorksName + '.nft_url',
                  label: 'Enter the Artpool NFT URL',
                  type: 'url',
                }}
              />
            </Grid>
            <Grid item={true} xs={4}>
              <DesignedButton
                disabled={!nftUrl}
                ACGType={'main'}
                size="large"
                fullWidth={true}
                onClick={onGetArtPoolNFTData}
              >
                Get data
              </DesignedButton>
            </Grid>
          </>
        )}

        {nftData && isNftArtwork && !nftError && (
          <Grid item={true} xs={4}>
            <NftCard nft={nftData.nft} nonClickable={true} />
          </Grid>
        )}
        <Grid item={true} xs={nftData ? 6 : 12}>
          <p style={{ fontSize: 12, paddingTop: 20 }}>
            Set colors for this artwork point on the map:
          </p>
          <div style={{ paddingLeft: 10 }}>
            <Field
              name={artWorksPointColor}
              component={ColorPickerInput}
              // classes={classes}
              inputProps={{
                id: artWorksPointColor,
                type: 'artworks_color',
              }}
              label={'Border'}
              initialColor={artworksColor ? artworksColor : defaultArtWorkColor}
            />
            <br />
            <Field
              name={artWorksPointBackColor}
              component={ColorPickerInput}
              // classes={classes}
              inputProps={{
                id: artWorksPointBackColor,
                type: 'artworks_back_color',
              }}
              label={'Fill'}
              initialColor={
                artworksBackColor ? artworksBackColor : defaultArtWorkBackColor
              }
            />
            <br />
            <Field
              name={artWorksPointLabel}
              component={ColorPickerInput}
              // classes={classes}
              inputProps={{
                id: artWorksPointLabel,
                type: 'artworks_label_color',
              }}
              label={'Number'}
              initialColor={
                artworksLabelColor
                  ? artworksLabelColor
                  : defaultArtWorkLabelColor
              }
            />
            <br />
          </div>

          <p onClick={resetToDefaultColors} className={classes.resetButton}>
            Revert to default
          </p>
        </Grid>

        <Grid item={true} xs={12} className={classes.buttonsContainer}>
          <Grid container={true} spacing={2}>
            {this.state.isNftArtwork && nftError && (
              <Grid item={true} xs={12}>
                <Alert severity="error">
                  <AlertTitle>Error on the artwork creation</AlertTitle>
                  <b>NFT Artwork: </b> Couldn't find NFT
                  <br />
                  *If you can't find your listing or your NFT has not been
                  created yet, please contact support in the chat.
                </Alert>
              </Grid>
            )}

            {!this.state.isNftArtwork &&
              this.state.errors &&
              getErrors(this.state.errors) && (
                <Grid item={true} xs={12}>
                  <Alert severity="error">
                    <AlertTitle>Error on the artwork creation</AlertTitle>
                    {this.state.errors && this.state.errors.title && (
                      <>
                        <b>Title: </b> Required
                        <br />
                      </>
                    )}
                    {this.state.errors && this.state.errors.technic_used && (
                      <>
                        <b>Technic Used: </b> Required
                        <br />
                      </>
                    )}
                    {this.state.errors && this.state.errors.imagesWithCredit && (
                      <>
                        <b>Images: </b> Credit field is required
                        <br />
                      </>
                    )}
                  </Alert>
                </Grid>
              )}

            <Grid item={true} xs={6}>
              <DesignedButton
                ACGType={'neutral'}
                size="large"
                aria-label="Add"
                fullWidth={true}
                onClick={OnCancel}
              >
                Cancel
              </DesignedButton>
            </Grid>
            <Grid item={true} xs={6}>
              <DesignedButton
                ACGType={'main'}
                size="large"
                fullWidth={true}
                onClick={isNftArtwork ? onUpdateNFt : onUpdateEvent}
                disabled={isNftArtwork && nftError}
              >
                Save Artwork
              </DesignedButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default compose<Props, IArtWorkInputProps>(withStyles(style))(
  ArtWorkInput
);
