import './location-icon.module.scss';
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

// tslint:disable-next-line:no-empty-interface
export interface LocationIconProps {
  stroke?: string;
}

type Props = LocationIconProps & SvgIconProps;

export function LocationIcon(props: Props) {
  return (
    <SvgIcon {...props} htmlColor={'transparent'} viewBox="0 0 10 16">
      <path
        d="M5 7.5C6.10457 7.5 7 6.60457 7 5.5C7 4.39543 6.10457 3.5 5 3.5C3.89543 3.5 3 4.39543 3 5.5C3 6.60457 3.89543 7.5 5 7.5Z"
        stroke={props.stroke || 'black'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00012 7.5C7.50012 11 5.00012 15 5.00012 15C5.00012 15 2.50012 11 1.00012 7.5C-0.499878 4 2.00012 1 5.00012 1C8.00012 1 10.5001 4 9.00012 7.5Z"
        stroke={props.stroke || 'black'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default LocationIcon;
