import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Grid } from '@mui/material';
import { compose } from 'recompose';
import {
  IExhibition,
  IExhibitionMap,
  IExhibitionPoint,
  IExhibitionSection,
  visibilityType,
} from '../../types/exhibitions';
import OnlineExhibitionLayout from '../../library/layout/container/OnlineExhibitionLayout';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import { ITag } from '../../types/tags';
import { IImage } from '../../types/images';
import { ILink } from '../../types/links';
import { IVideoLink } from '../../types/videos';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { RouteComponentProps } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRedirect from '../../components/errors/NotFoundRedirect';
import Loading from '../../components/loading/Loading';
import * as exhibitionsActions from '../../store/modules/exhibitions/actions';
import * as Yup from 'yup';
import OnlineExhibitionFormComponent from '../../components/onlineexhibition/OnlineExhibitionFormComponent';
import { formatErrors } from '../../utils/formatters';
import { EXHIBITION_BREAKPOINT } from '../../styles/constants';
import { IUser } from '../../types/user';
import { deserializeUrl } from '../../utils/router';
import { IArtist } from '../../components/form/FormArtistNameWrapper';
import { getExhibitionPointType, PointType } from '../../utils/exhibition';
import { IStoreState } from '../../store';
import { Redirect } from 'react-router-dom';
import { IValidationError } from '../../types/returns';
import { ACGAppPaths } from '@acg/shared/const';

export interface IOnlineExhibitionFormProps {
  onExhibitionDone: () => void;
  onExhibitionCreate: (exhibition: IExhibition) => void;
  onExhibitionUpdate: (exhibition: IExhibition) => void;
}

const style = createStyles({
  container: {
    backgroundColor: '#fff',
    fontFamily: 'Replica-Regular',
    '& h1': {
      fontSize: 50,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 30,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 16,
    },
  },
  containerMobile: {
    backgroundColor: '#fff',
    fontFamily: 'Replica-Regular',
    '& h1': {
      fontSize: 35,
      fontWeight: 'normal',
    },
    '& h2': {
      fontSize: 20,
      fontWeight: 'normal',
    },
    '& h3': {
      fontSize: 15,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 12,
    },
  },
});

export interface IOnlineExhibitionInitialValue {
  id?: string;
  url?: string;
  venue_id?: any;
  description: string;
  title: string;
  created_by?: IUser;
  curated_by?: any[];
  last_creator_update?: string;
  base_map: IExhibitionMap;
  exhibition_points: IExhibitionPoint[];
  start_date: string;
  end_date: string;
  // Detailed information
  artists_list?: string;
  artists?: any;
  co_curator_list: string;
  other_contributors_list: string;
  acknowledgement_text?: string;
  tags: ITag[];
  // Additional info
  press_release: string;
  featured_imageWithCredit?: IImage;
  imagesWithCredit?: IImage[];
  links: ILink[];
  videos: IVideoLink[];
  // The map definition
  // The sections on the exhibition
  sections?: IExhibitionSection[];
  isDraft: boolean;

  show_artworks_list: boolean;
  show_artworks_order: boolean;
  show_artworks_line: boolean;

  artworks_color: string;
  artworks_label_color: string;
  artworks_line_color: string;

  visibility: visibilityType;
  access_key: string;

  edit_key: string;
  invited_users: string[];
}

type Props = WithStyles<string> &
  IWithAccountProps &
  WithWidth &
  RouteComponentProps<{ exhibitionId: string }>;

const OnlineExhibitionForm: React.FC<Props> = (props) => {
  const {
    match: {
      params: { exhibitionId },
    },
    location: { search },
    history,
    classes,
  } = props;

  const key = deserializeUrl(search);

  const { exhibition_edit, isLoading, error, isExhibitionAdmin, redirectTo } =
    useSelector((state: IStoreState) => state.exhibitions);
  const dispatch = useDispatch();

  /* Setting the references */
  const [isUpdate, setIsUpdate] = useState(false);

  /* Setting the references */
  const formRef = useRef<any | undefined>();

  /*
     Functions section
  */

  const onExhibitionCancel = () => {
    dispatch(exhibitionsActions.clearExhibitionEdit());
    history.push(ACGAppPaths.ROOT);
  };

  const addLinksToPost = (
    typeArray: string,
    theArray: any,
    theUrl: string,
    theTitle?: string
  ) => {
    const regexURLFormula =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

    if (!regexURLFormula.test(theUrl)) {
      return false;
    } else {
      if (typeArray === 'links') {
        const linkList = {
          link_title: theTitle,
          link_url: theUrl,
        };

        theArray.push(linkList);
      } else {
        const linkList = {
          video_title: theTitle,
          video_link: theUrl,
        };

        theArray.push(linkList);
      }

      return true;
    }
  };

  const getArtistList = (exhibitionPoints: IExhibitionPoint[]) => {
    let artistList: any = [];
    exhibitionPoints.forEach((artPoint: IExhibitionPoint) => {
      if (
        artPoint.art_work &&
        artPoint.art_work.artists &&
        artPoint.art_work.artists.length > 0
      ) {
        artistList = _.union(
          artistList,
          artPoint.art_work.artists.map((artist: any) => artist._id)
        );
      }
      if (getExhibitionPointType(artPoint) === PointType.NFT) {
        if (artPoint.art_work!!.nft_data!!.nft.artist._id) {
          artistList = _.union(artistList, [
            artPoint.art_work!!.nft_data!!.nft.artist._id,
          ]);
        }
      }
    });
    return artistList;
  };

  const fetchData = () => {
    const editKey = key && key.key ? key.key : undefined;
    dispatch(exhibitionsActions.getEditExhibition(exhibitionId, editKey));
    setIsUpdate(true);
  };

  const hasExhibitionAccess = () => {
    dispatch(exhibitionsActions.hasExhibitionAccess());
  };

  const onSubmit = async (
    values: IOnlineExhibitionInitialValue,
    actions: FormikHelpers<IOnlineExhibitionInitialValue>
  ) => {
    try {
      const pointsFieldName = 'exhibition_points';
      values[pointsFieldName].forEach(
        (point: IExhibitionPoint, index: number) => {
          point.point_order = index + 1;
          point.id = index + 1;
        }
      );

      const exhibitionPoints = values[pointsFieldName].map(
        (point: IExhibitionPoint, index: number) => {
          if (point.art_work && !point.art_work.nft_data) {
            const artistsIds =
              point.art_work.artists &&
              point.art_work.artists.map((artist: any) => artist._id);
            const editedPoint = {
              ...point,
              art_work: { ...point.art_work, artists: artistsIds },
            };
            return editedPoint;
          } else if (point.art_work && point.art_work.nft_data) {
            const editedPoint = {
              ...point,
              art_work: {
                ...point.art_work,
                nft_data: {
                  nft: (point.art_work.nft_data.nft as any)._id,
                  project: point.art_work.nft_data.project,
                },
              },
            };
            return editedPoint;
          }
          return point;
        }
      );

      const linkTitle = _.get(values, 'link_title');
      const linkUrl = _.get(values, 'link_url');

      if (
        linkUrl &&
        addLinksToPost('links', values.links, linkUrl, linkTitle)
      ) {
        _.set(values, 'link_title', '');
        _.set(values, 'link_url', '');
      }

      const videoTitle = _.get(values, 'video_title');
      const videoUrl = _.get(values, 'video_link');

      if (
        videoUrl &&
        addLinksToPost('videos', values.videos, videoUrl, videoTitle)
      ) {
        _.set(values, 'video_title', '');
        _.set(values, 'video_link', '');
      }

      // const [featuredImage] = values.imagesWithCredit;
      const artistList = getArtistList(values.exhibition_points);

      const coCuratorList: string[] = [];
      if (values.co_curator_list.length > 1) {
        const list = values.co_curator_list.split(',');
        list.forEach((elem) => {
          coCuratorList.push(elem.trim());
        });
      }

      const otherContributorsList: string[] = [];
      if (values.other_contributors_list.length > 1) {
        const list = values.other_contributors_list.split(',');
        list.forEach((elem) => {
          otherContributorsList.push(elem.trim());
        });
      }

      const curatedByList = values.curated_by
        ? values.curated_by.map((curator: any) => {
            return curator._id;
          })
        : [];

      const payload: Partial<IExhibition> = {
        ...values,
        curated_by: curatedByList,
        exhibition_points: exhibitionPoints,
        artists: artistList,
        co_curator_list: coCuratorList,
        other_contributors_list: otherContributorsList,
        tags: values.tags.map((t) => t.id) as any,
        last_creator_update: moment.utc().toISOString(),
      };
      if (isUpdate && exhibition_edit && exhibition_edit.id) {
        const dispatchObj = {
          id: exhibition_edit.id,
          exhibition: payload,
          editKey: undefined,
        };

        if (key && key.key) {
          dispatchObj.editKey = key.key;
        }
        // TODO - Update state after the updateExhibition API call success.
        dispatch(
          exhibitionsActions.updateState({
            ...values,
            last_creator_update: payload.last_creator_update,
          })
        );
        dispatch(exhibitionsActions.updateExhibition(dispatchObj));
      } else {
        // window.console.log("payload create", payload);
        const dispatchObj = { exhibition: payload };
        // TODO - Update state after the updateExhibition API call success.
        dispatch(
          exhibitionsActions.updateState({
            ...values,
            last_creator_update: payload.last_creator_update,
          })
        );
        dispatch(exhibitionsActions.createExhibition(dispatchObj));
        setIsUpdate(true);
      }
    } catch (err) {
      const formattedErrors = formatErrors(err as IValidationError);
      window.console.log('ERROR', err);
      if (formattedErrors && formattedErrors.featured_image) {
        formattedErrors.images = 'Image must be set';
      }
      actions.setErrors(error);
    }

    actions.setSubmitting(false);
  };

  const draftOnlineExhibitionSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, 'The title is too short.')
      // .max(70, 'The title is too long.')
      .required('Please add a title to save as draft'),
  });

  const onlineExhibitionSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, 'The title is too short.')
      // .max(70, 'The title is too long.')
      .required('Required'),
    description: Yup.string()
      .min(20, 'The curatorial text is too short.')
      .required('Required'),
    base_map: Yup.object().shape({
      base_image: Yup.string().required('An base image is required'),
    }),
    featured_imageWithCredit: Yup.object().shape({
      image_url: Yup.string().required('Required'),
      image_credit: Yup.string().required('Image credit is required'),
    }),
    imagesWithCredit: Yup.array()
      .of(
        Yup.object().shape({
          image_url: Yup.string().required('Required'),
          image_credit: Yup.string().required('Image credit is required'),
        })
      )
      .optional(),
    exhibition_points: Yup.array()
      .of(
        Yup.object().shape({
          imagesWithCredit: Yup.array()
            .of(
              Yup.object().shape({
                image_url: Yup.string().required('Required'),
                image_credit: Yup.string().required('Image credit is required'),
              })
            )
            .optional(),
        })
      )
      .min(1, 'Please add at least one art work'),
  });

  const getValidationSchema = () => {
    if (exhibition_edit && exhibition_edit.isDraft) {
      return draftOnlineExhibitionSchema;
    } else {
      return onlineExhibitionSchema;
    }
  };

  const redirectToPage = () => {
    if (redirectTo) {
      dispatch(exhibitionsActions.clearRedirect());
      dispatch(exhibitionsActions.getEditExhibition(redirectTo));
      history.push(`/create-online-exhibition/${redirectTo}`);
    }
  };

  const renderForm = (
    formProps: FormikProps<IOnlineExhibitionInitialValue>
  ) => {
    return (
      <OnlineExhibitionFormComponent
        onExhibitionCancel={onExhibitionCancel}
        isUpdate={isUpdate}
        error={error}
        {...formProps}
      />
    );
  };

  // Check if the user has permissions to have access to
  // the online exhibition took
  useEffect(() => {
    // setInitialValues(initialDefaultValues)
    hasExhibitionAccess();
  }, []);

  // If the user has permission and there it's set
  // the online exhibition id we'll fetch the data
  useEffect(() => {
    if (isExhibitionAdmin && exhibitionId) {
      fetchData();
    }
  }, [isExhibitionAdmin]);

  if (error && error.error_message === 'NOT_FOUND') {
    return <NotFoundRedirect />;
  }
  // If the user don't have permissions is redirected
  // needs to be with === because isExhibitionAdmin is optional
  if (isExhibitionAdmin === false) {
    return <NotFoundRedirect />;
  }

  if (
    error &&
    error.error_message &&
    error.error_message.message === 'Unauthorized'
  ) {
    return <Redirect to="/login" />;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (redirectTo) {
    redirectToPage();
  }

  const isExhibitionBreakpoint = isWidthDown(
    EXHIBITION_BREAKPOINT,
    props.width
  );

  let exhibitionEditInitial = exhibition_edit;
  if (
    exhibition_edit &&
    exhibition_edit.venue_id &&
    exhibition_edit.venue_id._id
  ) {
    exhibitionEditInitial = {
      ...exhibition_edit,
      venue_id: exhibition_edit.venue_id._id,
    };
  }
  // Set Artists and existing artists to initial Values
  if (
    exhibitionEditInitial &&
    exhibitionEditInitial.exhibition_points &&
    exhibitionEditInitial.exhibition_points.length !== 0
  ) {
    exhibitionEditInitial.exhibition_points.map((point: any) => {
      if (point.art_work && point.art_work.existing_artists) {
        const allArtistsList = point.art_work.artists;
        point.art_work.existing_artists.map((existing: IUser) => {
          const found = allArtistsList.find(
            (s: IArtist) => s.id === existing.id
          );
          if (!found) {
            allArtistsList.push(existing);
          }
        });
        point.art_work.artists = allArtistsList;
      }
      return point;
    });
  }

  if (exhibition_edit) {
    return (
      <OnlineExhibitionLayout type={'ONLINEEXHIBITION'}>
        <Grid
          container={true}
          className={
            isExhibitionBreakpoint ? classes.containerMobile : classes.container
          }
        >
          <Formik
            enableReinitialize={true}
            initialValues={exhibitionEditInitial as any}
            onSubmit={onSubmit}
            render={renderForm}
            validationSchema={getValidationSchema}
            validateOnBlur={true}
            ref={formRef}
          />
        </Grid>
      </OnlineExhibitionLayout>
    );
  }
  return <></>;
};

export default compose<Props, IOnlineExhibitionFormProps>(
  withStyles(style),
  withAccount,
  withWidth()
)(OnlineExhibitionForm);
