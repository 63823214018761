import * as React from 'react';
import FormSubmit from '../form/FormSubmit';
import { Formik, FormikProps } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  IWithAccountProps,
  withAccount,
} from '../../containers/account/InjectAccount';
import { compose } from 'recompose';
import FormMessage from '../form/FormMessage';
import { Box, Grid } from '@mui/material';
import { PRIMARY_COLOR } from '../../styles/constants';

export interface IPendingValidationProps {}

interface IFormValue {
  isTouring: boolean;
}

type Props = IPendingValidationProps &
  RouteComponentProps<{}> &
  IWithAccountProps;

interface IState {
  error: boolean;
}
class PendingValidation extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: false,
    };
  }

  public onSubmit = async (values: IFormValue, { setSubmitting }: any) => {
    const {
      updateAccount,
      account,
      // history
    } = this.props;
    this.setState({ error: false });
    try {
      await updateAccount({
        ...account,
        ...values,
      });
      location.reload();
      // history.push('/login');
    } catch (err) {
      this.setState({ error: true });
    }
    setSubmitting(false);
  };

  public renderForm = (props: FormikProps<IFormValue>) => {
    return (
      <form onSubmit={props.handleSubmit}>
        <Grid
          container={true}
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item={true} xs={12} container={true} justifyContent={'center'}>
            <div style={{ color: PRIMARY_COLOR }}>Pending Application</div>
          </Grid>
          {this.state.error && (
            <FormMessage
              type="ERROR"
              message="An unexpected error happended, please reload your window."
              visible={this.state.error}
            />
          )}
          <Grid item={true} xs={12}>
            Your application is being processed by our administrator, you will
            receive an email as soon as it is approved.
          </Grid>
          <Grid item={true} xs={12}>
            <FormSubmit newButton={true} variant="contained">
              Discover Artpool
            </FormSubmit>
          </Grid>
        </Grid>
      </form>
    );
  };

  public render() {
    const initialValues: IFormValue = {
      isTouring: true,
    };
    return (
      <Box
        mx={2}
        style={{ height: 'calc(100vh - 90px)' }}
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <Grid container={true} direction="column" spacing={2} xs={12} md={5}>
          <Formik
            initialValues={initialValues}
            onSubmit={this.onSubmit}
            render={this.renderForm}
          />
        </Grid>
      </Box>
    );
  }
}

export default compose<Props, IPendingValidationProps>(
  withRouter,
  withAccount
)(PendingValidation);
