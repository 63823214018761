import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import {} from '@mui/material';
import { compose } from 'recompose';

export interface ISubTitleProps {
  html?: string;
  className?: string;
  children: React.ReactNode;
}

type Props = ISubTitleProps & WithStyles<string>;

const styles = createStyles({
  root: {
    fontSize: '12px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: '#afb0b3',
    fontFamily: 'Replica-Regular',
    whiteSpace: 'pre-wrap',
  },
});

class SubTitle extends React.Component<Props, any> {
  public render() {
    const { classes, className, html } = this.props;
    return (
      <div
        className={`${classes.root} ${className}`}
        dangerouslySetInnerHTML={html ? { __html: html } : undefined}
        children={this.props.children}
      />
    );
  }
}

export default compose<Props, ISubTitleProps>(withStyles(styles))(SubTitle);
