export const generateLocation = (object: any) => {
  if (object.location && object.location.address) {
    return object.location.address;
  } else {
    // if (object.city && object.state && object.country){ return `${object.city},${object.state}, ${object.country}`; }
    if (object.city && object.country) {
      return `${object.city}, ${object.country}`;
    }
    if (object.city) {
      return object.city;
    }
    if (object.country) {
      return object.country;
    }
  }
};

export const generateCityLocation = (object: any) => {
  // if (object.city && object.state && object.country){ return `${object.city},${object.state}, ${object.country}`; }
  if (!object) {
    return '';
  }
  if (object.city && object.country) {
    return `${object.city}, ${object.country}`;
  }
  if (object.city) {
    return object.city;
  }
  if (object.country) {
    return object.country;
  }
  if (object.address) {
    return object.address;
  }
};

export const generateAddressLocation = (object: any) => {
  // if (object.city && object.state && object.country){ return `${object.city},${object.state}, ${object.country}`; }
  if (object.address) {
    return object.address;
  }
  if (object.city && object.country) {
    return `${object.city}, ${object.country}`;
  }
  if (object.city) {
    return object.city;
  }
  if (object.country) {
    return object.country;
  }
};
