import {
  default as withWidth,
  isWidthDown,
  WithWidth,
} from '@mui/material/Hidden/withWidth';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import * as React from 'react';
import { Grid } from '@mui/material';
import { compose } from 'recompose';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import FormCancel from '../../components/form/FormCancel';
import { PostTypeOptions } from '../../types/posts';
import IconOnlineExhibition from '../../library/dataDisplay/icons/IconOnlineExhibition';
import {
  DARK_GREY_COLOR,
  EXHIBITION_BREAKPOINT,
  PRIMARY_COLOR,
} from '../../styles/constants';
import InfoSquareTooltip from '../../library/dataDisplay/tooltips/InfoSquareTooltip';
import { Link } from 'react-router-dom';
import { ButtonColors, StyledButton } from '@acg/frontend-ui-artpool';

export interface IPostTypeFormProps {
  onDone: () => void;
  onClickSelect: (id: string) => void;
  show: boolean;
}

const style = createStyles({
  cancelSection: {
    marginTop: 20,
  },
  sectionContainer: {
    textAlign: 'center',
    padding: '0px 15px',
  },
  headerSection: {
    fontFamily: 'Replica-Regular',
    fontSize: 20,
    textAlign: 'center',
    letterSpacing: -0.24,
  },
  infoSection: {
    textAlign: 'left',
    color: DARK_GREY_COLOR,
    fontSize: 12,
  },
  link: {
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    cursor: 'pointer',
    color: 'black',
    '&:hover': {
      color: PRIMARY_COLOR,
    },
  },
});

type Props = WithStyles<string> &
  IPostTypeFormProps &
  IWithAccountProps &
  WithWidth;

function PostTypeForm(props: Props) {
  const { account, classes, show, width, onDone, ...rest } = props;

  const isMobile = isWidthDown('md', width);
  const isExhibitionBreakpoint = isWidthDown(EXHIBITION_BREAKPOINT, width);

  const isVenuePost = location.pathname.indexOf('venues') > 0;

  const renderButton = (id: string, label: string) => {
    const onClickSelect = () => {
      props.onClickSelect(id);
    };

    if (id === 'ONLINEEXHIBITION' && !account.isExhibitionAdmin) {
      return;
    }

    return (
      <StyledButton
        variant={id === 'ONLINEEXHIBITION' ? 'contained' : 'outlined'}
        size={isMobile ? 'small' : 'large'}
        bgColor={id === 'ONLINEEXHIBITION' ? ButtonColors.GREEN : undefined}
        aria-label="Add"
        fullWidth
        style={{ marginBottom: 10 }}
        disabled={
          id === 'ONLINEEXHIBITION' && isExhibitionBreakpoint ? true : false
        }
        onClick={onClickSelect}
        key={id}
      >
        {label}
      </StyledButton>
    );
  };

  // const renderExhibitionCreation = () => {
  //   return (
  //
  //   );
  // };

  return show === false ? (
    <></>
  ) : (
    <Grid
      item
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          className={classes.sectionContainer}
          style={{ display: 'flex', flexDirection: 'column' }}
          order={isMobile ? 2 : 1}
        >
          <p className={classes.headerSection}>Build Your Exhibition Project</p>
          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <IconOnlineExhibition
                height={350}
                fill={DARK_GREY_COLOR}
                style={{ margin: -34 }}
              />
              <p style={{ color: DARK_GREY_COLOR, fontSize: 12 }}>
                Create a visual representation of your curatorial concept
              </p>
            </div>
            {isExhibitionBreakpoint && (
              <p style={{ margin: 0 }} className={classes.infoSection}>
                * Only available in desktop
              </p>
            )}
            {PostTypeOptions.map((type) => {
              if (type.id === 'ONLINEEXHIBITION') {
                return renderButton(type.id, 'Exhibition Tool');
              } else {
                return;
              }
            })}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'left',
              }}
            >
              <p style={{ marginRight: 88 }}>
                <InfoSquareTooltip
                  helpText={
                    <React.Fragment>
                      Want to know more about this Exhibition Tool? <br />
                      <br />
                      Visit our{' '}
                      <Link className={classes.link} to={'/FAQ'}>
                        FAQ
                      </Link>{' '}
                      page to know how it was been done
                      <br />
                      <br />
                      See the Exhibiton example:
                      <br />
                      <Link
                        className={classes.link}
                        to={'/online-exhibition/wildflowers-grow-in-fields'}
                      >
                        Wildflowers grow in fields, wildflowers are beautiful
                      </Link>
                      <br />
                      Filippo Minelli
                      <br />
                      Curated by Pauline Foessel
                    </React.Fragment>
                  }
                />
              </p>
              <p className={classes.infoSection}>
                It can be used as:
                <br />
                - An online version of your physical exhibition with multimedia
                add-ons (videos, interviews, etc.)
                <br />
                - A 100% digital exhibition
                <br />
                - A pitching asset to institutions or galleries
                <br />
                - A management assistant tool
                <br />
              </p>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={6} order={isMobile ? 1 : 2}>
          <div
            className={classes.sectionContainer}
            style={
              !isMobile ? { borderLeft: `1px solid ${DARK_GREY_COLOR}` } : {}
            }
          >
            <p
              className={classes.headerSection}
              style={{ color: DARK_GREY_COLOR, paddingBottom: 35 }}
            >
              Post About Your {isVenuePost ? 'Venue' : 'Work'}
            </p>

            {PostTypeOptions.map((type) => {
              if (type.id === 'RELATEDTOYOURWORK') {
                return renderButton(
                  type.id,
                  `Related To Your ${isVenuePost ? 'Venue' : 'Work'}*`
                );
              }
              if (type.id !== 'ONLINEEXHIBITION') {
                return renderButton(type.id, type.label);
              } else {
                return;
              }
            })}
            <p className={classes.infoSection}>
              * Share anything related to your curatorial work like your
              inspiration, talk, art travel, exhibition visit, art fairs,
              residency, etc...
            </p>
          </div>
        </Grid>
        {/*{isMobile && renderExhibitionCreation()}*/}
      </Grid>

      <Grid container>
        <Grid
          item
          xs={12}
          className={classes.cancelSection}
          style={{
            alignSelf: 'flex-end',
            paddingLeft: '20%',
            paddingRight: '20%',
            marginBottom: 50,
          }}
        >
          <FormCancel onClick={onDone} styleOveride={{ borderRadius: 8 }}>
            Cancel
          </FormCancel>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default compose<Props, IPostTypeFormProps>(
  withStyles(style),
  withAccount,
  withWidth()
)(PostTypeForm);
