import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import {} from '@mui/material';
import { compose } from 'recompose';

export interface ICaptionProps {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

type Props = ICaptionProps & WithStyles<string>;

const styles = createStyles({
  root: {
    fontSize: '10px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.2,
    letterSpacing: 'normal',
    color: '#2b2e34',
    fontFamily: 'Replica-Regular',
  },
});

class Caption extends React.Component<Props, any> {
  public render() {
    const { classes, className, style } = this.props;
    return (
      <div className={`${classes.root} ${className}`} style={style}>
        {this.props.children}
      </div>
    );
  }
}

export default compose<Props, ICaptionProps>(withStyles(styles))(Caption);
