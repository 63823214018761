import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { Grid, Avatar } from '@mui/material';
import { GREY_COLOR } from '../../styles/constants';
import Title from '../../library/dataDisplay/typography/Title';
import SubTitle from '../../library/dataDisplay/typography/SubTitle';
import { generateCityLocation } from '../../utils/locationHelper';
import { IInstitution } from '../../types/institution';
import { getMediumImageUrl } from '../../utils/imageUrl';

export interface IProfileSmallProps {
  institution: IInstitution;
}

type Props = IProfileSmallProps & WithStyles<string>;

const styles = createStyles({
  profile: {
    paddingTop: '0.75rem',
  },
  profileContainer: {
    backgroundColor: GREY_COLOR,
    paddingTop: 14,
    paddingLeft: 10,
    paddingBottom: 10,
  },
  user: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '40px',
  },
  userName: {
    fontSize: 16,
  },
});

class ProfileSmall extends React.Component<Props, any> {
  public render() {
    const { classes, institution } = this.props;

    return (
      <Grid container={true} className={classes.profile}>
        <Grid item={true} xs={12} className={classes.profileContainer}>
          <Avatar
            alt={`${institution.name}`}
            className={classes.picture}
            src={getMediumImageUrl(institution.profilePicture)}
            style={{ float: 'left', marginRight: 10, marginLeft: 16 }}
          />

          <div className={classes.user}>
            {institution.name && (
              <Title className={classes.userName}>{institution.name}</Title>
            )}
            <SubTitle>{generateCityLocation(institution.location)}</SubTitle>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default compose<Props, IProfileSmallProps>(withStyles(styles))(
  ProfileSmall
);
