import * as React from 'react';
import { Grid } from '@mui/material';
import { useIsWidthDown } from '../../../hooks/theme';
import { useHistory } from 'react-router-dom';
import { StyledButton } from '@acg/frontend-ui-artpool';

export const ApplyNow = () => {
  const isPad = useIsWidthDown('md');
  const isMobile = useIsWidthDown('sm');
  const history = useHistory();
  const onSignUpClick = () => history.push('/login');

  return (
    <Grid
      container
      style={{
        backgroundColor: '#cab8ff',
        padding: '30px 20px 30px 20px',
        paddingLeft: `${isMobile ? '45px' : '135px'}`,
        paddingRight: `${isMobile ? '20px' : '135px'}`,
      }}
      spacing={3}
      alignItems="center"
    >
      <StyledButton
        style={{
          borderRadius: 8,
          fontSize: `${isPad ? '20px' : '30px'}`,
          width: `${isMobile ? '100%' : 'auto'}`,
          marginRight: `${isMobile ? '0' : '20px  '}`,
        }}
        variant="contained"
        color="primary"
        onClick={onSignUpClick}
      >
        Apply now!*
      </StyledButton>
      <div>
        <h3>
          Be a part of an interactive, global community of curators, artists and
          institutions!
        </h3>
        <p>
          * Each application is verified by our team, one of our member will
          reach out to you to ask for track record and verify your experience.
        </p>
      </div>
    </Grid>
  );
};
