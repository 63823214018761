import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { Grid } from '@mui/material';
import { compose } from 'recompose';
import {
  InjectedFormikProps,
  Formik,
  FormikProps,
  FormikHelpers,
} from 'formik';
import { IValidationConfig, generateValidator } from '../../utils/formatters';
import FormMessage from '../../components/form/FormMessage';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import ACGModalContent from '../../components/modal/ACGModalContent';
import ProfileSmall from '../../library/dataDisplay/profile/ProfileSmall';
import Title from '../../library/dataDisplay/typography/Title';
import UserService from '../../services/UserService';
import { IUser } from '../../types/user';
import Loading from '../../components/loading/Loading';
import SubTitle from '../../library/dataDisplay/typography/SubTitle';
import { withRouter, RouteComponentProps } from 'react-router';
import DesignedButton from '../../library/inputs/DesignedButton';
import { environment } from '../../environments/environment';
import { ACGAppPaths } from '@acg/shared/const';

const { REACT_APP_PAULINE } = environment;

export interface IOnboardingViewerFormProps {
  onOnboardingEnd: () => void;
}

const style = createStyles({
  alignCenter: {
    textAlign: 'center',
  },
  title: {
    fontSize: 16,
  },
});

export interface IOnboardingViewerInitialValue {}

interface IState {
  error: boolean;
  loading: boolean;
  pauline?: IUser;
}

type Props = WithStyles<string> &
  InjectedFormikProps<
    IOnboardingViewerFormProps,
    IOnboardingViewerInitialValue
  > &
  IWithAccountProps &
  RouteComponentProps<{}>;

class OnboardingViewer extends React.Component<Props, IState> {
  private userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      error: false,
      loading: true,
    };
  }

  public componentDidMount() {
    this.fetchPauline();
  }

  public fetchPauline = () => {
    this.setState({ loading: true });
    this.userService
      .getUser(`${REACT_APP_PAULINE}` ? `${REACT_APP_PAULINE}` : 'luisa-santos')
      .then((r) => {
        this.setState({ pauline: r.data, loading: false });
      })
      .catch(() => this.setState({ error: true, loading: false }));
  };

  public onSubmit = async (
    values: IOnboardingViewerInitialValue,
    actions: FormikHelpers<IOnboardingViewerInitialValue>
  ) => {
    const { updateAccount, account, history } = this.props;

    this.setState({ error: false });
    try {
      await updateAccount({
        ...account,
        hasTakenTour: true,
      });
      this.props.onOnboardingEnd();
      history.push('/login', {
        from: `/users/${
          REACT_APP_PAULINE ? REACT_APP_PAULINE : 'luisa-santos'
        }/posts`,
      });
    } catch (err) {
      this.setState({ error: true });
    }

    actions.setSubmitting(false);
  };

  public onDiscoverGrid = async () => {
    const { updateAccount, account, history } = this.props;

    this.setState({ error: false, loading: true });
    try {
      await updateAccount({
        ...account,
        hasTakenTour: true,
      });
      this.props.onOnboardingEnd();
      history.push('/login', { from: ACGAppPaths.ROOT });
    } catch (err) {
      this.setState({ error: true, loading: false });
    }
  };

  public renderForm = (props: FormikProps<IOnboardingViewerInitialValue>) => {
    const { classes } = this.props;

    const { loading, pauline, error } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <form onSubmit={props.handleSubmit}>
        <Grid container={true} spacing={1}>
          <FormMessage
            message={'An unexpected error happened, please try again.'}
            visible={!!error}
            type={'ERROR'}
          />

          {pauline && (
            <React.Fragment>
              <Grid item={true} xs={12}>
                <ProfileSmall user={pauline} />
              </Grid>
              <Grid style={{ padding: 4 }}>
                <SubTitle>{pauline.bio}</SubTitle>
              </Grid>
              <Grid className={classes.alignCenter} item={true} xs={12}>
                <br />
                <Title className={classes.title}>
                  Welcome to Artpool.
                </Title>
                <Title className={classes.title}>
                  The first social network dedicated to connecting art
                  enthusiasts.
                </Title>
                <br />
              </Grid>
              <Grid className={classes.alignCenter} item={true} xs={12}>
                <Title className={classes.title}>
                  To help get you started, we selected this Curator that you
                  might find interesting.
                </Title>
                <br />
              </Grid>
              <Grid className={classes.alignCenter} item={true} xs={12}>
                <Title className={classes.title}>
                  Discover the profile, Exhibitions and Inspirations.
                </Title>
              </Grid>
              <Grid item={true} xs={12}>
                <Grid container={true} spacing={1}>
                  <Grid item={true} xs={6}>
                    <DesignedButton
                      ACGType={'neutral'}
                      size={'large'}
                      fullWidth={true}
                      onClick={this.onDiscoverGrid}
                    >
                      Discover the Grid
                    </DesignedButton>
                  </Grid>
                  <Grid item={true} xs={6}>
                    <DesignedButton
                      ACGType={'main'}
                      type="submit"
                      size={'large'}
                      fullWidth={true}
                      disabled={props.isSubmitting}
                    >
                      Discover this Curator
                    </DesignedButton>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </form>
    );
  };

  public render() {
    const initialValues: IOnboardingViewerInitialValue = {};

    const validationRule: IValidationConfig = {};

    const validation = generateValidator(validationRule);

    return (
      <ACGModalContent>
        <Formik
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          render={this.renderForm}
          validate={validation}
        />
      </ACGModalContent>
    );
  }
}

export default compose<Props, IOnboardingViewerFormProps>(
  withAccount,
  withStyles(style),
  withRouter
)(OnboardingViewer);
