import * as React from 'react';
import { DialogTitleProps } from '@mui/material/DialogTitle';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Typography, IconButton, createStyles } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from '@mui/styles';
import { compose } from 'recompose';

interface IExtendedDialogTitleProps extends DialogTitleProps {
  onClose: () => void;
  children?: React.ReactNode;
}

const styles = createStyles({
  root: {
    margin: 0,
    padding: 10,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 5,
    color: '#000000',
    opacity: 0.4,
  },
});

const ACGDialogTitle = (props: IExtendedDialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes && classes.root} {...other}>
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        {children && (
          <Grid item>
            <Typography variant="h6">{children}</Typography>
          </Grid>
        )}
        {onClose && (
          <Grid item>
            <IconButton
              aria-label="close"
              className={classes && (classes as any).closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </MuiDialogTitle>
  );
};
export default compose<IExtendedDialogTitleProps, IExtendedDialogTitleProps>(
  withStyles(styles)
)(ACGDialogTitle);
