import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { UserCuratorType } from '../../types/user';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Field, Formik, FormikProps } from 'formik';
import { Box, Grid } from '@mui/material';
import { compose } from 'recompose';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import TextInput from '../../components/form/TextInput';
import { generateValidator, IValidationConfig } from '../../utils/formatters';
import SelectInput from '../../components/form/SelectInput';
import FormSubmit from '../../components/form/FormSubmit';

export interface ICuratorStep2Props {}

type Props = RouteComponentProps<{}> &
  ICuratorStep2Props &
  IWithAccountProps &
  WithStyles<string> &
  WithWidth;

const styles = createStyles({
  text: {
    color: 'rgba(0, 0, 0, 0.64)',
    fontSize: '0.9em',
    margin: '0 10px',
  },
  main: {
    width: '100%',
    position: 'relative',
    zIndex: 1,
    minHeight: '100%',
  },
  navbar: {
    flexGrow: 1,
    position: 'sticky',
    display: 'flex',
    justifyContent: 'center',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
  },
  colorDefault: {
    backgroundColor: 'white',
    height: 60,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  content: {
    zIndex: 2,
    width: '100%',
    minHeight: '100%',
  },
  wrapper: {},
  logo: {
    height: 40,
    float: 'left',
    marginRight: 15,
  },
  title: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    float: 'left',
    minWidth: 220,
    cursor: 'pointer',
  },
  titleSmall: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    float: 'left',
    minWidth: 125,
    cursor: 'pointer',
  },
  left: {
    float: 'left',
    width: '100%',
  },
  right: {
    float: 'right',
    width: 250,
  },
  companyName: {
    lineHeight: '40px',
  },
  companyNameSmall: {
    lineHeight: '40px',
    fontSize: '15px',
  },
  beta: {
    width: 26,
    height: 14,
    fontFamily: 'Replica-Regular',
    fontSize: 12,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -0.12,
    textAlign: 'center',
    color: '#6236ff',
    marginLeft: 5,
  },
  grow: {
    flex: 1,
  },
  contentMobile: {
    zIndex: 2,
    height: '100%',
    width: '100%',
    minHeight: '100%',
    justifyContent: 'center',
    paddingTop: 65,
  },
});

interface IFormValue {
  company_type: UserCuratorType;
  company_name: string;
}

interface IState {
  error: boolean;
}

class CuratorStep2 extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: false,
    };
  }

  public onSubmit = async (values: IFormValue, { setSubmitting }: any) => {
    const { history, updateAccount, account } = this.props;
    this.setState({ error: false });
    try {
      await updateAccount({
        ...account,
        ...values,
      });
      history.push('/login');
    } catch (err) {
      this.setState({ error: true });
    }
    setSubmitting(false);
  };

  public renderForm = (props: FormikProps<IFormValue>) => {
    return (
      <form onSubmit={props.handleSubmit}>
        <Grid
          container={true}
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item={true} xs={12} sm={12} md={12}>
            <Field
              name="company_type"
              component={SelectInput}
              inputLabelProps={{
                children: 'You are a:',
              }}
              options={[
                { label: 'Independent', value: 'INDEPENDANT' },
                { label: 'Institution', value: 'INSTITUTION' },
              ]}
            />

            <Field
              name="company_name"
              component={TextInput}
              inputProps={{
                id: 'company_name',
                label: 'Institution Name',
                type: 'company_name',
                disabled: props.values.company_type === 'INDEPENDANT',
              }}
              naked={true}
              newTextInput={true}
            />
          </Grid>

          <Grid item={true} xs={12} sm={12} md={12}>
            <FormSubmit
              disabled={props.isSubmitting}
              newButton={true}
              variant="contained"
            >
              Next Step
            </FormSubmit>
          </Grid>
        </Grid>
      </form>
    );
  };

  public render() {
    // const onHomeClick = () => history.push('/')

    // const hidden = isWidthDown(BREAKPOINT, width);
    // const logoHidden = isWidthDown("xs", width)

    const config: IValidationConfig = {
      company_name: [
        (values: any) =>
          values.company_type === 'INSTITUTION' && !values.company_name
            ? 'REQUIRED'
            : null,
      ],
    };

    const validate = generateValidator(config);

    const initialValues: IFormValue = {
      company_type: 'INSTITUTION',
      company_name: '',
    };

    return (
      <Box
        mx={2}
        style={{ height: 'calc(100vh - 90px)' }}
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <Grid container={true} direction="column" spacing={2}>
          <Formik
            initialValues={initialValues}
            onSubmit={this.onSubmit}
            render={this.renderForm}
            validate={validate}
          />
        </Grid>
      </Box>
    );
  }
}

export default compose<Props, ICuratorStep2Props>(
  withRouter,
  withStyles(styles),
  withWidth(),
  withAccount
)(CuratorStep2);
