import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { Theme } from '@mui/material';

import {} from '@mui/material/';
import { withRouter, RouteComponentProps } from 'react-router';
import { PRIMARY_COLOR } from '../../styles/constants';
import VisitorUserProfile from './VisitorUserProfile';
import { setColorOpacity } from '../../utils/theme';

export interface IVisitorComponentProps {}

const styles = (theme: Theme) =>
  createStyles({
    main: {
      width: '100%',
      position: 'relative',
      zIndex: 1,
      minHeight: '100%',
    },
    navbar: {
      flexGrow: 1,
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
    },
    colorDefault: {
      backgroundColor: 'white',
      height: 60,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    content: {
      zIndex: 2,
      minHeight: '100vh',
    },
    wrapper: {},
    logo: {
      height: 40,
      float: 'left',
      marginRight: 15,
    },
    title: {
      textDecoration: 'none',
      color: 'rgba(0, 0, 0, 0.87)',
      float: 'left',
      minWidth: 220,
      cursor: 'pointer',
    },
    titleSmall: {
      textDecoration: 'none',
      color: 'rgba(0, 0, 0, 0.87)',
      float: 'left',
      minWidth: 125,
      cursor: 'pointer',
    },
    left: {
      float: 'left',
      width: '100%',
    },
    right: {
      float: 'right',
      width: 250,
    },
    companyName: {
      lineHeight: '40px',
    },
    companyNameSmall: {
      lineHeight: '40px',
      fontSize: '15px',
    },
    search: {
      display: 'inline-block',
      width: 'calc(100% - 250px)',
    },
    searchWrapper: {
      width: '50%',
    },
    service: {
      float: 'left',
      height: 60,
      lineHeight: '60px',
      marginRight: 20,
    },
    profile: {
      float: 'right',
      height: 60,
      lineHeight: 60,
      width: 40,
      marginRight: 20,
    },
    grow: {
      flex: 1,
    },
    button: {
      fontFamily: 'Replica-Regular',
      fontSize: 14,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: -0.12,
      textAlign: 'center',
      color: PRIMARY_COLOR,
      cursor: 'pointer',
      textDecoration: 'none',
      marginRight: 20,
    },
    avatar: {
      margin: 10,
      marginLeft: 0,
    },
    link: {
      fontWeight: 500,
      textDecoration: 'none',
    },
    menuItem: {
      '&:hover': {
        backgroundColor: setColorOpacity(theme.palette.primary.light, 70),
      },
    },
    menu: {
      backgroundColor: '#fff',
    },
    beta: {
      width: 26,
      height: 14,
      fontFamily: 'Replica-Regular',
      fontSize: 12,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: -0.12,
      textAlign: 'center',
      color: '#6236ff',
      marginLeft: 5,
    },
  });

type Props = IVisitorComponentProps &
  WithStyles<string> &
  WithWidth &
  RouteComponentProps<{}>;

interface IState {
  open: boolean;
  element?: any;
}

class VisitorComponent extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  public onClickOnAction = () => {
    this.setState({
      open: true,
    });
  };

  public handleClose = () => {
    this.setState({
      open: false,
    });
  };

  public handleMenuClick = (event: any) => {
    this.setState({ element: event.currentTarget });
  };

  public handleMenuClose = (event: any) => {
    this.setState({ element: null });
  };

  public redirectToUrl = (event: any) => {
    this.setState({
      open: false,
    });
    window.open('https://curtain.artcuratorgrid.com/');
  };

  // public redirectToGridUrl = (event: any) => {
  //   this.setState({
  //     open: false
  //   });
  //   window.open("https://blog.artcuratorgrid.com/");
  // }

  public render() {
    return <VisitorUserProfile onClickOnAction={this.onClickOnAction} />;
  }
}

export default compose<Props, IVisitorComponentProps>(
  withRouter,
  withStyles(styles),
  withWidth()
)(VisitorComponent);
