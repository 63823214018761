import { InstagramIcon, LinkedinIcon, TwitterIcon } from '@acg/assets';
import { Member } from '../../constants';
import styles from './member-card.module.scss';

type MemberCardProps = Member;

export const MemberCard = ({
  img,
  name,
  role,
  socialIcons,
}: MemberCardProps) => {
  return (
    <article className={styles.card}>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={img} alt={`${name}'s profile`} />
      </div>
      <div className={styles.contentContainer}>
        <div>
          <h4 className={styles.name}>{name}</h4>
          <span className={styles.role}>{role}</span>
        </div>
        <div className={styles.socialIcons}>
          {socialIcons?.linkedin && (
            <a
              href={socialIcons.linkedin}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedinIcon />
            </a>
          )}
          {socialIcons?.twitter && (
            <a
              href={socialIcons.twitter}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon />
            </a>
          )}
          {socialIcons?.instagram && (
            <a
              href={socialIcons.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </a>
          )}
        </div>
      </div>
    </article>
  );
};
