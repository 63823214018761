import React from 'react';
import footerStyle from './footer.module.scss';
import artpoolStyle from '../../styles/artpool-styles.module.scss';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid } from '@mui/material';
import { Caption } from './../../data-display/typography/caption/caption';
import { Body2 } from './../../data-display/typography/body2/body2';
import { Subtitle4 } from './../../data-display/typography/subtitle4/subtitle4';
import { Subtitle3 } from './../../data-display/typography/subtitle3/subtitle3';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  NEUTRAL,
  TelegramIcon,
  themes,
  TwitterIcon,
} from '@acg/shared/assets';
import {
  ACG_ABOUT_US_URL,
  ACG_PRIVACY_POLICY_URL,
  ACG_SERVICES_URL,
  ACG_TS_AND_CS_URL,
  ARTPOOL_MY_ACCOUNT_URL,
  ARTPOOL_URL,
  BLOG_URL,
  CURTAIN_URL,
  FACEBOOK_URL,
  FAQ_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  TELEGRAM_URL,
  TWITTER_URL,
} from '@acg/shared/const';
import { ACG_URL, ArtpoolAppPaths } from '@acg/shared/const';
import Stack from '@mui/material/Stack';

export const Footer = () => {
  const isMedium = useMediaQuery(themes.artpoolTheme.breakpoints.down('lg'));
  const isSmall = useMediaQuery(themes.artpoolTheme.breakpoints.down('md'));

  const BOTTOM_NAVIGATION_HEIGHT = '62px';
  return (
    <Grid
      container
      className={footerStyle.container}
      justifyContent={isMedium ? 'center' : undefined}
      style={{
        padding: isMedium
          ? `20px 20px ${BOTTOM_NAVIGATION_HEIGHT} 20px`
          : '40px 60px',
      }}
    >
      {/* COPYRIGHT SECTION */}
      <Grid
        item
        xs={12}
        lg={5}
        style={{ textAlign: isMedium ? 'center' : 'left' }}
      >
        <Link to={'/'}>
          <img
            className={footerStyle.logo_artpool}
            alt="Logo"
            src="/assets/logos/artpool/ARTPOOL_Green_RGB.svg"
          />
        </Link>
        {isMedium ? (
          <div className={footerStyle.copyright}>
            <Caption style={{ color: NEUTRAL[600] }}>
              <a href={ACG_URL} target={'blank'}>
                ©️️ {new Date().getFullYear()} Artpool. <br />
                All rights reserved
              </a>
            </Caption>
          </div>
        ) : (
          <a href={ACG_URL} target={'blank'}>
            <Grid container alignItems={'center'} spacing={1}>
              <Grid item>
                <div className={footerStyle.copyright}>
                  <Caption style={{ color: NEUTRAL[600] }}>
                    ©️️ {new Date().getFullYear()} Artpool
                  </Caption>
                </div>
              </Grid>
            </Grid>
          </a>
        )}
        <Body2 style={{ color: NEUTRAL[600], paddingTop: 15 }}>
          Revolutionize art curation
        </Body2>
        <a
          href={`/assets/images/acg/Portugal2020Doc.pdf`}
          target={'_blank'}
          rel="noopener noreferrer"
          download={false}
        >
          <img
            src={'/assets/images/acg/Lisboa2020_RGB_1.png'}
            style={{ maxWidth: 250, paddingTop: 20 }}
          />
        </a>
      </Grid>

      {/* LINKS SECTION */}
      <Grid item xs={12} lg={4} style={isMedium ? { padding: 30 } : {}}>
        <Grid container>
          <Grid item xs={4}>
            <Stack spacing={3} textAlign={isMedium ? 'center' : 'left'}>
              <Subtitle4>
                <a href={ACG_ABOUT_US_URL} className={artpoolStyle.nav_link}>
                  About
                </a>
              </Subtitle4>
              <Subtitle4>
                <a href={ACG_SERVICES_URL} className={artpoolStyle.nav_link}>
                  Services
                </a>
              </Subtitle4>
              <Subtitle4>
                <a href={ARTPOOL_URL} className={artpoolStyle.nav_link}>
                  Marketplace
                </a>
              </Subtitle4>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack spacing={3} textAlign={isMedium ? 'center' : 'left'}>
              <Subtitle4>
                <a
                  href={ARTPOOL_MY_ACCOUNT_URL}
                  className={artpoolStyle.nav_link}
                >
                  My NFTs
                </a>
              </Subtitle4>
              <Subtitle4>
                <a
                  href={CURTAIN_URL}
                  target={'blank'}
                  className={artpoolStyle.nav_link}
                >
                  Editorial
                </a>
              </Subtitle4>
              <Subtitle4>
                <a
                  href={FAQ_URL}
                  target={'blank'}
                  className={artpoolStyle.nav_link}
                >
                  FAQ
                </a>
              </Subtitle4>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack spacing={3} textAlign={isMedium ? 'center' : 'left'}>
              <Subtitle4>
                <a href={ACG_TS_AND_CS_URL} className={artpoolStyle.nav_link}>
                  Our Conditions
                </a>
              </Subtitle4>
              <Subtitle4>
                <a
                  href={ACG_PRIVACY_POLICY_URL}
                  className={artpoolStyle.nav_link}
                >
                  Privacy Policy
                </a>
              </Subtitle4>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        lg={3}
        style={{
          textAlign: isMedium ? 'center' : 'left',
          borderTop: isMedium ? `1px solid ${NEUTRAL[300]}` : undefined,
        }}
      >
        <Subtitle3 style={{ paddingBottom: 20 }}>
          <b>Connect with us</b>
        </Subtitle3>
        <Stack
          spacing={2}
          justifyContent={isMedium ? 'center' : 'left'}
          direction={'row'}
        >
          <a href={INSTAGRAM_URL} target="blank" className={artpoolStyle.icon}>
            <InstagramIcon fontSize={'inherit'} />
          </a>
          <a href={TELEGRAM_URL} target="blank" className={artpoolStyle.icon}>
            <TelegramIcon fontSize={'inherit'} />
          </a>
          <a href={FACEBOOK_URL} target="blank" className={artpoolStyle.icon}>
            <FacebookIcon fontSize={'inherit'} />
          </a>
          <a href={TWITTER_URL} target="blank" className={artpoolStyle.icon}>
            <TwitterIcon fontSize={'inherit'} />
          </a>
          <a href={LINKEDIN_URL} target="blank" className={artpoolStyle.icon}>
            <LinkedinIcon fontSize={'inherit'} />
          </a>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Footer;
