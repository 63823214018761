import * as React from 'react';
import { IUser } from '../../../types/user';
import { Avatar } from '@mui/material';
import { getMediumImageUrl } from '../../../utils/imageUrl';

export interface IUserPictureProps
  extends React.HTMLAttributes<HTMLDivElement> {
  user: IUser;
}

export default function UserPicture(props: IUserPictureProps) {
  const { user, ...rest } = props;

  let avatar = (
    <Avatar
      alt={`${user.firstName} ${user.lastName}`}
      src={getMediumImageUrl(user.profilePicture)}
      {...rest}
    />
  );

  if (!user.profilePicture) {
    avatar = (
      <Avatar {...rest}>
        {user.firstName && user.firstName.charAt(0)
          ? user.firstName.charAt(0)
          : ''}
        {user.lastName && user.lastName.charAt(0)
          ? user.lastName.charAt(0)
          : ''}
      </Avatar>
    );
  }

  return avatar;
}
