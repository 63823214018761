import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import {} from '@mui/material';
import { IComment } from '../../types/comments';
import { IUser } from '../../types/user';
import { Formik, FormikProps, FormikHelpers, Field } from 'formik';
import { IValidationConfig, generateValidator } from '../../utils/formatters';
import { IPost } from '../../types/posts';
import TextInput from '../../components/form/TextInput';
import UserPicture from '../../library/dataDisplay/profile/UserPicture';
import Comment from '../../components/comment/Comment';
import FormLink from '../../library/dataDisplay/typography/FormLink';
import Caption from '../../library/dataDisplay/typography/Caption';
import {} from '@mui/material/';
import { withRouter, RouteComponentProps } from 'react-router';
import DesignedButton from '../../library/inputs/DesignedButton';
import Grid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';
import * as commentsActions from '../../store/modules/comments/actions';

export interface ICommentFormProps {
  user: IUser | undefined;
  post: IPost;
  initialValues?: IComment;
  isExhibition?: boolean;
  onUpdate: () => void;
  onCreate?: () => void;
  onDelete?: () => void;
  onDone: (comment: IComment) => void;
}

const style = createStyles({
  root: {},
  wrapper: {
    padding: '15px 0',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
  },
  buttonMargin: {
    marginTop: '3px !important',
    marginBottom: '3px !important',
  },
  buttonSmall: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 10,
    marginTop: 10,
    width: '100%',
  },
  smallLabel: {
    fontSize: '0.8em',
  },
});

type Props = ICommentFormProps &
  WithStyles<string> &
  WithWidth &
  RouteComponentProps<{}>;

type ICommentInitialValues = Partial<IComment>;

const CommentForm: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const {
    user,
    classes,
    initialValues: propsInitialValues,
    post,
    onCreate,
    onUpdate,
    onDelete,
    history,
    isExhibition,
  } = props;

  if (!user) {
    return null;
  }

  const onSubmit = async (
    values: ICommentInitialValues,
    actions: FormikHelpers<ICommentInitialValues>
  ) => {
    // setError(false)
    try {
      const payload: Partial<IComment> = {
        message: values.message,
        isExhibition,
      };
      if (values.id) {
        // modify comment
        dispatch(commentsActions.updateComment(post.id, values.id, payload));
        onUpdate();
      } else {
        dispatch(commentsActions.createComment(post.id, payload));
        if (onCreate) {
          onCreate();
        }
      }
      actions.resetForm();
    } catch (err) {
      // const formattedErrors = formatErrors(err);
      // setError(formattedErrors ? false : true)
    }

    actions.setSubmitting(false);
  };

  const deleteComment = (comment: IComment) => () => {
    // commentService.deletePostComment(post.id, comment.id);
    dispatch(commentsActions.deleteComment(post.id, comment.id));

    if (onDelete) {
      onDelete();
    }
  };

  const cancel = (comment: IComment) => () => {
    return props.onDone(comment);
  };

  const renderForm = (formProps: FormikProps<ICommentInitialValues>) => {
    const additionalButton = (
      <Grid
        container={true}
        spacing={1}
        alignItems={'center'}
        justifyContent={'flex-end'}
      >
        <Grid item={true}>
          <FormLink
            onClick={deleteComment(formProps.initialValues as IComment)}
          >
            <Caption
              style={{
                cursor: 'pointer',
                display: 'inline-block',
                textDecoration: 'underline',
              }}
            >
              Delete Comment
            </Caption>
          </FormLink>
        </Grid>
        <Grid item={true}>
          <DesignedButton
            ACGType={'secondary'}
            size={'small'}
            onClick={cancel(formProps.initialValues as IComment)}
          >
            Cancel
          </DesignedButton>
        </Grid>
        <Grid item={true}>
          <DesignedButton type={'submit'} ACGType={'main'} size={'small'}>
            Update
          </DesignedButton>
        </Grid>
      </Grid>
    );
    const onClick = () =>
      history.push(`/users/${(formProps.initialValues.created_by as any).id}`);
    return (
      <form onSubmit={formProps.handleSubmit} style={{ width: '100%' }}>
        {formProps.initialValues.id ? (
          <Comment
            onClick={onClick}
            comment={formProps.initialValues as IComment}
            additionalButton={additionalButton}
            editing={true}
          />
        ) : (
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={2} sm={1}>
              <UserPicture user={user} />
            </Grid>
            <Grid item={true} xs={10} sm={9} className={classes.field}>
              <Field
                name="message"
                component={TextInput}
                inputProps={{
                  id: 'message',
                  label: 'Write a Comment...',
                  type: 'message',
                  multiline: true,
                  rows: 4,
                  maxRows: 20,
                  margin: 'normal',
                  variant: 'outlined',
                  error: undefined,
                  helperText: undefined,
                }}
                naked={true}
                small={true}
              />
            </Grid>
            <Grid item={true} xs={12} sm={2} style={{ textAlign: 'right' }}>
              <DesignedButton
                type={'submit'}
                fullWidth={true}
                ACGType={'main'}
                disabled={formProps.isSubmitting}
                size={'small'}
              >
                Post
              </DesignedButton>
            </Grid>
          </Grid>
        )}
      </form>
    );
  };

  const initialValues: ICommentInitialValues = {
    message: '',
  };

  const validationRule: IValidationConfig = {
    message: ['REQUIRED'],
  };

  const validation = generateValidator(validationRule);

  return (
    <Formik
      initialValues={propsInitialValues ? propsInitialValues : initialValues}
      onSubmit={onSubmit}
      render={renderForm}
      validate={validation}
    />
  );
};

export default compose<Props, ICommentFormProps>(
  withStyles(style),
  withWidth(),
  withRouter
)(CommentForm);
