import * as React from 'react';
import { makeStyles } from '@mui/styles';
import BottomNavigation from '@mui/material/BottomNavigation';
import { WithWidth } from '@mui/material/Hidden/withWidth';
import IconPost from '../../library/dataDisplay/icons/IconPost';
import IconNetwork from '../../library/dataDisplay/icons/IconNetwork';
import IconOnlineExhibition from '../../library/dataDisplay/icons/IconOnlineExhibition';
import { GREY_COLOR, PRIMARY_COLOR } from '../../styles/constants';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import { IExtraInfoButtonProps } from '../services/ExtraInfoButton';
import { compose } from 'recompose';
import { checkRight } from '../../utils/userUtils';
import IconVenues from '../../components/icons/IconVenues';
import * as _ from 'lodash';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles({
  nav: {
    width: '100vw',
    height: 75,
    backgroundColor: GREY_COLOR,
  },
  fixed: {
    bottom: 0,
    zIndex: 1000,
    position: 'fixed',
  },
  navFiller: {
    visibility: 'hidden',
  },
  buttonStyle: {
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
      color: PRIMARY_COLOR,
    },
  },
  iconText: {
    display: 'block',
    fontSize: 12,
    fontFamily: 'Replica-Regular',
    whiteSpace: 'nowrap',
    margin: 0,
  },
});

type Props = IExtraInfoButtonProps &
  IWithAccountProps &
  WithWidth &
  RouteComponentProps<{}>;

function SimpleBottomNavigation(props: Props) {
  const classes = useStyles();

  const { account } = props;

  const managesVenue = () => {
    if (account.manages && account.manages.length > 0) {
      const venueCurrentUrl = location.pathname.replace('/venues/', '');
      return _.find(account.manages, (ele: any) => {
        return ele.url === venueCurrentUrl;
      });
    }
    return false;
  };

  const handleCreateClick = (event: any) => {
    if (right === 'MANAGER') {
      if (account.manages && account.manages.length > 0) {
        if (location.pathname.indexOf('/venues/') < 0) {
          props.history.push(
            `/venues/${account.manages[0].url}/posts?create=true`
          );
        } else {
          props.history.push(`${location.pathname}?create=true`);
        }
        return;
      }
    } else {
      if (managesVenue()) {
        if (
          account.profile === 'CURATOR' &&
          location.pathname.indexOf('/venues/') === 0 &&
          account.manages
        ) {
          props.history.push(
            `/venues/${account.manages[0].url}/posts?create=true`
          );
        } else if (location.pathname.indexOf('/users/') < 0) {
          props.history.push(`/users/${props.account.url}/posts?create=true`);
        } else {
          props.history.push(`${location.pathname}?create=true`);
        }
      } else {
        props.history.push(`/users/${props.account.url}/posts?create=true`);
      }
      return;
    }
    props.history.push(`/users/${props.account.url}/posts?create=true`);
  };

  const handleExhibitionClick = (event: any) => {
    props.history.push('/exhibitions');
  };

  const right = checkRight(props.account);

  return (
    <>
      <div className={`${classes.nav} ${classes.navFiller}`} />
      <BottomNavigation
        showLabels
        className={`${classes.nav} ${classes.fixed}`}
      >
        <Grid
          container
          justifyContent={'space-around'}
          alignItems={'center'}
          style={{
            padding: 5,
            width: '100%',
          }}
        >
          {right === 'ALL' ? (
            <Grid item className={classes.buttonStyle}>
              <Link
                to={'/users'}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <IconNetwork />
                <p className={classes.iconText}>Network</p>
              </Link>
            </Grid>
          ) : (
            <Grid
              item
              onClick={handleExhibitionClick}
              className={classes.buttonStyle}
            >
              <IconOnlineExhibition />
              <p className={classes.iconText}>Exhibitions</p>
            </Grid>
          )}
          {(right === 'ARTIST' || right === 'ALL' || right === 'MANAGER') && (
            <Grid item className={classes.buttonStyle}>
              <div onClick={handleCreateClick} className={classes.buttonStyle}>
                <IconPost />
                <p className={classes.iconText}>Create Post</p>
              </div>
            </Grid>
          )}
          <Grid item className={classes.buttonStyle}>
            <Link
              to={'/venues'}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <IconVenues />
              <p className={classes.iconText}>Art Venues</p>
            </Link>
          </Grid>
        </Grid>
      </BottomNavigation>
    </>
  );
}

export default compose<Props, IExtraInfoButtonProps>(
  withRouter,
  withAccount
)(SimpleBottomNavigation);
