import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';

import {
  Grid,
  Dialog,
  Backdrop,
  Typography,
  IconButton,
  Button,
  DialogContent,
  DialogActions,
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { SPACING } from '../../styles/constants';
import { IArtistUser, ICuratorUser, UserViewerType } from '../../types/user';
import { Field, Formik, FormikHelpers, FormikProps } from 'formik';
import SelectInput from '../form/SelectInput';

const style = createStyles({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  titleContainer: {
    padding: SPACING,
  },
  button: {
    padding: 25,
    borderRadius: 100,
    marginBottom: 20,
  },
  buttonSmall: {
    padding: '7px 20px',
    minHeight: 'auto',
    borderRadius: 30,
    display: 'inline-block',
    width: 'auto',
    marginLeft: 5,
    fontSize: '0.6em',
    minWidth: 0,
  },
  closeButton: {
    position: 'absolute',
    right: '2px',
    top: '2px',
  },
});

interface IModalChangeUserTypeProps {
  onChange: (type: UserViewerType) => void;
  onClose: () => void;
  isOpen: boolean;
  user?: ICuratorUser | IArtistUser;
}

interface IInitialValues {
  profile: 'VIEWER' | 'ARTIST';
  type: UserViewerType;
}

type props = IModalChangeUserTypeProps & WithStyles<string>;

const ModalChangeUserType: React.FC<props> = (Props) => {
  const { onChange, isOpen, onClose, classes, user } = Props;

  const initialValues: IInitialValues = {
    profile: 'VIEWER',
    type: user && user.profile === 'CURATOR' ? 'ART CONSULTANT' : 'ARTIST',
  };

  const renderForm = (propsFormik: FormikProps<IInitialValues>) => (
    <>
      <form onSubmit={propsFormik.handleSubmit}>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={6}>
            <Field
              name="profile"
              component={SelectInput}
              options={[
                { label: 'Viewer', value: 'VIEWER' },
                { label: 'Artist', value: 'ARTIST' },
              ]}
            />
          </Grid>
          <Grid item={true} xs={6}>
            <Field
              name="type"
              component={SelectInput}
              options={[
                { label: 'Company', value: 'COMPANY' },
                { label: 'Art Consultant', value: 'ART CONSULTANT' },
                { label: 'Art Dealer', value: 'ART DEALER' },
                { label: 'Art Fair', value: 'ART FAIR' },
                { label: 'Auction House', value: 'AUCTION HOUSE' },
                { label: 'Collector', value: 'COLLECTOR' },
                { label: 'Art Lover', value: 'ART LOVER' },
                { label: 'Institution', value: 'INSTITUTION' },
                { label: 'Journalist', value: 'JOURNALIST' },
                { label: 'Young Curator', value: 'YOUNG CURATOR' },
                { label: 'Gallery', value: 'GALLERY' },
                { label: 'Artist', value: 'ARTIST' },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container={true} justifyContent="flex-end" xs={12}>
          <Button
            variant="outlined"
            size="large"
            color="secondary"
            aria-label="Add"
            className={classes.buttonSmall}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="large"
            type="submit"
            color="secondary"
            aria-label="Add"
            className={classes.buttonSmall}
          >
            Change
          </Button>
        </Grid>
      </form>
    </>
  );

  let userName: string = '';
  let profile: string = 'curator';
  if (user) {
    userName = `${user.firstName ? user.firstName : ''} ${
      user.lastName ? user.lastName : ''
    }`;
    profile = user.profile === 'CURATOR' ? 'curator' : 'artist';
  }

  const handleOnClick = (
    values: IInitialValues,
    actions: FormikHelpers<IInitialValues>
  ) => onChange(values.type);

  return (
    <Dialog open={isOpen} onClose={onClose} BackdropComponent={Backdrop}>
      <MuiDialogTitle>
        <Typography variant="h6">Change Profile</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent>
        {profile === 'curator' && <p>Change {userName}'s profile to:</p>}
        {profile === 'artist' && <p>Change {userName} artist's profile to:</p>}
        <Formik
          initialValues={initialValues}
          onSubmit={handleOnClick}
          render={renderForm}
        />
      </DialogContent>
      <DialogActions />

      {/* </DialogActions> */}
    </Dialog>
  );
};

export default compose<props, IModalChangeUserTypeProps>(
  withStyles(style),
  withRouter
)(ModalChangeUserType);
