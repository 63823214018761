import style from './read-more-card.module.scss';
import Stack from '@mui/material/Stack';
import { PixeledArrowOpenIcon } from '@acg/assets';
import React from 'react';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router';

export const ReadMoreCard = ({
  bgColor,
  text,
  link,
  hideArrow,
  hoverColor,
  children,
}: {
  bgColor: string;
  text: string;
  link: { url: string; external?: boolean };
  hideArrow?: boolean;
  hoverColor?: boolean;
  children?: React.ReactNode;
}) => {
  const history = useHistory();

  const handleClick = () => {
    if (link.external) {
      window.open(link.url);
    } else {
      window.scrollTo(0, 0);
      history.push(link.url);
    }
  };

  return (
    <Grid
      container
      className={`${style.container} ${hoverColor && style.hovered}`}
      style={{
        backgroundColor: bgColor,
      }}
      onClick={handleClick}
      justifyContent={'center'}
    >
      <Grid item xs={12}>
        <h3 style={{ color: 'inherit' }}>
          <b>{text}</b>
        </h3>
      </Grid>
      {!!children && children}
      {!hideArrow && (
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Stack
            direction={'row'}
            spacing={2}
            className={style.link}
            alignItems={'flex-end'}
          >
            <p>Read more</p>
            <PixeledArrowOpenIcon />
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};
