import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import * as _ from 'lodash';
import {} from '@mui/material';
import { compose } from 'recompose';
// import { SPACING } from '../../styles/constants';
import Heart from '../icons/Heart';
import SubTitle from '../../library/dataDisplay/typography/SubTitle';
import { IPost } from '../../types/posts';
import CategoryButton from '../buttons/CategoryButton';
import logo from '../../img/logo.svg';
import { DARK_GREY_COLOR, PRIMARY_COLOR } from '../../styles/constants';
import IconLike from '../../library/dataDisplay/icons/IconLike';
import IconViews from '../../library/dataDisplay/icons/IconViews';
import Grid from '@mui/material/Grid';

export interface IContentContainer2Props {
  footer: React.ReactNode;
  featured: React.ReactNode;
  onClick: () => void;
  onCommentClick?: () => void;
  onLike: () => void;
  onShare?: () => void;
  liked: boolean;
  numberOfLike: number;
  post: IPost;
  nonClickable?: boolean;
  categorySubType: string;
  onCategoryButtonClick: (selectedCategory: string) => void;
}

const styles = createStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  },
  featured: {
    overflow: 'hidden',
    order: 1,
    flex: 2,
    alignSelf: 'flex-start',
    position: 'relative',
    width: '100%',
    backgroundColor: 'black',
    textAlign: 'center',
    cursor: 'pointer',
  },
  featuredInner: {
    width: '100%',
    height: '100%',
  },
  overlay: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    backgroundColor: 'rgba(246, 246, 246, 0.5)',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      opacity: 1,
    },
  },
  logo: {
    width: 50,
  },
  footer: {
    display: 'inline-block',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    order: 2,
    alignSelf: 'flex-end',
  },
  draft: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    backgroundColor: PRIMARY_COLOR,
    padding: '8px 8px',
  },
  footerInner: {
    // padding: `${SPACING}px 0 0 0`,
    display: 'inline-block',
    width: '100%',
  },

  like: {
    float: 'left',
    textAlign: 'center',
    marginRight: 10,
    fontFamily: 'Replica-Regular',
    fontSize: '10px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.2',
    letterSpacing: 'normal',
    color: '#2b2e34',
  },
  actionButton: {
    display: 'inline-block',
    cursor: 'pointer',
    fontSize: 12,
  },
  liking: {
    // backgroundColor: convertHex(PRIMARY_COLOR, 80),
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  iconSize: {
    fontSize: 12,
  },
});

type Props = IContentContainer2Props & WithStyles<string>;

interface IState {
  liking: boolean;
}

class ContentContainer2 extends React.Component<Props, IState> {
  private ref: React.RefObject<HTMLDivElement>;
  private delayedClick?: NodeJS.Timeout;
  private doubleClick: boolean = false;

  constructor(props: Props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      liking: false,
    };
  }

  public onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!this.delayedClick) {
      this.delayedClick = setTimeout(this.handleClick, 200);
    } else {
      this.doubleClick = true;
    }
  };

  public handleClick = () => {
    const { onClick, onLike } = this.props;
    if (this.doubleClick) {
      onLike();
      this.setState({ liking: true });
      setTimeout(() => {
        this.setState({ liking: false });
      }, 1000);
    } else {
      this.setState({ liking: false });
      onClick();
    }
    if (this.ref.current) {
      this.ref.current.focus();
    }

    this.doubleClick = false;
    this.delayedClick = undefined;
  };

  public render() {
    const {
      classes,
      featured,
      footer,
      onClick,
      post,
      onLike,
      liked,
      numberOfLike,
      categorySubType,
      onCategoryButtonClick,
      // onCommentClick,
      // onShare,
    } = this.props;

    const { liking } = this.state;

    const likingRender = (
      <div className={classes.liking}>
        <Heart big={true} />
      </div>
    );

    return (
      <div className={classes.container}>
        <div
          className={classes.featured}
          style={this.props.nonClickable ? { cursor: 'default' } : {}}
        >
          <div onClick={this.onClick} className={classes.featuredInner}>
            {featured}
            {liking ? likingRender : undefined}
          </div>
          {!this.props.nonClickable && (
            <div className={classes.overlay} onClick={this.onClick}>
              <img className={classes.logo} src={logo} />
            </div>
          )}
          <CategoryButton
            subType={categorySubType}
            onCategoryClick={onCategoryButtonClick}
          />
        </div>

        <div className={classes.footer}>
          <div className={classes.footerInner}>
            {post.status === 'DRAFT' ? (
              <div className={classes.draft}>DRAFT</div>
            ) : post.visibility === 'PRIVATE' ? (
              <div className={classes.draft}>PRIVATE</div>
            ) : (
              <Grid
                container={true}
                justifyContent={'flex-end'}
                spacing={1}
                style={{ marginTop: 10 }}
              >
                <Grid
                  item={true}
                  onClick={onLike}
                  ref={this.ref}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <IconLike height={17} liked={liked} fill={DARK_GREY_COLOR} />
                  <SubTitle>{numberOfLike}</SubTitle>
                </Grid>
                <Grid
                  item={true}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <IconViews height={17} />
                  <SubTitle>{post.number_of_views}</SubTitle>
                </Grid>
              </Grid>
            )}

            <div className={classes.content} onClick={onClick}>
              {footer}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose<Props, IContentContainer2Props>(withStyles(styles))(
  ContentContainer2
);
