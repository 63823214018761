import { idParams } from './util';
import { createRouteRequest } from 'typesafe-api';
import {
  GetIsPurchasableEndpointDef,
  getIsPurchasableRoute,
  GetNftEndpointDef,
  getNftRoute,
  GetNftsEndpointDef,
  getNftsRoute,
  JsonNftWithRawFile,
  PatchNftEndpointDef,
  patchNftRoute,
  PostNftEndpointDef,
  postNftRoute,
  setNftFileEndpointDef,
  setNftFileRoute,
  UploadSignedUrlReq,
} from '@acg/artpool-api-spec';
import { INftFile } from '@acg/api-interfaces';
import { AuthApiClient } from '@acg/shared/api-spec-utils';
import { CreateNftData } from '@acg/shared/interfaces';

export interface PatchNftParams {
  nftId: string;
  nft: Partial<JsonNftWithRawFile>;
}

export interface PostNftParams {
  projectId: string;
  nft: CreateNftData;
}

export interface SetNftFileParams {
  nftId: string;
  nftFile: INftFile;
}

export class NftClient extends AuthApiClient {
  /**
   * get NFTs
   */
  private _getNfts = createRouteRequest<GetNftsEndpointDef>(this, getNftsRoute);

  public getNfts = () => this._getNfts({});
  public getNftsForLiveProjects = () =>
    this._getNfts({ query: { live: true } });

  /**
   * get NFT
   */
  private _getNft = createRouteRequest<GetNftEndpointDef>(this, getNftRoute);
  public getNft = (nftId: string) => this._getNft(idParams(nftId));

  /**
   * edit NFT
   */
  private _patchNft = createRouteRequest<PatchNftEndpointDef>(
    this,
    patchNftRoute
  );
  public patchNft = (params: PatchNftParams) =>
    this._patchNft({
      ...idParams(params.nftId),
      body: params.nft,
    });

  /**
   * Post NFT
   */
  private _postNft = createRouteRequest<PostNftEndpointDef>(this, postNftRoute);
  public postNft = (params: PostNftParams) =>
    this._postNft({
      params: { projectId: params.projectId },
      body: params.nft,
    });

  /**
   * set NFT file
   */
  private _setNftFile = createRouteRequest<setNftFileEndpointDef>(
    this,
    setNftFileRoute
  );
  public setNftFile = (params: SetNftFileParams) =>
    this._setNftFile({
      ...idParams(params.nftId),
      body: params.nftFile,
    });

  /**
   * is NFT purchasable
   */
  private _isPurchasable = createRouteRequest<GetIsPurchasableEndpointDef>(
    this,
    getIsPurchasableRoute
  );
  public isPurchasable = (nftId: string) =>
    this._isPurchasable(idParams(nftId));
}
