import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { SelectProps } from '@mui/material/Select';
import { InputLabelProps } from '@mui/material/InputLabel';
import { FieldProps } from 'formik';
import { compose } from 'recompose';
import IconLockClosed from '../../library/dataDisplay/icons/iconLockClosed';
import InfoTooltip from '../../library/dataDisplay/tooltips/InfoTooltip';

export type RadioInputOptions = Array<{
  label: string;
  value: string;
  helper?: string;
  tooltip?: string;
}>;

export interface IRadioInputProps extends FieldProps {
  selectProps: SelectProps;
  inputLabelProps: InputLabelProps;
  options: RadioInputOptions;
}

type Props = WithStyles<string> & IRadioInputProps;

const style = createStyles({
  root: {},
  /*
  input: {
    backgroundImage: 'linear-gradient(to right, #f5f5f5, #f6f6f6 10%, #f7f7f7 20%, #f8f8f8 30%, #f9f9f9 40%, #fafafa 50%, #fbfbfb 60%, #fcfcfc 70%, #fdfdfd 80%, #fefefe 90%, #ffffff)',
  },*/
});

class RadioInput extends React.Component<Props, any> {
  public radioLabel(optionValue: any, classes: any) {
    if (optionValue.helper) {
      return (
        <>
          <div style={{ paddingTop: 15, lineHeight: 1 }}>
            {optionValue.label}
          </div>
          {!optionValue.helperAdd && (
            <div className={classes.radioHelper}>{optionValue.helper}</div>
          )}
          {optionValue.helperAdd && (
            <>
              <div className={classes.radioHelperAdd}>{optionValue.helper}</div>
              <div className={classes.radioHelper}>{optionValue.helperAdd}</div>
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {optionValue.label}
            {optionValue.tooltip && (
              <div style={{ paddingLeft: 15 }}>
                <InfoTooltip helpText={optionValue.tooltip} />
              </div>
            )}
            {optionValue.icon && <IconLockClosed />}
          </div>
        </>
      );
    }
  }

  public render() {
    const { form, field, options, classes } = this.props;

    return (
      <FormControl
        style={{ marginTop: 16, marginBottom: 8 }}
        variant="filled"
        fullWidth={true}
      >
        <RadioGroup
          classes={classes}
          id={field.name}
          name={field.name}
          value={form.values[field.name]}
          onChange={form.handleChange}
        >
          {options.map((op, index) => {
            return (
              <FormControlLabel
                key={index}
                value={op.value}
                control={<Radio color="primary" />}
                label={this.radioLabel(op, classes)}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  }
}

export default compose<Props, IRadioInputProps>(withStyles(style))(RadioInput);
