import * as React from 'react';

export interface IIconFilterProps {
  fill?: string;
  height?: number;
  style?: any;
}

const IconFilter = (props: IIconFilterProps) => {
  const style: React.CSSProperties = {
    height: props.height || 24,
    backgroundColor:
      (props.style && props.style.backgroundColor) || 'transparent',
    borderRadius: (props.style && props.style.borderRadius) || 6,
  };

  return (
    <svg
      fill={props.fill || 'black'}
      style={style}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
    >
      <path d="M419.71,830.83a76.81,76.81,0,0,1-.68,10.06l.43-3.19a74.87,74.87,0,0,1-5.15,18.81l1.21-2.87a75.54,75.54,0,0,1-9.62,16.45l1.87-2.43a75.76,75.76,0,0,1-13.39,13.39l2.43-1.87a75.54,75.54,0,0,1-16.45,9.62l2.87-1.21a74.87,74.87,0,0,1-18.81,5.15l3.19-.43a76.14,76.14,0,0,1-20.12,0l3.19.43a74.79,74.79,0,0,1-18.8-5.15l2.86,1.21a75.54,75.54,0,0,1-16.45-9.62l2.43,1.87a75.76,75.76,0,0,1-13.39-13.39l1.88,2.43a75.58,75.58,0,0,1-9.63-16.45l1.21,2.87a74.87,74.87,0,0,1-5.15-18.81l.43,3.19a76.14,76.14,0,0,1,0-20.12l-.43,3.19a74.68,74.68,0,0,1,5.15-18.8L299.58,808a75.58,75.58,0,0,1,9.63-16.45L307.33,794a75.76,75.76,0,0,1,13.39-13.39l-2.43,1.88a75.18,75.18,0,0,1,16.45-9.63l-2.86,1.21a74.79,74.79,0,0,1,18.8-5.15l-3.19.43a76.14,76.14,0,0,1,20.12,0l-3.19-.43a74.87,74.87,0,0,1,18.81,5.15l-2.87-1.21a75.18,75.18,0,0,1,16.45,9.63l-2.43-1.88A75.76,75.76,0,0,1,407.77,794l-1.87-2.43A75.54,75.54,0,0,1,415.52,808l-1.21-2.86a74.68,74.68,0,0,1,5.15,18.8l-.43-3.19a76.92,76.92,0,0,1,.68,10.06,12.43,12.43,0,0,0,3.51,8.49,12.24,12.24,0,0,0,8.49,3.51c6.49-.29,12-5.27,12-12a85.55,85.55,0,0,0-15.43-49.14,95.15,95.15,0,0,0-8.89-10.85,91.82,91.82,0,0,0-10.6-9.18,85.45,85.45,0,0,0-48.43-16.94,85.73,85.73,0,0,0-68.57,30.52,82.06,82.06,0,0,0-14.4,24.15,84.15,84.15,0,0,0-5.76,25.5,85.61,85.61,0,0,0,12.12,50.42c8.75,14.42,21.93,26.61,37.36,33.55a81.18,81.18,0,0,0,27.74,7.62,85.71,85.71,0,0,0,70.77-25.9c11.76-12.28,20.18-28.27,22.79-45.14a96.74,96.74,0,0,0,1.3-14.61,12.35,12.35,0,0,0-3.51-8.48,12.2,12.2,0,0,0-8.49-3.52C425.2,819.12,419.73,824.1,419.71,830.83Z" />
      <path d="M785.64,540.57a76.8,76.8,0,0,1-.67,10.06l.42-3.19a74.87,74.87,0,0,1-5.15,18.81l1.21-2.87a75.54,75.54,0,0,1-9.62,16.45l1.88-2.43a76.1,76.1,0,0,1-13.39,13.39l2.42-1.88a74.72,74.72,0,0,1-16.45,9.63l2.87-1.21a74.79,74.79,0,0,1-18.8,5.15l3.19-.43a76.22,76.22,0,0,1-20.13,0l3.19.43a74.79,74.79,0,0,1-18.8-5.15l2.87,1.21a74.72,74.72,0,0,1-16.45-9.63l2.42,1.88a76.1,76.1,0,0,1-13.39-13.39l1.88,2.43a75.54,75.54,0,0,1-9.62-16.45l1.21,2.87a74.87,74.87,0,0,1-5.15-18.81l.42,3.19a76.14,76.14,0,0,1,0-20.12l-.42,3.19a74.87,74.87,0,0,1,5.15-18.81l-1.21,2.87a75.54,75.54,0,0,1,9.62-16.45l-1.88,2.43a76.1,76.1,0,0,1,13.39-13.39l-2.42,1.88a74.72,74.72,0,0,1,16.45-9.63l-2.87,1.21a74.79,74.79,0,0,1,18.8-5.15l-3.19.43a76.22,76.22,0,0,1,20.13,0l-3.19-.43a74.79,74.79,0,0,1,18.8,5.15l-2.87-1.21a74.72,74.72,0,0,1,16.45,9.63l-2.42-1.88a76.1,76.1,0,0,1,13.39,13.39l-1.88-2.43a75.54,75.54,0,0,1,9.62,16.45l-1.21-2.87a74.87,74.87,0,0,1,5.15,18.81l-.42-3.19a76.91,76.91,0,0,1,.67,10.06,12.44,12.44,0,0,0,3.52,8.49,12.2,12.2,0,0,0,8.48,3.51c6.49-.29,12-5.27,12-12a85.61,85.61,0,0,0-15.42-49.14,97.32,97.32,0,0,0-8.89-10.85,93.53,93.53,0,0,0-10.6-9.18,85.52,85.52,0,0,0-48.43-16.94A85.73,85.73,0,0,0,657.72,485a94.92,94.92,0,0,0-8.21,11.4,93.66,93.66,0,0,0-6.18,12.75,83.87,83.87,0,0,0-5.77,25.5,85.61,85.61,0,0,0,12.12,50.42c8.75,14.42,21.93,26.61,37.36,33.55a81.32,81.32,0,0,0,27.75,7.62,85.7,85.7,0,0,0,70.76-25.9c11.76-12.28,20.18-28.27,22.79-45.14a96.74,96.74,0,0,0,1.3-14.61,12.35,12.35,0,0,0-3.51-8.48,12.19,12.19,0,0,0-8.49-3.52C791.13,528.86,785.66,533.84,785.64,540.57Z" />
      <path d="M419.71,248a77.08,77.08,0,0,1-.68,10.07l.43-3.19a74.91,74.91,0,0,1-5.15,18.8l1.21-2.87a75.31,75.31,0,0,1-9.62,16.45l1.87-2.43a75.43,75.43,0,0,1-13.39,13.39l2.43-1.87A75.54,75.54,0,0,1,380.36,306l2.87-1.21a74.87,74.87,0,0,1-18.81,5.15l3.19-.43a76.14,76.14,0,0,1-20.12,0l3.19.43a74.79,74.79,0,0,1-18.8-5.15l2.86,1.21a75.54,75.54,0,0,1-16.45-9.62l2.43,1.87a75.43,75.43,0,0,1-13.39-13.39l1.88,2.43a75.35,75.35,0,0,1-9.63-16.45l1.21,2.87a74.91,74.91,0,0,1-5.15-18.8l.43,3.19a76.22,76.22,0,0,1,0-20.13l-.43,3.19a74.79,74.79,0,0,1,5.15-18.8l-1.21,2.86a75.58,75.58,0,0,1,9.63-16.45l-1.88,2.43a76.1,76.1,0,0,1,13.39-13.39l-2.43,1.88A75.58,75.58,0,0,1,334.74,190l-2.86,1.21a75.22,75.22,0,0,1,18.8-5.15l-3.19.43a76.14,76.14,0,0,1,20.12,0l-3.19-.43a75.29,75.29,0,0,1,18.81,5.15L380.36,190a75.58,75.58,0,0,1,16.45,9.63l-2.43-1.88a76.1,76.1,0,0,1,13.39,13.39l-1.87-2.43a75.54,75.54,0,0,1,9.62,16.45l-1.21-2.86a74.79,74.79,0,0,1,5.15,18.8l-.43-3.19a77,77,0,0,1,.68,10.06,12.42,12.42,0,0,0,3.51,8.49,12.19,12.19,0,0,0,8.49,3.51c6.49-.29,12-5.27,12-12a85.52,85.52,0,0,0-15.43-49.13A95.25,95.25,0,0,0,419.39,188a91.74,91.74,0,0,0-10.6-9.17,85.45,85.45,0,0,0-48.43-16.94,85.66,85.66,0,0,0-68.57,30.51,82.06,82.06,0,0,0-14.4,24.15,84.29,84.29,0,0,0-5.76,25.5,85.61,85.61,0,0,0,12.12,50.42c8.75,14.42,21.93,26.61,37.36,33.55a81.49,81.49,0,0,0,27.74,7.63,85.8,85.8,0,0,0,70.77-25.9c11.76-12.29,20.18-28.27,22.79-45.15a96.62,96.62,0,0,0,1.3-14.61,12,12,0,0,0-12-12C425.2,236.29,419.73,241.27,419.71,248Z" />
      <path d="M99.44,260H217.5c5.43,0,10.88.07,16.31,0h.24a12.41,12.41,0,0,0,8.48-3.51,12.23,12.23,0,0,0,3.52-8.49c-.3-6.5-5.28-12-12-12H116c-5.44,0-10.88-.06-16.31,0h-.24A12.36,12.36,0,0,0,91,239.51,12.21,12.21,0,0,0,87.44,248c.29,6.5,5.28,12,12,12Z" />
      <path d="M478.59,260H960.07c6.51,0,13,.08,19.52,0h.85c6.28,0,12.29-5.52,12-12s-5.27-12-12-12H499c-6.5,0-13-.07-19.52,0h-.85c-6.28,0-12.29,5.52-12,12s5.27,12,12,12Z" />
      <path d="M99.44,844H217.5c5.43,0,10.88.07,16.31,0h.24a12.38,12.38,0,0,0,8.48-3.52,12.21,12.21,0,0,0,3.52-8.48c-.3-6.5-5.28-12-12-12H116c-5.44,0-10.88-.07-16.31,0h-.24A12.39,12.39,0,0,0,91,823.5,12.25,12.25,0,0,0,87.44,832c.29,6.5,5.28,12,12,12Z" />
      <path d="M478.9,844H960c6.5,0,13,.08,19.51,0h.85c6.28,0,12.29-5.52,12-12s-5.27-12-12-12H499.26c-6.5,0-13-.08-19.51,0h-.85c-6.28,0-12.29,5.52-12,12s5.27,12,12,12Z" />
      <path d="M980.38,528h-118c-5.44,0-10.87-.07-16.31,0h-.23a12.34,12.34,0,0,0-8.48,3.52,12.21,12.21,0,0,0-3.52,8.48c.29,6.5,5.27,12,12,12h118c5.44,0,10.88.07,16.31,0h.23a12.37,12.37,0,0,0,8.48-3.51,12.19,12.19,0,0,0,3.52-8.49c-.29-6.5-5.27-12-12-12Z" />
      <path d="M601.52,528H119.8c-6.5,0-13-.07-19.5,0h-.86c-6.27,0-12.29,5.52-12,12s5.28,12,12,12H581.16c6.5,0,13,.08,19.51,0h.85c6.28,0,12.29-5.52,12-12s-5.27-12-12-12Z" />
    </svg>
  );
};

export default IconFilter;
