import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { Grid, Tabs, Tab } from '@mui/material';
import ProfileLarge from '../../library/dataDisplay/profile/ProfileLarge';
import { IUser } from '../../types/user';
import PaddedLayout from '../../library/layout/container/PaddedLayout';
import Posts from './VisitorPosts';
import Loading from '../../components/loading/Loading';
import VisitorService, { IPostTotals } from '../../services/VisitorServices';
import { generateMetaForUser } from '../../components/meta';
import SocialModal from '../social/SocialModal';
import {} from '@mui/material/';
import { PRIMARY_COLOR, SPACING } from '../../styles/constants';
import { PostTypeOptions } from '../../types/posts';
import Follow from '../users/Follow';
import { sumPostTotals } from '../../utils/post-totals-utils';
import { Redirect } from 'react-router-dom';

export interface IVisitorUserProfileProps {
  onClickOnAction: () => void;
}

type Props = IVisitorUserProfileProps &
  WithWidth &
  RouteComponentProps<{ userId: string; userURL: string }> &
  WithStyles<string>;

interface IState {
  user?: IUser;
  loading: boolean;
  dialogOpen: boolean;
  following: string[];
  isPrivateUser: boolean;
  selectedTab: string;
  postTotals?: {
    exhibition: number;
    art_project: number;
    publication: number;
    studio_visit: number;
    interview: number;
    online_exhibition: number;
    misc: number;
  };
}

const style = createStyles({
  root: {
    flexGrow: 1,
  },
  tabRoot: {
    textTransform: 'none',
  },
  backdrop: {
    backgroundColor: 'rgba(242,242,242,0.92)',
  },
  button: {
    padding: '25px 40px',
  },

  signupSection: {
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    paddingTop: 200,
    paddingBottom: 200,
    backgroundColor: '#fafbfb',
  },

  signupSectionMobile: {
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    paddingTop: 100,
    paddingBottom: 100,
    backgroundColor: '#fafbfb',
  },
  signupContainer: {
    flexGrow: 0,
    maxWidth: '50%',
    height: '100%',
    flexBasis: '50%',
  },
  signupContainerMobile: {
    flexGrow: 0,
    height: '100%',
    flexBasis: '90%',
  },
  tabs: {
    fontSize: '0.715rem',
    letterSpacing: -0.12,
    textAlign: 'right',
    minWidth: 120,
    textColor: '#000',
    '& button': {
      minWidth: 120,
      fontSize: 16,
    },
  },
  indicator: {
    backgroundColor: PRIMARY_COLOR,
  },
  scrollButton: {
    width: 10,
  },
  tabsContainer: {
    marginTop: `-${SPACING}px`,
    paddingRight: `${SPACING}px`,
    paddingLeft: `${SPACING}px`,
  },

  postsContainer: {
    marginTop: `30px`,
  },
});

class VisitorUserProfile extends React.Component<Props, IState> {
  private visitorService = new VisitorService();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      following: [],
      dialogOpen: false,
      isPrivateUser: false,
      selectedTab: 'ALL',
    };
  }

  public componentDidMount() {
    const {
      match: {
        params: { userId },
      },
    } = this.props;
    this.getUser(userId);
  }

  public componentDidUpdate(prevProps: Props) {
    const {
      match: {
        params: { userId },
      },
    } = this.props;

    const {
      match: {
        params: { userId: prevUserId },
      },
    } = prevProps;

    if (userId !== prevUserId) {
      this.getUser(userId);
    }
  }

  public getUser = (userId: string) => {
    this.setState({ loading: true });
    return Promise.all([
      this.visitorService.getPublicUser(userId),
      this.visitorService.getPublicUserTotalPosts(userId),
    ])
      .then(([userData, postsTotals]) => {
        if (userData.data.profile === 'VIEWER') {
          this.props.history.push(`/users/${userId}/followers`);
        }
        this.setState({
          loading: false,
          user: userData.data,
          postTotals: postsTotals.data || null,
        });
      })
      .catch((err) => {
        window.console.log('err ', err);
        if (err.error_message === 'private user') {
          this.setState({ isPrivateUser: true });
        }
        this.setState({ loading: false });
      });
  };

  public render() {
    const { classes, width } = this.props;

    const { user, loading, isPrivateUser, selectedTab, postTotals } =
      this.state;

    if (loading) {
      return <Loading />;
    }

    const onTagClick = () => this.props.onClickOnAction();
    const onShare = () => this.setState({ dialogOpen: true });
    const onClose = () => this.setState({ dialogOpen: false });

    const onTabClick = (e: any, val: string) => {
      this.setState({ selectedTab: val });
    };

    const isMobile = isWidthDown('xs', width);
    const isPad = isWidthDown('sm', width);

    if (isPrivateUser) {
      return <Redirect to="/login" />;
    }

    return (
      <PaddedLayout>
        <Grid container={true} className={classes.root}>
          {user && (
            <ProfileLarge
              user={user}
              showBio={true}
              onTagClick={onTagClick}
              onShareClick={onShare}
              additionalButton={
                <Follow
                  buttonSize={isPad ? 'small' : 'large'}
                  user={user!}
                  isFollowing={false}
                />
              }
            />
          )}
          {user && generateMetaForUser(user)}

          <Grid item={true} xs={12} className={classes.tabsContainer}>
            <Tabs
              value={selectedTab}
              onChange={onTabClick}
              centered={false}
              classes={{
                root: classes.tabs,
                indicator: classes.indicator,
                scrollButtons: classes.scrollButton,
              }}
              scrollButtons={true}
              variant="scrollable"
            >
              {postTotals && (
                <Tab
                  label={'(' + sumPostTotals(postTotals) + ') All'}
                  value={'ALL'}
                  key={'ALL'}
                  classes={{
                    root: classes.tabRoot,
                  }}
                />
              )}
              {PostTypeOptions.map((type) => {
                let tabLabel = type.tabLabel;

                if (postTotals && postTotals.exhibition >= 0) {
                  switch (type.id) {
                    case 'EXHIBITION':
                      tabLabel =
                        '(' + postTotals.exhibition + ') ' + type.tabLabel;
                      break;
                    case 'ARTPROJECT':
                      tabLabel =
                        '(' + postTotals.art_project + ') ' + type.tabLabel;
                      break;
                    case 'PUBLICATION':
                      tabLabel =
                        '(' + postTotals.publication + ') ' + type.tabLabel;
                      break;
                    case 'STUDIOVISIT':
                      tabLabel =
                        '(' + postTotals.studio_visit + ') ' + type.tabLabel;
                      break;
                    case 'INTERVIEW':
                      tabLabel =
                        '(' + postTotals.interview + ') ' + type.tabLabel;
                      break;
                    case 'ONLINEEXHIBITION':
                      tabLabel =
                        '(' +
                        postTotals.online_exhibition +
                        ') ' +
                        type.tabLabel;
                      break;
                    default:
                      tabLabel = '(' + postTotals.misc + ') ' + type.tabLabel;
                      break;
                  }
                }

                return (
                  <Tab
                    label={tabLabel}
                    value={type.id}
                    key={type.id}
                    classes={{
                      root: classes.tabRoot,
                    }}
                  />
                );
              })}
            </Tabs>
            <div className={classes.postsContainer}>
              <Posts
                noProfile={true}
                selectedTab={selectedTab}
                noTopMargin={true}
                onClickOnAction={this.props.onClickOnAction}
              />
            </div>
          </Grid>

          {user && (
            <SocialModal
              open={this.state.dialogOpen}
              onClose={onClose}
              object={{
                type: 'USER',
                object: user,
              }}
            />
          )}
        </Grid>
      </PaddedLayout>
    );
  }
}

export default compose<Props, IVisitorUserProfileProps>(
  withRouter,
  withWidth(),
  withStyles(style)
)(VisitorUserProfile);
