import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import UserService from '../../services/UserService';
import { IUser } from '../../types/user';
import { withRouter, RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { Button, Grid } from '@mui/material';
import ProfileLarge from '../../library/dataDisplay/profile/ProfileLarge';
import Follow from './Follow';
import { withAccount, IWithAccountProps } from '../account/InjectAccount';
import Loading from '../../components/loading/Loading';
import UserFilter from '../../components/filters/UserFilter';
import { IError } from '../../types/returns';
import SubTitle from '../../library/dataDisplay/typography/SubTitle';
import SearchService from '../../services/SearchService';
import { deserializeUrl } from '../../utils/router';
import ConnectButton from '../services/ConnectButton';
import { checkRight } from '../../utils/userUtils';
import { Footer } from '@acg/frontend-ui-artpool';
import { ACGAppPaths } from '@acg/shared/const';

export interface ICuratorListingProps {}

type Props = ICuratorListingProps &
  RouteComponentProps<{}> &
  IWithAccountProps &
  WithStyles<string> &
  WithWidth;

const PAGE_SIZE = 10;

const style = createStyles({
  main: {
    minHeight: 'calc(100vh - 278px)',
  },
  bottomContent: {
    textAlign: 'center',
    padding: '20px 0',
  },

  noData: {
    width: '100%',
    textAlign: 'center',
    padding: '20px 0',
  },
});

class CuratorListing extends React.Component<Props, any> {
  private userService = new UserService();
  private searchService = new SearchService();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      following: [],
      data: [],
      currentPage: 0,
      hasMoreData: false,
      hasLoadedOnce: false,
      search: false,
      tabSelected: 'ALL',
    };
  }

  public componentDidMount() {
    const {
      location: { search },
    } = this.props;

    const filter = deserializeUrl(search);

    if (filter) {
      this.setState({ search: filter });
      this.searchData(0, filter, this.state.tabSelected, true);
    } else {
      this.setState({ search: null });
      this.fetchData(0, this.state.tabSelected, true);
    }

    this.fetchFollowing();

    window.addEventListener('scroll', this.handleScroll);
  }

  public componentDidUpdate(prevProps: Props) {
    const {
      location: { search },
    } = this.props;

    const {
      location: { search: prevState },
    } = prevProps;

    const filter = deserializeUrl(search);

    if (search !== prevState) {
      if (filter) {
        this.setState({ search: filter });
        this.searchData(0, filter, this.state.tabSelected, true);
      } else {
        this.setState({ search: null });
        this.fetchData(0, this.state.tabSelected, true);
      }
      this.fetchFollowing();
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  public fetchFollowing = async () => {
    const { account } = this.props;
    this.setState({ loading: true });
    try {
      const followings = await this.userService.getUserFollowings(account.id);
      const simplifiedFollowing = followings.data.map((d) => {
        return d.following ? d.following.id : '';
      });
      this.setState({ loading: false, following: simplifiedFollowing });
    } catch (e) {
      this.setState({ loading: false, error: true });
    }
  };

  public handleScroll = () => {
    // This will avoid of call the Load more again if it's still loading

    if (!this.state.loading) {
      const windowHeight =
        'innerHeight' in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      const windowBottom = windowHeight + window.pageYOffset;
      if (windowBottom >= docHeight) {
        // const {query} = this.props;
        const { currentPage, hasMoreData, search, tabSelected } = this.state;
        if (hasMoreData) {
          this.setState(
            {
              message: 'bottom reached',
              loading: true,
            },
            () => {
              this.state.search
                ? this.searchData(currentPage + 1, search, tabSelected)
                : this.fetchData(currentPage + 1, tabSelected);
            }
          );
        }
        // else {
        //     window.removeEventListener("scroll", this.handleScroll);
        // }
      } else {
        this.setState({
          message: 'not at bottom',
        });
      }
    }
  };

  public searchData = (
    pageNumber = 0,
    query: any,
    subType?: string,
    reset?: boolean
  ) => {
    if (reset) {
      this.setState({ data: [] });
    }

    const filters = {
      sub_type: subType,
      query: query.query && query.query,
      location: query.location && query.location,
      expertise: query.expertise && query.expertise,
      first_result: PAGE_SIZE * pageNumber,
      max_results: PAGE_SIZE,
    };

    return this.searchService
      .searchUsers(filters)
      .then((data) => {
        this.setState({
          loading: false,
          hasLoadedOnce: true,
          data: [...this.state.data, ...data.data],
          currentPage: pageNumber,
          hasMoreData:
            data.count === PAGE_SIZE || data.count !== 0 ? true : false,
        });
      })
      .catch((err: IError) => {
        this.setState({
          loading: false,
          error:
            err.status === 'error' ? new Error(err.error_message) : undefined,
        });
      });
  };

  public fetchData = (
    pageNumber: number,
    subType?: string,
    reset?: boolean
  ) => {
    if (reset) {
      this.setState({ data: [] });
    }

    return this.userService
      .getUsers({
        sub_type: subType,
        first_result: pageNumber * PAGE_SIZE,
        max_results: PAGE_SIZE,
        profile: 'CURATOR',
      })
      .then((data) => {
        this.setState({
          loading: false,
          hasLoadedOnce: true,
          data: [...this.state.data, ...data.data],
          currentPage: pageNumber,
          hasMoreData:
            data.count === PAGE_SIZE || data.count !== 0 ? true : false,
        });
      })
      .catch((err: IError) => {
        this.setState({
          loading: false,
          error:
            err.status === 'error' ? new Error(err.error_message) : undefined,
        });
      });
  };

  public onDataChange = (data: any) =>
    this.setState({
      data: this.state.data.map((d: any) => (d.id === data.id ? data : d)),
    });

  public render() {
    const { history, account, classes, width } = this.props;

    const {
      currentPage,
      hasMoreData,
      loading,
      data: users,
      search,
      tabSelected,
      following,
    } = this.state;

    const noDataMessage = 'There is no curator';

    const isPad = isWidthDown('sm', width);
    const isMobile = isWidthDown('xs', width);

    const loadNextPage = () =>
      this.state.search
        ? this.searchData(currentPage + 1, search, tabSelected, false)
        : this.fetchData(currentPage + 1, tabSelected, false);

    const renderSingle = (user: IUser, onDataChange: (user: IUser) => void) => {
      const onSelect = () =>
        history.push(`/users/${user.url}/posts`, { from: '/users' });
      const callback = () => {
        if (this.state.following.indexOf(user.id) > -1) {
          // un-follow user
          this.setState({
            following: following.filter((d: any) => d !== user.id),
          });
          onDataChange({
            ...user,
            followers_number: user.followers_number - 1,
          });
        } else {
          // un-follow user
          this.setState({ following: [...following, user.id] });
          onDataChange({
            ...user,
            followers_number: user.followers_number + 1,
          });
        }
      };

      if (user.id === account.id) {
        user.followings_number = following.length;
      }

      const onTagClick = (id: string) => history.push(ACGAppPaths.ROOT, { tags: [id] });
      const right = checkRight(account);
      const isViewer = right === 'VIEWER' || right === 'PENDING';

      return (
        <React.Fragment>
          <ProfileLarge
            key={user.id}
            onTagClick={onTagClick}
            onSelect={onSelect}
            user={user}
            additionalButton={
              !isViewer &&
              user.id !== account.id && (
                <>
                  <ConnectButton
                    user={user}
                    buttonSize={isPad ? 'small' : 'large'}
                  />
                  <Follow
                    user={user}
                    callback={callback}
                    isFollowing={following.indexOf(user.id) > -1}
                    buttonSize={isPad ? 'small' : 'large'}
                  />
                </>
              )
            }
          />
        </React.Fragment>
      );
    };

    const handleTabChange = (e: any, val: string) => {
      window.addEventListener('scroll', this.handleScroll);
      this.setState(
        {
          tabSelected: val,
        },
        () => {
          this.state.search
            ? this.searchData(0, this.state.search, val, true)
            : this.fetchData(0, val, true);
        }
      );
    };

    return (
      <React.Fragment>
        <div className={classes.main}>
          <UserFilter
            fetchData={this.fetchData}
            searchData={this.searchData}
            handleTabChange={handleTabChange}
            tabSelected={this.state.tabSelected}
          />

          <Grid
            container={true}
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ paddingTop: isPad ? 180 : 140 }}
          >
            {this.state.data.map((user: any) => {
              return renderSingle(user, this.onDataChange);
            })}
            {!loading && users.length === 0 ? (
              <div className={classes.noData}>
                <SubTitle>{noDataMessage}</SubTitle>
              </div>
            ) : undefined}
            {hasMoreData && !loading ? (
              <Grid
                key="showMore"
                item={true}
                className={classes.bottomContent}
                xs={12}
              >
                <Button onClick={loadNextPage}>Load More</Button>
              </Grid>
            ) : null}
            {loading ? (
              <Grid
                key="loading"
                item={true}
                className={classes.bottomContent}
                xs={12}
              >
                <Loading />
              </Grid>
            ) : null}
          </Grid>
        </div>
        {!hasMoreData && !isMobile && <Footer />}
      </React.Fragment>
    );
  }
}

export default compose<Props, ICuratorListingProps>(
  withStyles(style),
  withAccount,
  withRouter,
  withWidth()
)(CuratorListing);
