import { ActionType } from 'typesafe-actions';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import * as actions from './actions';
import BackOfficeServices from '../../../services/BackOfficeServices';

export function* fetchRequest(action: ActionType<typeof actions.fetchRequest>) {
  try {
    const service = new BackOfficeServices();

    const { data } = yield call(service.getPendingUsers);

    yield put(actions.fetchSuccess(data));
  } catch (err: any) {
    actions.fetchError(err.message);
  }
}

export function* fetchArtistRequest(
  action: ActionType<typeof actions.fetchRequest>
) {
  try {
    const service = new BackOfficeServices();

    const { data } = yield call(service.getPendingArtists);

    yield put(actions.fetchArtistSuccess(data));
  } catch (err: any) {
    actions.fetchArtistError(err.message);
  }
}

export function* approveCurator({
  payload,
}: ActionType<typeof actions.approveCurator>) {
  try {
    const service = new BackOfficeServices();

    yield call(service.approveUser, payload.userId, payload.profile);

    if (payload.profile === 'ARTIST') {
      yield put(actions.fetchArtistRequest());
    } else {
      yield put(actions.fetchRequest());
    }
  } catch (err) {
    yield put(actions.fetchError('Error'));
  }
}
export function* changeCuratorProfile({
  payload,
}: ActionType<typeof actions.changeCuratorProfile>) {
  try {
    const service = new BackOfficeServices();

    yield call(service.updateUser, payload.viewer);

    yield put(actions.fetchRequest());
  } catch (err) {
    yield put(actions.fetchError('Error'));
  }
}

export default all([
  takeLatest('@@pendingCurators/FETCH_REQUEST', fetchRequest),
  takeLatest('@@pendingCurators/APPROVE_CURATOR', approveCurator),
  takeLatest('@@pendingCurators/CHANGE_CURATOR_PROFILE', changeCuratorProfile),
  takeLatest('@@pendingCurators/FETCH_ARTIST_REQUEST', fetchArtistRequest),
]);
