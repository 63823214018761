import * as React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';

export interface IPostMobileButtonProps extends ButtonProps {}

export default class PostMobileButton extends React.Component<
  IPostMobileButtonProps,
  any
> {
  public render() {
    return (
      <Button
        {...this.props}
        variant="contained"
        color="secondary"
        style={{ padding: '5px 40px' }}
      >
        Post
      </Button>
    );
  }
}
