import * as React from 'react';
import Caption from '../../../library/dataDisplay/typography/Caption';
import SquaredCardContainer from './squared-card-container';
import { getMediumImageUrl, getSmallmageUrl } from '../../../utils/imageUrl';
import { NEUTRAL } from '../../../styles/constants';
import {
  editionStr,
  formatUserOrVenueData,
} from '../../utilities/shared-utilities';
import { Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@mui/styles';

export interface INftCardProps {
  nft: any;
  title?: string;
  noShadow?: boolean;
  hidePrice?: boolean;
  nonClickable?: boolean;
}

export function NftCard(props: INftCardProps) {
  const { nonClickable } = props;
  const useStyles = makeStyles({
    container: {
      cursor: nonClickable ? 'cursor' : 'pointer',
      width: 240,
      transition: 'all .3s ease-in-out',
      '&:hover': {
        transform: nonClickable ? 'inherit' : 'scale(1.015)',
      },
    },
    image: {
      width: 240,
      height: '100%',
      objectFit: 'cover',
    },
    content: {
      height: 176,
      textAlign: 'left',
      alignItems: 'center',
    },
  });

  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <SquaredCardContainer
        type={'image'}
        noShadow={props.noShadow}
        height={'320px'}
      >
        <img
          src={getMediumImageUrl(props.nft.image.src)}
          className={classes.image}
        />
      </SquaredCardContainer>
      <SquaredCardContainer noShadow={props.noShadow}>
        <Grid container={true} className={classes.content} spacing={1}>
          {props.title && (
            <Grid item={true} xs={12}>
              <Caption>{props.title}</Caption>
            </Grid>
          )}
          <Grid item={true} xs={12}>
            <p
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                margin: 0,
              }}
            >
              {props.nft.title}
            </p>
          </Grid>
          <Grid item={true}>
            <Avatar
              alt={formatUserOrVenueData(props.nft.artist).name}
              src={getSmallmageUrl(props.nft.artist.profilePicture)}
            />
          </Grid>
          <Grid item={true}>
            <p style={{ margin: 0 }}>
              {formatUserOrVenueData(props.nft.artist).name}
            </p>
          </Grid>
          {!props.hidePrice && (
            <>
              <Grid item={true} xs={12}>
                <p style={{ color: NEUTRAL['600'], margin: 0 }}>PRICE</p>
              </Grid>
              <Grid item={true} xs={12}>
                <p style={{ margin: 0 }}>
                  {Number(props.nft.price.value)} {props.nft.price.currency}
                </p>
              </Grid>
            </>
          )}
          {props.nft.edition && (
            <>
              <Grid item={true} xs={12}>
                <Caption>{editionStr(props.nft)}</Caption>
              </Grid>
            </>
          )}
        </Grid>
      </SquaredCardContainer>
    </div>
  );
}

export default NftCard;
