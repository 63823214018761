import {
  IUser,
  IAuthToken,
  IModifyPassword,
  IFollower,
  IServiceRequest,
  IConnectRequest,
} from '../types/user';
import ApiServices, { IDataResponse, IDataListResponse } from './ApiServices';
import { IInviteInstitutionRequest } from '../types/institution';

export interface ISignupRequest {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
}

export interface ILoginRequest {
  email: string;
  password: string;
}

export interface IForgotPasswordRequest {
  email: string;
  host: string;
}

export interface IResetPasswordRequest {
  password: string;
  confirmPassword: string;
}

export interface IUserFilters {
  first_result?: number;
  max_results?: number;
  profile?: 'CURATOR';
  sub_type?: string;
}

class UserService {
  public login = (
    loginRequest: ILoginRequest
  ): Promise<IDataResponse<IAuthToken>> => {
    return ApiServices.postRequest(
      'login',
      loginRequest,
      {},
      {},
      { authenticated: false }
    );
  };

  public logout = (): Promise<IDataResponse<{}>> => {
    return ApiServices.getRequest('logout', {});
  };

  public signup = (
    signupRequest: ISignupRequest
  ): Promise<IDataResponse<IUser>> => {
    return ApiServices.postRequest(
      'signup',
      signupRequest,
      {},
      {},
      { authenticated: false }
    );
  };

  public getAccount = (): Promise<IDataResponse<IUser>> => {
    return ApiServices.getRequest('account');
  };

  public updateProfile = (
    profile: Partial<IUser>
  ): Promise<IDataResponse<any>> => {
    return ApiServices.putRequest('account/profile', profile);
  };

  public updatePassword = (
    payload: IModifyPassword
  ): Promise<IDataResponse<void>> => {
    return ApiServices.putRequest('account/password', payload);
  };

  public forgotPassword = (forgotRequest: IForgotPasswordRequest) => {
    return ApiServices.postRequest(
      'forgot',
      forgotRequest,
      {},
      {},
      { authenticated: false }
    );
  };

  public resetPassword = (
    token: string,
    resetRequest: IResetPasswordRequest
  ) => {
    return ApiServices.postRequest(
      `reset/${token}`,
      resetRequest,
      {},
      {},
      { authenticated: false }
    );
  };

  public getUser = (userId: string): Promise<IDataResponse<IUser>> => {
    return ApiServices.getRequest(`users/${userId}`);
  };

  public getUsers = (
    options: IUserFilters = {}
  ): Promise<IDataListResponse<IUser>> => {
    return ApiServices.getRequest(`users`, options);
  };

  public followUnfollowUser = (
    id: string
  ): Promise<IDataResponse<IFollower | {}>> => {
    return ApiServices.putRequest(`users/${id}/follow`, {});
  };

  public getUserFollowers = (
    id: string,
    options: IUserFilters = {}
  ): Promise<IDataListResponse<IFollower>> => {
    return ApiServices.getRequest(`users/${id}/followers`, options);
  };

  public getUserFollowings = (
    id: string,
    options: IUserFilters = {}
  ): Promise<IDataListResponse<IFollower>> => {
    return ApiServices.getRequest(`users/${id}/followings`, options);
  };

  public serviceRequest = (
    userId: string,
    serviceRequest: IServiceRequest
  ): Promise<IDataResponse<IServiceRequest>> => {
    return ApiServices.postRequest(
      `users/${userId}/service_requests`,
      serviceRequest
    );
  };

  public connectRequest = (
    userId: string,
    connectRequest: IConnectRequest
  ): Promise<IDataResponse<IServiceRequest>> => {
    return ApiServices.postRequest(
      `users/${userId}/connect_requests`,
      connectRequest
    );
  };

  public inviteRequest = (
    id: string,
    inviteRequest: IInviteInstitutionRequest,
    profileInvited: string
  ): Promise<IDataResponse<IInviteInstitutionRequest>> => {
    return ApiServices.postRequest(`users/invite_request`, {
      inviteRequest,
      profileInvited,
    });
  };

  public getUserRef = (query: any): Promise<IDataResponse<any>> => {
    return ApiServices.getRequest(
      'users/search',
      query,
      {},
      { backOffice: true }
    );
  };

  public getUserFollowingsList = (
    id: string,
    options: IUserFilters = {}
  ): Promise<IDataListResponse<IFollower>> => {
    return ApiServices.getRequest(`users/${id}/allfollowings`, options);
  };

  public acceptEthicCode = (): Promise<IDataResponse<{}>> => {
    return ApiServices.postRequest('accept_ethic_code', {});
  };
}

export default UserService;
