import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import * as _ from 'lodash';
import { Field, FieldArrayRenderProps, getIn } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  duration,
  Fade,
  Grid,
} from '@mui/material';
import { compose } from 'recompose';
import {
  DARK_GREY_COLOR,
  GREY_COLOR,
  INFO_POINT_ICON,
} from '../../styles/constants';
import locationIcon from '../../img/icons/location.svg';
import { IExhibitionMap, IExhibitionPoint } from '../../types/exhibitions';
import InfoTooltip from '../../library/dataDisplay/tooltips/InfoTooltip';
import ColorPickerInput from './ColorPickerInput';
import ArtWorkInput from './ArtWorkInput';
import InfoPointInput from './InfoPointInput';
import ArtistInput from './ArtistInput';
import DesignedButton from '../../library/inputs/DesignedButton';
import ACGLink from '../../library/inputs/ACGLink';
import IconUpload from '../../library/dataDisplay/icons/iconUpload';
import CheckboxInput from './CheckboxInput';
import UploadService from '../../services/UploadService';
import {
  getImageUrl,
  getMediumImageUrl,
  getSmallmageUrl,
} from '../../utils/imageUrl';
import { CRS, DivIcon } from 'leaflet';
import { ImageOverlay, Map, Marker, Polyline } from 'react-leaflet';
import ArtWorkPopup from '../popup/ArtWorkPopup';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import FormMessage from './FormMessage';
import ReactPlayer from 'react-player';
import { ACGModal } from '../modal/ACGModal';

export interface IBaseMapProps extends FieldArrayRenderProps {
  isPointEditMode: () => void;
}

const MAP_HEIGHT = '580px';

const style = createStyles({
  resize: {
    fontSize: 12,
  },
  root: {
    position: 'relative',
    '&:hover overlay': {
      backgroundColor: 'black',
      opacity: 1,
    },
  },
  overlay: {
    textAlign: 'center',
    marginLeft: 10,
  },
  mapSettingsBackground: {
    backgroundColor: GREY_COLOR,
    height: 'calc(100% - 20px)',
    padding: 10,
    borderRadius: 8,
  },
  map: {
    height: MAP_HEIGHT,
  },
  mapWithCursor: {
    height: MAP_HEIGHT,
    cursor: `url(${locationIcon}), auto`,
  },
  itemObject: {
    fontFamily: 'Replica-Regular',
    backgroundColor: '#eeeeee',
    borderRadius: '0.25rem',
    marginBottom: '0.2rem',
    marginRight: '0.2rem',
    padding: '0.5rem',
    paddingLeft: '0.25rem',
    opacity: 1,
  },
  artWorkSection: {
    marginTop: 20,
    marginBottom: 20,
  },
  artPointImage: {
    maxHeight: 76,
  },
  centerImage: {
    alignItems: 'center',
  },
  centerInGrid: {
    marginBottom: 'auto',
    marginTop: 'auto',
  },
  modal: {
    '& > *:focus': {
      outline: 'none',
    },
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    width: '80%',
    height: 'calc(100% - 96px)',
    margin: 'auto auto',
    position: 'relative',
    borderRadius: 0,
    overflow: 'auto',
    backgroundColor: 'white',
  },
  mapContainer: {
    border: '1px solid black',
  },
  headerSection: {
    margin: 0,
  },
  sectionSpacing: {
    paddingTop: 50,
  },
  button: {
    backgroundColor: GREY_COLOR,
    border: '1px dashed rgba(43, 46, 52, 0.5)',
    padding: '8px 0',
    boxShadow: 'none',
    textTransform: 'none',
  },
  buttonWithError: {
    color: 'red',
  },
});

type Props = IBaseMapProps & WithStyles<string> & WithWidth;

const BaseMapUpload: React.FC<Props> = (props) => {
  /* Services */
  const uploadService = new UploadService();

  /* State */
  const [baseMapObj, setBaseMapObj] = useState<IExhibitionMap | undefined>(
    undefined
  );
  const [uploading, setUploading] = useState<boolean | undefined>(false);
  const [errorSubmit, setErrorSubmit] = useState<any | undefined>(undefined);

  const [createSelectionMode, setCreateSelectionMode] =
    useState<boolean>(false);
  const [infoSelectionMode, setInfoSelectionMode] = useState<boolean>(false);
  const [nftSelectionMode, setNftSelectionMode] = useState<boolean>(false);

  const [exhibitionPoints, setExhibitionPoints] = useState<
    IExhibitionPoint[] | undefined
  >(undefined);
  const [selectedPoint, setSelectedPoint] = useState<
    IExhibitionPoint | undefined
  >(undefined);
  const [exhibitionPointIndex, setExhibitionPointIndex] = useState<
    number | undefined
  >(undefined);

  const [editMode, setEditMode] = useState<boolean | undefined>(false);
  const [createMode, setCreateMode] = useState<boolean | undefined>(false);

  const [latlangs, setLatlangs] = useState<[] | undefined>(undefined);

  /* Modals */
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false);
  const [toBeRemove, setToBeRemove] = useState<number | undefined>(undefined);
  const [selectedArtist, setSelectedArtist] = useState<any | undefined>(
    undefined
  );
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openArtistModal, setOpenArtistModal] = useState<boolean>(false);

  /* References */
  const gridContainer = useRef<any>();
  const popup = useRef<any>();

  /* Properties */
  const {
    classes,
    form: { values, errors },
    name,
    width,
  } = props;

  const map = values[name];

  const show_artworks_line = values.show_artworks_line;
  const show_artworks_order = values.show_artworks_order;

  const artworks_color = values.artworks_color;
  const artworks_back_color = values.artworks_back_color;
  const artworks_label_color = values.artworks_label_color;
  const artworks_line_color = values.artworks_line_color;

  const transitionDuration = {
    enter: duration.enteringScreen,
    exit: duration.leavingScreen,
  };

  const isPad = isWidthDown('sm', width);
  const isMobile = isWidthDown('xs', width);

  const error = getIn(errors, name);

  const nameField = 'exhibition_points';
  const missingArtworksError = errors[nameField];

  /* Functions */
  const loadMapData = () => {
    if (map && map.base_image !== '') {
      setBaseMapObj(map);
    }

    const pointsFieldName = 'exhibition_points';
    const exhibitionPointsValue = values[pointsFieldName];
    setExhibitionPoints(exhibitionPointsValue);
  };

  const resetBaseMap = () => {
    values[name] = {
      base_image:
        'https://artcuratorgrid-dev.s3.amazonaws.com/5ebd0abb8e46cb06a2e0c885/1597245765864-lg.jpg',
      size: [768, 1024],
    };

    setBaseMapObj(values[name]);
    setUploading(false);
  };

  const submitFile = (event: any) => {
    event.preventDefault();

    const fileList: File[] = [];

    for (const f of event.target.files) {
      fileList.push(f);
    }
    fileList.forEach((file) => {
      const formData = new FormData();
      formData.append('file', file);
      setUploading(true);
      setBaseMapObj(undefined);

      uploadService
        .uploadFile(formData)
        .then((response) => {
          setUploading(false);
          values[name] = {
            base_image: response.data.Key,
            size: response.data.size || [768, 1024],
          };
          setBaseMapObj(values[name]);
        })
        .catch((errorResp) => {
          setUploading(false);
          setErrorSubmit({ errorResp });
          window.console.log('errorSubmit ', errorSubmit);
        });
    });
  };

  const renderExhibitionLines = () => {
    const latlangsAux: any = [];

    if (exhibitionPoints) {
      exhibitionPoints.forEach((point: IExhibitionPoint) => {
        latlangsAux.push(point.point_position);
      });
    }

    setLatlangs(latlangsAux);
  };

  const markerRemoveClick = () => {
    if (toBeRemove) {
      const pointsFieldName = 'exhibition_points';
      const indexValue = _.findIndex(values.exhibition_points, {
        id: toBeRemove,
      });
      const lastPointOrder = values.exhibition_points[indexValue].point_order;
      values.exhibition_points.splice(indexValue, 1);

      values.exhibition_points.forEach(
        (point: IExhibitionPoint, index: number) => {
          if (point.point_order > lastPointOrder) {
            point.point_order--;
          }
        }
      );
      setExhibitionPointIndex(values[pointsFieldName]);
      setToBeRemove(undefined);
    }
  };

  const createNewPoint = (event: any) => {
    if (!createSelectionMode && !infoSelectionMode && !nftSelectionMode) {
      return;
    }

    const idAndPointOrderOnCreate = values.exhibition_points
      ? values.exhibition_points.length + 1
      : 1;

    const newPoint: IExhibitionPoint = {
      id: idAndPointOrderOnCreate,
      art_work: {
        id: '',
        title: '',
        // year: '',
        technic_used: '',
        imagesWithCredit: [],
        videos: [],
        links: [],
      },
      point_order: idAndPointOrderOnCreate,
      point_position: [event.latlng.lat, event.latlng.lng],
      artworks_color: '',
      artworks_label_color: '',
      artworks_back_color: '',
    };

    const newInfoPoint: IExhibitionPoint = {
      id: idAndPointOrderOnCreate,
      imagesWithCredit: [
        {
          image_url: INFO_POINT_ICON,
          image_credit: 'Information Point',
        },
      ],
      videos: [],
      links: [],
      point_order: idAndPointOrderOnCreate,
      point_position: [event.latlng.lat, event.latlng.lng],
      artworks_color: '',
      artworks_label_color: '',
      artworks_back_color: '',
      info_point: true,
    };

    const newNFTPoint: IExhibitionPoint = {
      id: idAndPointOrderOnCreate,
      art_work: {
        id: '',
        title: '',
        // year: '',
        technic_used: '',
        imagesWithCredit: [],
        videos: [],
        links: [],
        nft_url: '',
      },
      point_order: idAndPointOrderOnCreate,
      point_position: [event.latlng.lat, event.latlng.lng],
      artworks_color: '',
      artworks_label_color: '',
      artworks_back_color: '',
      nft_point: true,
    };

    values.exhibition_points.push(
      createSelectionMode
        ? newPoint
        : nftSelectionMode
        ? newNFTPoint
        : newInfoPoint
    );

    const exhibitionPointIndexValue = _.findIndex(values.exhibition_points, {
      id: newPoint.id,
    });

    handleOrderChange(values.exhibition_points);

    setSelectedPoint(newPoint);
    setExhibitionPointIndex(exhibitionPointIndexValue);
    setExhibitionPoints(values.exhibition_points);
    setCreateSelectionMode(false);
    setInfoSelectionMode(false);
    setNftSelectionMode(false);
    setEditMode(false);
    setCreateMode(true);
    setOpenInfoModal(infoSelectionMode || false);
    setOpenModal(createSelectionMode || false);
  };

  const updatePosition = (event: any) => {
    const pointsFieldName = 'exhibition_points';
    const exhibitionPointsValue = values[pointsFieldName];

    const newPointPosition = event.target.getLatLng();

    exhibitionPointsValue[event.target.options.marker_index].point_position = [
      newPointPosition.lat,
      newPointPosition.lng,
    ];

    setExhibitionPoints(exhibitionPointsValue);

    renderExhibitionLines();
  };

  const handleOrderChange = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      exhibitionPoints,
      result.source.index,
      result.destination.index
    );
    const orderItems = _.sortBy(reorderedItems, ['id']);

    const pointsFieldName = 'exhibition_points';
    values[pointsFieldName] = orderItems;

    setExhibitionPoints(values[pointsFieldName]);
  };

  const reorder = (
    list?: IExhibitionPoint[],
    startIndex?: any,
    endIndex?: any
  ) => {
    let result: IExhibitionPoint[] = [];
    if (list) {
      result = Array.from(list);
    }

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    result.forEach((point: IExhibitionPoint, index: number) => {
      point.point_order = index + 1;
      point.id = index + 1;
    });
    return _.sortBy(result, ['id']);
  };

  const handleClickOpen = (event: any) => {
    const indexToRemove = Number(event.currentTarget.value);
    setOpenDialog(true);
    setToBeRemove(indexToRemove);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleAgree = () => {
    markerRemoveClick();
    setOpenDialog(false);
  };

  const markerEditClick = (event: any) => {
    values.exhibition_points.forEach(
      (point: IExhibitionPoint, index: number) => {
        if (event.currentTarget.value === point.id.toString()) {
          setEditMode(true);
          setSelectedPoint(point);
          setExhibitionPointIndex(index);
          setCreateMode(false);
          if (point.info_point) {
            setOpenInfoModal(true);
          } else {
            setOpenModal(true);
          }
        }
      }
    );
  };

  const onCreate = () => {
    setCreateSelectionMode(!createSelectionMode);
    setInfoSelectionMode(false);
    setNftSelectionMode(false);
    setOpenModal(false);
    if (popup && popup.current) {
      popup.current.leafletElement.options.leaflet.map.closePopup();
    }
  };

  const onCreateInfoPoint = () => {
    setInfoSelectionMode(!infoSelectionMode);
    setNftSelectionMode(false);
    setCreateSelectionMode(false);
    setOpenInfoModal(false);
    if (popup && popup.current && popup.current.leafletElement) {
      popup.current.leafletElement.options.leaflet.map.closePopup();
    }
  };

  const artistEditClick = (artist: any) => {
    setOpenArtistModal(true);
    setSelectedArtist(artist);
  };

  const OnCancel = () => {
    if (selectedPoint) {
      const indexValue = _.findIndex(values.exhibition_points, {
        id: selectedPoint.id,
      });
      values.exhibition_points[indexValue] = selectedPoint;
    }
    if (createMode) {
      const pointsFieldName = 'exhibition_points';
      values.exhibition_points.splice(values.exhibition_points.length - 1, 1);

      setExhibitionPoints(values[pointsFieldName]);
      setToBeRemove(undefined);
      setOpenModal(false);
      setOpenInfoModal(false);
      setSelectedPoint(undefined);
    }
    window.scrollTo(0, gridContainer.current.offsetTop);

    setEditMode(false);
    setCreateMode(false);
    setOpenModal(false);
    setOpenInfoModal(false);
    setSelectedPoint(undefined);
  };

  const OnUpdatePoint = (pointToUpdate: IExhibitionPoint) => {
    window.scrollTo(0, gridContainer.current.offsetTop);
    const pointsFieldName = 'exhibition_points';

    setEditMode(false);
    setExhibitionPoints(values[pointsFieldName]);
    setCreateMode(false);
    setOpenModal(false);
    setOpenInfoModal(false);
  };

  const onArtistUpdate = (artist: any) => {
    if (exhibitionPoints) {
      const newPoints = [...exhibitionPoints];
      newPoints.forEach((artPoint: IExhibitionPoint) => {
        if (artPoint.art_work) {
          const artistIndex = artPoint.art_work.artists.findIndex((a: any) => {
            return a.name === artist.name;
          });

          if (artistIndex !== -1) {
            artist._id = artPoint.art_work.artists[artistIndex]._id;
            artPoint.art_work.artists[artistIndex] = artist;
          }
        }
      });
    }

    window.scrollTo(0, gridContainer.current.offsetTop);
    setOpenArtistModal(false);
    setSelectedArtist(undefined);
  };

  const OnArtistCancel = () => {
    window.scrollTo(0, gridContainer.current.offsetTop);
    setOpenArtistModal(false);
    setSelectedArtist(undefined);
  };

  useEffect(() => {
    loadMapData();
  }, []);

  useEffect(() => {
    renderExhibitionLines();
  }, [exhibitionPoints]);

  useEffect(() => {
    loadMapData();
  }, [values]);

  const renderExhibitionPointsSettings = () => {
    return (
      <Grid container={true} style={{ marginBottom: 30 }}>
        <Grid item={true} xs={12} md={8}>
          <div
            className={classes.mapSettingsBackground}
            // style={isPad ? {} : {marginRight: 20}}
          >
            <p>Map General Settings</p>
            <Grid container={true}>
              <Grid item={true} xs={12} sm={6} style={{ paddingRight: 15 }}>
                <Field
                  name="show_artworks_list"
                  component={CheckboxInput}
                  inputProps={{
                    id: 'show_artworks_list',
                    type: 'show_artworks_list',
                  }}
                  resize={true}
                  label={'Show artworks list with the map'}
                />
                {!isMobile && (
                  <InfoTooltip
                    helpText={
                      'Selecting this option, a list of all exhibition points will be displayed next to the map. Check the preview below to see the result.'
                    }
                  />
                )}
                <Field
                  name="show_artworks_order"
                  component={CheckboxInput}
                  // classes={classes}
                  inputProps={{
                    id: 'show_artworks_order',
                    type: 'show_artworks_order',
                  }}
                  label={"Show artwork's ordering number inside the points"}
                  resize={true}
                />

                <Field
                  name="show_artworks_line"
                  component={CheckboxInput}
                  // classes={classes}
                  inputProps={{
                    id: 'show_artworks_line',
                    type: 'show_artworks_line',
                  }}
                  label={'Show line between points'}
                  resize={true}
                />
              </Grid>
              <Grid item={true} xs={12} sm={6}>
                <Grid container={true}>
                  <Grid item={true} xs={12}>
                    <p style={{ fontSize: 12 }}> Exhibition Points' Colour </p>
                  </Grid>
                  <Grid item={true} xs={12} sm={6}>
                    <Field
                      name="artworks_color"
                      component={ColorPickerInput}
                      // classes={classes}
                      inputProps={{
                        id: 'artworks_color',
                        type: 'artworks_color',
                      }}
                      label={'Border'}
                      initialColor={artworks_color ? artworks_color : '#9013FE'}
                      updatedColor={renderExhibitionLines}
                    />
                  </Grid>

                  <Grid item={true} xs={12} sm={6}>
                    <Field
                      name="artworks_label_color"
                      component={ColorPickerInput}
                      // classes={classes}
                      inputProps={{
                        id: 'artworks_label_color',
                        type: 'artworks_label_color',
                      }}
                      label={'Number'}
                      initialColor={
                        artworks_label_color ? artworks_label_color : '#9013FE'
                      }
                      updatedColor={renderExhibitionLines}
                    />
                    {!isMobile && (
                      <InfoTooltip
                        helpText={
                          'Changes colour of the ordering numbers inside the exhibition points.'
                        }
                      />
                    )}
                  </Grid>

                  <Grid item={true} xs={12} sm={6}>
                    <Field
                      name="artworks_back_color"
                      component={ColorPickerInput}
                      // classes={classes}
                      inputProps={{
                        id: 'artworks_back_color',
                        type: 'artworks_back_color',
                      }}
                      label={'Fill'}
                      initialColor={
                        artworks_back_color ? artworks_back_color : '#FFFFFF'
                      }
                      updatedColor={renderExhibitionLines}
                    />
                  </Grid>

                  <Grid item={true} xs={12} sm={6}>
                    <Field
                      name="artworks_line_color"
                      component={ColorPickerInput}
                      // classes={classes}
                      inputProps={{
                        id: 'artworks_line_color',
                        type: 'artworks_line_color',
                      }}
                      label={'Line'}
                      initialColor={
                        artworks_line_color ? artworks_line_color : '#9013FE'
                      }
                      updatedColor={renderExhibitionLines}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid item={true} xs={12} md={4}>
          <div
            className={classes.mapSettingsBackground}
            style={isPad ? {} : { marginLeft: '5%' }}
          >
            <p style={{ display: 'inline-block', marginRight: 5 }}>
              Map Background Image
            </p>
            <InfoTooltip helpText={'Recommended image size: 1280x720'} />
            <Grid container={true} style={{ marginTop: 20 }}>
              <Grid item={true} xs={12}>
                <Button
                  variant="contained"
                  component="label"
                  fullWidth={true}
                  className={`${classes.button} ${
                    error && classes.buttonWithError
                  }`}
                >
                  {uploading ? (
                    <CircularProgress />
                  ) : (
                    <React.Fragment>
                      <IconUpload />
                      Image or Gif
                      <input
                        id="file"
                        name="file"
                        type="file"
                        multiple={true}
                        onChange={submitFile}
                        style={{ display: 'none' }}
                        accept="image/*"
                      />
                    </React.Fragment>
                  )}
                </Button>
              </Grid>
              <Grid item={true} xs={12}>
                <ACGLink onClick={resetBaseMap}>Reset to White</ACGLink>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    );
  };

  const itemRenderer = (item: IExhibitionPoint, index: number): JSX.Element => {
    const indexValue = index + 1;
    const pointLabel = values.show_artworks_order
      ? indexValue < 10
        ? '0' + indexValue
        : indexValue
      : '';

    const pointArtworksColor = values.artworks_color;
    const pointArtworksBackColor = values.artworks_back_color;
    const pointArtworksLabelColor = values.artworks_label_color;

    const pointColor = item.artworks_color
      ? item.artworks_color
      : pointArtworksColor;
    const labelColor = item.artworks_label_color
      ? item.artworks_label_color
      : pointArtworksLabelColor;
    const pointBackColor = item.artworks_back_color
      ? item.artworks_back_color
      : pointArtworksBackColor;

    const artistList =
      item.art_work && item.art_work.artists
        ? item.art_work.artists.map((artist: any, i: number) => {
            return (
              <React.Fragment key={i}>
                {(i ? ', ' : '') +
                  `${
                    artist.name
                      ? artist.name
                      : `${artist.firstName ? artist.firstName : ''} ${
                          artist.lastName ? artist.lastName : ''
                        }`
                  }`}
              </React.Fragment>
            );
          })
        : '';

    const renderPointData = () => {
      if (item.info_point) {
        return (
          <React.Fragment>
            <Grid
              item={true}
              xs={6}
              sm={3}
              className={classes.centerInGrid}
              style={{ textAlign: 'center' }}
            >
              {item &&
                item.imagesWithCredit &&
                item.imagesWithCredit[0] &&
                item.imagesWithCredit[0].image_url && (
                  <img
                    src={getMediumImageUrl(item.imagesWithCredit[0].image_url)}
                    className={classes.artPointImage}
                  />
                )}

              {item &&
                (!item.imagesWithCredit || !item.imagesWithCredit[0]) &&
                item.videos &&
                item.videos[0] &&
                item.videos[0].video_link && (
                  <div className={classes.playerList}>
                    <ReactPlayer
                      className="reactPlayer"
                      url={item.videos[0].video_link}
                      playing={false}
                      width="100%"
                      height="76px"
                      controls={true}
                      vimeo={{ playerOptions: { height: '76px' } }}
                    />
                  </div>
                )}
            </Grid>
            {!isMobile && (
              <React.Fragment>
                <Grid
                  item={true}
                  xs={4}
                  className={classes.details}
                  style={{ alignSelf: 'center' }}
                >
                  <b>
                    <p className={classes.body}>{item.title}</p>
                  </b>
                </Grid>
                <Grid
                  item={true}
                  xs={2}
                  className={classes.details}
                  style={{ alignSelf: 'center' }}
                >
                  <p>{item.group}</p>
                </Grid>
              </React.Fragment>
            )}
          </React.Fragment>
        );
      } else if (item.art_work && item.art_work.nft_data) {
        const { nft } = item.art_work.nft_data;
        return (
          <>
            <Grid
              item={true}
              xs={6}
              sm={3}
              className={classes.centerInGrid}
              style={{ textAlign: 'center' }}
            >
              <img
                src={getSmallmageUrl(nft.image.src)}
                className={classes.artPointImage}
              />
            </Grid>
            <Grid item={true} xs={4} className={classes.details}>
              <b>
                <p>
                  {nft.artist.firstName} {nft.artist.lastName}
                </p>
              </b>
              <p className={classes.body}>{nft.title}</p>
            </Grid>
            <Grid item={true} xs={2} className={classes.details} />
          </>
        );
      } else {
        return (
          <React.Fragment>
            <Grid
              item={true}
              xs={6}
              sm={3}
              className={classes.centerInGrid}
              style={{ textAlign: 'center' }}
            >
              {item.art_work &&
                item.art_work.imagesWithCredit &&
                item.art_work.imagesWithCredit[0] &&
                item.art_work.imagesWithCredit[0].image_url && (
                  <img
                    src={getImageUrl(
                      item.art_work.imagesWithCredit[0].image_url
                    )}
                    className={classes.artPointImage}
                  />
                )}
              {item.art_work &&
                !item.art_work.featured_imageWithCredit &&
                (!item.art_work.imagesWithCredit ||
                  !item.art_work.imagesWithCredit[0]) &&
                item.art_work.videos &&
                item.art_work.videos[0] &&
                item.art_work.videos[0].video_link && (
                  <div className={classes.playerList}>
                    <ReactPlayer
                      className="reactPlayer"
                      url={item.art_work.videos[0].video_link}
                      playing={false}
                      width="100%"
                      height="76px"
                      controls={true}
                      vimeo={{ playerOptions: { height: '76px' } }}
                    />
                  </div>
                )}
            </Grid>
            {!isMobile && (
              <React.Fragment>
                <Grid item={true} xs={4} className={classes.details}>
                  <b>
                    <p>{artistList}</p>
                  </b>
                  <p className={classes.body}>
                    {item.art_work && item.art_work.title}
                  </p>
                </Grid>
                <Grid item={true} xs={2} className={classes.details}>
                  <p>{item.art_work && item.art_work.artwork_group}</p>
                </Grid>
              </React.Fragment>
            )}
          </React.Fragment>
        );
      }
    };
    return (
      <Grid container={true} className={classes.itemObject}>
        <Grid item={true} xs={3} sm={1} className={classes.centerInGrid}>
          <svg viewBox="0 0 50 50">
            <g>
              {item.info_point ? (
                <rect
                  x="15"
                  y="15"
                  width="20"
                  height="20"
                  rx="6"
                  stroke={pointColor}
                  strokeWidth={1}
                  fill={pointBackColor}
                />
              ) : (
                <circle
                  cx="25"
                  cy="25"
                  r="10"
                  stroke={pointColor}
                  strokeWidth={1}
                  fill={pointBackColor}
                />
              )}
              <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dy=".3em"
                fontFamily="Replica"
                fontSize="8"
                fill={labelColor}
              >
                {pointLabel}
              </text>
            </g>
          </svg>
        </Grid>
        {renderPointData()}
        <Grid item={true} xs={3} sm={1} className={classes.centerInGrid}>
          <Button
            value={item.id}
            disabled={editMode}
            style={{ textTransform: 'none' }}
            onClick={markerEditClick}
          >
            Edit
          </Button>
        </Grid>
        <Grid item={true} xs={3} sm={1} className={classes.centerInGrid}>
          <Button
            value={item.id}
            style={{ textTransform: 'none' }}
            disabled={editMode}
            onClick={handleClickOpen}
          >
            Remove
          </Button>
        </Grid>
      </Grid>
    );
  };

  const artists = () => {
    let artistList: any = [];
    if (exhibitionPoints) {
      exhibitionPoints.map((artPoint: IExhibitionPoint) => {
        if (
          artPoint.art_work &&
          artPoint.art_work.existing_artists &&
          artPoint.art_work.existing_artists.length > 0
        ) {
          artistList = [...artistList, ...artPoint.art_work.existing_artists];
        }
        if (
          artPoint.art_work &&
          artPoint.art_work.artists &&
          artPoint.art_work.artists.length > 0
        ) {
          artistList = [...artistList, ...artPoint.art_work.artists];
        }
        if (artPoint.art_work && artPoint.art_work.nft_data) {
          artistList = [...artistList, artPoint.art_work.nft_data.nft.artist];
        }
      });
    }
    // GENERATE UNIQUE LIST OF OBJECTS
    const uniqueArtistList: any = artistList.filter(
      (obj: any, pos: any, arr: any) => {
        return arr.map((mapObj: any) => mapObj._id).indexOf(obj._id) === pos;
      }
    );

    uniqueArtistList.sort((a: any, b: any) =>
      a.name || a.firstName > b.name || b.firstName
        ? 1
        : b.name || b.firstName > a.name || b.firstName
        ? -1
        : 0
    );

    return uniqueArtistList;
  };

  const artistRenderer = () => {
    return artists().map((artist: any) => {
      const editInfo = () => {
        artistEditClick(artist);
      };

      const artistName =
        artist.name || `${artist.firstName} ${artist.lastName}`;

      return (
        <Grid
          container={true}
          className={classes.itemObject}
          style={{ height: 101 }}
          key={artist._id}
        >
          <Grid
            item={true}
            xs={9}
            sm={2}
            style={{ overflow: 'hidden', padding: 15, height: '4.4em' }}
          >
            <p style={{ margin: 0 }}>{artistName}</p>
            <p
              style={{
                margin: 0,
                color: '#6d7278',
              }}
            >
              {artist.location && artist.location.address
                ? artist.location.address
                : ''}
            </p>

            <p
              style={{
                margin: 0,
                color: '#6d7278',
              }}
            >
              {artist.nationality}
              {artist.nationality && artist.birthYear && ','}{' '}
              {artist.birthYear && `b. ${artist.birthYear}`}
            </p>
          </Grid>
          {!isMobile && (
            <Grid
              item={true}
              xs={8}
              style={{
                overflow: 'hidden',
                padding: 15,
                height: '4.4em',
                paddingRight: 150,
              }}
            >
              {artist.bio && (
                <React.Fragment>
                  <p style={{ margin: 0 }}>Bio</p>
                  <p style={{ margin: 0, color: '#6d7278' }}>{artist.bio}</p>
                </React.Fragment>
              )}
            </Grid>
          )}
          {artist.url ? (
            <Grid
              item={true}
              xs={2}
              style={{ alignSelf: 'center', textAlign: 'center' }}
            >
              <a
                href={`/users/${artist.url}/posts`}
                target="_blank"
                style={{ textDecoration: 'none' }}
              >
                <Button
                  value={JSON.stringify(artist)}
                  style={{ textTransform: 'none' }}
                >
                  View Profile
                </Button>
              </a>
            </Grid>
          ) : (
            <Grid
              item={true}
              xs={2}
              style={{ alignSelf: 'center', textAlign: 'center' }}
            >
              <Button
                value={JSON.stringify(artist)}
                style={{ textTransform: 'none' }}
                onClick={editInfo}
              >
                Edit
              </Button>
            </Grid>
          )}
        </Grid>
      );
    });
  };

  const renderArtWorksList = () => {
    return (
      <>
        <p>Exhibition points (drag and drop to change the order)</p>
        <DragDropContext onDragEnd={handleOrderChange}>
          <Droppable droppableId="droppable">
            {(provided: any, snapshot: any) => (
              <div ref={provided.innerRef}>
                {exhibitionPoints &&
                  exhibitionPoints.map((item: any, index: number) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id.toString()}
                      index={index}
                    >
                      {(draggableProvided: any) => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                        >
                          {itemRenderer(item, index)}
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </>
    );
  };

  const renderArtworkPoints = () => {
    if (exhibitionPoints) {
      return _.sortBy(exhibitionPoints, ['id']).map(
        (point: IExhibitionPoint, i: number) => {
          const pointLabel = show_artworks_order
            ? point.point_order < 10
              ? '0' + point.point_order
              : point.point_order
            : '';

          const pointColor = point.artworks_color
            ? point.artworks_color
            : artworks_color;
          const labelColor = point.artworks_label_color
            ? point.artworks_label_color
            : artworks_label_color;

          const pointBackColor = point.artworks_back_color
            ? point.artworks_back_color
            : artworks_back_color;

          const iconToShow = `<svg xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            xml:space="preserve" style="shape-rendering:geometricPrecision;
                                                            text-rendering:geometricPrecision; image-rendering:optimizeQuality;
                                                            fill-rule:evenodd; clip-rule:evenodd"
                                                            viewBox="0 0 50 50">
                                                <g>
                                                ${
                                                  point.info_point
                                                    ? `<rect x="7.5"  y="7.5" width="35" height="35" rx="6"
                                                    stroke="${pointColor}" stroke-width="2"
                                                    fill="${pointBackColor}"/>`
                                                    : ` <circle cx="25" cy="25" r="20"
                                                    stroke="${pointColor}" stroke-width="2"
                                                    fill="${pointBackColor}" />`
                                                }
                                                    <text x="50%" y="50%" text-anchor="middle"
                                                        dy=".3em"
                                                        font-family="Replica" font-size="18"
                                                        fill="${labelColor}">${pointLabel}</text>
                                                </g>

                                            </svg>`;
          const pointDivIcon = new DivIcon({
            className: '',
            html: iconToShow,
            iconAnchor: [23, 23],
            popupAnchor: [0, -35],
            iconSize: [50, 50],
          });
          return (
            <Marker
              position={point.point_position}
              key={i}
              // @ts-ignore
              marker_index={i}
              draggable={true}
              onDragend={updatePosition}
              icon={pointDivIcon}
            >
              <ArtWorkPopup
                popup={popup}
                point={point}
                edit_mode={editMode}
                create_mode={createMode}
                handleClickOpen={handleClickOpen}
                markerEditClick={markerEditClick}
              />
            </Marker>
          );
        }
      );
    }
    return;
  };

  const renderMap = () => {
    if (!baseMapObj) {
      return;
    }
    return (
      <Map
        zoom={1}
        zoomSnap={0.5}
        zoomDelta={0.5}
        center={[
          (baseMapObj.size[0] * 0.4) / 2,
          (baseMapObj.size[1] * 0.4) / 2,
        ]}
        className={
          createSelectionMode || infoSelectionMode || nftSelectionMode
            ? classes.mapWithCursor
            : classes.map
        }
        crs={CRS.Simple}
        onClick={createNewPoint}
        attributionControl={false}
        minZoom={0}
        maxZoom={6}
        boundsOptions={{ padding: [50, 50] }}
        scrollWheelZoom={false}
      >
        <ImageOverlay
          url={getImageUrl('/' + baseMapObj.base_image)}
          bounds={[
            [0, 0],
            // base_map_obj.size,
            [baseMapObj.size[0] * 0.4, baseMapObj.size[1] * 0.4],
          ]}
        />
        {renderArtworkPoints()}

        {exhibitionPoints &&
          exhibitionPoints.length > 1 &&
          latlangs &&
          show_artworks_line && (
            <Polyline
              color={artworks_line_color}
              weight={2}
              positions={latlangs}
            />
          )}
      </Map>
    );
  };

  const renderMapSection = () => {
    return (
      <Grid container={true}>
        <Grid
          item={true}
          xs={12}
          sm={values.show_artworks_list ? 8 : 12}
          key={'map_container'}
          style={{ zIndex: 1 }}
          className={classes.mapContainer}
        >
          {renderMap()}
        </Grid>
        {values.show_artworks_list && (
          <Grid item={true} xs={4} className={classes.map}>
            <div
              style={{
                borderRadius: 8,
                backgroundColor: GREY_COLOR,
                height: '100%',
                width: '95%',
                marginLeft: '5%',
              }}
            >
              <p
                style={{
                  paddingLeft: 12,
                  color: DARK_GREY_COLOR,
                  display: 'inline-block',
                }}
              >
                The list of artworks will be displayed here
              </p>
            </div>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderButtonsSection = () => {
    const getPointType = () => {
      if (nftSelectionMode) {
        return 'Artpool NFT';
      } else if (infoSelectionMode) {
        return 'information';
      } else if (createSelectionMode) {
        return 'artwork';
      } else {
        return;
      }
    };

    return (
      <Grid container={true}>
        {missingArtworksError === 'Please add an exhibition point' && (
          <FormMessage
            message={missingArtworksError}
            visible={true}
            type={'ERROR'}
          />
        )}

        <Grid
          item={true}
          xs={12}
          sm={6}
          style={{ marginTop: '25px', paddingRight: 10 }}
        >
          {!editMode && !createMode && (
            <DesignedButton
              ACGType={'neutral'}
              size={'large'}
              fullWidth={true}
              onClick={onCreate}
            >
              {!createSelectionMode ? 'Add new Artwork' : 'Cancel'}
            </DesignedButton>
          )}
        </Grid>
        <Grid
          item={true}
          xs={12}
          sm={6}
          style={{ marginTop: '25px', paddingLeft: 10 }}
        >
          {!editMode && !createMode && (
            <DesignedButton
              ACGType={'neutral'}
              size={'large'}
              fullWidth={true}
              onClick={onCreateInfoPoint}
            >
              {!infoSelectionMode ? 'Add Informational point' : 'Cancel'}
            </DesignedButton>
          )}
        </Grid>
        {(nftSelectionMode || createSelectionMode || infoSelectionMode) && (
          <Grid item={true} xs={12} style={{ paddingTop: 10 }}>
            <Box
              display="flex"
              justifyContent="center"
              padding="25px 0px 25px 0px"
              border="solid 1px"
            >
              <label>
                Click to set the location of your next {getPointType()} point
                within the above space
              </label>
            </Box>
          </Grid>
        )}
        {!baseMapObj && error && (
          <p
            key={'base_map-helper-text'}
            className={
              'MuiFormHelperText-root MuiFormHelperText-contained Mui-error'
            }
          >
            Please add your Exhibition Map
          </p>
        )}

        {missingArtworksError && (
          <FormMessage
            message={
              'Some error found on the art points. Please review that before saving the exhibition.'
            }
            visible={!!missingArtworksError}
            type={'ERROR'}
          />
        )}
      </Grid>
    );
  };

  const renderDialogWindow = () => {
    return (
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{`You are about to remove the selected Exhibition Point?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure that you want to remove the selected exhibition point?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAgree} color="primary">
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const artWorkInputModal = () => {
    return (
      <ACGModal open={openModal} className={classes.modal}>
        <Fade appear={true} in={openModal} timeout={transitionDuration}>
          <div className={classes.content}>
            <Grid container={true}>
              <Grid item={true}>
                {selectedPoint && (
                  <Field
                    name={selectedPoint.id + '_artwork'}
                    component={ArtWorkInput}
                    inputProps={{
                      exhibition_point: selectedPoint,
                      OnUpdatePoint,
                      OnCancel,
                      exhibition_point_index: exhibitionPointIndex,
                      isCreate: createMode,
                      defaultArtWorkColor: artworks_color,
                      defaultArtWorkBackColor: artworks_back_color,
                      defaultArtWorkLabelColor: artworks_label_color,
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </Fade>
      </ACGModal>
    );
  };

  const infoPointInputModal = () => {
    return (
      <ACGModal open={openInfoModal} className={classes.modal}>
        <Fade appear={true} in={openInfoModal} timeout={transitionDuration}>
          <div className={classes.content}>
            <Grid container={true}>
              <Grid item={true}>
                {selectedPoint && (
                  <Field
                    name={selectedPoint.id + '_artwork'}
                    component={InfoPointInput}
                    inputProps={{
                      exhibition_point: selectedPoint,
                      OnUpdatePoint,
                      OnCancel,
                      exhibition_point_index: exhibitionPointIndex,
                      isCreate: createMode,
                      defaultArtWorkColor: artworks_color,
                      defaultArtWorkBackColor: artworks_back_color,
                      defaultArtWorkLabelColor: artworks_label_color,
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </Fade>
      </ACGModal>
    );
  };

  const artistInputModal = () => {
    return (
      <ACGModal open={openArtistModal} className={classes.modal}>
        <Fade appear={true} in={openArtistModal} timeout={transitionDuration}>
          <div className={classes.content}>
            <Grid container={true}>
              <Grid item={true}>
                {selectedArtist && (
                  <React.Fragment>
                    <ArtistInput
                      artist={selectedArtist}
                      onArtistUpdate={onArtistUpdate}
                      OnArtistCancel={OnArtistCancel}
                    />
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
          </div>
        </Fade>
      </ACGModal>
    );
  };
  return (
    <>
      <div ref={gridContainer}>
        <Grid container={true} className={classes.sectionSpacing}>
          <Grid item={true} sm={2}>
            <h3 className={classes.headerSection}>Exhibition Map</h3>
          </Grid>
          <Grid item={true} xs={12} sm={8}>
            <Grid container={true} spacing={1}>
              <Grid item={true} xs={12}>
                {renderExhibitionPointsSettings()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12}>
            <Grid container={true} spacing={1}>
              <Grid item={true} xs={12}>
                {renderMapSection()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12} sm={2} />
          <Grid item={true} xs={12} sm={8}>
            <Grid container={true} spacing={1}>
              <Grid item={true} xs={12}>
                {renderButtonsSection()}
              </Grid>
              <Grid item={true} xs={12} className={classes.artWorkSection}>
                {exhibitionPoints &&
                  exhibitionPoints.length > 0 &&
                  renderArtWorksList()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {artists().length > 0 && (
          <Grid container={true} className={classes.sectionSpacing}>
            <Grid item={true} xs={12} sm={2}>
              <h3 className={classes.headerSection}>Artist Info</h3>
            </Grid>

            <Grid item={true} xs={12} sm={8}>
              {artistRenderer()}
            </Grid>
          </Grid>
        )}
      </div>

      {renderDialogWindow()}
      {artWorkInputModal()}
      {infoPointInputModal()}
      {artistInputModal()}
    </>
  );
};

export default compose<Props, IBaseMapProps>(
  withStyles(style),
  withWidth()
)(BaseMapUpload);
