import {
  default as withWidth,
  WithWidth,
} from '@mui/material/Hidden/withWidth';
import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { GREY_COLOR } from '../../styles/constants';
import { compose } from 'recompose';
import { IWithAccountProps, withAccount } from '../account/InjectAccount';
import { MenuList } from './MenuList';
import { BurgerButton } from './BurgerButton';
import { useState } from 'react';

export interface ITemporaryDrawerProps {}

type Props = ITemporaryDrawerProps &
  WithWidth &
  IWithAccountProps &
  RouteComponentProps<{}>;

const drawerStyles: React.CSSProperties = {
  backgroundColor: GREY_COLOR,
};

function TemporaryDrawer(props: Props) {
  const { account, width } = props;

  const [open, setOpen] = useState<boolean>(false);

  const toggleDrawer = (open: boolean) => setOpen(open);

  const anchorPosition = 'right';
  return (
    <React.Fragment key={anchorPosition}>
      <BurgerButton
        onClick={() => {
          setOpen(true);
        }}
      />
      <Drawer
        style={drawerStyles}
        anchor={anchorPosition}
        open={open}
        onClose={() => toggleDrawer(false)}
      >
        <MenuList
          account={account}
          onInteraction={() => toggleDrawer(false)}
          width={width}
        />
      </Drawer>
    </React.Fragment>
  );
}

export default compose<Props, ITemporaryDrawerProps>(
  withWidth(),
  withAccount,
  withRouter
)(TemporaryDrawer);
