import { createTheme, adaptV4Theme } from '@mui/material';
import { PRIMARY, NEUTRAL, BREAKPOINTS } from './artpool/constants';

export const themes = {
  artpoolTheme: createTheme(
    adaptV4Theme({
      breakpoints: {
        values: {
          xs: parseInt(BREAKPOINTS.X_SMALL, 10),
          sm: parseInt(BREAKPOINTS.SMALL, 10),
          md: parseInt(BREAKPOINTS.MEDIUM, 10),
          lg: parseInt(BREAKPOINTS.LARGE, 10),
          xl: parseInt(BREAKPOINTS.X_LARGE, 10),
        },
      },
      typography: {
        // useNextVariants: true,
        // Use the system font instead of the default Roboto font.
        fontFamily: 'Replica-Regular',
      },
      palette: {
        primary: {
          main: PRIMARY.ARTPOOL_GREEN,
        },
        secondary: {
          main: NEUTRAL[500],
        },
      },
    })
  ),

  acgTheme: createTheme(
    adaptV4Theme({
      typography: {
        fontFamily: 'Replica-Regular',
      },
      palette: {
        primary: {
          main: PRIMARY.ARTPOOL_GREEN,
        },
        secondary: {
          main: NEUTRAL[500],
        },
      },
    })
  ),
};
