import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { Grid } from '@mui/material';
import { IUser } from '../../types/user';
import Title from '../../library/dataDisplay/typography/Title';
import UserPicture from '../../library/dataDisplay/profile/UserPicture';

export interface IProfileCommentProps {
  user: IUser;
  additionalButton: React.ReactNode;
  onClick: () => void;
}

type Props = IProfileCommentProps & WithStyles<string>;

const styles = createStyles({
  profile: {
    padding: 5,
  },
  user: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '40px',
  },
});

class ProfileComment extends React.Component<Props, any> {
  public render() {
    const { classes, user, additionalButton, onClick } = this.props;

    return (
      <Grid container={true} className={classes.profile}>
        <Grid item={true} xs={12} style={{ textAlign: 'right' }}>
          {additionalButton}
        </Grid>
        <Grid item={true} xs={12} onClick={onClick}>
          <UserPicture user={user} style={{ float: 'left', marginRight: 10 }} />
          <div className={classes.user}>
            {user.firstName && user.lastName && (
              <Title>
                {user.firstName} {user.lastName}
              </Title>
            )}
            {/*<SubTitle>*/}
            {/*  {generateLocation(user)}*/}
            {/*</SubTitle>*/}
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default compose<Props, IProfileCommentProps>(withStyles(styles))(
  ProfileComment
);
