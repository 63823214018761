import { useEffect, useRef } from 'react';

export const useTimeout = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }

    const timeOutId = setTimeout(() => savedCallback.current(), delay);

    return () => clearTimeout(timeOutId);
  }, [delay]);
};
