import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ALTERNATIVE } from '@acg/assets';

interface ArtpoolTooltipProps {
  text: NonNullable<React.ReactNode>;
}

export const ArtpoolTooltip = (props: ArtpoolTooltipProps) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip open={open} title={props.text}>
        <IconButton onClick={handleTooltipOpen}>
          <InfoIcon style={{ color: ALTERNATIVE.ARTPOOL_ORANGE }} />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};
