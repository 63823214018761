import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { FieldProps } from 'formik';
import { ALTERNATIVE, NEUTRAL, PRIMARY } from '@acg/assets';

export interface TextInputProps extends FieldProps {
  inputProps: TextFieldProps;
  small?: boolean;
}

export const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: NEUTRAL[600],
  },
  '& label.Mui-error': {
    color: ALTERNATIVE.ARTPOOL_ORANGE,
  },
  '& .Mui-error': {
    color: ALTERNATIVE.ARTPOOL_ORANGE,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: NEUTRAL[600],
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: NEUTRAL[300],
    },
    '&:hover fieldset': {
      borderColor: PRIMARY.ARTPOOL_GREEN,
    },
    '&.Mui-focused fieldset': {
      borderColor: NEUTRAL[600],
    },
    '&.Mui-error fieldset': {
      borderColor: ALTERNATIVE.ARTPOOL_ORANGE,
    },
  },
  '& .MuiFormHelperText-root': {
    '&.Mui-error': {
      color: ALTERNATIVE.ARTPOOL_ORANGE,
    },
  },
});

type Props = TextInputProps;

export function TextInput(props: Props) {
  const { inputProps, field, form } = props;

  return (
    <CssTextField
      variant={'outlined'}
      defaultValue={field.value}
      fullWidth={true}
      onChange={form.handleChange}
      onBlur={form.handleBlur}
      disabled={form.isSubmitting || inputProps.disabled}
      error={!!(form.touched[field.name] && form.errors[field.name])}
      helperText={
        form.touched[field.name] && (form.errors[field.name] as string)
      }
      {...inputProps}
    />
  );
}

export default TextInput;
