import styles from './form-message.module.scss';
import React from 'react';
import Grid from '@mui/material/Grid';

const typeClass = {
  ERROR: styles.error,
  SUCCESS: styles.success,
};
export interface IFormMessageProps {
  visible: boolean;
  message: string | React.ReactNode;
  type?: keyof typeof typeClass;
}

export function FormMessage(props: IFormMessageProps) {
  const { visible, message, type = 'ERROR' } = props;

  if (!visible || !message) {
    return null;
  }

  return (
    <Grid item={true} xs={12} className={typeClass[type]}>
      <span className={styles.text}>{message}</span>
    </Grid>
  );
}

export default FormMessage;
