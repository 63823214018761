import { ExhibitionsAction, IExhibitionsState } from './types';
import {
  IExhibition,
  IExhibitionMap,
  IExhibitionPoint,
} from '../../../types/exhibitions';
import moment from 'moment';
import { IOnlineExhibitionInitialValue } from '../../../containers/onlineexhibition/OnlineExhibitionForm';
import { formatErrors } from '../../../utils/formatters';

const initialState: IExhibitionsState = {
  exhibition: undefined,
  exhibitions: undefined,
  isLoading: false,
  error: undefined,
  errorMessage: undefined,
  isExhibitionAdmin: undefined,
  exhibition_edit: undefined,
  redirectTo: undefined,
  isDeleted: false,
};

export default function (
  state = initialState,
  action: ExhibitionsAction
): IExhibitionsState {
  const payloadIntoInitialValue = (exhibitionToEdit: IExhibition) => {
    const mapData: IExhibitionMap = {
      base_image: exhibitionToEdit.base_map.base_image,
      size: exhibitionToEdit.base_map.size,
    };

    const points = exhibitionToEdit.exhibition_points;
    points.forEach((point: IExhibitionPoint) => {
      point.id = point.point_order;
    });

    const initialFetchData: IOnlineExhibitionInitialValue = {
      ...exhibitionToEdit,
      exhibition_points: points,
      base_map: mapData,
      press_release: exhibitionToEdit.press_release
        ? exhibitionToEdit.press_release
        : '',
      artists: exhibitionToEdit.artists ? exhibitionToEdit.artists : [],
      // artists_list: serverData.artists_list ? serverData.artists_list : [],
      co_curator_list: exhibitionToEdit.co_curator_list
        ? exhibitionToEdit.co_curator_list.join(', ')
        : '',
      other_contributors_list: exhibitionToEdit.other_contributors_list
        ? exhibitionToEdit.other_contributors_list.join(', ')
        : '',
      end_date: moment(exhibitionToEdit.end_date).isValid()
        ? moment(exhibitionToEdit.end_date).format('YYYY-MM-DD')
        : '',
      start_date: moment(exhibitionToEdit.start_date).isValid()
        ? moment(exhibitionToEdit.start_date).format('YYYY-MM-DD')
        : '',
      imagesWithCredit: exhibitionToEdit.imagesWithCredit
        ? exhibitionToEdit.imagesWithCredit
        : [],
      links: exhibitionToEdit.links ? exhibitionToEdit.links : [],
      videos: exhibitionToEdit.videos ? exhibitionToEdit.videos : [],
      isDraft: exhibitionToEdit.isDraft ? exhibitionToEdit.isDraft : false,
    };

    if (!initialFetchData.access_key) {
      initialFetchData.access_key =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
    }

    if (!initialFetchData.edit_key) {
      initialFetchData.edit_key =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
    }
    return initialFetchData;
  };

  switch (action.type) {
    case '@@exhibitions/GET_EXHIBITIONS':
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
      };
    case '@@exhibitions/GET_EXHIBITIONS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        exhibitions: action.payload.exhibitions,
        exhibition_edit: undefined,
        error: undefined,
        redirectTo: undefined,
      };
    case '@@exhibitions/GET_EXHIBITION':
      return {
        ...state,
        isDeleted: false,
        isLoading: true,
      };
    case '@@exhibitions/GET_EXHIBITION_SUCCESS':
      return {
        ...state,
        isLoading: false,
        exhibition: action.payload.exhibition,
        exhibition_edit: undefined,
        error: undefined,
        redirectTo: undefined,
      };
    case '@@exhibitions/CREATE_EXHIBITION':
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        redirectTo: undefined,
      };
    case '@@exhibitions/CREATE_EXHIBITION_SUCCESS':
      return {
        ...state,
        exhibition_edit: payloadIntoInitialValue(action.payload.exhibition),
        redirectTo: action.payload.exhibition.url,
        isLoading: false,
        error: undefined,
      };

    case '@@exhibitions/CREATE_EXHIBITION_ERROR':
      const formattedCreatedErrors = formatErrors(action.payload.error);
      window.console.log('ERROR', action.payload.error);
      let errorCreateMessage = 'Something went wrong';
      if (formattedCreatedErrors && formattedCreatedErrors.featured_image) {
        formattedCreatedErrors.images = 'Image must be set';
        errorCreateMessage = 'Image must be set';
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        errorMessage: errorCreateMessage,
        redirectTo: undefined,
      };
    case '@@exhibitions/UPDATE_EXHIBITION':
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        redirectTo: undefined,
      };
    case '@@exhibitions/UPDATE_EXHIBITION_SUCCESS':
      const url = action.payload.exhibition.url;
      if (state.exhibition_edit && url !== state.exhibition_edit.url) {
        return {
          ...state,
          isLoading: false,
          error: undefined,
          redirectTo: url,
        };
      }

      return {
        ...state,
        isLoading: false,
        error: undefined,
      };
    case '@@exhibitions/UPDATE_EXHIBITION_ERROR':
      const formattedUpdateErrors = formatErrors(action.payload.error);
      let errorUpdateMessage = 'Something went wrong';
      if (formattedUpdateErrors && formattedUpdateErrors.featured_image) {
        formattedUpdateErrors.images = 'Image must be set';
        errorUpdateMessage = 'Image must be set';
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        errorMessage: errorUpdateMessage,
        redirectTo: undefined,
      };
    case '@@exhibitions/ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        redirectTo: undefined,
      };

    case '@@exhibitions/LIKE_EXHIBITION':
      return {
        ...state,
        // isLoading: true
      };
    case '@@exhibitions/LIKE_EXHIBITION_SUCCESS':
      return {
        ...state,
        isLoading: false,
        exhibition: action.payload.exhibition,
        error: undefined,
      };
    case '@@exhibitions/LIKE_EXHIBITION_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case '@@exhibitions/HAS_EXHIBITION_ACCESS':
      return {
        ...state,
        isLoading: true,
      };
    case '@@exhibitions/HAS_EXHIBITION_ACCESS_SUCCESS':
      const initialDefaultValues: IOnlineExhibitionInitialValue = {
        description: '',
        title: '',
        base_map: {
          // The url for the white base map
          base_image: 'assets/online-exhibition-background.jpg',
          size: [768, 1024],
        },
        exhibition_points: [],
        imagesWithCredit: [],
        links: [],
        videos: [],
        tags: [],
        artists_list: '',
        artists: [],
        press_release: '',
        co_curator_list: '',
        other_contributors_list: '',
        end_date: moment().format('YYYY-MM-DD'),
        start_date: moment().format('YYYY-MM-DD'),
        isDraft: true,

        show_artworks_list: true,
        show_artworks_order: true,
        show_artworks_line: true,

        artworks_color: '#6e32ff',
        artworks_label_color: '#6e32ff',
        artworks_line_color: '#6e32ff',

        visibility: 'PUBLIC',
        access_key:
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15),
        edit_key:
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15),
        invited_users: [],
      };

      return {
        ...state,
        isLoading: false,
        isExhibitionAdmin: action.payload.isExhibitionAdmin,
        exhibition_edit: initialDefaultValues,
        error: undefined,
      };
    case '@@exhibitions/HAS_EXHIBITION_ACCESS_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case '@@exhibitions/GET_EDIT_EXHIBITION':
      return {
        ...state,
        isLoading: true,
        exhibition: undefined,
        exhibition_edit: undefined,
      };
    case '@@exhibitions/GET_EDIT_EXHIBITION_SUCCESS':
      const exhibitionToEdit = action.payload.exhibition;

      const mapData: IExhibitionMap = {
        base_image: exhibitionToEdit.base_map.base_image,
        size: exhibitionToEdit.base_map.size,
      };

      const points = exhibitionToEdit.exhibition_points;
      points.forEach((point: IExhibitionPoint) => {
        point.id = point.point_order;
      });

      const initialFetchData: IOnlineExhibitionInitialValue = {
        ...exhibitionToEdit,
        exhibition_points: points,
        base_map: mapData,
        press_release: exhibitionToEdit.press_release
          ? exhibitionToEdit.press_release
          : '',
        artists: exhibitionToEdit.artists ? exhibitionToEdit.artists : [],
        // artists_list: serverData.artists_list ? serverData.artists_list : [],
        co_curator_list: exhibitionToEdit.co_curator_list
          ? exhibitionToEdit.co_curator_list.join(', ')
          : '',
        other_contributors_list: exhibitionToEdit.other_contributors_list
          ? exhibitionToEdit.other_contributors_list.join(', ')
          : '',
        end_date: moment(exhibitionToEdit.end_date).isValid()
          ? moment(exhibitionToEdit.end_date).format('YYYY-MM-DD')
          : '',
        start_date: moment(exhibitionToEdit.start_date).isValid()
          ? moment(exhibitionToEdit.start_date).format('YYYY-MM-DD')
          : '',
        imagesWithCredit: exhibitionToEdit.imagesWithCredit
          ? exhibitionToEdit.imagesWithCredit
          : [],
        links: exhibitionToEdit.links ? exhibitionToEdit.links : [],
        videos: exhibitionToEdit.videos ? exhibitionToEdit.videos : [],
        isDraft: exhibitionToEdit.isDraft ? exhibitionToEdit.isDraft : false,
      };

      if (!initialFetchData.access_key) {
        initialFetchData.access_key =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);
      }

      if (!initialFetchData.edit_key) {
        initialFetchData.edit_key =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);
      }

      return {
        ...state,
        isLoading: false,
        exhibition_edit: initialFetchData,
        error: undefined,
      };

    case '@@exhibitions/UPDATE_STATE':
      return {
        ...state,
        exhibition_edit: action.payload.editExhibitionData,
      };
    case '@@exhibitions/CLEAR_REDIRECT':
      return {
        ...state,
        redirectTo: undefined,
      };

    case '@@exhibitions/CLEAR_EDIT_EXHIBITION':
      return {
        ...state,
        redirectTo: undefined,
        isLoading: false,
        exhibition: undefined,
        exhibition_edit: undefined,
        error: undefined,
      };

    case '@@exhibitions/DELETE_EXHIBITION_SUCCESS':
      return {
        ...state,
        isLoading: false,
        exhibition: undefined,
        isDeleted: true,
        error: undefined,
      };

    case '@@exhibitions/INVITE_EDIT_EXHIBITION':
      return {
        ...state,
        // isLoading: true,
        error: undefined,
      };
    case '@@exhibitions/INVITE_EDIT_EXHIBITION_SUCCESS':
      const exhibitionEditUpdate = state.exhibition_edit;
      if (exhibitionEditUpdate) {
        if (!exhibitionEditUpdate.invited_users) {
          exhibitionEditUpdate.invited_users = [];
        }
        exhibitionEditUpdate.invited_users.push(action.payload.inviteEmail);
      }
      return {
        ...state,
        isLoading: false,
        error: undefined,
        exhibition_edit: exhibitionEditUpdate,
      };

    default:
      return state;
  }
}
