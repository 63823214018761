import * as React from 'react';

export interface IIconDownArrowProps {
  fill?: string;
  height?: number;
  style?: any;
  isSelectInputArrow?: boolean;
}

// isSelectInputArrow is adding the classes that the original arrow has to be able to make it clickable
const IconDownArrow = (props: IIconDownArrowProps) => {
  const style: React.CSSProperties = {
    height: props.height || 23,
    backgroundColor:
      (props.style && props.style.backgroundColor) || 'transparent',
    borderRadius: (props.style && props.style.borderRadius) || 6,
    top: props.isSelectInputArrow ? 'calc(50% - 8px)' : '',
  };

  return (
    <svg
      className={
        props.isSelectInputArrow
          ? 'MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined'
          : ''
      }
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400 400"
      fill={props.fill || 'gray'}
      style={style}
    >
      <path
        id="svg_1"
        d="m177.405,261.755l-3.07,-3.76l-8.33,-10.14l-12.38,-15l-15,-18.25l-16.62,-20.11l-16.7,-20.27l-15.56,-18.9l-13.16,-16l-9.41,-11.43c-1.5,-1.82 -3,-3.67 -4.5,-5.46l-0.19,-0.23c-1.85,-2.24 -5.71,-3.52 -8.49,-3.52a12.19,12.19 0 0 0 -8.48,3.52a12.06,12.06 0 0 0 -3.52,8.48l0.43,3.19a12,12 0 0 0 3.09,5.3l3.07,3.73l8.42,10.19l12.38,15l15,18.24l16.54,20.08l16.69,20.27l15.57,18.9l13.15,16l9.41,11.41c1.5,1.82 3,3.67 4.5,5.46l0.19,0.24c1.85,2.24 5.71,3.51 8.49,3.51a12.22,12.22 0 0 0 8.48,-3.51a12.1,12.1 0 0 0 3.52,-8.49l-0.43,-3.19a11.93,11.93 0 0 0 -3.09,-5.29l0,0.03z"
      />
      <path
        id="svg_2"
        d="m337.515,121.265l-3.11,3.73l-8.49,10.23l-12.47,15.06l-15.29,18.46l-16.76,20.25l-16.91,20.42l-15.7,18.94l-13.33,16.1l-9.59,11.54l-4.54,5.48l-0.2,0.24c-2.08,2.51 -3.51,5.1 -3.51,8.48a12.26,12.26 0 0 0 3.51,8.49a12.09,12.09 0 0 0 8.49,3.51c3,-0.13 6.48,-1.1 8.48,-3.51l3.12,-3.77l8.48,-10.23l12.48,-15.06l15.29,-18.46l16.76,-20.17l16.91,-20.42l15.7,-18.94l13.33,-16.1l9.59,-11.54l4.54,-5.48l0.19,-0.24c2.09,-2.51 3.52,-5.1 3.52,-8.48a12.23,12.23 0 0 0 -3.52,-8.49a12.07,12.07 0 0 0 -8.48,-3.51c-3,0.13 -6.49,1.1 -8.49,3.51l0,-0.04z"
      />
    </svg>
  );
};

export default IconDownArrow;
