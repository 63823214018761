import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { ClickAwayListener, Theme, Tooltip } from '@mui/material';
import { LIGHT_PRIMARY, PRIMARY_COLOR } from '../../../styles/constants';

export interface IInfoTooltipProps {
  helpText: any;
}

const style = createStyles({
  icon: {
    // backgroundColor: PRIMARY_COLOR,
    backgroundColor: PRIMARY_COLOR,
    borderColor: LIGHT_PRIMARY,
    borderRadius: 3,
    padding: 2,
    cursor: 'pointer',
    width: 13,
    height: 13,
    lineHeight: '13.5px',
    textAlign: 'center',
    fontWeight: 500,
    fontFamily: 'Replica-Regular',
    fontSize: 10,
    display: 'inline-block',
  },
});

type Props = IInfoTooltipProps & WithStyles<string> & WithWidth;

function InfoTooltip(props: Props) {
  const { classes, helpText, width } = props;

  const [open, setOpen] = React.useState(false);

  const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const isPad = isWidthDown('sm', width);

  // @ts-ignore
  return isPad ? (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div style={{ display: 'inherit' }}>
        <HtmlTooltip
          onClose={handleTooltipClose}
          title={<React.Fragment>{helpText}</React.Fragment>}
          open={open}
          disableFocusListener={true}
          disableHoverListener={true}
          disableTouchListener={true}
        >
          <div onClick={handleTooltipOpen} className={classes.icon}>
            ?
          </div>
        </HtmlTooltip>
      </div>
    </ClickAwayListener>
  ) : (
    <HtmlTooltip
      onClose={handleTooltipClose}
      title={<React.Fragment>{helpText}</React.Fragment>}
    >
      <div onClick={handleTooltipOpen} className={classes.icon}>
        ?
      </div>
    </HtmlTooltip>
  );
}

export default compose<Props, IInfoTooltipProps>(
  withStyles(style),
  withWidth()
)(InfoTooltip);
