import {
  default as withWidth,
  WithWidth,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Formik, FormikProps, FieldArray } from 'formik';
import { Grid } from '@mui/material';
import { compose } from 'recompose';
import { withAccount, IWithAccountProps } from '../account/InjectAccount';
import { generateValidator, IValidationConfig } from '../../utils/formatters';
import {} from '@mui/material/';
import { PRIMARY_COLOR } from '../../styles/constants';
import DesignedButton from '../../library/inputs/DesignedButton';
import CuratorInput from '../../components/form/CuratorInput';
import Button from '@mui/material/Button';
import { NEUTRAL } from '@acg/assets';

export interface IViewerStep5Props {}

type Props = RouteComponentProps<{}> &
  IViewerStep5Props &
  IWithAccountProps &
  WithStyles<string> &
  WithWidth;

interface IFormValue {
  curators: any[];
  firstName?: string;
  lastName?: string;
  email?: string;
}

const styles = createStyles({
  wrapper: {
    fontFamily: 'Replica-Regular',
    minHeight: 'calc(100vh - 90px)',
    textAlign: 'center',
    padding: 20,
  },
  link: {
    cursor: 'pointer',
    textTransform: 'none',
    margin: 10,
    textDecoration: 'underline',
    color: NEUTRAL[500],
    '&:hover': {
      color: PRIMARY_COLOR,
      textDecoration: 'underline',
    },
  },
});

const ViewerStep5: React.FC<Props> = (props) => {
  const { classes, history, account, updateAccount } = props;

  const onSubmit = async (values: IFormValue, { setSubmitting }: any) => {
    try {
      const curators = values.curators;

      if (
        (values.firstName && values.firstName !== '') ||
        (values.lastName && values.lastName !== '') ||
        (values.email && values.email !== '')
      ) {
        curators.push({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
        });
        values.firstName = '';
        values.lastName = '';
        values.email = '';
      }
      await updateAccount({
        ...account,
        hasWorkedWith: curators,
      });
      history.push('/login');
    } catch (err) {}
    setSubmitting(false);
  };

  const onClick = async () => {
    await updateAccount({
      ...account,
      profile: 'VIEWER',
      pending: false,
    });
    history.push('/login');
  };

  const renderForm = (formProps: FormikProps<IFormValue>) => {
    return (
      <>
        <form onSubmit={formProps.handleSubmit}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12}>
              <FieldArray
                name={'curators'}
                component={CuratorInput as React.ComponentType<any>}
              />
            </Grid>
            <Grid item xs={8}>
              <DesignedButton
                ACGType={'main'}
                size={'large'}
                type={'submit'}
                fullWidth
                disabled={formProps.isSubmitting}
              >
                Finalize Application
              </DesignedButton>
              <Button className={classes.link} onClick={onClick}>
                I haven't worked with any curator yet
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    );
  };

  const initialValues: IFormValue = {
    curators: [],
  };
  const config: IValidationConfig = {};

  const validate = generateValidator(config);

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      className={classes.wrapper}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Grid item xs={12} md={8}>
        <h3 style={{ color: PRIMARY_COLOR, margin: 0, fontSize: 20 }}>
          Pending Application
        </h3>
        <p style={{ margin: 0 }}>
          Artpool is a community based on recommendations by worldwide
          curators and institutions, only referred artists can join our artists
          directory. If you are not featured already on the platform, please,
          give us the full name and email of at least one curator you worked
          with to complete your application. We’ll get in touch on your behalf!
        </p>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
          render={renderForm}
        />
      </Grid>
    </Grid>
  );
};

export default compose<Props, IViewerStep5Props>(
  withRouter,
  withAccount,
  withStyles(styles),
  withWidth()
)(ViewerStep5);
