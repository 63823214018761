import ApiServices, { IDataListResponse, IDataResponse } from './ApiServices';
import { IExhibition } from '../types/exhibitions';

export interface IExhibitionFilters {
  first_result?: number;
  max_results?: number;
  user_id?: string;
  key?: string;
}

class ExhibitionService {
  public hasExhibitionAccess = (): Promise<IDataResponse<any>> => {
    return ApiServices.getRequest(`exhibitions_admin`);
  };

  public getExhibitions = (
    filters?: IExhibitionFilters
  ): Promise<IDataListResponse<IExhibition>> => {
    return ApiServices.getRequest('exhibitions', filters);
  };

  public getExhibitionData = (
    id: string,
    editKey?: string
  ): Promise<IDataResponse<IExhibition>> => {
    let hasKey = '';
    if (editKey) {
      hasKey = `?key=${editKey}`;
    }
    return ApiServices.getRequest(`exhibitions/${id}/data${hasKey}`);
  };

  public getExhibition = (
    id: string,
    loggedUserId?: string,
    filters?: IExhibitionFilters
  ): Promise<IDataResponse<IExhibition>> => {
    if (loggedUserId) {
      return ApiServices.getRequest(`exhibitions/${id}`, filters);
    }
    return ApiServices.getRequest(
      `exhibitions/${id}`,
      filters,
      {},
      { authenticated: false }
    );
  };

  public createExhibition = (
    exhibition: Partial<IExhibition>
  ): Promise<IDataResponse<IExhibition>> => {
    return ApiServices.postRequest('exhibitions', exhibition);
  };

  public deleteExhibition = (id: string) => {
    return ApiServices.deleteRequest(`exhibitions/${id}`);
  };

  public updateExhibition = (
    id: string,
    exhibition: Partial<IExhibition>,
    editKey?: string
  ): Promise<IDataResponse<IExhibition>> => {
    let hasKey = '';
    if (editKey) {
      hasKey = `?key=${editKey}`;
    }
    return ApiServices.putRequest(`exhibitions/${id}${hasKey}`, exhibition);
  };

  public likeExhibition = (id: string): Promise<IDataResponse<IExhibition>> => {
    return ApiServices.putRequest(
      `exhibitions/${id}/like`,
      {},
      {},
      { authenticated: false }
    );
  };

  public sendInvitationEmail = (
    exhibitionId: string,
    emailToInvite: string
  ): Promise<IDataResponse<{}>> => {
    return ApiServices.postRequest('invite_edit', {
      exhibitionId,
      emailToInvite,
    });
  };

  public getNFTProject = (projectId: string): Promise<IDataResponse<{}>> => {
    return ApiServices.getRequest(
      `project/${projectId}`,
      {},
      {},
      { artPool: true, authenticated: false }
    );
  };
}

export default ExhibitionService;
