import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import {} from '@mui/material';
import { compose } from 'recompose';

import {} from '@mui/material/';
import { useSelector } from 'react-redux';
import { IStoreState } from '../../store';
import { Authenticator } from '@acg/frontend-ui-artpool';
import { artcuratorgridApiClient } from '../../services/ArtcuratorgridApiClient';
import { ACGAppPaths } from '@acg/shared/const';

export interface ILoginProps {}

type Props = RouteComponentProps<{}> &
  ILoginProps &
  WithStyles<string> &
  WithWidth;

const Login: React.FC<Props> = (props) => {
  const { user } = useSelector((root: IStoreState) => root.authReducer);

  const history = useHistory();

  const redirect = (fallBack: string) => {
    const stateAny = props.location.state as any;
    const from = stateAny?.from;
    const redirect = from || fallBack;
    history.push(redirect);
  };

  useEffect(() => {
    if (user) {
      redirect(ACGAppPaths.ROOT);
    }
  }, [user]);

  if (user) {
    return <></>;
  }

  const onClose = () => redirect('/');
  return (
    <Authenticator
      onClose={onClose}
      artcuratorgridApi={artcuratorgridApiClient()}
    />
  );
};

export default compose<Props, ILoginProps>(withRouter, withWidth())(Login);
