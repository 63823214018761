import React from 'react';
import Grid from '@mui/material/Grid';
import { NEUTRAL } from '@acg/assets';
import { CssTextField } from './../text-input/text-input';
import { styled } from '@mui/material/styles';

export const CSSSharesField = styled(CssTextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: NEUTRAL[300],
      borderRadius: '0px 4px 4px 0px',
    },
  },
});
export const sharesInput = (props: any) => {
  const { inputProps, field, form } = props;

  return (
    <Grid container>
      <Grid
        item
        xs={6}
        sx={{
          backgroundColor: NEUTRAL[300],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '4px 0px 0px 4px',
          maxHeight: 56,
        }}
      >
        <p style={{ textTransform: 'capitalize', color: NEUTRAL[600] }}>
          {inputProps.userType}*
        </p>
      </Grid>
      <Grid item xs={6}>
        <CSSSharesField
          variant={'outlined'}
          defaultValue={field.value}
          fullWidth={true}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          disabled={form.isSubmitting || inputProps.disabled}
          error={!!(form.touched[field.name] && form.errors[field.name])}
          helperText={form.touched[field.name] && form.errors[field.name]}
          inputProps={{ step: 1 }}
          {...inputProps}
        />
      </Grid>
    </Grid>
  );
};
