import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import {} from '@mui/material';
import { compose } from 'recompose';
import { IExhibitionPost } from '../../types/posts';
import Title from '../../library/dataDisplay/typography/Title';
import SubTitle from '../../library/dataDisplay/typography/SubTitle';
import ContentContainer from './ContentContainer2';
import { getImageUrl } from '../../utils/imageUrl';
import { dateToStringParser } from '../../utils/datetimehelper';

export interface IExhibitionProps {
  post: IExhibitionPost;
  onClick: () => void;
  onCommentClick: () => void;
  onLike: (post: IExhibitionPost) => void;
  onShare: () => void;
  like: boolean;
  numberOfLike: number;
  onCategoryButtonClick: (selectedCategory: string) => void;
  nonClickable?: boolean;
}

const styles = createStyles({
  img: {},
  oneLiner: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  secondLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
});

type Props = WithStyles<string> & IExhibitionProps;

class ExhibitionContent extends React.Component<Props, any> {
  public render() {
    const {
      post,
      onClick,
      like,
      numberOfLike,
      classes,
      onCommentClick,
      onShare,
      onCategoryButtonClick,
    } = this.props;

    const postVenue =
      post.venue && typeof post.venue === 'string'
        ? post.venue
        : post.venue_name;

    const footer = (
      <div>
        <Title className={classes.oneLiner}>{post.title}</Title>
        {!post.title && <Title className={classes.oneLiner}>&nbsp;</Title>}
        <SubTitle className={classes.secondLine}>
          {dateToStringParser(post.start_date, 'MMM Do YYYY')} -{' '}
          {dateToStringParser(post.end_date, 'MMM Do YYYY')}
        </SubTitle>
        <SubTitle className={classes.secondLine}>{postVenue}</SubTitle>
      </div>
    );

    const featured = (
      <>
        <div
          style={{
            width: '100%',
            height: '100%',
            minHeight: 379,
            background: `url(${getImageUrl(
              post.featured_image
            )}) no-repeat center`,
            backgroundSize: 'cover',
          }}
        />
      </>
    );

    const onLike = () => this.props.onLike(post);

    return (
      <ContentContainer
        footer={footer}
        onCommentClick={onCommentClick}
        featured={featured}
        onClick={onClick}
        onLike={onLike}
        liked={like}
        numberOfLike={numberOfLike}
        post={post}
        onShare={onShare}
        categorySubType={post.sub_type}
        onCategoryButtonClick={onCategoryButtonClick}
        nonClickable={this.props.nonClickable}
      />
    );
  }
}

export default compose<Props, IExhibitionProps>(withStyles(styles))(
  ExhibitionContent
);
