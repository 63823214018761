import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { Grid, Button } from '@mui/material';
import { compose } from 'recompose';
import { withRouter, RouteComponentProps } from 'react-router';
import { IPost } from '../../types/posts';
import PostModal from '../../components/modal/ACGModalContent';
import FullPost from '../../components/post/FullPost';
import Loading from '../../components/loading/Loading';
import PostService from '../../services/PostService';
import { IError } from '../../types/returns';
import FormCancel from '../../components/form/FormCancel';
import ExhibitionForm, { IExhibitionInitialValue } from './ExhibitionForm';
import InspirationForm, { IInspirationInitialValue } from './InspirationForm';
import { withAccount, IWithAccountProps } from '../account/InjectAccount';
import PostComments from '../comment/PostComments';
import SocialModal from '../social/SocialModal';
import { checkRight } from '../../utils/userUtils';
import ArtProjectForm, { IArtProjectInitialValue } from './ArtProjectForm';
import { dateToStringParser } from '../../utils/datetimehelper';
import { ACGAppPaths } from '@acg/shared/const';
import { IUser } from '../../types/user';

const COMMENT_ID = 'comments';
const MODAL_ID = 'modal';

export interface IPostProps {
  onDone: () => void;
  onCreate: (post: IPost) => void;
  onUpdate: (post: IPost) => void;
  onLike: (post: IPost) => void;
  onRemove: (post: IPost) => void;
}

interface IState {
  post?: IPost;
  loading: boolean;
  error?: IError;
  editing: boolean;
  dialogOpen: boolean;
  shareDialogOpen: boolean;
}

const styles = createStyles({
  modal: {},
});

type Props = IPostProps &
  WithStyles<string> &
  RouteComponentProps<{ postId: string }> &
  IWithAccountProps;

class Post extends React.Component<Props, IState> {
  private postService = new PostService();
  private shouldScroll = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      editing: false,
      dialogOpen: false,
      shareDialogOpen: false,
    };
    const locationState = props.history.location.state as any;
    this.shouldScroll = locationState?.comment;
  }

  public componentDidMount() {
    const {
      match: {
        params: { postId },
      },
    } = this.props;
    this.fetchData(postId);
  }

  public componentDidUpdate(prevProps: Props) {
    const {
      match: {
        params: { postId },
      },
    } = this.props;

    const {
      match: {
        params: { postId: prevPostId },
      },
    } = prevProps;

    if (prevPostId !== postId && postId) {
      this.fetchData(postId);
    }
  }

  public deletePost = async () => {
    const { post } = this.state;
    this.setState({ loading: true, dialogOpen: false });
    try {
      if (post) {
        await this.postService.deletePost(post.id);
      }
      this.props.onRemove(post!);
      this.props.onDone();
    } catch (e) {
      this.setState({ error: e as IError, loading: false });
    }
  };

  public fetchData = (id: string) => {
    this.setState({ loading: true });
    this.postService
      .getPost(id)
      .then((data) => {
        this.setState({ loading: false, post: data.data });
        setTimeout(() => {
          if (this.shouldScroll) {
            this.scrollToComment();
          }
        }, 500);
      })
      .catch((err: IError) => {
        this.setState({ loading: false, error: err });
      });
  };

  public scrollToComment = () => {
    const account = this.props.account as IUser | undefined;
    if (!account) {
      this.redirectToLogin();
    }
    const commnets = document.getElementById(COMMENT_ID);
    const modal = document.getElementById(MODAL_ID);
    if (commnets && modal) {
      const bdrect = commnets.getBoundingClientRect();
      modal.scrollTo({ top: bdrect.top });
    }
  };

  private redirectToLogin = () => {
    const {
      history,
    } = this.props;
    history.push(ACGAppPaths.LOGIN, { from: history.location })
  }

  public render() {
    const {
      onDone,
      history,
      onCreate,
      onLike: onLikeProps,
      onUpdate,
    } = this.props;
    const { post, loading, editing, shareDialogOpen } = this.state;
    const account = this.props.account as IUser | undefined;

    const onAuthorClick = () => {
      if (post && post.venue_id) {
        return history.push(`/venues/${post.venue_id.url}/posts`);
      } else if (post) {
        return history.push(`/users/${post.created_by.url}/posts`);
      }
      return;
    };

    const handleClose = editing || shareDialogOpen ? undefined : onDone;

    const rights = checkRight(account);

    if (loading) {
      return (
        <PostModal id={MODAL_ID} onClickAway={handleClose}>
          <Loading />
        </PostModal>
      );
    }

    if (!post) {
      return (
        <PostModal id={MODAL_ID} onClickAway={handleClose}>
          <div>
            this content has either been deleted or not available for you.
          </div>
          <FormCancel onClick={handleClose}>Close</FormCancel>
        </PostModal>
      );
    }

    let editingContent = <div />;

    if (post.type === 'EXHIBITION') {
      const venueId = post.venue_id && post.venue_id._id;
      const initialValuesExhibition = {
        ...post,
        start_date: dateToStringParser(post.start_date, 'YYYY-MM-DD'),
        end_date: dateToStringParser(post.end_date, 'YYYY-MM-DD'),
        // artists: (post.artists_list || []).reduce((acc, v) => `${acc}${v},`, '').slice(0, -1),
        artists: post.artists || [],
        // images: [post.featured_image, ...(post.images || [])],
        // imagesWithCredit: [post.featured_imageWithCredit, ...(post.imagesWithCredit || [])],
        imagesWithCredit: post.imagesWithCredit ? post.imagesWithCredit : [],
        venue: {
          address:
            typeof post.venue! === 'string'
              ? (post.venue! as any)
              : post.venue!.address,
          value:
            typeof post.venue! === 'string'
              ? (post.venue! as any)
              : post.venue!.address,
          placeId: typeof post.venue! === 'string' ? '' : post.venue!.placeId,
          latLng: typeof post.venue! === 'string' ? [0, 0] : post.venue!.latLng,
        },
        tags: post.tags ? post.tags : [],
        press_release: post.press_release ? post.press_release : '',
        links: post.links ? post.links : [],
        videos: post.videos ? post.videos : [],
        co_curator_list: (post.co_curator_list || [])
          .reduce((acc, v) => `${acc}${v},`, '')
          .slice(0, -1),
        other_contributors_list: (post.other_contributors_list || [])
          .reduce((acc, v) => `${acc}${v},`, '')
          .slice(0, -1),
        venue_id: venueId,
      } as IExhibitionInitialValue;
      editingContent = (
        <ExhibitionForm
          onDone={onDone}
          onCreate={onCreate}
          onUpdate={onUpdate}
          initialValues={initialValuesExhibition}
        />
      );
    } else if (post.type === 'ARTPROJECT') {
      const venueId = post.venue_id && post.venue_id._id;
      const initialValuesArtProject = {
        ...post,
        start_date: dateToStringParser(post.start_date, 'YYYY-MM-DD'),
        end_date: dateToStringParser(post.end_date, 'YYYY-MM-DD'),
        artists: post.artists || [],
        // artists: (post.artists_list || []).reduce((acc, v) => `${acc}${v},`, '').slice(0, -1),
        // images: [post.featured_image, ...(post.images || [])],
        // imagesWithCredit: [post.featured_imageWithCredit, ...(post.imagesWithCredit || [])],
        imagesWithCredit: post.imagesWithCredit ? post.imagesWithCredit : [],
        venue: {
          address:
            typeof post.venue! === 'string'
              ? (post.venue! as any)
              : post.venue!.address,
          value:
            typeof post.venue! === 'string'
              ? (post.venue! as any)
              : post.venue!.address,
          placeId: typeof post.venue! === 'string' ? '' : post.venue!.placeId,
          latLng: typeof post.venue! === 'string' ? [0, 0] : post.venue!.latLng,
        },
        tags: post.tags ? post.tags : [],
        press_release: post.press_release ? post.press_release : '',
        links: post.links ? post.links : [],
        videos: post.videos ? post.videos : [],
        co_curator_list: (post.co_curator_list || [])
          .reduce((acc, v) => `${acc}${v},`, '')
          .slice(0, -1),
        other_contributors_list: (post.other_contributors_list || [])
          .reduce((acc, v) => `${acc}${v},`, '')
          .slice(0, -1),
        venue_id: venueId,
      } as IArtProjectInitialValue;

      editingContent = (
        <ArtProjectForm
          onDone={onDone}
          onCreate={onCreate}
          onUpdate={onUpdate}
          initialValues={initialValuesArtProject}
        />
      );
    } else if (post.type === 'ONLINEEXHIBITION') {
      history.push(`/online-exhibition/${post.url}`);
    } else {
      const venueId = post.venue_id && post.venue_id._id;

      const startDate = dateToStringParser(post.start_date, 'YYYY-MM-DD');
      const imagesWithCredit =
        post.type === 'IMAGE'
          ? post.imagesWithCredit
            ? post.imagesWithCredit
            : []
          : [];
      const initialValuesInspiration: IInspirationInitialValue = {
        ...post,
        imagesWithCredit,
        artists: post.artists || [],
        start_date: post.start_date ? startDate : undefined,
        links: post.links ? post.links : [],
        videos: post.videos ? post.videos : [],
        venue_id: venueId,
      } as IInspirationInitialValue;
      editingContent = (
        <InspirationForm
          onDone={onDone}
          onCreate={onCreate}
          onUpdate={onUpdate}
          initialValues={initialValuesInspiration}
        />
      );
    }

    const onClickOnModify = () =>
      this.setState({ editing: !this.state.editing });

    const isOwner =
      account && post.created_by && post.created_by.id === account.id;

    const handleDialogClose = () => this.setState({ dialogOpen: false });
    const handleDialogOpen = () => this.setState({ dialogOpen: true });

    let content: React.ReactNode;

    const liking = (lpost: IPost) =>
      !!lpost.likes.find((lp) => lp.id === account?.id);

    const onLike = (lpost: IPost) => {
      if (!account) {
        this.redirectToLogin();
        return;
      }
      if (lpost.type === 'ONLINEEXHIBITION') {
        this.postService
          .likeOnlineExhibitionPost(post.id)
          .then((p) => p.data)
          .then((p) => {
            onLikeProps(p);
            this.setState({ post: p });
          });
      } else {
        this.postService
          .likePost(lpost.id)
          .then((p) => p.data)
          .then((p) => {
            onLikeProps(p);
            this.setState({ post: p });
          });
      }
    };

    const onComment = (commentNumber: number) => {
      onLikeProps({
        ...post,
        number_of_comments: commentNumber,
      });
    };

    const onShareClick = () => this.setState({ shareDialogOpen: true });
    const onShareClose = () => this.setState({ shareDialogOpen: false });

    const onTagClick = (id: string) =>
      rights === 'ALL' ? history.push(ACGAppPaths.ROOT, { tags: [id] }) : {};

    content = (
      <div>
        {editing && editingContent}
        {!editing && (
          <FullPost
            onTagClick={onTagClick}
            onShareClick={onShareClick}
            onCommentClick={this.scrollToComment}
            liking={liking}
            onLike={onLike}
            post={post}
            onAuthorClick={onAuthorClick}
          />
        )}
        {!editing && (
          <div id={COMMENT_ID}>
            <PostComments onComment={onComment} post={post} user={account} />
          </div>
        )}
        {!editing && (
          <SocialModal
            open={this.state.shareDialogOpen}
            onClose={onShareClose}
            object={{
              type: 'POST',
              object: post,
            }}
          />
        )}
        {!editing && <FormCancel onClick={handleClose}>Close</FormCancel>}
        {isOwner && !editing && (
          <Grid container={true} spacing={1} style={{ marginTop: 8 }}>
            <Grid item={true} xs={12} sm={6} md={6}>
              <FormCancel onClick={onClickOnModify}>edit</FormCancel>
            </Grid>
            <Grid item={true} xs={12} sm={6} md={6}>
              <FormCancel onClick={handleDialogOpen}>delete</FormCancel>
            </Grid>
          </Grid>
        )}
      </div>
    );

    if (this.state.dialogOpen) {
      content = (
        <div>
          <h2>Are you sure?</h2>
          <br />
          <div>
            You are about to delete a post, this operation cannot be undone. Are
            you sure you want to proceed?
          </div>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.deletePost} color="primary" autoFocus={true}>
            Proceed
          </Button>
        </div>
      );
    }

    return (
      <PostModal onClickAway={handleClose} id={MODAL_ID}>
        {content}
      </PostModal>
    );
  }
}

export default compose<Props, IPostProps>(
  withRouter,
  withAccount,
  withStyles(styles)
)(Post);
