import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { Grid, Button } from '@mui/material';
import { compose } from 'recompose';
import { IDataListResponse } from '../../services/ApiServices';
import { IError } from '../../types/returns';
import { IUser } from '../../types/user';
import Loading from '../loading/Loading';
import SubTitle from '../../library/dataDisplay/typography/SubTitle';

export interface IProfileListProps<T> {
  loadUsers: (pageNumber: number) => Promise<IDataListResponse<T>>;
  connectedUser?: IUser;
  pageSize: number;
  renderSingle: (data: T, onDataChange: (data: T) => void) => React.ReactNode;
  noDataMessage: string;
}

const style = createStyles({
  postBar: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: 60,
    lineHeight: '60px',
    textAlign: 'center',
    backgroundColor: 'white',
  },
  bottomContent: {
    textAlign: 'center',
    padding: '20px 0',
  },
  bottomContentMobile: {
    textAlign: 'center',
    padding: '20px 0 80px 0',
  },
  noData: {
    width: '100%',
    textAlign: 'center',
    padding: '20px 0',
  },
});

type Props<T> = IProfileListProps<T> & WithStyles<string>;

interface IState<T> {
  loading: boolean;
  error?: Error;
  data: T[];
  currentPage: number;
  hasMoreData: boolean;
  hasLoadedOnce: boolean;
}

class ProfileList<T extends { id: string }> extends React.Component<
  Props<T>,
  IState<T>
> {
  constructor(props: Props<T>) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      currentPage: 0,
      hasMoreData: false,
      hasLoadedOnce: false,
    };
  }

  public componentDidMount() {
    this.getData(0);
  }

  public getData = (pageNumber: number = 0) => {
    const { loadUsers, pageSize } = this.props;

    this.setState({ loading: true });
    return loadUsers(pageNumber)
      .then((data) => {
        this.setState({
          loading: false,
          hasLoadedOnce: true,
          data: [...this.state.data, ...data.data],
          currentPage: pageNumber,
          hasMoreData:
            data.count !== 0 || data.count === pageSize ? true : false,
        });
      })
      .catch((err: IError) => {
        this.setState({
          loading: false,
          error:
            err.status === 'error' ? new Error(err.error_message) : undefined,
        });
      });
  };

  public onDataChange = (data: T) =>
    this.setState({
      data: this.state.data.map((d) => (d.id === data.id ? data : d)),
    });

  public render() {
    const { classes, renderSingle, noDataMessage } = this.props;

    const { currentPage, hasMoreData, loading, data: users } = this.state;

    const loadNextPage = () => this.getData(currentPage + 1);

    return (
      <React.Fragment>
        {users.map((data, n) => {
          return renderSingle(data, this.onDataChange);
        })}
        {!loading && users.length === 0 ? (
          <div className={classes.noData}>
            <SubTitle>{noDataMessage}</SubTitle>
          </div>
        ) : undefined}
        {hasMoreData && !loading ? (
          <Grid
            key="showMore"
            item={true}
            className={classes.bottomContent}
            xs={12}
          >
            <Button onClick={loadNextPage}>Load More</Button>
          </Grid>
        ) : null}
        {loading ? (
          <Grid
            key="loading"
            item={true}
            className={classes.bottomContent}
            xs={12}
          >
            <Loading />
          </Grid>
        ) : null}
      </React.Fragment>
    );
  }
}

export default compose(withStyles(style))(
  ProfileList as any
) as React.ComponentClass<IProfileListProps<any>>;
