import { ReqOptions, ResOptions, Route } from 'typesafe-api';
import { ArtpoolApiEndpoint } from './api';
import { JsonNftWithRawFile } from './nft';

export interface JsonMyAccount {
  discord: {
    username?: string;
  };
  membershipWhitelistAddress?: string;
}

export interface MyAccountResp extends ResOptions {
  body: JsonMyAccount;
}

const basePath = '/my-account';

/**
 * GET my account
 */

export const getMyAccountRoute: Route = {
  method: 'get',
  path: basePath,
};

export type GetMyAccountEndpointDef = ArtpoolApiEndpoint<
  ReqOptions,
  MyAccountResp
>;

/**
 * PATCH my account
 */

export const patchMyAccountRoute: Route = {
  method: 'patch',
  path: basePath,
};

export interface PatchMyAccountReq extends ReqOptions {
  body: {
    discord?: {
      username?: string;
    };
  };
}

export type PatchMyAccountEndpointDef = ArtpoolApiEndpoint<
  PatchMyAccountReq,
  MyAccountResp
>;

/**
 * GET NFTs owned
 */

export const getNftsOwnedRoute: Route = {
  method: 'get',
  path: basePath + '/nfts-owned',
};

export interface NftsOwnedResp extends ResOptions {
  body: {
    nfts: JsonNftWithRawFile[];
  };
}

export type GetNftsOwnedEndpointDef = ArtpoolApiEndpoint<
  ReqOptions,
  NftsOwnedResp
>;
