import { SVGProps } from 'react';

export const UniqueIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 168 56" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M92.9827 48.2989C92.9827 48.8751 93.5127 49.3071 94.0697 49.1614C103.206 46.7759 109.947 38.4883 109.947 28.6313C109.947 16.9111 100.418 7.41016 88.6622 7.41016C76.9074 7.41016 67.3779 16.9111 67.3779 28.6313C67.3779 38.7148 74.4318 47.1558 83.8884 49.3164C84.4392 49.4424 84.9509 49.0128 84.9509 48.4474V43.4279C84.9509 43.019 84.677 42.6628 84.2873 42.5377C81.2932 41.5762 78.8714 39.8731 77.0223 37.4288C75.0524 34.8064 74.0674 31.7677 74.0674 28.3127C74.0674 23.7479 75.7877 20.0085 79.2281 17.0947C82.0857 14.6804 85.339 13.4733 88.9875 13.4733C93.69 13.4733 97.4841 15.173 100.37 18.5724C102.701 21.3197 103.866 24.4902 103.866 28.0838C103.866 31.6081 102.833 34.7646 100.765 37.5537C99.0314 39.909 96.658 41.5705 93.6447 42.5376C93.2558 42.6624 92.9827 43.0183 92.9827 43.4267V48.2989ZM92.7119 31.5121C92.7119 31.0096 92.3045 30.6022 91.8021 30.6022H86.1522C85.649 30.6022 85.2416 31.0096 85.2416 31.5121V31.8559C85.2416 32.6254 84.3702 33.0092 83.9298 32.378C83.1397 31.2442 82.7446 29.9031 82.7446 28.3545C82.7446 26.5922 83.3622 25.0661 84.5965 23.7757C85.8315 22.4853 87.2952 21.84 88.9875 21.84C90.666 21.84 92.1227 22.4853 93.357 23.7757C94.5921 25.0661 95.2089 26.5922 95.2089 28.3545C95.2089 29.8561 94.8184 31.1788 94.0375 32.3224C93.5987 32.9646 92.7119 32.5838 92.7119 31.806V31.5121Z"
      />
      <path d="M7.99219 20.6219C7.99219 20.1194 8.39963 19.7119 8.90217 19.7119H16.3433C16.8459 19.7119 17.2532 20.1194 17.2532 20.6219V32.8131C17.2532 33.3837 17.3804 33.8346 17.6346 34.1654C17.8887 34.488 18.2004 34.6493 18.5693 34.6493C18.9464 34.6493 19.3237 34.4756 19.7007 34.1282C20.0861 33.7724 20.2789 33.3342 20.2789 32.8131V20.6219C20.2789 20.1194 20.6863 19.7119 21.1889 19.7119H28.7407C29.2434 19.7119 29.6507 20.1194 29.6507 20.6219V33.1729C29.6507 35.2488 29.1219 37.0478 28.0641 38.5697C27.0146 40.0915 25.637 41.2412 23.9316 42.0186C22.2345 42.7961 20.447 43.1848 18.5693 43.1848C17.2328 43.1848 15.9292 42.9739 14.6582 42.5521C13.3873 42.122 12.2476 41.4852 11.2391 40.6415C10.2388 39.7897 9.44752 38.735 8.86543 37.4779C8.28326 36.2206 7.99219 34.7857 7.99219 33.1729V20.6219Z" />
      <path d="M32.743 41.8706C32.2404 41.8706 31.833 41.4632 31.833 40.9606V27.38C31.833 25.552 32.3373 23.9889 33.3459 22.6904C34.3625 21.3836 35.7031 20.3993 37.3676 19.7377C39.0321 19.076 40.7948 18.7451 42.6561 18.7451C44.5584 18.7451 46.3663 19.0925 48.0801 19.7873C49.8018 20.4738 51.1916 21.5035 52.2493 22.8765C53.3153 24.2494 53.8481 25.8705 53.8481 27.7398V40.9606C53.8481 41.4632 53.4408 41.8706 52.9382 41.8706H45.0297C44.5271 41.8706 44.1197 41.4632 44.1197 40.9606V28.3477C44.1197 27.8514 43.9803 27.4834 43.7016 27.2435C43.4309 26.9955 43.1195 26.8713 42.7667 26.8713C42.3732 26.8713 41.9962 27.0202 41.6353 27.318C41.2745 27.6074 41.0942 27.9507 41.0942 28.3477V40.9606C41.0942 41.4632 40.6867 41.8706 40.1842 41.8706H32.743Z" />
      <path d="M56.9305 41.8698C56.4279 41.8698 56.0205 41.4624 56.0205 40.9598V19.9148C56.0205 19.4122 56.4279 19.0049 56.9305 19.0049H64.7406C65.2432 19.0049 65.6506 19.4122 65.6506 19.9148V40.9598C65.6506 41.4624 65.2432 41.8698 64.7406 41.8698H56.9305Z" />
      <path d="M111.635 20.6219C111.635 20.1194 112.042 19.7119 112.545 19.7119H119.986C120.488 19.7119 120.895 20.1194 120.895 20.6219V32.8131C120.895 33.3837 121.023 33.8346 121.277 34.1654C121.531 34.488 121.843 34.6493 122.212 34.6493C122.589 34.6493 122.966 34.4756 123.343 34.1282C123.728 33.7724 123.921 33.3342 123.921 32.8131V20.6219C123.921 20.1194 124.328 19.7119 124.832 19.7119H132.383C132.886 19.7119 133.293 20.1194 133.293 20.6219V33.1729C133.293 35.2488 132.764 37.0478 131.707 38.5697C130.657 40.0915 129.279 41.2412 127.574 42.0186C125.877 42.7961 124.089 43.1848 122.212 43.1848C120.876 43.1848 119.571 42.9739 118.3 42.5521C117.03 42.122 115.89 41.4852 114.881 40.6415C113.881 39.7897 113.09 38.735 112.508 37.4779C111.926 36.2206 111.635 34.7857 111.635 33.1729V20.6219Z" />
      <path d="M158.639 26.6364C158.823 27.0557 158.646 27.5421 158.246 27.7629L148.255 33.2632C147.82 33.5023 147.274 33.3498 147.027 32.9204L145.559 30.374C145.304 29.9305 145.464 29.3636 145.914 29.1197L148.714 27.6025C149.247 27.3133 149.33 26.5843 148.749 26.4084C148.473 26.3247 148.174 26.2828 147.857 26.2828C146.842 26.2828 145.828 26.7416 144.813 27.6239C144.603 27.8003 143.659 28.5061 143.379 29.2471C143.205 29.6706 143.239 30.0588 143.274 30.3058C143.31 30.6234 144.254 33.1995 145.058 34.117C145.897 35.0698 146.842 35.5286 147.962 35.5286C149.59 35.5286 151.357 34.6245 153.191 32.8161C153.556 32.4561 154.144 32.4353 154.512 32.7917L158.399 36.5523C158.717 36.8585 158.772 37.3501 158.5 37.697C157.757 38.6465 156.832 39.5 155.763 40.2574C154.434 41.175 153.035 41.8453 151.53 42.3394C150.026 42.7983 148.523 43.0453 147.017 43.0453C145.514 43.0453 144.113 42.7983 142.749 42.3394C141.385 41.8807 140.16 41.1749 139.041 40.1868C137.922 39.2339 137.047 37.9989 136.348 36.5167C135.682 35.0346 135.193 33.3407 135.088 31.3998C135.088 31.2939 135.088 31.2586 135.088 31.0821C135.088 30.9058 135.088 30.7998 135.088 30.7998C135.088 28.2238 135.682 26.0006 136.837 24.1654C137.991 22.3305 139.496 20.9188 141.28 20.0014C143.1 19.0839 144.919 18.5898 146.773 18.5898C152.329 18.5898 156.285 21.2721 158.639 26.6364Z" />
    </svg>
  );
};
