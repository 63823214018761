import { ICuratorUser, IUser } from './user';
import { ITag } from './tags';
import { ILink } from './links';
import { IVideoLink } from './videos';
import { IImage } from './images';
import { IInstitution } from './institution';

export type PostSubType =
  | 'EXHIBITION'
  | 'ARTPROJECT'
  | 'PUBLICATION'
  | 'STUDIOVISIT'
  | 'INTERVIEW'
  | 'RELATEDTOYOURWORK'
  | 'ONLINEEXHIBITION';

export type PostType =
  | 'STANDARD'
  | 'VIDEO'
  | 'IMAGE'
  | 'EXHIBITION'
  | 'ARTICLE'
  | 'ARTPROJECT'
  | 'ONLINEEXHIBITION';

export type VideoProvider = 'SELF' | 'YOUTUBE' | 'DAYLIMOTION';

export type VisibilityType = 'PUBLIC' | 'PRIVATE' | 'MEMBERS';

export interface IBasePost {
  id: string;
  curated_by?: any[];
  status: string;
  artists_list?: string[];
  artists?: any[];
  existing_artists?: any[];
  description: string;
  created_at: string;
  created_by: ICuratorUser;
  type: PostType;
  sub_type: string;
  likes: IUser[];
  tags: ITag[];
  number_of_comments: number;
  number_of_views: number;
  url: string;

  visibility: VisibilityType;
  access_key: string;
  start_date?: string;
  venue_id?: IInstitution;
}

export interface IStandardPost extends IBasePost {
  title?: string;
  type: 'STANDARD';
  links?: ILink[];
  videos?: IVideoLink[];
}

export interface IVideoPost extends IBasePost {
  title?: string;
  video_link: string;
  video_provider: VideoProvider;
  type: 'VIDEO';
  links?: ILink[];
  videos?: IVideoLink[];
}

export interface IArticlePost extends IBasePost {
  title?: string;
  article_link: string;
  article_provider: string;
  article_title: string;
  article_excerpt: string;
  type: 'ARTICLE';
  links?: ILink[];
  videos?: IVideoLink[];
}

export interface IImagePost extends IBasePost {
  featured_image?: string;
  featured_imageWithCredit: IImage;
  images?: string[];
  imagesWithCredit?: IImage[];
  type: 'IMAGE';
  title?: string;
  links?: ILink[];
  videos?: IVideoLink[];
}

export interface IImagePost extends IBasePost {
  featured_image?: string;
  featured_imageWithCredit: IImage;
  images?: string[];
  imagesWithCredit?: IImage[];
  type: 'IMAGE';
  title?: string;
  links?: ILink[];
  videos?: IVideoLink[];
}

export interface IArtProjectPost extends IBasePost {
  title: string;
  // start_date: string;
  end_date: string;
  city?: string;
  country?: string;
  venue?: {
    address: string;
    latLng: [number, number];
    placeId: string;
  };
  venue_name?: string;
  featured_image?: string;
  featured_imageWithCredit: IImage;
  images?: string[];
  imagesWithCredit?: IImage[];
  type: 'ARTPROJECT';
  press_release?: string;
  links?: ILink[];
  videos?: IVideoLink[];
  co_curator_list?: string[];
  other_contributors_list?: string[];
  sub_type: 'ARTPROJECT';
}

export interface IExhibitionPost extends IBasePost {
  title: string;
  // start_date: string;
  end_date: string;
  city?: string;
  country?: string;
  venue?: {
    address: string;
    latLng: [number, number];
    placeId: string;
  };
  venue_name?: string;
  featured_image?: string;
  featured_imageWithCredit: IImage;
  images?: string[];
  imagesWithCredit?: IImage[];
  type: 'EXHIBITION';
  press_release?: string;
  links?: ILink[];
  videos?: IVideoLink[];

  co_curator_list?: string[];
  other_contributors_list?: string[];
}

export interface IOnlineExhibitionPost extends IBasePost {
  title: string;
  // start_date: string;
  end_date: string;
  type: 'ONLINEEXHIBITION';
  featured_image?: string;
  featured_imageWithCredit: IImage;
  artists?: any[];
}

export const PostTypeOptions = [
  {
    id: 'EXHIBITION',
    label: 'Exhibition Archive',
    text: 'Exhibition Archive',
    tabLabel: 'Exhibition Archive',
  },

  {
    id: 'ONLINEEXHIBITION',
    label: 'Exhibition',
    text: 'Exhibition',
    tabLabel: 'Exhibition',
  },

  {
    id: 'ARTPROJECT',
    label: 'Art Project',
    text: 'Art Project',
    tabLabel: 'Art Project',
  },
  {
    id: 'PUBLICATION',
    label: 'Publication',
    text: 'Publication',
    tabLabel: 'Publication',
  },
  {
    id: 'STUDIOVISIT',
    label: 'Studio Visit',
    text: 'Studio Visit',
    tabLabel: 'Studio Visit',
  },
  {
    id: 'INTERVIEW',
    label: 'Interview',
    text: 'Interview',
    tabLabel: 'Interview',
  },
  {
    id: 'RELATEDTOYOURWORK',
    label: 'Related To Your Work*',
    text: 'Related To Your Work',
    tabLabel: 'Other',
  },
];

export const PostInstitutionsTypeOptions = [
  {
    id: 'ONLINEEXHIBITION',
    label: 'Exhibition',
    text: 'Exhibition',
    tabLabel: 'Exhibition',
  },
  {
    id: 'EXHIBITION',
    label: 'Exhibition Archive',
    text: 'Exhibition Archive',
    tabLabel: 'Exhibition Archive',
  },
  {
    id: 'ARTPROJECT',
    label: 'Art Project',
    text: 'Art Project',
    tabLabel: 'Art Project',
  },
  {
    id: 'PUBLICATION',
    label: 'Publication',
    text: 'Publication',
    tabLabel: 'Publication',
  },
  {
    id: 'STUDIOVISIT',
    label: 'Studio Visit',
    text: 'Studio Visit',
    tabLabel: 'Studio Visit',
  },
  {
    id: 'INTERVIEW',
    label: 'Interview',
    text: 'Interview',
    tabLabel: 'Interview',
  },
  {
    id: 'RELATEDTOYOURWORK',
    label: 'Related To Your Work*',
    text: 'Related To Your Work',
    tabLabel: 'Other',
  },
];
export type IPost =
  | IStandardPost
  | IExhibitionPost
  | IImagePost
  | IArticlePost
  | IVideoPost
  | IArtProjectPost
  | IOnlineExhibitionPost;
