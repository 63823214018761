import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { FieldProps } from 'formik';
import { compose } from 'recompose';

export interface ICheckboxInputProps extends FieldProps {
  label: string;
  resize?: boolean;
}

type Props = WithStyles<string> & ICheckboxInputProps;

const style = createStyles({
  resizeClass: {
    fontSize: 12,
  },
});

class CheckboxInput extends React.Component<Props, any> {
  public render() {
    const { form, field, label, classes, resize } = this.props;

    return (
      <FormControlLabel
        classes={{
          label: resize ? classes.resizeClass : classes.checkboxClass,
        }}
        control={
          <Checkbox
            checked={form.values[field.name]}
            onChange={form.handleChange}
            name={field.name}
            color="secondary"
          />
        }
        label={label}
      />
    );
  }
}

export default compose<Props, ICheckboxInputProps>(withStyles(style))(
  CheckboxInput
);
