import './facebook-icon.module.scss';
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

// tslint:disable-next-line:no-empty-interface
export interface FacebookIconProps {}

type Props = FacebookIconProps & SvgIconProps;

export function FacebookIcon(props: Props) {
  return (
    <SvgIcon {...props} viewBox={'0 0 25 24'}>
      <path d="M17.8755 13.5L18.5423 9.15673H14.3745V6.33823C14.3745 5.15023 14.9565 3.99148 16.8233 3.99148H18.7178V0.293982C18.7178 0.293982 16.9988 0.000732422 15.3548 0.000732422C11.9228 0.000732422 9.67951 2.08123 9.67951 5.84698V9.15748H5.86426V13.5007H9.67951V24.0007H14.3745V13.5007L17.8755 13.5Z" />
    </SvgIcon>
  );
}

export default FacebookIcon;
