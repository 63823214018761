import React from 'react';
import styles from './subtitle1.module.scss';

// tslint:disable-next-line:no-empty-interface
export interface Subtitle1Props
  extends React.HTMLAttributes<HTMLParagraphElement> {
  children?: React.ReactNode;
}

export const Subtitle1 = (props: Subtitle1Props) => {
  const { children, ...rest } = props;

  return (
    <p className={styles.subtitle1} {...rest}>
      {props.children}
    </p>
  );
};

export default Subtitle1;
