import { ActionType } from 'typesafe-actions';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import * as actions from './actions';
import BackOfficeServices from '../../../services/BackOfficeServices';
import InstitutionService from '../../../services/InstitutionService';

export function* fetchRequest({
  payload,
}: ActionType<typeof actions.fetchRequest>) {
  try {
    const service = new BackOfficeServices();

    const { data } = yield call(
      service.getInstitutions,
      payload.institutionType
    );

    yield put(actions.fetchSuccess(data));
  } catch (err: any) {
    actions.fetchError(err.message);
  }
}

export function* createInstitution({
  payload,
}: ActionType<typeof actions.createInstitution>) {
  try {
    const service = new BackOfficeServices();

    const { data } = yield call(service.createInstitution, payload.institution);

    yield put(actions.createInstitutionSuccess(data));
  } catch (err: any) {
    actions.createInstitutionError(err.message);
  }
}

export function* updateInstitution({
  payload,
}: ActionType<typeof actions.updateInstitution>) {
  try {
    if (payload.isBackOffice) {
      const service = new BackOfficeServices();
      const { data } = yield call(
        service.updateInstitution,
        payload.institution
      );

      yield put(actions.updateInstitutionSuccess(data));
    } else {
      const service = new InstitutionService();
      const { data } = yield call(
        service.updateInstitution,
        payload.institution
      );

      yield put(actions.updateInstitutionSuccess(data));
    }
  } catch (err: any) {
    actions.updateInstitutionError(err.message);
  }
}

export default all([
  takeLatest('@@institutions/FETCH_REQUEST', fetchRequest),
  takeLatest('@@institutions/CREATE_INSTITUTION', createInstitution),
  takeLatest('@@institutions/UPDATE_INSTITUTION', updateInstitution),
]);
