import styles from './artpool-image-stepper.module.scss';
import artpoolStyles from './../../styles/artpool-styles.module.scss';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import {
  getFundraisingProjectUrl,
  getNftCollectionPageUrl,
  getOrganizedOrCuratedByUser,
} from '@acg/shared/utilities';
import { Grid, useTheme } from '@mui/material';
import { MENU_HEIGHT, NEUTRAL, PRIMARY, themes } from '@acg/assets';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import MobileStepper from '@mui/material/MobileStepper/MobileStepper';
import { autoPlay } from 'react-swipeable-views-utils';
import SwipeableViews from 'react-swipeable-views';
import { useHistory } from 'react-router';
import { clippedCorners } from '../../styles/artpool-styles';
import NftViewer from '../../surfaces/nft-viewer/nft-viewer';
import { JsonNft, JsonProject } from '@acg/artpool-api-spec';
import { Property } from 'csstype';
import {
  UserOrVenueThumbnail,
  UserOrVenueTypes,
  UserProfileProps,
} from '../../data-display/user-thumbnail/user-or-venue-thumbnail';
import Subtitle4 from '../../data-display/typography/subtitle4/subtitle4';
import {
  ArrowButton,
  RotationString,
} from '../../inputs/arrow-button/arrow-button';
import { BuyButton } from '../../inputs/button/buy-button';
import { Link } from 'react-router-dom';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export interface ArtpoolImageStepperProps {
  nftDetails?: boolean;
  project: JsonProject;
  height?: string;
  objectFit?: Property.ObjectFit;
  hideBuyButton?: boolean;
  textColors?: { fontColor: string; hoverColor: string };
  cloudFrontUrl: string;
}

export function ArtpoolImageStepper(props: ArtpoolImageStepperProps) {
  const theme = useTheme();
  const history = useHistory();

  const { project, hideBuyButton, textColors, cloudFrontUrl } = props;

  const [activeStep, setActiveStep] = React.useState(0);
  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const [selectedNft, setSelectedNft] = React.useState<JsonNft>(
    project.nfts[0]
  );
  const maxSteps = project.nfts.length;

  const isMedium = useMediaQuery(themes.artpoolTheme.breakpoints.down('lg'));
  const isSmall = useMediaQuery(themes.artpoolTheme.breakpoints.down('md'));

  const refElement = useRef<any>();
  const [refHeight, setRefHeight] = React.useState<number>(0);

  useEffect(() => {
    if (refElement.current) {
      setRefHeight(refElement.current.offsetHeight);
    }
    setSelectedNft(project.nfts[activeStep]);
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const membershipExclusiveNft = !!selectedNft.minMembershipTier;
  const highlightedProject = project.highlightedOpts;

  const renderButton = () => {
    if (maxSteps > 1 && !isSmall) {
      return (
        <div>
          <Grid container spacing={1} alignItems={'center'}>
            <Grid item>
              <p>
                {activeStep + 1}/{maxSteps}
              </p>
            </Grid>
            <Grid item>
              <ArrowButton
                disabled={activeStep === 0}
                rotation={RotationString.RTL}
                handleClick={handleBack}
              />
            </Grid>
            <Grid item>
              <ArrowButton
                disabled={activeStep === maxSteps - 1}
                rotation={RotationString.LTR}
                handleClick={handleNext}
              />
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const renderBuyButton = () => {
    if ((isHovering && !isSmall) || (isMedium && !isSmall)) {
      return (
        <BuyButton projectId={project._id} nft={selectedNft} size={'small'} />
      );
    } else return <></>;
  };

  const userOrVenueProps: Partial<UserProfileProps> = {
    fontColor: textColors?.fontColor
      ? textColors.fontColor
      : highlightedProject
      ? 'white'
      : 'black',
    hoverColor: textColors?.hoverColor
      ? textColors.hoverColor
      : highlightedProject
      ? PRIMARY.ARTPOOL_GREEN
      : undefined,
  };

  const renderMobileStepper = (style: any) => {
    return (
      <MobileStepper
        steps={maxSteps}
        style={style}
        classes={{ progress: styles.progress }}
        position="static"
        activeStep={activeStep}
        variant={'progress'}
        backButton={renderButton()}
        nextButton={hideBuyButton ? <></> : renderBuyButton()}
      />
    );
  };
  const curatorOrOrganizer = getOrganizedOrCuratedByUser(project, selectedNft);

  return (
    <Box
      sx={{
        width: '100%',
        flexDirection: 'column',
        position: 'relative',
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {membershipExclusiveNft && (
        <div className={artpoolStyles.tag}>
          <p style={{ fontSize: 14 }}>Membership Exclusive</p>
        </div>
      )}
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={5000}
        style={{ ...clippedCorners }}
        slideStyle={{ overflow: 'hidden' }}
      >
        {project.nfts.map((nft: any, index: number) => {
          const handleNftClick = () => {
            history.push(getNftCollectionPageUrl(project._id, nft._id));
          };

          return (
            <div key={index}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Box
                  sx={{
                    height:
                      props.height ||
                      `calc(100vh - ${MENU_HEIGHT} ${
                        props.nftDetails ? '- 167px' : ''
                      })`,
                    width: '100%',
                    cursor: 'pointer',
                  }}
                  onClick={handleNftClick}
                >
                  <NftViewer
                    nft={nft}
                    objectFit={props.objectFit}
                    maxHeight={'100%'}
                    style={{ height: '100%' }}
                    hideFullScreenBtn
                    alwaysUseImage
                    bgColor={NEUTRAL[900]}
                    cloudFrontUrl={cloudFrontUrl}
                  />
                </Box>
              ) : null}
            </div>
          );
        })}
      </AutoPlaySwipeableViews>
      <Hidden lgDown>
        {renderMobileStepper({
          position: 'absolute',
          bottom: refHeight,
          margin: 8,
          alignItems: 'flex-end',
          width: '-webkit-fill-available',
          backgroundColor: 'transparent',
        })}
      </Hidden>
      {props.nftDetails && (
        <Grid
          container
          spacing={1}
          style={{
            backgroundColor: 'transparent',
            minHeight: 100,
            padding: 15,
            overflow: 'hidden',
          }}
          alignItems={'center'}
          textAlign={'left'}
          ref={refElement}
        >
          <Grid
            item
            xs={12}
            style={{
              color: highlightedProject ? PRIMARY.ARTPOOL_GREEN : 'black',
            }}
          >
            <Link to={getFundraisingProjectUrl(project._id)}>
              <Subtitle4>{project.title}</Subtitle4>
            </Link>
          </Grid>
          <Grid item xs={6}>
            <UserOrVenueThumbnail
              cloudFrontUrl={cloudFrontUrl}
              typeOverwrite={UserOrVenueTypes.ARTIST}
              userOrVenue={selectedNft.artist}
              {...userOrVenueProps}
            />
          </Grid>
          {curatorOrOrganizer && (
            <Grid item xs={6}>
              <UserOrVenueThumbnail
                cloudFrontUrl={cloudFrontUrl}
                userOrVenue={curatorOrOrganizer}
                {...userOrVenueProps}
              />
            </Grid>
          )}
        </Grid>
      )}
      <Hidden lgUp>
        {renderMobileStepper({
          margin: 8,
          alignItems: 'flex-end',
          width: '-webkit-fill-available',
          backgroundColor: 'transparent',
        })}
      </Hidden>
    </Box>
  );
}
