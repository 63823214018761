import * as React from 'react';

export interface IIconCloseProps {
  fill?: string;
  height?: number;
  style?: any;
  strokeWidth?: number;
}

const IconClose = (props: IIconCloseProps) => {
  const style: React.CSSProperties = {
    height: props.height || 23,
    backgroundColor:
      (props.style && props.style.backgroundColor) || 'transparent',
    borderRadius: (props.style && props.style.borderRadius) || 6,
  };

  return (
    <svg
      fill={props.fill || 'white'}
      style={style}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
    >
      <path
        strokeWidth={props.strokeWidth || ''}
        d="M585.14,517.61,596,508.7l29.42-24.23,43.5-35.83,52.78-43.47,58.1-47.86L838.4,309l54.68-45,46-37.87,33.25-27.38L988,185.78l.67-.55c2.23-1.85,3.51-5.71,3.51-8.49a12.22,12.22,0,0,0-3.51-8.48,12.1,12.1,0,0,0-8.49-3.52,13.68,13.68,0,0,0-8.49,3.52l-10.81,8.9-29.42,24.23L888,237.23,835.2,280.69l-58.1,47.86-58.65,48.31-54.67,45-46,37.86-33.25,27.38-15.73,13-.66.55c-2.24,1.84-3.52,5.7-3.52,8.48a12.21,12.21,0,0,0,3.52,8.49,12,12,0,0,0,8.48,3.51,13.7,13.7,0,0,0,8.49-3.51Z"
      />
      <path
        strokeWidth={props.strokeWidth || ''}
        d="M988.13,894.76l-10.87-9-29.35-24.32-43.36-35.93-52.88-43.8-57.9-48-58.45-48.42-54.51-45.16L634.52,601.8l-33.07-27.4-15.65-13-.66-.55c-2.52-2.08-5.11-3.51-8.49-3.51a12.18,12.18,0,0,0-8.48,3.51c-4.06,4.42-5.17,12.7,0,17l10.86,9,29.35,24.32,43.36,35.92,52.88,43.81,57.9,48L821,787.3l54.51,45.16,46.29,38.35,33.07,27.4,15.65,13,.66.55c2.52,2.08,5.11,3.51,8.49,3.51a12.26,12.26,0,0,0,8.49-3.51c4-4.43,5.16-12.7,0-17Z"
      />
      <path
        strokeWidth={props.strokeWidth || ''}
        d="M494.5,562.38l-10.82,8.9-29.41,24.23-43.51,35.83L358,674.81l-58.1,47.86L241.23,771l-54.67,45-46,37.86-33.25,27.38-15.73,13-.66.55c-2.24,1.84-3.52,5.7-3.52,8.48A12.23,12.23,0,0,0,91,911.73a12.07,12.07,0,0,0,8.48,3.51,13.7,13.7,0,0,0,8.49-3.51l10.81-8.91,29.42-24.23,43.5-35.83,52.78-43.47,58.1-47.86,58.65-48.3,54.68-45,46-37.86,33.25-27.38,15.73-13,.67-.55c2.23-1.85,3.51-5.71,3.51-8.49a12.22,12.22,0,0,0-3.51-8.48,12.1,12.1,0,0,0-8.49-3.52,13.63,13.63,0,0,0-8.48,3.52Z"
      />
      <path
        strokeWidth={props.strokeWidth || ''}
        d="M91.51,185.23l10.86,9,29.35,24.32,43.36,35.92L228,298.28l57.91,48,58.45,48.43,54.5,45.15,46.29,38.35,33.07,27.41,15.65,13,.67.55c2.51,2.08,5.1,3.52,8.48,3.52a12.23,12.23,0,0,0,8.49-3.52c4-4.42,5.16-12.69,0-17l-10.87-9-29.35-24.32-43.36-35.92L375,389.08l-57.9-48-58.45-48.43-54.51-45.16-46.28-38.35-33.08-27.4-15.65-13-.66-.55c-2.52-2.09-5.11-3.52-8.49-3.52a12.19,12.19,0,0,0-8.48,3.52c-4.06,4.42-5.16,12.69,0,17Z"
      />
    </svg>
  );
};

export default IconClose;
