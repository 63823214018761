import {
  default as withWidth,
  WithWidth,
  isWidthDown,
} from '@mui/material/Hidden/withWidth';
import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { compose } from 'recompose';
import { Grid } from '@mui/material';
// import backgroundColor from '../../img/publicBackgroung.png'
import backgroundColor from '../../../img/ACG_background_landingPage.jpg';
import {} from '@mui/material/';
import { GridProps } from '@mui/material/Grid';
import { PRIMARY_COLOR } from '../../../styles/constants';

export interface ICenterLayoutProps {
  type: 'STANDARD' | 'IMAGE' | 'DEGRADE';
  children: React.ReactNode;
}

const styles = createStyles({
  fullScreen: {
    height: '100vh',
  },
  backgroundFilled: {
    // background: `url(${backgroundColor}) no-repeat center fixed`,
    backgroundColor: 'white',
    backgroundSize: 'cover',
  },
  degrade: {
    // background: `url(${backgroundColor}) no-repeat center fixed`,
    // backgroundSize: 'cover'
    background: `linear-gradient(45deg, rgba(255,255,255,1) 0%, ${PRIMARY_COLOR} 100%)`,
  },
  image: {
    background: `url(${backgroundColor}) no-repeat center fixed`,
    backgroundSize: 'cover',
  },
});

type Props = ICenterLayoutProps & WithStyles<string> & WithWidth;

class CenterLayout extends React.Component<Props, any> {
  public render() {
    const {
      classes,
      // width,
      type,
    } = this.props;

    const props: Partial<GridProps> = {
      // justify: isWidthDown(BREAKPOINT, width) ? "flex-start" : "center",
      // alignItems: isWidthDown(BREAKPOINT, width) ? "flex-start" : "center",
      justifyContent: 'center',
      alignItems: 'center',
    };

    let classname = null;

    switch (type) {
      case 'STANDARD':
        classname = classes.backgroundFilled;
        break;
      case 'DEGRADE':
        classname = classes.degrade;
        break;
      case 'IMAGE':
        classname = classes.image;
    }

    return (
      <Grid
        container={true}
        direction="row"
        {...props}
        style={{ height: '100%', overflowY: 'auto' }}
        className={`${classes.fullScreen} ${classname}`}
      >
        <Grid xs={12} sm={10} md={8} lg={6} item={true}>
          {this.props.children}
        </Grid>
      </Grid>
    );
  }
}

export default compose<Props, ICenterLayoutProps>(
  withStyles(styles),
  withWidth()
)(CenterLayout);
