import './twitter-icon.module.scss';
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

// tslint:disable-next-line:no-empty-interface
export interface TwitterIconProps {}

type Props = TwitterIconProps & SvgIconProps;

export function TwitterIcon(props: Props) {
  return (
    <SvgIcon {...props} viewBox={'0 0 28 23'}>
      <path d="M28 3.00047L25 3.50047L27 1.00047L23.5 2.00047C19 -2.99953 12 2.50047 14 7.00048C6 7.00048 2 1.00047 2 1.00047C2 1.00047 -1 5.50047 4 9.00048L1 8.00048C1 11.0005 3 13.0005 6.5 14.0005H3C5 18.0005 8.5 18.0005 8.5 18.0005C8.5 18.0005 5.5 20.5005 0 20.5005C16.5 28.5005 26.5 13.5005 25 5.50048L28 3.00047Z" />
    </SvgIcon>
  );
}

export default TwitterIcon;
