import { WithStyles, withStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { FieldProps } from 'formik';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Popper, Paper, MenuItem } from '@mui/material';
import { compose } from 'recompose';

export interface ILocationFinderProps extends FieldProps {
  inputProps: TextFieldProps;
  naked?: boolean;
  newLocationFinder?: boolean;
}

type Props = WithStyles<string> & ILocationFinderProps;

const style = createStyles({
  root: {},
  input: {},
  backgroundGradient: {
    backgroundImage:
      'linear-gradient(to right, #f5f5f5, #f6f6f6 10%, #f7f7f7 20%, #f8f8f8 30%, #f9f9f9 40%, #fafafa 50%, #fbfbfb 60%, #fcfcfc 70%, #fdfdfd 80%, #fefefe 90%, #ffffff)',
  },
  newBackground: {
    backgroundColor: 'white',
  },
  secondaryText: {
    color: 'rgb(0, 0, 0, 0.6)',
    fontSize: '0.8em',
  },
});

class LocationFinder extends React.Component<Props, any> {
  private ref: any;
  private nodeRef: any;

  constructor(props: Props) {
    super(props);
    const initialValue = props.form.values[props.field.name].value;
    this.state = { address: initialValue ? initialValue : '' };
  }

  public handleError = (error: any) => {
    const { field } = this.props;
    this.props.form.setFieldError(field.name, error);
  };

  public handleChange = (address: string) => {
    const { form, field } = this.props;
    form.setFieldTouched(`${field.name}.value`);
    form.setFieldTouched(`${field.name}.address`);
    form.setFieldValue(field.name, {
      value: address,
      address,
      placeId: '',
      latLng: [0, 0],
    });
    this.setState({ address });
  };

  public handleSelect = (address: string, placeId: string) => {
    const { field, form } = this.props;
    this.setState({ address });
    let formattedLocation = '';
    let country = '';
    let city = '';
    let state = '';
    geocodeByAddress(address)
      .then((results) => {
        formattedLocation = results[0].formatted_address;

        results[0].address_components.forEach((addressComponent: any) => {
          if (addressComponent.types[0] === 'locality') {
            city = addressComponent.long_name;
          }
          if (addressComponent.types[0] === 'country') {
            country = addressComponent.long_name;
          }
          if (addressComponent.types[0] === 'administrative_area_level_1') {
            state = addressComponent.long_name;
          }
        });

        // window.console.log('results -> ', results);
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        form.setFieldValue(field.name, {
          value: formattedLocation,
          address: formattedLocation,
          placeId,
          latLng: [latLng.lat, latLng.lng],
          country,
          state,
          city,
        });
      })
      .catch((error) => {
        form.setFieldValue(field.name, {
          value: address,
          address,
          placeId,
          latLng: [0, 0],
          state,
          country,
          city,
        });
      });
  };

  public componentDidMount(): void {
    this.nodeRef = ReactDOM.findDOMNode(this.ref);
  }

  public render() {
    const { inputProps, form, field, classes, naked, newLocationFinder } =
      this.props;

    let stl = {};
    if (naked) {
      stl = {
        variant: 'outlined',
      };
    }

    const assignRef = (node: any) => {
      this.ref = node;
    };

    const b = this.nodeRef;

    const coordinate = {
      // top: b ? (b as any).getBoundingClientRect().y + (b as any).getBoundingClientRect().height : 0,
      left: b ? -(b as any).getBoundingClientRect().x + 20 : 0,
      width: b ? (b as any).getBoundingClientRect().width : 0,
    };

    const computeInputProps = (getInputProps: any) => {
      const props = getInputProps(inputProps);
      return props;
    };

    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        debounce={500}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <TextField
              {...computeInputProps(getInputProps)}
              disabled={form.isSubmitting || inputProps.disabled}
              error={!!(form.touched[field.name] && form.errors[field.name])}
              helperText={form.touched[field.name] && form.errors[field.name]}
              fullWidth={true}
              margin={'normal'}
              className={classes.root}
              InputProps={{
                className: `${classes.input} ${
                  newLocationFinder
                    ? classes.newBackground
                    : classes.backgroundGradient
                }`,
              }}
              variant="filled"
              {...stl}
              ref={assignRef}
            />
            {/*@ts-ignore*/}
            <Popper
              open={suggestions.length > 0 || loading}
              anchorEl={this.ref}
              style={{ zIndex: 10000 }}
            >
              <div>
                <Paper
                  square={true}
                  style={{
                    marginTop: 8,
                    position: 'absolute',
                    ...coordinate,
                  }}
                >
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    // inline style for demonstration purpose
                    const styles: React.CSSProperties = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <MenuItem
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          styles,
                        })}
                        selected={suggestion.active}
                        key={suggestion.id}
                      >
                        <span>{suggestion.formattedSuggestion.mainText}, </span>
                        <span className={classes.secondaryText}>
                          {' '}
                          {suggestion.formattedSuggestion.secondaryText}
                        </span>
                      </MenuItem>
                    );
                  })}
                </Paper>
              </div>
            </Popper>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default compose<Props, ILocationFinderProps>(withStyles(style))(
  LocationFinder
);
