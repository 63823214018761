export const clippedCorners = {
  clipPath:
    'polygon(8px 0%, calc(100% - 8px) 0%, 100% 8px, 100% calc(100% - 8px), calc(100% - 8px) 100%, 8px 100%, 0% calc(100% - 8px), 0% 8px)',
};

export const lineClamp1 = {
  WebkitBoxOrient: 'vertical' as any,
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitLineClamp: 1,
  lineClamp: 1,
};

export const lineClamp3 = {
  ...lineClamp1,
  WebkitLineClamp: 3,
  lineClamp: 3,
};

export const lineClamp4 = {
  ...lineClamp1,
  WebkitLineClamp: 5,
  lineClamp: 5,
};

export const cardShadow = {
  filter: 'drop-shadow(rgba(0, 0, 0, 0.2) 1.5px 2px 2px)',
};
