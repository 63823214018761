import * as React from 'react';
import { MouseEventHandler, useState } from 'react';
import { ACG_URL } from '@acg/shared/const';
import useAsyncEffect from 'use-async-effect';

export interface IArtpoolLogoProps {
  hoverEffect?: boolean;
  isMobile?: boolean;
  isPad?: boolean;
  className?: string;
  onClick?: MouseEventHandler<HTMLImageElement>;
  linkToArtpool?: boolean;
  alwaysGreen?: boolean;
}

const blobToDataUrl = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const ArtpoolLogo = (props: IArtpoolLogoProps) => {
  const {
    hoverEffect,
    isPad,
    isMobile,
    onClick,
    className,
    linkToArtpool,
    alwaysGreen
  } = props;

  const [logoHover, setLogoHover] = useState(false);
  const [gifDataUrl, setGifDataUrl] = useState<string | undefined>();

  // Preload the gif if we want to use the hover effect
  useAsyncEffect(async () => {
    if (hoverEffect && !gifDataUrl) {
      const resp = await fetch(
        '/assets/artpool-logo/artpool-animation-logo.gif'
      );
      setGifDataUrl(await blobToDataUrl(await resp.blob()));
    }
  }, [hoverEffect]);

  const showGif = hoverEffect && logoHover;
  const staticImage = alwaysGreen
    ? '/assets/artpool-logo/artpool-logo-green.png'
    : '/assets/artpool-logo/artpool-logo.svg';
  const src = showGif && gifDataUrl ? gifDataUrl : staticImage;

  const onMouseEnter = (e: React.MouseEvent) => setLogoHover(true);
  const onMouseLeave = (e: React.MouseEvent) => setLogoHover(false);

  const img = (
    <img
      alt="Artpool Logo"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      className={className}
      src={src}
      style={{ width: isMobile ? 200 : isPad ? 280 : 320 }}
    />
  );

  if (linkToArtpool) {
    return <a href={ACG_URL}>{img}</a>;
  }

  return img;
};
