import { ITag } from './tags-interfaces';
import { InstitutionType } from './institution-interfaces';

export enum UserProfileType {
  VIEWER = 'VIEWER',
  CURATOR = 'CURATOR',
  ARTIST = 'ARTIST',
}

export type OrganizerType = UserProfileType | InstitutionType;

export interface IUser {
  email: string;
  password: string;
  passwordResetToken: string;
  passwordResetExpires: Date;
  facebook: string;
  tokens: AuthToken[];
  profile: UserProfileType;
  firstName: string;
  lastName: string;
  description: string;
  pending: boolean;
  company_type: 'INDEPENDANT' | 'INSTITUTION';
  company_name: string;
  country: string;
  // city: string;
  location: {
    address: string;
    placeId: string;
    latLng: [number, number];
  };
  profilePicture: string;
  hasTakenTour: boolean;
  bio: string;
  hasSkippedProfilePicture: boolean;
  isTouring: boolean;
  followers_number: number;
  followings_numbers: number;
  tags: [ITag];
  url: string;
  type: string;
  profile_type: 'PUBLIC' | 'PRIVATE';

  newsletter_network: boolean;
  newsletter_curtain: boolean;
  exhibitions_number: number;
  post_total: number;

  isExhibitionAdmin: boolean;
  isBackOfficeAdmin: boolean;
}

export interface AuthToken {
  accessToken: string;
  kind: string;
}

export interface IFollower {
  created_at: Date;
  created_by: IUser;
  following: IUser;
}
